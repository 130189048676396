import { capital } from 'case'
import { Pane, Table, Text, Tooltip } from 'evergreen-ui'
import { colors, Icon, Types } from 'lib'
import moment from 'moment'

import { outreachEvents } from 'utility/label-maps'
import OutreachTimelineAction from './OutreachTimelineAction'

type Props = {
  flow: Types.GuarantorBillingOutreach_contact_outreach_timeline_flow
  isFirstItem?: boolean
}

const OutreachTimelineFlow = ({ flow, isFirstItem = false }: Props) => {
  const { startDate, endDate, isManualEnd, endReason, isPrompted, items } = flow

  const isPromptedPayment = endReason === Types.OutreachFlowEndReason.BALANCE_CHANGED && isPrompted
  const endReasonTooltip = endReason ? capital(endReason) : 'Ended Manually'

  const endTooltipContent = isPromptedPayment ? `${endReasonTooltip} (prompted payment)` : endReasonTooltip
  const isTooltipShown = !!(endReason || isManualEnd)

  return (
    <>
      {!!endDate && (
        <>
          {!isFirstItem && (
            <Pane height={1} width={24} backgroundColor={colors.border.default} marginY={8} marginX="auto" />
          )}
          <Table.Row height={32} borderColor="transparent" position="relative">
            <Table.Cell display="flex" paddingRight={0}>
              <Pane width="18px" marginRight={4} backgroundColor="white" zIndex={2}>
                <Icon
                  icon={['far', outreachEvents.END.icon]}
                  fontSize={12}
                  lineHeight="12px"
                  width={16}
                  marginLeft={0}
                  flexShrink={0}
                  color={outreachEvents.END.color}
                />
              </Pane>
              <Tooltip isShown={isTooltipShown ? undefined : false} content={endTooltipContent} position="top">
                <Text size={300} flex="1" fontWeight={500}>
                  {outreachEvents.END.label}
                </Text>
              </Tooltip>
            </Table.Cell>
            <Table.TextCell flexBasis={110} flexShrink={0} flexGrow={0} paddingRight={0}>
              {moment.unix(endDate).format('MMM D, YYYY')}
            </Table.TextCell>
            <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
              {moment.unix(endDate).format('h:mm A')}
            </Table.TextCell>
          </Table.Row>
        </>
      )}
      {items.map((item, i) => (
        <OutreachTimelineAction key={item.id} action={item} isOnFlow isFirstItem={i === 0} />
      ))}
      <Table.Row height={32} borderColor="transparent" position="relative">
        <Table.Cell display="flex">
          <Pane
            height={16}
            width={2}
            backgroundColor={colors.border.default}
            position="absolute"
            left={17}
            bottom={24}
            zIndex={1}
          />
          <Pane width="18px" marginRight={4} backgroundColor="white" zIndex={2}>
            <Icon
              icon={['far', outreachEvents.START.icon]}
              fontSize={12}
              lineHeight="12px"
              width={16}
              marginLeft={0}
              flexShrink={0}
              color={outreachEvents.START.color}
            />
          </Pane>
          <Tooltip content={`Outreach Started: ${capital(flow.type)} Flow`} position="top">
            <Text size={300} flex="1" fontWeight={500}>
              {outreachEvents.START.label}
            </Text>
          </Tooltip>
        </Table.Cell>
        <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
          {moment.unix(startDate).format('MMM D, YYYY')}
        </Table.TextCell>
        <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
          {moment.unix(startDate).format('h:mm A')}
        </Table.TextCell>
      </Table.Row>
    </>
  )
}

export default OutreachTimelineFlow
