import moment from 'moment'
import { CardProps, Heading, Text, Pane } from 'evergreen-ui'
import { Types, Card, CardHeader, Icon, Button, StatusBadge, toDollarsFromCents } from 'lib'

import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'

import ModifyInstallmentPlanDropdown from 'components/modify-installment-plan-dropdown'

export interface Props extends CardProps {
  plan: Types.GuarantorPlans_contact_installmentPlans
  guarantor: Types.GuarantorPlans_contact
}

const InstallmentPlanCard = ({ plan, guarantor, elevation = 0, ...props }: Props) => {
  const { account } = useGlobal()
  const showInstallmentTerms = useModal('installmentTerms')

  if (!plan) throw Error('Transaction does not have payment plan')
  const { scheduledPayments, periods } = plan

  const planCompleted = Types.InstallmentPlanStatus.COMPLETED === plan.status
  const planCanceled = Types.InstallmentPlanStatus.CANCELED === plan.status

  const planTotal = plan.adminFeeAmount + plan.faceAmount
  const paidScheduledPayments = scheduledPayments.filter((payment) => payment.status === 'SUCCESS')
  const remainingAmount = planCompleted
    ? 0
    : paidScheduledPayments.reduce((acc, paid) => acc - paid.amount, plan.faceAmount)

  return (
    <Card elevation={elevation} padding={0} {...props}>
      <CardHeader display="flex" justifyContent="space-between">
        <Heading size={500}>
          <Icon icon={['fad', 'chart-pie-alt']} marginRight={8} />
          Payment Plan - {toDollarsFromCents(planTotal, 2)}
        </Heading>
        <StatusBadge.InstallmentPlanStatus status={plan.status} />
      </CardHeader>
      <Pane padding={16} display="flex" flexDirection="column">
        <Pane display="grid" gridTemplateColumns="auto 1fr" gridRowGap={12} gridColumnGap={40} marginBottom={16}>
          <Text size={400} textTransform="uppercase" color="selected">
            Start Date:
          </Text>
          <Text size={500}>{moment(plan.startDate).format('M/D/YYYY')}</Text>

          <Text size={400} textTransform="uppercase" color="selected">
            Payoff Date:
          </Text>
          <Text size={500}>{moment(scheduledPayments[scheduledPayments.length - 1].date).format('M/D/YYYY')}</Text>

          <Text size={400} textTransform="uppercase" color="selected">
            Plan Amount:
          </Text>
          <Text size={500}>{toDollarsFromCents(plan.faceAmount, 2)}</Text>

          {plan.adminFeeAmount > 0 && (
            <>
              <Text size={400} textTransform="uppercase" color="selected">
                Enrollment Fee:
              </Text>

              <Text size={500}>{toDollarsFromCents(plan.adminFeeAmount, 2)}</Text>
            </>
          )}

          <Text size={400} textTransform="uppercase" color="selected">
            Completed:
          </Text>
          <Text size={500}>
            {paidScheduledPayments.length} of {periods}
          </Text>

          {remainingAmount > 0 && (
            <>
              <Text size={400} textTransform="uppercase" color="selected">
                Remaining:
              </Text>
              <Text size={500}>{toDollarsFromCents(remainingAmount, 2)}</Text>
            </>
          )}
        </Pane>
        <Pane display="flex" alignSelf="center" marginTop={8}>
          <Button
            marginRight={4}
            onClick={() =>
              showInstallmentTerms({
                startDate: new Date(plan.startDate),
                amount: plan.faceAmount,
                installmentPlan: plan,
                account,
                guarantor
              })
            }
          >
            Schedule
          </Button>
          {!planCompleted && !planCanceled && <ModifyInstallmentPlanDropdown installmentPlan={plan} marginLeft={4} />}
        </Pane>
      </Pane>
    </Card>
  )
}

export default InstallmentPlanCard
