import { Pane } from 'evergreen-ui'

import { Button, ButtonProps, colors, Types } from 'lib'
export interface Props {
  state: Types.SelectedBuckets | null
  onSelect: (buckets: Types.SelectedBuckets) => void
}

const buttonProps: ButtonProps = {
  width: 80,
  justifyContent: 'center',
  onFocus: (e) => e.currentTarget.blur()
}

const BucketSelector = ({ state, onSelect }: Props) => {
  const handleClick = (bucket: keyof Types.SelectedBuckets) => {
    if (!state) return
    onSelect({ ...state, [bucket]: !state[bucket] })
  }

  const borderWidth = 1.6

  const unselectedStyle: React.CSSProperties = {
    borderColor: colors.border.default,
    borderWidth: borderWidth,
    borderStyle: 'solid',
    color: colors.neutral.muted,
    zIndex: 0
  }

  const selectedStyle: React.CSSProperties = {
    borderColor: 'rgba(16, 112, 202, 0.7)',
    borderWidth: borderWidth,
    borderStyle: 'solid',
    color: colors.blue.base,
    zIndex: 1
  }

  if (!state) return null

  return (
    <Pane display="flex" position="relative">
      <Button
        appearance={state.allowL30 ? 'default' : 'minimal'}
        marginX={-borderWidth / 2}
        style={state.allowL30 ? selectedStyle : unselectedStyle}
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        {...buttonProps}
        onClick={() => handleClick('allowL30')}
      >
        {'<30'}
      </Button>
      <Button
        appearance={state.allowL60 ? 'default' : 'minimal'}
        marginX={-borderWidth / 2}
        style={state.allowL60 ? selectedStyle : unselectedStyle}
        borderRadius={0}
        {...buttonProps}
        onClick={() => handleClick('allowL60')}
      >
        30-60
      </Button>
      <Button
        appearance={state.allowL90 ? 'default' : 'minimal'}
        marginX={-borderWidth / 2}
        style={state.allowL90 ? selectedStyle : unselectedStyle}
        borderRadius={0}
        {...buttonProps}
        onClick={() => handleClick('allowL90')}
      >
        60-90
      </Button>
      <Button
        appearance={state.allowG90 ? 'default' : 'minimal'}
        marginX={-borderWidth / 2}
        style={state.allowG90 ? selectedStyle : unselectedStyle}
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        {...buttonProps}
        onClick={() => handleClick('allowG90')}
      >
        90+
      </Button>
    </Pane>
  )
}

export default BucketSelector
