import moment from 'moment'
import { kebab } from 'case'

import { Types } from 'lib'

export const objectKeys = <O extends { [key: string]: any }>(o: O) => {
  return Object.keys(o) as (keyof O)[]
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export const getShownPrice = ({
  term,
  isFirstFamilyMember,
  plan,
  coupon
}: {
  term: Types.PlanTerm
  isFirstFamilyMember: boolean
  plan: {
    annualPrice: number | null
    annualFamilyPrice: number | null
    monthlyPrice: number | null
    monthlyFamilyPrice: number | null
  }
  coupon?: Types.Coupon
}) => {
  const annualPrice = plan.annualPrice ?? 0
  const annualFamilyPrice = plan.annualFamilyPrice ?? 0
  const monthlyPrice = plan.monthlyPrice ?? 0
  const monthlyFamilyPrice = plan.monthlyFamilyPrice ?? 0

  return (
    (term === Types.PlanTerm.ANNUAL
      ? isFirstFamilyMember
        ? annualPrice
        : annualFamilyPrice
      : isFirstFamilyMember
      ? monthlyPrice
      : monthlyFamilyPrice) *
    ((100 - (coupon?.percent_off ?? 0)) / 100)
  )
}

export const mergeVariables = (template: string, variables: { [x: string]: string | null }) =>
  Object.keys(variables).reduce((acc, cur) => {
    if (variables[cur] == null) {
      throw new Error(`No data for variable {{${cur}}`)
    }

    return acc.replace(new RegExp(`{{${cur}}}`, 'g'), variables[cur]!)
  }, template)

export const getAge = ({ dobDay, dobMonth, dobYear }: { dobDay: number; dobMonth: number; dobYear: number }) =>
  moment().diff(moment(`${dobYear}-${dobMonth}-${dobDay}`, 'YYYY-M-D'), 'years')

export const toCents = (amount: number) => Math.round(amount * 100)

type TimeSeries = {
  month0: number
  month1: number
  month2: number
  month3: number
  month4: number
  month5: number
  month6: number
}

export const mergeTimeSeries = (...timeSeriesArray: TimeSeries[]) =>
  timeSeriesArray.reduce(
    (prev, curr) => ({
      month0: prev.month0 + curr.month0,
      month1: prev.month1 + curr.month1,
      month2: prev.month2 + curr.month2,
      month3: prev.month3 + curr.month3,
      month4: prev.month4 + curr.month4,
      month5: prev.month5 + curr.month5,
      month6: prev.month6 + curr.month6
    }),
    { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0 }
  )

export const getImageUrl = (siteImage: string) =>
  `https://storage.googleapis.com/pearly-app/site-images/${kebab(siteImage)}.png`
