import styled from 'styled-components/macro'
import moment from 'moment'

import { useQuery, useApolloClient } from '@apollo/client'

import { GET_CONTRACT_SHEET } from 'lib/graphql/_contract-sheet'

import { SideSheet, Pane, Paragraph, Text, Heading, UnorderedList, ListItem, Strong } from 'evergreen-ui'

import { Types, CardHeader, Spinner, TreatmentsTable, DiscountsTable } from 'lib'

import { colors } from 'lib'
import { useMediaQueryContext } from 'lib'
import { useWindowSize } from 'lib'
import { mc } from 'lib'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  planId: string
  effectiveDate?: number
  planTerm?: Types.PlanTerm
  autoRenew?: boolean
}

const ContractSheet = ({ isShown, setIsShown, planId, effectiveDate, planTerm, autoRenew }: Props) => {
  const apolloClient = useApolloClient()
  const { isMobile } = useMediaQueryContext()
  const height = useWindowSize()[1]

  const { loading, error, data } = useQuery<Types.ContractSheet, Types.ContractSheetVariables>(GET_CONTRACT_SHEET, {
    variables: { id: planId! },
    skip: !isShown || !planId,
    client: apolloClient
  })
  const plan = data?.plan
  const annualPrice = plan?.annualPrice ?? 0
  const monthlyPrice = plan?.monthlyPrice ?? 0

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={600}
      preventBodyScrolling={true}
      //@ts-ignore
      position={isMobile ? 'bottom' : 'right'}
    >
      {loading || error || !plan ? (
        <Pane display="flex" alignItems="center" background="blueTint" height={isMobile ? height - 56 : '100%'}>
          <Spinner delay={0} />
        </Pane>
      ) : (
        <SheetLayout>
          <CardHeader gridArea="header">
            <Heading size={600}>{plan.name}</Heading>
          </CardHeader>

          <Pane gridArea="body" overflow="auto" height={isMobile ? height - 112 : '100%'}>
            <Pane margin={24}>
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                Overview
              </Heading>
              <Paragraph marginBottom={24}>
                We offer an in-house membership plan to our loyal patients that provides easy, comprehensive, and
                affordable dental care benefits. You do not have to deal with the headaches of insurance, such as denial
                of claims or pre-approvals. Enrollment takes only 90-seconds and you receive instant access to your
                benefits. Learn more about the benefits, pricing, and terms below.
              </Paragraph>
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                Plan Details
              </Heading>
              <Pane display="flex" flexDirection="column" marginBottom={24}>
                <Text marginBottom={4}>
                  <Strong>Plan: </Strong>
                  {plan.name}
                </Text>
                <Text marginBottom={4}>
                  <Strong>Effective Date: </Strong>
                  {(effectiveDate ? moment.unix(effectiveDate) : moment()).format('M/D/YYYY')}
                </Text>

                <Text marginBottom={4}>
                  <Strong>{autoRenew !== false ? 'Renewal' : 'Termination'} Date: </Strong>
                  {`${(effectiveDate ? moment.unix(effectiveDate) : moment()).add(1, 'year').format('M/D/YYYY')}`}
                </Text>
                <Text marginBottom={4}>
                  <Strong>Renewal: </Strong>
                  {autoRenew !== false ? 'Annual' : 'Canceled'}
                </Text>

                <Text marginBottom={4}>
                  <Strong>Price: </Strong>
                  {`
                    ${
                      (!planTerm && plan.annualPriceActive) || planTerm === Types.PlanTerm.ANNUAL
                        ? `$${annualPrice} / year`
                        : ''
                    }
                    ${!planTerm && plan.annualPriceActive && plan.monthlyPriceActive ? ' or ' : ''}
                    ${
                      (!planTerm && plan.monthlyPriceActive) || planTerm === Types.PlanTerm.MONTHLY
                        ? `$${monthlyPrice} / month`
                        : ''
                    }
                  `}
                </Text>
                {!!plan.familyDiscount && plan.familyDiscount > 0 && (
                  <Text marginBottom={4}>
                    <Strong>Family Discount: </Strong>
                    {plan.familyDiscount}%
                  </Text>
                )}
                {plan.showValue && (
                  <Text marginBottom={4}>
                    <Strong>Annual Value: </Strong>
                    <PlanValueString plan={plan} />
                  </Text>
                )}
              </Pane>
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={16}>
                Included Treatments
              </Heading>
              <TreatmentsTable
                marginBottom={24}
                elevation={0}
                treatments={plan.treatments}
                showValue={plan.showValue}
              />
              {((plan.globalDiscountActive && plan.globalDiscount && plan.globalDiscount > 0) ||
                !!plan.discounts.length) && (
                <>
                  <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                    Procedure Discounts by Category
                  </Heading>
                  <Paragraph marginBottom={16}>
                    The following highlights the discounts provided for each dental procedure category as part of this
                    membership plan:
                  </Paragraph>
                  <DiscountsTable marginBottom={24} elevation={0} plan={plan} />
                </>
              )}
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                Terms, Exclusions, & Disclosures
              </Heading>
              <Pane marginBottom={24}>
                <Paragraph>
                  You agree to the following terms and disclosures by purchasing this membership plan:
                </Paragraph>
                <UnorderedList>
                  <ListItem>
                    Our membership plans are provided only to uninsured patients of our practice and are not insurance,
                    pre-payment for future services, payment for access to discounted services, or a qualified health
                    plan under the Affordable Care Act. Instead, our patients elect to make a monthly or annual
                    subscription payment for preventive care versus paying at the time of each service.
                  </ListItem>
                  <ListItem>
                    The membership plan is a direct agreement between the patient and the legal entity of our practice.
                    Membership plan payments must be current in order for the patient to receive the benefits included
                    with their membership plan.
                  </ListItem>
                  <ListItem>
                    Membership plan benefits may not be combined with any other offers or discounts. Payment for
                    additional treatment provided by our practice to the patient is due at the time of service and our
                    membership plans do not cover procedures completed outside of our practice.
                  </ListItem>
                  <ListItem>
                    The term of our membership plans is 12 months from the patient Effective Date and automatically
                    renews on the Renewal Date. Patients can opt-out of membership plan renewals by clicking “Cancel
                    Auto-Renew” in the Member Portal or by contacting our practice directly. Refunds are at the sole
                    discretion of our practice.
                  </ListItem>
                  <ListItem>
                    Software services are provided by {mc.legalName} and you can learn more at {mc.urls.info}.
                  </ListItem>
                  {plan.additionalTerms.map((term, i) => (
                    <ListItem key={i}>{term}</ListItem>
                  ))}
                </UnorderedList>
              </Pane>
            </Pane>
          </Pane>
        </SheetLayout>
      )}
    </SideSheet>
  )
}

const PlanValueString = ({ plan }: { plan: Types.ContractSheet_plan }) => {
  const totalValue = plan.treatments.reduce((prev, curr) => prev + curr.value, 0)
  const annualCost = plan.annualPriceActive ? plan.annualPrice! : plan.monthlyPrice! * 12
  return (
    <span>
      {`$${totalValue} `} <i>{`(Save $${totalValue - annualCost} compared to our standard fees)`}</i>
    </span>
  )
}

export default ContractSheet

const SheetLayout = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
`
