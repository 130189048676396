import gql from 'graphql-tag'

const fragments = {
  onboardingUserFields: gql`
    fragment onboardingUserFields on User {
      id
      firstName
      lastName
    }
  `,
  onboardingAccountFields: gql`
    fragment onboardingAccountFields on Account {
      id
      name
      timezone
      phone
      offices {
        id
        name
        street1
        street2
        city
        state
        zip
      }
    }
  `,
  onboardingOfficeFields: gql`
    fragment onboardingAddressFields on Address {
      id
      name
      street1
      street2
      city
      state
      zip
    }
  `,
  onboardingDentistFields: gql`
    fragment onboardingDentistFields on Dentist {
      id
      salutation
      name
      degree
    }
  `
}

export const GET_ONBOARDING_START = gql`
  query OnboardingStart {
    meUser {
      ...onboardingUserFields
    }
  }
  ${fragments.onboardingUserFields}
`

export const SUBMIT_ONBOARDING_START = gql`
  mutation SubmitOnboardingStart($idToken: String!, $firstName: String!, $lastName: String!) {
    onboardingStart(idToken: $idToken, firstName: $firstName, lastName: $lastName) {
      ...onboardingUserFields
    }
  }
  ${fragments.onboardingUserFields}
`

export const UPDATE_ONBOARDING_START = gql`
  mutation UpdateOnboardingStart($id: ID!, $firstName: String!, $lastName: String!) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName, role: ADMIN) {
      ...onboardingUserFields
    }
  }
  ${fragments.onboardingUserFields}
`

export const GET_ONBOARDING_PRACTICE = gql`
  query OnboardingPractice {
    account {
      ...onboardingAccountFields
    }
  }
  ${fragments.onboardingAccountFields}
`

export const SUBMIT_ONBOARDING_PRACTICE = gql`
  mutation SubmitOnboardingPractice(
    $name: String!
    $mirror: Mirror!
    $timezone: Timezone!
    $street1: String!
    $street2: String
    $city: String!
    $state: State!
    $zip: String!
    $phone: String!
  ) {
    onboardingPractice(
      name: $name
      mirror: $mirror
      timezone: $timezone
      street1: $street1
      street2: $street2
      city: $city
      state: $state
      zip: $zip
      phone: $phone
    ) {
      ...onboardingAccountFields
    }
  }
  ${fragments.onboardingAccountFields}
`

export const UPDATE_ONBOARDING_PRACTICE = gql`
  mutation UpdateOnboardingPractice(
    $name: String!
    $timezone: Timezone!
    $officeId: ID!
    $street1: String!
    $street2: String
    $city: String!
    $state: State!
    $zip: String!
  ) {
    updateAccount(name: $name, timezone: $timezone) {
      id
      name
      timezone
    }
    updateAddress(id: $officeId, street1: $street1, street2: $street2, city: $city, state: $state, zip: $zip) {
      ...onboardingAddressFields
    }
  }
  ${fragments.onboardingOfficeFields}
`

export const GET_ONBOARDING_OFFICES = gql`
  query OnboardingOffices {
    account {
      id
      offices {
        ...onboardingAddressFields
      }
    }
  }
  ${fragments.onboardingOfficeFields}
`

export const GET_ONBOARDING_DENTISTS = gql`
  query OnboardingDentists {
    dentists {
      ...onboardingDentistFields
    }
  }
  ${fragments.onboardingDentistFields}
`

export const SUBMIT_ONBOARDING_DENTISTS = gql`
  mutation SubmitOnboardingDentists($salutation: Salutation!, $name: String!, $degree: Degree!) {
    onboardingDentists(salutation: $salutation, name: $name, degree: $degree) {
      ...onboardingDentistFields
    }
  }
  ${fragments.onboardingDentistFields}
`

export const GET_ONBOARDING_COMPLETE = gql`
  query OnboardingComplete {
    firstAddress {
      ...onboardingAddressFields
    }
    meUser {
      firstName
    }
  }
  ${fragments.onboardingOfficeFields}
`
