export enum Mirror {
  PEARLY = 'PEARLY',
  PPO = 'PPO'
}

export enum ExtendedMirror {
  PEARLY = 'PEARLY',
  PPO = 'PPO',
  LOCAL = 'LOCAL',
  STAGING = 'STAGING',
  EXPERIMENTAL = 'EXPERIMENTAL',
  TEST = 'TEST'
}

const detectMirror = () => {
  if (typeof window === 'undefined') {
    return ExtendedMirror.PEARLY
  }

  const hostname = window.location.hostname.toLowerCase()

  if (hostname.includes('localhost')) {
    return ExtendedMirror.LOCAL
  }

  if (hostname.includes('ffsprofits')) {
    return ExtendedMirror.PPO
  }

  if (hostname.includes('staging')) {
    return ExtendedMirror.STAGING
  }

  if (hostname.includes('experimental')) {
    return ExtendedMirror.EXPERIMENTAL
  }

  if (hostname.includes('test')) {
    return ExtendedMirror.TEST
  }

  return Mirror.PEARLY
}

type MirrorConfig = {
  id: Mirror
  name: string
  imagePaths?: {
    logo?: string
    favicon?: string
    providerLogin?: string
    patientLogin?: string
  }
  color: 'blue' | 'orange' | 'red'
  backgroundColor: 'blueTint' | 'tint2'
  chartColor: 'green' | 'red'
  intent: 'none' | 'warning' | 'danger'
  legalName: string
  email: string
  phone: string
  timezone: string
  urls: {
    info: string
    provider: string
    member: string
    payment: string
    legal: string
  }
  signupSite: {
    hero: {
      header: string
      body: string
      cta: string
    }
    features: {
      header: string
      body: string
      first: {
        title: string
        body: string
      }
      second: {
        title: string
        body: string
      }
      third: {
        title: string
        body: string
      }
      cta: string
    }
  }
}

const pearlyConfig: MirrorConfig = {
  id: Mirror.PEARLY,
  name: 'Pearly',
  color: 'blue',
  chartColor: 'green',
  backgroundColor: 'blueTint',
  intent: 'none',
  legalName: 'Pearly Technology Inc.',
  email: 'support@pearly.co',
  phone: '18449550637',
  timezone: 'PT',
  urls: {
    info: 'https://www.pearly.co',
    provider: 'https://app.pearly.co',
    member: 'https://member.pearly.co',
    payment: 'https://bill.care',
    legal: 'https://www.pearly.co/legal/links/'
  },
  signupSite: {
    hero: {
      header: 'Join Our Plan',
      body:
        'No dental insurance? Our in-house membership plan is for you! Our plans include preventive care and save you money on other procedures. Enroll in 90-seconds and receive instant access.',
      cta: 'Enroll Now'
    },
    features: {
      header: 'No Dental Benefits? No Problem!',
      body:
        'For our loyal patients without dental benefits, we’ve launched our own membership program that eliminates the insurance middleman.',
      first: {
        title: 'Easy',
        body: 'Sign-up in 90-seconds and instantly access your benefits.'
      },
      second: {
        title: 'Comprehensive',
        body: 'Plans cover your preventive care like cleanings and x-rays.'
      },
      third: {
        title: 'Affordable',
        body: 'Simple and transparent pricing with discounts on other procedures.'
      },
      cta: 'Enroll in 90 Seconds'
    }
  }
}

const ppoConfig: MirrorConfig = {
  id: Mirror.PPO,
  name: 'FFS Profits',
  imagePaths: {
    logo: 'https://storage.googleapis.com/pearly-app/mirror-images/ppo-logo.png',
    favicon: '/ppo.ico',
    providerLogin: 'https://storage.googleapis.com/pearly-app/mirror-images/ppo-practice-login.png',
    patientLogin: 'https://storage.googleapis.com/pearly-app/mirror-images/ppo-patient-login.png'
  },
  color: 'red',
  chartColor: 'red',
  backgroundColor: 'tint2',
  intent: 'danger',
  legalName: 'PPO Profits, LLC',
  email: 'ffsprofits@ppoprofits.com',
  phone: '8006191695',
  timezone: 'CT',
  urls: {
    info: 'https://www.ppoprofits.com',
    provider: 'https://practice.ffsprofits.com',
    member: 'https://plan.ffsprofits.com',
    payment: 'https://bill.care',
    legal: 'https://drive.google.com/file/d/1FLtACpci7sj5Bj0KDzLqgVZNe9xFHAyH/view'
  },
  signupSite: {
    hero: {
      header: 'Become a Member',
      body:
        'If you don’t have dental benefits, our in-house membership plan is for you!  Comprehensive and affordable care directly to you.  Review the details below and enroll in today.',
      cta: 'Sign Up'
    },
    features: {
      header: 'No Insurance? No Problem!',
      body: 'For our loyal patients without dental benefits, we offer in-house membership plans directly to you.',
      first: {
        title: 'Simple',
        body: 'Sign-up in 90-seconds and instantly access your benefits.'
      },
      second: {
        title: 'Comprehensive',
        body: 'Plans built for your oral wellness.'
      },
      third: {
        title: 'Affordable',
        body: 'Transparent pricing and other member benefits.'
      },
      cta: 'Sign Up in 90 Seconds'
    }
  }
}

const localConfig: MirrorConfig = {
  ...pearlyConfig,
  urls: {
    info: 'https://www.pearly.co',
    provider: 'http://localhost:3000',
    member: 'http://localhost:8000',
    payment: 'http://localhost:8000/pay',
    legal: 'https://www.pearly.co/legal/links/'
  }
}

const stagingConfig: MirrorConfig = {
  ...pearlyConfig,
  urls: {
    info: 'https://www.pearly.co',
    provider: 'https://staging-app.pearly.co',
    member: 'https://staging-member.pearly.co',
    payment: 'https://staging-member.pearly.co/pay',
    legal: 'https://www.pearly.co/legal/links/'
  }
}

const experimentalConfig: MirrorConfig = {
  ...pearlyConfig,
  urls: {
    info: 'https://www.pearly.co',
    provider: 'https://experimental-app.pearly.co',
    member: 'https://experimental-member.pearly.co',
    payment: 'https://experimental-member.pearly.co/pay',
    legal: 'https://www.pearly.co/legal/links/'
  }
}

const testConfig: MirrorConfig = {
  ...pearlyConfig,
  urls: {
    info: 'https://www.pearly.co',
    provider: 'https://test-app.pearly.co',
    member: 'https://test-member.pearly.co',
    payment: 'https://test-member.pearly.co/pay',
    legal: 'https://www.pearly.co/legal/links/'
  }
}

const configMap: { [key in ExtendedMirror]: MirrorConfig } = {
  [ExtendedMirror.PEARLY]: pearlyConfig,
  [ExtendedMirror.LOCAL]: localConfig,
  [ExtendedMirror.PPO]: ppoConfig,
  [ExtendedMirror.STAGING]: stagingConfig,
  [ExtendedMirror.EXPERIMENTAL]: experimentalConfig,
  [ExtendedMirror.TEST]: testConfig
}

export const mc = configMap[detectMirror()]
