import gql from 'graphql-tag'

export const GET_INVOICE_SHEET = gql`
  query InvoiceSheet($invoiceId: ID, $subscriptionId: ID, $expandSubscription: Boolean) {
    invoice(
      invoiceId: $invoiceId
      subscriptionId: $subscriptionId
      expandCharge: true
      expandSubscription: $expandSubscription
    ) {
      id
      code
      date
      status
      voidedAt
      amount
      isMembership
      contact {
        id
        email
        phone
        code
        name {
          first
          last
        }
      }
    }
  }
`
