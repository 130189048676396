import { useState } from 'react'

import { GET_PLAID_LINK_BUTTON } from 'lib/graphql/_plaid-link-button'
import { usePlaidLink, PlaidLinkOnSuccess, PlaidLinkOnExit } from 'react-plaid-link'
import { useQuery } from '@apollo/client'
import { toaster } from 'evergreen-ui'

import { Types, Button, ButtonProps } from 'lib'

export interface Props extends Omit<ButtonProps, 'disabled' | 'onClick'> {
  onSuccess: PlaidLinkOnSuccess
  onExit?: PlaidLinkOnExit
  userId?: string | null
}

const PlaidLinkButton = ({ userId, onSuccess, ...props }: Props) => {
  const [isPlaidOpen, setIsPlaidOpen] = useState(false)

  const { loading, data } = useQuery<Types.PlaidLinkButton, Types.PlaidLinkButtonVariables>(GET_PLAID_LINK_BUTTON, {
    variables: {
      userId
    }
  })

  const { open: openPlaidLink, ready: plaidLinkReady } = usePlaidLink({
    token: data ? data.plaidLinkToken : '', // Use placeholder while waiting for data
    onSuccess,
    onExit: () => setIsPlaidOpen(false)
  })

  const initializing = loading || !plaidLinkReady

  return (
    <Button
      {...props}
      disabled={isPlaidOpen}
      onClick={() => {
        if (initializing) toaster.notify('Plaid Link is loading, please try again in a moment')
        else {
          setIsPlaidOpen(true)
          openPlaidLink()
        }
      }}
    />
  )
}

export default PlaidLinkButton
