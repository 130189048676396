import { Card } from 'lib'
import { Link } from 'lib'
import { Spinner } from 'lib'
import { InfoTooltip } from 'lib'

import { Pane, PaneProps, BadgeProps, Badge, Heading } from 'evergreen-ui'

import { Icon } from 'lib'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = PaneProps & {
  label: string
  color: BadgeProps['color']
  stat?: string | number
  link?: {
    label: string
    to: string
  }
  tooltip?: string
  loading?: boolean
  icon?: IconProp
  children?: React.ReactNode
}

const StatCard = ({ label, tooltip, color = 'blue', stat, children, link, loading, icon, ...props }: Props) => (
  <Card justifyContent="center" alignItems="center" paddingX={16} paddingTop={20} paddingBottom={16} {...props}>
    {loading ? (
      <Spinner delay={0} />
    ) : (
      <>
        <Badge color={color} marginBottom={4}>
          {label}
        </Badge>
        <Heading size={800} textTransform="uppercase" marginBottom={-4} display="flex">
          {icon && <Icon icon={icon} marginRight={8} alignSelf="center" size="lg" />}
          {stat}
          {tooltip && <InfoTooltip content={tooltip} marginTop={4} />}
        </Heading>
        <Pane width="100%" display="flex" flexDirection="column" alignItems="center">
          {children}
        </Pane>

        {link && (
          <Link to={link.to} size={300} textTransform="uppercase" marginTop={children ? 0 : 16}>
            {link.label}
          </Link>
        )}
      </>
    )}
  </Card>
)

export default StatCard
