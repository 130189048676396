import styled from 'styled-components/macro'
import { Badge } from 'evergreen-ui'

const TruncatedBadge = styled(Badge)`
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: -3px;
`
export default TruncatedBadge
