import { useState } from 'react'

import { useQuery, useMutation } from '@apollo/client'
import { GET_GUARANTOR_BILLING_VISITS, SET_GUARANTOR_BALANCE_NOTE } from 'graphql/_guarantor-sheet'

import { toaster, Text, Textarea, Pane, Switch, CardProps } from 'evergreen-ui'
import { Types, Card, CardHeader, IconButton, InfoTooltip, Divider, Spinner, BalanceActivityCard } from 'lib'

import chargesIllustration from 'assets/img/charges.svg'

export type Props = { guarantor: Types.GuarantorBilling_contact }

const GuarantorBillingVisits = ({ guarantor }: Props) => {
  const { data } = useQuery<Types.GuarantorBillingVisits, Types.GuarantorBillingVisitsVariables>(
    GET_GUARANTOR_BILLING_VISITS,
    {
      variables: {
        id: guarantor.id
      }
    }
  )

  const activity = data?.contact.balance.activity

  return (
    <>
      <CustomMemoCard guarantor={guarantor} />
      {!activity ? (
        <Spinner delay={0} marginY={48} />
      ) : activity?.length ? (
        <>
          <Divider label="History" marginY={12} />
          {[...activity]
            .sort((a, b) => b.date - a.date)
            .map((el, i) => (
              <BalanceActivityCard key={i} guarantorId={guarantor.id} marginBottom={8} {...el} />
            ))}
        </>
      ) : (
        <Pane display="flex" flexDirection="column" alignItems="center" marginTop={48}>
          <img src={chargesIllustration} height="100px" alt="Invoices Illustration" />
          <Text marginTop={24} size={500} textAlign="center" width="70%">
            This patient has no existing balance history
          </Text>
        </Pane>
      )}
    </>
  )
}

export default GuarantorBillingVisits

const CustomMemoCard = ({ guarantor, ...props }: Props & CardProps) => {
  const { balanceNote, id: contactId } = guarantor

  const [noteToggle, setNoteToggle] = useState(balanceNote !== null)
  const [noteState, setNoteState] = useState(balanceNote ?? '')

  const [saveNote, saveNoteStatus] = useMutation<Types.SetGuarantorBalanceNote, Types.SetGuarantorBalanceNoteVariables>(
    SET_GUARANTOR_BALANCE_NOTE,
    {
      onCompleted: () => {
        if (!noteToggle) setNoteState('')
        toaster.success('Custom memo successfully updated')
      },
      onError: () => {
        if (!noteToggle) setNoteToggle(true)
        toaster.danger('Unable to update custom memo')
      }
    }
  )

  return (
    <Card elevation={0} padding={0} {...props}>
      <CardHeader justifyContent="space-between" withButton={noteToggle} paddingRight={12}>
        <Pane>
          <Text size={500}>Show Statement Memo</Text>
          {noteToggle && (
            <InfoTooltip content="Add a custom memo that the patient will see on the Pay Portal. Once payment has been made, the memo will be removed." />
          )}
        </Pane>
        <Pane display="flex" flexDirection="row" alignItems="center">
          {noteToggle && (
            <IconButton
              icon={['fas', 'save']}
              marginRight={12}
              appearance="primary"
              disabled={noteState === balanceNote || noteState === ''}
              onClick={() => saveNote({ variables: { contactId, balanceNote: noteState } })}
              isLoading={saveNoteStatus.loading}
            />
          )}
          <Switch
            height={20}
            checked={noteToggle}
            onChange={() => {
              if (noteToggle && balanceNote !== null) saveNote({ variables: { contactId, balanceNote: null } })
              setNoteToggle(!noteToggle)
            }}
          />
        </Pane>
      </CardHeader>
      {noteToggle && (
        <Pane padding={16}>
          <Textarea
            width="100%"
            value={noteState}
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setNoteState(e.currentTarget.value)}
            placeholder="Memo text..."
          />
        </Pane>
      )}
    </Card>
  )
}
