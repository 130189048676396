import gql from 'graphql-tag'

export const REFUND_CHARGE = gql`
  mutation RefundCharge($chargeId: ID!, $amount: Int!) {
    refundCharge(chargeId: $chargeId, amount: $amount) {
      id
      refundedAmount
    }
  }
`
