import { Label, Pane } from 'evergreen-ui'

import { Types, DoubleTextInput, TextInputField, TextInput, SelectMenu } from 'lib'

export type Props = {
  practice?: boolean
}

const AddressFields = ({ practice }: Props) => (
  <>
    <TextInputField
      name="name"
      label={practice ? 'Practice Name' : 'Address Name'}
      width="100%"
      type="text"
      placeholder={practice ? `John's Dental` : 'Our Address'}
    />
    <Label marginBottom={4} alignSelf="flex-start">
      Address
    </Label>
    <DoubleTextInput name="street1" half="top" width="100%" type="text" placeholder="1728 Robbins Street" />
    <DoubleTextInput name="street2" half="bottom" width="100%" type="text" placeholder="Suite 10" />
    <TextInput name="city" width="100%" type="text" placeholder="Santa Barbara" />
    <Pane display="flex" justifyContent="space-between" marginBottom={practice ? 16 : 0}>
      <TextInput name="state" type="text" placeholder="CA" marginRight={8} marginBottom={0} />
      <TextInput name="zip" type="text" placeholder="93101" marginLeft={8} marginBottom={0} />
    </Pane>
    {practice && (
      <>
        <TextInputField
          name="phone"
          label="Phone Number"
          isPhone
          width="100%"
          type="text"
          placeholder="(805) 123-4567"
          marginBottom={practice ? 16 : 0}
        />
        <Pane width="100%">
          <Label marginBottom={4} display="block">
            Time Zone
          </Label>
          <SelectMenu
            name="timezone"
            buttonWidth="100%"
            marginBottom={0}
            options={[
              { label: 'Eastern', value: Types.Timezone.EASTERN },
              { label: 'Central', value: Types.Timezone.CENTRAL },
              { label: 'Mountain', value: Types.Timezone.MOUNTAIN },
              { label: 'Pacific', value: Types.Timezone.PACIFIC },
              { label: 'Arizona', value: Types.Timezone.ARIZONA },
              { label: 'Alaska', value: Types.Timezone.ALASKA },
              { label: 'Hawaii-Aleutian', value: Types.Timezone.HAWAII }
            ]}
          />
        </Pane>
      </>
    )}
  </>
)

export default AddressFields
