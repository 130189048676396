import { Pane, Text, Strong, Position } from 'evergreen-ui'
import { Icon, IconProps } from 'lib'
import { useModal } from 'components/modal-provider'

export interface Props extends Omit<IconProps, 'icon' | 'ref' | 'position'> {
  isGroup?: boolean
  position?: Position
}

const RoleTooltip = ({ isGroup = false, position = Position.TOP_RIGHT, ...props }: Props) => {
  const showCornerDialog = useModal('corner')

  return (
    <Icon
      cursor="help"
      opacity={0.8}
      icon={['fas', 'info-circle']}
      {...props}
      onClick={() =>
        showCornerDialog({
          position,
          confirmLabel: 'Close',
          hasCancel: false,
          containerProps: { zIndex: 20 },
          title: isGroup ? 'Group-Wide User Access Roles' : 'Office-Specific User Access Roles',
          body: isGroup ? groupContent : officeContent
        })
      }
    />
  )
}

const officeContent = (
  <Pane display="grid" gridGap={16}>
    <Pane display="flex" flexDirection="column">
      <Strong>Office Admin</Strong>
      <Text>
        Full access to all features and functionality for individual offices, including the ability to edit bank payout
        details for each office.
      </Text>
    </Pane>
    <Pane display="flex" flexDirection="column">
      <Strong>Office Standard</Strong>
      <Text>
        Full access to all features and functionality for individual offices, excluding the ability to edit bank payout
        details for each office.
      </Text>
    </Pane>
    <Pane display="flex" flexDirection="column">
      <Strong>Office Basic</Strong>
      <Text>
        Access to patient-specific activities including sending notifications, plan enrollment, stopping outreach, and
        charging patients. No access to office-specific settings including auto-outreach rules, payment plan schedules,
        membership pricing, practice details and branding. No ability to issue patient payment refunds.
      </Text>
    </Pane>
  </Pane>
)

const groupContent = (
  <Pane display="grid" gridGap={16}>
    <Pane display="flex" flexDirection="column">
      <Strong>Group Admin</Strong>
      <Text>
        Ability to see Group Dashboard views and has full access to all features and functionality for each office
        account within the group, including the ability to edit bank payout details for each office.
      </Text>
    </Pane>
    <Pane display="flex" flexDirection="column">
      <Strong>Group Standard</Strong>
      <Text>
        Ability to see Group Dashboard views and has full access to all features and functionality for each office
        account within the group, excluding the ability to edit bank payout details for each office.
      </Text>
    </Pane>
  </Pane>
)

export default RoleTooltip
