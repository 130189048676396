import styled from 'styled-components/macro'

import { useMutation } from '@apollo/client'
import { UPSERT_INSTALLMENT_TEMPLATE, DELETE_INSTALLMENT_TEMPLATE } from 'graphql/_installment-template-sheet'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Label, Strong, Text, Heading, toaster } from 'evergreen-ui'
import {
  Types,
  Card,
  CardHeader,
  Button,
  Switch,
  FormError,
  TextInputField,
  SelectMenu,
  colors,
  TextInput,
  InfoTooltip,
  calculateInstallmentAmounts,
  toDollarsFromCents,
  prettyInterval
} from 'lib'

import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'
import InstallmentTemplateToggle from 'components/installment-template-toggle'
import { useState } from 'react'
import BucketSelector from 'components/bucket-selector'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  installmentTemplate?: Types.PayOverTimeInstallments_account_installmentTemplates
}

const InstallmentTemplateSheet = ({ isShown, setIsShown, installmentTemplate: template }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const global = useGlobal()

  const [upsertTemplate, upsertStatus] = useMutation<
    Types.UpsertInstallmentTemplate,
    Types.UpsertInstallmentTemplateVariables
  >(UPSERT_INSTALLMENT_TEMPLATE, {
    update: (cache, { data }) => {
      // return if performing update (Apollo automatically updates cache)
      if (template || !data) return

      cache.modify({
        id: cache.identify({ id: global.account.id, __typename: 'Account' }),
        fields: {
          installmentTemplates(cachedInstallmentTemplates) {
            return [
              ...cachedInstallmentTemplates,
              { __ref: cache.identify({ id: data.upsertInstallmentTemplate.id, __typename: 'InstallmentTemplate' }) }
            ]
          }
        }
      })
    },
    onCompleted: () => {
      toaster.success(`Installment schedule successfully ${template ? 'updated' : 'created'}!`)
      if (!template) setIsShown(false)
    },
    onError: () => toaster.danger(`Unable to ${template ? 'update' : 'create'} installment schedule`)
  })

  const [deleteTemplate, deleteStatus] = useMutation<
    Types.DeleteInstallmentTemplate,
    Types.DeleteInstallmentTemplateVariables
  >(DELETE_INSTALLMENT_TEMPLATE, {
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          id: cache.identify({ id: global.account.id, __typename: 'Account' }),
          fields: {
            installmentTemplates(cachedInstallmentTemplates) {
              return cachedInstallmentTemplates.filter(
                ({ __ref }: { __ref: string }) =>
                  __ref !== cache.identify({ id: data.deleteInstallmentTemplate.id, __typename: 'InstallmentTemplate' })
              )
            }
          }
        })

        cache.gc()
      }
    },
    onCompleted: () => {
      setIsShown(false)
      toaster.success(`Payment plan successfully deleted!`)
    },
    onError: () => toaster.danger('Unable to delete payment plan')
  })

  const [bucketState, setBucketState] = useState({
    allowL30: template ? template.balanceFilter.allowL30 : true,
    allowL60: template ? template.balanceFilter.allowL60 : true,
    allowL90: template ? template.balanceFilter.allowL90 : true,
    allowG90: template ? template.balanceFilter.allowG90 : true
  })

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      <Formik
        initialValues={
          template
            ? {
                ...template,
                adminFee: template.adminFee / 100,
                downPayment: template.downPayment / 100,
                maxBalance: template.balanceFilter.maxBalance / 100,
                minBalance: template.balanceFilter.minBalance / 100,
                intervalString: `${template.interval} ${template.intervalType}`
              }
            : {
                name: 'Pay in 6 Interest-Free Installments',
                intervalString: '4 WEEK',
                periods: 6,
                minBalance: 0,
                maxBalance: 0,
                allowCC: true,
                allowACH: true,
                adminFee: 0,
                downPayment: 0
              }
        }
        onSubmit={({ ...fields }) =>
          showConfirmDialog({
            body: `Are you sure you want to ${template ? 'update' : 'create'} this payment plan?`,
            onConfirm: () => {
              const [interval, intervalType] = fields.intervalString.split(' ')

              return upsertTemplate({
                variables: {
                  id: template ? template.id : null,
                  ...fields,
                  balanceFilter: {
                    maxBalance: fields.maxBalance * 100,
                    minBalance: fields.minBalance * 100,

                    ...bucketState
                  },
                  adminFee: fields.adminFee * 100,
                  downPayment: fields.downPayment * 100,
                  interval: +interval,
                  intervalType: intervalType as Types.InstallmentIntervalType
                }
              })
            }
          })
        }
        validationSchema={Yup.object({
          name: Yup.string().min(4, 'Name must be at least 4 characters').required('Name is required'),
          minBalance: Yup.number().min(0, 'Field must be 0 or greater').required('Required field is missing'),
          maxBalance: Yup.number().min(0, 'Field must be 0 or greater').required('Required field is missing'),

          adminFee: Yup.number().min(0, 'Field must be 0 or greater').required('Required field is missing'),
          downPayment: Yup.number().min(0, 'Field must be 0 or greater').required('Required field is missing')
        })}
      >
        {({ values }) => {
          const { enrollmentAmount, installmentAmount } = calculateInstallmentAmounts({
            amount: 100000,
            installmentTemplate: {
              adminFee: values.adminFee * 100,
              downPayment: values.downPayment * 100,
              periods: values.periods
            }
          })

          const [intervalAsString, intervalTypeAsString] = values.intervalString.split(' ')
          const interval = +intervalAsString
          const intervalType = intervalTypeAsString as Types.InstallmentIntervalType

          return (
            <Form style={{ height: '100%' }}>
              <SheetLayout>
                <CardHeader justifyContent="space-between" alignItems="center" overflow="hidden">
                  {/* Necessary for text truncation */}
                  <Pane maxWidth={template ? '254px' : undefined}>
                    <Label>In-House Payment Plan</Label>
                    <Heading size={600} height={24} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                      {values.name}
                    </Heading>
                  </Pane>

                  {template && <InstallmentTemplateToggle installmentTemplate={template} marginLeft={4} />}
                </CardHeader>

                <Pane gridArea="body" overflow="auto" background="blueTint">
                  <Pane display="grid" gap={16} padding={16}>
                    <Card backgroundColor="white" elevation={0} padding={0}>
                      <CardHeader>
                        <Heading>Plan Name</Heading>
                      </CardHeader>
                      <Pane padding={16}>
                        <TextInputField
                          height={40}
                          name="name"
                          label="Display Name (shown to patients at checkout)"
                          marginBottom={0}
                        />
                      </Pane>
                    </Card>

                    <Card backgroundColor="white" elevation={0} padding={0}>
                      <CardHeader>
                        <Heading>Plan Schedule</Heading>
                      </CardHeader>
                      <Pane padding={16}>
                        <Heading size={300} color={colors.blue.base} textTransform="uppercase" marginBottom={4}>
                          Set schedule - payment every
                        </Heading>
                        <Pane display="flex" alignItems="center" marginTop={8} marginBottom={16}>
                          <SelectMenu
                            name="intervalString"
                            height={40}
                            width={96}
                            marginBottom={0}
                            options={[
                              ...(['staging', 'experimental', 'development', 'test'].includes(
                                window._env_.REACT_APP_ENV
                              )
                                ? [{ value: '1 HOUR', label: 'Hour' }]
                                : []),
                              { value: '1 WEEK', label: 'Week' },
                              { value: '2 WEEK', label: '2 Weeks' },
                              { value: '4 WEEK', label: '4 Weeks' },
                              { value: '6 WEEK', label: '6 Weeks' },
                              { value: '8 WEEK', label: '8 Weeks' }
                            ]}
                          />
                          <Text marginX={8} size={500}>
                            in
                          </Text>
                          <SelectMenu
                            name="periods"
                            height={40}
                            width={136}
                            marginBottom={0}
                            options={[
                              { value: 2, label: '2 Installments' },
                              { value: 3, label: '3 Installments' },
                              { value: 4, label: '4 Installments' },
                              { value: 5, label: '5 Installments' },
                              { value: 6, label: '6 Installments' },
                              { value: 7, label: '7 Installments' },
                              { value: 8, label: '8 Installments' },
                              { value: 9, label: '9 Installments' },
                              { value: 10, label: '10 Installments' },
                              { value: 11, label: '11 Installments' },
                              { value: 12, label: '12 Installments' },
                              { value: 18, label: '18 Installments' },
                              { value: 24, label: '24 Installments' }
                            ]}
                          />
                        </Pane>

                        <Heading size={300} color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                          Payment Settings
                        </Heading>

                        <Pane display="grid" gap={16} gridAutoFlow="columns" gridTemplateColumns="1fr 1fr">
                          <Pane width="100%">
                            <Label display="block" marginBottom="4px">
                              Enrollment Fee
                              <InfoTooltip content="One-time plan administration charged to patients and paid to your practice. This is an optional fee that allows you to charge more for payment plans." />
                            </Label>
                            <SelectMenu
                              name="adminFee"
                              height={40}
                              buttonWidth="100%"
                              marginBottom={0}
                              options={[
                                { value: 0, label: '0%' },

                                { value: 1, label: '1%' },
                                { value: 2, label: '2%' },
                                { value: 3, label: '3%' },
                                { value: 4, label: '4%' },
                                { value: 5, label: '5%' },
                                { value: 6, label: '6%' },
                                { value: 7, label: '7%' },
                                { value: 8, label: '8%' },
                                { value: 9, label: '9%' },
                                { value: 10, label: '10%' },
                                { value: 11, label: '11%' },
                                { value: 12, label: '12%' },
                                { value: 13, label: '13%' },
                                { value: 14, label: '14%' },
                                { value: 15, label: '15%' },
                                { value: 16, label: '16%' },
                                { value: 17, label: '17%' },
                                { value: 18, label: '18%' },
                                { value: 19, label: '19%' },
                                { value: 20, label: '20%' }
                              ]}
                            />
                          </Pane>
                          <Pane width="100%">
                            <Label display="block" marginBottom="4px">
                              Down Payment (%)
                              <InfoTooltip content="The down payment allows you to collect a larger percentage of the balance upfront.  This is in addition to the first installment amount." />
                            </Label>
                            <TextInput height={40} name="downPayment" marginBottom={0} />
                          </Pane>
                        </Pane>
                      </Pane>

                      <Pane
                        padding={16}
                        borderTop={`solid 1px ${colors.border.muted}`}
                        borderBottom={`solid 1px ${colors.border.muted}`}
                      >
                        <Heading size={300} color={colors.blue.base} textTransform="uppercase" marginBottom={4}>
                          Example - $1,000
                        </Heading>
                        <Text fontStyle="italic">
                          Patient will pay {toDollarsFromCents(enrollmentAmount, 2)} on enrollment, then pay{' '}
                          {toDollarsFromCents(installmentAmount, 2)} every {prettyInterval(interval, intervalType)}, for
                          the next {prettyInterval((values.periods - 1) * interval, intervalType)}.
                        </Text>
                      </Pane>
                    </Card>

                    <Card backgroundColor="white" elevation={0} padding={0}>
                      <CardHeader>
                        <Heading>Pay Portal Defaults</Heading>
                      </CardHeader>
                      <Pane padding={16} borderBottom={`solid 1px ${colors.border.muted}`}>
                        <Text>
                          Show this as a payment option on digital statements to patients who satisfy the below
                          criteria. Can be adjusted on a patient-by- patient basis.
                        </Text>
                      </Pane>
                      <Pane padding={16} display="grid" gap={6}>
                        <Heading size={300} color={colors.blue.base} textTransform="uppercase" marginBottom={4}>
                          Offer this plan if...
                        </Heading>

                        <Pane display="flex" alignItems="center" justifyContent="space-between">
                          <Pane display="flex">
                            <Strong size={500} marginRight={4} width={120}>
                              Patient Balance
                            </Strong>
                            <Text size={500}>between </Text>
                          </Pane>
                          <Pane display="flex" alignItems="center">
                            <TextInput name="minBalance" width={52} height={32} marginBottom={0} />
                            <Text size={500} display="inline-block" marginX={8}>
                              -
                            </Text>
                            <TextInput name="maxBalance" width={52} height={32} marginBottom={0} />
                          </Pane>
                        </Pane>
                        <Heading size={300} color={colors.blue.base} marginBottom={4}>
                          AND
                        </Heading>
                        <Pane display="flex" flexDirection="column" justifyContent="space-between" rowGap={16}>
                          <Pane display="flex" alignItems="center">
                            <Strong size={500} marginRight={4}>
                              Days Past Due
                            </Strong>
                            <Text size={500}>bucket includes:</Text>
                          </Pane>
                          <BucketSelector state={bucketState} onSelect={setBucketState} />
                        </Pane>
                      </Pane>
                      <Pane backgroundColor={colors.border.muted} marginY={4} height={1} />
                      <Pane padding={16} display="grid" gap={12}>
                        <Heading size={300} color={colors.blue.base} textTransform="uppercase">
                          Allow Patients to Pay With
                        </Heading>
                        <Pane>
                          <Pane display="flex" justifyContent="space-between">
                            <Text size={500}>Credit/Debit</Text>
                            <Switch name="allowCC" marginBottom={8} />
                          </Pane>
                          <Pane display="flex" justifyContent="space-between">
                            <Text size={500}>eCheck/ACH</Text>
                            <Switch name="allowACH" marginBottom={0} />
                          </Pane>
                        </Pane>
                        <FormError marginTop={0} />
                      </Pane>
                    </Card>
                  </Pane>
                </Pane>
                <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
                  {template ? (
                    <Pane display="flex" justifyContent="space-between">
                      <Button
                        isLoading={deleteStatus.loading}
                        disabled={upsertStatus.loading}
                        onClick={() => {
                          showConfirmDialog({
                            body: 'Are you sure you want to delete this installment schedule?',
                            onConfirm: () => deleteTemplate({ variables: { id: template.id } }),
                            intent: 'danger'
                          })
                        }}
                        appearance="minimal"
                        intent="danger"
                        height={48}
                        justifyContent="center"
                      >
                        Delete
                      </Button>
                      <Button
                        isLoading={upsertStatus.loading}
                        disabled={deleteStatus.loading}
                        type="submit"
                        appearance="primary"
                        height={48}
                        justifyContent="center"
                      >
                        Save
                      </Button>
                    </Pane>
                  ) : (
                    <Button
                      isLoading={upsertStatus.loading}
                      type="submit"
                      appearance="primary"
                      height={48}
                      width="100%"
                      justifyContent="center"
                      iconBefore={['fas', 'plus']}
                    >
                      Create Payment Plan
                    </Button>
                  )}
                </Pane>
              </SheetLayout>
            </Form>
          )
        }}
      </Formik>
    </SideSheet>
  )
}

export default InstallmentTemplateSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
