import 'graphql'
import gql from 'graphql-tag'

export const GET_MERGE_GUARANTORS_DIALOG = gql`
  query MergeGuarantorsDialog($legacyCode: String!, $currentCode: String!) {
    legacy: contactByCode(code: $legacyCode) {
      id
      accountId
      isGuarantor
      code
      name {
        first
        last
      }
      dependents {
        id
        manual_guarantorId
        stripe {
          subscriptionId
        }
      }
    }
    current: contactByCode(code: $currentCode) {
      id
      accountId
      isGuarantor
      code
      name {
        first
        last
      }
      stripe {
        customerId
        subscriptionId
      }
      dependents {
        id
        stripe {
          subscriptionId
        }
      }
    }
  }
`

export const MERGE_GUARANTORS = gql`
  mutation MergeGuarantors($legacyId: ID!, $currentId: ID!) {
    mergeGuarantors(legacyId: $legacyId, currentId: $currentId) {
      id
    }
  }
`
