import gql from 'graphql-tag'

export const GET_CHARGE_GUARANTOR_CARD = gql`
  query ChargeGuarantorCard($id: ID!) {
    contact(id: $id) {
      id
      name {
        first
        last
      }
      email
      balance {
        metrics {
          id
          patientPortion
        }
      }
      account {
        id
        feeSchedule {
          id
          payments_patient_ach
          payments_patient_cc
          payments_patient_30
        }
      }
      stripe {
        defaultSource {
          id
          isCredit
          institution
          last4
        }
      }
    }
  }
`

export const COMPLETE_GUARANTOR_CHARGE = gql`
  mutation CompleteGuarantorCharge($guarantorId: ID!, $amount: Int!, $sendReceipt: Boolean!) {
    chargeGuarantor(guarantorId: $guarantorId, amount: $amount, sendReceipt: $sendReceipt) {
      id
      balanceAmount
    }
  }
`
