import { forwardRef } from 'react'

import { useField } from 'formik'

import { TextInput as EvergreenTextInput, TextInputProps, Pane } from 'evergreen-ui'

import { Icon } from 'lib'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = TextInputProps & {
  name: string
  height?: number
  icon?: IconProp
  loading?: boolean
  isPhone?: boolean
}

const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ icon, width, flex, marginLeft, marginRight, loading, isPhone, ...props }, ref) => {
    const [field, meta] = useField(props.name)
    const { height = 48 } = props
    if (!!icon || loading) props.paddingLeft = height
    return (
      <>
        <Pane
          position="relative"
          width={width}
          flex={flex}
          marginBottom={typeof props.marginBottom === 'number' ? props.marginBottom : 16}
          marginLeft={marginLeft}
          marginRight={marginRight}
        >
          {icon && (
            <Icon
              icon={icon}
              position="absolute"
              size="lg"
              lineHeight={`${height}px`}
              left={`${height && height / 3}px`}
              opacity={0.7}
            />
          )}
          {loading && (
            <Icon
              icon={['fas', 'circle-notch']}
              spin
              position="absolute"
              size="lg"
              lineHeight={`${height}px`}
              left={`${height && height / 3}px`}
              opacity={0.7}
            />
          )}
          <EvergreenTextInput
            ref={ref}
            width="100%"
            height={48}
            {...field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (isPhone) e.target.value = e.target.value.replace(/\D/g, '')
              field.onChange(e)
            }}
            isInvalid={meta.touched && !!meta.error}
            {...props}
          />
        </Pane>
      </>
    )
  }
)

export default TextInput
