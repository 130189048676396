import { useState, useContext, createContext, useCallback, useEffect } from 'react'

import { useUser } from 'reactfire'

import { User, IdTokenResult } from 'firebase/auth'
import { Types } from 'lib'

export type Props = {
  children: React.ReactNode
}

type ContextType = {
  tokenResult?: IdTokenResult | null
  refreshToken: () => Promise<void>
}

const Context = createContext({} as ContextType)
export const useToken = () => useContext(Context)

export const TokenProvider = ({ children }: Props) => {
  const { data: user } = useUser()

  const [tokenResult, setTokenResult] = useState(undefined as IdTokenResult | null | undefined)

  const setTokenResultFromFirebase = async (user: User | null) => {
    if (!user) return setTokenResult(null)
    const tokenResult = await user.getIdTokenResult(true)
    setTokenResult(tokenResult)
  }

  useEffect(() => {
    setTokenResultFromFirebase(user)
  }, [user])

  const refreshToken = useCallback(async () => {
    await setTokenResultFromFirebase(user)
  }, [user])

  return <Context.Provider value={{ tokenResult, refreshToken }}>{children}</Context.Provider>
}

export const parseUserRoleFromToken = (value: any): Types.UserRole => {
  if (Object.values(Types.UserRole).includes(value)) {
    return value as Types.UserRole
  } else {
    throw new Error(`Invalid UserRole: ${value}`)
  }
}
