import { useState } from 'react'
import styled from 'styled-components/macro'

import { Pane, Heading, Text, Textarea } from 'evergreen-ui'
import { Types, Button, Card, CardHeader, InfoTooltip, IconButton } from 'lib'
import { useModal } from 'components/modal-provider'

export type Props = {
  plan: Types.PlanSheet_plan
  update: [Types.UpdatePlanProp, Types.UpdatePlanStatusProp]
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const PlanTerms = ({ plan, update: [updatePlan, updatePlanStatus], dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')
  const showContractSheet = useModal('contract')

  const [termsState, setTermsState] = useState(plan.additionalTerms ?? [''])

  return (
    <SheetBodyLayout>
      <Pane gridArea="body" background="blueTint" overflow="auto">
        <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
          <CardHeader>
            <Heading size={500}>Plan Details</Heading>
          </CardHeader>
          <Pane padding={24} display="flex">
            <Text size={500}>
              View your full plan details, terms, and disclosures that members agree to upon purchase.
            </Text>
            <Button height={48} appearance="primary" onClick={() => showContractSheet({ planId: plan.id })}>
              View
            </Button>
          </Pane>
        </Card>
        <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
          <CardHeader>
            <Heading size={500}>
              Add Additional Terms, Exclusions, & Disclosures
              <InfoTooltip content="Please review the terms of your plan with legal representation and add additional language in the box below." />
            </Heading>
          </CardHeader>
          <Pane padding={16} display="flex" flexDirection="column">
            {termsState.map((_el, i) => (
              <Pane display="flex" marginBottom={16} key={i}>
                <Textarea
                  placeholder="Enter details here..."
                  value={termsState[i]}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const newTermsState = [...termsState]
                    newTermsState[i] = event.target.value
                    setTermsState(newTermsState)
                  }}
                  rows={4}
                  padding={13}
                  marginRight={8}
                  marginBottom={0}
                />
                <IconButton
                  icon={['fas', 'times']}
                  intent="danger"
                  appearance="minimal"
                  onClick={() => setTermsState(termsState.filter((el, j) => i !== j))}
                />
              </Pane>
            ))}

            <Button
              iconBefore={['fas', 'plus']}
              appearance="minimal"
              alignSelf="center"
              onClick={() => setTermsState([...termsState, ''])}
            >
              Add Section
            </Button>
          </Pane>
        </Card>
      </Pane>
      <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="flex-end">
        <Button
          isLoading={updatePlanStatus.called && updatePlanStatus.loading}
          type="submit"
          appearance="primary"
          height={48}
          justifyContent="center"
          onClick={() =>
            showConfirmDialog({
              body: 'Are you sure you want to save this plan?',
              onConfirm: () => {
                updatePlan({ variables: { id: plan.id, additionalTerms: termsState.filter((terms) => terms.length) } })
              }
            })
          }
        >
          Save
        </Button>
      </Pane>
    </SheetBodyLayout>
  )
}

export default PlanTerms

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
