import { EnrollMemberCard } from 'lib'

export type Props = {
  guarantorId: string
}

const GuarantorEnrollMember = ({ guarantorId }: Props) => {
  return <EnrollMemberCard contactId={guarantorId} margin={16} elevation={0} />
}

export default GuarantorEnrollMember
