import gql from 'graphql-tag'

export const GET_ENROLL_INSTALLMENTS_CARD = gql`
  query EnrollInstallmentsCard($id: ID!) {
    contact(id: $id) {
      id
      name {
        first
        last
      }
      dob {
        day
        month
        year
      }
      balance {
        metrics {
          patientPortion
        }
      }
      stripe {
        defaultSource {
          id
        }
      }
    }
  }
`

export const ENROLL_INSTALLMENTS = gql`
  mutation EnrollInstallments($contactId: ID!, $amount: Int!, $installmentTemplateId: ID!) {
    enrollInstallments(contactId: $contactId, amount: $amount, installmentTemplateId: $installmentTemplateId) {
      id
      grossAmount
    }
  }
`
