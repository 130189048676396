import { forwardRef } from 'react'

import { Pane, TextInput, TextInputProps, IntentTypes, ButtonAppearance } from 'evergreen-ui'
import { Button } from 'lib'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = TextInputProps & {
  name: string
  label: string
  isButtonLoading?: boolean
  buttonIntent?: IntentTypes
  buttonOnClick?: () => void
  buttonIconBefore?: IconProp
  buttonIconAfter?: IconProp
  autoFocus?: boolean
  buttonType?: 'submit' | 'reset' | 'button'
  buttonAppearance?: ButtonAppearance
}

const TextInputWithButton = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      buttonIntent,
      isButtonLoading,
      buttonAppearance,
      buttonOnClick,
      buttonIconBefore,
      buttonIconAfter,
      autoFocus,
      width,
      buttonType,
      ...props
    },
    ref
  ) => {
    const { height = 48, maxWidth } = props

    return (
      <Pane position="relative" display="flex" width={width} maxWidth={maxWidth}>
        <TextInput
          {...props}
          ref={ref}
          borderTopRightRadius={0}
          borderBottomRightRadius={0}
          marginRight={-1}
          flex={1}
          position="relative"
          zIndex={1}
          height={height}
        />
        <Button
          isLoading={isButtonLoading}
          intent={buttonIntent}
          appearance={buttonAppearance}
          onClick={buttonOnClick}
          borderTopLeftRadius={0}
          borderBottomLeftRadius={0}
          iconBefore={buttonIconBefore}
          iconAfter={buttonIconAfter}
          height={height}
          autoFocus={autoFocus}
          type={buttonType}
        >
          {label}
        </Button>
      </Pane>
    )
  }
)

export default TextInputWithButton
