import { CardElement as StripeCardElement } from '@stripe/react-stripe-js'
import { Pane } from 'evergreen-ui'

import { colors, fontFamily } from 'lib'

const CardElement = () => (
  <Pane width="100%" height="32px" display="flex" flexDirection="column" justifyContent="center">
    <StripeCardElement
      options={{
        style: {
          base: {
            fontFamily,
            fontSize: '16px',
            color: colors.neutral.base
          },
          invalid: {
            color: colors.red.base
          }
        }
      }}
    />
  </Pane>
)

export default CardElement
