import { useState } from 'react'
import { Pane } from 'evergreen-ui'
import { Button, Types } from 'lib'

import VerifyContactAddressCard from 'components/verify-contact-address-card'

type Props = {
  state: Types.SendNotificationState
  guarantor: Types.GetSendNotificationCard['contact']
  onBack: () => void
  onNext: (lobAddressId: string) => void
}

const SendNotificationVerifyAddress = ({ guarantor, onBack, onNext }: Props) => {
  const [addressIdState, setAddressIdState] = useState<string | null>(guarantor.verifiedAddress?.id ?? null)

  return (
    <>
      <Pane display="flex" flexDirection="column" alignItems="center" gap={8}>
        <VerifyContactAddressCard
          contactId={guarantor.id}
          hasWrapperCard={false}
          onVerifyStart={() => setAddressIdState(null)}
          onVerified={(addressId) => setAddressIdState(addressId)}
        />
      </Pane>
      <Pane display="flex" justifyContent="space-between" width="100%" elevation={0} padding={16}>
        <Button type="button" justifyContent="center" height={48} onClick={onBack} appearance="minimal">
          Back
        </Button>
        {addressIdState && (
          <Button
            type="button"
            height={48}
            onClick={() => onNext(addressIdState)}
            appearance="primary"
            rightChevron
            paddingRight={48}
          >
            Use Address
          </Button>
        )}
      </Pane>
    </>
  )
}

export default SendNotificationVerifyAddress
