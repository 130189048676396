import { useState } from 'react'
import styled from 'styled-components/macro'

import { useMutation, useQuery } from '@apollo/client'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Strong, Text, Heading, toaster } from 'evergreen-ui'
import { Types, Card, CardHeader, Button, FormError, colors, TextInput, Spinner } from 'lib'

import { useModal } from 'components/modal-provider'

import BucketSelector from 'components/bucket-selector'
import { GET_BALANCE_FILTER, UPDATE_BALANCE_FILTER } from 'graphql/_balance-filter-sheet'
import { pick } from 'lodash'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  title: string
  intro: React.ReactNode
  filterId: string
}

const BalanceFilterSheet = ({ isShown, setIsShown, title, intro, filterId }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const { data, loading } = useQuery<Types.BalanceFilter, Types.BalanceFilterVariables>(GET_BALANCE_FILTER, {
    variables: { filterId }
  })

  const balanceFilter = data?.balanceFilter

  const [bucketState, setBucketState] = useState<{
    allowL30: boolean
    allowL60: boolean
    allowL90: boolean
    allowG90: boolean
  } | null>(null)

  const [update, updateStatus] = useMutation<Types.UpdateBalanceFilter, Types.UpdateBalanceFilterVariables>(
    UPDATE_BALANCE_FILTER,
    {
      onCompleted: () => toaster.success('Rule updated'),
      onError: () => toaster.danger('Unable to update rule, please contact Support'),
      update: (cache) => {
        cache.evict({ fieldName: 'balances' })
      }
    }
  )

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      {({ close }: { close: () => void }) =>
        loading || !balanceFilter ? (
          <Pane height="100%" display="flex" alignItems="center">
            <Spinner delay={0} />
          </Pane>
        ) : (
          <Formik
            initialValues={{
              ...balanceFilter,
              minBalance: balanceFilter.minBalance / 100,
              maxBalance: balanceFilter.maxBalance / 100
            }}
            onSubmit={({ ...fields }) =>
              showConfirmDialog({
                body: 'Are you sure you want to update this rule?  It will apply immediately.',
                onConfirm: async () => {
                  const bucketFields = pick(bucketState ?? balanceFilter, [
                    'allowL30',
                    'allowL60',
                    'allowL90',
                    'allowG90'
                  ])

                  await update({
                    variables: {
                      filterId,
                      balanceFilter: {
                        maxBalance: fields.maxBalance * 100,
                        minBalance: fields.minBalance * 100,
                        ...bucketFields
                      }
                    }
                  })
                  close()
                }
              })
            }
            validationSchema={Yup.object({
              minBalance: Yup.number()
                .min(0, 'Field must be 0 or greater')
                .required('Required field is missing')
                .test('is-greater', 'Minimum balance can not exceed the maximum balance.', function (value) {
                  const { maxBalance } = this.parent
                  return value <= maxBalance
                }),
              maxBalance: Yup.number().min(0, 'Field must be 0 or greater').required('Required field is missing')
            })}
          >
            <Form style={{ height: '100%' }}>
              <SheetLayout>
                <CardHeader justifyContent="space-between" alignItems="center" overflow="hidden">
                  <Heading size={600} height={24} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    {title}
                  </Heading>
                </CardHeader>

                <Pane gridArea="body" overflow="auto" background="blueTint">
                  <Pane display="grid" gap={16} padding={16}>
                    <Card backgroundColor="white" elevation={0} padding={0}>
                      <Pane padding={16} borderBottom={`solid 1px ${colors.border.muted}`}>
                        {intro}
                      </Pane>
                      <Pane padding={16} display="grid" gap={6}>
                        <Pane display="flex" alignItems="center" justifyContent="space-between">
                          <Pane display="flex">
                            <Strong size={500} marginRight={4} width={120}>
                              Patient Balance
                            </Strong>
                            <Text size={500}>between </Text>
                          </Pane>
                          <Pane display="flex" alignItems="center">
                            <TextInput name="minBalance" width={52} height={32} marginBottom={0} />
                            <Text size={500} display="inline-block" marginX={8}>
                              -
                            </Text>
                            <TextInput name="maxBalance" width={52} height={32} marginBottom={0} />
                          </Pane>
                        </Pane>
                        <Heading size={300} color={colors.blue.base} marginBottom={4}>
                          AND
                        </Heading>
                        <Pane display="flex" flexDirection="column" justifyContent="space-between" rowGap={16}>
                          <Pane display="flex" alignItems="center">
                            <Strong size={500} marginRight={4}>
                              Days Past Due
                            </Strong>
                            <Text size={500}>bucket includes:</Text>
                          </Pane>
                          <BucketSelector state={bucketState ?? balanceFilter} onSelect={setBucketState} />
                        </Pane>
                        <FormError marginTop={0} />
                      </Pane>
                    </Card>
                  </Pane>
                </Pane>
                <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
                  <Button
                    isLoading={updateStatus.loading}
                    type="submit"
                    height={48}
                    width="100%"
                    justifyContent="center"
                  >
                    Update + Save Rules
                  </Button>
                </Pane>
              </SheetLayout>
            </Form>
          </Formik>
        )
      }
    </SideSheet>
  )
}

export default BalanceFilterSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
