import gql from 'graphql-tag'

const fragments = {
  sendNotificationTemplateFields: gql`
    fragment sendNotificationTemplateFields on NotificationTemplate {
      id
      channel
      layout
      isBilling
      name
      body
      subject
      title
      buttonLabel
      buttonUrl
      signature
      createdAt
    }
  `
}

export const GET_SEND_NOTIFICATION_CARD = gql`
  query GetSendNotificationCard($contactId: ID!) {
    contact(id: $contactId) {
      id
      phone
      email
      code
      name {
        first
        last
      }
      balance {
        metrics {
          patientPortion
        }
      }
      account {
        id
        name
        code
        programName
        publicReplyPhone
        notificationTemplates {
          ...sendNotificationTemplateFields
        }
        returnAddress {
          id
          verifiedAddress {
            id
          }
        }
      }
      address {
        street1
        street2
        city
        state
        zip
      }

      verifiedAddress {
        id
      }
    }
    account {
      id
    }
  }
  ${fragments.sendNotificationTemplateFields}
`

export const DELIVER_EMAIL_NOTIFICATION = gql`
  mutation DeliverEmailNotification(
    $contactId: ID!
    $templateId: ID!
    $subject: String!
    $body: String!
    $signature: String!
    $buttonLabel: String
    $buttonUrl: String
  ) {
    deliverEmailNotification(
      contactId: $contactId
      templateId: $templateId
      subject: $subject
      body: $body
      signature: $signature
      buttonLabel: $buttonLabel
      buttonUrl: $buttonUrl
    ) {
      id
    }
  }
`

export const DELIVER_SMS_NOTIFICATION = gql`
  mutation DeliverSmsNotification($contactId: ID!, $templateId: ID!, $body: String!) {
    deliverSmsNotification(contactId: $contactId, templateId: $templateId, body: $body) {
      id
    }
  }
`

export const SEND_LETTER = gql`
  mutation SendLetter($contactId: ID!, $templateId: ID!, $title: String, $body: String!) {
    sendLetter(contactId: $contactId, templateId: $templateId, title: $title, body: $body) {
      id
      lob_url
      estimatedAt
    }
  }
`
