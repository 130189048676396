import { ChangeEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Heading, Pane, SideSheet, Strong, Text, toaster } from 'evergreen-ui'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import {
  Types,
  Button,
  Card,
  CardHeader,
  TextInputField,
  FormError,
  Textarea,
  Icon,
  Switch,
  Spinner,
  SelectMenu,
  prettyPhoneNumber
} from 'lib'
import { useMutation, useQuery } from '@apollo/client'

import { CREATE_NOTIFICATION_TEMPLATE, GET_TEMPLATE_SHEET, UPDATE_NOTIFICATION_TEMPLATE } from 'graphql/_template-sheet'
import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'

import TemplateVariableButtons from './template-variable-buttons'
import LetterPreviewButton from 'components/letter-preview-button'

type SubmitVariables = {
  name: string
  body: string
  layout: Types.NotificationLayout

  subject?: string | null
  buttonEnabled?: boolean | null
  buttonLabel?: string | null
  buttonUrl?: string | null
  signature?: string | null

  titleEnabled?: boolean | null
  title?: string | null
}

type FormikValues = {
  name: string
  body: string
  layout: Types.NotificationLayout

  subject?: string | null
  buttonEnabled?: boolean
  buttonLabel?: string
  buttonUrl?: string
  signature?: string | null

  titleEnabled?: boolean
  title?: string
}

type Template = {
  id: string
  channel: Types.NotificationChannel
  layout: Types.NotificationLayout
  isBilling: boolean
  name: string
  body: string
  title: string | null
  subject: string | null
  buttonLabel: string | null
  buttonUrl: string | null
  signature: string | null
  createdAt: string
}

type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  channel: Types.NotificationChannel
  template?: Template
  isDeliverMode?: boolean
  onTemplateChange?: (template: Template) => void
}

const NOTIFICATION_TEMPLATE_LAYOUTS = [
  { label: 'Payment - QR', value: Types.NotificationLayout.PAYMENT_QR },
  { label: 'Payment - Text', value: Types.NotificationLayout.PAYMENT_REQUEST },
  { label: 'Custom', value: Types.NotificationLayout.CUSTOM }
]

const TemplateSheet = ({
  isShown,
  setIsShown,
  channel,
  template: propsTemplate,
  isDeliverMode = false,
  onTemplateChange
}: Props) => {
  const global = useGlobal()
  const history = useHistory()
  const hideAllModals = useModal(false)

  const isEmail = channel === Types.NotificationChannel.EMAIL
  const isLetter = channel === Types.NotificationChannel.LETTER

  const showNotificationTemplateTest = useModal('notificationTemplateTest')
  const [selection, setSelection] = useState<{
    name: keyof FormikValues
    start: number
    end: number
    focus: () => void
    setPosition: (position: number) => void
  } | null>(null)

  const { data } = useQuery<Types.GetTemplateSheet, Types.GetTemplateSheetVariables>(GET_TEMPLATE_SHEET, {
    variables: { channel },
    skip: !!propsTemplate && !isLetter // Letter requires more account info
  })

  const account = data?.account
  if (account && !(account.returnAddress && account.checkAddress))
    throw Error('Account is missing returnAddress or checkAddress')

  const exampleTemplate = account?.exampleNotificationTemplate

  const template = propsTemplate || exampleTemplate

  const [createTemplate, createTemplateStatus] = useMutation<
    Types.CreateNotificationTemplate,
    Types.CreateNotificationTemplateVariables
  >(CREATE_NOTIFICATION_TEMPLATE, {
    update: (cache, { data }) => {
      if (!data) return

      cache.modify({
        id: cache.identify({ id: global.account.id, __typename: 'Account' }),
        fields: {
          notificationTemplates(cachedNotificationTemplates) {
            return [
              ...cachedNotificationTemplates,
              { __ref: cache.identify({ id: data.createNotificationTemplate.id, __typename: 'NotificationTemplate' }) }
            ]
          }
        }
      })
    }
  })
  const { loading: isCreating } = createTemplateStatus

  const [updateTemplate, updateTemplateStatus] = useMutation<
    Types.UpdateNotificationTemplate,
    Types.UpdateNotificationTemplateVariables
  >(UPDATE_NOTIFICATION_TEMPLATE)
  const { loading: isUpdating } = updateTemplateStatus

  const isSaving = isCreating || isUpdating

  const handleSubmit = async ({
    buttonEnabled,
    buttonUrl,
    buttonLabel,
    titleEnabled,
    title,
    ...fields
  }: SubmitVariables) => {
    if (!template) throw new Error('Template is empty')
    if (buttonEnabled && (!buttonLabel || !buttonUrl)) throw new Error('Button label and URL are required')
    if (titleEnabled && !title) throw new Error('Title is required')

    const enabledFields = {
      ...fields,
      buttonLabel: buttonEnabled ? buttonLabel! : null,
      buttonUrl: buttonEnabled ? buttonUrl! : null,
      title: titleEnabled ? title! : null
    }

    if (isDeliverMode) {
      if (!onTemplateChange) throw new Error('Template change handler is not provided')
      if (!propsTemplate) throw new Error('Template is not provided')

      onTemplateChange({
        ...propsTemplate,
        ...enabledFields
      })
    } else {
      if (propsTemplate) {
        await updateTemplate({
          variables: {
            ...enabledFields,
            id: propsTemplate.id
          }
        })
        toaster.success('Template successfully updated')
      } else {
        await createTemplate({
          variables: {
            ...enabledFields,
            channel
          }
        })

        toaster.success('Template successfully created')
      }
    }
  }

  const handleSelection = (
    target: { name: string; selectionStart: number | null; selectionEnd: number | null },
    focus: () => void,
    setPosition: (position: number) => void
  ) => {
    if (!target.selectionStart || !target.selectionEnd) return

    setSelection({
      name: target.name as keyof FormikValues,
      start: target.selectionStart,
      end: target.selectionEnd,
      focus,
      setPosition
    })
  }

  const handleInputSelection = (e: ChangeEvent<HTMLInputElement>) =>
    handleSelection(
      e.target,
      () => e.target.focus(),
      (position) => e.target.setSelectionRange(position, position)
    )
  const handleTextAreaSelection = (e: ChangeEvent<HTMLTextAreaElement>) =>
    handleSelection(
      e.target,
      () => e.target.focus(),
      (position) => e.target.setSelectionRange(position, position)
    )

  const initialValues: FormikValues | null = template
    ? {
        name: template.name || '',
        body: template.body || '',
        layout: template.layout,
        ...(isEmail
          ? {
              subject: template.subject ?? '',
              buttonEnabled: !!(template.buttonLabel && template.buttonUrl),
              buttonLabel: template.buttonLabel ?? '',
              buttonUrl: template.buttonUrl ?? '',
              signature: template.signature ?? ''
            }
          : {}),
        ...(isLetter
          ? {
              titleEnabled: !!template.title,
              title: template.title ?? ''
            }
          : {})
      }
    : null

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={isDeliverMode ? 440 : 700}
      shouldCloseOnOverlayClick={false}
    >
      {({ close }: { close: () => void }) => {
        return !template || !initialValues || (isLetter && !account) ? (
          <Pane display="flex" alignItems="center" height="100%" background="blueTint">
            <Spinner delay={0} />
          </Pane>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              await handleSubmit(values)
              close()
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Name is required'),
              body: Yup.string()
                .required('Body is required')
                .when('layout', {
                  is: Types.NotificationLayout.PAYMENT_QR,
                  then: Yup.string().max(800, `Body must be at most 800 characters`),
                  otherwise: Yup.string().max(
                    isLetter ? 3000 : 800,
                    `Body must be at most ${isLetter ? 3000 : 800} characters`
                  )
                }),
              // body: Yup.string().required('Body is required').max(800, `Body must be at most 800 characters`),
              ...(isEmail
                ? {
                    subject: Yup.string()
                      .required('Subject is required')
                      .max(80, ({ max }) => `Subject must be at most ${max} characters`),
                    buttonLabel: Yup.string().when('buttonEnabled', {
                      is: true,
                      then: Yup.string()
                        .required('Button label is required')
                        .max(40, ({ max }) => `Button label must be at most ${max} characters`)
                    }),
                    buttonUrl: Yup.string().when('buttonEnabled', {
                      is: true,
                      then: Yup.string()
                        .required('Button URL is required')
                        .max(100, ({ max }) => `Button URL must be at most ${max} characters`)
                    }),
                    signature: Yup.string().max(1000, ({ max }) => `Signature must be at most ${max} characters`)
                  }
                : {}),
              ...(isLetter
                ? {
                    title: Yup.string().when('titleEnabled', {
                      is: true,
                      then: Yup.string()
                        .required('Title is required')
                        .max(80, ({ max }) => `Title must be at most ${max} characters`)
                    })
                  }
                : {})
            })}
          >
            {({ values, setFieldValue }) => {
              const handleVariableInsert = (variableName: string) => {
                if (!selection) return

                const value = values[selection.name] as string
                const newValue = `${value.substring(0, selection.start)}{{${variableName}}}${value.substring(
                  selection.end
                )}`

                const newPosition = selection.start + variableName.length + 4

                setFieldValue(selection.name, newValue)
                setSelection((oldSelection) => {
                  if (!oldSelection) {
                    return null
                  }

                  return {
                    ...oldSelection,
                    start: newPosition,
                    end: newPosition
                  }
                })

                selection.focus()
                setTimeout(() => {
                  selection.setPosition(newPosition)
                }, 0)
              }

              return (
                <Form style={{ height: '100%' }}>
                  <SheetLayout>
                    <CardHeader
                      gridArea="header"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Heading size={600}>
                        Edit {isEmail ? 'Email' : isLetter ? 'Letter' : 'Text Message'}{' '}
                        {isDeliverMode ? 'Notification' : ' Template'}
                      </Heading>
                      <Pane display="flex" flexDirection="row" alignItems="center" gap={16}>
                        <Icon
                          icon={['fad', isEmail ? 'at' : isLetter ? 'mailbox-flag-up' : 'message-dots']}
                          size="lg"
                          color="default"
                        />
                        {!isDeliverMode && isLetter ? (
                          <LetterPreviewButton
                            contactId={null}
                            title={values.title}
                            body={values.body}
                            layout={values.layout}
                          />
                        ) : (
                          <Button
                            onClick={() => showNotificationTemplateTest({ template: { ...template, ...values } })}
                          >
                            Send Test
                          </Button>
                        )}
                      </Pane>
                    </CardHeader>
                    <Pane gridArea="body" background="blueTint" display="flex" gap={16} overflow="hidden">
                      <Pane width={440} padding={16} overflow="auto">
                        {!isDeliverMode && (
                          <Card backgroundColor="white" elevation={0} padding={0} marginBottom={16}>
                            <CardHeader withButton>
                              <Heading>Details</Heading>
                              <Pane display="flex" alignItems="center">
                                <Heading marginRight={8}>Type: </Heading>
                                <SelectMenu
                                  name="layout"
                                  height={32}
                                  width={140}
                                  marginBottom={0}
                                  options={NOTIFICATION_TEMPLATE_LAYOUTS}
                                />
                              </Pane>
                            </CardHeader>
                            <Pane padding={16}>
                              <TextInputField name="name" marginBottom={0} label="Name" />
                            </Pane>
                          </Card>
                        )}
                        {isEmail && (
                          <Card backgroundColor="white" elevation={0} padding={0} marginBottom={16}>
                            <CardHeader>
                              <Heading>Subject</Heading>
                            </CardHeader>
                            <Pane padding={16}>
                              <TextInputField name="subject" marginBottom={0} onSelect={handleInputSelection} />
                            </Pane>
                          </Card>
                        )}

                        {isLetter && (
                          <Card backgroundColor="white" elevation={0} padding={0} marginBottom={16}>
                            <CardHeader display="flex" justifyContent="space-between" alignItems="center">
                              <Heading size={500}>Title</Heading>
                              <Switch marginY={-4} height={24} name="titleEnabled" />
                            </CardHeader>
                            {values.titleEnabled && (
                              <Pane padding={16}>
                                <TextInputField name="title" marginBottom={0} onSelect={handleInputSelection} />
                              </Pane>
                            )}
                          </Card>
                        )}
                        <Card backgroundColor="white" elevation={0} padding={0} marginBottom={16}>
                          <CardHeader>
                            <Heading>Body</Heading>
                          </CardHeader>
                          <Pane padding={16}>
                            <Textarea name="body" height={280} marginBottom={0} onSelect={handleTextAreaSelection} />
                          </Pane>
                        </Card>
                        {isEmail && (
                          <>
                            <Card backgroundColor="white" elevation={0} padding={0} marginBottom={16}>
                              <CardHeader display="flex" justifyContent="space-between" alignItems="center">
                                <Heading size={500}>Button</Heading>
                                <Switch marginY={-4} height={24} name="buttonEnabled" />
                              </CardHeader>
                              {values.buttonEnabled && (
                                <Pane padding={16}>
                                  <TextInputField
                                    name="buttonLabel"
                                    label="Label"
                                    placeholder="Click Here"
                                    onSelect={handleInputSelection}
                                  />
                                  <TextInputField
                                    name="buttonUrl"
                                    label="URL"
                                    placeholder="https://www.google.com"
                                    onSelect={handleInputSelection}
                                  />
                                </Pane>
                              )}
                            </Card>
                            <Card backgroundColor="white" elevation={0} padding={0}>
                              <CardHeader>
                                <Heading>Signature</Heading>
                              </CardHeader>
                              <Pane padding={16}>
                                <Textarea
                                  name="signature"
                                  rows={3}
                                  marginBottom={0}
                                  onSelect={handleTextAreaSelection}
                                />
                              </Pane>
                            </Card>
                          </>
                        )}
                        {/* Checked by spinner condition above */}
                        {isLetter && account && (
                          <Card backgroundColor="white" elevation={0} padding={0} marginBottom={16}>
                            <CardHeader withButton>
                              <Heading>Mail Return Details</Heading>
                              <Button
                                iconBefore={['fas', 'pencil-alt']}
                                onClick={() => {
                                  hideAllModals()
                                  history.push('/account/practice')
                                }}
                              >
                                Edit Account Settings
                              </Button>
                            </CardHeader>
                            <Pane padding={16} display="grid" gridGap={16} gridTemplateColumns="120px 1fr">
                              <Strong>Practice Name</Strong>
                              <Text>{account.name}</Text>

                              <Strong>Practice Phone</Strong>
                              <Text>{prettyPhoneNumber(account.publicReplyPhone)}</Text>

                              <Strong>Return Address</Strong>
                              <Text whiteSpace="pre-wrap">{convertAddressToText(account.returnAddress!)}</Text>

                              <Strong>Check Address</Strong>
                              <Text whiteSpace="pre-wrap">{convertAddressToText(account.checkAddress!)}</Text>
                            </Pane>
                          </Card>
                        )}
                        <FormError collapseSpace />
                      </Pane>
                      {!isDeliverMode && (
                        <Pane flex="1 0 0" paddingY={16} paddingRight={16}>
                          <Heading marginY={16}>Merge Variables</Heading>
                          <TemplateVariableButtons onVariableClick={handleVariableInsert} channel={channel} />
                        </Pane>
                      )}
                    </Pane>
                    <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="space-between">
                      <Button type="button" height={48} onClick={close} appearance="minimal">
                        Cancel
                      </Button>
                      <Button type="submit" height={48} appearance="primary" autoFocus isLoading={isSaving}>
                        Save
                      </Button>
                    </Pane>
                  </SheetLayout>
                </Form>
              )
            }}
          </Formik>
        )
      }}
    </SideSheet>
  )
}

export default TemplateSheet

const convertAddressToText = ({
  street1,
  street2,
  city,
  state,
  zip
}: {
  street1: string
  street2: string | null
  city: string
  state: Types.State
  zip: string
}) => `${street1}${street2 ? `, ${street2}` : ''}\n${city}, ${state} ${zip}`

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
