import { useQuery } from '@apollo/client'
import { GET_GUARANTOR_BILLING_PROCESSED } from 'graphql/_guarantor-sheet'

import { Pane, Text } from 'evergreen-ui'
import { Types, Spinner } from 'lib'
import BillingCard from 'components/billing-card'

import receiptIllustration from 'assets/img/receipt.svg'

export type Props = { guarantor: Types.GuarantorBilling_contact }

const GuarantorBillingProcessed = ({ guarantor }: Props) => {
  const { data } = useQuery<Types.GuarantorBillingProcessed, Types.GuarantorBillingProcessedVariables>(
    GET_GUARANTOR_BILLING_PROCESSED,
    {
      variables: {
        id: guarantor.id
      }
    }
  )

  if (!data) return <Spinner delay={0} marginTop={36} />

  const {
    contact: { charges, upcomingInvoices, invoices, nextScheduledPayments }
  } = data

  const elements = [
    ...charges,
    ...upcomingInvoices.filter((ui) => ui.isMembership), // During testing new installments PR
    ...nextScheduledPayments,
    ...invoices
  ]

  return (
    <Pane display="grid" gridGap={8}>
      {elements.length ? (
        elements
          .sort((a, b) => +new Date(b.date) - +new Date(a.date))
          .map((el, i) => {
            if (el.__typename === 'Charge') return <BillingCard.Charge charge={el} elevation={0} key={i} />
            if (el.__typename === 'ScheduledPayment')
              return (
                <BillingCard.ScheduledPayment
                  scheduledPayment={{ ...el, date: new Date(el.date) }}
                  elevation={0}
                  key={i}
                />
              )
            return <BillingCard.Invoice invoice={el} elevation={0} key={i} />
          })
      ) : (
        <Pane display="flex" flexDirection="column" alignItems="center" marginTop={36}>
          <img src={receiptIllustration} height="100px" alt="Receipt Illustration" />
          <Text marginTop={24} size={500} textAlign="center" width="70%">
            This patient has no processed or upcoming charges
          </Text>
        </Pane>
      )}
    </Pane>
  )
}

export default GuarantorBillingProcessed
