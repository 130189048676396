import moment from 'moment'

import { CardProps, Pane, Text, Heading, Badge } from 'evergreen-ui'
import { Types, Card, Icon, toDollarsFromCents } from 'lib'

import { useModal } from 'components/modal-provider'

export interface Props extends CardProps {
  scheduledPayment: {
    id: string
    status: Types.ScheduledPaymentStatus
    amount: number
    date: Date
    installmentPlan: {
      id: string
      guarantor: {
        id: string
        code: string
        name: {
          first: string
          last: string
        }
      }
    } | null
  }
}

const ScheduledPayment = ({ scheduledPayment, ...props }: Props) => {
  const showScheduledPaymentSheet = useModal('scheduledPayment')

  return (
    <Card
      {...props}
      onClick={() => showScheduledPaymentSheet({ scheduledPayment })}
      paddingY={12}
      paddingX={16}
      hoverElevation={1}
      cursor="pointer"
      flexDirection="row"
      alignItems="center"
    >
      <Pane width="36px">
        <Icon icon={['fal', 'calendar-alt']} color="default" size="lg" />
      </Pane>

      <Pane flexGrow={1}>
        <Text size={300} marginBottom={4}>
          {moment(scheduledPayment.date).format('M/D/YYYY')}
        </Text>
        <Heading size={500}>{toDollarsFromCents(scheduledPayment.amount, 2)}</Heading>
      </Pane>
      <Badge color="blue">Scheduled Payment</Badge>
      <Icon icon={['far', 'chevron-right']} color="default" marginLeft={14} />
    </Card>
  )
}

export default ScheduledPayment
