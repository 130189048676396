import { Pane, Heading, Alert, Text } from 'evergreen-ui'
import { Icon, Link } from 'lib'

const Scheduled = () => (
  <Link to="billing/outstanding/assistant" data-testId="ar-assistant-link">
    <Alert hasIcon={false} hoverElevation={1} cursor="pointer">
      <Pane display="flex" alignItems="center">
        <Icon icon={['fad', 'fingerprint']} size="2x" marginRight={20} marginLeft={8} color="default" />
        <Pane flexGrow={1}>
          <Heading marginBottom={4}>Explore A/R Assistant Powered Cohorts</Heading>
          <Text color="muted">Pearly's propensity-to-pay algorithm uncovers key patient cohorts for your practice</Text>
        </Pane>
        <Icon icon={['far', 'chevron-right']} color="default" size="lg" marginRight={8} />
      </Pane>
    </Alert>
  </Link>
)

export default Scheduled
