export const colors = {
  neutral: {
    lightest: '#F9F9FB',
    light: '#E4E7EB',
    base: '#425A70',
    dark: '#234361',
    muted: '#66788A'
  },
  blue: {
    lightest: '#F7F9FD',
    light: '#DDEBF7',
    base: '#1070CA',
    dark: '#084B8A'
  },
  red: {
    lightest: '#FEF6F6',
    light: '#FAE2E2',
    base: '#EC4C47',
    dark: '#BF0E08'
  },
  orange: {
    lightest: '#FDF8F3',
    light: '#FAE3CD',
    base: '#D9822B',
    dark: '#95591E'
  },
  yellow: {
    lightest: '#FEF8E7',
    light: '#FBE6A2',
    base: '#F7D154',
    dark: '#7E6514'
  },
  green: {
    lightest: '#F1FAF5',
    light: '#D4EEE2',
    base: '#47B881',
    dark: '#00783E'
  },
  teal: {
    lightest: '#F1FBFC',
    light: '#D2EEF3',
    base: '#14B5D0',
    dark: '#007489'
  },
  purple: {
    lightest: '#F8F7FC',
    light: '#EAE7F8',
    base: '#735DD0',
    dark: '#37248F'
  },
  border: {
    default: '#E4E7EB',
    muted: '#EDF0F2'
  }
}

export const fontFamily = `"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`

export const boxShadow = { minimal: 'inset 0 0 0 1px rgba(67, 90, 111, 0.14);' }
