import { mc, prettyPhoneNumber, toDollarsFromCents, Types } from 'lib'

type Account = {
  name: string
  publicReplyPhone: string
  phone: string
  code: string
  programName: string
}

export const mapTemplateData = (
  contact: Types.GetSendNotificationCard['contact']
): {
  accountName: string
  accountCode: string
  accountReplyPhone: string
  accountProgramName: string
  contactCode: string
  contactFirstName: string
  memberUrl: string
  paymentUrl: string
  updatePaymentMethodUrl: string
  balancePatientPortion: string
  smsPaymentLink: string
} => {
  const { account } = contact

  return {
    accountName: account.name,
    accountCode: account.code,
    accountReplyPhone: prettyPhoneNumber(account.publicReplyPhone),
    accountProgramName: account.programName,
    contactCode: contact.code,
    contactFirstName: contact.name.first,
    memberUrl: mc.urls.member,
    paymentUrl: mc.urls.payment,
    updatePaymentMethodUrl: `${mc.urls.payment}/${contact.code}?dest=card`,
    balancePatientPortion: toDollarsFromCents(contact.balance.metrics.patientPortion, 2),
    smsPaymentLink: `${mc.urls.payment}/${contact.code}`
  }
}

export const getTestVariables = (account: Account): Types.NotificationTemplateVariables => ({
  accountName: account.name,
  accountCode: account.code,
  accountReplyPhone: prettyPhoneNumber(account.publicReplyPhone),
  accountProgramName: account.programName,
  contactCode: 'ABCD1234',
  contactFirstName: 'Rachel',
  memberUrl: mc.urls.member,
  paymentUrl: mc.urls.payment,
  updatePaymentMethodUrl: `${mc.urls.payment}/ABCD1234?dest=card`,
  balancePatientPortion: toDollarsFromCents(10000, 2),
  smsPaymentLink: `${mc.urls.payment}/ABCD1234`
})

export const getTemplateVariableButtons = ({ channel }: { channel: Types.NotificationChannel }) => {
  const templates: Types.NotificationTemplateVariables = {
    accountName: 'Practice Name',
    accountCode: 'Practice Code',
    accountReplyPhone: 'Billing Phone',
    accountProgramName: 'Practice Program Name',
    contactCode: 'Patient Code',
    contactFirstName: 'Patient First Name',
    memberUrl: 'Member Link',
    paymentUrl: 'Payment Link',
    balancePatientPortion: 'Patient Balance'
  }

  if (channel === 'SMS') {
    templates.smsPaymentLink = 'SMS Payment Link'
    templates.updatePaymentMethodUrl = 'Update Payment Method'
  }

  if (channel === 'EMAIL') {
    templates.updatePaymentMethodUrl = 'Update Payment Method'
  }

  return templates
}
