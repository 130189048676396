import { useField } from 'formik'

import { Switch as EvergreenSwitch, SwitchProps } from 'evergreen-ui'

export type Props = SwitchProps & {
  name: string
  onChange?: () => void
}

const Switch = ({ className, ...props }: Props) => {
  const { name, height = 24 } = props
  const [field] = useField({ name, type: 'checkbox' })

  return (
    <EvergreenSwitch
      marginBottom={16}
      height={height}
      {...props}
      checked={field.checked}
      onChange={() => {
        props.onChange && props.onChange()
        field.onChange({ target: { name, value: !field.checked } })
      }}
    />
  )
}

export default Switch
