import styled from 'styled-components/macro'
import { SideSheet, Pane, Text, Heading, Strong } from 'evergreen-ui'
import moment from 'moment'

import { Types, Card, CardHeader, Icon, Button, toDollarsFromCents, StatusBadge } from 'lib'
import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  scheduledPayment: {
    id: string
    status: Types.ScheduledPaymentStatus
    amount: number
    date: Date
    installmentPlan?: {
      id: string
      guarantor: {
        id: string
        code: string
        name: {
          first: string
          last: string
        }
      }
    } | null
  }
}

const ScheduledPaymentSheet = ({ isShown, setIsShown, scheduledPayment }: Props) => {
  const global = useGlobal()
  const anonymize = global?.meUser.google.anonymize

  const showGuarantorSheet = useModal('guarantor')

  const contact = scheduledPayment.installmentPlan?.guarantor

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      <SheetLayout>
        <CardHeader gridArea="header" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Pane display="flex" alignItems="center">
            <Icon icon={['fad', 'file-invoice-dollar']} size="2x" color="selected" paddingLeft={4} />
            <Pane marginLeft={16}>
              <Heading size={600}>Invoice</Heading>
              <Text size={400}>
                <Strong size={400}>Type: </Strong>
                Pay-Over-Time
              </Text>
            </Pane>
          </Pane>

          <StatusBadge.ScheduledPayment status={scheduledPayment.status} />
        </CardHeader>

        <Pane gridArea="body" overflow="auto" background="blueTint">
          <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
            <CardHeader withButton>
              <Heading size={500}>Patient</Heading>
              <Button
                iconBefore={['fas', 'user']}
                onClick={() => showGuarantorSheet({ guarantorId: contact?.id as string })}
              >
                View Patient
              </Button>
            </CardHeader>
            <Pane padding={16} display="flex" flexDirection="column">
              <Text size={500} marginBottom={8}>
                <Strong size={500}>Name: </Strong>
                {contact?.name.first} {contact?.name.last}
              </Text>
              <Text size={500}>
                <Strong size={500}>Patient ID: </Strong>
                {anonymize ? 'ABC123' : contact?.code.toUpperCase()}
              </Text>
            </Pane>
          </Card>

          <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
            <CardHeader>
              <Heading size={500}>Total Due - {toDollarsFromCents(scheduledPayment.amount, 2)}</Heading>
            </CardHeader>
            <Pane padding={16}>
              <Pane padding={8} display="flex" flexDirection="column">
                <Text size={500}>
                  <Strong size={500}>Invoice Date: </Strong>
                  {moment(scheduledPayment.date).format('M/D/YYYY')}
                </Text>
              </Pane>
            </Pane>
          </Card>
        </Pane>
      </SheetLayout>
    </SideSheet>
  )
}

export default ScheduledPaymentSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
