import gql from 'graphql-tag'
import { fragments } from 'graphql/_user-sheet'

export const GET_GROUP_USERS = gql`
  query GroupUsers {
    group {
      id

      accounts {
        id
        name
      }
      users {
        ...userFields
      }

      accountUsers {
        ...userFields

        accounts(excludeGroupAccounts: true) {
          id
          name
        }
      }
    }
  }

  ${fragments.userFields}
`
