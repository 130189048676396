import gql from 'graphql-tag'

export const TOGGLE_INSTALLMENT_TEMPLATE = gql`
  mutation ToggleInstallmentTemplate($id: ID!, $enabled: Boolean!) {
    toggleInstallmentTemplate(id: $id, enabled: $enabled) {
      id
      enabled
    }
  }
`
