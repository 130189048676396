import { Pane, Heading, Paragraph } from 'evergreen-ui'

import { Types, toDollarsFromCents, Icon, Button } from 'lib'

import { EnrollInstallmentsState } from '.'

import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'

export type Props = {
  guarantor: Types.EnrollInstallmentsCard_contact
  enrollState: EnrollInstallmentsState
  updateEnrollState: (update: Partial<EnrollInstallmentsState>) => void
}

const EnrollInstallmentsComplete = ({ guarantor, enrollState: { charge } }: Props) => {
  if (!charge) throw Error('EnrollState charge missing')

  const { updateGuarantorSheetState } = useGuarantorSheetState()

  return (
    <Pane display="flex" flexDirection="column" alignItems="center" paddingY={36} paddingX={40}>
      <Heading size={500} flex={1} textAlign="center" marginBottom={24}>
        Success
      </Heading>
      <Icon icon={['fad', 'check']} color="success" size="3x" marginBottom={24} />
      <Paragraph size={500} textAlign="center" marginBottom={24}>
        {guarantor.name.first} {guarantor.name.last} has made their first installment payment of{' '}
        {toDollarsFromCents(charge.grossAmount)} and is now on a payment plan. The next payment will be automatically
        charged.
      </Paragraph>
      <Button
        iconBefore={['far', 'file-chart-pie']}
        height={48}
        onClick={() =>
          updateGuarantorSheetState({ path: Types.GuarantorPath.PLANS, planType: Types.PlanType.INSTALLMENTS })
        }
      >
        Review Plan Details
      </Button>
    </Pane>
  )
}

export default EnrollInstallmentsComplete
