import { useMutation } from '@apollo/client'
import styled from 'styled-components/macro'

import {
  InstallmentTemplate,
  toDollarsFromCents,
  prettyPhoneNumber,
  prettyIntervalStart,
  calculateInstallmentAmounts,
  colors,
  CardHeader,
  useMediaQueryContext,
  useWindowSize,
  Card,
  Button,
  Types
} from 'lib'
import { Pane, SideSheet, Table, Paragraph, Heading } from 'evergreen-ui'
import { GENERATE_INSTALLMENT_TERMS_PDF } from 'lib/graphql/_installment-terms-sheet'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  installmentTemplateId?: string,
  installmentPlan?: {
    id: string
    intervalType: Types.InstallmentIntervalType
    interval: number
    periods: number
    adminFee: number
    downPayment: number
    adminFeeAmount?: number
    scheduledPayments: Types.GuarantorPlans_contact_installmentPlans_scheduledPayments[]
    faceAmount: number
  }
  installmentTemplate?: {
    id: string
    intervalType: Types.InstallmentIntervalType
    interval: number
    periods: number
    adminFee: number
    downPayment: number
  }
  amount: number
  startDate?: Date
  account: { name: string; phone: string; logoUrl: string | null }
  guarantor: {
    id: string
    name: {
      first: string
      last: string
    }
    dob: {
      day: number
      month: number
      year: number
    } | null
  }
}

const getInstallmentAmounts = ({ adminFeeAmount = 0, scheduledPayments, faceAmount }: {
  adminFeeAmount?: number
  scheduledPayments: Types.GuarantorPlans_contact_installmentPlans_scheduledPayments[]
  faceAmount: number
}) => ({
  enrollmentAmount: scheduledPayments[0].amount + adminFeeAmount,
  planTotal: faceAmount + adminFeeAmount,
  faceAmount,
  adminFeeAmount,
  installmentAmount: scheduledPayments[1].amount
})

const InstallmentTermsSheet = ({
  isShown,
  setIsShown,
  installmentPlan,
  installmentTemplate,
  startDate = new Date(),
  amount,
  account,
  guarantor
}: Props) => {
  const { isMobile } = useMediaQueryContext()
  const height = useWindowSize()[1]

  const variables: Types.GenerateInstallmentTermsPdfVariables = {
    contactId: guarantor.id,
    amount,
    startDate: startDate.toISOString(),
  }
  if (installmentPlan?.id) {
    variables.installmentPlanId = installmentPlan.id
  } else if (installmentTemplate?.id) {
    variables.installmentTemplateId = installmentTemplate.id
  } else {
    throw new Error('installmentTemplateId or installmentPlanId should be provided')
  }
  
  const {
    enrollmentAmount,
    planTotal,
    faceAmount,
    adminFeeAmount,
    installmentAmount
  } = installmentPlan
    ? getInstallmentAmounts(installmentPlan)
    : calculateInstallmentAmounts({ amount, installmentTemplate: installmentTemplate! })  // Covered by if statement above

  const [generateInstallmentTermsPdf, { loading }] = useMutation<
    Types.GenerateInstallmentTermsPdf,
    Types.GenerateInstallmentTermsPdfVariables
  >(GENERATE_INSTALLMENT_TERMS_PDF, {
    variables,
    onCompleted: ({ generateInstallmentTermsPdf }) => window.open(generateInstallmentTermsPdf)
  })

  const installmentData = installmentTemplate || installmentPlan! // Covered by if statement above

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={600}
      preventBodyScrolling={true}
      //@ts-ignore
      position={isMobile ? 'bottom' : 'right'}
    >
      <SheetLayout>
        <CardHeader gridArea="header" justifyContent="space-between" withButton>
          <Heading size={600}>Payment Plan Schedule & Terms</Heading>
          <Button
            iconBefore={['fas', 'file-download']}
            onClick={() => generateInstallmentTermsPdf()}
            isLoading={loading}
          >
            Download PDF
          </Button>
        </CardHeader>
        <Pane gridArea="body" overflow="auto" height={isMobile ? height - 112 : '100%'}>
          <Pane margin={24} display="grid" gridAutoColumns="1fr" gridGap={24}>
            <Pane>
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8} gridColumn="span 2">
                Overview
              </Heading>
              <Paragraph gridColumn="span 2">
                We are pleased to offer this in-house payment plan as a courtesy to you. By enrolling in this payment
                plan, you authorize and agree to pay the Plan Total in accordance with the below Schedule & Terms:
              </Paragraph>
            </Pane>

            <Pane display="grid" gridAutoFlow={isMobile ? 'row' : 'column'} gap={isMobile ? 24 : 16}>
              <Pane>
                <Card elevation={0} paddingY={8} borderRadius={0}>
                  <Table>
                    <Table.Row height={24} borderBottom="none">
                      <Table.TextCell flex="3">Plan Amount</Table.TextCell>
                      <Table.TextCell
                        textAlign="right"
                        flexBasis={100}
                        flexShrink={0}
                        flexGrow={0}
                        style={{ fontVariantNumeric: 'tabular-nums' }}
                      >
                        {toDollarsFromCents(faceAmount, 2)}
                      </Table.TextCell>
                    </Table.Row>

                    {adminFeeAmount > 0 && (
                      <Table.Row height={24} borderBottom="none">
                        <Table.TextCell flex="3">Enrollment Fee</Table.TextCell>
                        <Table.TextCell
                          textAlign="right"
                          flexBasis={100}
                          flexShrink={0}
                          flexGrow={0}
                          style={{ fontVariantNumeric: 'tabular-nums' }}
                        >
                          {toDollarsFromCents(adminFeeAmount, 2)}
                        </Table.TextCell>
                      </Table.Row>
                    )}

                    <Table.Row height={24} borderBottom="none" borderTop={`solid 1px ${colors.border.default}`}>
                      <Table.TextCell flex="3">Plan Total</Table.TextCell>
                      <Table.TextCell
                        textAlign="right"
                        flexBasis={100}
                        flexShrink={0}
                        flexGrow={0}
                        style={{ fontVariantNumeric: 'tabular-nums' }}
                      >
                        {toDollarsFromCents(planTotal, 2)}
                      </Table.TextCell>
                    </Table.Row>
                  </Table>
                </Card>
              </Pane>
              <Pane display="flex" justifyContent="center" alignItems="center">
                <InstallmentTemplate
                  installmentTemplate={installmentData}
                  startDate={startDate}
                  amount={amount}
                  width="100%"
                  isTruncated
                  marginX={16}
                />
              </Pane>
            </Pane>
            <Table gridRow="span 2" width="100%" style={{ border: `solid 1px ${colors.border.default}` }}>
              <Table.Head height={32} paddingRight={0}>
                <Table.TextHeaderCell>Due Date</Table.TextHeaderCell>
                <Table.TextHeaderCell textAlign="right">Amount Due</Table.TextHeaderCell>
              </Table.Head>

              {[...Array(installmentData.periods).fill('')].map((_el, i) => (
                <Table.Row key={i} height={32}>
                  <Table.TextCell>
                    {prettyIntervalStart({ interval: installmentData.interval, intervalNumber: i, intervalType: installmentData.intervalType, startDate, format: 'M/D/YY' })}{' '}
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {toDollarsFromCents(i === 0 ? enrollmentAmount : installmentAmount, 2)}
                  </Table.TextCell>
                </Table.Row>
              ))}
            </Table>
            <Pane>
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8} gridColumn="span 2">
                Plan Terms
              </Heading>
              <Paragraph gridColumn="span 2">
                By enrolling in this payment plan, you hereby authorize and agree to pay {account.name} the Plan Total
                in accordance with the Payment Schedule. You authorize and agree to have your payment method stored
                securely on file and automatically charged in accordance with the Payment Schedule. If your payment
                method on file fails to process, it is your sole responsibility to call our office at{' '}
                {prettyPhoneNumber(account.phone)} to update the payment method within one (1) business day of the
                payment Due Date.
              </Paragraph>
            </Pane>
          </Pane>
        </Pane>
      </SheetLayout>
    </SideSheet>
  )
}

export default InstallmentTermsSheet

const SheetLayout = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
`
