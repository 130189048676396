import styled from 'styled-components/macro'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, Radio, Text, Table } from 'evergreen-ui'
import { Types, Button, Card, CardHeader, TextInput, Switch, FormError, objectKeys } from 'lib'
import { discountData } from 'utility/data'

import { useModal } from 'components/modal-provider'

type Props = {
  plan: Types.PlanSheet_plan
  update: [Types.UpdatePlanProp, Types.UpdatePlanStatusProp]
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

type categoryFormFields = {
  toggleDiagnostic: boolean
  discountDiagnostic: number
  togglePreventive: boolean
  discountPreventive: number
  toggleRestorative: boolean
  discountRestorative: number
  toggleEndodontics: boolean
  discountEndodontics: number
  togglePeriodontics: boolean
  discountPeriodontics: number
  toggleProsthodonticsRemovable: boolean
  discountProsthodonticsRemovable: number
  toggleMaxillofacialProsthetics: boolean
  discountMaxillofacialProsthetics: number
  toggleImplantServices: boolean
  discountImplantServices: number
  toggleProsthodonticsFixed: boolean
  discountProsthodonticsFixed: number
  toggleOralAndMaxillofacialSurgery: boolean
  discountOralAndMaxillofacialSurgery: number
  toggleOrthodontics: boolean
  discountOrthodontics: number
  toggleAdjunctiveGeneralServices: boolean
  discountAdjunctiveGeneralServices: number
}

const PlanDiscounts = ({ plan, update: [updatePlan, updatePlanStatus], dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const initialValues = {
    discountType: plan.globalDiscountActive ? 'global' : 'category',
    globalDiscount: plan.globalDiscount ?? 15,
    ...discountData.reduce((prev, curr) => {
      const discount = plan.discounts.find((discount) => discount.category === curr.type)
      prev[curr.toggleField] = !!discount
      prev[curr.discountField] = discount?.value ?? 15
      return prev
    }, {} as categoryFormFields)
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(args) => {
        if (!dirty && args !== initialValues) setDirty(true)
      }}
      onSubmit={({ discountType, globalDiscount, ...args }) => {
        const submitArgs = {
          globalDiscountActive: discountType === 'global',
          globalDiscount: globalDiscount,
          discounts: objectKeys(args)
            .filter((argKey) => argKey.includes('toggle') && !!args[argKey])
            .map((toggleArg) => {
              const { discountField, type } = discountData.find((category) => category.toggleField === toggleArg)!
              return { category: type, value: args[discountField] }
            })
        }

        showConfirmDialog({
          body: 'Are you sure you want to save this plan?',
          onConfirm: () => updatePlan({ variables: { id: plan.id, ...submitArgs } })
        })
      }}
      validationSchema={Yup.object({
        globalDiscount: Yup.number()
          .when('discountType', {
            is: 'global',
            then: Yup.number().required('Global discount is empty')
          })
          .max(100, 'Global discount must be less than 100%'),
        ...discountData.reduce((prev, curr) => {
          prev[curr.discountField] = Yup.number()
            .when(curr.toggleField, {
              is: true,
              then: Yup.number().required(`${curr.name} discount is empty`)
            })
            .max(100, `${curr.name} discount must be less than 100%`)
          return prev
        }, {} as { [x: string]: Yup.NumberSchema })
      })}
    >
      {(formProps) => (
        <Form style={{ height: '100%', width: '100%' }}>
          <SheetBodyLayout>
            <Pane gridArea="body" background="blueTint" overflow="auto">
              <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                <CardHeader>
                  <Heading size={500}>Procedure Discounts</Heading>
                </CardHeader>
                <Pane padding={24}>
                  <Pane aria-label="discountType radio group" role="group">
                    <Radio
                      name="discountType"
                      onChange={formProps.handleChange}
                      checked={formProps.values.discountType === 'global'}
                      value="global"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      marginTop={0}
                      label={
                        <Pane display="flex" alignItems="center">
                          <TextInput
                            name="globalDiscount"
                            type="number"
                            height={40}
                            width={48}
                            marginRight={8}
                            marginBottom={0}
                            disabled={formProps.values.discountType === 'category'}
                          />
                          <Text size={400}>% Flat discount on all procedure categories</Text>
                        </Pane>
                      }
                      size={16}
                    />
                    <Radio
                      name="discountType"
                      onChange={formProps.handleChange}
                      checked={formProps.values.discountType === 'category'}
                      label="Discounts by procedure category"
                      value="category"
                      size={16}
                      marginBottom={0}
                    />
                  </Pane>
                  <Card backgroundColor="white" elevation={0} padding={0} marginTop={16}>
                    {formProps.values.discountType === 'category' && (
                      <Table borderRadius="5px" textAlign="left">
                        <Table.Head borderTopRightRadius="5px" borderTopLeftRadius="5px" height={44}>
                          <Table.TextHeaderCell flexBasis={320} flexShrink={0} flexGrow={0}>
                            Procedure Category
                          </Table.TextHeaderCell>
                          <Table.TextHeaderCell>Offer</Table.TextHeaderCell>
                          <Table.TextHeaderCell textAlign="center">Discount</Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body>
                          {discountData.map((category) => (
                            <CategoryRow
                              key={category.codes}
                              category={category}
                              toggled={formProps.values[category.toggleField]}
                            />
                          ))}
                        </Table.Body>
                      </Table>
                    )}
                  </Card>
                </Pane>
              </Card>
            </Pane>
            <Pane
              gridArea="footer"
              elevation={0}
              padding={16}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormError marginTop={0} max={2} />

              <Button
                isLoading={updatePlanStatus.called && updatePlanStatus.loading}
                type="submit"
                appearance="primary"
                height={48}
                justifyContent="center"
              >
                Save
              </Button>
            </Pane>
          </SheetBodyLayout>
        </Form>
      )}
    </Formik>
  )
}

export default PlanDiscounts

const CategoryRow = ({
  category: { name, codes, toggleField, discountField },
  toggled
}: {
  category: { name: string; codes: string; toggleField: string; discountField: string }
  toggled: boolean
}) => (
  <Table.Row>
    <Table.Cell flexBasis={320} flexShrink={0} flexGrow={0}>
      <Text size={300} color="selected" marginRight={4}>
        {name}
      </Text>
      <Text size={300} color="muted" fontStyle="italic">
        {codes}
      </Text>
    </Table.Cell>
    <Table.Cell>
      <Switch name={toggleField} marginBottom={0} />
    </Table.Cell>
    <Table.Cell display="flex" justifyContent="center">
      {toggled && (
        <>
          <TextInput name={discountField} type="number" width={40} height={32} marginBottom={0} marginRight={8} />
          <Text size={400}>%</Text>
        </>
      )}
    </Table.Cell>
  </Table.Row>
)

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
