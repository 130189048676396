import { prettyPhoneNumber, CornerDialog } from 'lib'
import { Pane, Strong, Paragraph } from 'evergreen-ui'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  account?: { publicReplyPhone: string }
}

const PayFaqsDialog = ({ isShown, setIsShown, account }: Props) => (
  <CornerDialog
    title="Online Bill Pay FAQs"
    isShown={isShown}
    setIsShown={setIsShown}
    hasFooter={false}
    body={
      <Pane display="grid" gridGap={6} marginTop={8}>
        <Strong>What is online bill pay?</Strong>
        <Paragraph marginBottom={8}>
          Our online payment portal lets you pay your dental bill online using a secure, HIPAA- and PCI-compliant site.
          Enter your details on file at our office to access your current balance, visit details, and payment options.
        </Paragraph>
        <Strong>What payment methods are accepted?</Strong>
        <Paragraph marginBottom={8}>
          You can pay in seconds with all major credit and debit cards, FSAH/HSA cards, ACH/eCheck, and Apple Pay.
        </Paragraph>
        <Strong>How do payments show up on my credit card statement?</Strong>
        <Paragraph marginBottom={8}>
          Your payment will appear as made on the date you completed the transaction online and our office information
          will appear on the statement.
        </Paragraph>
        <Strong>How secure is my personal information?</Strong>
        <Paragraph marginBottom={8}>
          All transactions are processed through a secure website, and all personal information is encrypted using TLS
          AES-256.
        </Paragraph>
        {account && (
          <>
            <Strong>Need help?</Strong>
            <Paragraph marginBottom={8}>
              Please call our office at {prettyPhoneNumber(account.publicReplyPhone)} and we’d be happy to answer any additional
              questions.
            </Paragraph>
          </>
        )}
      </Pane>
    }
  />
)

export default PayFaqsDialog
