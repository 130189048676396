import gql from 'graphql-tag'

export const GET_VERIFY_CONTACT_ADDRESS_CARD = gql`
  query VerifyContactAddressCard($contactId: ID!) {
    contact(id: $contactId) {
      id
      address {
        street1
        street2
        city
        state
        zip
      }
      verifiedAddress {
        street1
        street2
        city
        state
        zip
      }
    }
  }
`

export const VERIFY_CONTACT_ADDRESS = gql`
  mutation VerifyContactAddress(
    $contactId: ID!
    $street1: String!
    $street2: String
    $city: String!
    $state: State!
    $zip: String!
  ) {
    verifyContactAddress(
      contactId: $contactId
      street1: $street1
      street2: $street2
      city: $city
      state: $state
      zip: $zip
    ) {
      id
      isAddressVerified
      verifiedAddress {
        street1
        street2
        city
        state
        zip
      }
    }
  }
`
