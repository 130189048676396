import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { useMutation } from '@apollo/client'

import { Types, Button, ButtonProps, useLibConfig } from './'

import { GENERATE_SUNBIT_CHECKOUT_TOKEN } from 'lib/graphql/_sunbit-checkout-button'

export type Props = ButtonProps & {
  amount: number

  contactId?: string
  onCompleted?: () => void
}

const SunbitCheckoutButton = ({ amount, contactId, onCompleted, ...props }: Props) => {
  const {
    sunbit: { mode, apiKey }
  } = useLibConfig()

  const [generateToken, generateTokenStatus] = useMutation<
    Types.GenerateSunbitCheckoutToken,
    Types.GenerateSunbitCheckoutTokenVariables
  >(GENERATE_SUNBIT_CHECKOUT_TOKEN, {
    variables: {
      amount,
      contactId
    }
  })

  useEffect(() => {
    // @ts-ignore
    window.sunbitAsyncInit = function () {
      // @ts-ignore
      SUNBIT.init({ mode, sunbitKey: apiKey })
    }
  }, [])

  return (
    <>
      <Helmet>
        <script src="https://static.sunbit.com/sdk/sunbit-sdk.js"></script>
      </Helmet>
      <Button
        {...props}
        isLoading={generateTokenStatus.loading}
        onClick={async () => {
          const { data } = await generateToken()

          // @ts-ignore
          SUNBIT.epay.checkout({
            token: data?.generateSunbitCheckoutToken,
            onCompleted,
            onUserCancelled: null
          })
        }}
      >
        Apply
      </Button>
    </>
  )
}

export default SunbitCheckoutButton
