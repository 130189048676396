import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { GET_ONBOARDING_PRACTICE, SUBMIT_ONBOARDING_PRACTICE, UPDATE_ONBOARDING_PRACTICE } from 'graphql/_onboarding'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Text, Heading, Pill, toaster } from 'evergreen-ui'
import { Types, Card, CardHeader, Icon, FormError, Button, Spinner, useToken, mc } from 'lib'
import AddressFields from 'components/_fields/address-fields'

export type Props = {
  prev: string
  next: string
  setIsForward: React.Dispatch<React.SetStateAction<boolean>>
}

const OnboardingPractice = ({ prev, next, setIsForward }: Props) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { tokenResult, refreshToken } = useToken()
  const isCurrentStep = tokenResult?.claims.onboardingStep === Types.OnboardingStep.PRACTICE

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery<Types.OnboardingPractice>(
    GET_ONBOARDING_PRACTICE,
    { skip: tokenResult === undefined || isCurrentStep }
  )

  const [submit] = useMutation<Types.SubmitOnboardingPractice, Types.SubmitOnboardingPracticeVariables>(
    SUBMIT_ONBOARDING_PRACTICE,
    {
      onCompleted: async () => {
        await refreshToken()
        setIsForward(true)
        history.push(next)
      },
      onError: () => {
        toaster.danger('Unable to create practice')
        setLoading(false)
      }
    }
  )

  const [update] = useMutation<Types.UpdateOnboardingPractice, Types.UpdateOnboardingPracticeVariables>(
    UPDATE_ONBOARDING_PRACTICE,
    {
      onCompleted: () => {
        setIsForward(true)
        history.push(next)
      },
      onError: () => {
        toaster.danger('Unable to update practice')
        setLoading(false)
      }
    }
  )

  // Show spinner while loading query, for query error, or prior to initiating query
  return queryLoading || queryError || (!isCurrentStep && !queryData && !loading) ? (
    <Spinner paddingTop={0} delay={1200} />
  ) : (
    <Card padding={0}>
      <CardHeader>
        <Heading size={500} flexGrow={1} textAlign="center">
          Set up {mc.name} for your Practice
        </Heading>
        <Pill color="blue">2/5</Pill>
      </CardHeader>
      <Formik
        initialValues={
          queryData
            ? {
                name: queryData.account?.name ?? '',
                street1: queryData.account?.offices[0].street1 ?? '',
                street2: queryData.account?.offices[0].street2 ?? '',
                city: queryData.account?.offices[0].city ?? '',
                state: queryData.account?.offices[0].state ?? ('' as Types.State),
                zip: queryData.account?.offices[0].zip ?? '',
                phone: queryData.account?.phone ?? '',
                timezone: queryData.account.timezone
              }
            : {
                name: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                timezone: Types.Timezone.PACIFIC
              }
        }
        onSubmit={({ ...fields }) => {
          const fieldsWithTypedState = { ...fields, state: fields.state as Types.State, mirror: mc.id }
          setLoading(true)

          if (queryData) {
            update({ variables: { ...fieldsWithTypedState, officeId: queryData.account?.offices[0].id ?? '' } })
          } else {
            submit({ variables: { ...fieldsWithTypedState } })
          }
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Practice name is required'),
          street1: Yup.string().required('Address is required'),
          street2: Yup.string().nullable(),
          city: Yup.string().required('City is required'),
          state: Yup.mixed()
            .required('State is required')
            .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation)'),
          zip: Yup.string()
            .required('Zip is required')
            .matches(/^\d{5}(-\d{4})?$/, 'Please enter a valid zip code (5 digits or 9 digits)'),
          phone: Yup.string()
            .required('Phone is required')
            .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number')
        })}
      >
        <Form>
          <Pane padding={24} paddingBottom={8} display="flex" flexDirection="column" alignItems="center">
            <Icon icon={['fad', 'store-alt']} color="default" size="3x" marginBottom={16} />
            <Text size={500} marginBottom={16}>
              Enter practice details (main office)
            </Text>
            <AddressFields practice />
            <FormError marginBottom={0} />
          </Pane>

          <Pane zIndex={1} elevation={0} padding={16} flexShrink={0} display="flex" justifyContent="flex-end">
            <Button
              isLoading={loading}
              type="submit"
              appearance="primary"
              height={48}
              justifyContent="center"
              iconAfter={['far', 'chevron-right']}
            >
              Next
            </Button>
          </Pane>
        </Form>
      </Formik>
    </Card>
  )
}

export default OnboardingPractice
