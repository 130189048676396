import { useGlobal } from 'components/global-provider'
import { Heading, Pane, Position, Tooltip } from 'evergreen-ui'
import { Button, Types } from 'lib'

type Props = {
  state: Types.SendNotificationState
  guarantor: Types.GetSendNotificationCard['contact']
  onNext: (channel: Types.NotificationChannel) => void
}

const SendNotificationChannel = ({ guarantor, onNext }: Props) => {
  const { account } = useGlobal()
  const hasLetters = account.capabilities.includes(Types.AccountCapability.LETTERS)

  const hasSmsTemplates = guarantor.account.notificationTemplates.some((t) => t.channel === 'SMS')
  const hasEmailTemplates = guarantor.account.notificationTemplates.some((t) => t.channel === 'EMAIL')

  const hasLetterTemplates = guarantor.account.notificationTemplates.some((t) => t.channel === 'LETTER')
  const hasVerifiedReturnAddress = !!guarantor.account.returnAddress?.verifiedAddress

  const lettersDisabled = !guarantor.address || !hasVerifiedReturnAddress
  const lettersDisabledTooltip = !guarantor.address
    ? 'Address missing from Patient'
    : !hasVerifiedReturnAddress
    ? 'Please Verify Account Return Address'
    : null

  const hasTemplates = hasSmsTemplates || hasEmailTemplates || hasLetterTemplates

  return (
    <Pane display="flex" flexDirection="column" alignItems="center" padding={16} gap={8}>
      {hasTemplates ? (
        <Heading marginTop={8} marginBottom={16}>
          Choose a Channel:
        </Heading>
      ) : (
        <Heading marginY={16}>No templates available in this account</Heading>
      )}
      <Tooltip
        isShown={guarantor.phone ? false : undefined}
        position={Position.LEFT}
        content="Mobile phone number missing from Patient Profile"
      >
        {/* Necessary for tooltip */}
        <Pane width="100%">
          <Button
            display={hasSmsTemplates ? 'flex' : 'none'}
            width="100%"
            height={48}
            iconBefore={['fad', 'message-dots']}
            rightChevron
            onClick={() => onNext(Types.NotificationChannel.SMS)}
            disabled={!guarantor.phone}
          >
            Text Message
          </Button>
        </Pane>
      </Tooltip>
      <Tooltip
        isShown={guarantor.email ? false : undefined}
        position={Position.LEFT}
        content="Email missing from Patient Profile"
      >
        {/* Necessary for tooltip */}
        <Pane width="100%">
          <Button
            display={hasEmailTemplates ? 'flex' : 'none'}
            width="100%"
            height={48}
            iconBefore={['fad', 'paper-plane']}
            rightChevron
            onClick={() => onNext(Types.NotificationChannel.EMAIL)}
            disabled={!guarantor.email}
          >
            Email
          </Button>
        </Pane>
      </Tooltip>
      {hasLetters && (
        <Tooltip
          isShown={lettersDisabled ? undefined : false}
          position={Position.LEFT}
          content={lettersDisabledTooltip}
        >
          {/* Necessary for tooltip */}
          <Pane width="100%">
            <Button
              display={hasLetterTemplates ? 'flex' : 'none'}
              width="100%"
              height={48}
              iconBefore={['fad', 'mailbox-flag-up']}
              rightChevron
              onClick={() => onNext(Types.NotificationChannel.LETTER)}
              disabled={lettersDisabled}
            >
              Physical Mail
            </Button>
          </Pane>
        </Tooltip>
      )}
    </Pane>
  )
}

export default SendNotificationChannel
