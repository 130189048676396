import { useGlobal } from 'components/global-provider'
import { Types } from 'lib'

export const useCheckMinRole = () => {
  const { role } = useGlobal()

  const checkMinRole = (minRole: Types.UserRole) => {
    const ascendingRoles = [
      Types.UserRole.BASIC,
      Types.UserRole.STANDARD,
      Types.UserRole.ADMIN,
      Types.UserRole.SUPERADMIN
    ]

    if (ascendingRoles[ascendingRoles.length - 1] !== Types.UserRole.SUPERADMIN)
      throw Error('SUPERADMIN must be highest role')

    return ascendingRoles.indexOf(role) >= ascendingRoles.indexOf(minRole)
  }

  return checkMinRole
}
