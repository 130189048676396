import styled from 'styled-components/macro'

import { useQuery } from '@apollo/client'
import { GET_GUARANTOR_EXISTING } from 'graphql/_guarantor-sheet'

import { Pane, Spinner, Heading, Avatar, Tablist, Tab, Text } from 'evergreen-ui'
import { Types, Icon, colors } from 'lib'

import { useGlobal } from 'components/global-provider'

import Plans from './guarantor-plans'
import Profile from './guarantor-profile'
import Overview from './guarantor-overview'
import Billing from './guarantor-billing'

import Dependents from './guarantor-dependents'
import PaymentInfo from './guarantor-payment-info'
import EnrollMember from './guarantor-enroll-member'
import EnrollInstallments from './guarantor-enroll-installments'
import Charge from './guarantor-charge'
import Notification from './guarantor-notification'
import GuarantorStartOutreach from './guarantor-start-outreach'

import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'
import { intersection } from 'lodash'

let tabs = [
  { path: Types.GuarantorPath.OVERVIEW, component: Overview },
  { path: Types.GuarantorPath.BILLING, component: Billing },
  { path: Types.GuarantorPath.PLANS, component: Plans },
  {
    path: Types.GuarantorPath.PROFILE,
    component: Profile
  }
]

const hiddenTabs = [
  { path: Types.GuarantorPath.DEPENDENTS, component: Dependents },
  { path: Types.GuarantorPath.PAYMENT_INFO, component: PaymentInfo },
  { path: Types.GuarantorPath.ENROLL_MEMBER, component: EnrollMember },
  { path: Types.GuarantorPath.ENROLL_INSTALLMENTS, component: EnrollInstallments },
  { path: Types.GuarantorPath.CHARGE, component: Charge },
  { path: Types.GuarantorPath.NOTIFICATION, component: Notification },
  { path: Types.GuarantorPath.START_OUTREACH, component: GuarantorStartOutreach }
]

export type Props = {
  guarantorId: string
  closeModal?: () => void
}

const GuarantorExisting = ({ guarantorId, closeModal, ...props }: Props) => {
  const global = useGlobal()
  const anonymize = global?.meUser.google.anonymize
  const capabilities = global.account.capabilities

  if (
    intersection(capabilities, [Types.AccountCapability.MEMBERSHIP, Types.AccountCapability.INSTALLMENTS]).length === 0
  )
    tabs = tabs.filter((t) => t.path !== Types.GuarantorPath.PLANS)

  const { path: currentPath, updateGuarantorSheetState } = useGuarantorSheetState()

  const CurrentView = [...tabs, ...hiddenTabs].find((tab) => tab.path === currentPath)!.component

  const { data } = useQuery<Types.GuarantorExisting, Types.GuarantorExistingVariables>(GET_GUARANTOR_EXISTING, {
    variables: {
      id: guarantorId
    }
  })

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  const guarantor = data.contact
  const {
    name: { first, last },
    code
  } = guarantor

  const defaultSource = guarantor.stripe?.defaultSource

  return (
    <SheetLayout>
      <Pane
        gridArea="header"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        background="white"
        borderBottom="muted"
        padding={16}
      >
        <Pane display="flex" alignItems="center" flexGrow={1}>
          <Avatar name={`${first} ${last}`} size={40} />
          <Pane marginLeft={16}>
            <Heading size={600}>
              {first} {last}
            </Heading>
            <Pane height="20px" display="flex" alignItems="center">
              <Text size={400} color="muted">
                ID: {anonymize ? 'ABC123' : code.toUpperCase()}
              </Text>
            </Pane>
          </Pane>
        </Pane>
        <Tab
          size={400}
          height="52px"
          color={colors.blue.base}
          flexDirection={defaultSource ? 'column' : 'row'}
          alignItems={defaultSource ? 'flex-end' : 'center'}
          isSelected={currentPath === Types.GuarantorPath.PAYMENT_INFO}
          onSelect={() => updateGuarantorSheetState({ path: Types.GuarantorPath.PAYMENT_INFO })}
          marginLeft={0}
        >
          {defaultSource ? (
            <>
              <Pane display="flex" flexDirection="row" alignItems="center" lineHeight="22px">
                <Icon size="sm" marginRight={6} color="default" opacity="0.7" icon={['fas', 'credit-card']} />
                {defaultSource.isCredit ? 'Card' : 'Bank'}
              </Pane>
              **{defaultSource.last4}
            </>
          ) : (
            <>
              <Icon
                alignSelf="flex-start"
                size="sm"
                marginTop={6}
                marginRight={6}
                color="default"
                opacity="0.7"
                icon={['fas', 'pencil-alt']}
              />
              <Pane display="flex" flexDirection="row" alignItems="center" lineHeight="22px">
                Payment
                <br />
                Method
              </Pane>
            </>
          )}
        </Tab>
      </Pane>

      <Pane
        display="flex"
        padding={8}
        justifyContent="space-between"
        alignItems="center"
        background="white"
        borderBottom="muted"
      >
        <Tablist>
          {tabs.map((step) => {
            const { path } = step
            return (
              <Tab
                key={path}
                textTransform="uppercase"
                isSelected={currentPath === path}
                onSelect={() => updateGuarantorSheetState({ path })}
                data-cy={`guarantor-tab-${path}`}
              >
                {path}
              </Tab>
            )
          })}
        </Tablist>
      </Pane>

      <Pane gridArea="body" overflow="auto">
        <CurrentView closeModal={closeModal} guarantorId={guarantorId} />
      </Pane>
    </SheetLayout>
  )
}

export default GuarantorExisting

const SheetLayout = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    'header'
    'nav'
    'body';
`
