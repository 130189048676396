import gql from 'graphql-tag'

export const CONNECT_STRIPE_ACCOUNT = gql`
  mutation ConnectStripeAccount($authorizationCode: String!) {
    connectStripeAccount(authorizationCode: $authorizationCode) {
      id
      stripe {
        id
        loginLink
        acceptPayments
        bankTransfers
        required
        verifying
      }
    }
  }
`
