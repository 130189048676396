import styled from 'styled-components/macro'

import { useField } from 'formik'

import { SegmentedControl as EvergreenSegmentedControl, SegmentedControlProps } from 'evergreen-ui'

export type Props = Omit<SegmentedControlProps, 'onChange'> & {
  name: string
}

const SegmentedControl = ({ name, ...props }: Props) => {
  const [field] = useField(name)
  return (
    <EvergreenSegmentedControl
      marginBottom={16}
      value={field.value}
      {...props}
      onChange={(value) => field.onChange({ target: { name, value } })}
    />
  )
}

export default styled(SegmentedControl)`
  label:last-child {
    margin-bottom: 0;
  }
`
