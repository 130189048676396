import { Pane } from 'evergreen-ui'
import { Types, Button, colors, Card, PaymentMethodCard, InstallmentTemplate, TemplateDescription } from 'lib'
import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'

import { EnrollInstallmentsState } from '.'

export type Props = {
  guarantor: Types.EnrollInstallmentsCard_contact
  enrollState: EnrollInstallmentsState
  updateEnrollState: (update: Partial<EnrollInstallmentsState>) => void
}

const EnrollInstallmentsConfirm = ({
  guarantor,
  enrollState: { amount, installmentTemplate: template, step },
  updateEnrollState
}: Props) => {
  const { account } = useGlobal()

  if (!amount || !template) throw Error('EnrollState incomplete')

  const showInstallmentTermsSheet = useModal('installmentTerms')

  const hasPaymentDetails = guarantor.stripe?.defaultSource !== null

  return (
    <>
      <Pane display="grid" padding={16} gridGap={16}>
        <Card elevation={0} width="100%">
          <InstallmentTemplate
            installmentTemplate={template}
            isTruncated
            startDate={new Date()}
            marginBottom={12}
            amount={amount}
          />
          <TemplateDescription
            color="muted"
            marginBottom={8}
            amount={amount}
            installmentTemplate={template}
            prepend="Patient pays"
          />

          <Button
            appearance="minimal"
            alignSelf="center"
            onClick={() =>
              showInstallmentTermsSheet({
                installmentTemplate: template,
                amount,
                account,
                guarantor
              })
            }
          >
            View Schedule & Terms
          </Button>
        </Card>
        <PaymentMethodCard guarantorId={guarantor.id} elevation={0} mode="update" />
      </Pane>
      <Pane display="flex" flexDirection="row" padding={16} borderTop={`solid 1px ${colors.border.muted}`}>
        <Button appearance="minimal" height={48} marginRight={16} onClick={() => updateEnrollState({ step: step - 1 })}>
          Back
        </Button>
        <Button
          height={48}
          appearance="primary"
          flexGrow={1}
          rightChevron
          disabled={!hasPaymentDetails}
          onClick={() => updateEnrollState({ step: step + 1 })}
        >
          <Pane textAlign="center" width="100%">
            {hasPaymentDetails ? 'Review' : 'Update Payment Details'}
          </Pane>
        </Button>
      </Pane>
    </>
  )
}

export default EnrollInstallmentsConfirm
