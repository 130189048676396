import styled from 'styled-components/macro'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Heading, toaster } from 'evergreen-ui'
import { Types, Card, CardHeader, Button, Icon, Textarea } from 'lib'
import { useMutation } from '@apollo/client'
import { UPSERT_OUTREACH_INBOUND_CALL_GREETING } from 'graphql/_outreach-inbound'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  publicReplyPhone: string
  accountId: string
  accountName: string
  greeting?: {
    greetingText: string
    greetingUrl: string
  }
}

const BillingIvrSheet = ({ isShown, setIsShown, accountId, accountName, greeting, publicReplyPhone }: Props) => {
  const [upsert, upsertStatus] = useMutation<
    Types.UpsertOutreachInboundCallGreeting,
    Types.UpsertOutreachInboundCallGreetingVariables
  >(UPSERT_OUTREACH_INBOUND_CALL_GREETING, {
    onCompleted: () => toaster.success('Inbound call greeting updated'),
    onError: () => toaster.danger('Failed to update inbound call greeting')
  })

  const placeholderText = `Thank you for calling ${accountName}!  I believe you are calling about a billing question...

...If you are able to complete payment online, please hang up this call.  If you have further questions, please press 1 and I will forward this call to our patient billing team.
`

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      {({ close }: { close: () => void }) => (
        <Formik
          initialValues={greeting ? { ...greeting } : { greetingText: '', greetingUrl: '' }}
          onSubmit={({ greetingText: text }) => {
            upsert({ variables: { accountId, text } })
          }}
          validationSchema={Yup.object({
            greetingText: Yup.string().required('Salutation is required')
          })}
        >
          <Form style={{ height: '100%' }}>
            <SheetLayout>
              <CardHeader gridArea="header" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Heading size={600}>Edit Inbound Call Script</Heading>
                <Pane>
                  <Icon icon={['fad', 'phone']} size="lg" color="default" marginRight={12} />
                  <Button onClick={() => (window.location.href = `tel:${publicReplyPhone}`)}>Test Call</Button>
                </Pane>
              </CardHeader>

              <Pane background="blueTint">
                {' '}
                <Card backgroundColor="white" elevation={0} padding={0} margin={16}>
                  <CardHeader>
                    <Heading>IVR AI Script</Heading>
                  </CardHeader>
                  <Pane padding={16}>
                    <Textarea name="greetingText" placeholder={placeholderText} height={280} marginBottom={0} />
                  </Pane>
                </Card>
              </Pane>
              <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="space-between">
                <Button marginRight={8} onClick={close} height={48}>
                  Cancel
                </Button>
                <Button isLoading={upsertStatus.loading} appearance="primary" type="submit" height={48}>
                  {upsertStatus.loading ? 'Generating Recording' : 'Save'}
                </Button>
              </Pane>
            </SheetLayout>
          </Form>
        </Formik>
      )}
    </SideSheet>
  )
}

export default BillingIvrSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
