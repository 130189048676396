import gql from 'graphql-tag'

export const fragments = gql`
  fragment contactIndicatorFields on Contact {
    id
    hasAddress
    isAddressVerified

    email

    phone
    phone_smsEnabled
    phone_consent

    isPMS
    pms_override

    dob {
      day
      month
      year
    }

    latestEmailNotification {
      id
      createdAt
      channel
      status
      failureReason
    }
    latestSMSNotification {
      id
      createdAt
      channel
      status
      failureReason
    }
    latestLetterNotification {
      id
      createdAt
      channel
      status
      failureReason
    }
  }
`
