import { useEffect, useState } from 'react'

import { useMutation, useQuery, ApolloCache } from '@apollo/client'
import { GET_BALANCE_CARD, OVERRIDE_BALANCE, REMOVE_PROCESSED_PENDING_AMOUNT } from 'graphql/_balance-card'

import { Formik, Form } from 'formik'
import { Pane, Text, Heading, toaster, CardProps } from 'evergreen-ui'
import {
  Types,
  Card,
  CardHeader,
  Button,
  BalanceEquation,
  Spinner,
  toDollarsFromCents,
  colors,
  Icon,
  IconButton,
  TextInput,
  SegmentedControl,
  toDollars
} from 'lib'

import { useModal } from 'components/modal-provider'

export interface Props extends CardProps {
  guarantorId: string
  setParentEditingState?: React.Dispatch<React.SetStateAction<boolean>>
}

const BalanceCard = ({ guarantorId, setParentEditingState, ...props }: Props) => {
  const showConfirm = useModal('confirm')

  const [editingState, setEditingState] = useState(false)

  useEffect(() => setParentEditingState && setParentEditingState(editingState), [editingState])

  const { data } = useQuery<Types.BalanceCard, Types.BalanceCardVariables>(GET_BALANCE_CARD, {
    variables: {
      id: guarantorId
    }
  })

  const reloadBalance = (cache: ApolloCache<any>) => {
    cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        balanceMetrics: (_existing, { DELETE }) => DELETE,
        balancesConnection: (_existing, { DELETE }) => DELETE
      }
    })

    cache.modify({
      id: cache.identify({ id: guarantorId, __typename: 'Contact' }),
      fields: {
        balance(cachedBalance) {
          return { ...cachedBalance, engagementDetails: undefined }
        }
      }
    })

    cache.gc()
  }

  const [overrideBalance, overrideBalanceStatus] = useMutation<Types.OverrideBalance, Types.OverrideBalanceVariables>(
    OVERRIDE_BALANCE,
    {
      update: reloadBalance,
      onCompleted: () => {
        toaster.success('Balance successfully updated')
        setEditingState(false)
      },
      onError: (err) => toaster.danger(err.message.replace('GraphQL error: ', ''))
    }
  )

  const [removeProcessedPendingAmount, removeProcessedPendingAmountStatus] = useMutation<
    Types.RemoveProcessedPendingAmount,
    Types.RemoveProcessedPendingAmountVariables
  >(REMOVE_PROCESSED_PENDING_AMOUNT, {
    onCompleted: () => {
      toaster.success('Processed Pending Amount removed')
    },
    onError: (err) => toaster.danger(err.message.replace('GraphQL error: ', '')),
    update: reloadBalance
  })

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  const guarantor = data.contact
  const { balance } = guarantor

  const {
    type,
    patientPortion,
    insurancePortion,
    writeOffEstimate,
    ledger_patientPortion,
    ledger_insurancePortion,
    summary_patientPortion,
    summary_insurancePortion,
    manual_patientPortion,
    manual_insurancePortion,
    installmentsPending,
    shadowBalance
  } = balance.metrics

  const showTabs =
    typeof manual_patientPortion === 'number' ||
    !(
      ledger_patientPortion === 0 &&
      ledger_insurancePortion === 0 &&
      summary_patientPortion === 0 &&
      summary_insurancePortion === 0
    )

  const patientPortionAdjustment = shadowBalance + installmentsPending

  return (
    <>
      <Formik
        initialValues={{
          balanceType: type,
          patientPortion: patientPortion / 100,
          insurancePortion: insurancePortion / 100
        }}
        onSubmit={({ balanceType, insurancePortion, patientPortion }) =>
          showConfirm({
            title: 'Confirm',
            body: `Please confirm that you would like to update this patient's balance.`,
            onConfirm: () => {
              const isManual = balanceType === Types.BalanceType.MANUAL

              overrideBalance({
                variables: {
                  guarantorId,
                  type: balanceType,
                  manual_patientPortion: isManual ? Math.round(patientPortion * 100) : null,
                  manual_insurancePortion: isManual ? Math.round(insurancePortion * 100) : null
                }
              })
            }
          })
        }
      >
        {({ values, setValues }) => {
          const shownPatientPortion = editingState
            ? {
                [Types.BalanceType.SUMMARY]: summary_patientPortion + patientPortionAdjustment,
                [Types.BalanceType.LEDGER]: ledger_patientPortion + patientPortionAdjustment,
                [Types.BalanceType.ZERO]: 0,
                [Types.BalanceType.MANUAL]: values.patientPortion ?? 0
              }[values.balanceType]
            : patientPortion

          const shownInsurancePortion = editingState
            ? {
                [Types.BalanceType.SUMMARY]: summary_insurancePortion,
                [Types.BalanceType.LEDGER]: ledger_insurancePortion,
                [Types.BalanceType.ZERO]: 0,
                [Types.BalanceType.MANUAL]: values.insurancePortion ?? 0
              }[values.balanceType]
            : insurancePortion

          const shownTotal = shownPatientPortion + shownInsurancePortion

          return (
            <Form>
              <Card padding={0} {...props}>
                <CardHeader withButton>
                  <Pane display="flex" alignItems="center">
                    <Text size={500} marginRight={4}>
                      Owes:
                    </Text>
                    <Heading fontWeight={700}>{toDollarsFromCents(patientPortion, 2)}</Heading>
                    {/* {daysPastDue > 0 && !editingState && <Badge marginLeft={8}>{daysPastDue} days</Badge>} */}
                  </Pane>
                  {editingState ? (
                    <Pane>
                      <Button
                        key="cancel"
                        appearance="minimal"
                        disabled={overrideBalanceStatus.loading}
                        marginRight={8}
                        onClick={() => {
                          setEditingState(false)
                          setValues({ ...values, balanceType: type })
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        key="save"
                        appearance="primary"
                        type="submit"
                        isLoading={overrideBalanceStatus.loading}
                        data-cy="guarantor-balance-save-button"
                      >
                        Save
                      </Button>
                    </Pane>
                  ) : (
                    <Button
                      key="edit"
                      appearance="minimal"
                      type="button"
                      data-cy="guarantor-balance-edit-button"
                      onClick={() => {
                        if (shadowBalance !== 0 || installmentsPending !== 0) {
                          toaster.warning('Unable to edit balance with pending items', {
                            description: 'Please contact support'
                          })
                        } else {
                          setEditingState(true)
                          setValues({ ...values, balanceType: Types.BalanceType.MANUAL })
                        }
                      }}
                    >
                      Edit Balance
                    </Button>
                  )}
                </CardHeader>
                <Pane>
                  {editingState && showTabs && (
                    <SegmentedControl
                      height={28}
                      marginTop={16}
                      marginX={16}
                      marginBottom={0}
                      options={[
                        { label: 'Summary', value: Types.BalanceType.SUMMARY },
                        { label: 'Ledger', value: Types.BalanceType.LEDGER },
                        { label: 'Manual', value: Types.BalanceType.MANUAL }
                      ]}
                      name="balanceType"
                    />
                  )}

                  <Pane marginY={24} marginX={16}>
                    {editingState && values.balanceType === Types.BalanceType.MANUAL ? (
                      <Pane display="grid" gridGap={8} gridTemplateColumns="auto 1fr auto 1fr auto">
                        <Pane display="flex" flexDirection="column" alignItems="center">
                          <Text size={300} marginBottom={4}>
                            Total Balance
                          </Text>

                          <Heading size={500} fontWeight={300}>
                            {shownTotal === 0 ? '$0' : toDollars(shownTotal, 2)}
                          </Heading>
                        </Pane>
                        <Pane display="flex" justifyContent="center" alignItems="center">
                          <Icon color="none" icon={['fas', 'minus']} />
                        </Pane>
                        <Pane display="flex" flexDirection="column" alignItems="center">
                          <Text size={300} marginBottom={4}>
                            Insurance Est.
                          </Text>
                          <TextInput
                            name="insurancePortion"
                            height={24}
                            type="number"
                            step="any"
                            fontSize="16px"
                            width="72px"
                            marginBottom={-4}
                            textAlign="center"
                          />
                        </Pane>
                        <Pane display="flex" justifyContent="center" alignItems="center">
                          <Icon color="none" icon={['fas', 'equals']} />
                        </Pane>
                        <Pane display="flex" flexDirection="column" alignItems="center">
                          <Text size={300} marginBottom={4} position="relative">
                            Patient Balance
                          </Text>
                          <TextInput
                            name="patientPortion"
                            height={24}
                            type="number"
                            step="any"
                            fontSize="16px"
                            width="72px"
                            marginBottom={-4}
                            textAlign="center"
                            data-cy="guarantor-balance-input"
                          />
                        </Pane>
                      </Pane>
                    ) : (
                      <BalanceEquation
                        patientPortion={shownPatientPortion}
                        insurancePortion={shownInsurancePortion}
                        writeOffEstimate={writeOffEstimate}
                      />
                    )}
                  </Pane>

                  {values.balanceType === Types.BalanceType.MANUAL && (
                    <Pane borderTop="muted">
                      <Text size={300} color="muted" padding={8} textAlign="center" width="100%" display="inline-block">
                        {!!installmentsPending || !!shadowBalance
                          ? `${toDollarsFromCents(manual_patientPortion! + manual_insurancePortion!, 2)} `
                          : ''}
                        Manual Balance Override
                      </Text>
                    </Pane>
                  )}

                  {(!!installmentsPending || !!shadowBalance) && (
                    <>
                      <Pane display="grid" gridAutoFlow="column" justifyContent="space-evenly" borderTop="muted">
                        {!!installmentsPending && (
                          <Text size={300} color="muted" padding={8} textAlign="center">
                            {toDollarsFromCents(-installmentsPending)} Installments Pending{' '}
                          </Text>
                        )}
                        {!!installmentsPending && !!shadowBalance && (
                          <Pane width="1px" height="100%" backgroundColor={colors.border.default} />
                        )}
                        {!!shadowBalance && (
                          <Pane display="flex" alignItems="center">
                            <Text size={300} color="muted" padding={8} textAlign="center">
                              {toDollarsFromCents(-shadowBalance)} Processed Pending
                            </Text>
                            <IconButton
                              fontSize={12}
                              width={16}
                              height={20}
                              appearance="minimal"
                              icon={['fas', 'trash-alt']}
                              isLoading={removeProcessedPendingAmountStatus.loading}
                              onClick={() => {
                                showConfirm({
                                  title: 'Confirm Remove Processed Pending Amount',
                                  body:
                                    'Confirm that you want to remove the processed pending amount from the patient’s ledger.',
                                  onConfirm: () =>
                                    removeProcessedPendingAmount({ variables: { guarantorId: guarantorId! } })
                                })
                              }}
                            />
                          </Pane>
                        )}
                      </Pane>
                    </>
                  )}

                  {values.patientPortion !== 0 && values.balanceType !== Types.BalanceType.MANUAL && (
                    <Pane borderTop="muted" padding={8} textAlign="center">
                      <Text size={300} color="muted" whiteSpace="pre-line">
                        Recent balance based on PMS Data
                      </Text>
                    </Pane>
                  )}
                </Pane>
              </Card>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default BalanceCard
