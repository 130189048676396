import { useState } from 'react'
import { Types } from 'lib'

import { useStripe, useElements } from '@stripe/react-stripe-js'
import { useMutation, useQuery } from '@apollo/client'
import { ENROLL } from 'lib/graphql/_enroll-member-card'
import { GET_PAYMENT_METHOD_CARD } from 'lib/graphql/_payment-method-card'

import { Pane, toaster } from 'evergreen-ui'
import { Button } from 'lib'
import { MemberPaymentFields } from 'lib'
import { colors } from 'lib'

import { EnrollMemberState } from '.'

export type Props = {
  contact: Types.EnrollMemberCard_contact
  enrollState: EnrollMemberState
  updateEnrollState: (update: Partial<EnrollMemberState>) => void
  isPortal: boolean
}

const EnrollMemberPayment = ({ contact, enrollState, updateEnrollState, isPortal }: Props) => {
  const stripe = useStripe()
  const elements = useElements()
  const { plan } = enrollState
  if (!plan) throw Error('Incomplete enrollState')

  const [loading, setLoading] = useState(false)
  const [couponCodeState, setCouponCodeState] = useState<string | undefined>()
  const [plaidState, setPlaidState] = useState<{ token: string; accountId: string } | undefined>()

  // For payment purposes
  const existingGuarantorId = contact.guarantorId || contact.id

  const paymentCardStatus = useQuery<Types.PaymentMethodCard, Types.PaymentMethodCardVariables>(
    GET_PAYMENT_METHOD_CARD,
    {
      variables: {
        guarantorId: existingGuarantorId!,
        allowDelete: false,
        attemptScheduledPayments: false
      },
      skip: !existingGuarantorId
    }
  )

  const [enroll] = useMutation<Types.Enroll, Types.EnrollVariables>(ENROLL, {
    refetchQueries: ['DependentMemberCardList', 'PatientSheetPlans'],

    update: (cache) => {
      cache.evict({ id: cache.identify({ id: contact.id, __typename: 'Contact' }), fieldName: 'stripe' })
      cache.evict({ fieldName: 'patientsConnection' })
    },
    onCompleted: () => {
      toaster.success(`Member successfully enrolled`)
      updateEnrollState({ step: 2 })
    }
  })

  const submitPayment = async () => {
    // Propogate any changes to self-enroll.tsx
    try {
      setLoading(true)
      if (!elements || !stripe) throw Error('Stripe not initialized')

      let paymentVariables = {}

      if (elements?.getElement('card')) {
        // Credit Card
        const stripeElement = elements?.getElement('card')
        if (!stripeElement) throw Error('Stripe element not present')

        const { token: stripeToken } = await stripe.createToken(stripeElement)

        if (!stripeToken) throw Error('Please include a valid credit card')
        paymentVariables = { stripeToken: stripeToken.id }
      } else if (plaidState) {
        // ACH
        paymentVariables = {
          plaidToken: plaidState.token,
          plaidAccountId: plaidState.accountId
        }
      } else if (!paymentCardStatus.data?.contact.stripe) {
        throw Error('Please include valid payment details')
      }

      await enroll({
        variables: {
          contactId: contact.id,
          planId: plan.id,
          planTerm: plan.term,
          billingCycleAnchor: enrollState.payment?.billingCycleAnchor,
          coupon: couponCodeState,
          ...paymentVariables
        }
      })
    } catch (err) {
      setLoading(false)
      if (err instanceof Error) toaster.danger(err.message.replace('GraphQL error: ', ''))
    }
  }

  return (
    <Pane>
      <Pane display="flex" flexDirection="column" padding={16}>
        <MemberPaymentFields
          plan={plan!}
          setPlaidState={setPlaidState}
          setCouponCodeState={setCouponCodeState}
          billingCycleAnchorState={enrollState.payment?.billingCycleAnchor}
          setBillingCycleAnchorState={(billingCycleAnchor) => updateEnrollState({ payment: { billingCycleAnchor } })}
          guarantorId={existingGuarantorId}
          patientName={`${contact.name.first} ${contact.name.last}`}
          isDependent={typeof contact.guarantorId === 'string'}
          isPortal={isPortal}
        />
      </Pane>
      <Pane display="flex" flexDirection="row" padding={16} borderTop={`solid 1px ${colors.border.muted}`}>
        <Button
          disabled={loading}
          appearance="minimal"
          height={48}
          marginRight={16}
          onClick={() => updateEnrollState({ step: 0 })}
        >
          Back
        </Button>
        <Button
          isLoading={loading}
          onClick={() => submitPayment()}
          height={48}
          appearance="primary"
          flexGrow={1}
          rightChevron
        >
          <Pane textAlign="center" width="100%">
            {loading ? 'Enrolling' : 'Complete'}
          </Pane>
        </Button>
      </Pane>
    </Pane>
  )
}

export default EnrollMemberPayment
