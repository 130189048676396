import { Types } from 'lib'
import styled from 'styled-components/macro'

import { Pane, Heading } from 'evergreen-ui'
import { Icon } from 'lib'
import { PatientDetails } from 'lib'

export type Props = {
  guarantorId: string
  setDependentIdState: React.Dispatch<React.SetStateAction<string | undefined>>
  initialPath: Types.DependentPath
}

const DependentCreate = ({ guarantorId, setDependentIdState, initialPath }: Props) => {
  const optionalFields = [Types.PatientField.EMAIL, Types.PatientField.PHONE, Types.PatientField.PERIO]

  const requiredFields: Types.PatientField[] = []

  if (initialPath === Types.DependentPath.ENROLL) requiredFields.push(Types.PatientField.DOB)
  else optionalFields.push(Types.PatientField.DOB)

  return (
    <SheetLayout>
      <Pane
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        padding={16}
        borderBottom="muted"
        background="white"
      >
        <Pane height={40} width={40} display="flex" justifyContent="center" alignItems="center">
          <Icon icon={['fad', 'tooth']} size="lg" color="selected" marginRight={16} />
        </Pane>
        <Heading size={600}>Create a Dependent</Heading>
      </Pane>

      <Pane gridArea="body" overflow="hidden">
        <PatientDetails
          height={40}
          guarantorId={guarantorId}
          optionalFields={optionalFields}
          requiredFields={requiredFields}
          button={{ label: 'Create Dependent', existingLabel: 'Select Dependent', chevron: true }}
          onCreated={(data) => setDependentIdState(data.createPatient.id)}
          onSkip={(data) => setDependentIdState(data.createPatient.id)}
          onUpdated={(data) => setDependentIdState(data.updatePatient.id)}
          disableExistingGuarantor
          disableExistingMember={initialPath === Types.DependentPath.ENROLL}
        />
      </Pane>
    </SheetLayout>
  )
}

export default DependentCreate

const SheetLayout = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
`
