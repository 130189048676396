import { useField } from 'formik'

import { TextInputField as EvergreenTextInputField, TextInputFieldProps } from 'evergreen-ui'

export type Props = Omit<TextInputFieldProps, 'label' | 'textAlign'> & {
  name: string
  height?: number
  label?: React.ReactNode
  textAlign?: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start'
  isPhone?: boolean
}

const TextInputField = ({ height = 48, label = '', isPhone, ...props }: Props) => {
  const [field, meta] = useField(props.name)
  return (
    <EvergreenTextInputField
      marginBottom={16}
      inputHeight={height}
      spellCheck={false}
      style={{ textAlign: props.textAlign }}
      label={label}
      {...field}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (isPhone) e.target.value = e.target.value.replace(/\D/g, '')
        field.onChange(e)
      }}
      {...props}
      isInvalid={meta.touched && !!meta.error}
    />
  )
}

export default TextInputField
