import gql from 'graphql-tag'
import { fragments as contactIndicatorFields } from './_contact-indicator'

export const GET_GUARANTOR_EXISTING = gql`
  query GuarantorExisting($id: ID!) {
    contact(id: $id) {
      id
      name {
        first
        last
      }
      dob {
        day
        month
        year
      }
      code
      email
      phone
      stripe {
        defaultSource {
          isCredit
          last4
        }
      }
    }
  }
`

export const GET_GUARANTOR_OVERVIEW = gql`
  query GuarantorOverview($id: ID!) {
    contact(id: $id) {
      id

      ...contactIndicatorFields

      address {
        street1
        street2
        city
        state
        zip
      }
      balance {
        engagementDetails {
          status
        }
        metrics {
          patientPortion
        }
      }
      stripe {
        customerId
        membership {
          id
          status
        }
      }
      dependents {
        id
      }
    }
  }

  ${contactIndicatorFields}
`

export const GET_GUARANTOR_BILLING = gql`
  query GuarantorBilling($id: ID!) {
    contact(id: $id) {
      id
      code
      name {
        first
        last
      }
      dob {
        day
        month
        year
      }
      suppressed
      allowed
      balanceNote
    }
  }
`

export const SET_GUARANTOR_BALANCE_NOTE = gql`
  mutation SetGuarantorBalanceNote($contactId: ID!, $balanceNote: String) {
    setContactbalanceNote(contactId: $contactId, balanceNote: $balanceNote) {
      id
      balanceNote
    }
  }
`

export const GET_GUARANTOR_BILLING_VISITS = gql`
  query GuarantorBillingVisits($id: ID!) {
    contact(id: $id) {
      id
      balance {
        activity {
          date
          patient {
            name {
              first
              last
            }
            dob {
              day
              month
              year
            }
          }
          netAmount
          patientPaymentsSum
          insurancePaymentsSum
          adjustmentsSum
          proceduresSum
          isPendingPrimary
          isPendingSecondary
          isDenied
          uploadPaths
          procedures {
            id
            amount
            procedureCode
            description
            pms_claimId
          }
        }
      }
    }
  }
`

const fragments = {
  timelineFields: gql`
    fragment timelineFields on OutreachTimelineItem {
      id
      type
      flow {
        id
        type
        startDate
        endDate
        isManualEnd
        endReason
        isPrompted
        items {
          id
          date
          initiatedDate
          type
          templateName
          url
        }
      }
      singleAction {
        id
        date
        initiatedDate
        type
        templateName
        url
      }
    }
  `
}

export const GET_GUARANTOR_BILLING_OUTREACH = gql`
  query GuarantorBillingOutreach($id: ID!) {
    contact(id: $id) {
      id
      ...contactIndicatorFields

      balance {
        engagementDetails {
          status
          blockedReasons
        }
      }
      outreach {
        timeline {
          ...timelineFields
        }
      }
    }
  }
  ${fragments.timelineFields}
  ${contactIndicatorFields}
`

export const SET_GUARANTOR_OUTREACH_OPTIONS = gql`
  mutation SetGuarantorOutreachOptions($contactId: ID!, $suppressed: Boolean!, $allowed: Boolean!) {
    setGuarantorOutreachOptions(contactId: $contactId, suppressed: $suppressed, allowed: $allowed) {
      id
      suppressed
      allowed

      balance {
        engagementDetails {
          status
          blockedReasons
        }
      }
      outreach @include(if: $suppressed) {
        timeline {
          ...timelineFields
        }
        latestSMSNotification {
          channel
          failureReason
        }
        latestEmailNotification {
          channel
          failureReason
        }
      }
    }
  }
  ${fragments.timelineFields}
`

export const GET_GUARANTOR_BILLING_PROCESSED = gql`
  query GuarantorBillingProcessed($id: ID!) {
    contact(id: $id) {
      id

      upcomingInvoices {
        id
        stripeId_subscription
        amount
        isMembership
        date
        status
      }

      invoices(statuses: [OPEN, DRAFT]) {
        id
        stripeId_invoice
        status
        amount
        isMembership
        date
        status
      }

      charges(statuses: [SUCCEEDED, PENDING]) {
        id
        grossAmount
        balanceAmount
        date
        transaction {
          id
          type
          stripeId_subscription
          installmentPlanId
        }
        refundedAmount
        disputedAmount
      }

      nextScheduledPayments {
        id
        amount
        date
        status

        installmentPlan {
          id
          guarantor {
            id
            code
            name {
              first
              last
            }
          }
        }
      }
    }
  }
`

export const GET_GUARANTOR_PLANS = gql`
  query GuarantorPlans($id: ID!) {
    contact(id: $id) {
      id
      email
      phone
      code
      perio
      name {
        first
        last
      }
      dob {
        day
        month
        year
      }

      balance {
        metrics {
          patientPortion
        }
        engagementDetails {
          id
          status
          isLastStartManual
        }
      }

      isGuarantor
      familyMemberCount
      planTerm
      planRenewalDate
      plan {
        id
        name
        status
      }
      stripe {
        latestOpenInvoice
        membership {
          id
          effectiveDate
          status
          autoRenew
          price
          interval
        }
      }
      dependents {
        id
      }
      installmentPlans {
        id
        status
        startDate
        faceAmount
        adminFee
        downPayment
        periods
        interval
        intervalType
        guarantorId
        adminFeeAmount
        scheduledPayments {
          id
          amount
          date
          status
        }
      }
    }
  }
`

export const GET_GUARANTOR_DEPENDENTS = gql`
  query GuarantorDependents($id: ID!) {
    contact(id: $id) {
      id
      dependents {
        id
        name {
          first
          last
        }
      }
    }
  }
`

export const START_GUARANTOR_FLOW = gql`
  mutation StartGuarantorFlow($guarantorId: ID!, $balanceNote: String) {
    startFlow(guarantorId: $guarantorId, balanceNote: $balanceNote) {
      id
      suppressed
      latestEmailNotification {
        channel
      }
    }
  }
`
