import { Link, useRouteMatch } from 'react-router-dom'

import { Tab } from 'lib'

export type Props = {
  to: string
  children: string
}

export default ({ to, children }: Props) => {
  const isMatched = useRouteMatch({
    path: to,
    exact: true
  })

  return (
    <Link to={to}>
      <Tab size={300} textTransform="uppercase" isSelected={!!isMatched}>
        {children}
      </Tab>
    </Link>
  )
}
