import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { loginWithInvitation } from 'utility/auth'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, Text, toaster } from 'evergreen-ui'

import {
  FullScreen,
  Link,
  Button,
  useMediaQueryContext,
  Card,
  CardHeader,
  Icon,
  TextInputField,
  Checkbox,
  FormError,
  mc,
  prettyPhoneNumber
} from 'lib'
import { useModal } from 'components/modal-provider'

import logo from 'assets/img/logo_color.png'

export type Props = {
  className?: string
}

const InvitationView = ({ className }: Props) => {
  const { isMobile } = useMediaQueryContext()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const showResetPasswordDialog = useModal('resetUserPassword')

  return (
    <FullScreen className={className}>
      <Pane gridArea="header" display="flex" justifyContent="space-between" alignItems="center">
        <Pane width={isMobile ? '120px' : '180px'} textAlign="center" paddingY={16}>
          <img src={mc.imagePaths?.logo ?? logo} alt="Logo" height={isMobile ? '28px' : '37px'} />
        </Pane>
        <Pane height={isMobile ? '100%' : '50px'} marginRight={8} display="flex" alignItems="center">
          <Link href={`tel:${mc.phone}`}>
            <Button appearance="minimal" iconBefore={['fas', 'phone']}>
              {prettyPhoneNumber(mc.phone)}
            </Button>
          </Link>
          <Link href={`mailto:${mc.email}`} hidden={isMobile} marginLeft={8}>
            <Button appearance="minimal" iconBefore={['fas', 'envelope']}>
              {mc.email}
            </Button>
          </Link>
        </Pane>
      </Pane>

      <Pane
        gridArea="body"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        overflow="auto"
      >
        <Pane
          width="400px"
          maxWidth="100vw"
          paddingTop={isMobile ? 8 : 0}
          paddingX={16}
          paddingBottom={48}
          display="flex"
          justifyContent="center"
        >
          <Card padding={0} width="100%">
            <CardHeader>
              <Heading size={500} flexGrow={1} textAlign="center">
                Welcome to {mc.name}!
              </Heading>
            </CardHeader>
            <Formik
              initialValues={{ email: '', password: '', confirmPassword: '', consent: true }}
              onSubmit={async ({ email, password }) => {
                setIsLoading(true)
                await loginWithInvitation(
                  email,
                  password,
                  () => history.push('/'),
                  (err) => {
                    toaster.notify(err.message, {
                      description: (
                        <Link inline size={400} onClick={() => showResetPasswordDialog()}>
                          Please Reset Password
                        </Link>
                      )
                    })
                  }
                )
                setIsLoading(false)
              }}
              validationSchema={Yup.object({
                email: Yup.string().required('Email is required').email('Please provide a valid email address'),
                password: Yup.string()
                  .required('Password is required')
                  .min(8, 'Password must be at least 8 characters'),
                confirmPassword: Yup.string()
                  .required('Confirm password is required')
                  .oneOf([Yup.ref('password')], 'Passwords do not match'),
                consent: Yup.boolean().oneOf([true], 'Consent is required')
              })}
            >
              <Form>
                <Pane padding={24} display="flex" flexDirection="column" alignItems="center">
                  <Icon icon={['fad', 'id-card-alt']} color="default" size="3x" marginBottom={16} />
                  <Text size={500} marginBottom={16} textAlign="center">
                    Please confirm your email and set your password
                  </Text>
                  <Pane paddingX={24} width="100%">
                    <TextInputField
                      name="email"
                      label="Email"
                      width="100%"
                      type="text"
                      placeholder="johnharris@mydental.com"
                    />
                    <TextInputField
                      name="password"
                      label="Password"
                      width="100%"
                      type="password"
                      placeholder="Password"
                    />
                    <TextInputField
                      name="confirmPassword"
                      label="Confirm Password"
                      width="100%"
                      type="password"
                      placeholder="Confirm"
                    />
                  </Pane>
                  <Checkbox
                    name="consent"
                    label={
                      <>
                        I agree to{' '}
                        <Link href={mc.urls.legal} target="_blank" inline>
                          Terms of Use and Policies
                        </Link>
                      </>
                    }
                    marginBottom={0}
                  />
                  <FormError />
                </Pane>

                <Pane zIndex={1} elevation={0} padding={16} flexShrink={0} display="flex" justifyContent="flex-end">
                  <Button
                    isLoading={isLoading}
                    type="submit"
                    appearance="primary"
                    height={48}
                    width="100%"
                    justifyContent="center"
                    iconAfter={['far', 'chevron-right']}
                  >
                    Complete
                  </Button>
                </Pane>
              </Form>
            </Formik>
          </Card>
        </Pane>
      </Pane>
    </FullScreen>
  )
}

export default styled(InvitationView)`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;
  grid-template-areas:
    'header'
    'body';
`
