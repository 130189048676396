import { Types } from 'lib'

import { Pane, Heading, Paragraph } from 'evergreen-ui'

import { Icon } from 'lib'

import { EnrollMemberState } from '.'

export type Props = {
  contact: Types.EnrollMemberCard_contact
  enrollState: EnrollMemberState
  updateEnrollState: (update: Partial<EnrollMemberState>) => void
}

const EnrollMemberComplete = ({ contact }: Props) => (
  <Pane display="flex" flexDirection="column" alignItems="center" paddingY={36} paddingX={16}>
    <Heading size={500} flex={1} textAlign="center">
      Success
    </Heading>
    <Pane padding={24} display="flex" flexDirection="column" alignItems="center">
      <Icon icon={['fad', 'check']} color="success" size="3x" marginBottom={16} />
      <Paragraph size={500} textAlign="center">
        You have added {contact.name.first} {contact.name.last} to your membership program. {contact.name.first} will
        receive{' '}
        {contact.guarantorId
          ? 'a welcome email informing them of their enrollment.'
          : 'an email to login and set their password.'}
      </Paragraph>
    </Pane>
  </Pane>
)

export default EnrollMemberComplete
