import styled from 'styled-components/macro'

import { useField } from 'formik'

import { RadioGroup as EvergreenRadioGroup, RadioGroupProps } from 'evergreen-ui'

export type Props = RadioGroupProps & {
  name: string
}

const RadioGroup = ({ ...props }: Props) => {
  const { name } = props
  const [field] = useField(name)
  return (
    <EvergreenRadioGroup
      marginBottom={16}
      value={field.value}
      onChange={(e) => field.onChange({ target: { name, value: e.target.value } })}
      {...props}
    />
  )
}

export default styled(RadioGroup)`
  label:last-child {
    margin-bottom: 0;
  }
`
