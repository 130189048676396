import {
  createModalProvider,
  ContractSheet,
  SendInviteDialog,
  ConfirmDialog,
  SelectPlanDialog,
  ResumeMembershipDialog,
  ResumeInstallmentPlanDialog,
  DependentSheet,
  InstallmentTermsSheet,
  PayFaqsDialog,
  CornerDialog
} from 'lib'

import DentistSheet from 'components/_sheets/dentist-sheet'
import AddressSheet from 'components/_sheets/address-sheet'
import PlanSheet from 'components/_sheets/plan-sheet'
import UserSheet from 'components/_sheets/user-sheet'
import ChargeSheet from 'components/_sheets/charge-sheet'
import InvoiceSheet from 'components/_sheets/invoice-sheet'
import ScheduledPaymentSheet from 'components/_sheets/scheduled-payment-sheet'
import GuarantorSheet from 'components/_sheets/guarantor-sheet'
import InstallmentTemplateSheet from 'components/_sheets/installment-template-sheet'
import FinancingProviderSheet from 'components/_sheets/financing-provider-sheet'
import SunbitSheet from 'components/_sheets/sunbit-sheet'
import TemplateSheet from 'components/_sheets/template-sheet'
import ResetUserPasswordDialog from 'components/_dialogs/reset-user-password-dialog'
import VideoDialog from 'components/_dialogs/video-dialog'
import RefundDialog from 'components/_dialogs/refund-dialog'
import AccountBalanceTypeDialog from 'components/_dialogs/account-balance-type-dialog'
import SunbitTextDialog from 'components/_dialogs/sunbit-text-dialog'
import NotificationTemplateTestDialog from 'components/_dialogs/notification-template-test-dialog'
import MergeGuarantorsDialog from 'components/_dialogs/merge-guarantors-dialog'
import BalanceFilterSheet from 'components/_sheets/balance-filter-sheet'
import BillingIvrSheet from 'components/_sheets/billing-ivr-sheet'

const sheetComponents = {
  dentist: DentistSheet,
  address: AddressSheet,
  plan: PlanSheet,
  user: UserSheet,
  dependent: DependentSheet,
  guarantor: GuarantorSheet,
  sendInvite: SendInviteDialog,
  charge: ChargeSheet,
  invoice: InvoiceSheet,
  scheduledPayment: ScheduledPaymentSheet,
  contract: ContractSheet,
  installmentTemplate: InstallmentTemplateSheet,
  installmentTerms: InstallmentTermsSheet,
  financingProvider: FinancingProviderSheet,
  sunbit: SunbitSheet,
  confirm: ConfirmDialog,
  selectPlan: SelectPlanDialog,
  resumeMembership: ResumeMembershipDialog,
  resumeInstallmentPlan: ResumeInstallmentPlanDialog,
  resetUserPassword: ResetUserPasswordDialog,
  video: VideoDialog,
  refund: RefundDialog,
  accountBalanceType: AccountBalanceTypeDialog,
  sunbitText: SunbitTextDialog,
  template: TemplateSheet,
  notificationTemplateTest: NotificationTemplateTestDialog,
  payFaqs: PayFaqsDialog,
  mergeGuarantors: MergeGuarantorsDialog,
  balanceFilter: BalanceFilterSheet,
  corner: CornerDialog,
  billingIvr: BillingIvrSheet
}

export const { ModalProvider, useModal } = createModalProvider(sheetComponents)
