import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { GET_LIVE_PLANS, GET_LEGACY_PLANS, GET_DRAFT_PLANS } from 'graphql/_plans'
import { SET_PLAN_STATUS, DELETE_PLAN, COPY_PLAN } from 'graphql/_plan-status-selector'

import { Badge, Popover, Menu, Position, toaster } from 'evergreen-ui'
import { Types, Button } from 'lib'

import { useGlobal } from 'components/global-provider'
import { useModal } from 'components/modal-provider'
import { useCheckMinRole } from 'utility/roles'

export type Props = {
  plan: {
    id: string
    name: string
    status: Types.PlanStatus
    allTimeMemberCount: number
  }
}

const statusColors = {
  [Types.PlanStatus.LIVE]: { badge: 'green' as const, button: 'success' as const },
  [Types.PlanStatus.LEGACY]: { badge: 'orange' as const, button: 'warning' as const },
  [Types.PlanStatus.DRAFT]: { badge: 'blue' as const, button: 'none' as const },
  [Types.PlanStatus.TEMPLATE]: { badge: 'neutral' as const, button: 'success' as const }
}

const statusQueries = {
  [Types.PlanStatus.LIVE]: GET_LIVE_PLANS,
  [Types.PlanStatus.LEGACY]: GET_LEGACY_PLANS,
  [Types.PlanStatus.DRAFT]: GET_DRAFT_PLANS,
  [Types.PlanStatus.TEMPLATE]: null
}

const PlanStatusSelector = ({ plan }: Props) => {
  const global = useGlobal()

  const checkMinRole = useCheckMinRole()
  const isRoleDisabled = !checkMinRole(Types.UserRole.STANDARD)

  const history = useHistory()
  const resetModals = useModal(false)
  const showConfirmDialog = useModal('confirm')
  const showContractSheet = useModal('contract')

  const [isLoading, setIsLoading] = useState(false)
  const statusColor = statusColors[plan.status]
  const isTemplate = plan.status === Types.PlanStatus.TEMPLATE

  const [setPlanStatus] = useMutation<Types.SetPlanStatus, Types.SetPlanStatusVariables>(SET_PLAN_STATUS, {
    update(cache, { data }) {
      if (!data?.setPlanStatus) return
      const newPlan = data.setPlanStatus

      const removalQuery = statusQueries[plan.status]
      const removalCachedData = cache.readQuery<Types.LivePlans | Types.LegacyPlans | Types.DraftPlans>({
        query: removalQuery!
      })
      if (removalCachedData && removalQuery) {
        cache.writeQuery({
          query: removalQuery,
          data: {
            ...removalCachedData,
            plans: removalCachedData.plans.filter((listedPlan) => listedPlan.id !== plan.id)
          }
        })
      }

      try {
        const additionQuery = statusQueries[newPlan.status]
        const additionCachedData = cache.readQuery<Types.LivePlans | Types.LegacyPlans | Types.DraftPlans>({
          query: additionQuery!
        })
        if (additionCachedData && additionQuery) {
          cache.writeQuery({
            query: additionQuery,
            data: { ...additionCachedData, plans: additionCachedData.plans.concat(newPlan) }
          })
        }
      } catch (err) {
        if (window._env_.IS_LOCAL !== 'production') throw err
        else console.error(err)
      } finally {
        setIsLoading(false)
        history.push(`/membership/plans/${data.setPlanStatus.status.toLowerCase()}`)
        toaster.success(`Plan status successfully updated to ${data.setPlanStatus.status}`)
      }
    },
    onError: () => {
      setIsLoading(false)
      toaster.danger('Unable to set plan status')
    }
  })

  const [copyPlan] = useMutation<Types.CopyPlan, Types.CopyPlanVariables>(COPY_PLAN, {
    variables: { id: plan.id },
    update: (cache, { data }) => {
      if (!data) return

      const newPlan = data.copyPlan

      const cachedData = cache.readQuery<Types.DraftPlans>({
        query: GET_DRAFT_PLANS
      })
      if (cachedData) {
        cache.writeQuery({
          query: GET_DRAFT_PLANS,
          data: { ...cachedData, plans: [...cachedData.plans, newPlan] }
        })

        toaster.success(`${plan.name} has been successfully copied!`)
      }
    }
  })

  const [deletePlan] = useMutation<Types.DeletePlan, Types.DeletePlanVariables>(DELETE_PLAN, {
    variables: { id: plan.id },
    update: (cache, { data }) => {
      const query = statusQueries[plan.status]
      const cachedData = cache.readQuery<Types.LivePlans | Types.LegacyPlans | Types.DraftPlans>({ query: query! })
      if (data && cachedData && query) {
        cache.writeQuery({
          query,
          data: { ...cachedData, plans: cachedData.plans.filter((listedPlan) => listedPlan.id !== data.deletePlan?.id) }
        })

        toaster.success(`${plan.name} has been successfully deleted!`)
        resetModals()
      }
    },
    onError: () => toaster.danger('Unable to delete plan')
  })

  return isTemplate ? (
    <Badge color={statusColor.badge} marginRight={6}>
      {plan.status}
    </Badge>
  ) : (
    <Popover
      position={Position.BOTTOM_RIGHT}
      trigger="click"
      minWidth={120}
      content={({ close }) => (
        <Menu>
          {global.account?.stripe?.acceptPayments && (
            <>
              <Menu.Group>
                {(plan.status === Types.PlanStatus.DRAFT || plan.status === Types.PlanStatus.LEGACY) && (
                  <Menu.Item
                    disabled={isRoleDisabled}
                    onSelect={() => {
                      close()
                      showConfirmDialog({
                        body:
                          'Are you sure you want to make this plan live?  Patients will be able to enroll immediately.',
                        onConfirm: () => {
                          setIsLoading(true)
                          setPlanStatus({ variables: { id: plan.id, status: Types.PlanStatus.LIVE } })
                        }
                      })
                    }}
                  >
                    <Badge color="green" marginBottom={3}>
                      Live
                    </Badge>
                  </Menu.Item>
                )}
                {plan.status === Types.PlanStatus.LIVE && plan.allTimeMemberCount > 0 && (
                  <Menu.Item
                    disabled={isRoleDisabled}
                    onSelect={() => {
                      close()
                      setIsLoading(true)
                      setPlanStatus({ variables: { id: plan.id, status: Types.PlanStatus.LEGACY } })
                    }}
                  >
                    <Badge color="orange" marginBottom={3}>
                      Legacy
                    </Badge>
                  </Menu.Item>
                )}
                {plan.allTimeMemberCount === 0 &&
                  (plan.status === Types.PlanStatus.LIVE || plan.status === Types.PlanStatus.LEGACY) && (
                    <Menu.Item
                      disabled={isRoleDisabled}
                      onSelect={() => {
                        close()
                        showConfirmDialog({
                          body: `Are you sure you want to set this plan's status to draft?`,
                          onConfirm: () => {
                            setIsLoading(true)
                            setPlanStatus({ variables: { id: plan.id, status: Types.PlanStatus.DRAFT } })
                          }
                        })
                      }}
                    >
                      <Badge color="blue" marginBottom={3}>
                        Draft
                      </Badge>
                    </Menu.Item>
                  )}
              </Menu.Group>
              <Menu.Divider />
            </>
          )}

          <Menu.Group>
            <Menu.Item
              onSelect={() => {
                close()
                showContractSheet({ planId: plan.id })
              }}
            >
              View Contract
            </Menu.Item>
            {plan.status === Types.PlanStatus.LIVE && (
              <Menu.Item
                onSelect={() => {
                  close()
                  showConfirmDialog({
                    title: 'Confirm Copy Plan',
                    body:
                      'Please confirm that you would like to copy this Live Plan. This plan will be duplicated and saved as a Draft Plan that you can then edit and make live when needed. This existing Live Plan will not be affected and you can mark it as legacy when your new plan is ready to go live.',
                    confirmLabel: 'Duplicate Plan',
                    onConfirm: async () => {
                      setIsLoading(true)
                      const { data: newPlan } = await copyPlan()
                      if (!newPlan) throw new Error('Plan is empty')
                      history.push(`/membership/plans/draft?edit=${newPlan.copyPlan.id}`)
                    }
                  })
                }}
              >
                Copy Plan
              </Menu.Item>
            )}
            {plan.allTimeMemberCount === 0 && plan.status !== Types.PlanStatus.TEMPLATE && (
              <Menu.Item
                intent="danger"
                disabled={isRoleDisabled}
                onSelect={() => {
                  close()
                  showConfirmDialog({
                    body: 'Are you sure you want to delete this plan?',
                    onConfirm: () => {
                      setIsLoading(true)
                      deletePlan()
                    }
                  })
                }}
              >
                Delete
              </Menu.Item>
            )}
          </Menu.Group>
        </Menu>
      )}
    >
      <Button
        isLoading={isLoading}
        appearance="minimal"
        intent={statusColor.button}
        iconAfter={['far', 'chevron-down']}
      >
        <Badge color={statusColor.badge}>{plan.status}</Badge>
      </Button>
    </Popover>
  )
}

export default PlanStatusSelector
