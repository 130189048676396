import 'graphql'
import gql from 'graphql-tag'

export const RESUME_MEMBERSHIP = gql`
  mutation ResumeSubscription($subscriptionId: String!) {
    pauseContactSubscription(pause: false, subscriptionId: $subscriptionId) {
      id
      stripe {
        membership {
          id
          effectiveDate
          status
          autoRenew
        }
      }
    }
  }
`
