import { useField } from 'formik'

import TextInputWithButton, { Props as TextInputWithButtonProps } from 'lib/text-input-with-button'

export type Props = TextInputWithButtonProps

const TextInputFieldWithButton = ({ ...props }: Props) => {
  const [field, meta] = useField(props.name)
  return <TextInputWithButton {...props} {...field} isInvalid={meta.touched && !!meta.error} />
}

export default TextInputFieldWithButton
