import SendNotificationCard from 'components/send-notification-card'

import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'

export type Props = {
  guarantorId: string
}

const GuarantorNotification = ({ guarantorId }: Props) => {
  const { initialNotificationChannel } = useGuarantorSheetState()

  return (
    <SendNotificationCard
      guarantorId={guarantorId}
      margin={16}
      elevation={0}
      initialChannel={initialNotificationChannel}
    />
  )
}

export default GuarantorNotification
