import 'graphql'
import gql from 'graphql-tag'

const fragments = {
  contactFields: gql`
    fragment contactFields on Contact {
      id
      guarantorId
      isGuarantor
      email
      phone
      source
      isFirstFamilyMember

      code
      name {
        first
        last
      }
      perio
      dob {
        day
        month
        year
      }
      address {
        street1
        street2
        city
        state
        zip
      }
    }
  `
}

export const CREATE_PATIENT = gql`
  mutation CreatePatient($guarantorId: ID, $email: String, $phone: String, $profile: CreatePatientProfileInput!) {
    createPatient(guarantorId: $guarantorId, email: $email, phone: $phone, profile: $profile) {
      ...contactFields
    }
  }
  ${fragments.contactFields}
`

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient($contactId: ID!, $email: String, $phone: String, $profile: UpdatePatientProfileInput!) {
    updatePatient(
      contactId: $contactId

      email: $email
      phone: $phone
      profile: $profile
    ) {
      ...contactFields
      balance {
        engagementDetails {
          blockedReasons
        }
      }
    }
  }
  ${fragments.contactFields}
`
export const GET_PATIENT_DETAILS = gql`
  query PatientDetails($id: ID!) {
    contact(id: $id) {
      source
      pms_override
      pms_primaryInsuranceName
      pms_secondaryInsuranceName
      pms_billingType
      stripeId_customer
      stripeId_subscription
      ...contactFields
    }
    activeOutreachFlowsByContactId(contactId: $id)
    transactionsCountByContactId(contactId: $id)
  }
  ${fragments.contactFields}
`

export const GET_EXISTING_PATIENTS = gql`
  query ExistingPatients($email: String, $phone: String, $guarantorId: ID) {
    existingContacts(email: $email, phone: $phone, guarantorId: $guarantorId) {
      source
      isMember
      ...contactFields
    }
  }
  ${fragments.contactFields}
`

export const DELETE_PATIENT = gql`
  mutation DeletePatient($contactId: ID!) {
    deletePatient(contactId: $contactId)
  }
`

export const UNSYNC_PATIENT = gql`
  mutation UnsyncPatient($contactId: ID!) {
    unsyncPatient(contactId: $contactId) {
      id
      pms_override
    }
  }
`

export const SYNC_PATIENT = gql`
  mutation SyncPatient($contactId: ID!) {
    syncPatient(contactId: $contactId) {
      pms_override
      ...contactFields
    }
  }
  ${fragments.contactFields}
`
