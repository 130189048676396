import gql from 'graphql-tag'

export const TOGGLE_SUNBIT = gql`
  mutation ToggleSunbit($enabled: Boolean!) {
    toggleSunbit(enabled: $enabled) {
      id
      enabled
    }
  }
`
