import { EnrollMemberCard } from 'lib'

export type Props = {
  dependentId: string
}

const GuarantorEnroll = ({ dependentId }: Props) => {
  return <EnrollMemberCard contactId={dependentId} margin={16} elevation={0} />
}

export default GuarantorEnroll
