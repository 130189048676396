import { useState } from 'react'
import { Types } from 'lib'

import { Pane, Dialog, Text, Strong, Avatar, toaster } from 'evergreen-ui'
import { colors } from 'lib'
import { prettyPhoneNumber } from 'lib'

import { Card } from 'lib'
import { Icon } from 'lib'
import { capital } from 'case'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  disablePMSPatient?: boolean
  disableExistingDependent?: boolean
  disableExistingGuarantor?: boolean
  disableExistingMember?: boolean
  guarantorId?: string
  patients?: Types.ExistingPatients_existingContacts[]
  selectPatientId: (patientId: string | 'new') => void
}

const ExistingPatientDialog = ({
  isShown,
  setIsShown,
  disablePMSPatient,
  disableExistingDependent,
  disableExistingGuarantor,
  disableExistingMember,
  patients = [],
  guarantorId,
  selectPatientId
}: Props) => {
  const [patientId, setPatientId] = useState<string>()

  const multiple = patients.length > 1

  const entity = guarantorId ? 'dependent' : 'patient'

  return (
    <Dialog
      isShown={isShown}
      title="Existing Patient"
      onCloseComplete={() => {
        setIsShown(false)
      }}
      onCancel={(close) => {
        close()
      }}
      onConfirm={(close) => {
        selectPatientId(patientId!)
        close()
      }}
      isConfirmDisabled={!patientId}
      confirmLabel="Continue"
    >
      <Pane padding={16}>
        <Text size={500} marginBottom={24} display="block" textAlign="center">
          {multiple
            ? `There are already ${entity}s with this email or phone. Please select an existing ${entity}, or create a new one:`
            : `There is already a ${entity} with this email or phone. Please select the existing ${entity}, or create a new one:`}
        </Text>
        {patients.map((patient, i) => {
          const { id, phone, email, name, code } = patient

          const fullName = `${name.first} ${name.last}`
          const disabled_pms = disablePMSPatient && patient.source !== 'PEARLY'
          const disabled_dependent = disableExistingDependent && !patient.isGuarantor
          const disabled_guarantor = disableExistingGuarantor && patient.isGuarantor
          const disabled_member = disableExistingMember && patient.isMember
          const disabled = disabled_pms || disabled_dependent || disabled_member

          return (
            <Card
              key={i}
              cursor={disabled ? 'not-allowed' : 'pointer'}
              elevation={0}
              hoverElevation={disabled ? undefined : 2}
              opacity={disabled ? 1 : 0.8}
              flexDirection="row"
              alignItems="center"
              height={64}
              width="100%"
              borderStyle="solid"
              borderWidth="2px"
              borderColor={patientId === id ? colors.blue.base : 'transparent'}
              marginBottom={16}
              onClick={() => {
                if (disabled_pms) toaster.danger('Unable to proceed for PMS synced patients')
                else if (disabled_dependent) toaster.danger('Unable to proceed with dependent patient')
                else if (disabled_guarantor) toaster.danger('Unable to proceed with guarantor patient')
                else if (disabled_member) toaster.danger('Patient already has a membership subscription')
                else setPatientId(id)
              }}
            >
              <Pane width="36px" display="flex" alignItems="center">
                <Avatar name={fullName} color="blue" />
              </Pane>
              <Pane display="flex" flexDirection="column">
                <Text>
                  <Strong>{fullName}</Strong> | {code.toUpperCase()}
                </Text>
                <Text>
                  {email ?? ''}
                  {email && phone ? ' | ' : ''}
                  {phone ? prettyPhoneNumber(phone) : ''}
                </Text>
              </Pane>
            </Card>
          )
        })}

        <Card
          cursor="pointer"
          elevation={1}
          hoverElevation={2}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          height={64}
          width="100%"
          borderStyle="solid"
          borderWidth="2px"
          borderColor={patientId === 'new' ? colors.blue.base : 'transparent'}
          onClick={() => setPatientId('new' as const)}
        >
          <Icon icon={['fas', 'user-plus']} marginRight={8} opacity={0.7} />
          <Text display="block">Create New {capital(entity)}</Text>
        </Card>
      </Pane>
    </Dialog>
  )
}

export default ExistingPatientDialog
