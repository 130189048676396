import { toaster } from 'evergreen-ui'

import * as firebaseAuth from 'firebase/auth'
import { getAuth } from 'firebase/auth'

export const signupWithEmailAndPassword = async (
  setSignupLoading: React.Dispatch<React.SetStateAction<boolean>>,
  email: string,
  password: string,
  cb: (idToken: string) => void
) => {
  const auth = getAuth()

  try {
    setSignupLoading(true)

    const { user } = await firebaseAuth.createUserWithEmailAndPassword(auth, email, password)
    if (!user) throw Error('Unable to create user')

    const idToken = await user.getIdToken()
    cb(idToken)
  } catch (err) {
    setSignupLoading(false)
    if (err instanceof Error) {
      toaster.notify(err.message)
    }
  }
}

export const loginWithEmailAndPassword = async (
  setLoginLoading: React.Dispatch<React.SetStateAction<boolean>>,
  email: string,
  password: string
) => {
  const auth = getAuth()

  try {
    setLoginLoading(true)
    await firebaseAuth.signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    setLoginLoading(false)
    toaster.notify('No user exists with the email and password provided.  Please try again.')
  }
}

export const loginWithInvitation = async (
  email: string,
  password: string,
  cb: () => void,
  onError: (err: any) => void
) => {
  const auth = getAuth()

  try {
    await firebaseAuth.signInWithEmailLink(auth, email, window.location.href)
    const currentUser = auth.currentUser
    if (currentUser) {
      firebaseAuth.updatePassword(currentUser, password)
      cb()
    } else {
      throw Error('User not found')
    }
  } catch (err) {
    onError(err)
  }
}

export const getCurrentUser = () => getAuth().currentUser

export const logout = async () => {
  try {
    await getAuth().signOut()
  } catch (err: unknown) {
    toaster.notify((err as { message: string }).message)
  }
}
