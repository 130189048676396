import styled from 'styled-components/macro'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { pickBy } from 'lodash'

import { Pane, Heading, Label, toaster } from 'evergreen-ui'
import {
  Types,
  Button,
  Card,
  CardHeader,
  Switch,
  TextInput,
  TextInputField,
  FormError,
  InfoTooltip,
  SelectMenu
} from 'lib'

import { useModal } from 'components/modal-provider'

export type Props = {
  plan: Types.PlanSheet_plan
  update: [Types.UpdatePlanProp, Types.UpdatePlanStatusProp]
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const PlanFees = ({ plan, update: [updatePlan, updatePlanStatus], dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const initialValues = {
    name: plan.name,
    minAgeToggle: !!plan.minAge,
    minAge: plan.minAge ?? (('' as unknown) as number),
    maxAgeToggle: !!plan.maxAge,
    maxAge: plan.maxAge ?? (('' as unknown) as number),
    annualPriceActive: plan.annualPriceActive,
    annualPrice: plan.annualPrice ?? (('' as unknown) as number),
    monthlyPriceActive: plan.monthlyPriceActive,
    monthlyPrice: plan.monthlyPrice ?? (('' as unknown) as number),
    familyDiscountToggle: !!plan.familyDiscount,
    familyDiscount: plan.familyDiscount ?? (('' as unknown) as number),
    perio: plan.perio === null ? 'allow' : plan.perio ? 'require' : 'exclude'
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(args) => {
        if (!dirty && args !== initialValues) setDirty(true)
      }}
      onSubmit={({ perio, ...args }) => {
        if (!args.annualPriceActive && !args.monthlyPriceActive)
          return toaster.warning('Please set either an annual or monthly price')

        const submitArgs = pickBy(args, (arg) => arg !== '')

        showConfirmDialog({
          body: 'Are you sure you want to save this plan?',
          onConfirm: () => {
            updatePlan({
              variables: {
                id: plan.id,
                perio: perio === 'require' ? true : perio === 'exclude' ? false : null,
                ...submitArgs
              }
            })
          }
        })
      }}
      validationSchema={Yup.object({
        name: Yup.string().required('Plan name is required'),
        annualPrice: Yup.mixed().when('annualPriceActive', {
          is: true,
          then: Yup.number().required('Annual price is empty')
        }),
        minAge: Yup.number().when('minAgeToggle', { is: true, then: Yup.number().required('Minimum age is empty') }),
        maxAge: Yup.number().when('maxAgeToggle', { is: true, then: Yup.number().required('Maximum age is empty') }),
        monthlyPrice: Yup.mixed().when('monthlyPriceActive', {
          is: true,
          then: Yup.number().required('Monthly price is empty')
        }),
        familyDiscount: Yup.mixed().when('familyDiscountToggle', {
          is: true,
          then: Yup.number()
            .required('Family discount is empty')
            .min(0, 'Discount must be a percentage out of 100')
            .max(100, 'Discount must be a percentage out of 100')
        })
      })}
    >
      {(formProps) => (
        <Form style={{ height: '100%', width: '100%' }}>
          <SheetBodyLayout>
            <Pane gridArea="body" background="blueTint" overflow="auto">
              <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                <CardHeader>
                  <Heading size={500}>Plan Details</Heading>
                </CardHeader>
                <Pane padding={24}>
                  <TextInputField name="name" label="Plan Name" placeholder="Plan Name" height={40} />
                  <Pane display="flex">
                    <Pane marginRight={16} flex={1}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Minimum Age
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="minAgeToggle" marginBottom={0} />
                        <TextInput
                          name="minAge"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.minAgeToggle ? 'visible' : 'hidden'}
                        />
                      </Pane>
                    </Pane>
                    <Pane marginRight={16} flex={1}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Maximum Age
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="maxAgeToggle" marginBottom={0} />
                        <TextInput
                          name="maxAge"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.maxAgeToggle ? 'visible' : 'hidden'}
                        />
                      </Pane>
                    </Pane>
                    <Pane flex={1.4}>
                      <Label marginBottom={9} alignSelf="flex-start" display="block">
                        Perio Eligibility
                        <InfoTooltip
                          position="top"
                          content="Set whether patients with periodontal disease, gingivitis, or gum disease are eligible for the plan."
                        />
                      </Label>
                      <SelectMenu
                        name="perio"
                        height={32}
                        width={112}
                        marginBottom={0}
                        options={[
                          { label: 'Exclude', value: 'exclude' },
                          { label: 'Require', value: 'require' },
                          { label: 'Allow', value: 'allow' }
                        ]}
                      />
                    </Pane>
                  </Pane>
                </Pane>
              </Card>

              <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                <CardHeader>
                  <Heading size={500}>Pricing</Heading>
                </CardHeader>
                <Pane padding={24}>
                  <Pane display="flex">
                    <Pane marginRight={16} flex={1}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Annual Price ($)
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="annualPriceActive" marginBottom={0} />
                        <TextInput
                          name="annualPrice"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.annualPriceActive ? 'visible' : 'hidden'}
                        />
                      </Pane>
                    </Pane>
                    <Pane marginRight={16} flex={1}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Monthly Price ($)
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="monthlyPriceActive" marginBottom={0} />
                        <TextInput
                          name="monthlyPrice"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.monthlyPriceActive ? 'visible' : 'hidden'}
                        />
                      </Pane>
                    </Pane>
                    <Pane flex={1.4}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Family Discount (%)
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="familyDiscountToggle" marginBottom={0} />
                        <TextInput
                          name="familyDiscount"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.familyDiscountToggle ? 'visible' : 'hidden'}
                        />
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>
              </Card>
            </Pane>
            <Pane
              gridArea="footer"
              elevation={0}
              padding={16}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormError marginTop={0} max={2} />

              <Button
                isLoading={updatePlanStatus.called && updatePlanStatus.loading}
                type="submit"
                appearance="primary"
                height={48}
                justifyContent="center"
              >
                Save
              </Button>
            </Pane>
          </SheetBodyLayout>
        </Form>
      )}
    </Formik>
  )
}

export default PlanFees

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
