import { Pane, Dialog, Text, UnorderedList, ListItem, Alert, toaster } from 'evergreen-ui'
import { TextInputField, Button, Icon, Card, CardHeader, Types, Spinner } from 'lib'

import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_MERGE_GUARANTORS_DIALOG, MERGE_GUARANTORS } from 'graphql/_merge-guarantors-dialog'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
}

const MergeGuarantorsDialog = ({ isShown, setIsShown }: Props) => {
  const [getGuarantors, { data, loading }] = useLazyQuery<
    Types.MergeGuarantorsDialog,
    Types.MergeGuarantorsDialogVariables
  >(GET_MERGE_GUARANTORS_DIALOG, {})

  const [merge, mergeStatus] = useMutation<Types.MergeGuarantors, Types.MergeGuarantorsVariables>(MERGE_GUARANTORS, {
    onCompleted: () => toaster.success('Contacts successfully merged')
  })

  const currentIsStripeCustomer = data?.current?.stripe?.customerId
  const currentHasStripeSubscription = data?.current?.stripe?.subscriptionId
  const legacyPMSDependentHasStripeSubscription = data?.legacy.dependents.some(
    (d) => !d.manual_guarantorId && d.stripe.subscriptionId
  )
  const currentDependentHasStripeSubscription = data?.current.dependents.some((d) => d.stripe.subscriptionId)

  const showFailureDialog = !!(
    currentIsStripeCustomer ||
    currentHasStripeSubscription ||
    legacyPMSDependentHasStripeSubscription ||
    currentDependentHasStripeSubscription
  )

  return (
    <Dialog
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width="640px"
      title="Merge Account Guarantors"
      hasFooter={!!data}
      confirmLabel="Confirm Merge"
      isConfirmDisabled={loading || showFailureDialog}
      isConfirmLoading={mergeStatus.loading}
      onConfirm={(close) => {
        if (!data) throw Error('Unable to confirm without data loaded')
        const currentId = data.current.id
        const legacyId = data.legacy.id

        merge({
          variables: { currentId, legacyId },
          update: (cache) => {
            close()
            cache.evict({ id: cache.identify({ id: currentId, __typename: 'Contact' }) })
            cache.evict({ id: cache.identify({ id: legacyId, __typename: 'Contact' }) })
          }
        })
      }}
    >
      <Formik
        initialValues={{ legacyCode: '', currentCode: '' }}
        validationSchema={Yup.object({
          legacyCode: Yup.string().required().length(8),
          currentCode: Yup.string().required().length(8)
        })}
        onSubmit={({ legacyCode, currentCode }) => getGuarantors({ variables: { legacyCode, currentCode } })}
      >
        <Form>
          <Pane width="100%" display="flex" flexDirection="column" gap={16} padding={8}>
            <Pane display="grid" gridTemplateColumns="1fr 86px 1fr" gap={8} alignItems="flex-end" marginBottom={8}>
              <Pane display="flex" flexDirection="column" alignItems="center">
                <Text>Legacy Contact</Text>
                <TextInputField name="legacyCode" height={32} width="100%" marginBottom={0} placeholder="ABCD1234" />
              </Pane>
              <Button justifyContent="center" type="submit" isLoading={loading}>
                Retrieve
              </Button>
              <Pane display="flex" flexDirection="column" alignItems="center">
                <Text>Current Contact</Text>
                <TextInputField name="currentCode" height={32} width="100%" marginBottom={0} placeholder="EFGH5678" />
              </Pane>
            </Pane>

            {data && (
              <>
                {showFailureDialog ? (
                  <Pane>
                    <Alert
                      title="Unable to Merge Guarantors"
                      intent="danger"
                      children={
                        <UnorderedList>
                          {currentIsStripeCustomer && <ListItem>Current guarantor is a Stripe Customer</ListItem>}
                          {currentHasStripeSubscription && (
                            <ListItem>Current guarantor has a Stripe Subscription</ListItem>
                          )}
                          {legacyPMSDependentHasStripeSubscription && (
                            <ListItem>Legacy guarantor has PMS dependents with a Stripe Subscription</ListItem>
                          )}
                          {currentDependentHasStripeSubscription && (
                            <ListItem>Current guarantor has dependents with a Stripe Subscription</ListItem>
                          )}
                        </UnorderedList>
                      }
                    />
                  </Pane>
                ) : (
                  <Pane display="grid" gridTemplateColumns="1fr 86px 1fr" gap={8}>
                    <Card elevation={1} padding={0}>
                      <CardHeader>
                        <Icon icon={['fad', 'credit-card']} color="default" marginRight={8} />

                        <Text>
                          Legacy - {data.legacy.name.first} {data.legacy.name.last}
                        </Text>
                      </CardHeader>
                      <Pane padding={16}>
                        <Text>Pearly Data</Text>
                        <UnorderedList>
                          <ListItem>Payment Method</ListItem>
                          <ListItem>Membership / Pay-Over-Time</ListItem>
                          <ListItem>Dependents</ListItem>
                          <ListItem>Payment History</ListItem>
                          <ListItem>Notification History</ListItem>
                          <ListItem>Billing Settings</ListItem>
                        </UnorderedList>
                      </Pane>
                    </Card>
                    <Pane display="flex" justifyContent="center" alignItems="center">
                      <Icon icon={['fad', 'chevrons-right']} color="default" size="2x" />
                    </Pane>
                    <Card elevation={1} padding={0}>
                      <CardHeader>
                        <Icon icon={['fad', 'id-card-alt']} color="default" marginRight={8} />
                        <Text>
                          Current - {data.current.name.first} {data.current.name.last}
                        </Text>
                      </CardHeader>
                      <Pane padding={16}>
                        <Text>Patient Data</Text>
                        <UnorderedList>
                          <ListItem>Code - {data.current.code}</ListItem>

                          <ListItem>Profile Details</ListItem>
                          <ListItem>PMS Data (If Applicable)</ListItem>
                        </UnorderedList>
                      </Pane>
                    </Card>
                  </Pane>
                )}
              </>
            )}
          </Pane>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default MergeGuarantorsDialog
