import { useState, useEffect } from 'react'
import { useField } from 'formik'

import { SelectMenu as EverGreenSelectMenu, SelectMenuProps, Pane } from 'evergreen-ui'
import { Button } from 'lib'

export type Props = Omit<SelectMenuProps, 'children'> & {
  name: string
  buttonWidth?: number | string
  height?: number
  placeholder?: string
  marginTop?: number
  marginBottom?: number
  marginRight?: number
  disabled?: boolean
  intent?: 'none' | 'success' | 'warning' | 'danger'
}

const SelectMenu = ({
  name,
  options = [],
  buttonWidth,
  height = 48,
  placeholder,
  marginTop = 0,
  marginBottom = 16,
  marginRight = 0,
  disabled = false,
  intent = 'none',
  ...props
}: Props) => {
  const [field] = useField(name)
  const selectedOption = options.find((option) => option.value === field.value)
  const [selectedValueState, setSelectedValueState] = useState(field.value)

  useEffect(() => {
    // delay updating selectedValueState until menu has closed
    setTimeout(() => setSelectedValueState(field.value), 100)
  }, [field])

  return (
    <EverGreenSelectMenu
      closeOnSelect={true}
      options={options}
      height={33 * Math.min(options.length, 10)}
      onSelect={(e) => field.onChange({ target: { name, value: e.value } })}
      selected={selectedValueState}
      hasFilter={false}
      hasTitle={false}
      {...props}
    >
      <Button
        width={buttonWidth}
        height={height}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginRight={marginRight}
        display="flex"
        justifyContent="space-between"
        iconAfter={['far', 'chevron-down']}
        disabled={disabled}
        intent={intent}
      >
        <Pane
          display="block"
          flexGrow={1}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          textAlign="left"
        >
          {(selectedOption && selectedOption.label) ?? placeholder}
        </Pane>
      </Button>
    </EverGreenSelectMenu>
  )
}

export default SelectMenu
