import gql from 'graphql-tag'

export const GET_GUARANTOR_ACTION_ITEMS = gql`
  query GuarantorActionItems($id: ID!) {
    contact(id: $id) {
      id
      account {
        sunbitConfigurationId
      }
      name {
        first
        last
      }
      code
      dob {
        day
        month
        year
      }
      email
      phone
      balance {
        metrics {
          patientPortion
        }
        engagementDetails {
          status
          blockedReasons
        }
      }
      stripe {
        customerId
        subscriptionId
        defaultSource {
          id
        }
      }
    }
  }
`

export const END_GUARANTOR_FLOW = gql`
  mutation EndGuarantorFlow($guarantorId: ID!) {
    endFlow(contactId: $guarantorId) {
      id
    }
  }
`
