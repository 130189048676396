import 'graphql'
import gql from 'graphql-tag'

export const GET_COUPON = gql`
  query GetCoupon($code: String!, $accountId: ID) {
    coupon(code: $code, accountId: $accountId) {
      id
      valid
      duration
      duration_in_months
      amount_off
      percent_off
    }
  }
`
