import gql from 'graphql-tag'

const fragments = {
  balanceFields: gql`
    fragment balanceFields on Balance {
      id
      type
      patientPortion
      insurancePortion
      writeOffEstimate
      ledger_patientPortion
      ledger_insurancePortion
      summary_patientPortion
      summary_insurancePortion
      manual_patientPortion
      manual_insurancePortion
      installmentsPending
      shadowBalance
      daysPastDue
    }
  `
}

export const GET_BALANCE_CARD = gql`
  query BalanceCard($id: ID!) {
    contact(id: $id) {
      id
      dob {
        day
        month
        year
      }
      balance {
        metrics {
          ...balanceFields
        }
      }
    }
  }
  ${fragments.balanceFields}
`

export const OVERRIDE_BALANCE = gql`
  mutation OverrideBalance(
    $guarantorId: ID!
    $type: BalanceType!
    $manual_patientPortion: Int
    $manual_insurancePortion: Int
  ) {
    overrideBalance(
      guarantorId: $guarantorId
      type: $type
      manual_patientPortion: $manual_patientPortion
      manual_insurancePortion: $manual_insurancePortion
    ) {
      ...balanceFields
    }
  }
  ${fragments.balanceFields}
`

export const REMOVE_PROCESSED_PENDING_AMOUNT = gql`
  mutation RemoveProcessedPendingAmount($guarantorId: ID!) {
    removeProcessedPendingAmount(guarantorId: $guarantorId)
  }
`
