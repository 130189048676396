import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

// SOLID
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane'
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint'
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faFilesMedical as faFilesMedical_s } from '@fortawesome/pro-solid-svg-icons/faFilesMedical'
import { faTimes as faTimes_s } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faBuildingColumns as faBuildingColumns_s } from '@fortawesome/pro-solid-svg-icons'
import { faTooth } from '@fortawesome/pro-solid-svg-icons/faTooth'
import { faPercentage } from '@fortawesome/pro-solid-svg-icons/faPercentage'
import { faHourglass } from '@fortawesome/pro-solid-svg-icons/faHourglass'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faEquals } from '@fortawesome/pro-solid-svg-icons/faEquals'
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'

// DELETE WHEN POSSIBLE
import { faCommentAltDollar } from '@fortawesome/pro-solid-svg-icons/faCommentAltDollar'
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch'
import { faLock as faLock_s } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faUnlock } from '@fortawesome/pro-solid-svg-icons/faUnlock'

// REGULAR
import { faUser as faUser_r } from '@fortawesome/pro-regular-svg-icons/faUser'
import { faFilesMedical as faFilesMedical_r } from '@fortawesome/pro-regular-svg-icons/faFilesMedical'
import { faCreditCard as faCreditCard_s } from '@fortawesome/pro-regular-svg-icons/faCreditCard'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faCheck as faCheck_r } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip'
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'

// LIGHT
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faMobile } from '@fortawesome/pro-light-svg-icons/faMobile'

import { faLock as faLock_l } from '@fortawesome/pro-light-svg-icons/faLock'
import { faReceipt as faReceipt_l } from '@fortawesome/pro-light-svg-icons/faReceipt'
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan'
import { faBuildingColumns as faBuildingColumns_l } from '@fortawesome/pro-light-svg-icons'
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt'
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar'

// DUOTONE
import { faCheck as faCheck_d } from '@fortawesome/pro-duotone-svg-icons/faCheck'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle'
import { faTimes as faTimes_d } from '@fortawesome/pro-duotone-svg-icons/faTimes'
import { faShieldCheck } from '@fortawesome/pro-duotone-svg-icons/faShieldCheck'
import { faCreditCard as faCreditCard_d } from '@fortawesome/pro-duotone-svg-icons/faCreditCard'
import { faUser as faUser_d } from '@fortawesome/pro-duotone-svg-icons/faUser'
import { faHouseUser } from '@fortawesome/pro-duotone-svg-icons/faHouseUser'
import { faBuildingColumns as faBuildingColumns_d } from '@fortawesome/pro-duotone-svg-icons'
import { faWindowClose } from '@fortawesome/pro-duotone-svg-icons/faWindowClose'
import { faReceipt as faReceipt_d } from '@fortawesome/pro-duotone-svg-icons/faReceipt'
import { faShieldAlt } from '@fortawesome/pro-duotone-svg-icons/faShieldAlt'

// BRAND
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple'

export const iconLibrary: IconDefinition[] = [
  faPaperPlane,
  faEnvelope,
  faLock_l,
  faUser_r,
  faFilesMedical_r,
  faFilesMedical_s,
  faCreditCard_s,
  faPrint,
  faCheck_r,
  faCheck_d,
  faChevronLeft,
  faChevronRight,
  faUserPlus,
  faCheckCircle,
  faInfoCircle,
  faChevronDown,
  faShieldCheck,
  faTimes_d,
  faTimes_s,
  faCommentAltDollar,
  faCreditCard_d,
  faUser_d,
  faHouseUser,
  faBuildingColumns_d,
  faBuildingColumns_s,
  faCircleNotch,
  faLock_s,
  faWindowClose,
  faUnlock,
  faReceipt_d,
  faReceipt_l,
  faPaperclip,
  faBan,
  faApple,
  faBuildingColumns_l,
  faPercentage,
  faMobile,
  faCalendarAlt,
  faFileInvoiceDollar,
  faTooth,
  faHourglass,
  faCreditCard,
  faUserFriends,
  faShieldAlt,
  faMinus,
  faEquals,
  faFileDownload,
  faCircle,
  faTrashAlt
]
