import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { GET_SEND_NOTIFICATION_CARD } from 'graphql/_send-notification-card'

import { Badge, CardProps, Heading, Pane } from 'evergreen-ui'
import { Card, CardHeader, Spinner, Types } from 'lib'

import SendNotificationChannel from './send-notification-channel'
import SendNotificationTemplate from './send-notification-template'
import SendNotificationVerifyAddress from './send-notification-verify-address'
import SendNotificationPayment from './send-notification-payment'
import SendNotificationReview from './send-notification-review'
import SendNotificationComplete from './send-notification-complete'
import { capital } from 'case'

const STEPS_COUNT = 3
const stepCountMap: Record<Types.SendNotificationState['step'], number> = {
  channel: 1,
  verifyAddress: 2,
  template: 2,
  payment: 2,
  review: 3,
  complete: 3
}

type Props = CardProps & {
  guarantorId: string
  initialChannel?: Types.NotificationChannel
}

const SendNotificationCard = ({ guarantorId, initialChannel, ...props }: Props) => {
  const initialStep = initialChannel
    ? initialChannel === Types.NotificationChannel.LETTER
      ? 'verifyAddress'
      : 'template'
    : 'channel'

  const [state, setState] = useState<Types.SendNotificationState>({
    step: initialStep,
    channel: initialChannel
  })
  const { step, channel, templateId } = state

  const { data: sendNotificationData } = useQuery<
    Types.GetSendNotificationCard,
    Types.GetSendNotificationCardVariables
  >(GET_SEND_NOTIFICATION_CARD, {
    variables: {
      contactId: guarantorId
    }
  })

  if (!sendNotificationData)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  const templates = sendNotificationData.contact.account.notificationTemplates.filter((t) => t.channel === channel)
  const template = sendNotificationData.contact.account.notificationTemplates.find((t) => t.id === templateId)
  const guarantor = sendNotificationData.contact

  const updateState = (update: Partial<Types.SendNotificationState>) => setState({ ...state, ...update })

  const commonProps = { state, guarantor, template }

  const title =
    step === 'channel' || !channel
      ? 'Send Notification'
      : step === 'verifyAddress'
      ? 'Verify Mailing Address'
      : step === 'template' || !template
      ? `Send ${channel === Types.NotificationChannel.SMS ? 'Text Message' : capital(channel)} `
      : template.name

  return (
    <Card {...props} padding={0} elevation={0} margin={16}>
      <CardHeader justifyContent="space-between">
        <Heading>{title}</Heading>
        <Badge color="blue">{step !== 'complete' ? `Step ${stepCountMap[step]}/${STEPS_COUNT}` : 'Completed'}</Badge>
      </CardHeader>

      {step === 'channel' && (
        <SendNotificationChannel
          {...commonProps}
          onNext={(channel) => {
            const isLetter = channel === Types.NotificationChannel.LETTER

            setState({ ...state, channel, step: isLetter ? 'verifyAddress' : 'template' })
          }}
        />
      )}
      {step === 'verifyAddress' && (
        <SendNotificationVerifyAddress
          {...commonProps}
          onBack={() => updateState({ step: 'channel' })}
          onNext={() => updateState({ step: 'template' })}
        />
      )}

      {step === 'template' && (
        <SendNotificationTemplate
          {...commonProps}
          templates={templates}
          onBack={() => {
            const isLetter = channel === Types.NotificationChannel.LETTER

            updateState({ ...state, step: isLetter ? 'verifyAddress' : 'channel' })
          }}
          onNext={(templateId) => {
            const selectedTemplate = sendNotificationData.contact.account.notificationTemplates.find(
              (t) => t.id === templateId
            )
            if (!selectedTemplate) throw new Error('Template not found')

            const isBilling = selectedTemplate.isBilling
            const nextStep = isBilling ? 'payment' : 'review'

            updateState({
              ...state,
              templateId,
              step: nextStep
            })
          }}
        />
      )}

      {step === 'payment' && (
        <SendNotificationPayment
          {...commonProps}
          onBack={() => updateState({ step: 'template' })}
          onNext={() => updateState({ step: 'review' })}
        />
      )}
      {step === 'review' && (
        <SendNotificationReview
          {...commonProps}
          onBack={() =>
            updateState({
              step: template?.isBilling ? 'payment' : 'template'
            })
          }
          onNext={(params?: { letterEstimatedAt?: string }) =>
            updateState({ step: 'complete', letterEstimatedAt: params?.letterEstimatedAt })
          }
        />
      )}
      {step === 'complete' && <SendNotificationComplete {...commonProps} />}
    </Card>
  )
}

export default SendNotificationCard
