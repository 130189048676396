import { useState } from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { loginWithEmailAndPassword } from 'utility/auth'

import { Pane, Paragraph } from 'evergreen-ui'

import { useMediaQueryContext, FullScreen, Button, Link, DoubleTextInput, FormError, Spinner, mc, Mirror } from 'lib'
import { useModal } from 'components/modal-provider'

import logo from 'assets/img/logo_color.png'
import loginIllustration from 'assets/img/login-illustration.svg'

const LoginView = () => {
  const { isTablet } = useMediaQueryContext()
  const [loading, setLoading] = useState(false)
  const showResetPasswordDialog = useModal('resetUserPassword')

  return (
    <>
      <FullScreen padding={24} display="flex" justifyContent="center" alignItems="center">
        <Pane width={320}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Pane textAlign="center">
                <img src={mc.imagePaths?.logo ?? logo} height="52px" alt="Logo" />

                <Paragraph size={500} color="muted" marginTop={4} marginBottom={20}>
                  Log in to the {mc.name} Practice Portal
                </Paragraph>
              </Pane>

              {/* Email / Password Login */}
              <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={({ email, password }) => {
                  loginWithEmailAndPassword(setLoading, email, password)
                }}
                validationSchema={Yup.object({
                  email: Yup.string().email('Invalid email address').required('Email is required'),
                  password: Yup.string()
                    .min(8, 'Password must be at least 8 characters')
                    .required('Password is required')
                })}
              >
                <Form>
                  <DoubleTextInput
                    name="email"
                    type="email"
                    width="100%"
                    placeholder="john@harris.com"
                    icon={['fal', 'envelope']}
                    half="top"
                  />
                  <DoubleTextInput
                    name="password"
                    type="password"
                    placeholder="Password"
                    width="100%"
                    marginBottom={0}
                    icon={['fal', 'lock']}
                    half="bottom"
                  />
                  <FormError marginBottom={8} />
                  <Button
                    type="submit"
                    appearance="primary"
                    height={48}
                    width="100%"
                    justifyContent="center"
                    marginBottom={8}
                    intent={mc.intent}
                  >
                    Log In
                  </Button>
                  <Paragraph textAlign="center">
                    Need Help?{' '}
                    <Link inline data-cy="reset-password-link" onClick={() => showResetPasswordDialog()}>
                      Reset Password
                    </Link>{' '}
                    {mc.id === Mirror.PEARLY && (
                      <>
                        or{' '}
                        <Link inline href="https://www.pearly.co/demo">
                          Request Demo
                        </Link>
                      </>
                    )}
                  </Paragraph>
                </Form>
              </Formik>
            </>
          )}
        </Pane>

        <Pane width="560px" marginLeft={72} display={isTablet ? 'none' : 'block'}>
          <img src={mc.imagePaths?.providerLogin ?? loginIllustration} width="100%" alt="Growth Services Hero" />
        </Pane>
      </FullScreen>
    </>
  )
}

export default LoginView
