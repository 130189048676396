import { useState } from 'react'
import styled from 'styled-components/macro'

import { useQuery } from '@apollo/client'
import { Icon, colors, Types } from 'lib'
import { GET_DEPENDENT_EXISTING } from 'lib/graphql/_dependent-sheet'

import { Pane, Spinner, Heading, Avatar, Tablist, Tab, Text } from 'evergreen-ui'

import Plans from './dependent-plan'
import Profile from './dependent-profile'
import Enroll from './dependent-enroll'

const tabs = [
  {
    path: Types.DependentPath.PROFILE,
    component: Profile
  },
  { path: Types.DependentPath.PLAN, component: Plans }
]

const hiddenTabs = [{ path: Types.DependentPath.ENROLL, component: Enroll }]

export type Props = {
  dependentId: string
  initialPath: Types.DependentPath
  showGuarantorSheet?: () => void
  isPortal: boolean
  closeModal?: () => void
}

const DependentExisting = ({ dependentId, showGuarantorSheet, isPortal, initialPath, closeModal }: Props) => {
  const [currentPath, setCurrentPath] = useState(initialPath)
  const { data } = useQuery<Types.DependentExisting, Types.DependentExistingVariables>(GET_DEPENDENT_EXISTING, {
    variables: { id: dependentId }
  })

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  const guarantor = data.contact
  const {
    name: { first, last },
    code,
    account: { capabilities }
  } = guarantor
  const hasMembershipCapability = capabilities.includes(Types.AccountCapability.MEMBERSHIP)
  const filteredTabs = hasMembershipCapability ? tabs : tabs.filter((tab) => tab.path !== Types.DependentPath.PLAN)

  const CurrentView = [...filteredTabs, ...hiddenTabs].find((tab) => tab.path === currentPath)?.component

  if (!CurrentView) throw new Error(`No view found for path: ${currentPath}`)

  return (
    <SheetLayout>
      <Pane
        gridArea="header"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        padding={16}
        background="white"
        borderBottom="muted"
      >
        <Pane display="flex" alignItems="center" flexGrow={1}>
          <Avatar name={`${first} ${last}`} size={40} />
          <Pane marginLeft={16}>
            <Heading size={600}>
              {first} {last}
            </Heading>
            <Pane height="20px" display="flex" alignItems="center">
              <Text size={400} color="muted">
                {/* No need to anonymize because code is only used for support (not invoice access) */}
                ID: {code.toUpperCase()}
              </Text>
            </Pane>
          </Pane>
        </Pane>
        {showGuarantorSheet && (
          <Tab
            size={400}
            color={colors.blue.base}
            flexDirection="row"
            alignItems="center"
            onSelect={() => showGuarantorSheet()}
          >
            <Icon size="sm" marginRight={6} color="default" opacity="0.7" icon={['fas', 'user-friends']} />
            Guarantor
          </Tab>
        )}
      </Pane>

      <Pane
        display="flex"
        padding={8}
        justifyContent="space-between"
        alignItems="center"
        background="white"
        borderBottom="muted"
      >
        <Tablist>
          {filteredTabs.map((step) => {
            const { path } = step
            return (
              <Tab
                key={path}
                textTransform="uppercase"
                isSelected={currentPath === path}
                onSelect={() => setCurrentPath(path)}
              >
                {path}
              </Tab>
            )
          })}
        </Tablist>
      </Pane>

      <Pane gridArea="body" overflow="auto">
        <CurrentView
          closeModal={closeModal}
          dependentId={dependentId}
          setCurrentPath={setCurrentPath}
          isPortal={isPortal}
        />
      </Pane>
    </SheetLayout>
  )
}

export default DependentExisting

const SheetLayout = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    'header'
    'nav'
    'body';
`
