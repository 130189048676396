import { Pane, Dialog } from 'evergreen-ui'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  wistiaId: string
  title?: string
}

const VideoDialog = ({ isShown, setIsShown, wistiaId, title }: Props) => (
  <Dialog isShown={isShown} onCloseComplete={() => setIsShown(false)} hasFooter={false} width="672px" title={title}>
    <Pane width="100%" height="360px">
      <iframe
        src={`https://fast.wistia.net/embed/iframe/${wistiaId}`}
        title="Software-Home Video"
        allow="autoplay; fullscreen"
        allowTransparency={true}
        frameBorder={0}
        scrolling="no"
        className="wistia_embed"
        name="wistia_embed"
        allowFullScreen
        width="100%"
        height="100%"
      ></iframe>
      <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
    </Pane>
  </Dialog>
)

export default VideoDialog
