import { Types } from 'lib'

import { useMutation } from '@apollo/client'
import { RESUME_INSTALLMENT_PLAN } from 'lib/graphql/_resume-installment-plan-dialog'

import { Pane, Dialog, Text, toaster } from 'evergreen-ui'
import { Button, Spinner } from 'lib'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  installmentPlan: {
    id: string
    status: Types.InstallmentPlanStatus
  }
}

const ResumeInstallmentPlanDialog = ({ isShown, setIsShown, installmentPlan }: Props) => {
  const [resumePlan, resumePlanStatus] = useMutation<
    Types.ResumeInstallment,
    Types.ResumeInstallmentVariables
  >(RESUME_INSTALLMENT_PLAN, {
    variables: {
      installmentPlanId: installmentPlan.id
    },
    onCompleted: () => {
      toaster.success(`Plan successfully resumed`)
    },
    onError: () => {
      toaster.danger('Unable to resume plan')
    }
  })

  return (
    <Dialog
      isShown={isShown}
      title="Resume Payment Plan"
      onCloseComplete={() => {
        setIsShown(false)
      }}
      hasFooter={false}
    >
      {({ close }) => (
        <>
          <Pane padding={16}>
            <Text>This payment plan has unpaid and past due installments. Please confirm that you would like to resume this plan and attempt to charge all previous unpaid installments associated with this plan. If you would not like to charge past due installment fees, please terminate this plan and enroll the patient in a new payment plan.</Text>
          </Pane>
          <Pane
            elevation={0}
            padding={16}
            margin={-16}
            marginTop={16}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {resumePlanStatus.loading ? (
              <Spinner delay={0} />
            ) : (
              <>
                <Button
                  height={48}
                  marginLeft={8}
                  appearance="minimal"
                  onClick={async () => {
                    close()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  height={48}
                  marginRight={8}
                  onClick={async () => {
                    await resumePlan()
                    close()
                  }}
                  intent="success"
                >
                  Resume & Charge
                </Button>
              </>
            )}
          </Pane>
        </>
      )}
    </Dialog>
  )
}

export default ResumeInstallmentPlanDialog
