import { library } from '@fortawesome/fontawesome-svg-core'

// SOLID
import { faUserPlus as faUserPlus_s } from '@fortawesome/pro-solid-svg-icons/faUserPlus'
import { faPaperPlane as faPaperPlane_s } from '@fortawesome/pro-solid-svg-icons/faPaperPlane'
import { faCheckCircle as faCheckCircle_s } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faUsdCircle } from '@fortawesome/pro-solid-svg-icons/faUsdCircle'
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faSearchMinus } from '@fortawesome/pro-solid-svg-icons/faSearchMinus'
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'
import { faArrowUpRightFromSquare, faUserGroup } from '@fortawesome/pro-solid-svg-icons'
import { faCheck as faCheck_s } from '@fortawesome/pro-solid-svg-icons'

import { faBan as faBan_s } from '@fortawesome/pro-solid-svg-icons/faBan'
import { faSortCircle } from '@fortawesome/pro-solid-svg-icons/faSortCircle'
import { faChevronUp as faChevronUp_s } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faChevronDown as faChevronDown_s } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronLeft as faChevronLeft_s } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight as faChevronRight_s } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch'
import { faUserFriends as faUserFriends_s } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faBolt as faBolt_s } from '@fortawesome/pro-solid-svg-icons/faBolt'

import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint'
import { faBrowser as faBrowser_s } from '@fortawesome/pro-solid-svg-icons/faBrowser'
import { faAd } from '@fortawesome/pro-solid-svg-icons/faAd'
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload'
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode'
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload'
import { faEnvelope as faEnvelope_s } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faUserCog } from '@fortawesome/pro-solid-svg-icons/faUserCog'

import { faLifeRing as faLifeRing_s } from '@fortawesome/pro-solid-svg-icons/faLifeRing'
import { faSave } from '@fortawesome/pro-solid-svg-icons/faSave'
import { faCommentAltLines as faCommentAltLines_s } from '@fortawesome/pro-solid-svg-icons/faCommentAltLines'
import { faRocketLaunch as faRocketLaunch_s } from '@fortawesome/pro-solid-svg-icons/faRocketLaunch'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faCreditCard as facCreditCard_s } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut'
import { faBadgeDollar as faBadgeDollar_s } from '@fortawesome/pro-solid-svg-icons/faBadgeDollar'
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter'
import { faLink as faLink_s } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faUnlink } from '@fortawesome/pro-solid-svg-icons/faUnlink'
import { faReceipt as faReceipt_s } from '@fortawesome/pro-solid-svg-icons/faReceipt'
import { faChartPieAlt as faChartPieAlt_s } from '@fortawesome/pro-solid-svg-icons/faChartPieAlt'
import { faHourglass as faHourglass_s } from '@fortawesome/pro-solid-svg-icons/faHourglass'
import { faUnlock as faUnlock_s } from '@fortawesome/pro-solid-svg-icons/faUnlock'
import { faMailBulk as faMailBulk_s } from '@fortawesome/pro-solid-svg-icons/faMailBulk'
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory'
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload'
import { faBookOpen } from '@fortawesome/pro-solid-svg-icons/faBookOpen'
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync'
import { faUsers as faUsers_s } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { faUndo as faUndo_s } from '@fortawesome/pro-solid-svg-icons/faUndo'
import { faCalculator } from '@fortawesome/pro-solid-svg-icons/faCalculator'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faPlayCircle as faPlayCircle_s } from '@fortawesome/pro-solid-svg-icons/faPlayCircle'
import { faUserClock as faUserClock_s } from '@fortawesome/pro-solid-svg-icons/faUserClock'
import {
  faMessageDots as faMessageDots_s,
  faMemoCircleInfo,
  faDollar,
  faSort,
  faLocationCheck,
  faBadgeCheck,
  faMailboxFlagUp as faMailboxFlagUp_s,
  faHand,
  faAt as faAt_s,
  faXmark,
  faEye,
  faPenToSquare as faPenToSquare_s,
  faTriangleExclamation as faTriangleExclamation_s
} from '@fortawesome/pro-solid-svg-icons'

// REGULAR
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronUp as faChevronUp_r } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faChevronDown as faChevronDown_r } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faCommentAltLines as faCommentAltLines_r } from '@fortawesome/pro-regular-svg-icons/faCommentAltLines'
import { faDollarSign as faDollarSign_r } from '@fortawesome/pro-regular-svg-icons/faDollarSign'
import { faStickyNote } from '@fortawesome/pro-regular-svg-icons/faStickyNote'

import { faIdCardAlt as faIdCardAlt_r } from '@fortawesome/pro-regular-svg-icons/faIdCardAlt'
import { faStoreAlt as faStoreAlt_r } from '@fortawesome/pro-regular-svg-icons/faStoreAlt'
import { faUsers as faUsers_r } from '@fortawesome/pro-regular-svg-icons/faUsers'
import { faBuildingColumns as faBuildingColumns_r } from '@fortawesome/pro-regular-svg-icons/'
import { faReceipt as faReceipt_r } from '@fortawesome/pro-regular-svg-icons/faReceipt'
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight'

import { faMobileAlt } from '@fortawesome/pro-regular-svg-icons/faMobileAlt'
import { faBan as faBan_r } from '@fortawesome/pro-regular-svg-icons/faBan'
import { faEnvelope as faEnvelope_r } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faCreditCard as facCreditCard_r } from '@fortawesome/pro-regular-svg-icons/faCreditCard'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink'
import { faMailBulk as faMailBulk_r } from '@fortawesome/pro-regular-svg-icons/faMailBulk'
import { faPlaneDeparture } from '@fortawesome/pro-regular-svg-icons/faPlaneDeparture'
import { faPlaneArrival } from '@fortawesome/pro-regular-svg-icons/faPlaneArrival'
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink'
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'
import { faFileChartPie as faFileChartPie_r } from '@fortawesome/pro-regular-svg-icons/faFileChartPie'
import { faMapLocation as faMapLocation_r } from '@fortawesome/pro-regular-svg-icons/faMapLocation'
import {
  faCircleDollar,
  faCircleBolt,
  faPenToSquare,
  faAt as faAt_r,
  faMailboxFlagUp as faMailboxFlagUp_r,
  faArrowDownToLine
} from '@fortawesome/pro-regular-svg-icons'

// LIGHT
import { faEnvelope as faEnvelope_l } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faEnvelopesBulk as faEnvelopesBulk_l } from '@fortawesome/pro-light-svg-icons/faEnvelopesBulk'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'

// DUOTONE
import { faFileSignature as faFileSignature_d } from '@fortawesome/pro-duotone-svg-icons/faFileSignature'
import { faUnlock as faUnlock_d } from '@fortawesome/pro-duotone-svg-icons/faUnlock'
import { faFilesMedical } from '@fortawesome/pro-duotone-svg-icons/faFilesMedical'
import { faHomeLgAlt } from '@fortawesome/pro-duotone-svg-icons/faHomeLgAlt'
import { faTooth } from '@fortawesome/pro-duotone-svg-icons/faTooth'
import { faChartLine } from '@fortawesome/pro-duotone-svg-icons/faChartLine'

import { faBuildingColumns as faBuildingColumns_d } from '@fortawesome/pro-duotone-svg-icons/'
import { faUsers as faUsers_d } from '@fortawesome/pro-duotone-svg-icons/faUsers'
import { faHome } from '@fortawesome/pro-duotone-svg-icons/faHome'
import { faStoreAlt as faStoreAlt_d } from '@fortawesome/pro-duotone-svg-icons/faStoreAlt'
import { faIdCardAlt as faIdCardAlt_d } from '@fortawesome/pro-duotone-svg-icons/faIdCardAlt'
import { faAnalytics } from '@fortawesome/pro-duotone-svg-icons/faAnalytics'
import { faCommentsAltDollar } from '@fortawesome/pro-duotone-svg-icons/faCommentsAltDollar'
import { faSearchDollar } from '@fortawesome/pro-duotone-svg-icons/faSearchDollar'
import { faLightbulbDollar } from '@fortawesome/pro-duotone-svg-icons/faLightbulbDollar'
import { faHouseUser } from '@fortawesome/pro-duotone-svg-icons/faHouseUser'
import { faLink as faLink_d } from '@fortawesome/pro-duotone-svg-icons/faLink'
import { faIdCard } from '@fortawesome/pro-duotone-svg-icons/faIdCard'
import { faMapLocation as faMapLocation_d } from '@fortawesome/pro-duotone-svg-icons/faMapLocation'
import { faCheckCircle as faCheckCircle_d } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle'
import { faMapMarkedAlt as faMapMarkedAlt_d } from '@fortawesome/pro-duotone-svg-icons/faMapMarkedAlt'

import { faPlus as faPlus_d } from '@fortawesome/pro-duotone-svg-icons/faPlus'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons/faCalendar'

import { faUserHeadset } from '@fortawesome/pro-duotone-svg-icons/faUserHeadset'
import { faLifeRing as faLifeRing_d } from '@fortawesome/pro-duotone-svg-icons/faLifeRing'
import { faSlidersH } from '@fortawesome/pro-duotone-svg-icons/faSlidersH'
import { faFileInvoiceDollar } from '@fortawesome/pro-duotone-svg-icons/faFileInvoiceDollar'
import { faPaperPlane as faPaperPlane_d } from '@fortawesome/pro-duotone-svg-icons/faPaperPlane'
import { faBrowser as faBrowser_d } from '@fortawesome/pro-duotone-svg-icons/faBrowser'
import { faUserPlus as faUserPlus_d } from '@fortawesome/pro-duotone-svg-icons/faUserPlus'
import { faTasks } from '@fortawesome/pro-duotone-svg-icons/faTasks'
import { faChartNetwork } from '@fortawesome/pro-duotone-svg-icons/faChartNetwork'
import { faLayerGroup } from '@fortawesome/pro-duotone-svg-icons/faLayerGroup'
import { faMoneyCheckAlt } from '@fortawesome/pro-duotone-svg-icons/faMoneyCheckAlt'
import { faPlayCircle as faPlayCircle_d } from '@fortawesome/pro-duotone-svg-icons/faPlayCircle'
import { faCreditCard as facCreditCard_d } from '@fortawesome/pro-duotone-svg-icons/faCreditCard'
import { faPhoneVolume } from '@fortawesome/pro-duotone-svg-icons/faPhoneVolume'
import { faHandHoldingUsd } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingUsd'
import { faChartPieAlt as faChartPieAlt_d } from '@fortawesome/pro-duotone-svg-icons/faChartPieAlt'
import { faBadgeDollar as faBadgeDollar_d } from '@fortawesome/pro-duotone-svg-icons/faBadgeDollar'

import { faUsersCrown } from '@fortawesome/pro-duotone-svg-icons/faUsersCrown'
import { faChartArea } from '@fortawesome/pro-duotone-svg-icons/faChartArea'
import { faFingerprint } from '@fortawesome/pro-duotone-svg-icons/faFingerprint'

import { faUserAstronaut } from '@fortawesome/pro-duotone-svg-icons/faUserAstronaut'
import { faUserClock as faUserClock_d } from '@fortawesome/pro-duotone-svg-icons/faUserClock'
import { faUserCrown } from '@fortawesome/pro-duotone-svg-icons/faUserCrown'
import { faUserTimes } from '@fortawesome/pro-duotone-svg-icons/faUserTimes'
import { faUserCheck } from '@fortawesome/pro-duotone-svg-icons/faUserCheck'
import { faUserTag } from '@fortawesome/pro-duotone-svg-icons/faUserTag'
import { faUndo as faUndo_d } from '@fortawesome/pro-duotone-svg-icons/faUndo'
import { faPalette } from '@fortawesome/pro-duotone-svg-icons/faPalette'
import { faLaptop } from '@fortawesome/pro-duotone-svg-icons/faLaptop'
import { faSolarSystem } from '@fortawesome/pro-duotone-svg-icons/faSolarSystem'
import { faRocketLaunch as faRocketLaunch_d } from '@fortawesome/pro-duotone-svg-icons/faRocketLaunch'
import { faEnvelope as faEnvelope_d } from '@fortawesome/pro-duotone-svg-icons/faEnvelope'
import { faPhone as faPhone_d } from '@fortawesome/pro-duotone-svg-icons/faPhone'
import { faBirthdayCake } from '@fortawesome/pro-duotone-svg-icons/faBirthdayCake'

import { faUserFriends as faUserFriends_d } from '@fortawesome/pro-duotone-svg-icons/faUserFriends'
import { faBan } from '@fortawesome/pro-duotone-svg-icons/faBan'
import { faBolt as faBolt_d } from '@fortawesome/pro-duotone-svg-icons/faBolt'
import { faHourglass as faHourglass_d } from '@fortawesome/pro-duotone-svg-icons/faHourglass'
import { faFileChartPie as faFileChartPie_d } from '@fortawesome/pro-duotone-svg-icons/faFileChartPie'
import { faMessageDots as faMessageDots_d } from '@fortawesome/pro-duotone-svg-icons'
import { faTriangleExclamation as faTriangleExclamation_d } from '@fortawesome/pro-duotone-svg-icons'
import {
  faChevronsRight,
  faGear,
  faMagnifyingGlassLocation,
  faGrid2,
  faMailboxFlagUp as faMailboxFlagUp_d,
  faForward as faForward_d,
  faLocationXmark,
  faChevronsDown,
  faMapMarkedAlt,
  faEnvelopesBulk,
  faStopCircle as faCircleStop_d,
  faArrowRightLong as faArrowRightLong_d,
  faAt as faAt_d,
  faCheckDouble,
  faSquareCheck as faSquareCheck_d,
  faClock as faClock_d,
  faPenToSquare as faPenToSquare_d,
  faUserRobot
} from '@fortawesome/pro-duotone-svg-icons'

// BRAND
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faStripeS } from '@fortawesome/free-brands-svg-icons/faStripeS'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons/faMicrosoft'

import { iconLibrary } from 'lib'

export const initializeIconLibrary = () => {
  library.add(
    ...iconLibrary,
    faBirthdayCake,
    faEnvelope_d,
    faPhone_d,
    faGoogle,
    faFacebookSquare,
    faLinkedin,
    faUnlock_d,
    faUnlock_s,
    faChevronDown_r,
    faChevronDown_s,
    faFilesMedical,
    faUserPlus_s,
    faUserPlus_d,
    faPaperPlane_s,
    faCheckCircle_d,
    faCheckCircle_s,
    faUsdCircle,
    faUserCircle,
    faCommentAltLines_s,
    faCommentAltLines_r,
    faHomeLgAlt,
    faTooth,
    faChartLine,
    faChevronLeft,
    faChevronRight,
    faStripeS,

    faEnvelope_l,
    faEnvelopesBulk_l,
    faLock,
    faSearch,
    faPencilAlt,
    faTrashAlt,
    faCheck_s,
    faDollarSign_r,
    faStickyNote,
    faPrint,
    faChevronUp_r,
    faChevronUp_s,
    faPlus_d,
    faRocketLaunch_s,
    faCalendar,
    faUserHeadset,
    faTwitter,
    faBrowser_s,
    faBrowser_d,
    faAd,
    faUpload,
    faCode,
    faFileDownload,
    faEnvelope_s,
    faPhone,
    faIdCardAlt_r,
    faStoreAlt_r,
    faUsers_r,
    faBuildingColumns_r,
    faIdCardAlt_d,
    faStoreAlt_d,
    faHome,
    faUsers_d,
    faBuildingColumns_d,
    faFilePlus,
    faPlus,
    faLifeRing_s,
    faLifeRing_d,
    faSave,
    faSortCircle,
    faBan_s,
    faBan_r,
    faAnalytics,
    faChevronLeft_s,
    faChevronRight_s,
    faCommentsAltDollar,
    faSlidersH,
    faFileInvoiceDollar,
    faUser,
    faReceipt_r,
    faReceipt_s,
    facCreditCard_d,
    facCreditCard_s,
    faSignOut,
    faLongArrowRight,
    faSearchMinus,
    faPaperPlane_d,
    faCircleNotch,
    faAt_s,
    faAt_r,
    faMobileAlt,
    faTasks,
    faApple,
    faMicrosoft,
    faBadgeDollar_s,
    faBadgeDollar_d,
    faChartNetwork,
    faLayerGroup,
    faMoneyCheckAlt,
    faFilter,
    faPlayCircle_d,
    faPlayCircle_s,
    faPhoneVolume,
    faHandHoldingUsd,
    faLink_s,
    faLink_d,
    faUnlink,
    faTimes,
    faChartPieAlt_d,
    faChartPieAlt_s,
    faSearchDollar,
    faLightbulbDollar,
    faUsersCrown,
    faChartArea,
    faHourglass_s,
    faHourglass_d,
    faEnvelope_r,
    facCreditCard_r,
    faMailBulk_r,
    faHistory,
    faFingerprint,
    faUserAstronaut,
    faUserClock_s,
    faUserClock_d,
    faUserCrown,
    faUserTimes,
    faUserCheck,
    faDownload,
    faBookOpen,
    faSync,
    faUserTag,
    faHouseUser,
    faUserFriends_d,
    faUserFriends_s,
    faUsers_s,
    faUndo_s,
    faUndo_d,
    faPalette,
    faLaptop,
    faSolarSystem,
    faRocketLaunch_d,
    faExternalLink,
    faCalculator,
    faBan,
    faPencil,
    faMailBulk_s,
    faPlaneDeparture,
    faPlaneArrival,
    faIdCard,
    faUserCog,
    faBolt_d,
    faBolt_s,
    faLink,
    faMoneyCheckAlt,
    faFileSignature_d,
    faFileChartPie_d,
    faCircle,
    faFileChartPie_r,
    faMapLocation_r,
    faMapLocation_d,
    faMapMarkedAlt_d,
    faMessageDots_s,
    faMessageDots_d,
    faMemoCircleInfo,
    faTriangleExclamation_s,
    faTriangleExclamation_d,
    faUserGroup,
    faChevronsRight,
    faGear,
    faMagnifyingGlassLocation,
    faCircleDollar,
    faCircleBolt,
    faPenToSquare,
    faGrid2,
    faDollar,
    faSort,
    faMailboxFlagUp_d,
    faMailboxFlagUp_s,
    faLocationCheck,
    faLocationXmark,
    faChevronsDown,
    faBadgeCheck,
    faMapMarkedAlt,
    faHand,
    faMailboxFlagUp_r,
    faArrowDownToLine,
    faEnvelopesBulk,
    faCircleStop_d,
    faArrowRightLong_d,
    faAt_d,
    faXmark,
    faEye,
    faPenToSquare_s,
    faForward_d,
    faArrowUpRightFromSquare,
    faCheckDouble,
    faSquareCheck_d,
    faPenToSquare_d,
    faClock_d,
    faUserRobot
  )
}
