import { Types } from 'lib'

export const getPrettyTransactionType = ({
  type,
  stripeId_subscription,
  installmentPlanId
}: {
  type: Types.TransactionType
  stripeId_subscription: string | null
  installmentPlanId: string | null
}) => {
  if ((stripeId_subscription || installmentPlanId) && type === Types.TransactionType.PEARLY_PAYMENT)
    return 'Pay-Over-Time'

  return ({
    [Types.TransactionType.ADJUSTMENT]: 'Adjustment',
    [Types.TransactionType.CLAIM_DENIED]: 'Claim Denied',
    [Types.TransactionType.COLLECTION_ADJUSTMENT]: 'Adjustment',
    [Types.TransactionType.INSURANCE_PAYMENT]: 'Insurance Payment',
    [Types.TransactionType.OTHER_ADJUSTMENT]: 'Adjustment',
    [Types.TransactionType.PAYMENT]: 'Payment',
    [Types.TransactionType.PEARLY_ADJUSTMENT]: 'Adjustment',
    [Types.TransactionType.PEARLY_ADMIN_FEE]: 'Enrollment Fee',
    [Types.TransactionType.PEARLY_BILL]: 'Bill',
    [Types.TransactionType.PEARLY_MEMBERSHIP]: 'Membership',
    [Types.TransactionType.PEARLY_PAYMENT]: 'Payment',
    [Types.TransactionType.PEARLY_PLAN]: 'Pay-Over-Time',
    [Types.TransactionType.PEARLY_REFUND]: 'Refund',
    [Types.TransactionType.PROCEDURE]: 'Procedure'
  } as Record<Types.TransactionType, string>)[type]
}
