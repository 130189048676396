import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { Pane, Dialog, Text, toaster } from 'evergreen-ui'

import { TextInputField } from 'lib'
import { FormError } from 'lib'

import moment from 'moment'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>

  title: string
  body: string
  confirmLabel?: string
  onSubmit: ({ day, month, year }: { day: number; month: number; year: number }) => void
}

const SetDateDialog = ({ isShown, setIsShown, title, body, confirmLabel, onSubmit }: Props) => (
  <Formik
    initialValues={{
      day: (undefined as unknown) as number,
      month: (undefined as unknown) as number,
      year: (undefined as unknown) as number
    }}
    onSubmit={(values) => onSubmit(values)}
    validationSchema={Yup.object({
      month: Yup.number()
        .required('Month is required')
        .integer('Please provide a valid month')
        .min(1, 'Please provide a valid month')
        .max(12, 'Please provide a valid month'),
      day: Yup.number()
        .required('Day is required')
        .integer('Please provide a valid day')
        .min(1, 'Please provide a valid day')
        .max(31, 'Please provide a valid day'),
      year: Yup.number()
        .required('Year is required')
        .integer('Please provide a valid year')
        .min(1900, 'Please provide a valid year')
    })}
    render={({ handleSubmit, values }) => (
      <Dialog
        isShown={isShown}
        title={title}
        confirmLabel={confirmLabel}
        onCloseComplete={() => {
          setIsShown(false)
        }}
        onConfirm={(close) => {
          const { day, month, year } = values
          const dateMoment = moment({ day, month: month - 1, year })
          if (dateMoment.isSameOrBefore(moment().add(1, 'hour'))) toaster.danger('Please provide a date in the future')
          else {
            handleSubmit()
            close()
          }
        }}
      >
        <Form style={{ height: '100%' }}>
          <Pane paddingX={16} paddingTop={16} display="flex" flexDirection="column" alignItems="center">
            <Text size={500} display="block" marginBottom={16} textAlign="center">
              {body}
            </Text>
            <Pane display="flex" maxWidth={300}>
              <TextInputField
                name="month"
                type="number"
                flex={1}
                placeholder="MM"
                marginRight={16}
                marginBottom={0}
                textAlign="center"
                hint="Month"
              />
              <TextInputField
                name="day"
                type="number"
                flex={1}
                placeholder="DD"
                marginRight={16}
                marginBottom={0}
                textAlign="center"
                hint="Day"
              />
              <TextInputField
                name="year"
                type="number"
                flex={2}
                placeholder="YYYY"
                textAlign="center"
                hint="Year"
                marginBottom={0}
              />
            </Pane>
            <FormError />
          </Pane>
        </Form>
      </Dialog>
    )}
  />
)

export default SetDateDialog
