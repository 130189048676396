import 'graphql'
import gql from 'graphql-tag'

export const RESUME_INSTALLMENT_PLAN = gql`
  mutation ResumeInstallment($installmentPlanId: String!) {
    resumeInstallmentPlan(installmentPlanId: $installmentPlanId) {
      id
      status
    }
  }
`

