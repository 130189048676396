const SunbitLogo = () => (
  <svg
    id="Logo_Sunbit_white"
    data-name="Logo/Sunbit/white"
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 140 39.252"
  >
    <defs>
      <clipPath id="clip-path">
        <path id="Clip_4" data-name="Clip 4" d="M0,0H7.414V10.9H0Z" fill="none" />
      </clipPath>
      <clipPath id="clip-path-5">
        <path id="Clip_28" data-name="Clip 28" d="M0,0H10.9V7.414H0Z" fill="none" />
      </clipPath>
      <clipPath id="clip-path-6">
        <path id="Clip_37" data-name="Clip 37" d="M0,0H10.467V25.732H0Z" fill="none" />
      </clipPath>
    </defs>
    <g id="Group_45" data-name="Group 45">
      <path
        id="Fill_1"
        data-name="Fill 1"
        d="M0,1.451c.007.014.008.03.015.044l4.5,8.574a.745.745,0,0,0,1.089.261A19.946,19.946,0,0,0,10.33,5.606a.745.745,0,0,0-.261-1.09L1.5.012C1.485.006,1.471.005,1.459,0A9.371,9.371,0,0,1,0,1.451"
        transform="translate(25.732 25.296)"
        fill="#3155a5"
      />
      <g id="Group_5" data-name="Group 5" transform="translate(20.935 28.349)">
        <path id="Clip_4-2" data-name="Clip 4" d="M0,0H7.414V10.9H0Z" fill="none" />
        <g id="Group_5-2" data-name="Group 5" clip-path="url(#clip-path)">
          <path
            id="Fill_3"
            data-name="Fill 3"
            d="M.371.6,0,9.982a.876.876,0,0,0,.949.917A18.723,18.723,0,0,0,6.888,9.293a.924.924,0,0,0,.386-1.324L2.361.057C2.349.037,2.333.019,2.319,0A8.828,8.828,0,0,1,.381.514.816.816,0,0,0,.371.6"
            transform="translate(0)"
            fill="#3155a5"
          />
        </g>
      </g>
      <path
        id="Fill_6"
        data-name="Fill 6"
        d="M10.069,0l-9.5.37C.559.371.547.377.534.378A8.734,8.734,0,0,1,0,2.306c.022.017.042.033.065.048L8.071,7.293a.812.812,0,0,0,1.158-.334A18.509,18.509,0,0,0,10.9.854.792.792,0,0,0,10.069,0"
        transform="translate(28.349 20.935)"
        fill="#3155a5"
      />
      <path
        id="Fill_8"
        data-name="Fill 8"
        d="M9.008,0C9,.006,8.983.007,8.97.012L.4,4.516a.745.745,0,0,0-.261,1.09A19.944,19.944,0,0,0,4.861,10.33a.744.744,0,0,0,1.089-.261l4.5-8.574c.007-.014.008-.03.015-.045A9.345,9.345,0,0,1,9.008,0"
        transform="translate(3.489 25.296)"
        fill="#3155a5"
      />
      <g id="Group_12" data-name="Group 12" transform="translate(10.903 28.349)">
        <path id="Clip_11-2" data-name="Clip 11" d="M0,0H7.414V10.9H0Z" fill="none" />
        <g id="Group_12-2" data-name="Group 12" clip-path="url(#clip-path)">
          <path
            id="Fill_10"
            data-name="Fill 10"
            d="M5.053.067.14,7.972A.922.922,0,0,0,.525,9.295a18.742,18.742,0,0,0,5.94,1.6.876.876,0,0,0,.948-.916L7.044.608A.735.735,0,0,0,7.032.519,8.87,8.87,0,0,1,5.1,0a.791.791,0,0,0-.05.067"
            transform="translate(0)"
            fill="#3155a5"
          />
        </g>
      </g>
      <path
        id="Fill_13"
        data-name="Fill 13"
        d="M10.332.371.835,0A.792.792,0,0,0,0,.854a18.491,18.491,0,0,0,1.671,6.1.812.812,0,0,0,1.157.334l8.006-4.939a.594.594,0,0,0,.065-.048A8.751,8.751,0,0,1,10.37.379c-.014,0-.025-.007-.038-.008"
        transform="translate(0 20.935)"
        fill="#3155a5"
      />
      <g id="Group_17" data-name="Group 17" transform="translate(20.935)">
        <path id="Clip_16-2" data-name="Clip 16" d="M0,0H7.414V10.9H0Z" fill="none" />
        <g id="Group_17-2" data-name="Group 17" clip-path="url(#clip-path)">
          <path
            id="Fill_15"
            data-name="Fill 15"
            d="M2.361,10.847,7.274,2.935A.924.924,0,0,0,6.888,1.61,18.737,18.737,0,0,0,.949,0,.876.876,0,0,0,0,.921L.371,10.3a.827.827,0,0,0,.01.085,8.828,8.828,0,0,1,1.938.514c.014-.019.03-.037.042-.057"
            transform="translate(0 0)"
            fill="#f6931d"
          />
        </g>
      </g>
      <path
        id="Fill_18"
        data-name="Fill 18"
        d="M1.48,10.467l.011,0,8.578-4.507a.746.746,0,0,0,.261-1.091A19.986,19.986,0,0,0,5.6.137.746.746,0,0,0,4.513.4L.007,8.98C0,8.987,0,8.995,0,9a9.386,9.386,0,0,1,1.48,1.465"
        transform="translate(25.732 3.489)"
        fill="#f6931d"
      />
      <path
        id="Fill_20"
        data-name="Fill 20"
        d="M.546,7.043l9.521.371a.793.793,0,0,0,.833-.854A18.469,18.469,0,0,0,9.224.455.815.815,0,0,0,8.064.121L.039,5.06c-.014.008-.025.019-.039.029A8.728,8.728,0,0,1,.544,7.042h0"
        transform="translate(28.349 10.903)"
        fill="#f6931d"
      />
      <path
        id="Fill_22"
        data-name="Fill 22"
        d="M10.467,9.016c-.007-.014-.008-.03-.015-.043L5.95.4A.745.745,0,0,0,4.861.137,19.96,19.96,0,0,0,.137,4.862.745.745,0,0,0,.4,5.951l8.572,4.5c.012.007.026.007.038.012a9.4,9.4,0,0,1,1.459-1.452"
        transform="translate(3.489 3.489)"
        fill="#f6931d"
      />
      <g id="Group_26" data-name="Group 26" transform="translate(10.903)">
        <path id="Clip_25-2" data-name="Clip 25" d="M0,0H7.414V10.9H0Z" fill="none" />
        <g id="Group_26-2" data-name="Group 26" clip-path="url(#clip-path)">
          <path
            id="Fill_24"
            data-name="Fill 24"
            d="M7.043,10.3,7.414.92A.876.876,0,0,0,6.465,0a18.751,18.751,0,0,0-5.939,1.6A.923.923,0,0,0,.14,2.932l4.913,7.9a.832.832,0,0,0,.05.067,8.908,8.908,0,0,1,1.93-.52.837.837,0,0,0,.01-.088"
            transform="translate(0 0)"
            fill="#f6931d"
          />
        </g>
      </g>
      <g id="Group_29" data-name="Group 29" transform="translate(0 10.903)">
        <path id="Clip_28-2" data-name="Clip 28" d="M0,0H10.9V7.414H0Z" fill="none" />
        <g id="Group_29-2" data-name="Group 29" clip-path="url(#clip-path-5)">
          <path
            id="Fill_27"
            data-name="Fill 27"
            d="M10.838,5.06,2.833.121A.812.812,0,0,0,1.675.455,18.508,18.508,0,0,0,0,6.559a.792.792,0,0,0,.831.854l9.5-.371c.014,0,.025-.006.038-.007A8.729,8.729,0,0,1,10.9,5.108a.786.786,0,0,0-.065-.048"
            transform="translate(0 0)"
            fill="#f6931d"
          />
        </g>
      </g>
      <path
        id="Fill_30"
        data-name="Fill 30"
        d="M12.057,10.279a4.745,4.745,0,0,1-.979,3.087,3.3,3.3,0,0,1-2.8,1.208c-2.125,0-3.29-1.176-3.29-3.528V.716A.716.716,0,0,0,4.275,0H.593A.593.593,0,0,0,0,.592v11.22A7.934,7.934,0,0,0,.48,14.65,6.7,6.7,0,0,0,1.8,16.837,5.744,5.744,0,0,0,3.82,18.255a6.548,6.548,0,0,0,2.592.5c2.6,0,4.541-.71,5.823-2.75.015-.025.052-.118.052-.089V17.53a.726.726,0,0,0,.726.725h3.22a.775.775,0,0,0,.776-.774V.793A.794.794,0,0,0,16.215,0H12.8a.747.747,0,0,0-.748.747Z"
        transform="translate(61.059 13.956)"
        fill="#191d63"
      />
      <path
        id="Fill_32"
        data-name="Fill 32"
        d="M10.121,26.168c-2.5,0-4.179-.832-5.272-2.618l-.027,1.5a.624.624,0,0,1-.619.612H.619A.621.621,0,0,1,0,25.042V.623A.622.622,0,0,1,.619,0H4.307a.621.621,0,0,1,.618.623V9.088a5.333,5.333,0,0,1,2.78-1.928,9.418,9.418,0,0,1,2.839-.436,7.34,7.34,0,0,1,3.291.716,6.937,6.937,0,0,1,2.443,2.034,9.454,9.454,0,0,1,1.52,3.118,10.175,10.175,0,0,1,.52,3.351,13.958,13.958,0,0,1-.616,4.3,9.83,9.83,0,0,1-1.693,3.215,7.235,7.235,0,0,1-2.6,2.013A7.84,7.84,0,0,1,10.121,26.168ZM9.35,10.887a4.259,4.259,0,0,0-3.27,1.392,6.138,6.138,0,0,0-1.347,4.176,6.569,6.569,0,0,0,1.213,4.1,3.741,3.741,0,0,0,3.021,1.511,4.036,4.036,0,0,0,3.286-1.607,5.283,5.283,0,0,0,.86-1.763,8.324,8.324,0,0,0,.264-2.238A6.427,6.427,0,0,0,12.055,12.2,3.611,3.611,0,0,0,9.35,10.887Z"
        transform="translate(100.312 6.106)"
        fill="#191d63"
      />
      <path
        id="Fill_34"
        data-name="Fill 34"
        d="M.689,18.318h3.42A.7.7,0,0,0,4.8,17.6V.718A.7.7,0,0,0,4.109,0H.689A.7.7,0,0,0,0,.718V17.6a.7.7,0,0,0,.689.718"
        transform="translate(120.374 13.956)"
        fill="#191d63"
      />
      <g id="Group_38" data-name="Group 38" transform="translate(127.788 6.542)">
        <path id="Clip_37-2" data-name="Clip 37" d="M0,0H10.467V25.732H0Z" transform="translate(0 0)" fill="none" />
        <g id="Group_38-2" data-name="Group 38" transform="translate(0 0)" clip-path="url(#clip-path-6)">
          <path
            id="Fill_36"
            data-name="Fill 36"
            d="M10.467,21.835a.873.873,0,0,0-.883-.864l-2.041.008c-3.247,0-2.518-3.362-2.518-6.078V11.757H8.681a.872.872,0,0,0,.881-.864V8.1a.872.872,0,0,0-.881-.863H5.025V.864A.872.872,0,0,0,4.145,0H.887A.873.873,0,0,0,.006.864V18.118c0,1.026-.491,7.614,7.459,7.614.171,0,.338,0,.5-.009H9.586a.873.873,0,0,0,.881-.864Z"
            transform="translate(0)"
            fill="#191d63"
          />
        </g>
      </g>
      <path
        id="Fill_39"
        data-name="Fill 39"
        d="M5.67,2.835A2.835,2.835,0,1,1,2.835,0,2.835,2.835,0,0,1,5.67,2.835"
        transform="translate(119.938 5.67)"
        fill="#191d63"
      />
      <path
        id="Fill_41"
        data-name="Fill 41"
        d="M4.953,8.475a4.745,4.745,0,0,1,.979-3.087,3.511,3.511,0,0,1,2.9-1.208q3.187,0,3.187,3.528v10.33a.716.716,0,0,0,.717.716h3.681a.592.592,0,0,0,.593-.592V6.942A7.932,7.932,0,0,0,16.53,4.1a6.707,6.707,0,0,0-1.325-2.186A5.761,5.761,0,0,0,13.189.5,6.556,6.556,0,0,0,10.6,0,6.412,6.412,0,0,0,4.775,3.061a.028.028,0,0,1-.052-.014V1.224A.726.726,0,0,0,4,.5H.776A.776.776,0,0,0,0,1.274V17.96a.8.8,0,0,0,.8.794H4.2a.747.747,0,0,0,.748-.747Z"
        transform="translate(80.249 13.52)"
        fill="#191d63"
      />
      <path
        id="Fill_43"
        data-name="Fill 43"
        d="M8.795,20.062a11.076,11.076,0,0,1-4.512-.875,7.681,7.681,0,0,1-3.1-2.379A6.274,6.274,0,0,1,.006,14.131a.388.388,0,0,1,.381-.458H5.2a.388.388,0,0,1,.371.282,2.55,2.55,0,0,0,.949,1.354,3.565,3.565,0,0,0,2.209.685,3.16,3.16,0,0,0,1.869-.486,1.582,1.582,0,0,0,.707-1.337,1.542,1.542,0,0,0-1.007-1.463,18.074,18.074,0,0,0-2.9-.924,26.49,26.49,0,0,1-3.51-1.045A5.924,5.924,0,0,1,1.529,9.05,4.607,4.607,0,0,1,.556,5.961a5.331,5.331,0,0,1,.9-3A6.134,6.134,0,0,1,4.125.8,10.313,10.313,0,0,1,8.371,0a8.464,8.464,0,0,1,5.715,1.806,6.915,6.915,0,0,1,2.286,4.1.373.373,0,0,1-.078.3.388.388,0,0,1-.3.146h-4.41a.388.388,0,0,1-.376-.271,2.6,2.6,0,0,0-.885-1.326A3.318,3.318,0,0,0,8.194,4.1a2.95,2.95,0,0,0-1.746.45,1.528,1.528,0,0,0-.654,1.3,1.577,1.577,0,0,0,1.023,1.48,14.546,14.546,0,0,0,2.853.872,30.455,30.455,0,0,1,3.538,1.115,5.986,5.986,0,0,1,2.34,1.726,4.9,4.9,0,0,1,1.026,3.165,5.082,5.082,0,0,1-.932,2.993,6.253,6.253,0,0,1-2.7,2.088,10.439,10.439,0,0,1-4.143.769"
        transform="translate(42.741 12.212)"
        fill="#191d63"
      />
    </g>
  </svg>
)

export default SunbitLogo
