import { useQuery } from '@apollo/client'
import { GET_GUARANTOR_OVERVIEW } from 'graphql/_guarantor-sheet'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { Pane, Text, CardProps } from 'evergreen-ui'
import {
  Types,
  Card,
  colors,
  Icon,
  Spinner,
  toDollarsFromCents,
  getPrettySubscriptionStatus,
  getSubscriptionStatusColor
} from 'lib'
import GuarantorActionItemsCard from 'components/guarantor-action-items-card'

import { getPrettyBalanceStatus, getBillingStatusColor } from 'utility/label-maps'
import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'
import { useGlobal } from 'components/global-provider'
import ContactIndicator from 'components/contact-indicator'

export type Props = {
  guarantorId: string
}

const GuarantorOverview = ({ guarantorId }: Props) => {
  const { updateGuarantorSheetState } = useGuarantorSheetState()

  const global = useGlobal()
  const capabilities = global.account.capabilities

  const { data } = useQuery<Types.GuarantorOverview, Types.GuarantorOverviewVariables>(GET_GUARANTOR_OVERVIEW, {
    variables: {
      id: guarantorId
    }
  })

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  const guarantor = data.contact
  const { balance, stripe, dependents } = guarantor

  const balanceStatus = balance.engagementDetails?.status
  const membershipStatus = stripe?.membership?.status

  const dependentsCount = dependents.length ?? 0

  return (
    <Pane padding={16}>
      <Pane display="grid" gridTemplateColumns="1fr 1fr" gridGap={4}>
        {capabilities.includes(Types.AccountCapability.BILLING) && (
          <StatusCard
            icon={['fad', 'comments-alt-dollar']}
            iconColor={balanceStatus && getBillingStatusColor(balanceStatus)}
            label={`${toDollarsFromCents(balance.metrics.patientPortion)} ${
              balanceStatus ? getPrettyBalanceStatus(balanceStatus) : 'Balance'
            }`}
            borderTopLeftRadius={5}
            path={Types.GuarantorPath.BILLING}
          />
        )}
        <StatusCard
          icon={['fad', 'files-medical']}
          iconColor={membershipStatus && getSubscriptionStatusColor(membershipStatus)}
          label={membershipStatus ? getPrettySubscriptionStatus(membershipStatus) : 'Not Enrolled'}
          borderTopRightRadius={5}
          path={Types.GuarantorPath.PLANS}
          planType={Types.PlanType.MEMBERSHIP}
        />
        <StatusCard
          icon={['fad', dependentsCount > 2 ? 'users' : 'user-friends']}
          iconColor={dependentsCount > 0 ? colors.green.base : undefined}
          label={`${dependentsCount} Dependent${dependentsCount === 1 ? '' : 's'}`}
          path={Types.GuarantorPath.DEPENDENTS}
          disabled={dependentsCount === 0}
        />

        <Card
          padding={16}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          elevation={0}
          hoverElevation={1}
          cursor="pointer"
          borderRadius={0}
          borderBottomRightRadius={5}
          onClick={() => updateGuarantorSheetState({ path: Types.GuarantorPath.PROFILE })}
        >
          <ContactIndicator.Inline contact={guarantor} showDob showPms />
        </Card>
      </Pane>

      <GuarantorActionItemsCard
        guarantorId={guarantorId}
        items={[
          Types.GuarantorActionItem.NOTIFICATION,
          Types.GuarantorActionItem.OUTREACH,
          Types.GuarantorActionItem.CHARGE,
          Types.GuarantorActionItem.ENROLL_INSTALLMENTS,
          Types.GuarantorActionItem.ENROLL_MEMBER,
          Types.GuarantorActionItem.PAYMENT_INFO,
          Types.GuarantorActionItem.PROFILE_INFO,
          Types.GuarantorActionItem.SUNBIT_TEXT
        ]}
        marginTop={16}
        elevation={0}
      />
    </Pane>
  )
}

interface StatusCardProps extends CardProps {
  path: Types.GuarantorPath
  planType?: Types.PlanType
  icon: IconProp
  iconColor?: string | null
  label: string
  disabled?: boolean
}

const StatusCard = ({ path, planType, icon, iconColor, label, disabled, ...props }: StatusCardProps) => {
  const { updateGuarantorSheetState } = useGuarantorSheetState()

  return (
    <Card
      {...props}
      paddingY={16}
      paddingX={12}
      display="flex"
      flexDirection="row"
      elevation={0}
      hoverElevation={disabled ? undefined : 1}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      borderRadius={0}
      onClick={() => {
        if (!disabled) updateGuarantorSheetState({ path, planType })
      }}
    >
      <Pane width="24px">
        <Icon icon={icon} color={iconColor ?? colors.neutral.muted} />
      </Pane>

      <Text>{label}</Text>
    </Card>
  )
}

export default GuarantorOverview
