import { Heading, Pane, Badge, Strong, Text } from 'evergreen-ui'
import { Card, CardHeader, colors } from 'lib'

interface Props {
  address: {
    street1: string
    street2: string | null
    city: string
    state: string
    zip: string
  }
}

const VerifiedAddressCard = ({ address: { street1, street2, city, state, zip } }: Props) => (
  <Card padding={0} elevation={1}>
    <CardHeader justifyContent="space-between">
      <Heading>Verified Address</Heading>
      <Badge color="green">Deliverable</Badge>
    </CardHeader>
    <Pane padding={24} display="grid" gridGap={8} gridTemplateColumns="48px 1fr">
      <Strong color={colors.blue.base}>Street</Strong>
      <Text>
        {street1}
        <br />
        {street2}
      </Text>
      <Strong color={colors.blue.base}>City</Strong>
      <Text>{city}</Text>
      <Strong color={colors.blue.base}>State</Strong>
      <Text>{state}</Text>
      <Strong color={colors.blue.base}>Zip</Strong>
      <Text>{zip}</Text>
    </Pane>
  </Card>
)

export default VerifiedAddressCard
