import { Heading, Pane } from 'evergreen-ui'
import { Button, Types } from 'lib'

type Props = {
  state: Types.SendNotificationState
  guarantor: Types.GetSendNotificationCard['contact']
  templates: Types.GetSendNotificationCard_contact_account_notificationTemplates[]
  onBack: () => void
  onNext: (templateId: string) => void
}

const SendNotificationTemplate = ({ state: { channel }, templates, onBack, onNext }: Props) => {
  if (!channel) throw Error('Incomplete client state')

  return (
    <>
      <Pane padding={16} display="flex" flexDirection="column" alignItems="center" gap={8}>
        <Heading marginTop={8} marginBottom={16}>
          Choose a Template:
        </Heading>
        {templates.map((template) => (
          <Button key={template.id} height={48} width="100%" rightChevron onClick={() => onNext(template.id)}>
            {template.name}
          </Button>
        ))}
      </Pane>
      <Pane display="flex" justifyContent="space-between" width="100%" elevation={0} padding={16}>
        <Button type="button" justifyContent="center" height={48} onClick={onBack} appearance="minimal">
          Back
        </Button>
      </Pane>
    </>
  )
}

export default SendNotificationTemplate
