import { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { TOGGLE_INSTALLMENT_TEMPLATE } from 'graphql/_installment-template-toggle'

import { Types } from 'lib'

import { Pane, Label, toaster, Switch as EvergreenSwitch, PaneProps } from 'evergreen-ui'

import { useCheckMinRole } from 'utility/roles'

export interface Props extends PaneProps {
  installmentTemplate: {
    id: string
    enabled: boolean
  }
}

const InstallmentTemplateToggle = ({ installmentTemplate: template, ...props }: Props) => {
  const checkMinRole = useCheckMinRole()
  const [enabledState, setEnabledState] = useState(template.enabled)

  useEffect(() => {
    setEnabledState(template.enabled)
  }, [template.enabled])

  const [toggleTemplate, toggleStatus] = useMutation<
    Types.ToggleInstallmentTemplate,
    Types.ToggleInstallmentTemplateVariables
  >(TOGGLE_INSTALLMENT_TEMPLATE, {
    onCompleted: (data) => {
      if (data.toggleInstallmentTemplate.enabled) {
        toaster.success(`Installment schedule enabled!`)
      } else {
        toaster.danger(`Installment schedule disabled!`)
      }
    },
    onError: () => toaster.danger('Unable to toggle installment schedule')
  })

  return (
    <Pane display="flex" alignItems="center" {...props}>
      <Label marginRight={8}>Enabled</Label>
      <EvergreenSwitch
        marginBottom={0}
        height={24}
        onChange={({ currentTarget: { checked } }) => {
          setEnabledState(checked)
          toggleTemplate({ variables: { id: template.id, enabled: checked } })
        }}
        checked={enabledState}
        disabled={!checkMinRole(Types.UserRole.STANDARD) || toggleStatus.loading}
      />
    </Pane>
  )
}

export default InstallmentTemplateToggle
