import styled from 'styled-components/macro'
import moment from 'moment'
import { Types } from 'lib'
import { title } from 'case'
import { useMutation } from '@apollo/client'
import { DENY_CLAIMS } from 'lib/graphql/_balance-activity-sheet'
import { orderBy, uniq } from 'lodash'

import { SideSheet, Pane, Text, Heading, Strong, Table, Badge, Alert, toaster } from 'evergreen-ui'

import { Card, CardHeader, Button, Icon, FileLink, Link } from 'lib'

import { colors } from 'lib'
import { toDollarsFromCents } from 'lib'
import { cdtData } from 'lib'

import { useMediaQueryContext } from 'lib'
import { useWindowSize } from 'lib'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void

  isPatientApp?: boolean
  guarantorId: string
  label: string

  date: number
  patient: {
    name: {
      first: string
      last: string
    }
    dob?: {
      day: number
      month: number
      year: number
    } | null
  }
  isPendingPrimary: boolean
  isPendingSecondary: boolean
  isDenied: boolean
  uploadPaths: string[]

  netAmount: number
  patientPaymentsSum: number
  insurancePaymentsSum: number
  adjustmentsSum: number
  proceduresSum: number

  procedures: {
    id: string
    amount: number
    procedureCode: string | null
    description: string
    pms_claimId: string | null
  }[]
}

const BalanceActivitySheet = ({
  isShown,
  setIsShown,
  isPatientApp,
  guarantorId,
  label,
  date,
  isPendingPrimary,
  isPendingSecondary,
  isDenied,
  patient,
  uploadPaths,
  procedures,
  proceduresSum,
  adjustmentsSum,
  insurancePaymentsSum,
  patientPaymentsSum,
  netAmount
}: Props) => {
  const isPendingAny = isPendingPrimary || isPendingSecondary

  const { isMobile } = useMediaQueryContext()
  const height = useWindowSize()[1]

  const [denyClaims, denyClaimsStatus] = useMutation<Types.DenyClaims, Types.DenyClaimsVariables>(DENY_CLAIMS, {
    variables: {
      guarantorId,
      claimIds: uniq(
        procedures.map((procedure) => procedure.pms_claimId).filter((claimId) => claimId !== null)
      ) as string[]
    },
    onCompleted: () => toaster.warning('Claims successfully denied', { description: 'Recalculating balance' }),
    onError: () => toaster.danger('Unable to deny claims'),
    update: (cache) => {
      cache.modify({
        id: cache.identify({ __typename: 'Contact', id: guarantorId }),
        fields: {
          balance: (_existing, { DELETE }) => DELETE
        }
      })

      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          balanceMetrics: (_existing, { DELETE }) => DELETE
        }
      })

      cache.gc()
    }
  })

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={400}
      preventBodyScrolling={true}
      //@ts-ignore
      position={isMobile ? 'bottom' : 'right'}
    >
      {({ close }: { close: () => void }) => (
        <SheetLayout>
          <CardHeader gridArea="header">
            <Icon icon={['fad', 'receipt']} color="default" size="2x" marginRight={16} />
            <Pane>
              <Heading size={500}>{label}</Heading>
              <Text size={400}>{moment.unix(date).format('M/D/YYYY')}</Text>
            </Pane>
            <Pane marginLeft={16} flexGrow={1} textAlign="right">
              <Text display="block" size={400}>
                {patient.name.first} {patient.name.last}
              </Text>
              {patient.dob && (
                <Text display="block" size={400}>
                  DOB:{' '}
                  {moment(`${patient.dob.year}-${patient.dob.month}-${patient.dob.day}`, 'YYYY-M-D').format('M/D/YYYY')}
                </Text>
              )}
            </Pane>
          </CardHeader>

          <Pane gridArea="body" overflow="auto" height={isMobile ? height - 129.4 : '100%'} background="blueTint">
            <Pane>
              {isPendingAny && (
                <Alert
                  margin={16}
                  intent="warning"
                  title={isPendingPrimary ? 'Bill is Pending Insurance' : 'Bill is Pending Secondary Insurance'}
                >
                  {isPendingPrimary ? (
                    <Text>This bill contains pending claims and/or charges. Patient balance may change.</Text>
                  ) : (
                    <Text>
                      Based on your secondary insurance{' '}
                      <Link inline to="/billing/settings" target="_blank">
                        claim rules
                      </Link>
                      , this bill has been marked as pending. Patient balance may change.
                    </Text>
                  )}
                  {!isPatientApp && (
                    <Button
                      intent="warning"
                      iconAfter={['far', 'ban']}
                      marginTop={12}
                      marginBottom={6}
                      isLoading={denyClaimsStatus.loading}
                      onClick={async () => {
                        await denyClaims()
                        close()
                      }}
                    >
                      Mark Claim as Denied
                    </Button>
                  )}
                </Alert>
              )}

              {!isPatientApp && isDenied && (
                <Alert margin={16} intent="warning" title="Insurance Claim Manually Denied">
                  <Text>This bill has a claim that was manually denied.</Text>
                </Alert>
              )}

              {!!uploadPaths?.length && (
                <Card
                  paddingY={12}
                  paddingX={16}
                  margin={12}
                  elevation={0}
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Heading size={400} flexShrink={0} marginRight={7}>
                    Uploaded Practice Document{uploadPaths.length > 1 ? 's' : ''}:
                  </Heading>

                  <Pane display="grid" gridTemplateColumns="auto" gridRowGap={4}>
                    {uploadPaths.map((path, i) => (
                      <FileLink key={i} size={400} icon={['far', 'paperclip']} label="Attachment" path={path} />
                    ))}
                  </Pane>
                </Card>
              )}

              <Card padding={0} margin={12} elevation={0}>
                <CardHeader padding={12} backgroundColor={colors.blue.base}>
                  <Heading size={400} color="white">
                    Visit Details
                  </Heading>
                </CardHeader>
                <Table>
                  {procedures &&
                    // order procedures without code at end (zzzz)
                    orderBy(procedures, (procedure) => procedure.procedureCode ?? 'zzzz', 'asc').map(
                      ({ procedureCode, description: transactionDescription, amount }, i) => {
                        const lookedupCode =
                          typeof procedureCode === 'string' ? cdtData.find((row) => row[0] === procedureCode) : null

                        // Default if code doesn't exist or was not found in CDT table
                        const [code, category, , description] = lookedupCode ?? [
                          procedureCode ?? 'MISC',
                          'Miscellaneous',
                          '',
                          transactionDescription
                        ]

                        return (
                          <Table.Row
                            key={i}
                            height="auto"
                            paddingTop={12}
                            paddingBottom={8}
                            borderBottom={i < procedures!.length - 1 ? `solid 1px ${colors.border.default}` : 'none'}
                          >
                            <Table.Cell display="flex" flexDirection="column" alignItems="flex-start" paddingRight={0}>
                              <Pane display="flex">
                                <Badge color="blue" marginBottom={4} marginRight={4}>
                                  {code}
                                </Badge>
                                <Strong size={300} color={colors.blue.dark}>
                                  {category}
                                </Strong>
                              </Pane>
                              <Text size={400} marginLeft={2} whiteSpace="pre-line">
                                {title(description.replace(/( - )/, '\n'))}
                              </Text>
                            </Table.Cell>
                            <Table.Cell flexBasis={100} flexShrink={0} flexGrow={0} justifyContent="flex-end">
                              <Text size={400} style={{ fontVariantNumeric: 'tabular-nums' }}>
                                {toDollarsFromCents(amount, 2)}
                              </Text>
                            </Table.Cell>
                          </Table.Row>
                        )
                      }
                    )}

                  <Table.Row height="auto" borderTop={`dotted 2px ${colors.blue.base}`}>
                    <Table.Cell
                      paddingY={12}
                      paddingX={12}
                      display="grid"
                      gridTemplateColumns="auto 1fr"
                      gridRowGap={2}
                    >
                      <Text size={400}>Visit Total</Text>
                      <Text size={400} textAlign="right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                        {toDollarsFromCents(proceduresSum, 2)}
                      </Text>

                      {!!adjustmentsSum && (
                        <>
                          <Text size={400}>Adjustments</Text>
                          <Text size={400} textAlign="right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                            {toDollarsFromCents(adjustmentsSum, 2)}
                          </Text>
                        </>
                      )}

                      {!!insurancePaymentsSum && (
                        <>
                          <Text size={400}>Insurance Payments</Text>
                          <Text size={400} textAlign="right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                            {toDollarsFromCents(insurancePaymentsSum, 2)}
                          </Text>
                        </>
                      )}

                      {!!patientPaymentsSum && (
                        <>
                          <Text size={400}>Payments</Text>
                          <Text size={400} textAlign="right" style={{ fontVariantNumeric: 'tabular-nums' }}>
                            {toDollarsFromCents(patientPaymentsSum, 2)}
                          </Text>
                        </>
                      )}

                      <Pane backgroundColor={colors.border.default} height="1px" gridColumn="span 2" marginX={-4} />

                      <Strong size={400} color={colors.blue.base}>
                        Balance{isPendingAny ? ' (pending)' : ''}
                      </Strong>
                      <Text
                        size={400}
                        color={colors.blue.base}
                        textAlign="right"
                        style={{ fontVariantNumeric: 'tabular-nums' }}
                      >
                        {toDollarsFromCents(netAmount, 2)}
                      </Text>
                    </Table.Cell>
                  </Table.Row>
                </Table>
              </Card>
            </Pane>
          </Pane>
        </SheetLayout>
      )}
    </SideSheet>
  )
}

export default BalanceActivitySheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
`
