import { useState } from 'react'

import { useQuery } from '@apollo/client'
import { GET_DEPENDENT_PLAN } from 'lib/graphql/_dependent-sheet'

import { Pane, Alert, Text, toaster } from 'evergreen-ui'
import { Types, Spinner, Button, MemberCard, ContractSheet } from 'lib'

import DependentsIllustration from 'lib/assets/img/dependents'

export type Props = {
  dependentId: string
  setCurrentPath: React.Dispatch<React.SetStateAction<Types.DependentPath>>
  isPortal: boolean
}

const DependentPlan = ({ dependentId, setCurrentPath, isPortal }: Props) => {
  const [isContractSheetShown, setIsContractSheetShown] = useState(false)

  const { data } = useQuery<Types.DependentPlan, Types.DependentPlanVariables>(GET_DEPENDENT_PLAN, {
    variables: {
      id: dependentId,
    }
  })

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  const { contact } = data
  const { plan, stripe: stripeData, planTerm, dob } = contact

  return (
    <>
      <Pane margin={16} display="grid" gridGap="16px">
        {(stripeData?.latestOpenInvoice) && (
          <Alert intent="danger" title="Saved Payment Method Invalid">
            <Text>To bring the account current, please update the guarantor's payment method</Text>
          </Alert>
        )}

        {plan && stripeData && stripeData.membership && planTerm ? (
          <>
            <ContractSheet
              isShown={isContractSheetShown}
              setIsShown={setIsContractSheetShown}
              planId={plan.id}
              planTerm={planTerm}
              autoRenew={stripeData.membership.autoRenew}
            />
            <MemberCard contact={contact} showContractSheet={() => setIsContractSheetShown(true)} isPortal={isPortal} />
          </>
        ) : (
          <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin={20}>
            <Pane height="112px">
              <DependentsIllustration />
            </Pane>
            <Text marginTop={24} marginBottom={6} size={500} textAlign="center">
              Dependent has not been enrolled in a plan...
            </Text>
            <Button
              appearance="minimal"
              onClick={() => {
                if (dob) setCurrentPath(Types.DependentPath.ENROLL)
                else toaster.warning('Unable to enroll member with missing DOB')
              }}
            >
              Enroll Now
            </Button>
          </Pane>
        )}
      </Pane>
    </>
  )
}

export default DependentPlan
