import 'graphql'
import gql from 'graphql-tag'

export const GET_CONTRACT_SHEET = gql`
  query ContractSheet($id: ID!) {
    plan(id: $id) {
      id
      name
      annualPriceActive
      annualPrice
      monthlyPriceActive
      monthlyPrice
      familyDiscount
      treatments {
        id
        name
        code
        quantity
        value
      }
      showValue
      globalDiscountActive
      globalDiscount
      discounts {
        id
        category
        value
      }
      additionalTerms
    }
  }
`
