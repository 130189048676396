import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { Dialog, Pane, Text, toaster, Label } from 'evergreen-ui'
import { Form, Formik } from 'formik'
import { Button, colors, FormError, mergeVariables, TextInputField, Types } from 'lib'

import { SEND_TEST_EMAIL_NOTIFICATION } from 'graphql/_notification-template-test-dialog'

import NotificationTestCompleted from './notification-test-completed'

type Props = {
  template: {
    id: string
    channel: Types.NotificationChannel
    name: string
    body: string
    subject: string
    buttonLabel?: string | null
    buttonUrl?: string | null
    signature: string
  }
  variables: Types.NotificationTemplateVariables
  isShown: boolean
  setIsShown: (isShown: boolean) => void
}

const EmailNotificationTest = ({ template, variables, isShown, setIsShown }: Props) => {
  const mergedTemplate = {
    subject: mergeVariables(template.subject || '', variables),
    body: mergeVariables(template.body || '', variables),
    signature: mergeVariables(template.signature || '', variables),
    buttonLabel: mergeVariables(template.buttonLabel || '', variables),
    buttonUrl: mergeVariables(template.buttonUrl || '', variables)
  }

  const [sendTestNotification, { loading, data }] = useMutation<
    Types.SendTestEmailNotification,
    Types.SendTestEmailNotificationVariables
  >(SEND_TEST_EMAIL_NOTIFICATION, {
    onError: (e) => toaster.danger(e.message)
  })

  const isCompleted = !!data

  const handleSubmit = ({ email }: { email: string }) => {
    sendTestNotification({
      variables: {
        ...mergedTemplate,
        email
      }
    })
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required')
      })}
    >
      {(formik) => (
        <Dialog
          isShown={isShown}
          title="Send Test Email"
          onConfirm={() => formik.handleSubmit()}
          isConfirmLoading={loading}
          confirmLabel="Send Test Email"
          hasFooter={!isCompleted}
          onCloseComplete={() => setIsShown(false)}
        >
          {!isCompleted ? (
            <Pane padding={16}>
              <Form>
                <TextInputField
                  label="Email Address"
                  name="email"
                  type="email"
                  placeholder="john.smith@gmail.com"
                  disabled={loading}
                />
                <FormError />
              </Form>
              <Label display="inline-block" marginBottom={8}>
                Sample Message
              </Label>

              <Pane padding={16} display="grid" gap={16} backgroundColor={colors.blue.lightest}>
                <Pane>
                  <Text fontWeight={500} fontSize={16}>
                    {mergedTemplate.subject}
                  </Text>
                </Pane>
                <Pane>
                  <Text whiteSpace="pre-wrap">{mergedTemplate.body}</Text>
                </Pane>
                {mergedTemplate.buttonUrl && mergedTemplate.buttonLabel && (
                  <Pane display="flex" alignItems="center">
                    <Button
                      flexShrink={0}
                      marginRight={16}
                      onClick={() => window.open(mergedTemplate.buttonUrl, '_blank')}
                    >
                      {mergedTemplate.buttonLabel}
                    </Button>
                    <Text whiteSpace="nowrap" display="block" overflow="hidden" textOverflow="ellipsis">
                      {mergedTemplate.buttonUrl}
                    </Text>
                  </Pane>
                )}
                <Pane>
                  <Text whiteSpace="pre-wrap">{mergedTemplate.signature}</Text>
                </Pane>
              </Pane>
            </Pane>
          ) : (
            <NotificationTestCompleted />
          )}
        </Dialog>
      )}
    </Formik>
  )
}

export default EmailNotificationTest
