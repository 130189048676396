import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useMutation } from '@apollo/client'
import { SEND_SUNBIT_TEXT_PROMPT } from 'graphql/_sunbit-text'

import { Heading, Paragraph } from 'evergreen-ui'

import { Types, TextInputField, FormError, toDollarsFromCents, colors } from 'lib'
import { Pane, Text, Dialog, toaster } from 'evergreen-ui'

import sunbitLogo from 'assets/img/sunbit-logo.svg'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  guarantor: Types.GuarantorActionItems_contact
}

const SunbitTextDialog = ({ guarantor, isShown, setIsShown }: Props) => {
  const {
    name: { first, last },
    balance: {
      metrics: { patientPortion }
    }
  } = guarantor

  const [sendSunbitTextPrompt, { loading, data }] = useMutation<
    Types.SendSunbitTextPrompt,
    Types.SendSunbitTextPromptVariables
  >(SEND_SUNBIT_TEXT_PROMPT, {
    onCompleted: () => toaster.success(`Sunbit text successfully sent to ${first} ${last}`),
    onError: () => toaster.danger('Unable to send Sunbit text')
  })

  return (
    <Formik
      initialValues={{ amount: patientPortion / 100 }}
      onSubmit={({ amount }) => sendSunbitTextPrompt({ variables: { contactId: guarantor.id, amount: amount * 100 } })}
      validationSchema={Yup.object({
        amount: Yup.number()
          .min(60, 'Amount must be at least $60')
          .max(20000, 'Amount must be at most $20,000')
          .required('Amount is required')
      })}
    >
      {(formik) => (
        <Dialog
          isShown={isShown}
          title="Text Sunbit Link"
          onCloseComplete={() => setIsShown(false)}
          isConfirmLoading={!!(loading || data)}
          onConfirm={() => formik.handleSubmit()}
          confirmLabel={!!(loading || data) ? 'Sending...' : 'Send'}
          width="440px"
        >
          {({ close }) => {
            // Unconventional close call due to nested Formik > Dialog components
            if (data) close()

            return (
              <Form>
                <Pane display="flex" flexDirection="column" alignItems="center" padding={16} paddingBottom={0}>
                  <Pane display="flex" marginTop={16} marginBottom={16} alignItems="center">
                    <Heading marginRight={8}>Offer Pay-Over-Time financing via</Heading>
                    <img src={sunbitLogo} height="20px" alt="Sunbit Logo" />
                  </Pane>

                  <Paragraph textAlign="center" marginBottom={16}>
                    Please enter the treatment amount. Patient will receive <br />a text message with a link to apply.
                  </Paragraph>
                  <Pane width={48} height={1} marginY={20} backgroundColor={colors.border.default} />
                  <Pane display="grid" gridTemplateColumns="1fr 1fr" alignItems="center" padding={8}>
                    <Heading>Amount:</Heading>
                    <TextInputField name="amount" marginBottom={0} textAlign="right" autoFocus placeholder="$0.00" />
                    <div />
                    <Text fontSize="14px" color="muted" fontStyle="italic" marginTop={4} textAlign="right">
                      Recent Balance: {toDollarsFromCents(patientPortion, 2)}
                    </Text>
                  </Pane>
                  <FormError marginTop={0} alignSelf="flex-end" />
                </Pane>
              </Form>
            )
          }}
        </Dialog>
      )}
    </Formik>
  )
}

export default SunbitTextDialog
