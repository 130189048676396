import { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { TOGGLE_FINANCING_PROVIDER } from 'graphql/_financing-provider-toggle'

import { Types } from 'lib'

import { Pane, Label, toaster, Switch as EvergreenSwitch, PaneProps } from 'evergreen-ui'

import { useCheckMinRole } from 'utility/roles'

export interface Props extends PaneProps {
  financingProvider: {
    id: string
    enabled: boolean
  }
}

const FinancingProviderToggle = ({ financingProvider: provider, ...props }: Props) => {
  const checkMinRole = useCheckMinRole()
  const [enabledState, setEnabledState] = useState(provider.enabled)

  useEffect(() => {
    setEnabledState(provider.enabled)
  }, [provider.enabled])

  const [toggleProvider, toggleStatus] = useMutation<
    Types.ToggleFinancingProvider,
    Types.ToggleFinancingProviderVariables
  >(TOGGLE_FINANCING_PROVIDER, {
    onCompleted: (data) => {
      if (data.toggleFinancingProvider.enabled) {
        toaster.success(`Financing provider enabled!`)
      } else {
        toaster.danger(`Financing provider disabled!`)
      }
    },
    onError: () => toaster.danger('Unable to toggle financing provider')
  })

  return (
    <Pane display="flex" alignItems="center" {...props}>
      <Label marginRight={8}>Enabled</Label>
      <EvergreenSwitch
        marginBottom={0}
        height={24}
        onChange={({ currentTarget: { checked } }) => {
          setEnabledState(checked)
          toggleProvider({ variables: { id: provider.id, enabled: checked } })
        }}
        checked={enabledState}
        disabled={!checkMinRole(Types.UserRole.STANDARD) || toggleStatus.loading}
      />
    </Pane>
  )
}

export default FinancingProviderToggle
