import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import { Pane, Heading, Alert, Text, Avatar, Strong } from 'evergreen-ui'
import { Types, Icon, colors, toDollarsFromCents } from 'lib'
import { useModal } from 'components/modal-provider'

export type Props = {
  count: number
  total: number
  connection: Types.HomeDashboard_balancesConnection
  minPatientPortion?: number
}

const Outstanding = ({ count, total, connection, minPatientPortion }: Props) => {
  const history = useHistory()
  const showGuarantorSheet = useModal('guarantor')

  const headingText = minPatientPortion
    ? `Patients With Balances Over ${toDollarsFromCents(minPatientPortion)}`
    : 'Patients With Outstanding Balances'

  return (
    <Alert
      hasIcon={false}
      hoverElevation={1}
      cursor="pointer"
      onClick={() => history.push('/billing/outstanding/aging')}
      data-testId={minPatientPortion ? `patients-with-balances-over-alert` : 'patients-with-outstanding-balances-alert'}
    >
      <Pane display="flex" alignItems="center">
        <Pane width="52px" display="flex" justifyContent="center" alignItems="center">
          <Icon icon={['fad', 'hand-holding-usd']} size="2x" marginRight={20} marginLeft={8} color="default" />
        </Pane>
        <Pane flexGrow={1}>
          <Heading marginBottom={4}>{headingText}</Heading>
          <Text color="muted">
            {count > 1 ? (
              <>
                You have <Strong>{count} patients</Strong> with balances{' '}
                {typeof minPatientPortion === 'number' ? `above ${toDollarsFromCents(minPatientPortion)} ` : ''}
                totaling
              </>
            ) : (
              <>
                You have <Strong>1 patient</Strong> with a balance{' '}
                {typeof minPatientPortion === 'number' ? `above ${toDollarsFromCents(minPatientPortion)} ` : ''}
                of
              </>
            )}
            <Strong> {toDollarsFromCents(total)}</Strong>
          </Text>
        </Pane>
        <Pane display="grid" gridTemplateRows="auto" gridGap={6} gridAutoFlow="column" paddingX={24}>
          {connection.edges.slice(0, 5).map(({ node: { guarantor } }, i) => (
            <HoverAvatar
              key={i}
              name={`${guarantor.name.first} ${guarantor.name.last}`}
              color="blue"
              size={28}
              onClick={(e: MouseEvent) => {
                e.stopPropagation()
                showGuarantorSheet({ guarantorId: guarantor.id })
              }}
            />
          ))}

          {count > 5 && <HoverAvatar name="＋" color="blue" size={28} />}
        </Pane>

        <Icon icon={['far', 'chevron-right']} color="default" size="lg" marginRight={8} />
      </Pane>
    </Alert>
  )
}

export default Outstanding

const HoverAvatar = styled(Avatar)`
  :hover {
    background-color: ${colors.blue.base};
    span {
      color: white;
    }
  }
`
