import gql from 'graphql-tag'

export const SET_CURRENT_ACCOUNT_ID = gql`
  mutation SetCurrentAccountId($accountId: ID!) {
    setCurrentAccountId(accountId: $accountId)
  }
`

export const SET_CURRENT_GROUP_ID = gql`
  mutation SetCurrentGroupId($groupId: ID!) {
    setCurrentGroupId(groupId: $groupId)
  }
`

export const SET_ANONYMIZE = gql`
  mutation SetAnonymize($anonymize: Boolean!) {
    setAnonymize(anonymize: $anonymize)
  }
`
