import { createContext, useContext, useEffect } from 'react'

import { useQuery, useMutation } from '@apollo/client'
import { GET_GLOBAL } from 'graphql/_global'
import { SET_CURRENT_ACCOUNT_ID, SET_CURRENT_GROUP_ID } from 'graphql/_header'
import { toaster } from 'evergreen-ui'

import { Types, FullScreen, Spinner, useToken, parseUserRoleFromToken } from 'lib'

type GlobalContext = Types.Global & {
  role: Types.UserRole
}

const Context = createContext<GlobalContext>({ role: Types.UserRole.BASIC } as GlobalContext)
export const useGlobal = () => useContext(Context)

export const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const { tokenResult, refreshToken } = useToken()

  const { loading, data } = useQuery<Types.Global>(GET_GLOBAL, { skip: !tokenResult })

  const [setGroup] = useMutation<Types.SetCurrentGroupId, Types.SetCurrentGroupIdVariables>(SET_CURRENT_GROUP_ID, {
    onCompleted: async (data) => {
      await refreshToken()
      toaster.success(`Set group to ${data?.setCurrentGroupId}`)
    },
    onError: () => {
      toaster.danger(`Unable to set group`)
    }
  })

  const [setAccount] = useMutation<Types.SetCurrentAccountId, Types.SetCurrentAccountIdVariables>(
    SET_CURRENT_ACCOUNT_ID,
    {
      onCompleted: async (data) => {
        await refreshToken()
        toaster.success(`Set account to ${data?.setCurrentAccountId}`)
      },
      onError: () => {
        toaster.danger(`Unable to set group`)
      }
    }
  )

  useEffect(() => {
    if (!tokenResult || !data) return

    const { groups, accounts } = data.meUser
    const { claims } = tokenResult

    // If no current group is set, set first group
    // Needs to occur in client so we can refresh token
    if (!claims.currentGroupId && groups.length) {
      setGroup({ variables: { groupId: groups[0].id } })
    }

    // Handle accountCode Param
    const url = new URL(window.location.href)
    const accountCodeParam = url.searchParams.get('accountCode')

    if (accountCodeParam) {
      const currentAccount = accounts.find((account) => account.id === claims.currentAccountId)
      if (!currentAccount) throw Error('No currentAccount for logged in User')

      const accountCodeAccount = accounts.find((account) => account.code === accountCodeParam)

      // Always clear param
      url.searchParams.delete('accountCode')
      window.history.replaceState({}, document.title, url.toString())

      if (accountCodeAccount && accountCodeAccount.code !== currentAccount.code) {
        setAccount({ variables: { accountId: accountCodeAccount.id } })
      }
    }
  }, [tokenResult, data])

  if (loading || !data || !tokenResult)
    return (
      <FullScreen display="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </FullScreen>
    )

  const role = parseUserRoleFromToken(tokenResult.claims.role)

  return <Context.Provider value={{ ...data, role }}>{children}</Context.Provider>
}
