import moment from 'moment'

import { Types, colors } from 'lib'

export const toDollars = (number: number, fractionDigits?: number, hideCurrency = false) => {
  return Intl.NumberFormat('en-US', {
    ...(hideCurrency ? {} : { style: 'currency', currency: 'USD' }),
    minimumFractionDigits: fractionDigits ?? 0,
    maximumFractionDigits: fractionDigits ?? 0
  }).format(number)
}

export const toDollarsFromCents = (number: number, fractionDigits?: number, hideCurrency = false) => {
  return Intl.NumberFormat('en-US', {
    ...(hideCurrency ? {} : { style: 'currency', currency: 'USD' }),
    minimumFractionDigits: fractionDigits ?? 0,
    maximumFractionDigits: fractionDigits ?? 0
  }).format(number / 100)
}

export const toPercent = ({ numerator, denominator }: { numerator: number; denominator: number }) => {
  if (denominator === 0) return '0%'
  return `${Math.round((numerator / denominator) * 100)}%`
}

export const toAbbreviatedDollarsFromCents = (number: number) => {
  const dollars = number / 100

  return dollars >= 10 ** 6
    ? `$${Math.round((dollars / 10 ** 6) * 10) / 10}m`
    : dollars >= 10 ** 3
    ? `$${Math.round((dollars / 10 ** 3) * 10) / 10}k`
    : dollars > 0
    ? `$${Math.round((dollars / 10 ** 3) * 10) / 10}k`
    : ''
}

export const withCommas = (number: number) => number.toLocaleString()

export const ageRangeToString = (ageRange: { min?: number | null; max?: number | null } = {}): string => {
  const { min, max } = ageRange

  if (min && max) {
    return `${min} - ${max}`
  } else if (max) {
    return `${max} and Under`
  } else if (min) {
    return `Ages ${min}+`
  } else return 'All Ages'
}

export const prettyDpdBucketList = (
  balanceFilter:
    | {
        allowL30: boolean
        allowL60: boolean
        allowL90: boolean
        allowG90: boolean
      }
    | undefined
) => {
  const output: string[] = []
  if (balanceFilter?.allowL30) output.push('<30')
  if (balanceFilter?.allowL60) output.push('30-60')
  if (balanceFilter?.allowL90) output.push('60-90')
  if (balanceFilter?.allowG90) output.push('90+')
  return output.length ? output.join(', ') : 'Disabled'
}

export const prettyPhoneNumber = (number: string) => {
  const cleaned = ('' + number).replace(/\D/g, '')
  const last10 = cleaned.substr(cleaned.length - 10)
  const match = /^(\d{3})(\d{3})(\d{4})$/.exec(last10)
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : number
}

export const getPrettySubscriptionStatus = (status: Types.SubscriptionStatus) =>
  (({
    [Types.SubscriptionStatus.ACTIVE]: 'Active',
    [Types.SubscriptionStatus.INCOMPLETE]: 'Incomplete',
    [Types.SubscriptionStatus.CANCELED]: 'Canceled',
    [Types.SubscriptionStatus.COMPLETED]: 'Completed',
    [Types.SubscriptionStatus.PAST_DUE]: 'Past Due',
    [Types.SubscriptionStatus.PAUSED]: 'Paused'
  } as Record<Types.SubscriptionStatus, string>)[status])

export const getSubscriptionStatusColor = (status: Types.SubscriptionStatus) =>
  (({
    [Types.SubscriptionStatus.ACTIVE]: colors.green.base,
    [Types.SubscriptionStatus.INCOMPLETE]: colors.green.base,
    [Types.SubscriptionStatus.CANCELED]: colors.neutral.muted,
    [Types.SubscriptionStatus.COMPLETED]: colors.green.base,
    [Types.SubscriptionStatus.PAST_DUE]: colors.red.base,
    [Types.SubscriptionStatus.PAUSED]: colors.yellow.base
  } as Record<Types.SubscriptionStatus, string>)[status])

export const prettyDpdBucket = (bucket: Types.DPDBucket) => {
  return {
    [Types.DPDBucket.l30]: '<30',
    [Types.DPDBucket.l60]: '30-60',
    [Types.DPDBucket.l90]: '60-90',
    [Types.DPDBucket.g90]: '90+'
  }[bucket]
}

export const prettyFlowEndReason = (endReason: Types.OutreachFlowEndReason) => {
  return {
    [Types.OutreachFlowEndReason.DURATION_EXCEEDED]: 'Duration Exceeded',
    [Types.OutreachFlowEndReason.STOPPED]: 'Manually Stopped',
    [Types.OutreachFlowEndReason.PENDING_INSURANCE]: 'Insurance Claim',
    [Types.OutreachFlowEndReason.BLOCKED]: 'Blocked',
    [Types.OutreachFlowEndReason.BALANCE_CHANGED]: 'Balance Changed',
    [Types.OutreachFlowEndReason.SUPERSEDED]: 'Superseded',
    [Types.OutreachFlowEndReason.LEGACY]: 'Other'
  }[endReason]
}

export const prettyInboundCallBehavior = (behavior: Types.InboundCallBehavior) => {
  return {
    [Types.InboundCallBehavior.DISABLED]: 'disabled',
    [Types.InboundCallBehavior.FORWARD]: 'forwarding',
    [Types.InboundCallBehavior.GREETING]: 'interactive greeting'
  }[behavior]
}

export const prettyInterval = (interval: number, intervalType: Types.InstallmentIntervalType) => {
  if (interval === 1) return intervalType.toLocaleLowerCase()
  else return `${interval} ${intervalType.toLocaleLowerCase()}s`
}

export const prettyIntervalStart = ({
  startDate,
  interval,
  intervalNumber,
  intervalType,
  format
}: {
  interval: number
  intervalNumber: number
  intervalType: Types.InstallmentIntervalType
  startDate: Date
  format: string
}) => {
  const startMomemnt = moment(startDate)
  return startMomemnt.add(interval * intervalNumber, intervalType.toLocaleLowerCase() as moment.DurationInputArg2).format(format)
}

export const getShortInterval = (intervalType: Types.InstallmentIntervalType) => {
  return {
    WEEK: 'Wk',
    HOUR: 'Hr'
  }[intervalType]
}
