import { Types } from 'lib'

import { Pane } from 'evergreen-ui'

import ChargeGuarantorCard from 'components/charge-guarantor-card'
import GuarantorActionItemsCard from 'components/guarantor-action-items-card'

export type Props = {
  guarantorId: string
}

const GuarantorCharge = ({ guarantorId }: Props) => (
  <Pane display="grid" padding={16} gridGap={16}>
    <ChargeGuarantorCard guarantorId={guarantorId} elevation={0} />

    <GuarantorActionItemsCard
      guarantorId={guarantorId}
      items={[Types.GuarantorActionItem.ENROLL_INSTALLMENTS]}
      elevation={0}
      header="Alternative Payment Methods"
    />
  </Pane>
)

export default GuarantorCharge
