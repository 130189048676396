import styled from 'styled-components/macro'

import { useMutation } from '@apollo/client'
import { UPSERT_SUNBIT_CONFIGURATION } from 'graphql/_sunbit-sheet'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Text, Heading, toaster, Strong } from 'evergreen-ui'
import { Types, Card, CardHeader, Button, FormError, TextInputField, TextInput, colors } from 'lib'

import { useModal } from 'components/modal-provider'
import SunbitToggle from 'components/sunbit-toggle'

import sunbitLogo from 'assets/img/sunbit-logo.svg'
import BucketSelector from 'components/bucket-selector'
import { useState } from 'react'
import { useCheckMinRole } from 'utility/roles'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  sunbit?: Types.PayOverTimeFinancing_account_sunbitConfiguration | null
}

const SunbitSheet = ({ isShown, setIsShown, sunbit }: Props) => {
  const checkMinRole = useCheckMinRole()

  const showConfirmDialog = useModal('confirm')

  const [upsertSunbit, sunbitStatus] = useMutation<
    Types.UpsertSunbitConfiguration,
    Types.UpsertSunbitConfigurationVariables
  >(UPSERT_SUNBIT_CONFIGURATION, {
    onCompleted: () => toaster.success(`Sunbit integration successfully ${sunbit ? 'updated' : 'connected'}!`),
    onError: () => toaster.danger(`Unable to ${sunbit ? 'update' : 'connect'} Sunbit integration`)
  })

  if (sunbitStatus.data) sunbit = sunbitStatus.data.upsertSunbitConfiguration!

  const [bucketState, setBucketState] = useState({
    allowL30: sunbit ? sunbit.balanceFilter.allowL30 : true,
    allowL60: sunbit ? sunbit.balanceFilter.allowL60 : true,
    allowL90: sunbit ? sunbit.balanceFilter.allowL90 : true,
    allowG90: sunbit ? sunbit.balanceFilter.allowG90 : true
  })

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      <Formik
        initialValues={
          sunbit
            ? {
                locationId: sunbit.locationId,
                departmentId: sunbit.departmentId ?? '',
                representativeEmail: sunbit.representativeEmail ?? '',
                representativeFirstName: sunbit.representativeFirstName ?? '',
                representativeLastName: sunbit.representativeLastName ?? '',
                minBalance: sunbit.balanceFilter.minBalance / 100,
                maxBalance: sunbit.balanceFilter.maxBalance / 100
              }
            : {
                locationId: '',
                departmentId: '',
                representativeEmail: '',
                representativeFirstName: '',
                representativeLastName: '',
                minBalance: ('' as unknown) as number,
                maxBalance: ('' as unknown) as number
              }
        }
        onSubmit={({ ...fields }) => {
          showConfirmDialog({
            body: `Are you sure you want to ${sunbit ? 'update' : 'connect'} the Sunbit integration?`,
            onConfirm: () => {
              upsertSunbit({
                variables: {
                  id: sunbit ? sunbit.id : undefined,
                  ...fields,
                  balanceFilter: {
                    maxBalance: fields.maxBalance * 100,
                    minBalance: fields.minBalance * 100,
                    ...bucketState
                  }
                }
              })
            }
          })
        }}
        validationSchema={Yup.object({
          locationId: Yup.string().required('Location ID is required'),
          departmentId: Yup.string(),
          representativeEmail: Yup.string(),
          representativeFirstName: Yup.string(),
          representativeLastName: Yup.string(),

          minBalance: Yup.number().min(60, 'Balance must be at least $60').required('Required field is missing'),
          maxBalance: Yup.number()
            .max(20000, 'Balance can be no more than $20,000')
            .required('Required field is missing')
        })}
      >
        <Form style={{ height: '100%' }}>
          <SheetLayout>
            <CardHeader justifyContent="space-between" alignItems="center" overflow="hidden">
              <img src={sunbitLogo} height="90%" alt="Sunbit Logo" />
              {sunbit && <SunbitToggle sunbitConfiguration={sunbit} />}
            </CardHeader>
            <Pane gridArea="body" overflow="auto" background="blueTint">
              <Pane display="grid" gap={16} padding={16}>
                <Card backgroundColor="white" elevation={0} padding={0}>
                  <CardHeader>
                    <Heading>Integration Details</Heading>
                  </CardHeader>
                  <Pane padding={16} borderBottom={`solid 1px ${colors.border.muted}`}>
                    <Paragraph>
                      Please contact Pearly to enable the Sunbit integration. We will connect your existing Sunbit
                      account or can introduce you to learn more about Sunbit's patient financing options.
                    </Paragraph>
                  </Pane>
                  <Pane padding={24}>
                    <TextInputField
                      name="locationId"
                      label="Sunbit Location ID*"
                      height={40}
                      disabled={!checkMinRole(Types.UserRole.SUPERADMIN)}
                    />
                    <TextInputField name="departmentId" label="Department ID" height={40} />
                    <TextInputField name="representativeEmail" label="Representative Email" height={40} />

                    <Pane display="grid" gridTemplateColumns="1fr 1fr" gap={16}>
                      <TextInputField
                        name="representativeFirstName"
                        label="Rep. First Name"
                        height={40}
                        marginBottom={0}
                      />
                      <TextInputField
                        name="representativeLastName"
                        label="Rep. Last Name"
                        height={40}
                        marginBottom={0}
                      />
                    </Pane>
                  </Pane>
                </Card>
                <Card backgroundColor="white" elevation={0} padding={0}>
                  <CardHeader>
                    <Heading>Pay Portal Defaults</Heading>
                  </CardHeader>
                  <Pane padding={16} borderBottom={`solid 1px ${colors.border.muted}`}>
                    <Text>
                      By default, offer Sunbit to patients with balances satisfying the below criteria. Can be adjusted
                      on a patient-by-patient basis.
                    </Text>
                  </Pane>
                  <Pane padding={16} display="grid" gap={6}>
                    <Heading size={300} color={colors.blue.base} textTransform="uppercase" marginBottom={4}>
                      Offer Sunbit if...
                    </Heading>
                    <Pane display="flex" alignItems="center" justifyContent="space-between">
                      <Pane display="flex">
                        <Strong size={500} marginRight={4} width={120}>
                          Patient Balance
                        </Strong>
                        <Text size={500}>between </Text>
                      </Pane>
                      <Pane display="flex" alignItems="center">
                        <TextInput name="minBalance" type="number" width={52} height={32} marginBottom={0} />
                        <Text size={500} display="inline-block" marginX={8}>
                          -
                        </Text>
                        <TextInput name="maxBalance" type="number" width={52} height={32} marginBottom={0} />
                      </Pane>
                    </Pane>
                    <Heading size={300} color={colors.blue.base} marginBottom={4}>
                      AND
                    </Heading>
                    <Pane display="flex" flexDirection="column" justifyContent="space-between" rowGap={16}>
                      <Pane display="flex" alignItems="center">
                        <Strong size={500} marginRight={4}>
                          Days Past Due
                        </Strong>
                        <Text size={500}>bucket includes:</Text>
                      </Pane>
                      <BucketSelector state={bucketState} onSelect={setBucketState} />
                    </Pane>

                    <FormError marginTop={0} />
                  </Pane>
                </Card>
              </Pane>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              <Button
                isLoading={sunbitStatus.loading}
                type="submit"
                appearance="primary"
                height={48}
                width="100%"
                justifyContent="center"
                iconBefore={['fas', 'bolt']}
              >
                {sunbit ? 'Update' : 'Connect'} Sunbit Integration
              </Button>
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default SunbitSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
