import { Types } from 'lib'

import GuarantorActionItemsCard from 'components/guarantor-action-items-card'

export type Props = { guarantor: Types.GuarantorBilling_contact }

const GuarantorBillingActions = ({ guarantor }: Props) => {
  return (
    <GuarantorActionItemsCard
      header={null}
      guarantorId={guarantor.id}
      items={[
        Types.GuarantorActionItem.NOTIFICATION,
        Types.GuarantorActionItem.OUTREACH,
        Types.GuarantorActionItem.CHARGE,
        Types.GuarantorActionItem.ENROLL_INSTALLMENTS,
        Types.GuarantorActionItem.PAY_PORTAL,
        Types.GuarantorActionItem.RECEIPT_PORTAL,
        Types.GuarantorActionItem.SUNBIT_TEXT
      ]}
      elevation={0}
    />
  )
}

export default GuarantorBillingActions
