import { useState } from 'react'
import { Pane, PaneProps, Heading, Text, Paragraph, Strong, Position } from 'evergreen-ui'

import { Icon, toDollarsFromCents, toAbbreviatedDollarsFromCents, colors, InfoTooltip, CornerDialog } from 'lib'

export type Props = PaneProps & {
  patientPortion: number
  insurancePortion: number
  writeOffEstimate?: number
  payPortal?: boolean
  totalLabel?: string
  insuranceLabel?: string
  patientLabel?: string
  abbreviateValues?: boolean
}

const BalanceEquation = ({
  patientPortion,
  insurancePortion,
  writeOffEstimate = 0,
  payPortal,
  totalLabel = 'Total Balance',
  insuranceLabel = 'Insurance Est.',
  patientLabel = 'Patient Balance',
  abbreviateValues,
  ...props
}: Props) => {
  const [showExplanationDialog, setShowExplanationDialog] = useState(false)

  const total = patientPortion + insurancePortion

  const [totalValueString, insuranceValueString, patientValueString, writeOffValueString] = [
    insurancePortion + patientPortion,
    insurancePortion,
    patientPortion,
    writeOffEstimate
  ].map((v) => (abbreviateValues ? toAbbreviatedDollarsFromCents(v) : toDollarsFromCents(v, 2)))

  return (
    <>
      <CornerDialog
        title="Balance Terminology"
        isShown={showExplanationDialog}
        setIsShown={setShowExplanationDialog}
        containerProps={{ style: { maxWidth: 'calc(100vw - 100px)' } }}
        position={Position.TOP_RIGHT}
        confirmLabel="Close"
        hasCancel={false}
        onCloseComplete={() => setShowExplanationDialog(false)}
        body={ExplanationDialogBody}
      />

      <Pane display="grid" gridGap={8} gridTemplateColumns="auto 1fr auto 1fr auto" {...props}>
        <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Text size={300} marginBottom={4}>
            {totalLabel}
            {writeOffEstimate > 0 && (
              <InfoTooltip
                marginLeft={6}
                fontSize={12}
                opacity={0.75}
                content={`Write-Off Estimate: ${writeOffValueString}`}
              />
            )}
          </Text>
          <Heading size={500} fontWeight={300}>
            {total === 0 ? '$0' : totalValueString}
          </Heading>
        </Pane>
        <Pane display="flex" justifyContent="center" alignItems="center">
          <Icon color="none" icon={['fas', 'minus']} />
        </Pane>
        <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Text size={300} marginBottom={4}>
            {insuranceLabel}
          </Text>
          <Heading size={500} fontWeight={300}>
            {insurancePortion === 0 ? '$0' : insuranceValueString}
          </Heading>
        </Pane>
        <Pane display="flex" justifyContent="center" alignItems="center">
          <Icon color="none" icon={['fas', 'equals']} />
        </Pane>
        <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Text size={300} marginBottom={4} position="relative" marginRight={payPortal ? 16 : 0}>
            {payPortal ? 'Due Now' : patientLabel}
            {payPortal && (
              <Icon
                cursor="pointer"
                fontSize="12px"
                icon={['fas', 'info-circle']}
                color={colors.neutral.muted}
                marginLeft={4}
                onClick={() => setShowExplanationDialog(true)}
                position="absolute"
                top="-2px"
              />
            )}
          </Text>
          <Heading size={500} fontWeight={700} color={payPortal ? colors.blue.base : colors.neutral.dark}>
            {patientPortion === 0 ? '$0' : patientValueString}
          </Heading>
        </Pane>
      </Pane>
    </>
  )
}

export default BalanceEquation

const ExplanationDialogBody = (
  <>
    <Paragraph marginBottom={16}>
      We understand that your dental bill can be confusing at times and our goal is to provide you clear balance
      terminology:
    </Paragraph>
    <Paragraph marginBottom={16}>
      <Strong>Total Balance:</Strong> You are ultimately responsible for the full balance due for services rendered,
      regardless of your insurance provider.
    </Paragraph>
    <Paragraph marginBottom={16}>
      <Strong>Insurance Estimate:</Strong> This is the estimated amount that your insurance provider is expected to pay
      our practice. The amount is subject to change and you may be responsible for this portion.
    </Paragraph>
    <Paragraph marginBottom={16}>
      <Strong>Total Due Now:</Strong> This is the estimated patient portion that is due by you today.
    </Paragraph>
    <Paragraph>Please call our office with any questions regarding your bill or our financial policy.</Paragraph>
  </>
)
