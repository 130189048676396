import { Pane } from 'evergreen-ui'

import StartGuarantorOutreachCard from 'components/start-guarantor-outreach-card'

export type Props = {
  guarantorId: string
}

const GuarantorStartOutreach = ({ guarantorId }: Props) => (
  <Pane display="grid" gap={16} padding={16}>
    <StartGuarantorOutreachCard guarantorId={guarantorId} elevation={0} />
  </Pane>
)

export default GuarantorStartOutreach
