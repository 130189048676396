import { Types } from 'lib'

import { toaster } from 'evergreen-ui'

import { PatientDetails } from 'lib'

export type Props = {
  dependentId: string
  closeModal?: () => void
}

const DependentProfile = ({ dependentId, closeModal }: Props) => {
  return (
    <PatientDetails
      height={40}
      optionalFields={[
        Types.PatientField.EMAIL,
        Types.PatientField.PHONE,
        Types.PatientField.PERIO,
        Types.PatientField.DOB,
        Types.PatientField.ADDRESS
      ]}
      isProfile
      contactId={dependentId}
      onUpdated={() => toaster.success('Dependent successfully updated')}
      onSkip={() => toaster.notify('Dependent details are unchanged')}
      button={{ label: 'Update Profile' }}
      closeModal={closeModal}
    />
  )
}

export default DependentProfile
