import PlanFees from './plan-fees'
import PlanTreatments from './plan-treatments'
import PlanDiscounts from './plan-discounts'
import PlanTerms from './plan-terms'

export default {
  Fees: PlanFees,
  Treatments: PlanTreatments,
  Discounts: PlanDiscounts,
  Terms: PlanTerms
}
