import { getCurrentUser, logout } from 'utility/auth'

import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { ApolloProvider as SourceApolloProvider } from '@apollo/client'

import { useToken } from 'lib'
import { toaster } from 'evergreen-ui'

export type Props = {
  children: React.ReactNode
}

const httpLink = createHttpLink({
  uri: window._env_.REACT_APP_API_URL
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors?.some((err) => err.message.includes('TOKEN_EXPIRED'))) logout()
  if (graphQLErrors?.some((err) => err.message.includes('MD1'))) toaster.notify('Demo Mode: Operation Skipped')
})

const createApolloClient = () => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await getCurrentUser()?.getIdToken()

    return token
      ? {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`
          }
        }
      : headers
  })

  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Contact: {
          fields: {
            stripe: {
              merge: (existing, incoming, { mergeObjects }) => mergeObjects(existing, incoming)
            },
            balance: {
              merge: (existing, incoming, { mergeObjects }) => mergeObjects(existing, incoming)
            }
          }
        },
        Analytics: {
          merge: (existing, incoming, { mergeObjects }) => mergeObjects(existing, incoming)
        },
        Balances: {
          merge: (existing, incoming, { mergeObjects }) => mergeObjects(existing, incoming)
        }
      }
    }),
    name: 'Practice App',
    connectToDevTools: window._env_.REACT_APP_ENV === 'development'
  })
}

const ApolloProvider = ({ children }: Props) => {
  useToken()

  return <SourceApolloProvider client={createApolloClient()}>{children}</SourceApolloProvider>
}

export default ApolloProvider
