import { Pane } from 'evergreen-ui'
import { Button, colors, Types } from 'lib'

import { getTemplateVariableButtons } from 'utility/notification-template-variables'

type Props = {
  onVariableClick: (variable: string) => void
  channel: Types.NotificationChannel
}

const TemplateVariableButtons = ({ onVariableClick, channel }: Props) => {
  const buttons = getTemplateVariableButtons({ channel })

  const mappedButtons = Object.entries(buttons).map(([key, value]) => ({
    value: key,
    label: value
  }))

  return (
    <Pane display="flex" flexDirection="column" alignItems="flex-start" gap={8}>
      {mappedButtons.map(({ value, label }) => (
        <Button color={colors.blue.base} onClick={() => onVariableClick(value)}>
          {label}
        </Button>
      ))}
    </Pane>
  )
}

export default TemplateVariableButtons
