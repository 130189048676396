import { Text, PaneProps, Heading } from 'evergreen-ui'

import { Card, Icon } from 'lib'

export type Props = PaneProps & {
  address: {
    name: string
    street1: string
    street2: string | null
    city: string
    state: string
    zip: string
  }
  isVerified?: boolean
}

const AddressCard = ({
  address: { name, street1, street2, city, state, zip },
  isVerified = false,

  ...props
}: Props) => (
  <Card className="office-card" elevation={1} hoverElevation={2} {...props}>
    <Heading marginBottom={4} display="flex" justifyContent="space-between">
      {name} {isVerified && <Icon icon={['fas', 'badge-check']} color="success" />}
    </Heading>
    <Text>
      {street1}
      {street2 && `, ${street2}`}
    </Text>

    <Text>{`${city}, ${state} ${zip}`}</Text>
  </Card>
)

export default AddressCard
