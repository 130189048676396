import { useState } from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useMutation } from '@apollo/client'
import { REFUND_CHARGE } from 'graphql/_refund-dialog'

import { Types, TextInput, FormError, Icon, toDollarsFromCents, toCents } from 'lib'
import { Pane, Text, Dialog, toaster } from 'evergreen-ui'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  charge: Types.ChargeSheet_charge
}

const RefundDialog = ({ isShown, setIsShown, charge }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const [refund] = useMutation<Types.RefundCharge, Types.RefundChargeVariables>(REFUND_CHARGE, {
    onCompleted: () => {
      toaster.success('Refund successfully issued', { description: 'Patient will receive funds in 5-10 days' })
    },
    onError: () => {
      toaster.danger('Unable to issue refund')
      setIsLoading(false)
    }
  })

  return (
    <Formik
      initialValues={{ amount: ((charge.grossAmount / 100).toFixed(2) as unknown) as number }}
      onSubmit={async ({ amount }) => {
        setIsLoading(true)
        await refund({
          variables: {
            chargeId: charge.id,
            amount: toCents(amount)
          }
        })
        setIsShown(false)
      }}
      validationSchema={Yup.object({
        amount: Yup.number()
          .max(charge.grossAmount / 100, 'Cannot refund more than total amount')
          .min(0, 'Refund must be greater than zero')
          .required('Email is required')
      })}
    >
      {(formik) => (
        <Dialog
          isShown={isShown}
          title="Refund Charge"
          isConfirmLoading={isLoading}
          onConfirm={() => formik.handleSubmit()}
          confirmLabel={isLoading ? 'Refunding...' : 'Confirm Refund'}
        >
          <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Icon icon={['fad', 'undo']} color="default" size="3x" marginY={16} />
            <Text size={500} marginBottom={16} textAlign="center">
              Patient paid {toDollarsFromCents(charge.grossAmount, 2)}
              {charge.grossAmount > charge.balanceAmount
                ? ` (${toDollarsFromCents(charge.balanceAmount, 2)} balance plus ${toDollarsFromCents(
                    charge.patientFee,
                    2
                  )} fee)`
                : ''}
              <br />
              Please enter the portion of the payment to refund (up to {toDollarsFromCents(charge.grossAmount, 2)})
            </Text>

            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TextInput
                name="amount"
                type="number"
                step="0.01"
                width={108}
                textAlign="center"
                marginBottom={0}
                placeholder={toDollarsFromCents(charge.grossAmount, 2)}
              />
              <FormError marginBottom={4} />
            </Form>
            <Text textAlign="center" color="muted" size={300}>
              Note: Refunds take 5-10 days to process back to patient. Original processing fees are not refunded to your
              practice.
            </Text>
          </Pane>
        </Dialog>
      )}
    </Formik>
  )
}

export default RefundDialog
