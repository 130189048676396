import gql from 'graphql-tag'

export const fragments = {
  inboundEventMetricsFields: gql`
    fragment inboundEventMetricsFields on InboundEventMetrics {
      totalEvents
      totalResolved
      totalForwarded
    }
  `
}

export const GET_OUTREACH_INBOUND = gql`
  query OutreachInbound {
    account {
      id
      name
      replyEmail
      privateReplyPhone
      publicReplyPhone
      inboundCall_behavior
      inboundCall_greetingText
      inboundCall_greetingUrl
      capabilities

      returnAddress {
        street1
        street2
        city
        state
        zip
      }
    }

    last30: inboundEventMetrics(channel: VOICE, lookbackDays: 30) {
      ...inboundEventMetricsFields
    }
    allTime: inboundEventMetrics(channel: VOICE) {
      ...inboundEventMetricsFields
    }
  }

  ${fragments.inboundEventMetricsFields}
`

export const UPDATE_OUTREACH_INBOUND_CALL_BEHAVIOR = gql`
  mutation UpdateOutreachInboundCallBehavior($accountId: ID!, $behavior: InboundCallBehavior!) {
    updateInboundCallBehavior(accountId: $accountId, behavior: $behavior) {
      id
      inboundCall_behavior
    }
  }
`

export const UPSERT_OUTREACH_INBOUND_CALL_GREETING = gql`
  mutation UpsertOutreachInboundCallGreeting($accountId: ID!, $text: String!) {
    upsertInboundCallGreeting(accountId: $accountId, text: $text) {
      id
      inboundCall_greetingText
      inboundCall_greetingUrl
    }
  }
`
