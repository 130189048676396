import { useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { GET_VERIFY_CONTACT_ADDRESS_CARD, VERIFY_CONTACT_ADDRESS } from 'graphql/_verify-contact-address-card'

import { Text, Strong, Heading, Pane } from 'evergreen-ui'

import {
  Card,
  CardHeader,
  Button,
  IconButton,
  TextInputField,
  DoubleTextInput,
  FormError,
  Types,
  Icon,
  colors,
  Spinner
} from 'lib'

import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import VerifiedAddressCard from 'components/verified-address-card'

interface Props {
  onVerified: (addressId: string) => void
  onVerifyStart: () => void
  hasWrapperCard?: boolean
  contactId: string
}

const VerifyContactAddressCard = ({ contactId, onVerified, onVerifyStart, hasWrapperCard = false }: Props) => {
  const [editing, setEditing] = useState(false)

  const { data } = useQuery<Types.VerifyContactAddressCard>(GET_VERIFY_CONTACT_ADDRESS_CARD, {
    variables: { contactId }
  })

  const [verifyAddress, { data: mutationData, loading }] = useMutation<
    Types.VerifyContactAddress,
    Types.VerifyContactAddressVariables
  >(VERIFY_CONTACT_ADDRESS, {
    onCompleted: (data) => {
      if (data?.verifyContactAddress.verifiedAddress) onVerified(data.verifyContactAddress.id)
    }
  })

  if (!data)
    return (
      <Card {...(hasWrapperCard ? {} : { elevation: undefined })}>
        <Spinner marginY={48} delay={0} />
      </Card>
    )

  const contactAddress = data.contact.address
  const verifiedAddress = loading
    ? null
    : mutationData?.verifyContactAddress.verifiedAddress ?? data.contact.verifiedAddress

  return (
    <Formik
      initialValues={{
        street1: contactAddress?.street1 ?? '',
        street2: contactAddress?.street2 ?? '',
        city: contactAddress?.city ?? '',
        state: contactAddress?.state ?? ('' as Types.State),
        zip: contactAddress?.zip ?? ''
      }}
      onSubmit={(values) => {
        onVerifyStart()
        verifyAddress({ variables: { contactId, ...values } })
      }}
      validationSchema={Yup.object({
        street1: Yup.string().required('Please submit a complete address'),
        city: Yup.string().required('Please include the city'),
        state: Yup.mixed()
          .required('Please include the state')
          .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation)'),
        zip: Yup.string()
          .required('Please include a zip code')
          .matches(/^\d{5}(-\d{4})?$/, 'Please enter a valid zip code (5 digits or 9 digits)')
      })}
    >
      {({ resetForm }) => (
        <Form>
          <Card padding={0} {...(hasWrapperCard ? {} : { elevation: undefined })}>
            {hasWrapperCard && (
              <CardHeader background="white">
                <Heading>Verify Mailing Address</Heading>
              </CardHeader>
            )}
            <Pane display="grid" gridGap={16} padding={16}>
              <Card padding={0} elevation={1}>
                <CardHeader withButton>
                  <Heading>{editing ? 'Custom Delivery Address' : 'Profile Address'}</Heading>
                  {!editing && (
                    <IconButton icon={['fas', 'pencil-alt']} appearance="minimal" onClick={() => setEditing(true)} />
                  )}
                  {editing && (
                    <IconButton
                      icon={['fas', 'times']}
                      appearance="minimal"
                      onClick={() => {
                        setEditing(false)
                        resetForm()
                      }}
                    />
                  )}
                </CardHeader>
                <Pane padding={16}>
                  <DoubleTextInput
                    half={'top'}
                    name="street1"
                    disabled={!editing}
                    type="text"
                    placeholder="Street Address"
                    height={40}
                  />
                  <DoubleTextInput
                    half="bottom"
                    name="street2"
                    disabled={!editing}
                    type="text"
                    placeholder="Unit/Suite"
                    height={40}
                    marginBottom={8}
                  />

                  <TextInputField
                    name="city"
                    disabled={!editing}
                    label="City"
                    placeholder="City"
                    height={40}
                    marginBottom={8}
                  />

                  <Pane display="flex">
                    <TextInputField
                      flex={1}
                      name="state"
                      disabled={!editing}
                      label="State"
                      placeholder="State"
                      marginRight={16}
                      marginBottom={0}
                      height={40}
                    />
                    <TextInputField
                      flex={1}
                      name="zip"
                      disabled={!editing}
                      label="Zip Code"
                      placeholder="Zip Code"
                      marginBottom={0}
                      height={40}
                    />
                  </Pane>
                  <FormError collapseSpace marginTop={16} />
                  <Button
                    type="submit"
                    flexGrow={1}
                    appearance={verifiedAddress ? 'default' : 'primary'}
                    justifyContent="center"
                    intent="success"
                    height={48}
                    marginTop={16}
                    width="100%"
                    iconBefore={['fas', 'location-check']}
                    isLoading={loading}
                  >
                    Verify with USPS CASS
                  </Button>
                </Pane>
              </Card>

              {(verifiedAddress || mutationData) && (
                <Icon
                  color={verifiedAddress ? colors.green.base : colors.red.base}
                  marginY={8}
                  marginX="auto"
                  size="xl"
                  icon={['fad', 'chevrons-down']}
                />
              )}

              {verifiedAddress ? (
                <VerifiedAddressCard address={verifiedAddress} />
              ) : mutationData ? (
                <Card elevation={1} display="flex" flexDirection="column" alignItems="center">
                  <Strong size={500}>Address Undeliverable</Strong>
                  <Icon marginY={16} size="2x" color={colors.red.base} icon={['fad', 'location-xmark']} />
                  <Text size={500}>Please enter a new address</Text>
                </Card>
              ) : null}
            </Pane>
          </Card>
        </Form>
      )}
    </Formik>
  )
}

export default VerifyContactAddressCard
