import { Types } from 'lib'

import { useMutation } from '@apollo/client'
import { RESUME_MEMBERSHIP } from 'lib/graphql/_resume-membership-dialog'

import { Pane, Dialog, Text, toaster } from 'evergreen-ui'
import { Button, Spinner } from 'lib'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  subscriptionId: string
}

const ResumeMembershipDialog = ({ isShown, setIsShown, subscriptionId }: Props) => {
  const [resumeSubscription, resumeSubscriptionStatus] = useMutation<
    Types.ResumeSubscription,
    Types.ResumeSubscriptionVariables
  >(RESUME_MEMBERSHIP, {
    onCompleted: () => {
      toaster.success(`Plan successfully resumed`)
    },
    onError: () => {
      toaster.danger('Unable to resume plan')
    }
  })

  return (
    <Dialog
      isShown={isShown}
      title="Resume Membership Plan"
      onCloseComplete={() => {
        setIsShown(false)
      }}
      hasFooter={false}
    >
      {({ close }) => (
        <>
          <Pane padding={16}>
            <Text>This plan has unpaid and past due membership subscription fees. Please confirm that you would like to resume this plan and attempt to charge all previous unpaid membership fees associated with this plan. If you would not like to charge past due membership fees, please terminate this plan and enroll the patient in a new plan.</Text>
          </Pane>
          <Pane
            elevation={0}
            padding={16}
            margin={-16}
            marginTop={16}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {resumeSubscriptionStatus.loading ? (
              <Spinner delay={0} />
            ) : (
              <>
               <Button
                  height={48}
                  marginLeft={8}
                  appearance="minimal"
                  onClick={async () => {
                    close()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  height={48}
                  marginRight={8}
                  onClick={async () => {
                    await resumeSubscription({ variables: { subscriptionId } })
                    close()
                  }}
                  intent="success"
                >
                  Resume & Charge
                </Button>
              </>
            )}
          </Pane>
        </>
      )}
    </Dialog>
  )
}

export default ResumeMembershipDialog
