import { useState, useEffect } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { capital } from 'case'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'

import {
  GET_PAYMENT_METHOD_CARD,
  UPDATE_GUARANTOR_PAYMENT_METHOD,
  DELETE_GUARANTOR_PAYMENT_METHOD
} from 'lib/graphql/_payment-method-card'

import { Heading, Pane, Text, Badge, CardProps, toaster, Elevation, Strong } from 'evergreen-ui'

import {
  Types,
  IconButton,
  Card,
  CardHeader,
  CardElement,
  Icon,
  Button,
  Spinner,
  ConfirmDialog,
  colors,
  InfoTooltip,
  PlaidLinkButton,
  PlaidLinkButtonProps
} from 'lib'

export type Props = CardProps & {
  header?: string | null
  elevation?: Elevation

  forceCard?: boolean
  forceAch?: boolean
  allowDelete?: boolean // For Practice use Only

  attemptScheduledPayments?: boolean

  setPlaidState?: React.Dispatch<
    React.SetStateAction<
      | {
          token: string
          accountId: string
        }
      | undefined
    >
  >
} & (
    | // Standalone use for updating
    {
        mode: 'update'
        guarantorId: string
        allowDelete?: boolean
        onUpdated?: (data: Types.UpdateGuarantorPaymentMethod) => void
      }
    // Used to capture card details as part of checkout process
    // with or without existing payment method (Membership Enrollment)
    | {
        mode: 'checkout-allow-existing'
        guarantorId?: string
        allowDelete?: false
      }
    // Used to capture new card details as part of checkout process (Pay Portal)
    | {
        mode: 'checkout-no-existing'
        allowDelete?: false
      }
  )
const PaymentMethodCard = ({
  mode,

  elevation = 1,

  header = 'Payment Details',

  forceCard,
  forceAch,
  allowDelete = false,
  attemptScheduledPayments = false,

  setPlaidState,

  ...props
}: Props) => {
  const guarantorId = 'guarantorId' in props ? props.guarantorId : null
  const onUpdated = 'onUpdated' in props ? props.onUpdated : null

  const stripe = useStripe()
  const elements = useElements()

  const [plaidLocalState, setPlaidLocalState] = useState<null | {
    token: string
    accountId: string
    accountName: string
    institutionName: string
  }>(null)

  const plaidLinkButtonProps: PlaidLinkButtonProps = {
    userId: guarantorId,

    onSuccess: (token: string, { institution, accounts }) => {
      const account = accounts[0]
      if (!institution) throw Error('Institution not found')
      if (accounts.length !== 1) throw Error('Single account expected')

      // Callback prop passed from parent component  
      if (setPlaidState) setPlaidState({ token, accountId: account.id })

      // Internal component state   
      setPlaidLocalState({
        token,
        accountId: account.id,
        accountName: account.name,
        institutionName: institution.name
      })
    }
  }

  const [isSaving, setIsSaving] = useState(false)

  const [isCard, setIsCard] = useState(!forceAch)

  const [isDeleteConfirmShown, setIsDeleteConfirmShown] = useState(false)
  const [isUpdateConfirmShown, setIsUpdateConfirmShown] = useState(false)

  const { loading, data } = useQuery<Types.PaymentMethodCard, Types.PaymentMethodCardVariables>(
    GET_PAYMENT_METHOD_CARD,
    {
      skip: !guarantorId,
      variables: {
        guarantorId: guarantorId!,
        allowDelete,
        attemptScheduledPayments
      }
    }
  )

  const source = data?.contact?.stripe?.defaultSource
  // Default to true if there is no existing guarantor or if cached result has no default Source
  const [editing, setEditing] = useState(!guarantorId || (loading === false && !source))

  useEffect(() => {
    // Use instead of onCompleted to avoid lexical scoping error
    // Triggers after query completion or cache retrieval completion
    if (loading === false) {
      if (!editing && !source) setEditing(true)
    }
  }, [loading])

  const [updateMethod] = useMutation<Types.UpdateGuarantorPaymentMethod, Types.UpdateGuarantorPaymentMethodVariables>(
    UPDATE_GUARANTOR_PAYMENT_METHOD,
    {
      update: (cache, { data }) => {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            failedPatients: (_existing, { DELETE }) => DELETE,
            invoicesConnection: (_existing, { DELETE }) => DELETE
          }
        })

        if (data) {
          cache.modify({
            id: cache.identify({ id: data.updateGuarantorPaymentMethod?.contact?.id, __typename: 'Contact' }),
            fields: {
              stripe(cachedStripe) {
                return {
                  ...cachedStripe,
                  defaultSource: data.updateGuarantorPaymentMethod?.contact?.stripe?.defaultSource
                }
              }
            }
          })

          cache.evict({
            id: cache.identify({ id: data.updateGuarantorPaymentMethod?.contact?.id, __typename: 'Contact' }),
            fieldName: 'installmentPlans'
          })
        }

        cache.gc()
      },
      onCompleted: (updateData) => {
        const hadFailedPayments = data?.contact.stripe.latestOpenInvoice || data?.contact.hasFailedScheduledPayment

        if (
          updateData?.updateGuarantorPaymentMethod?.contact?.stripe?.latestOpenInvoice ||
          updateData?.updateGuarantorPaymentMethod?.error
        ) {
          toaster.warning('Unable to save Payment Method', {
            description: 'Payment details were rejected, please review'
          })
        } else if (attemptScheduledPayments && hadFailedPayments) {
          toaster.success('Payment method securely stored and account brought current.')
          if (onUpdated) onUpdated(updateData)

          setEditing(false)
        } else {
          toaster.success('Payment method securely stored.')
          if (onUpdated) onUpdated(updateData)

          setEditing(false)
        }
      },
      onError: (err) => {
        toaster.danger('Unable to update payment method', { description: err.message.replace('GraphQL error: ', '') })
      }
    }
  )

  const [deleteMethod, deleteMethodStatus] = useMutation<
    Types.DeleteGuarantorPaymentMethod,
    Types.DeleteGuarantorPaymentMethodVariables
  >(DELETE_GUARANTOR_PAYMENT_METHOD, {
    onCompleted: () => {
      setEditing(true)
      toaster.success('Payment method successfuly deleted')
    },
    onError: () => toaster.danger('Unable to delete payment method')
  })

  const updatePaymentMethod = async () => {
    try {
      setIsSaving(true)
      let paymentVariables

      if (isCard) {
        if (!elements || !stripe) throw Error('Stripe not initialized')
        const stripeElement = elements?.getElement('card')
        if (!stripeElement) throw Error('Stripe element not present')

        const { token: stripeToken } = await stripe.createToken(stripeElement)

        if (!stripeToken) throw Error('Please include a valid credit card')
        paymentVariables = { stripeToken: stripeToken.id }
      } else {
        if (!plaidLocalState) throw Error('Please securely link your bank account')
        paymentVariables = {
          plaidToken: plaidLocalState.token,
          plaidAccountId: plaidLocalState.accountId
        }
      }
      if (!guarantorId) throw Error('Guarantor ID missing')

      await updateMethod({
        variables: {
          guarantorId,
          attemptScheduledPayments,
          ...paymentVariables
        }
      })
    } catch (err) {
      if (err instanceof Error) toaster.danger(err.message)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Card elevation={elevation} padding={0} {...props}>
      {header && (
        <CardHeader justifyContent="space-between">
          <Pane display="flex" alignItems="center">
            <Heading size={500} flex={1}>
              {header}
            </Heading>
            {source && <InfoTooltip content={`Updated on ${moment.unix(source.createdAt).format('MM/DD/YY')}`} />}
          </Pane>

          <Badge color="neutral" position="relative" paddingLeft={20}>
            <Icon icon={['fas', 'lock']} fontSize="8px" position="absolute" top={-2} left={8} />
            PCI Secure
          </Badge>
        </CardHeader>
      )}
      {loading ? (
        <Pane padding={16} display="flex" alignItems="center">
          <Spinner size={32} delay={0} />
        </Pane>
      ) : editing ? (
        <>
          {!(forceCard || forceAch) && <PaymentTabs isCard={isCard} setIsCard={setIsCard} />}
          <Pane width="100%" padding={16}>
            {isCard ? (
              <Pane display="flex" alignItems="center">
                <CardElement />
              </Pane>
            ) : plaidLocalState ? (
              <Pane display="flex" justifyContent="space-between" alignItems="center">
                <Text size={500} marginRight={8}>
                  <Icon icon={['fad', 'building-columns']} marginRight={8} />
                  <Strong size={500}>{plaidLocalState.institutionName}</Strong> - {plaidLocalState.accountName}
                </Text>

                <PlaidLinkButton {...plaidLinkButtonProps}>Edit</PlaidLinkButton>
              </Pane>
            ) : (
              <Pane textAlign="center" padding={8}>
                <PlaidLinkButton iconBefore={['fas', 'building-columns']} marginBottom={4} {...plaidLinkButtonProps}>
                  Link Bank Account
                </PlaidLinkButton>

                <Text display="block" size={300}>
                  Securely link your bank account to pay
                  <br />
                  via ACH Direct Debit
                </Text>
              </Pane>
            )}
          </Pane>

          {/* Save / Cancel Toolbar */}
          {(mode === 'update' || (mode === 'checkout-allow-existing' && source)) && (
            <Pane display="flex" justifyContent="flex-end" padding={16} borderTop={`solid 1px ${colors.border.muted}`}>
              {source && (
                <Button marginRight={8} onClick={() => setEditing(false)}>
                  Cancel
                </Button>
              )}
              {mode === 'update' && (
                <Button
                  intent="success"
                  isLoading={isSaving}
                  onClick={async () => {
                    const creditForbidden = data && isCard && !data.contact.restrictedPaymentMethods.cc
                    const achForbidden = data && !isCard && !data.contact.restrictedPaymentMethods.ach
                    const hasFailedPayments =
                      data?.contact.stripe.latestOpenInvoice || data?.contact.hasFailedScheduledPayment

                    if (creditForbidden || achForbidden) {
                      toaster.danger('Cannot Update Payment Method', {
                        description: 'Payment method not compatible with Payment Plan rules.'
                      })
                    } else if (attemptScheduledPayments && hasFailedPayments) {
                      setIsUpdateConfirmShown(true)
                    } else {
                      await updatePaymentMethod()
                    }
                  }}
                  data-cy="save-payment-method-card-button"
                >
                  Save Payment Method
                </Button>
              )}
              <ConfirmDialog
                isShown={isUpdateConfirmShown}
                setIsShown={setIsUpdateConfirmShown}
                onConfirm={updatePaymentMethod}
                title="Charge Past Due Invoices"
                body="This guarantor has unpaid and past due installment or membership fees. By updating the payment method, we will attempt to charge all previous unpaid invoices and bring plans current. If you would not like to charge past due invoices, please terminate plans prior to updating the payment method."
                confirmLabel="Update & Charge"
              />
            </Pane>
          )}
        </>
      ) : (
        source && (
          <Pane padding={16} display="flex" alignItems="center">
            <Pane display="flex" flexGrow={1}>
              <Icon icon={['fad', source.isCredit ? 'credit-card' : 'building-columns']} marginRight={8} />
              <Text size={500} display="block" flex={1}>
                {capital(source.institution)}
              </Text>
              <Text size={500} display="block" marginLeft={8} textAlign="right">
                **{source.last4}
              </Text>
            </Pane>

            {guarantorId && (
              <>
                <Button
                  onClick={() => {
                    setIsCard(source.isCredit)
                    setEditing(true)
                  }}
                  marginLeft={16}
                >
                  Update
                </Button>
                {allowDelete && (
                  <>
                    <ConfirmDialog
                      isShown={isDeleteConfirmShown}
                      setIsShown={setIsDeleteConfirmShown}
                      onConfirm={() => deleteMethod({ variables: { guarantorId } })}
                      title="Confirm Payment Method Removal"
                      body="Please confirm that you would like to permanently delete this payment method stored on file. You can always add a new payment method, but any recurring scheduled charges will fail due to a lack of payment method on file."
                    />
                    <IconButton
                      marginLeft={8}
                      icon={['fas', 'trash-alt']}
                      isLoading={deleteMethodStatus.loading}
                      onClick={() => {
                        if (data?.contact.stripe.hasSubscriptions || data?.contact.hasOngoingInstallmentPlan) {
                          toaster.danger('Cannot Delete Payment Method', {
                            description:
                              'In order to remove the payment method on file, please terminate any active membership or payment plans.'
                          })
                        } else setIsDeleteConfirmShown(true)
                      }}
                    />
                  </>
                )}
              </>
            )}
          </Pane>
        )
      )}
    </Card>
  )
}

export default PaymentMethodCard

type PaymentTabsProps = {
  isCard: boolean
  setIsCard: (isCard: boolean) => void
}

const PaymentTabs = ({ isCard, setIsCard }: PaymentTabsProps) => {
  const tabProps = {
    height: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'content-box' as const,
    flex: 1,
    cursor: 'pointer'
  }

  return (
    <Pane display="flex" height="32px">
      <Pane
        {...tabProps}
        onClick={() => setIsCard(true)}
        background={isCard ? 'white' : colors.neutral.lightest}
        borderBottom={isCard ? 'none' : `solid 1px ${colors.border.muted}`}
      >
        <Icon marginRight={8} color={isCard ? 'default' : colors.neutral.lightest} icon={['fas', 'check-circle']} />
        <Text marginRight={16} size={300} color={isCard ? colors.neutral.base : 'muted'}>
          Credit Card
        </Text>
      </Pane>
      <Pane width={1} backgroundColor={colors.border.muted} />
      <Pane
        {...tabProps}
        cursor="pointer"
        onClick={() => setIsCard(false)}
        background={isCard ? colors.neutral.lightest : 'white'}
        borderBottom={isCard ? `solid 1px ${colors.border.muted}` : 'none'}
      >
        <Text marginLeft={8} size={300} color={isCard ? 'muted' : colors.neutral.base}>
          Bank ACH
        </Text>
        <Icon marginLeft={8} color={isCard ? colors.neutral.lightest : 'default'} icon={['fas', 'check-circle']} />
      </Pane>
    </Pane>
  )
}
