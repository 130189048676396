import { useState } from 'react'
import { Types } from 'lib'

import { useQuery } from '@apollo/client'
import { GET_ENROLL_MEMBER_CARD } from 'lib/graphql/_enroll-member-card'

import { CardProps, Heading, Badge, Pane } from 'evergreen-ui'
import { Card } from 'lib'
import { CardHeader } from 'lib'
import { Spinner } from 'lib'

import Plan from './enroll-member-plan'
import Payment from './enroll-member-payment'
import Complete from './enroll-member-complete'

export type Props = CardProps & {
  contactId: string
  isPortal?: boolean
}

const steps = [Plan, Payment, Complete]

export type EnrollMemberState = {
  step: number
  plan?: {
    id: string
    name: string
    term: Types.PlanTerm
    price: number
  }
  payment?: {
    billingCycleAnchor: number
  }
}

const EnrollMemberCard = ({ contactId, isPortal = false, ...props }: Props) => {
  const { data } = useQuery<Types.EnrollMemberCard, Types.EnrollMemberCardVariables>(GET_ENROLL_MEMBER_CARD, {
    variables: {
      id: contactId
    }
  })

  const [enrollState, setEnrollState] = useState<EnrollMemberState>({
    step: 0
  })

  const updateEnrollState = (update: Partial<EnrollMemberState>) => setEnrollState({ ...enrollState, ...update })

  const { step } = enrollState

  const Current = steps[step]

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  return (
    <Card {...props} padding={0}>
      <CardHeader justifyContent="space-between">
        <Heading>Membership Enrollment</Heading>
        {step < 2 && <Badge color="blue">Step {step + 1}/2</Badge>}
      </CardHeader>

      <Current
        contact={data.contact}
        enrollState={enrollState}
        updateEnrollState={updateEnrollState}
        isPortal={isPortal}
      />
    </Card>
  )
}

export default EnrollMemberCard
