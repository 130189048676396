import styled from 'styled-components/macro'

import { Pane, Heading, Alert, Text, Avatar, Strong } from 'evergreen-ui'
import { Icon, colors } from 'lib'

export type Props = object

const FollowUps = ({ ...props }: Props) => (
  <Alert hasIcon={false} hoverElevation={1} cursor="pointer">
    <Pane display="flex" alignItems="center">
      <Pane width="52px" display="flex" justifyContent="center" alignItems="center">
        <Icon icon={['fad', 'phone-volume']} size="2x" marginRight={20} marginLeft={8} color="default" />
      </Pane>
      <Pane flexGrow={1}>
        <Heading marginBottom={4}>Patients Requiring Follow Up</Heading>
        <Text color="muted">
          You have <Strong>4 patients</Strong> that have received at least 5 invoice reminders.
        </Text>
      </Pane>
      <Pane display="grid" gridTemplateRows="auto" gridGap={6} gridAutoFlow="column" paddingX={24}>
        <HoverAvatar name="Sean Emmer" color="blue" size={28} />
        <HoverAvatar name="Jeff Cole" color="blue" size={28} />
        <HoverAvatar name="Killy Groks" color="blue" size={28} />
        <HoverAvatar name="Dean Emmer" color="blue" size={28} />
      </Pane>
      <Icon icon={['far', 'chevron-right']} color="default" size="lg" marginRight={8} />
    </Pane>
  </Alert>
)

export default FollowUps

const HoverAvatar = styled(Avatar)`
  :hover {
    background-color: ${colors.blue.base};
    span {
      color: white;
    }
  }
`
