import gql from 'graphql-tag'

export const fragments = {
  userFields: gql`
    fragment userFields on User {
      id
      firstName
      lastName
      google {
        email
        verified
        role
      }
      notify_enrolled
      notify_paymentCompleted
      notify_invoiceFailed
      notify_eodPracticeReport
    }
  `
}

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $groupId: ID
    $firstName: String!
    $lastName: String!
    $role: UserRole!
    $notify_enrolled: Boolean!
    $notify_paymentCompleted: Boolean!
    $notify_invoiceFailed: Boolean!
    $notify_eodPracticeReport: Boolean!
  ) {
    createUser(
      email: $email
      groupId: $groupId
      firstName: $firstName
      lastName: $lastName
      role: $role
      notify_enrolled: $notify_enrolled
      notify_paymentCompleted: $notify_paymentCompleted
      notify_invoiceFailed: $notify_invoiceFailed
      notify_eodPracticeReport: $notify_eodPracticeReport
    ) {
      ...userFields
    }
  }
  ${fragments.userFields}
`

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $role: UserRole!
    $notify_enrolled: Boolean!
    $notify_paymentCompleted: Boolean!
    $notify_invoiceFailed: Boolean!
    $notify_eodPracticeReport: Boolean!
  ) {
    updateUser(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      role: $role
      notify_enrolled: $notify_enrolled
      notify_paymentCompleted: $notify_paymentCompleted
      notify_invoiceFailed: $notify_invoiceFailed
      notify_eodPracticeReport: $notify_eodPracticeReport
    ) {
      ...userFields
    }
  }
  ${fragments.userFields}
`

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`

export const USER_SHEET_RESEND_INVITE = gql`
  mutation UserSheetResendInvite($id: ID!) {
    resendUserInvite(id: $id) {
      id
    }
  }
`
