import 'graphql'
import gql from 'graphql-tag'

export const UPDATE_MEMBERSHIP = gql`
  mutation UpdateMembership(
    $contactId: ID!
    $autoRenew: Boolean
    $renewalAnchor: Int
    $switchPlanId: String
    $switchPlanTerm: PlanTerm
    $coupon: String
  ) {
    updateMembership(
      contactId: $contactId
      autoRenew: $autoRenew
      renewalAnchor: $renewalAnchor
      switchPlanId: $switchPlanId
      switchPlanTerm: $switchPlanTerm
      coupon: $coupon
    ) {
      id
      planRenewalDate
      stripe {
        membership {
          id
          effectiveDate
          status
          autoRenew
          price
          interval
        }
      }
    }
  }
`

export const PAUSE_MEMBERSHIP = gql`
  mutation PauseMembership($subscriptionId: String!) {
    pauseContactSubscription(pause: true, subscriptionId: $subscriptionId) {
      id
      stripe {
        membership {
          id
          effectiveDate
          status
          autoRenew
          price
          interval
        }
        hasSubscriptions
      }
    }
  }
`

export const TERMINATE_MEMBERSHIP = gql`
  mutation TerminateMembership($contactId: ID!) {
    terminateMembership(contactId: $contactId) {
      id

      # Fields below wsed for cache update
      guarantorId
      stripe {
        hasSubscriptions
      }
    }
  }
`
