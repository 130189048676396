import { toaster } from 'evergreen-ui'
import { Types, PatientDetails } from 'lib'

export type Props = {
  guarantorId: string
  closeModal?: () => void
}

const GuarantorProfile = ({ guarantorId, closeModal }: Props) => {
  return (
    <PatientDetails
      height={40}
      optionalFields={[
        Types.PatientField.EMAIL,
        Types.PatientField.PHONE,
        Types.PatientField.PERIO,
        Types.PatientField.DOB,
        Types.PatientField.ADDRESS
      ]}
      isProfile
      contactId={guarantorId}
      closeModal={closeModal}
      onUpdated={() => toaster.success('Guarantor successfully updated')}
      onSkip={() => toaster.notify('Guarantor details are unchanged')}
      button={{ label: 'Update Profile' }}
    />
  )
}

export default GuarantorProfile
