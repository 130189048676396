import { useEffect } from 'react'
import { Types } from 'lib'

import { useMutation, useApolloClient } from '@apollo/client'
import { INVITE_MEMBER } from 'lib/graphql/_invite_fields'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Paragraph, toaster } from 'evergreen-ui'
import { TextInputFieldWithButton } from 'lib'

export type Props = {
  successCB?: () => void
}

const InviteFields = ({ successCB }: Props) => {
  const apolloClient = useApolloClient()

  const [inviteMember, { loading, error, data }] = useMutation<Types.InviteMember, Types.InviteMemberVariables>(
    INVITE_MEMBER,
    {
      client: apolloClient
    }
  )

  useEffect(() => {
    if (error) toaster.danger('Unable to send invitation.')
    if (!loading && data) {
      toaster.success(`Invitation sent!`)
      if (successCB) successCB()
    }
  }, [loading, error, data])

  return (
    <Formik
      initialValues={{ emailOrPhone: '' }}
      onSubmit={({ emailOrPhone }, { resetForm }) => {
        if (emailOrPhone.match(/^[0-9]\d{9}$/) || emailOrPhone.match(/\S+@\S+\.\S+/)) {
          inviteMember({ variables: { emailOrPhone } })
          resetForm()
        } else {
          toaster.warning('Please submit a valid email address or phone number')
        }
      }}
      validationSchema={Yup.object({})}
    >
      <Form style={{ maxWidth: '100%' }}>
        <TextInputFieldWithButton
          isButtonLoading={loading}
          name="emailOrPhone"
          placeholder="8058881234   OR   john@harris.com"
          buttonIconAfter={['fas', 'paper-plane']}
          height={40}
          width="100%"
          maxWidth="376px"
          marginBottom={4}
          label="Invite"
        />
        <Paragraph color="muted" size={300} textAlign="center">
          Input an email or mobile number to send your signup link.
          <br />
          This confirms that you have received opt-in permission.
        </Paragraph>
      </Form>
    </Formik>
  )
}

export default InviteFields
