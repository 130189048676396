import gql from 'graphql-tag'

export const GET_START_GUARANTOR_OUTREACH_CARD = gql`
  query StartGuarantorOutreachCard($id: ID!) {
    contact(id: $id) {
      id
      name {
        first
        last
      }
    }
  }
`

export const START_GUARANTOR_FLOW_ACTION = gql`
  mutation StartGurantorFlowAction($guarantorId: ID!) {
    startFlow(guarantorId: $guarantorId) {
      id
    }
  }
`
