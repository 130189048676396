import { Pane, Heading } from 'evergreen-ui'
import { Types, Button, colors, PayOverTimeSelector } from 'lib'

import { EnrollInstallmentsState } from '.'

export type Props = {
  guarantor: Types.EnrollInstallmentsCard_contact
  enrollState: EnrollInstallmentsState
  updateEnrollState: (update: Partial<EnrollInstallmentsState>) => void
}

const EnrollInstallmentsTemplate = ({
  guarantor,
  enrollState: { amount, installmentTemplate: template, step },
  updateEnrollState
}: Props) => {
  if (!amount) throw Error('EnrollState amount missing')

  return (
    <>
      <Pane display="flex" flexDirection="column" alignItems="center" padding={16}>
        <Heading marginTop={16} marginBottom={16}>
          Select Schedule:
        </Heading>
        <PayOverTimeSelector
          amount={amount}
          guarantorId={guarantor.id}
          width="100%"
          initialSelectedId={template?.id}
          setSelectedTemplate={(template) => updateEnrollState({ installmentTemplate: template })}
        />
      </Pane>
      <Pane display="flex" flexDirection="row" padding={16} borderTop={`solid 1px ${colors.border.muted}`}>
        <Button appearance="minimal" height={48} marginRight={16} onClick={() => updateEnrollState({ step: step - 1 })}>
          Back
        </Button>
        <Button
          type="submit"
          height={48}
          appearance="primary"
          flexGrow={1}
          rightChevron
          onClick={() => updateEnrollState({ step: step + 1 })}
          visibility={template ? 'visible' : 'hidden'}
        >
          <Pane textAlign="center" width="100%">
            Next
          </Pane>
        </Button>
      </Pane>
    </>
  )
}

export default EnrollInstallmentsTemplate
