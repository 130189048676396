import gql from 'graphql-tag'

const fragments = {
  FinancingProviderFields: gql`
    fragment FinancingProviderFields on FinancingProvider {
      id
      name
      url
      description
      enabled

      balanceFilter {
        id
        minBalance
        maxBalance
        allowL30
        allowL60
        allowL90
        allowG90
      }
    }
  `
}

export const UPSERT_FINANCING_PROVIDER = gql`
  mutation UpsertFinancingProvider(
    $id: ID
    $name: String!
    $url: String!
    $description: String!
    $balanceFilter: PartialBalanceFilterInput!
  ) {
    upsertFinancingProvider(id: $id, name: $name, url: $url, description: $description, balanceFilter: $balanceFilter) {
      ...FinancingProviderFields
    }
  }
  ${fragments.FinancingProviderFields}
`

export const DELETE_FINANCING_PROVIDER = gql`
  mutation DeleteFinancingProvider($id: ID!) {
    deleteFinancingProvider(id: $id) {
      id
    }
  }
`
