import { forwardRef } from 'react'

import { Text } from 'evergreen-ui'
import { InfoTooltip, InfoTooltipProps, Link } from './'

export type Props = Omit<InfoTooltipProps, 'content'> & {
  sunbitEstimate: {
    downPayment: number
    amountFinanced: number
    monthlyAmount: number
    apr: number
  }
}

const SunbitDisclaimer = forwardRef<HTMLDivElement, Props>(({ sunbitEstimate, ...props }, ref) => {
  const { apr } = sunbitEstimate

  return (
    <InfoTooltip
      marginLeft={0}
      opacity={0.5}
      fontSize={11}
      {...props}
      ref={ref}
      width="320px"
      content={
        <Text color="white" fontSize="12px">
          Example based on 18 monthly payments, a {apr}% APR, and a 10% payment of the purchase price at checkout
          including tax. Actual terms will vary. Subject to approval based on creditworthiness (0% - 35.99% APR).
          Account openings and payment activity are reported to a major credit bureau. See{' '}
          <Link inline href="https://sunbit.com/rates_and_terms/" target="_blank">
            <Text color="white" fontSize="12px" textDecoration="underline">
              Rates and Terms
            </Text>
          </Link>{' '}
          for loan requirements and state restrictions. Sunbit is licensed under the CT Laws Relating to Small Loans
          (lic. #SLC-1760582 & SLC-BCH-1844702). Loans are made by TAB Bank.
        </Text>
      }
    />
  )
})

export default SunbitDisclaimer
