import gql from 'graphql-tag'

export const SEND_TEST_EMAIL_NOTIFICATION = gql`
  mutation SendTestEmailNotification(
    $email: String!
    $subject: String!
    $body: String!
    $signature: String!
    $buttonUrl: String
    $buttonLabel: String
  ) {
    deliverTestEmailNotification(
      email: $email
      subject: $subject
      body: $body
      signature: $signature
      buttonUrl: $buttonUrl
      buttonLabel: $buttonLabel
    )
  }
`

export const SEND_TEST_SMS_NOTIFICATION = gql`
  mutation SendTestSmsNotification($phone: String!, $body: String!) {
    deliverTestSmsNotification(phone: $phone, body: $body)
  }
`
