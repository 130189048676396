export const cdtData: [string, string, string, string][] = [
  ['D0120', 'Diagnostic', 'Periodic Oral Evaluation - Established Patient', 'Periodic Oral Evaluation'],
  ['D0140', 'Diagnostic', 'Limited Oral Evaluation - Problem Focused', 'Limited Oral Evaluation'],
  [
    'D0145',
    'Diagnostic',
    'Oral Evaluation For A Patient Under Three Years Of Age And Counseling With Primary Caregiver',
    'Oral Evaluation (Under Three Years Of Age)'
  ],
  [
    'D0150',
    'Diagnostic',
    'Comprehensive Oral Evaluation - New Or Established Patient',
    'Comprehensive Oral Evaluation'
  ],
  [
    'D0160',
    'Diagnostic',
    'Detailed And Extensive Oral Evaluation - Problem Focused, By Report',
    'Detailed And Extensive Oral Evaluation'
  ],
  [
    'D0170',
    'Diagnostic',
    'Re-Evaluation - Limited, Problem Focused (Established Patient; Not Post-Operative Visit)',
    'Re-Evaluation - Limited'
  ],
  ['D0171', 'Diagnostic', 'Re-Evaluation – Post-Operative Office Visit', 'Re-Evaluation – Post-Operative'],
  [
    'D0180',
    'Diagnostic',
    'Comprehensive Periodontal Evaluation - New Or Established Patient',
    'Comprehensive Periodontal Evaluation'
  ],
  ['D0190', 'Diagnostic', 'Screening Of A Patient', 'Screening Of A Patient'],
  ['D0191', 'Diagnostic', 'Assessment Of A Patient', 'Assessment Of A Patient'],
  [
    'D0210',
    'Diagnostic',
    'Intraoral - Complete Series Of Radiographic Images',
    'Intraoral - Series Of Radiographic Images'
  ],
  ['D0220', 'Diagnostic', 'Intraoral - Periapical First Radiographic Image', 'Intraoral - First Radiographic Image'],
  [
    'D0230',
    'Diagnostic',
    'Intraoral - Periapical Each Additional Radiographic Image',
    'Intraoral - Additional Radiographic Image'
  ],
  ['D0240', 'Diagnostic', 'Intraoral - Occlusal Radiographic Image', 'Intraoral - Occlusal Radiographic Image'],
  [
    'D0250',
    'Diagnostic',
    'Extra-Oral – 2D Projection Radiographic Image Created Using A Stationary Radiation Source, And Detector',
    'Extra-Oral – 2D Projection Radiographic Image'
  ],
  [
    'D0251',
    'Diagnostic',
    'Extra-Oral Posterior Dental Radiographic Image',
    'Extra-Oral Posterior Dental Radiographic Image'
  ],
  ['D0270', 'Diagnostic', 'Bitewing - Single Radiographic Image', 'Bitewing - 1 Radiographic Image'],
  ['D0272', 'Diagnostic', 'Bitewings - Two Radiographic Images', 'Bitewings - 2 Radiographic Images'],
  ['D0273', 'Diagnostic', 'Bitewings - Three Radiographic Images', 'Bitewings - 3 Radiographic Images'],
  ['D0274', 'Diagnostic', 'Bitewings - Four Radiographic Images', 'Bitewings - 4 Radiographic Images'],
  [
    'D0277',
    'Diagnostic',
    'Vertical Bitewings - 7 To 8 Radiographic Images',
    'Vertical Bitewings - 7 To 8 Radiographic Images'
  ],
  ['D0310', 'Diagnostic', 'Sialography', 'Sialography'],
  [
    'D0320',
    'Diagnostic',
    'Temporomandibular Joint Arthrogram, Including Injection',
    'Temporomandibular Joint Arthrogram'
  ],
  [
    'D0321',
    'Diagnostic',
    'Other Temporomandibular Joint Radiographic Images, By Report',
    'Other Temporomandibular Joint Radiographic Images'
  ],
  ['D0322', 'Diagnostic', 'Tomographic Survey', 'Tomographic Survey'],
  ['D0330', 'Diagnostic', 'Panoramic Radiographic Image', 'Panoramic Radiographic Image'],
  [
    'D0340',
    'Diagnostic',
    '2D Cephalometric Radiographic Image – Acquisition, Measurement And Analysis',
    '2D Cephalometric Radiographic Image'
  ],
  [
    'D0350',
    'Diagnostic',
    '2D Oral/Facial Photographic Image Obtained Intra-Orally Or Extra-Orally',
    '2D Oral/Facial Photographic Image'
  ],
  ['D0351', 'Diagnostic', '3D Photographic Image', '3D Photographic Image'],
  [
    'D0364',
    'Diagnostic',
    'Cone Beam Ct Capture And Interpretation With Limited Field Of View – Less Than One Whole Jaw',
    'Cone Beam Ct Capture And Interpretation'
  ],
  [
    'D0365',
    'Diagnostic',
    'Cone Beam Ct Capture And Interpretation With Field Of View Of One Full Dental Arch – Mandible',
    'Cone Beam Ct Capture And Interpretation'
  ],
  [
    'D0366',
    'Diagnostic',
    'Cone Beam Ct Capture And Interpretation With Field Of View Of One Full Dental Arch – Maxilla, With Or Without Cranium',
    'Cone Beam Ct Capture And Interpretation'
  ],
  [
    'D0367',
    'Diagnostic',
    'Cone Beam Ct Capture And Interpretation With Field Of View Of Both Jaws; With Or Without Cranium',
    'Cone Beam Ct Capture And Interpretation'
  ],
  [
    'D0368',
    'Diagnostic',
    'Cone Beam Ct Capture And Interpretation For Tmj Series Including Two Or More Exposures',
    'Cone Beam Ct Capture And Interpretation For Tmj Series'
  ],
  [
    'D0369',
    'Diagnostic',
    'Maxillofacial Mri Capture And Interpretation',
    'Maxillofacial Mri Capture And Interpretation'
  ],
  [
    'D0370',
    'Diagnostic',
    'Maxillofacial Ultrasound Capture And Interpretation',
    'Maxillofacial Ultrasound Capture And Interpretation'
  ],
  ['D0371', 'Diagnostic', 'Sialoendoscopy Capture And Interpretation', 'Sialoendoscopy Capture And Interpretation'],
  [
    'D0380',
    'Diagnostic',
    'Cone Beam Ct Image Capture With Limited Field Of View – Less Than One Whole Jaw',
    'Cone Beam Ct Image Capture With Limited Field Of View '
  ],
  [
    'D0381',
    'Diagnostic',
    'Cone Beam Ct Image Capture With Field Of View Of One Full Dental Arch – Mandible',
    'Cone Beam Ct Image Capture With Field Of View Of One Full Dental Arch '
  ],
  [
    'D0382',
    'Diagnostic',
    'Cone Beam Ct Image Capture With Field Of View Of One Full Dental Arch – Maxilla, With Or Without Cranium',
    'Cone Beam Ct Image Capture With Field Of View Of One Full Dental Arch '
  ],
  [
    'D0383',
    'Diagnostic',
    'Cone Beam Ct Image Capture With Field Of View Of Both Jaws; With Or Without Cranium',
    'Cone Beam Ct Image Capture With Field Of View Of Both Jaws'
  ],
  [
    'D0384',
    'Diagnostic',
    'Cone Beam Ct Image Capture For Tmj Series Including Two Or More Exposures',
    'Cone Beam Ct Image Capture For Tmj Series'
  ],
  ['D0385', 'Diagnostic', 'Maxillofacial Mri Image Capture', 'Maxillofacial Mri Image Capture'],
  ['D0386', 'Diagnostic', 'Maxillofacial Ultrasound Image Capture', 'Maxillofacial Ultrasound Image Capture'],
  [
    'D0391',
    'Diagnostic',
    'Interpretation Of Diagnostic Image By A Practitioner Not Associated With Capture Of The Image, Including Report',
    'Interpretation Of Diagnostic Image'
  ],
  ['D0393', 'Diagnostic', 'Treatment Simulation Using 3D Image Volume', 'Treatment Simulation Using 3D Image Volume'],
  [
    'D0394',
    'Diagnostic',
    'Digital Subtraction Of Two Or More Images Or Image Volumes Of The Same Modality',
    'Digital Subtraction Of Two Or More Images'
  ],
  [
    'D0395',
    'Diagnostic',
    'Fusion Of Two Or More 3D Image Volumes Of One Or More Modalities',
    'Fusion Of Two Or More 3D Image Volumes'
  ],
  ['D0411', 'Diagnostic', 'Hba1C In-Office Point Of Service Testing', 'Hba1C In-Office Point Of Service Testing'],
  ['D0412', 'Diagnostic', 'Blood Glucose Level Test – In-Office Using A Glucose Meter', 'Blood Glucose Level Test'],
  [
    'D0414',
    'Diagnostic',
    'Laboratory Processing Of Microbial Specimen To Include Culture And Sensitivity Studies, Preparation And Transmission Of Written Report',
    'Laboratory Processing'
  ],
  [
    'D0415',
    'Diagnostic',
    'Collection Of Microorganisms For Culture And Sensitivity',
    'Collection Of Microorganisms For Culture And Sensitivity'
  ],
  ['D0416', 'Diagnostic', 'Viral Culture', 'Viral Culture'],
  [
    'D0417',
    'Diagnostic',
    'Collection And Preparation Of Saliva Sample For Laboratory Diagnostic Testing',
    'Collection And Preparation Of Saliva Sample'
  ],
  ['D0418', 'Diagnostic', 'Analysis Of Saliva Sample', 'Analysis Of Saliva Sample'],
  ['D0419', 'Diagnostic', 'Assessment Of Salivary Flow By Measurement', 'Assessment Of Salivary Flow By Measurement'],
  [
    'D0422',
    'Diagnostic',
    'Collection And Preparation Of Genetic Sample Material For Laboratory Analysis And Report',
    'Collection And Preparation Of Genetic Sample Material'
  ],
  [
    'D0423',
    'Diagnostic',
    'Genetic Test For Susceptibility To Diseases – Specimen Analysis',
    'Genetic Test For Susceptibility To Diseases'
  ],
  ['D0425', 'Diagnostic', 'Caries Susceptibility Tests', 'Caries Susceptibility Tests'],
  [
    'D0431',
    'Diagnostic',
    'Adjunctive Pre-Diagnostic Test That Aids In Detection Of Mucosal Abnormalities Including Premalignant And Malignant Lesions, Not To Include Cytology Or Biopsy Procedures',
    'Adjunctive Pre-Diagnostic Test'
  ],
  ['D0460', 'Diagnostic', 'Pulp Vitality Tests', 'Pulp Vitality Tests'],
  ['D0470', 'Diagnostic', 'Diagnostic Casts', 'Diagnostic Casts'],
  [
    'D0472',
    'Diagnostic',
    'Accession Of Tissue, Gross Examination, Preparation And Transmission Of Written Report',
    'Accession Of Tissue, Gross Examination, Preparation And Transmission Of Written Report'
  ],
  [
    'D0473',
    'Diagnostic',
    'Accession Of Tissue, Gross And Microscopic Examination, Preparation And Transmission Of Written Report',
    'Accession Of Tissue, Gross And Microscopic Examination, Preparation And Transmission Of Written Report'
  ],
  [
    'D0474',
    'Diagnostic',
    'Accession Of Tissue, Gross And Microscopic Examination, Including Assessment Of Surgical Margins For Presence Of Disease, Preparation And Transmission Of Written Report',
    'Accession Of Tissue, Gross And Microscopic Examination, Including Assessment Of Surgical Margins'
  ],
  ['D0475', 'Diagnostic', 'Decalcification Procedure', 'Decalcification Procedure'],
  ['D0476', 'Diagnostic', 'Special Stains For Microorganisms', 'Special Stains For Microorganisms'],
  ['D0477', 'Diagnostic', 'Special Stains, Not For Microorganisms', 'Special Stains, Not For Microorganisms'],
  ['D0478', 'Diagnostic', 'Immunohistochemical Stains', 'Immunohistochemical Stains'],
  [
    'D0479',
    'Diagnostic',
    'Tissue In-Situ Hybridization, Including Interpretation',
    'Tissue In-Situ Hybridization, Including Interpretation'
  ],
  [
    'D0480',
    'Diagnostic',
    'Accession Of Exfoliative Cytologic Smears, Microscopic Examination, Preparation And Transmission Of Written Report',
    'Accession Of Exfoliative Cytologic Smears, Microscopic Examination, Preparation'
  ],
  ['D0481', 'Diagnostic', 'Electron Microscopy', 'Electron Microscopy'],
  ['D0482', 'Diagnostic', 'Direct Immunofluorescence', 'Direct Immunofluorescence'],
  ['D0483', 'Diagnostic', 'Indirect Immunofluorescence', 'Indirect Immunofluorescence'],
  ['D0484', 'Diagnostic', 'Consultation On Slides Prepared Elsewhere', 'Consultation On Slides Prepared Elsewhere'],
  [
    'D0485',
    'Diagnostic',
    'Consultation, Including Preparation Of Slides From Biopsy Material Supplied By Referring Source',
    'Consultation, Including Preparation Of Slides'
  ],
  [
    'D0486',
    'Diagnostic',
    'Laboratory Accession Of Transepithelial Cytologic Sample, Microscopic Examination, Preparation And Transmission Of Written Report',
    'Laboratory Accession Of Transepithelial Cytologic Sample, Microscopic Examination, Preparation And Transmission Of Written Report'
  ],
  ['D0502', 'Diagnostic', 'Other Oral Pathology Procedures, By Report', 'Other Oral Pathology Procedures'],
  [
    'D0600',
    'Diagnostic',
    'Non-Ionizing Diagnostic Procedure Capable Of Quantifying, Monitoring, And Recording Changes In Structure Of Enamel, Dentin, And Cementum',
    'Non-Ionizing Diagnostic Procedure '
  ],
  [
    'D0601',
    'Diagnostic',
    'Caries Risk Assessment And Documentation, With A Finding Of Low Risk',
    'Caries Risk Assessment And Documentation, With A Finding Of Low Risk'
  ],
  [
    'D0602',
    'Diagnostic',
    'Caries Risk Assessment And Documentation, With A Finding Of Moderate Risk',
    'Caries Risk Assessment And Documentation, With A Finding Of Moderate Risk'
  ],
  [
    'D0603',
    'Diagnostic',
    'Caries Risk Assessment And Documentation, With A Finding Of High Risk',
    'Caries Risk Assessment And Documentation, With A Finding Of High Risk'
  ],
  ['D0999', 'Diagnostic', 'Unspecified Diagnostic Procedure, By Report', 'Unspecified Diagnostic Procedure'],
  ['D1110', 'Preventive', 'Prophylaxis - Adult', 'Adult Professional Cleaning'],
  ['D1120', 'Preventive', 'Prophylaxis - Child', 'Child Professional Cleaning'],
  ['D1206', 'Preventive', 'Topical Application Of Fluoride Varnish', 'Fluoride Varnish'],
  ['D1208', 'Preventive', 'Topical Application Of Fluoride – Excluding Varnish', 'Fluoride – Excluding Varnish'],
  [
    'D1310',
    'Preventive',
    'Nutritional Counseling For Control Of Dental Disease',
    'Nutritional Counseling For Control Of Dental Disease'
  ],
  [
    'D1320',
    'Preventive',
    'Tobacco Counseling For The Control And Prevention Of Oral Disease',
    'Tobacco Counseling For The Control And Prevention Of Oral Disease'
  ],
  ['D1330', 'Preventive', 'Oral Hygiene Instructions', 'Oral Hygiene Instructions'],
  ['D1351', 'Preventive', 'Sealant - Per Tooth', 'Sealant - Per Tooth'],
  [
    'D1352',
    'Preventive',
    'Preventive Resin Restoration In A Moderate To High Caries Risk Patient – Permanent Tooth',
    'Preventive Resin Restoration In A Moderate To High Caries Risk Patient – Permanent Tooth'
  ],
  ['D1353', 'Preventive', 'Sealant Repair – Per Tooth', 'Sealant Repair – Per Tooth'],
  [
    'D1354',
    'Preventive',
    'Interim Caries Arresting Medicament Application – Per Tooth',
    'Interim Caries Arresting Medicament Application – Per Tooth'
  ],
  [
    'D1510',
    'Preventive',
    'Space Maintainer - Fixed, Unilateral – Per Quadrant',
    'Space Maintainer - Fixed, Unilateral – Per Quadrant'
  ],
  [
    'D1516',
    'Preventive',
    'Space Maintainer - Fixed - Bilateral, Maxillary',
    'Space Maintainer - Fixed - Bilateral, Maxillary'
  ],
  [
    'D1517',
    'Preventive',
    'Space Maintainer - Fixed - Bilateral, Mandibular',
    'Space Maintainer - Fixed - Bilateral, Mandibular'
  ],
  [
    'D1520',
    'Preventive',
    'Space Maintainer - Removable, Unilateral - Per Quadrant',
    'Space Maintainer - Removable, Unilateral - Per Quadrant'
  ],
  [
    'D1526',
    'Preventive',
    'Space Maintainer - Removable - Bilateral, Maxillary',
    'Space Maintainer - Removable - Bilateral, Maxillary'
  ],
  [
    'D1527',
    'Preventive',
    'Space Maintainer - Removable - Bilateral, Mandibular',
    'Space Maintainer - Removable - Bilateral, Mandibular'
  ],
  ['D1550', 'Preventive', 'Re-Cement Or Re-Bond Space Maintainer', 'Re-Cement Or Re-Bond Space Maintainer'],
  [
    'D1551',
    'Preventive',
    'Re-Cement Or Re-Bond Bilateral Space Maintainer - Maxillary',
    'Re-Cement Or Re-Bond Bilateral Space Maintainer - Maxillary'
  ],
  [
    'D1552',
    'Preventive',
    'Re-Cement Or Re-Bond Bilateral Space Maintainer - Mandibular',
    'Re-Cement Or Re-Bond Bilateral Space Maintainer - Mandibular'
  ],
  [
    'D1553',
    'Preventive',
    'Re-Cement Or Re-Bond Unilateral Space Maintainer - Per Quadrant',
    'Re-Cement Or Re-Bond Unilateral Space Maintainer - Per Quadrant'
  ],
  ['D1555', 'Preventive', 'Removal Of Fixed Space Maintainer', 'Removal Of Fixed Space Maintainer'],
  [
    'D1556',
    'Preventive',
    'Removal Of Fixed Unilateral Space Maintainer - Per Quadrant',
    'Removal Of Fixed Unilateral Space Maintainer - Per Quadrant'
  ],
  [
    'D1557',
    'Preventive',
    'Removal Of Fixed Bilateral Space Maintainer - Maxillary',
    'Removal Of Fixed Bilateral Space Maintainer - Maxillary'
  ],
  [
    'D1558',
    'Preventive',
    'Removal Of Fixed Bilateral Space Maintainer - Mandibular',
    'Removal Of Fixed Bilateral Space Maintainer - Mandibular'
  ],
  [
    'D1575',
    'Preventive',
    'Distal Shoe Space Maintainer - Fixed, Unilateral - Per Quadrant',
    'Distal Shoe Space Maintainer - Fixed, Unilateral - Per Quadrant'
  ],
  ['D1999', 'Preventive', 'Unspecified Preventive Procedure, By Report', 'Unspecified Preventive Procedure'],
  [
    'D2140',
    'Restorative',
    'Amalgam - One Surface, Primary Or Permanent',
    'Amalgam - One Surface, Primary Or Permanent'
  ],
  [
    'D2150',
    'Restorative',
    'Amalgam - Two Surfaces, Primary Or Permanent',
    'Amalgam - Two Surfaces, Primary Or Permanent'
  ],
  [
    'D2160',
    'Restorative',
    'Amalgam - Three Surfaces, Primary Or Permanent',
    'Amalgam - Three Surfaces, Primary Or Permanent'
  ],
  [
    'D2161',
    'Restorative',
    'Amalgam - Four Or More Surfaces, Primary Or Permanent',
    'Amalgam - Four Or More Surfaces, Primary Or Permanent'
  ],
  [
    'D2330',
    'Restorative',
    'Resin-Based Composite - One Surface, Anterior',
    'Resin-Based Composite - One Surface, Anterior'
  ],
  [
    'D2331',
    'Restorative',
    'Resin-Based Composite - Two Surfaces, Anterior',
    'Resin-Based Composite - Two Surfaces, Anterior'
  ],
  [
    'D2332',
    'Restorative',
    'Resin-Based Composite - Three Surfaces, Anterior',
    'Resin-Based Composite - Three Surfaces, Anterior'
  ],
  [
    'D2335',
    'Restorative',
    'Resin-Based Composite - Four Or More Surfaces Or Involving Incisal Angle (Anterior)',
    'Resin-Based Composite - Four Or More Surfaces'
  ],
  ['D2390', 'Restorative', 'Resin-Based Composite Crown, Anterior', 'Resin-Based Composite Crown, Anterior'],
  [
    'D2391',
    'Restorative',
    'Resin-Based Composite - One Surface, Posterior',
    'Resin-Based Composite - One Surface, Posterior'
  ],
  [
    'D2392',
    'Restorative',
    'Resin-Based Composite - Two Surfaces, Posterior',
    'Resin-Based Composite - Two Surfaces, Posterior'
  ],
  [
    'D2393',
    'Restorative',
    'Resin-Based Composite - Three Surfaces, Posterior',
    'Resin-Based Composite - Three Surfaces, Posterior'
  ],
  [
    'D2394',
    'Restorative',
    'Resin-Based Composite - Four Or More Surfaces, Posterior',
    'Resin-Based Composite - Four Or More Surfaces, Posterior'
  ],
  ['D2410', 'Restorative', 'Gold Foil - One Surface', 'Gold Foil - One Surface'],
  ['D2420', 'Restorative', 'Gold Foil - Two Surfaces', 'Gold Foil - Two Surfaces'],
  ['D2430', 'Restorative', 'Gold Foil - Three Surfaces', 'Gold Foil - Three Surfaces'],
  ['D2510', 'Restorative', 'Inlay - Metallic - One Surface', 'Inlay - Metallic - One Surface'],
  ['D2520', 'Restorative', 'Inlay - Metallic - Two Surfaces', 'Inlay - Metallic - Two Surfaces'],
  ['D2530', 'Restorative', 'Inlay - Metallic - Three Or More Surfaces', 'Inlay - Metallic - Three Or More Surfaces'],
  ['D2542', 'Restorative', 'Onlay - Metallic - Two Surfaces', 'Onlay - Metallic - Two Surfaces'],
  ['D2543', 'Restorative', 'Onlay - Metallic - Three Surfaces', 'Onlay - Metallic - Three Surfaces'],
  ['D2544', 'Restorative', 'Onlay - Metallic - Four Or More Surfaces', 'Onlay - Metallic - Four Or More Surfaces'],
  ['D2610', 'Restorative', 'Inlay - Porcelain/Ceramic - One Surface', 'Inlay - Porcelain/Ceramic - One Surface'],
  ['D2620', 'Restorative', 'Inlay - Porcelain/Ceramic - Two Surfaces', 'Inlay - Porcelain/Ceramic - Two Surfaces'],
  [
    'D2630',
    'Restorative',
    'Inlay - Porcelain/Ceramic - Three Or More Surfaces',
    'Inlay - Porcelain/Ceramic - Three Or More Surfaces'
  ],
  ['D2642', 'Restorative', 'Onlay - Porcelain/Ceramic - Two Surfaces', 'Onlay - Porcelain/Ceramic - Two Surfaces'],
  ['D2643', 'Restorative', 'Onlay - Porcelain/Ceramic - Three Surfaces', 'Onlay - Porcelain/Ceramic - Three Surfaces'],
  [
    'D2644',
    'Restorative',
    'Onlay - Porcelain/Ceramic - Four Or More Surfaces',
    'Onlay - Porcelain/Ceramic - Four Or More Surfaces'
  ],
  [
    'D2650',
    'Restorative',
    'Inlay - Resin-Based Composite - One Surface',
    'Inlay - Resin-Based Composite - One Surface'
  ],
  [
    'D2651',
    'Restorative',
    'Inlay - Resin-Based Composite - Two Surfaces',
    'Inlay - Resin-Based Composite - Two Surfaces'
  ],
  [
    'D2652',
    'Restorative',
    'Inlay - Resin-Based Composite - Three Or More Surfaces',
    'Inlay - Resin-Based Composite - Three Or More Surfaces'
  ],
  [
    'D2662',
    'Restorative',
    'Onlay - Resin-Based Composite - Two Surfaces',
    'Onlay - Resin-Based Composite - Two Surfaces'
  ],
  [
    'D2663',
    'Restorative',
    'Onlay - Resin-Based Composite - Three Surfaces',
    'Onlay - Resin-Based Composite - Three Surfaces'
  ],
  [
    'D2664',
    'Restorative',
    'Onlay - Resin-Based Composite - Four Or More Surfaces',
    'Onlay - Resin-Based Composite - Four Or More Surfaces'
  ],
  ['D2710', 'Restorative', 'Crown - Resin-Based Composite (Indirect)', 'Crown - Resin-Based Composite (Indirect)'],
  ['D2712', 'Restorative', 'Crown - ¾ Resin-Based Composite (Indirect)', 'Crown - ¾ Resin-Based Composite (Indirect)'],
  ['D2720', 'Restorative', 'Crown - Resin With High Noble Metal', 'Crown - Resin With High Noble Metal'],
  [
    'D2721',
    'Restorative',
    'Crown - Resin With Predominantly Base Metal',
    'Crown - Resin With Predominantly Base Metal'
  ],
  ['D2722', 'Restorative', 'Crown - Resin With Noble Metal', 'Crown - Resin With Noble Metal'],
  ['D2740', 'Restorative', 'Crown - Porcelain/Ceramic', 'Crown - Porcelain/Ceramic'],
  [
    'D2750',
    'Restorative',
    'Crown - Porcelain Fused To High Noble Metal',
    'Crown - Porcelain Fused To High Noble Metal'
  ],
  [
    'D2751',
    'Restorative',
    'Crown - Porcelain Fused To Predominantly Base Metal',
    'Crown - Porcelain Fused To Predominantly Base Metal'
  ],
  ['D2752', 'Restorative', 'Crown - Porcelain Fused To Noble Metal', 'Crown - Porcelain Fused To Noble Metal'],
  [
    'D2753',
    'Restorative',
    'Crown - Porcelain Fused To Titanium And Titanium Alloys',
    'Crown - Porcelain Fused To Titanium And Titanium Alloys'
  ],
  ['D2780', 'Restorative', 'Crown - 3/4 Cast High Noble Metal', 'Crown - 3/4 Cast High Noble Metal'],
  ['D2781', 'Restorative', 'Crown - 3/4 Cast Predominantly Base Metal', 'Crown - 3/4 Cast Predominantly Base Metal'],
  ['D2782', 'Restorative', 'Crown - 3/4 Cast Noble Metal', 'Crown - 3/4 Cast Noble Metal'],
  ['D2783', 'Restorative', 'Crown - 3/4 Porcelain/Ceramic', 'Crown - 3/4 Porcelain/Ceramic'],
  ['D2790', 'Restorative', 'Crown - Full Cast High Noble Metal', 'Crown - Full Cast High Noble Metal'],
  ['D2791', 'Restorative', 'Crown - Full Cast Predominantly Base Metal', 'Crown - Full Cast Predominantly Base Metal'],
  ['D2792', 'Restorative', 'Crown - Full Cast Noble Metal', 'Crown - Full Cast Noble Metal'],
  ['D2794', 'Restorative', 'Crown - Titanium And Titanium Alloys', 'Crown - Titanium And Titanium Alloys'],
  [
    'D2799',
    'Restorative',
    'Provisional Crown– Further Treatment Or Completion Of Diagnosis Necessary Prior To Final Impression',
    'Provisional Crown'
  ],
  [
    'D2910',
    'Restorative',
    'Re-Cement Or Re-Bond Inlay, Onlay, Veneer Or Partial Coverage Restoration',
    'Re-Cement Or Re-Bond Inlay, Onlay, Veneer Or Partial Coverage Restoration'
  ],
  [
    'D2915',
    'Restorative',
    'Re-Cement Or Re-Bond Indirectly Fabricated Or Prefabricated Post And Core',
    'Re-Cement Or Re-Bond Indirectly Fabricated Or Prefabricated Post And Core'
  ],
  ['D2920', 'Restorative', 'Re-Cement Or Re-Bond Crown', 'Re-Cement Or Re-Bond Crown'],
  [
    'D2921',
    'Restorative',
    'Reattachment Of Tooth Fragment, Incisal Edge Or Cusp',
    'Reattachment Of Tooth Fragment, Incisal Edge Or Cusp'
  ],
  [
    'D2929',
    'Restorative',
    'Prefabricated Porcelain/Ceramic Crown – Primary Tooth',
    'Prefabricated Porcelain/Ceramic Crown – Primary Tooth'
  ],
  [
    'D2930',
    'Restorative',
    'Prefabricated Stainless Steel Crown - Primary Tooth',
    'Prefabricated Stainless Steel Crown - Primary Tooth'
  ],
  [
    'D2931',
    'Restorative',
    'Prefabricated Stainless Steel Crown - Permanent Tooth',
    'Prefabricated Stainless Steel Crown - Permanent Tooth'
  ],
  ['D2932', 'Restorative', 'Prefabricated Resin Crown', 'Prefabricated Resin Crown'],
  [
    'D2933',
    'Restorative',
    'Prefabricated Stainless Steel Crown With Resin Window',
    'Prefabricated Stainless Steel Crown With Resin Window'
  ],
  [
    'D2934',
    'Restorative',
    'Prefabricated Esthetic Coated Stainless Steel Crown - Primary Tooth',
    'Prefabricated Esthetic Coated Stainless Steel Crown - Primary Tooth'
  ],
  ['D2940', 'Restorative', 'Protective Restoration', 'Protective Restoration'],
  [
    'D2941',
    'Restorative',
    'Interim Therapeutic Restoration – Primary Dentition',
    'Interim Therapeutic Restoration – Primary Dentition'
  ],
  [
    'D2949',
    'Restorative',
    'Restorative Foundation For An Indirect Restoration',
    'Restorative Foundation For An Indirect Restoration'
  ],
  [
    'D2950',
    'Restorative',
    'Core Buildup, Including Any Pins When Required',
    'Core Buildup, Including Any Pins When Required'
  ],
  [
    'D2951',
    'Restorative',
    'Pin Retention - Per Tooth, In Addition To Restoration',
    'Pin Retention - Per Tooth, In Addition To Restoration'
  ],
  [
    'D2952',
    'Restorative',
    'Post And Core In Addition To Crown, Indirectly Fabricated',
    'Post And Core In Addition To Crown, Indirectly Fabricated'
  ],
  [
    'D2953',
    'Restorative',
    'Each Additional Indirectly Fabricated Post - Same Tooth',
    'Each Additional Indirectly Fabricated Post - Same Tooth'
  ],
  [
    'D2954',
    'Restorative',
    'Prefabricated Post And Core In Addition To Crown',
    'Prefabricated Post And Core In Addition To Crown'
  ],
  ['D2955', 'Restorative', 'Post Removal', 'Post Removal'],
  [
    'D2957',
    'Restorative',
    'Each Additional Prefabricated Post - Same Tooth',
    'Each Additional Prefabricated Post - Same Tooth'
  ],
  ['D2960', 'Restorative', 'Labial Veneer (Resin Laminate) - Chairside', 'Labial Veneer (Resin Laminate) - Chairside'],
  [
    'D2961',
    'Restorative',
    'Labial Veneer (Resin Laminate) - Laboratory',
    'Labial Veneer (Resin Laminate) - Laboratory'
  ],
  [
    'D2962',
    'Restorative',
    'Labial Veneer (Porcelain Laminate) - Laboratory',
    'Labial Veneer (Porcelain Laminate) - Laboratory'
  ],
  [
    'D2971',
    'Restorative',
    'Additional Procedures To Construct New Crown Under Existing Partial Denture Framework',
    'Additional Procedures To Construct New Crown'
  ],
  ['D2975', 'Restorative', 'Coping', 'Coping'],
  ['D2980', 'Restorative', 'Crown Repair Necessitated By Restorative Material Failure', 'Crown Repair'],
  ['D2981', 'Restorative', 'Inlay Repair Necessitated By Restorative Material Failure', 'Inlay Repair'],
  ['D2982', 'Restorative', 'Onlay Repair Necessitated By Restorative Material Failure', 'Onlay Repair'],
  ['D2983', 'Restorative', 'Veneer Repair Necessitated By Restorative Material Failure', 'Veneer Repair'],
  ['D2990', 'Restorative', 'Resin Infiltration Of Incipient Smooth Surface Lesions', 'Resin Infiltration'],
  ['D2999', 'Restorative', 'Unspecified Restorative Procedure, By Report', 'Unspecified Restorative Procedure'],
  [
    'D3110',
    'Endodontics',
    'Pulp Cap - Direct (Excluding Final Restoration)',
    'Pulp Cap - Direct (Excluding Final Restoration)'
  ],
  [
    'D3120',
    'Endodontics',
    'Pulp Cap - Indirect (Excluding Final Restoration)',
    'Pulp Cap - Indirect (Excluding Final Restoration)'
  ],
  [
    'D3220',
    'Endodontics',
    'Therapeutic Pulpotomy (Excluding Final Restoration) - Removal Of Pulp Coronal To The Dentinocemental Junction And Application Of Medicament',
    'Therapeutic Pulpotomy (Excluding Final Restoration)'
  ],
  [
    'D3221',
    'Endodontics',
    'Pulpal Debridement, Primary And Permanent Teeth',
    'Pulpal Debridement, Primary And Permanent Teeth'
  ],
  [
    'D3222',
    'Endodontics',
    'Partial Pulpotomy For Apexogenesis - Permanent Tooth With Incomplete Root Development',
    'Partial Pulpotomy For Apexogenesis'
  ],
  [
    'D3230',
    'Endodontics',
    'Pulpal Therapy (Resorbable Filling) - Anterior, Primary Tooth (Excluding Final Restoration)',
    'Pulpal Therapy (Resorbable Filling) - Anterior, Primary Tooth (Excluding Final Restoration)'
  ],
  [
    'D3240',
    'Endodontics',
    'Pulpal Therapy (Resorbable Filling) - Posterior, Primary Tooth (Excluding Final Restoration)',
    'Pulpal Therapy (Resorbable Filling) - Posterior, Primary Tooth (Excluding Final Restoration)'
  ],
  [
    'D3310',
    'Endodontics',
    'Endodontic Therapy, Anterior Tooth (Excluding Final Restoration)',
    'Endodontic Therapy, Anterior Tooth (Excluding Final Restoration)'
  ],
  [
    'D3320',
    'Endodontics',
    'Endodontic Therapy, Premolar Tooth (Excluding Final Restoration)',
    'Endodontic Therapy, Premolar Tooth (Excluding Final Restoration)'
  ],
  [
    'D3330',
    'Endodontics',
    'Endodontic Therapy, Molar Tooth (Excluding Final Restoration)',
    'Endodontic Therapy, Molar Tooth (Excluding Final Restoration)'
  ],
  [
    'D3331',
    'Endodontics',
    'Treatment Of Root Canal Obstruction; Non-Surgical Access',
    'Treatment Of Root Canal Obstruction; Non-Surgical Access'
  ],
  [
    'D3332',
    'Endodontics',
    'Incomplete Endodontic Therapy; Inoperable, Unrestorable Or Fractured Tooth',
    'Incomplete Endodontic Therapy; Inoperable, Unrestorable Or Fractured Tooth'
  ],
  [
    'D3333',
    'Endodontics',
    'Internal Root Repair Of Perforation Defects',
    'Internal Root Repair Of Perforation Defects'
  ],
  [
    'D3346',
    'Endodontics',
    'Retreatment Of Previous Root Canal Therapy - Anterior',
    'Retreatment Of Previous Root Canal Therapy - Anterior'
  ],
  [
    'D3347',
    'Endodontics',
    'Retreatment Of Previous Root Canal Therapy - Premolar',
    'Retreatment Of Previous Root Canal Therapy - Premolar'
  ],
  [
    'D3348',
    'Endodontics',
    'Retreatment Of Previous Root Canal Therapy - Molar',
    'Retreatment Of Previous Root Canal Therapy - Molar'
  ],
  [
    'D3351',
    'Endodontics',
    'Apexification/Recalcification – Initial Visit (Apical Closure / Calcific Repair Of Perforations, Root Resorption, Etc.)',
    'Apexification/Recalcification – Initial Visit '
  ],
  [
    'D3352',
    'Endodontics',
    'Apexification/Recalcification – Interim Medication Replacement',
    'Apexification/Recalcification – Interim Medication Replacement'
  ],
  [
    'D3353',
    'Endodontics',
    'Apexification/Recalcification - Final Visit (Includes Completed Root Canal Therapy - Apical Closure/Calcific Repair Of Perforations, Root Resorption, Etc.)',
    'Apexification/Recalcification - Final Visit '
  ],
  ['D3355', 'Endodontics', 'Pulpal Regeneration - Initial Visit', 'Pulpal Regeneration - Initial Visit'],
  [
    'D3356',
    'Endodontics',
    'Pulpal Regeneration - Interim Medication Replacement',
    'Pulpal Regeneration - Interim Medication Replacement'
  ],
  [
    'D3357',
    'Endodontics',
    'Pulpal Regeneration - Completion Of Treatment',
    'Pulpal Regeneration - Completion Of Treatment'
  ],
  ['D3410', 'Endodontics', 'Apicoectomy - Anterior', 'Apicoectomy - Anterior'],
  ['D3421', 'Endodontics', 'Apicoectomy - Premolar (First Root)', 'Apicoectomy - Premolar (First Root)'],
  ['D3425', 'Endodontics', 'Apicoectomy - Molar (First Root)', 'Apicoectomy - Molar (First Root)'],
  ['D3426', 'Endodontics', 'Apicoectomy (Each Additional Root)', 'Apicoectomy (Each Additional Root)'],
  ['D3427', 'Endodontics', 'Periradicular Surgery Without Apicoectomy', 'Periradicular Surgery Without Apicoectomy'],
  [
    'D3428',
    'Endodontics',
    'Bone Graft In Conjunction With Periradicular Surgery – Per Tooth, Single Site',
    'Bone Graft In Conjunction With Periradicular Surgery'
  ],
  [
    'D3429',
    'Endodontics',
    'Bone Graft In Conjunction With Periradicular Surgery – Each Additional Contiguous Tooth In The Same Surgical Site',
    'Bone Graft In Conjunction With Periradicular Surgery (Additional)'
  ],
  ['D3430', 'Endodontics', 'Retrograde Filling - Per Root', 'Retrograde Filling - Per Root'],
  [
    'D3431',
    'Endodontics',
    'Biologic Materials To Aid In Soft And Osseous Tissue Regeneration In Conjunction With Periradicular Surgery',
    'Biologic Materials To Aid In Soft And Osseous Tissue Regeneration'
  ],
  [
    'D3432',
    'Endodontics',
    'Guided Tissue Regeneration, Resorbable Barrier, Per Site, In Conjunction With Periradicular Surgery',
    'Guided Tissue Regeneration'
  ],
  ['D3450', 'Endodontics', 'Root Amputation - Per Root', 'Root Amputation - Per Root'],
  ['D3460', 'Endodontics', 'Endodontic Endosseous Implant', 'Endodontic Endosseous Implant'],
  ['D3470', 'Endodontics', 'Intentional Reimplantation (Including Necessary Splinting)', 'Intentional Reimplantation'],
  [
    'D3910',
    'Endodontics',
    'Surgical Procedure For Isolation Of Tooth With Rubber Dam',
    'Surgical Procedure For Isolation Of Tooth With Rubber Dam'
  ],
  [
    'D3920',
    'Endodontics',
    'Hemisection (Including Any Root Removal), Not Including Root Canal Therapy',
    'Hemisection (Including Any Root Removal)'
  ],
  [
    'D3950',
    'Endodontics',
    'Canal Preparation And Fitting Of Preformed Dowel Or Post',
    'Canal Preparation And Fitting Of Preformed Dowel Or Post'
  ],
  ['D3999', 'Endodontics', 'Unspecified Endodontic Procedure, By Report', 'Unspecified Endodontic Procedure'],
  [
    'D4210',
    'Periodontics',
    'Gingivectomy Or Gingivoplasty - Four Or More Contiguous Teeth Or Tooth Bounded Spaces Per Quadrant',
    'Gingivectomy Or Gingivoplasty'
  ],
  [
    'D4211',
    'Periodontics',
    'Gingivectomy Or Gingivoplasty - One To Three Contiguous Teeth Or Tooth Bounded Spaces Per Quadrant',
    'Gingivectomy Or Gingivoplasty'
  ],
  [
    'D4212',
    'Periodontics',
    'Gingivectomy Or Gingivoplasty To Allow Access For Restorative Procedure, Per Tooth',
    'Gingivectomy Or Gingivoplasty'
  ],
  [
    'D4230',
    'Periodontics',
    'Anatomical Crown Exposure – Four Or More Contiguous Teeth Or Tooth Bounded Spaces Per Quadrant',
    'Anatomical Crown Exposure '
  ],
  [
    'D4231',
    'Periodontics',
    'Anatomical Crown Exposure – One To Three Teeth Or Tooth Bounded Spaces Per Quadrant',
    'Anatomical Crown Exposure'
  ],
  [
    'D4240',
    'Periodontics',
    'Gingival Flap Procedure, Including Root Planing - Four Or More Contiguous Teeth Or Tooth Bounded Spaces Per Quadrant',
    'Gingival Flap Procedure, Including Root Planing '
  ],
  [
    'D4241',
    'Periodontics',
    'Gingival Flap Procedure, Including Root Planing - One To Three Contiguous Teeth Or Tooth Bounded Spaces Per Quadrant',
    'Gingival Flap Procedure, Including Root Planing'
  ],
  ['D4245', 'Periodontics', 'Apically Positioned Flap', 'Apically Positioned Flap'],
  ['D4249', 'Periodontics', 'Clinical Crown Lengthening – Hard Tissue', 'Clinical Crown Lengthening'],
  [
    'D4260',
    'Periodontics',
    'Osseous Surgery (Including Elevation Of A Full Thickness Flap And Closure) – Four Or More Contiguous Teeth Or Tooth Bounded Spaces Per Quadrant',
    'Osseous Surgery'
  ],
  [
    'D4261',
    'Periodontics',
    'Osseous Surgery (Including Elevation Of A Full Thickness Flap And Closure) – One To Three Contiguous Teeth Or Tooth Bounded Spaces Per Quadrant',
    'Osseous Surgery'
  ],
  [
    'D4263',
    'Periodontics',
    'Bone Replacement Graft – Retained Natural Tooth – First Site In Quadrant',
    'Bone Replacement Graft'
  ],
  [
    'D4264',
    'Periodontics',
    'Bone Replacement Graft – Retained Natural Tooth – Each Additional Site In Quadrant',
    'Bone Replacement Graft (Additional)'
  ],
  [
    'D4265',
    'Periodontics',
    'Biologic Materials To Aid In Soft And Osseous Tissue Regeneration',
    'Biologic Materials To Aid In Soft And Osseous Tissue Regeneration'
  ],
  [
    'D4266',
    'Periodontics',
    'Guided Tissue Regeneration - Resorbable Barrier, Per Site',
    'Guided Tissue Regeneration - Resorbable Barrier'
  ],
  [
    'D4267',
    'Periodontics',
    'Guided Tissue Regeneration - Nonresorbable Barrier, Per Site (Includes Membrane Removal)',
    'Guided Tissue Regeneration - Nonresorbable Barrier'
  ],
  ['D4268', 'Periodontics', 'Surgical Revision Procedure, Per Tooth', 'Surgical Revision Procedure'],
  ['D4270', 'Periodontics', 'Pedicle Soft Tissue Graft Procedure', 'Pedicle Soft Tissue Graft Procedure'],
  [
    'D4273',
    'Periodontics',
    'Autogenous Connective Tissue Graft Procedure (Including Donor And Recipient Surgical Sites) First Tooth, Implant, Or Edentulous Tooth Position In Graft',
    'Autogenous Connective Tissue Graft Procedure '
  ],
  [
    'D4274',
    'Periodontics',
    'Mesial/Distal Wedge Procedure, Single Tooth (When Not Performed In Conjunction With Surgical Procedures In The Same Anatomical Area)',
    'Mesial/Distal Wedge Procedure'
  ],
  [
    'D4275',
    'Periodontics',
    'Non-Autogenous Connective Tissue Graft (Including Recipient Site And Donor Material) First Tooth, Implant, Or Edentulous Tooth Position In Graft',
    'Non-Autogenous Connective Tissue Graft '
  ],
  [
    'D4276',
    'Periodontics',
    'Combined Connective Tissue And Double Pedicle Graft, Per Tooth',
    'Combined Connective Tissue And Double Pedicle Graft'
  ],
  [
    'D4277',
    'Periodontics',
    'Free Soft Tissue Graft Procedure (Including Recipient And Donor Surgical Sites) First Tooth, Implant Or Edentulous Tooth Position In Graft',
    'Free Soft Tissue Graft Procedure'
  ],
  [
    'D4278',
    'Periodontics',
    'Free Soft Tissue Graft Procedure (Including Recipient And Donor Surgical Sites) Each Additional Contiguous Tooth, Implant Or Edentulous Tooth Position In Same Graft Site',
    'Free Soft Tissue Graft Procedure (Additional)'
  ],
  [
    'D4283',
    'Periodontics',
    'Autogenous Connective Tissue Graft Procedure (Including Donor And Recipient Surgical Sites) – Each Additional Contiguous Tooth, Implant Or Edentulous Tooth Position In Same Graft Site',
    'Autogenous Connective Tissue Graft Procedure (Additional)'
  ],
  [
    'D4285',
    'Periodontics',
    'Non-Autogenous Connective Tissue Graft Procedure (Including Recipient Surgical Site And Donor Material) – Each Additional Contiguous Tooth, Implant Or Edentulous Tooth Position In Same Graft Site',
    'Non-Autogenous Connective Tissue Graft Procedure (Additional)'
  ],
  ['D4320', 'Periodontics', 'Provisional Splinting - Intracoronal', 'Provisional Splinting - Intracoronal'],
  ['D4321', 'Periodontics', 'Provisional Splinting - Extracoronal', 'Provisional Splinting - Extracoronal'],
  [
    'D4341',
    'Periodontics',
    'Periodontal Scaling And Root Planing - Four Or More Teeth Per Quadrant',
    'Periodontal Scaling And Root Planing'
  ],
  [
    'D4342',
    'Periodontics',
    'Periodontal Scaling And Root Planing - One To Three Teeth Per Quadrant',
    'Periodontal Scaling And Root Planing'
  ],
  [
    'D4346',
    'Periodontics',
    'Scaling In Presence Of Generalized Moderate Or Severe Gingival Inflammation – Full Mouth, After Oral Evaluation',
    'Scaling '
  ],
  [
    'D4355',
    'Periodontics',
    'Full Mouth Debridement To Enable A Comprehensive Oral Evaluation And Diagnosis On A Subsequent Visit',
    'Full Mouth Debridement'
  ],
  [
    'D4381',
    'Periodontics',
    'Localized Delivery Of Antimicrobial Agents Via A Controlled Release Vehicle Into Diseased Crevicular Tissue, Per Tooth',
    'Localized Delivery Of Antimicrobial Agents'
  ],
  ['D4910', 'Periodontics', 'Periodontal Maintenance', 'Periodontal Maintenance'],
  [
    'D4920',
    'Periodontics',
    'Unscheduled Dressing Change (By Someone Other Than Treating Dentist Or Their Staff)',
    'Unscheduled Dressing Change '
  ],
  ['D4921', 'Periodontics', 'Gingival Irrigation – Per Quadrant', 'Gingival Irrigation – Per Quadrant'],
  ['D4999', 'Periodontics', 'Unspecified Periodontal Procedure, By Report', 'Unspecified Periodontal Procedure'],
  ['D5110', 'Prosthodontics (Removable)', 'Complete Denture - Maxillary', 'Complete Denture - Maxillary'],
  ['D5120', 'Prosthodontics (Removable)', 'Complete Denture - Mandibular', 'Complete Denture - Mandibular'],
  ['D5130', 'Prosthodontics (Removable)', 'Immediate Denture - Maxillary', 'Immediate Denture - Maxillary'],
  ['D5140', 'Prosthodontics (Removable)', 'Immediate Denture - Mandibular', 'Immediate Denture - Mandibular'],
  [
    'D5211',
    'Prosthodontics (Removable)',
    'Maxillary Partial Denture – Resin Base (Including, Retentive/Clasping Materials, Rests, And Teeth)',
    'Maxillary Partial Denture'
  ],
  [
    'D5212',
    'Prosthodontics (Removable)',
    'Mandibular Partial Denture – Resin Base (Including, Retentive/Clasping Materials, Rests, And Teeth)',
    'Mandibular Partial Denture'
  ],
  [
    'D5213',
    'Prosthodontics (Removable)',
    'Maxillary Partial Denture - Cast Metal Framework With Resin Denture Bases (Including Retentive/Clasping Materials, Rests And Teeth)',
    'Maxillary Partial Denture '
  ],
  [
    'D5214',
    'Prosthodontics (Removable)',
    'Mandibular Partial Denture - Cast Metal Framework With Resin Denture Bases (Including Retentive/Clasping Materials, Rests And Teeth)',
    'Mandibular Partial Denture'
  ],
  [
    'D5221',
    'Prosthodontics (Removable)',
    'Immediate Maxillary Partial Denture - Resin Base (Including Retentive/Clasping Materials, Rests And Teeth)',
    'Immediate Maxillary Partial Denture'
  ],
  [
    'D5222',
    'Prosthodontics (Removable)',
    'Immediate Mandibular Partial Denture - Resin Base (Including Retentive/Clasping Materials, Rests And Teeth)',
    'Immediate Mandibular Partial Denture'
  ],
  [
    'D5223',
    'Prosthodontics (Removable)',
    'Immediate Maxillary Partial Denture - Cast Metal Framework With Resin Denture Bases (Including Retentive/Clasping Materials, Rests And Teeth)',
    'Immediate Maxillary Partial Denture'
  ],
  [
    'D5224',
    'Prosthodontics (Removable)',
    'Immediate Mandibular Partial Denture - Cast Metal Framework With Resin Denture Bases (Including Retentive/Clasping Materials, Rests And Teeth)',
    'Immediate Mandibular Partial Denture'
  ],
  [
    'D5225',
    'Prosthodontics (Removable)',
    'Maxillary Partial Denture - Flexible Base (Including Any Clasps, Rests And Teeth)',
    'Maxillary Partial Denture'
  ],
  [
    'D5226',
    'Prosthodontics (Removable)',
    'Mandibular Partial Denture - Flexible Base (Including Any Clasps, Rests And Teeth)',
    'Mandibular Partial Denture'
  ],
  [
    'D5282',
    'Prosthodontics (Removable)',
    'Removable Unilateral Partial Denture – One Piece Cast Metal (Including Clasps And Teeth), Maxillary',
    'Removable Unilateral Partial Denture – Maxillary'
  ],
  [
    'D5283',
    'Prosthodontics (Removable)',
    'Removable Unilateral Partial Denture – One Piece Cast Metal (Including Clasps And Teeth), Mandibular',
    'Removable Unilateral Partial Denture – Mandibular'
  ],
  [
    'D5284',
    'Prosthodontics (Removable)',
    'Removable Unilateral Partial Denture – One Piece Flexible Base (Including Clasps And Teeth) – Per Quadrant',
    'Removable Unilateral Partial Denture – One Piece Flexible Base'
  ],
  [
    'D5286',
    'Prosthodontics (Removable)',
    'Removable Unilateral Partial Denture – One Piece Resin (Including Clasps And Teeth) – Per Quadrant',
    'Removable Unilateral Partial Denture – One Piece Resin'
  ],
  ['D5410', 'Prosthodontics (Removable)', 'Adjust Complete Denture - Maxillary', 'Adjust Complete Denture - Maxillary'],
  [
    'D5411',
    'Prosthodontics (Removable)',
    'Adjust Complete Denture - Mandibular',
    'Adjust Complete Denture - Mandibular'
  ],
  ['D5421', 'Prosthodontics (Removable)', 'Adjust Partial Denture - Maxillary', 'Adjust Partial Denture - Maxillary'],
  ['D5422', 'Prosthodontics (Removable)', 'Adjust Partial Denture - Mandibular', 'Adjust Partial Denture - Mandibular'],
  [
    'D5511',
    'Prosthodontics (Removable)',
    'Repair Broken Complete Denture Base, Mandibular',
    'Repair Broken Complete Denture Base, Mandibular'
  ],
  [
    'D5512',
    'Prosthodontics (Removable)',
    'Repair Broken Complete Denture Base, Maxillary',
    'Repair Broken Complete Denture Base, Maxillary'
  ],
  [
    'D5520',
    'Prosthodontics (Removable)',
    'Replace Missing Or Broken Teeth - Complete Denture (Each Tooth)',
    'Replace Missing Or Broken Teeth - Complete Denture'
  ],
  [
    'D5611',
    'Prosthodontics (Removable)',
    'Repair Resin Partial Denture Base, Mandibular',
    'Repair Resin Partial Denture Base, Mandibular'
  ],
  [
    'D5612',
    'Prosthodontics (Removable)',
    'Repair Resin Partial Denture Base, Maxillary',
    'Repair Resin Partial Denture Base, Maxillary'
  ],
  [
    'D5621',
    'Prosthodontics (Removable)',
    'Repair Cast Partial Framework, Mandibular',
    'Repair Cast Partial Framework, Mandibular'
  ],
  [
    'D5622',
    'Prosthodontics (Removable)',
    'Repair Cast Partial Framework, Maxillary',
    'Repair Cast Partial Framework, Maxillary'
  ],
  [
    'D5630',
    'Prosthodontics (Removable)',
    'Repair Or Replace Broken Retentive Clasping Materials – Per Tooth',
    'Repair Or Replace Broken Retentive Clasping Materials'
  ],
  ['D5640', 'Prosthodontics (Removable)', 'Replace Broken Teeth - Per Tooth', 'Replace Broken Teeth - Per Tooth'],
  [
    'D5650',
    'Prosthodontics (Removable)',
    'Add Tooth To Existing Partial Denture',
    'Add Tooth To Existing Partial Denture'
  ],
  [
    'D5660',
    'Prosthodontics (Removable)',
    'Add Clasp To Existing Partial Denture - Per Tooth',
    'Add Clasp To Existing Partial Denture'
  ],
  [
    'D5670',
    'Prosthodontics (Removable)',
    'Replace All Teeth And Acrylic On Cast Metal Framework (Maxillary)',
    'Replace All Teeth And Acrylic On Cast Metal Framework (Maxillary)'
  ],
  [
    'D5671',
    'Prosthodontics (Removable)',
    'Replace All Teeth And Acrylic On Cast Metal Framework (Mandibular)',
    'Replace All Teeth And Acrylic On Cast Metal Framework (Mandibular)'
  ],
  ['D5710', 'Prosthodontics (Removable)', 'Rebase Complete Maxillary Denture', 'Rebase Complete Maxillary Denture'],
  ['D5711', 'Prosthodontics (Removable)', 'Rebase Complete Mandibular Denture', 'Rebase Complete Mandibular Denture'],
  ['D5720', 'Prosthodontics (Removable)', 'Rebase Maxillary Partial Denture', 'Rebase Maxillary Partial Denture'],
  ['D5721', 'Prosthodontics (Removable)', 'Rebase Mandibular Partial Denture', 'Rebase Mandibular Partial Denture'],
  [
    'D5730',
    'Prosthodontics (Removable)',
    'Reline Complete Maxillary Denture (Chairside)',
    'Reline Complete Maxillary Denture (Chairside)'
  ],
  [
    'D5731',
    'Prosthodontics (Removable)',
    'Reline Complete Mandibular Denture (Chairside)',
    'Reline Complete Mandibular Denture (Chairside)'
  ],
  [
    'D5740',
    'Prosthodontics (Removable)',
    'Reline Maxillary Partial Denture (Chairside)',
    'Reline Maxillary Partial Denture (Chairside)'
  ],
  [
    'D5741',
    'Prosthodontics (Removable)',
    'Reline Mandibular Partial Denture (Chairside)',
    'Reline Mandibular Partial Denture (Chairside)'
  ],
  [
    'D5750',
    'Prosthodontics (Removable)',
    'Reline Complete Maxillary Denture (Laboratory)',
    'Reline Complete Maxillary Denture (Laboratory)'
  ],
  [
    'D5751',
    'Prosthodontics (Removable)',
    'Reline Complete Mandibular Denture (Laboratory)',
    'Reline Complete Mandibular Denture (Laboratory)'
  ],
  [
    'D5760',
    'Prosthodontics (Removable)',
    'Reline Maxillary Partial Denture (Laboratory)',
    'Reline Maxillary Partial Denture (Laboratory)'
  ],
  [
    'D5761',
    'Prosthodontics (Removable)',
    'Reline Mandibular Partial Denture (Laboratory)',
    'Reline Mandibular Partial Denture (Laboratory)'
  ],
  [
    'D5810',
    'Prosthodontics (Removable)',
    'Interim Complete Denture (Maxillary)',
    'Interim Complete Denture (Maxillary)'
  ],
  [
    'D5811',
    'Prosthodontics (Removable)',
    'Interim Complete Denture (Mandibular)',
    'Interim Complete Denture (Mandibular)'
  ],
  ['D5820', 'Prosthodontics (Removable)', 'Interim Partial Denture (Maxillary)', 'Interim Partial Denture (Maxillary)'],
  [
    'D5821',
    'Prosthodontics (Removable)',
    'Interim Partial Denture (Mandibular)',
    'Interim Partial Denture (Mandibular)'
  ],
  ['D5850', 'Prosthodontics (Removable)', 'Tissue Conditioning, Maxillary', 'Tissue Conditioning, Maxillary'],
  ['D5851', 'Prosthodontics (Removable)', 'Tissue Conditioning, Mandibular', 'Tissue Conditioning, Mandibular'],
  ['D5862', 'Prosthodontics (Removable)', 'Precision Attachment, By Report', 'Precision Attachment'],
  ['D5863', 'Prosthodontics (Removable)', 'Overdenture – Complete Maxillary', 'Overdenture – Complete Maxillary'],
  ['D5864', 'Prosthodontics (Removable)', 'Overdenture – Partial Maxillary', 'Overdenture – Partial Maxillary'],
  ['D5865', 'Prosthodontics (Removable)', 'Overdenture – Complete Mandibular', 'Overdenture – Complete Mandibular'],
  ['D5866', 'Prosthodontics (Removable)', 'Overdenture – Partial Mandibular', 'Overdenture – Partial Mandibular'],
  [
    'D5867',
    'Prosthodontics (Removable)',
    'Replacement Of Replaceable Part Of Semi-Precision Or Precision Attachment (Male Or Female Component)',
    'Replacement Of Replaceable Part Of Semi-Precision Or Precision Attachment'
  ],
  [
    'D5875',
    'Prosthodontics (Removable)',
    'Modification Of Removable Prosthesis Following Implant Surgery',
    'Modification Of Removable Prosthesis Following Implant Surgery'
  ],
  [
    'D5876',
    'Prosthodontics (Removable)',
    'Add Metal Substructure To Acrylic Full Denture (Per Arch)',
    'Add Metal Substructure To Acrylic Full Denture (Per Arch)'
  ],
  [
    'D5899',
    'Prosthodontics (Removable)',
    'Unspecified Removable Prosthodontic Procedure, By Report',
    'Unspecified Removable Prosthodontic Procedure'
  ],
  ['D5911', 'Maxillofacial Prosthetics', 'Facial Moulage (Sectional)', 'Facial Moulage (Sectional)'],
  ['D5912', 'Maxillofacial Prosthetics', 'Facial Moulage (Complete)', 'Facial Moulage (Complete)'],
  ['D5913', 'Maxillofacial Prosthetics', 'Nasal Prosthesis', 'Nasal Prosthesis'],
  ['D5914', 'Maxillofacial Prosthetics', 'Auricular Prosthesis', 'Auricular Prosthesis'],
  ['D5915', 'Maxillofacial Prosthetics', 'Orbital Prosthesis', 'Orbital Prosthesis'],
  ['D5916', 'Maxillofacial Prosthetics', 'Ocular Prosthesis', 'Ocular Prosthesis'],
  ['D5919', 'Maxillofacial Prosthetics', 'Facial Prosthesis', 'Facial Prosthesis'],
  ['D5922', 'Maxillofacial Prosthetics', 'Nasal Septal Prosthesis', 'Nasal Septal Prosthesis'],
  ['D5923', 'Maxillofacial Prosthetics', 'Ocular Prosthesis, Interim', 'Ocular Prosthesis, Interim'],
  ['D5924', 'Maxillofacial Prosthetics', 'Cranial Prosthesis', 'Cranial Prosthesis'],
  [
    'D5925',
    'Maxillofacial Prosthetics',
    'Facial Augmentation Implant Prosthesis',
    'Facial Augmentation Implant Prosthesis'
  ],
  ['D5926', 'Maxillofacial Prosthetics', 'Nasal Prosthesis, Replacement', 'Nasal Prosthesis, Replacement'],
  ['D5927', 'Maxillofacial Prosthetics', 'Auricular Prosthesis, Replacement', 'Auricular Prosthesis, Replacement'],
  ['D5928', 'Maxillofacial Prosthetics', 'Orbital Prosthesis, Replacement', 'Orbital Prosthesis, Replacement'],
  ['D5929', 'Maxillofacial Prosthetics', 'Facial Prosthesis, Replacement', 'Facial Prosthesis, Replacement'],
  ['D5931', 'Maxillofacial Prosthetics', 'Obturator Prosthesis, Surgical', 'Obturator Prosthesis, Surgical'],
  ['D5932', 'Maxillofacial Prosthetics', 'Obturator Prosthesis, Definitive', 'Obturator Prosthesis, Definitive'],
  ['D5933', 'Maxillofacial Prosthetics', 'Obturator Prosthesis, Modification', 'Obturator Prosthesis, Modification'],
  [
    'D5934',
    'Maxillofacial Prosthetics',
    'Mandibular Resection Prosthesis With Guide Flange',
    'Mandibular Resection Prosthesis With Guide Flange'
  ],
  [
    'D5935',
    'Maxillofacial Prosthetics',
    'Mandibular Resection Prosthesis Without Guide Flange',
    'Mandibular Resection Prosthesis Without Guide Flange'
  ],
  ['D5936', 'Maxillofacial Prosthetics', 'Obturator Prosthesis, Interim', 'Obturator Prosthesis, Interim'],
  [
    'D5937',
    'Maxillofacial Prosthetics',
    'Trismus Appliance (Not For Tmd Treatment)',
    'Trismus Appliance (Not For Tmd Treatment)'
  ],
  ['D5951', 'Maxillofacial Prosthetics', 'Feeding Aid', 'Feeding Aid'],
  ['D5952', 'Maxillofacial Prosthetics', 'Speech Aid Prosthesis, Pediatric', 'Speech Aid Prosthesis, Pediatric'],
  ['D5953', 'Maxillofacial Prosthetics', 'Speech Aid Prosthesis, Adult', 'Speech Aid Prosthesis, Adult'],
  ['D5954', 'Maxillofacial Prosthetics', 'Palatal Augmentation Prosthesis', 'Palatal Augmentation Prosthesis'],
  ['D5955', 'Maxillofacial Prosthetics', 'Palatal Lift Prosthesis, Definitive', 'Palatal Lift Prosthesis, Definitive'],
  ['D5958', 'Maxillofacial Prosthetics', 'Palatal Lift Prosthesis, Interim', 'Palatal Lift Prosthesis, Interim'],
  [
    'D5959',
    'Maxillofacial Prosthetics',
    'Palatal Lift Prosthesis, Modification',
    'Palatal Lift Prosthesis, Modification'
  ],
  ['D5960', 'Maxillofacial Prosthetics', 'Speech Aid Prosthesis, Modification', 'Speech Aid Prosthesis, Modification'],
  ['D5982', 'Maxillofacial Prosthetics', 'Surgical Stent', 'Surgical Stent'],
  ['D5983', 'Maxillofacial Prosthetics', 'Radiation Carrier', 'Radiation Carrier'],
  ['D5984', 'Maxillofacial Prosthetics', 'Radiation Shield', 'Radiation Shield'],
  ['D5985', 'Maxillofacial Prosthetics', 'Radiation Cone Locator', 'Radiation Cone Locator'],
  ['D5986', 'Maxillofacial Prosthetics', 'Fluoride Gel Carrier', 'Fluoride Gel Carrier'],
  ['D5987', 'Maxillofacial Prosthetics', 'Commissure Splint', 'Commissure Splint'],
  ['D5988', 'Maxillofacial Prosthetics', 'Surgical Splint', 'Surgical Splint'],
  [
    'D5991',
    'Maxillofacial Prosthetics',
    'Vesiculobullous Disease Medicament Carrier',
    'Vesiculobullous Disease Medicament Carrier'
  ],
  [
    'D5992',
    'Maxillofacial Prosthetics',
    'Adjust Maxillofacial Prosthetic Appliance, By Report',
    'Adjust Maxillofacial Prosthetic Appliance'
  ],
  [
    'D5993',
    'Maxillofacial Prosthetics',
    'Maintenance And Cleaning Of A Maxillofacial Prosthesis (Extra- Or Intra-Oral) Other Than Required Adjustments, By Report',
    'Maintenance And Cleaning Of A Maxillofacial Prosthesis'
  ],
  [
    'D5994',
    'Maxillofacial Prosthetics',
    'Periodontal Medicament Carrier With Peripheral Seal – Laboratory Processed',
    'Periodontal Medicament Carrier With Peripheral Seal'
  ],
  [
    'D5999',
    'Maxillofacial Prosthetics',
    'Unspecified Maxillofacial Prosthesis, By Report',
    'Unspecified Maxillofacial Prosthesis'
  ],
  [
    'D6010',
    'Implant Services',
    'Surgical Placement Of Implant Body: Endosteal Implant',
    'Surgical Placement Of Implant Body'
  ],
  ['D6011', 'Implant Services', 'Second Stage Implant Surgery', 'Second Stage Implant Surgery'],
  [
    'D6012',
    'Implant Services',
    'Surgical Placement Of Interim Implant Body For Transitional Prosthesis: Endosteal Implant',
    'Surgical Placement Of Interim Implant Body'
  ],
  ['D6013', 'Implant Services', 'Surgical Placement Of Mini Implant', 'Surgical Placement Of Mini Implant'],
  ['D6040', 'Implant Services', 'Surgical Placement: Eposteal Implant', 'Surgical Placement: Eposteal Implant'],
  ['D6050', 'Implant Services', 'Surgical Placement: Transosteal Implant', 'Surgical Placement: Transosteal Implant'],
  ['D6051', 'Implant Services', 'Interim Abutment', 'Interim Abutment'],
  ['D6052', 'Implant Services', 'Semi-Precision Attachment Abutment', 'Semi-Precision Attachment Abutment'],
  ['D6055', 'Implant Services', 'Connecting Bar – Implant Supported Or Abutment Supported', 'Connecting Bar'],
  [
    'D6056',
    'Implant Services',
    'Prefabricated Abutment – Includes Modification And Placement',
    'Prefabricated Abutment'
  ],
  ['D6057', 'Implant Services', 'Custom Fabricated Abutment – Includes Placement', 'Custom Fabricated Abutment'],
  [
    'D6058',
    'Implant Services',
    'Abutment Supported Porcelain/Ceramic Crown',
    'Abutment Supported Porcelain/Ceramic Crown'
  ],
  [
    'D6059',
    'Implant Services',
    'Abutment Supported Porcelain Fused To Metal Crown (High Noble Metal)',
    'Abutment Supported Porcelain Fused To Metal Crown (High Noble Metal)'
  ],
  [
    'D6060',
    'Implant Services',
    'Abutment Supported Porcelain Fused To Metal Crown (Predominantly Base Metal)',
    'Abutment Supported Porcelain Fused To Metal Crown (Predominantly Base Metal)'
  ],
  [
    'D6061',
    'Implant Services',
    'Abutment Supported Porcelain Fused To Metal Crown (Noble Metal)',
    'Abutment Supported Porcelain Fused To Metal Crown (Noble Metal)'
  ],
  [
    'D6062',
    'Implant Services',
    'Abutment Supported Cast Metal Crown (High Noble Metal)',
    'Abutment Supported Cast Metal Crown (High Noble Metal)'
  ],
  [
    'D6063',
    'Implant Services',
    'Abutment Supported Cast Metal Crown (Predominantly Base Metal)',
    'Abutment Supported Cast Metal Crown (Predominantly Base Metal)'
  ],
  [
    'D6064',
    'Implant Services',
    'Abutment Supported Cast Metal Crown (Noble Metal)',
    'Abutment Supported Cast Metal Crown (Noble Metal)'
  ],
  [
    'D6065',
    'Implant Services',
    'Implant Supported Porcelain/Ceramic Crown',
    'Implant Supported Porcelain/Ceramic Crown'
  ],
  [
    'D6066',
    'Implant Services',
    'Implant Supported Crown - Porcelain Fused To High Noble Alloys',
    'Implant Supported Crown'
  ],
  [
    'D6067',
    'Implant Services',
    'Implant Supported Crown - High Noble Alloys',
    'Implant Supported Crown - High Noble Alloys'
  ],
  [
    'D6068',
    'Implant Services',
    'Abutment Supported Retainer For Porcelain/Ceramic Fpd',
    'Abutment Supported Retainer For Porcelain/Ceramic Fpd'
  ],
  [
    'D6069',
    'Implant Services',
    'Abutment Supported Retainer For Porcelain Fused To Metal Fpd (High Noble Metal)',
    'Abutment Supported Retainer For Porcelain Fused To Metal Fpd (High Noble Metal)'
  ],
  [
    'D6070',
    'Implant Services',
    'Abutment Supported Retainer For Porcelain Fused To Metal Fpd (Predominantly Base Metal)',
    'Abutment Supported Retainer For Porcelain Fused To Metal Fpd (Predominantly Base Metal)'
  ],
  [
    'D6071',
    'Implant Services',
    'Abutment Supported Retainer For Porcelain Fused To Metal Fpd (Noble Metal)',
    'Abutment Supported Retainer For Porcelain Fused To Metal Fpd (Noble Metal)'
  ],
  [
    'D6072',
    'Implant Services',
    'Abutment Supported Retainer For Cast Metal Fpd (High Noble Metal)',
    'Abutment Supported Retainer (High Noble Metal)'
  ],
  [
    'D6073',
    'Implant Services',
    'Abutment Supported Retainer For Cast Metal Fpd (Predominantly Base Metal)',
    'Abutment Supported Retainer (Predominantly Base Metal)'
  ],
  [
    'D6074',
    'Implant Services',
    'Abutment Supported Retainer For Cast Metal Fpd (Noble Metal)',
    'Abutment Supported Retainer (Noble Metal)'
  ],
  ['D6075', 'Implant Services', 'Implant Supported Retainer For Ceramic Fpd', 'Implant Supported Retainer For Ceramic'],
  [
    'D6076',
    'Implant Services',
    'Implant Supported Retainer For Fpd - Porcelain Fused To High Noble Alloys',
    'Implant Supported Retainer - Porcelain Fused To High Noble Alloys'
  ],
  [
    'D6077',
    'Implant Services',
    'Implant Supported Retainer For Metal Fpd - High Noble Alloys',
    'Implant Supported Retainer For Metal Fpd - High Noble Alloys'
  ],
  [
    'D6080',
    'Implant Services',
    'Implant Maintenance Procedures When Prostheses Are Removed And Reinserted, Including Cleansing Of Prostheses And Abutments',
    'Implant Maintenance Procedures'
  ],
  [
    'D6081',
    'Implant Services',
    'Scaling And Debridement In The Presence Of Inflammation Or Mucositis Of A Single Implant, Including Cleaning Of The Implant Surfaces, Without Flap Entry And Closure',
    'Scaling And Debridement'
  ],
  [
    'D6082',
    'Implant Services',
    'Implant Supported Crown - Porcelain Fused To Predominantly Base Alloys',
    'Implant Supported Crown - Porcelain Fused To Predominantly Base Alloys'
  ],
  [
    'D6083',
    'Implant Services',
    'Implant Supported Crown - Porcelain Fused To Noble Alloys',
    'Implant Supported Crown - Porcelain Fused To Noble Alloys'
  ],
  [
    'D6084',
    'Implant Services',
    'Implant Supported Crown - Porcelain Fused To Titanium And Titanium Alloys',
    'Implant Supported Crown - Porcelain Fused To Titanium And Titanium Alloys'
  ],
  ['D6085', 'Implant Services', 'Provisional Implant Crown', 'Provisional Implant Crown'],
  [
    'D6086',
    'Implant Services',
    'Implant Supported Crown - Predominantly Base Alloys',
    'Implant Supported Crown - Predominantly Base Alloys'
  ],
  ['D6087', 'Implant Services', 'Implant Supported Crown - Noble Alloys', 'Implant Supported Crown - Noble Alloys'],
  [
    'D6088',
    'Implant Services',
    'Implant Supported Crown - Titanium And Titanium Alloys',
    'Implant Supported Crown - Titanium And Titanium Alloys'
  ],
  [
    'D6090',
    'Implant Services',
    'Repair Implant Supported Prosthesis, By Report',
    'Repair Implant Supported Prosthesis'
  ],
  [
    'D6091',
    'Implant Services',
    'Replacement Of Semi-Precision Or Precision Attachment (Male Or Female Component) Of Implant/Abutment Supported Prosthesis, Per Attachment',
    'Replacement Of Semi-Precision Or Precision Attachment'
  ],
  [
    'D6092',
    'Implant Services',
    'Re-Cement Or Re-Bond Implant/Abutment Supported Crown',
    'Re-Cement Or Re-Bond Implant/Abutment Supported Crown'
  ],
  [
    'D6093',
    'Implant Services',
    'Re-Cement Or Re-Bond Implant/Abutment Supported Fixed Partial Denture',
    'Re-Cement Or Re-Bond Implant/Abutment Supported Fixed Partial Denture'
  ],
  [
    'D6094',
    'Implant Services',
    'Abutment Supported Crown - Titanium And Titanium Alloys',
    'Abutment Supported Crown - Titanium And Titanium Alloys'
  ],
  ['D6095', 'Implant Services', 'Repair Implant Abutment, By Report', 'Repair Implant Abutment'],
  ['D6096', 'Implant Services', 'Remove Broken Implant Retaining Screw', 'Remove Broken Implant Retaining Screw'],
  [
    'D6097',
    'Implant Services',
    'Abutment Supported Crown - Porcelain Fused To Titanium And Titanium Alloys',
    'Abutment Supported Crown - Porcelain Fused To Titanium And Titanium Alloys'
  ],
  [
    'D6098',
    'Implant Services',
    'Implant Supported Retainer - Porcelain Fused To Predominantly Base Alloys',
    'Implant Supported Retainer - Porcelain Fused To Predominantly Base Alloys'
  ],
  [
    'D6099',
    'Implant Services',
    'Implant Supported Retainer For Fpd - Porcelain Fused To Noble Alloys',
    'Implant Supported Retainer For Fpd - Porcelain Fused To Noble Alloys'
  ],
  ['D6100', 'Implant Services', 'Implant Removal, By Report', 'Implant Removal'],
  [
    'D6101',
    'Implant Services',
    'Debridement Of A Peri-Implant Defect Or Defects Surrounding A Single Implant, And Surface Cleaning Of The Exposed Implant Surfaces, Including Flap Entry And Closure',
    'Debridement Of A Peri-Implant Defect Or Defects Surrounding A Single Implant'
  ],
  [
    'D6102',
    'Implant Services',
    'Debridement And Osseous Contouring Of A Peri-Implant Defect Or Defects Surrounding A Single Implant And Includes Surface Cleaning Of The Exposed Implant Surfaces, Including Flap Entry And Closure',
    'Debridement And Osseous Contouring'
  ],
  [
    'D6103',
    'Implant Services',
    'Bone Graft For Repair Of Peri-Implant Defect – Does Not Include Flap Entry And Closure',
    'Bone Graft For Repair Of Peri-Implant Defect'
  ],
  ['D6104', 'Implant Services', 'Bone Graft At Time Of Implant Placement', 'Bone Graft At Time Of Implant Placement'],
  [
    'D6110',
    'Implant Services',
    'Implant /Abutment Supported Removable Denture For Edentulous Arch – Maxillary',
    'Implant /Abutment Supported Removable Denture For Edentulous Arch – Maxillary'
  ],
  [
    'D6111',
    'Implant Services',
    'Implant /Abutment Supported Removable Denture For Edentulous Arch – Mandibular',
    'Implant /Abutment Supported Removable Denture For Edentulous Arch – Mandibular'
  ],
  [
    'D6112',
    'Implant Services',
    'Implant /Abutment Supported Removable Denture For Partially Edentulous Arch – Maxillary',
    'Implant /Abutment Supported Removable Denture For Partially Edentulous Arch – Maxillary'
  ],
  [
    'D6113',
    'Implant Services',
    'Implant /Abutment Supported Removable Denture For Partially Edentulous Arch – Mandibular',
    'Implant /Abutment Supported Removable Denture For Partially Edentulous Arch – Mandibular'
  ],
  [
    'D6114',
    'Implant Services',
    'Implant /Abutment Supported Fixed Denture For Edentulous Arch – Maxillary',
    'Implant /Abutment Supported Fixed Denture For Edentulous Arch – Maxillary'
  ],
  [
    'D6115',
    'Implant Services',
    'Implant /Abutment Supported Fixed Denture For Edentulous Arch – Mandibular',
    'Implant /Abutment Supported Fixed Denture For Edentulous Arch – Mandibular'
  ],
  [
    'D6116',
    'Implant Services',
    'Implant /Abutment Supported Fixed Denture For Partially Edentulous Arch – Maxillary',
    'Implant /Abutment Supported Fixed Denture For Partially Edentulous Arch – Maxillary'
  ],
  [
    'D6117',
    'Implant Services',
    'Implant /Abutment Supported Fixed Denture For Partially Edentulous Arch – Mandibular',
    'Implant /Abutment Supported Fixed Denture For Partially Edentulous Arch – Mandibular'
  ],
  [
    'D6118',
    'Implant Services',
    'Implant/Abutment Supported Interim Fixed Denture For Edentulous Arch – Mandibular',
    'Implant/Abutment Supported Interim Fixed Denture For Edentulous Arch – Mandibular'
  ],
  [
    'D6119',
    'Implant Services',
    'Implant/Abutment Supported Interim Fixed Denture For Edentulous Arch – Maxillary',
    'Implant/Abutment Supported Interim Fixed Denture For Edentulous Arch – Maxillary'
  ],
  [
    'D6120',
    'Implant Services',
    'Implant Supported Retainer – Porcelain Fused To Titanium And Titanium Alloys',
    'Implant Supported Retainer – Porcelain Fused To Titanium And Titanium Alloys'
  ],
  [
    'D6121',
    'Implant Services',
    'Implant Supported Retainer For Metal Fpd – Predominantly Base Alloys',
    'Implant Supported Retainer For Metal Fpd – Predominantly Base Alloys'
  ],
  [
    'D6122',
    'Implant Services',
    'Implant Supported Retainer For Metal Fpd – Noble Alloys',
    'Implant Supported Retainer For Metal Fpd – Noble Alloys'
  ],
  [
    'D6123',
    'Implant Services',
    'Implant Supported Retainer For Metal Fpd – Titanium And Titanium Alloys',
    'Implant Supported Retainer For Metal Fpd – Titanium And Titanium Alloys'
  ],
  [
    'D6190',
    'Implant Services',
    'Radiographic/Surgical Implant Index, By Report',
    'Radiographic/Surgical Implant Index'
  ],
  [
    'D6194',
    'Implant Services',
    'Abutment Supported Retainer Crown For Fpd – Titanium And Titanium Alloys',
    'Abutment Supported Retainer Crown For Fpd – Titanium And Titanium Alloys'
  ],
  [
    'D6195',
    'Implant Services',
    'Abutment Supported Retainer - Porcelain Fused To Titanium And Titanium Alloys',
    'Abutment Supported Retainer - Porcelain Fused To Titanium And Titanium Alloys'
  ],
  ['D6199', 'Implant Services', 'Unspecified Implant Procedure, By Report', 'Unspecified Implant Procedure'],
  [
    'D6205',
    'Prosthodontics, Fixed',
    'Pontic - Indirect Resin Based Composite',
    'Pontic - Indirect Resin Based Composite'
  ],
  ['D6210', 'Prosthodontics, Fixed', 'Pontic - Cast High Noble Metal', 'Pontic - Cast High Noble Metal'],
  [
    'D6211',
    'Prosthodontics, Fixed',
    'Pontic - Cast Predominantly Base Metal',
    'Pontic - Cast Predominantly Base Metal'
  ],
  ['D6212', 'Prosthodontics, Fixed', 'Pontic - Cast Noble Metal', 'Pontic - Cast Noble Metal'],
  ['D6214', 'Prosthodontics, Fixed', 'Pontic - Titanium And Titanium Alloys', 'Pontic - Titanium And Titanium Alloys'],
  [
    'D6240',
    'Prosthodontics, Fixed',
    'Pontic - Porcelain Fused To High Noble Metal',
    'Pontic - Porcelain Fused To High Noble Metal'
  ],
  [
    'D6241',
    'Prosthodontics, Fixed',
    'Pontic - Porcelain Fused To Predominantly Base Metal',
    'Pontic - Porcelain Fused To Predominantly Base Metal'
  ],
  [
    'D6242',
    'Prosthodontics, Fixed',
    'Pontic - Porcelain Fused To Noble Metal',
    'Pontic - Porcelain Fused To Noble Metal'
  ],
  [
    'D6243',
    'Prosthodontics, Fixed',
    'Pontic - Porcelain Fused To Titanium And Titanium Alloys',
    'Pontic - Porcelain Fused To Titanium And Titanium Alloys'
  ],
  ['D6245', 'Prosthodontics, Fixed', 'Pontic - Porcelain/Ceramic', 'Pontic - Porcelain/Ceramic'],
  ['D6250', 'Prosthodontics, Fixed', 'Pontic - Resin With High Noble Metal', 'Pontic - Resin With High Noble Metal'],
  [
    'D6251',
    'Prosthodontics, Fixed',
    'Pontic - Resin With Predominantly Base Metal',
    'Pontic - Resin With Predominantly Base Metal'
  ],
  ['D6252', 'Prosthodontics, Fixed', 'Pontic - Resin With Noble Metal', 'Pontic - Resin With Noble Metal'],
  [
    'D6253',
    'Prosthodontics, Fixed',
    'Provisional Pontic - Further Treatment Or Completion Of Diagnosis Necessary Prior To Final Impression',
    'Provisional Pontic'
  ],
  [
    'D6545',
    'Prosthodontics, Fixed',
    'Retainer - Cast Metal For Resin Bonded Fixed Prosthesis',
    'Retainer - Cast Metal For Resin Bonded Fixed Prosthesis'
  ],
  [
    'D6548',
    'Prosthodontics, Fixed',
    'Retainer - Porcelain/Ceramic For Resin Bonded Fixed Prosthesis',
    'Retainer - Porcelain/Ceramic For Resin Bonded Fixed Prosthesis'
  ],
  [
    'D6549',
    'Prosthodontics, Fixed',
    'Retainer – For Resin Bonded Fixed Prosthesis',
    'Retainer – For Resin Bonded Fixed Prosthesis'
  ],
  [
    'D6600',
    'Prosthodontics, Fixed',
    'Retainer Inlay - Porcelain/Ceramic, Two Surfaces',
    'Retainer Inlay - Porcelain/Ceramic, Two Surfaces'
  ],
  [
    'D6601',
    'Prosthodontics, Fixed',
    'Retainer Inlay - Porcelain/Ceramic, Three Or More Surfaces',
    'Retainer Inlay - Porcelain/Ceramic, Three Or More Surfaces'
  ],
  [
    'D6602',
    'Prosthodontics, Fixed',
    'Retainer Inlay - Cast High Noble Metal, Two Surfaces',
    'Retainer Inlay - Cast High Noble Metal, Two Surfaces'
  ],
  [
    'D6603',
    'Prosthodontics, Fixed',
    'Retainer Inlay - Cast High Noble Metal, Three Or More Surfaces',
    'Retainer Inlay - Cast High Noble Metal, Three Or More Surfaces'
  ],
  [
    'D6604',
    'Prosthodontics, Fixed',
    'Retainer Inlay - Cast Predominantly Base Metal, Two Surfaces',
    'Retainer Inlay - Cast Predominantly Base Metal, Two Surfaces'
  ],
  [
    'D6605',
    'Prosthodontics, Fixed',
    'Retainer Inlay - Cast Predominantly Base Metal, Three Or More Surfaces',
    'Retainer Inlay - Cast Predominantly Base Metal, Three Or More Surfaces'
  ],
  [
    'D6606',
    'Prosthodontics, Fixed',
    'Retainer Inlay - Cast Noble Metal, Two Surfaces',
    'Retainer Inlay - Cast Noble Metal, Two Surfaces'
  ],
  [
    'D6607',
    'Prosthodontics, Fixed',
    'Retainer Inlay - Cast Noble Metal, Three Or More Surfaces',
    'Retainer Inlay - Cast Noble Metal, Three Or More Surfaces'
  ],
  [
    'D6608',
    'Prosthodontics, Fixed',
    'Retainer Onlay - Porcelain/Ceramic, Two Surfaces',
    'Retainer Onlay - Porcelain/Ceramic, Two Surfaces'
  ],
  [
    'D6609',
    'Prosthodontics, Fixed',
    'Retainer Onlay - Porcelain/Ceramic, Three Or More Surfaces',
    'Retainer Onlay - Porcelain/Ceramic, Three Or More Surfaces'
  ],
  [
    'D6610',
    'Prosthodontics, Fixed',
    'Retainer Onlay - Cast High Noble Metal, Two Surfaces',
    'Retainer Onlay - Cast High Noble Metal, Two Surfaces'
  ],
  [
    'D6611',
    'Prosthodontics, Fixed',
    'Retainer Onlay - Cast High Noble Metal, Three Or More Surfaces',
    'Retainer Onlay - Cast High Noble Metal, Three Or More Surfaces'
  ],
  [
    'D6612',
    'Prosthodontics, Fixed',
    'Retainer Onlay - Cast Predominantly Base Metal, Two Surfaces',
    'Retainer Onlay - Cast Predominantly Base Metal, Two Surfaces'
  ],
  [
    'D6613',
    'Prosthodontics, Fixed',
    'Retainer Onlay - Cast Predominantly Base Metal, Three Or More Surfaces',
    'Retainer Onlay - Cast Predominantly Base Metal, Three Or More Surfaces'
  ],
  [
    'D6614',
    'Prosthodontics, Fixed',
    'Retainer Onlay - Cast Noble Metal, Two Surfaces',
    'Retainer Onlay - Cast Noble Metal, Two Surfaces'
  ],
  [
    'D6615',
    'Prosthodontics, Fixed',
    'Retainer Onlay - Cast Noble Metal, Three Or More Surfaces',
    'Retainer Onlay - Cast Noble Metal, Three Or More Surfaces'
  ],
  ['D6624', 'Prosthodontics, Fixed', 'Retainer Inlay - Titanium', 'Retainer Inlay - Titanium'],
  ['D6634', 'Prosthodontics, Fixed', 'Retainer Onlay - Titanium', 'Retainer Onlay - Titanium'],
  [
    'D6710',
    'Prosthodontics, Fixed',
    'Retainer Crown - Indirect Resin Based Composite',
    'Retainer Crown - Indirect Resin Based Composite'
  ],
  [
    'D6720',
    'Prosthodontics, Fixed',
    'Retainer Crown - Resin With High Noble Metal',
    'Retainer Crown - Resin With High Noble Metal'
  ],
  [
    'D6721',
    'Prosthodontics, Fixed',
    'Retainer Crown - Resin With Predominantly Base Metal',
    'Retainer Crown - Resin With Predominantly Base Metal'
  ],
  [
    'D6722',
    'Prosthodontics, Fixed',
    'Retainer Crown - Resin With Noble Metal',
    'Retainer Crown - Resin With Noble Metal'
  ],
  ['D6740', 'Prosthodontics, Fixed', 'Retainer Crown - Porcelain/Ceramic', 'Retainer Crown - Porcelain/Ceramic'],
  [
    'D6750',
    'Prosthodontics, Fixed',
    'Retainer Crown - Porcelain Fused To High Noble Metal',
    'Retainer Crown - Porcelain Fused To High Noble Metal'
  ],
  [
    'D6751',
    'Prosthodontics, Fixed',
    'Retainer Crown - Porcelain Fused To Predominantly Base Metal',
    'Retainer Crown - Porcelain Fused To Predominantly Base Metal'
  ],
  [
    'D6752',
    'Prosthodontics, Fixed',
    'Retainer Crown - Porcelain Fused To Noble Metal',
    'Retainer Crown - Porcelain Fused To Noble Metal'
  ],
  [
    'D6753',
    'Prosthodontics, Fixed',
    'Retainer Crown - Porcelain Fused To Titanium And Titanium Alloys',
    'Retainer Crown - Porcelain Fused To Titanium And Titanium Alloys'
  ],
  [
    'D6780',
    'Prosthodontics, Fixed',
    'Retainer Crown - 3/4 Cast High Noble Metal',
    'Retainer Crown - 3/4 Cast High Noble Metal'
  ],
  [
    'D6781',
    'Prosthodontics, Fixed',
    'Retainer Crown - 3/4 Cast Predominantly Base Metal',
    'Retainer Crown - 3/4 Cast Predominantly Base Metal'
  ],
  ['D6782', 'Prosthodontics, Fixed', 'Retainer Crown - 3/4 Cast Noble Metal', 'Retainer Crown - 3/4 Cast Noble Metal'],
  [
    'D6783',
    'Prosthodontics, Fixed',
    'Retainer Crown - 3/4 Porcelain/Ceramic',
    'Retainer Crown - 3/4 Porcelain/Ceramic'
  ],
  [
    'D6784',
    'Prosthodontics, Fixed',
    'Retainer Crown ¾ - Titanium And Titanium Alloys',
    'Retainer Crown ¾ - Titanium And Titanium Alloys'
  ],
  [
    'D6790',
    'Prosthodontics, Fixed',
    'Retainer Crown - Full Cast High Noble Metal',
    'Retainer Crown - Full Cast High Noble Metal'
  ],
  [
    'D6791',
    'Prosthodontics, Fixed',
    'Retainer Crown - Full Cast Predominantly Base Metal',
    'Retainer Crown - Full Cast Predominantly Base Metal'
  ],
  [
    'D6792',
    'Prosthodontics, Fixed',
    'Retainer Crown - Full Cast Noble Metal',
    'Retainer Crown - Full Cast Noble Metal'
  ],
  [
    'D6793',
    'Prosthodontics, Fixed',
    'Provisional Retainer Crown - Further Treatment Or Completion Of Diagnosis Necessary Prior To Final Impression',
    'Provisional Retainer Crown'
  ],
  [
    'D6794',
    'Prosthodontics, Fixed',
    'Retainer Crown - Titanium And Titanium Alloys',
    'Retainer Crown - Titanium And Titanium Alloys'
  ],
  ['D6920', 'Prosthodontics, Fixed', 'Connector Bar', 'Connector Bar'],
  [
    'D6930',
    'Prosthodontics, Fixed',
    'Re-Cement Or Re-Bond Fixed Partial Denture',
    'Re-Cement Or Re-Bond Fixed Partial Denture'
  ],
  ['D6940', 'Prosthodontics, Fixed', 'Stress Breaker', 'Stress Breaker'],
  ['D6950', 'Prosthodontics, Fixed', 'Precision Attachment', 'Precision Attachment'],
  [
    'D6980',
    'Prosthodontics, Fixed',
    'Fixed Partial Denture Repair Necessitated By Restorative Material Failure',
    'Fixed Partial Denture Repair Necessitated By Restorative Material Failure'
  ],
  ['D6985', 'Prosthodontics, Fixed', 'Pediatric Partial Denture, Fixed', 'Pediatric Partial Denture, Fixed'],
  [
    'D6999',
    'Prosthodontics, Fixed',
    'Unspecified Fixed Prosthodontic Procedure, By Report',
    'Unspecified Fixed Prosthodontic Procedure'
  ],
  [
    'D7111',
    'Oral & Maxillofacial Surgery',
    'Extraction, Coronal Remnants – Primary Tooth',
    'Extraction, Coronal Remnants – Primary Tooth'
  ],
  [
    'D7140',
    'Oral & Maxillofacial Surgery',
    'Extraction, Erupted Tooth Or Exposed Root (Elevation And/Or Forceps Removal)',
    'Extraction, Erupted Tooth Or Exposed Root'
  ],
  [
    'D7210',
    'Oral & Maxillofacial Surgery',
    'Extraction, Erupted Tooth Requiring Removal Of Bone And/Or Sectioning Of Tooth, And Including Elevation Of Mucoperiosteal Flap If Indicated',
    'Extraction'
  ],
  [
    'D7220',
    'Oral & Maxillofacial Surgery',
    'Removal Of Impacted Tooth - Soft Tissue',
    'Removal Of Impacted Tooth - Soft Tissue'
  ],
  [
    'D7230',
    'Oral & Maxillofacial Surgery',
    'Removal Of Impacted Tooth - Partially Bony',
    'Removal Of Impacted Tooth - Partially Bony'
  ],
  [
    'D7240',
    'Oral & Maxillofacial Surgery',
    'Removal Of Impacted Tooth - Completely Bony',
    'Removal Of Impacted Tooth - Completely Bony'
  ],
  [
    'D7241',
    'Oral & Maxillofacial Surgery',
    'Removal Of Impacted Tooth - Completely Bony, With Unusual Surgical Complications',
    'Removal Of Impacted Tooth - Completely Bony, With Unusual Surgical Complications'
  ],
  [
    'D7250',
    'Oral & Maxillofacial Surgery',
    'Removal Of Residual Tooth Roots (Cutting Procedure)',
    'Removal Of Residual Tooth Roots (Cutting Procedure)'
  ],
  [
    'D7251',
    'Oral & Maxillofacial Surgery',
    'Coronectomy – Intentional Partial Tooth Removal',
    'Coronectomy – Intentional Partial Tooth Removal'
  ],
  ['D7260', 'Oral & Maxillofacial Surgery', 'Oroantral Fistula Closure', 'Oroantral Fistula Closure'],
  [
    'D7261',
    'Oral & Maxillofacial Surgery',
    'Primary Closure Of A Sinus Perforation',
    'Primary Closure Of A Sinus Perforation'
  ],
  [
    'D7270',
    'Oral & Maxillofacial Surgery',
    'Tooth Reimplantation And/Or Stabilization Of Accidentally Evulsed Or Displaced Tooth',
    'Tooth Reimplantation And/Or Stabilization Of Accidentally Evulsed Or Displaced Tooth'
  ],
  [
    'D7272',
    'Oral & Maxillofacial Surgery',
    'Tooth Transplantation (Includes Reimplantation From One Site To Another And Splinting And/Or Stabilization)',
    'Tooth Transplantation'
  ],
  ['D7280', 'Oral & Maxillofacial Surgery', 'Exposure Of An Unerupted Tooth', 'Exposure Of An Unerupted Tooth'],
  [
    'D7282',
    'Oral & Maxillofacial Surgery',
    'Mobilization Of Erupted Or Malpositioned Tooth To Aid Eruption',
    'Mobilization Of Erupted Or Malpositioned Tooth To Aid Eruption'
  ],
  [
    'D7283',
    'Oral & Maxillofacial Surgery',
    'Placement Of Device To Facilitate Eruption Of Impacted Tooth',
    'Placement Of Device To Facilitate Eruption Of Impacted Tooth'
  ],
  [
    'D7285',
    'Oral & Maxillofacial Surgery',
    'Incisional Biopsy Of Oral Tissue-Hard (Bone, Tooth)',
    'Incisional Biopsy Of Oral Tissue-Hard (Bone, Tooth)'
  ],
  [
    'D7286',
    'Oral & Maxillofacial Surgery',
    'Incisional Biopsy Of Oral Tissue-Soft',
    'Incisional Biopsy Of Oral Tissue-Soft'
  ],
  [
    'D7287',
    'Oral & Maxillofacial Surgery',
    'Exfoliative Cytological Sample Collection',
    'Exfoliative Cytological Sample Collection'
  ],
  [
    'D7288',
    'Oral & Maxillofacial Surgery',
    'Brush Biopsy - Transepithelial Sample Collection',
    'Brush Biopsy - Transepithelial Sample Collection'
  ],
  ['D7290', 'Oral & Maxillofacial Surgery', 'Surgical Repositioning Of Teeth', 'Surgical Repositioning Of Teeth'],
  [
    'D7291',
    'Oral & Maxillofacial Surgery',
    'Transseptal Fiberotomy/Supra Crestal Fiberotomy, By Report',
    'Transseptal Fiberotomy/Supra Crestal Fiberotomy'
  ],
  [
    'D7292',
    'Oral & Maxillofacial Surgery',
    'Placement Of Temporary Anchorage Device [Screw Retained Plate] Requiring Flap; Includes Device Removal',
    'Placement Of Temporary Anchorage Device'
  ],
  [
    'D7293',
    'Oral & Maxillofacial Surgery',
    'Placement Of Temporary Anchorage Device Requiring Flap; Includes Device Removal',
    'Placement Of Temporary Anchorage Device Requiring Flap'
  ],
  [
    'D7294',
    'Oral & Maxillofacial Surgery',
    'Placement Of Temporary Anchorage Device Without Flap; Includes Device Removal',
    'Placement Of Temporary Anchorage Device Without Flap'
  ],
  [
    'D7295',
    'Oral & Maxillofacial Surgery',
    'Harvest Of Bone For Use In Autogenous Grafting Procedure',
    'Harvest Of Bone For Use In Autogenous Grafting Procedure'
  ],
  [
    'D7296',
    'Oral & Maxillofacial Surgery',
    'Corticotomy – One To Three Teeth Or Tooth Spaces, Per Quadrant',
    'Corticotomy – One To Three Teeth Or Tooth Spaces'
  ],
  [
    'D7297',
    'Oral & Maxillofacial Surgery',
    'Corticotomy – Four Or More Teeth Or Tooth Spaces, Per Quadrant',
    'Corticotomy – Four Or More Teeth Or Tooth Spaces'
  ],
  [
    'D7310',
    'Oral & Maxillofacial Surgery',
    'Alveoloplasty In Conjunction With Extractions - Four Or More Teeth Or Tooth Spaces, Per Quadrant',
    'Alveoloplasty In Conjunction With Extractions - Four Or More Teeth'
  ],
  [
    'D7311',
    'Oral & Maxillofacial Surgery',
    'Alveoloplasty In Conjunction With Extractions - One To Three Teeth Or Tooth Spaces, Per Quadrant',
    'Alveoloplasty In Conjunction With Extractions - One To Three Teeth'
  ],
  [
    'D7320',
    'Oral & Maxillofacial Surgery',
    'Alveoloplasty Not In Conjunction With Extractions - Four Or More Teeth Or Tooth Spaces, Per Quadrant',
    'Alveoloplasty Not In Conjunction With Extractions - Four Or More Teeth'
  ],
  [
    'D7321',
    'Oral & Maxillofacial Surgery',
    'Alveoloplasty Not In Conjunction With Extractions - One To Three Teeth Or Tooth Spaces, Per Quadrant',
    'Alveoloplasty Not In Conjunction With Extractions - One To Three Teeth'
  ],
  [
    'D7340',
    'Oral & Maxillofacial Surgery',
    'Vestibuloplasty - Ridge Extension (Secondary Epithelialization)',
    'Vestibuloplasty - Ridge Extension (Secondary Epithelialization)'
  ],
  [
    'D7350',
    'Oral & Maxillofacial Surgery',
    'Vestibuloplasty - Ridge Extension (Including Soft Tissue Grafts, Muscle Reattachment, Revision Of Soft Tissue Attachment And Management Of Hypertrophied And Hyperplastic Tissue)',
    'Vestibuloplasty - Ridge Extension (Including Soft Tissue Grafts)'
  ],
  [
    'D7410',
    'Oral & Maxillofacial Surgery',
    'Excision Of Benign Lesion Up To 1.25 Cm',
    'Excision Of Benign Lesion Up To 1.25 Cm'
  ],
  [
    'D7411',
    'Oral & Maxillofacial Surgery',
    'Excision Of Benign Lesion Greater Than 1.25 Cm',
    'Excision Of Benign Lesion Greater Than 1.25 Cm'
  ],
  [
    'D7412',
    'Oral & Maxillofacial Surgery',
    'Excision Of Benign Lesion, Complicated',
    'Excision Of Benign Lesion, Complicated'
  ],
  [
    'D7413',
    'Oral & Maxillofacial Surgery',
    'Excision Of Malignant Lesion Up To 1.25 Cm',
    'Excision Of Malignant Lesion Up To 1.25 Cm'
  ],
  [
    'D7414',
    'Oral & Maxillofacial Surgery',
    'Excision Of Malignant Lesion Greater Than 1.25 Cm',
    'Excision Of Malignant Lesion Greater Than 1.25 Cm'
  ],
  [
    'D7415',
    'Oral & Maxillofacial Surgery',
    'Excision Of Malignant Lesion, Complicated',
    'Excision Of Malignant Lesion, Complicated'
  ],
  [
    'D7440',
    'Oral & Maxillofacial Surgery',
    'Excision Of Malignant Tumor - Lesion Diameter Up To 1.25 Cm',
    'Excision Of Malignant Tumor - Lesion Diameter Up To 1.25 Cm'
  ],
  [
    'D7441',
    'Oral & Maxillofacial Surgery',
    'Excision Of Malignant Tumor - Lesion Diameter Greater Than 1.25 Cm',
    'Excision Of Malignant Tumor - Lesion Diameter Greater Than 1.25 Cm'
  ],
  [
    'D7450',
    'Oral & Maxillofacial Surgery',
    'Removal Of Benign Odontogenic Cyst Or Tumor - Lesion Diameter Up To 1.25 Cm',
    'Removal Of Benign Odontogenic Cyst Or Tumor - Lesion Diameter Up To 1.25 Cm'
  ],
  [
    'D7451',
    'Oral & Maxillofacial Surgery',
    'Removal Of Benign Odontogenic Cyst Or Tumor - Lesion Diameter Greater Than 1.25 Cm',
    'Removal Of Benign Odontogenic Cyst Or Tumor - Lesion Diameter Greater Than 1.25 Cm'
  ],
  [
    'D7460',
    'Oral & Maxillofacial Surgery',
    'Removal Of Benign Nonodontogenic Cyst Or Tumor - Lesion Diameter Up To 1.25 Cm',
    'Removal Of Benign Nonodontogenic Cyst Or Tumor - Lesion Diameter Up To 1.25 Cm'
  ],
  [
    'D7461',
    'Oral & Maxillofacial Surgery',
    'Removal Of Benign Nonodontogenic Cyst Or Tumor - Lesion Diameter Greater Than 1.25 Cm',
    'Removal Of Benign Nonodontogenic Cyst Or Tumor - Lesion Diameter Greater Than 1.25 Cm'
  ],
  [
    'D7465',
    'Oral & Maxillofacial Surgery',
    'Destruction Of Lesion(S) By Physical Or Chemical Method, By Report',
    'Destruction Of Lesion(S) By Physical Or Chemical Method'
  ],
  [
    'D7471',
    'Oral & Maxillofacial Surgery',
    'Removal Of Lateral Exostosis (Maxilla Or Mandible)',
    'Removal Of Lateral Exostosis (Maxilla Or Mandible)'
  ],
  ['D7472', 'Oral & Maxillofacial Surgery', 'Removal Of Torus Palatinus', 'Removal Of Torus Palatinus'],
  ['D7473', 'Oral & Maxillofacial Surgery', 'Removal Of Torus Mandibularis', 'Removal Of Torus Mandibularis'],
  ['D7485', 'Oral & Maxillofacial Surgery', 'Reduction Of Osseous Tuberosity', 'Reduction Of Osseous Tuberosity'],
  [
    'D7490',
    'Oral & Maxillofacial Surgery',
    'Radical Resection Of Maxilla Or Mandible',
    'Radical Resection Of Maxilla Or Mandible'
  ],
  [
    'D7510',
    'Oral & Maxillofacial Surgery',
    'Incision And Drainage Of Abscess - Intraoral Soft Tissue',
    'Incision And Drainage Of Abscess - Intraoral Soft Tissue'
  ],
  [
    'D7511',
    'Oral & Maxillofacial Surgery',
    'Incision And Drainage Of Abscess - Intraoral Soft Tissue - Complicated (Includes Drainage Of Multiple Fascial Spaces)',
    'Incision And Drainage Of Abscess - Intraoral Soft Tissue - Complicated '
  ],
  [
    'D7520',
    'Oral & Maxillofacial Surgery',
    'Incision And Drainage Of Abscess - Extraoral Soft Tissue',
    'Incision And Drainage Of Abscess - Extraoral Soft Tissue'
  ],
  [
    'D7521',
    'Oral & Maxillofacial Surgery',
    'Incision And Drainage Of Abscess - Extraoral Soft Tissue - Complicated (Includes Drainage Of Multiple Fascial Spaces)',
    'Incision And Drainage Of Abscess - Extraoral Soft Tissue - Complicated '
  ],
  [
    'D7530',
    'Oral & Maxillofacial Surgery',
    'Removal Of Foreign Body From Mucosa, Skin, Or Subcutaneous Alveolar Tissue',
    'Removal Of Foreign Body From Mucosa, Skin, Or Subcutaneous Alveolar Tissue'
  ],
  [
    'D7540',
    'Oral & Maxillofacial Surgery',
    'Removal Of Reaction Producing Foreign Bodies, Musculoskeletal System',
    'Removal Of Reaction Producing Foreign Bodies, Musculoskeletal System'
  ],
  [
    'D7550',
    'Oral & Maxillofacial Surgery',
    'Partial Ostectomy/Sequestrectomy For Removal Of Non-Vital Bone',
    'Partial Ostectomy/Sequestrectomy For Removal Of Non-Vital Bone'
  ],
  [
    'D7560',
    'Oral & Maxillofacial Surgery',
    'Maxillary Sinusotomy For Removal Of Tooth Fragment Or Foreign Body',
    'Maxillary Sinusotomy For Removal Of Tooth Fragment Or Foreign Body'
  ],
  [
    'D7610',
    'Oral & Maxillofacial Surgery',
    'Maxilla - Open Reduction (Teeth Immobilized, If Present)',
    'Maxilla - Open Reduction (Teeth Immobilized, If Present)'
  ],
  [
    'D7620',
    'Oral & Maxillofacial Surgery',
    'Maxilla - Closed Reduction (Teeth Immobilized, If Present)',
    'Maxilla - Closed Reduction (Teeth Immobilized, If Present)'
  ],
  [
    'D7630',
    'Oral & Maxillofacial Surgery',
    'Mandible - Open Reduction (Teeth Immobilized, If Present)',
    'Mandible - Open Reduction (Teeth Immobilized, If Present)'
  ],
  [
    'D7640',
    'Oral & Maxillofacial Surgery',
    'Mandible - Closed Reduction (Teeth Immobilized, If Present)',
    'Mandible - Closed Reduction (Teeth Immobilized, If Present)'
  ],
  [
    'D7650',
    'Oral & Maxillofacial Surgery',
    'Malar And/Or Zygomatic Arch - Open Reduction',
    'Malar And/Or Zygomatic Arch - Open Reduction'
  ],
  [
    'D7660',
    'Oral & Maxillofacial Surgery',
    'Malar And/Or Zygomatic Arch - Closed Reduction',
    'Malar And/Or Zygomatic Arch - Closed Reduction'
  ],
  [
    'D7670',
    'Oral & Maxillofacial Surgery',
    'Alveolus - Closed Reduction, May Include Stabilization Of Teeth',
    'Alveolus - Closed Reduction, May Include Stabilization Of Teeth'
  ],
  [
    'D7671',
    'Oral & Maxillofacial Surgery',
    'Alveolus - Open Reduction, May Include Stabilization Of Teeth',
    'Alveolus - Open Reduction, May Include Stabilization Of Teeth'
  ],
  [
    'D7680',
    'Oral & Maxillofacial Surgery',
    'Facial Bones - Complicated Reduction With Fixation And Multiple Surgical Approaches',
    'Facial Bones - Complicated Reduction With Fixation And Multiple Surgical Approaches'
  ],
  ['D7710', 'Oral & Maxillofacial Surgery', 'Maxilla - Open Reduction', 'Maxilla - Open Reduction'],
  ['D7720', 'Oral & Maxillofacial Surgery', 'Maxilla - Closed Reduction', 'Maxilla - Closed Reduction'],
  ['D7730', 'Oral & Maxillofacial Surgery', 'Mandible - Open Reduction', 'Mandible - Open Reduction'],
  ['D7740', 'Oral & Maxillofacial Surgery', 'Mandible - Closed Reduction', 'Mandible - Closed Reduction'],
  [
    'D7750',
    'Oral & Maxillofacial Surgery',
    'Malar And/Or Zygomatic Arch - Open Reduction',
    'Malar And/Or Zygomatic Arch - Open Reduction'
  ],
  [
    'D7760',
    'Oral & Maxillofacial Surgery',
    'Malar And/Or Zygomatic Arch - Closed Reduction',
    'Malar And/Or Zygomatic Arch - Closed Reduction'
  ],
  [
    'D7770',
    'Oral & Maxillofacial Surgery',
    'Alveolus - Open Reduction Stabilization Of Teeth',
    'Alveolus - Open Reduction Stabilization Of Teeth'
  ],
  [
    'D7771',
    'Oral & Maxillofacial Surgery',
    'Alveolus, Closed Reduction Stabilization Of Teeth',
    'Alveolus, Closed Reduction Stabilization Of Teeth'
  ],
  [
    'D7780',
    'Oral & Maxillofacial Surgery',
    'Facial Bones - Complicated Reduction With Fixation And Multiple Approaches',
    'Facial Bones - Complicated Reduction With Fixation And Multiple Approaches'
  ],
  ['D7810', 'Oral & Maxillofacial Surgery', 'Open Reduction Of Dislocation', 'Open Reduction Of Dislocation'],
  ['D7820', 'Oral & Maxillofacial Surgery', 'Closed Reduction Of Dislocation', 'Closed Reduction Of Dislocation'],
  ['D7830', 'Oral & Maxillofacial Surgery', 'Manipulation Under Anesthesia', 'Manipulation Under Anesthesia'],
  ['D7840', 'Oral & Maxillofacial Surgery', 'Condylectomy', 'Condylectomy'],
  [
    'D7850',
    'Oral & Maxillofacial Surgery',
    'Surgical Discectomy, With/Without Implant',
    'Surgical Discectomy, With/Without Implant'
  ],
  ['D7852', 'Oral & Maxillofacial Surgery', 'Disc Repair', 'Disc Repair'],
  ['D7854', 'Oral & Maxillofacial Surgery', 'Synovectomy', 'Synovectomy'],
  ['D7856', 'Oral & Maxillofacial Surgery', 'Myotomy', 'Myotomy'],
  ['D7858', 'Oral & Maxillofacial Surgery', 'Joint Reconstruction', 'Joint Reconstruction'],
  ['D7860', 'Oral & Maxillofacial Surgery', 'Arthrotomy', 'Arthrotomy'],
  ['D7865', 'Oral & Maxillofacial Surgery', 'Arthroplasty', 'Arthroplasty'],
  ['D7870', 'Oral & Maxillofacial Surgery', 'Arthrocentesis', 'Arthrocentesis'],
  ['D7871', 'Oral & Maxillofacial Surgery', 'Non-Arthroscopic Lysis And Lavage', 'Non-Arthroscopic Lysis And Lavage'],
  [
    'D7872',
    'Oral & Maxillofacial Surgery',
    'Arthroscopy - Diagnosis, With Or Without Biopsy',
    'Arthroscopy - Diagnosis, With Or Without Biopsy'
  ],
  [
    'D7873',
    'Oral & Maxillofacial Surgery',
    'Arthroscopy: Lavage And Lysis Of Adhesions',
    'Arthroscopy: Lavage And Lysis Of Adhesions'
  ],
  [
    'D7874',
    'Oral & Maxillofacial Surgery',
    'Arthroscopy: Disc Repositioning And Stabilization',
    'Arthroscopy: Disc Repositioning And Stabilization'
  ],
  ['D7875', 'Oral & Maxillofacial Surgery', 'Arthroscopy: Synovectomy', 'Arthroscopy: Synovectomy'],
  ['D7876', 'Oral & Maxillofacial Surgery', 'Arthroscopy: Discectomy', 'Arthroscopy: Discectomy'],
  ['D7877', 'Oral & Maxillofacial Surgery', 'Arthroscopy: Debridement', 'Arthroscopy: Debridement'],
  ['D7880', 'Oral & Maxillofacial Surgery', 'Occlusal Orthotic Device, By Report', 'Occlusal Orthotic Device'],
  [
    'D7881',
    'Oral & Maxillofacial Surgery',
    'Occlusal Orthotic Device Adjustment',
    'Occlusal Orthotic Device Adjustment'
  ],
  ['D7899', 'Oral & Maxillofacial Surgery', 'Unspecified Tmd Therapy, By Report', 'Unspecified Tmd Therapy'],
  [
    'D7910',
    'Oral & Maxillofacial Surgery',
    'Suture Of Recent Small Wounds Up To 5 Cm',
    'Suture Of Recent Small Wounds Up To 5 Cm'
  ],
  ['D7911', 'Oral & Maxillofacial Surgery', 'Complicated Suture - Up To 5 Cm', 'Complicated Suture - Up To 5 Cm'],
  [
    'D7912',
    'Oral & Maxillofacial Surgery',
    'Complicated Suture - Greater Than 5 Cm',
    'Complicated Suture - Greater Than 5 Cm'
  ],
  [
    'D7920',
    'Oral & Maxillofacial Surgery',
    'Skin Graft (Identify Defect Covered, Location And Type Of Graft)',
    'Skin Graft (Identify Defect Covered, Location And Type Of Graft)'
  ],
  [
    'D7921',
    'Oral & Maxillofacial Surgery',
    'Collection And Application Of Autologous Blood Concentrate Product',
    'Collection And Application Of Autologous Blood Concentrate Product'
  ],
  [
    'D7922',
    'Oral & Maxillofacial Surgery',
    'Placement Of Intra-Socket Biological Dressing To Aid In Hemostasis Or Clot Stabilization, Per Site',
    'Placement Of Intra-Socket Biological Dressing'
  ],
  [
    'D7940',
    'Oral & Maxillofacial Surgery',
    'Osteoplasty - For Orthognathic Deformities',
    'Osteoplasty - For Orthognathic Deformities'
  ],
  ['D7941', 'Oral & Maxillofacial Surgery', 'Osteotomy - Mandibular Rami', 'Osteotomy - Mandibular Rami'],
  [
    'D7943',
    'Oral & Maxillofacial Surgery',
    'Osteotomy - Mandibular Rami With Bone Graft; Includes Obtaining The Graft',
    'Osteotomy - Mandibular Rami With Bone Graft; Includes Obtaining The Graft'
  ],
  ['D7944', 'Oral & Maxillofacial Surgery', 'Osteotomy - Segmented Or Subapical', 'Osteotomy - Segmented Or Subapical'],
  ['D7945', 'Oral & Maxillofacial Surgery', 'Osteotomy - Body Of Mandible', 'Osteotomy - Body Of Mandible'],
  ['D7946', 'Oral & Maxillofacial Surgery', 'Lefort I (Maxilla - Total)', 'Lefort I (Maxilla - Total)'],
  ['D7947', 'Oral & Maxillofacial Surgery', 'Lefort I (Maxilla - Segmented)', 'Lefort I (Maxilla - Segmented)'],
  [
    'D7948',
    'Oral & Maxillofacial Surgery',
    'Lefort Ii Or Lefort Iii (Osteoplasty Of Facial Bones For Midface Hypoplasia Or Retrusion) - Without Bone Graft',
    'Lefort Ii Or Lefort Iii'
  ],
  [
    'D7949',
    'Oral & Maxillofacial Surgery',
    'Lefort Ii Or Lefort Iii - With Bone Graft',
    'Lefort Ii Or Lefort Iii - With Bone Graft'
  ],
  [
    'D7950',
    'Oral & Maxillofacial Surgery',
    'Osseous, Osteoperiosteal, Or Cartilage Graft Of The Mandible Or Maxilla - Autogenous Or Nonautogenous, By Report',
    'Osseous, Osteoperiosteal, Or Cartilage Graft Of The Mandible Or Maxilla'
  ],
  [
    'D7951',
    'Oral & Maxillofacial Surgery',
    'Sinus Augmentation With Bone Or Bone Substitutes Via A Lateral Open Approach',
    'Sinus Augmentation With Bone Or Bone Substitutes Via A Lateral Open Approach'
  ],
  [
    'D7952',
    'Oral & Maxillofacial Surgery',
    'Sinus Augmentation Via A Vertical Approach',
    'Sinus Augmentation Via A Vertical Approach'
  ],
  [
    'D7953',
    'Oral & Maxillofacial Surgery',
    'Bone Replacement Graft For Ridge Preservation - Per Site',
    'Bone Replacement Graft For Ridge Preservation - Per Site'
  ],
  [
    'D7955',
    'Oral & Maxillofacial Surgery',
    'Repair Of Maxillofacial Soft And/Or Hard Tissue Defect',
    'Repair Of Maxillofacial Soft And/Or Hard Tissue Defect'
  ],
  [
    'D7960',
    'Oral & Maxillofacial Surgery',
    'Frenulectomy - Also Known As Frenectomy Or Frenotomy - Separate Procedure Not Incidental To Another Procedure',
    'Frenulectomy'
  ],
  ['D7963', 'Oral & Maxillofacial Surgery', 'Frenuloplasty', 'Frenuloplasty'],
  [
    'D7970',
    'Oral & Maxillofacial Surgery',
    'Excision Of Hyperplastic Tissue - Per Arch',
    'Excision Of Hyperplastic Tissue - Per Arch'
  ],
  ['D7971', 'Oral & Maxillofacial Surgery', 'Excision Of Pericoronal Gingiva', 'Excision Of Pericoronal Gingiva'],
  [
    'D7972',
    'Oral & Maxillofacial Surgery',
    'Surgical Reduction Of Fibrous Tuberosity',
    'Surgical Reduction Of Fibrous Tuberosity'
  ],
  ['D7979', 'Oral & Maxillofacial Surgery', 'Non – Surgical Sialolithotomy', 'Non – Surgical Sialolithotomy'],
  ['D7980', 'Oral & Maxillofacial Surgery', 'Surgical Sialolithotomy', 'Surgical Sialolithotomy'],
  ['D7981', 'Oral & Maxillofacial Surgery', 'Excision Of Salivary Gland, By Report', 'Excision Of Salivary Gland'],
  ['D7982', 'Oral & Maxillofacial Surgery', 'Sialodochoplasty', 'Sialodochoplasty'],
  ['D7983', 'Oral & Maxillofacial Surgery', 'Closure Of Salivary Fistula', 'Closure Of Salivary Fistula'],
  ['D7990', 'Oral & Maxillofacial Surgery', 'Emergency Tracheotomy', 'Emergency Tracheotomy'],
  ['D7991', 'Oral & Maxillofacial Surgery', 'Coronoidectomy', 'Coronoidectomy'],
  [
    'D7995',
    'Oral & Maxillofacial Surgery',
    'Synthetic Graft - Mandible Or Facial Bones, By Report',
    'Synthetic Graft - Mandible Or Facial Bones'
  ],
  [
    'D7996',
    'Oral & Maxillofacial Surgery',
    'Implant-Mandible For Augmentation Purposes (Excluding Alveolar Ridge), By Report',
    'Implant-Mandible For Augmentation Purposes'
  ],
  [
    'D7997',
    'Oral & Maxillofacial Surgery',
    'Appliance Removal (Not By Dentist Who Placed Appliance), Includes Removal Of Archbar',
    'Appliance Removal'
  ],
  [
    'D7998',
    'Oral & Maxillofacial Surgery',
    'Intraoral Placement Of A Fixation Device Not In Conjunction With A Fracture',
    'Intraoral Placement Of A Fixation Device Not In Conjunction With A Fracture'
  ],
  [
    'D7999',
    'Oral & Maxillofacial Surgery',
    'Unspecified Oral Surgery Procedure, By Report',
    'Unspecified Oral Surgery Procedure'
  ],
  [
    'D8010',
    'Orthodontics',
    'Limited Orthodontic Treatment Of The Primary Dentition',
    'Limited Orthodontic Treatment Of The Primary Dentition'
  ],
  [
    'D8020',
    'Orthodontics',
    'Limited Orthodontic Treatment Of The Transitional Dentition',
    'Limited Orthodontic Treatment Of The Transitional Dentition'
  ],
  [
    'D8030',
    'Orthodontics',
    'Limited Orthodontic Treatment Of The Adolescent Dentition',
    'Limited Orthodontic Treatment Of The Adolescent Dentition'
  ],
  [
    'D8040',
    'Orthodontics',
    'Limited Orthodontic Treatment Of The Adult Dentition',
    'Limited Orthodontic Treatment Of The Adult Dentition'
  ],
  [
    'D8050',
    'Orthodontics',
    'Interceptive Orthodontic Treatment Of The Primary Dentition',
    'Interceptive Orthodontic Treatment Of The Primary Dentition'
  ],
  [
    'D8060',
    'Orthodontics',
    'Interceptive Orthodontic Treatment Of The Transitional Dentition',
    'Interceptive Orthodontic Treatment Of The Transitional Dentition'
  ],
  [
    'D8070',
    'Orthodontics',
    'Comprehensive Orthodontic Treatment Of The Transitional Dentition',
    'Comprehensive Orthodontic Treatment Of The Transitional Dentition'
  ],
  [
    'D8080',
    'Orthodontics',
    'Comprehensive Orthodontic Treatment Of The Adolescent Dentition',
    'Comprehensive Orthodontic Treatment Of The Adolescent Dentition'
  ],
  [
    'D8090',
    'Orthodontics',
    'Comprehensive Orthodontic Treatment Of The Adult Dentition',
    'Comprehensive Orthodontic Treatment Of The Adult Dentition'
  ],
  ['D8210', 'Orthodontics', 'Removable Appliance Therapy', 'Removable Appliance Therapy'],
  ['D8220', 'Orthodontics', 'Fixed Appliance Therapy', 'Fixed Appliance Therapy'],
  [
    'D8660',
    'Orthodontics',
    'Pre-Orthodontic Treatment Examination To Monitor Growth And Development',
    'Pre-Orthodontic Treatment Examination To Monitor Growth And Development'
  ],
  ['D8670', 'Orthodontics', 'Periodic Orthodontic Treatment Visit', 'Periodic Orthodontic Treatment Visit'],
  [
    'D8680',
    'Orthodontics',
    'Orthodontic Retention (Removal Of Appliances, Construction And Placement Of Retainer(S))',
    'Orthodontic Retention'
  ],
  ['D8681', 'Orthodontics', 'Removable Orthodontic Retainer Adjustment', 'Removable Orthodontic Retainer Adjustment'],
  [
    'D8690',
    'Orthodontics',
    'Orthodontic Treatment (Alternative Billing To A Contract Fee)',
    'Orthodontic Treatment (Alternative Billing To A Contract Fee)'
  ],
  ['D8691', 'Orthodontics', 'Repair Of Orthodontic Appliance', 'Repair Of Orthodontic Appliance'],
  ['D8692', 'Orthodontics', 'Replacement Of Lost Or Broken Retainer', 'Replacement Of Lost Or Broken Retainer'],
  ['D8693', 'Orthodontics', 'Re-Cement Or Re-Bond Fixed Retainer', 'Re-Cement Or Re-Bond Fixed Retainer'],
  [
    'D8694',
    'Orthodontics',
    'Repair Of Fixed Retainers, Includes Reattachment',
    'Repair Of Fixed Retainers, Includes Reattachment'
  ],
  [
    'D8695',
    'Orthodontics',
    'Removal Of Fixed Orthodontic Appliances For Reasons Other Than Completion Of Treatment',
    'Removal Of Fixed Orthodontic Appliances'
  ],
  [
    'D8696',
    'Orthodontics',
    'Repair Of Orthodontic Appliance – Maxillary',
    'Repair Of Orthodontic Appliance – Maxillary'
  ],
  [
    'D8697',
    'Orthodontics',
    'Repair Of Orthodontic Appliance – Mandibular',
    'Repair Of Orthodontic Appliance – Mandibular'
  ],
  [
    'D8698',
    'Orthodontics',
    'Re-Cement Or Re-Bond Fixed Retainer – Maxillary',
    'Re-Cement Or Re-Bond Fixed Retainer – Maxillary'
  ],
  [
    'D8699',
    'Orthodontics',
    'Re-Cement Or Re-Bond Fixed Retainer – Mandibular',
    'Re-Cement Or Re-Bond Fixed Retainer – Mandibular'
  ],
  [
    'D8701',
    'Orthodontics',
    'Repair Of Fixed Retainer, Includes Reattachment – Maxillary',
    'Repair Of Fixed Retainer, Includes Reattachment – Maxillary'
  ],
  [
    'D8702',
    'Orthodontics',
    'Repair Of Fixed Retainer, Includes Reattachment – Mandibular',
    'Repair Of Fixed Retainer, Includes Reattachment – Mandibular'
  ],
  [
    'D8703',
    'Orthodontics',
    'Replacement Of Lost Or Broken Retainer – Maxillary',
    'Replacement Of Lost Or Broken Retainer – Maxillary'
  ],
  [
    'D8704',
    'Orthodontics',
    'Replacement Of Lost Or Broken Retainer – Mandibular',
    'Replacement Of Lost Or Broken Retainer – Mandibular'
  ],
  ['D8999', 'Orthodontics', 'Unspecified Orthodontic Procedure, By Report', 'Unspecified Orthodontic Procedure'],
  [
    'D9110',
    'Adjunctive General Services',
    'Palliative (Emergency) Treatment Of Dental Pain - Minor Procedure',
    'Palliative (Emergency) Treatment Of Dental Pain - Minor Procedure'
  ],
  ['D9120', 'Adjunctive General Services', 'Fixed Partial Denture Sectioning', 'Fixed Partial Denture Sectioning'],
  [
    'D9130',
    'Adjunctive General Services',
    'Temporomandibular Joint Dysfunction – Non-Invasive Physical Therapies',
    'Temporomandibular Joint Dysfunction – Non-Invasive Physical Therapies'
  ],
  [
    'D9210',
    'Adjunctive General Services',
    'Local Anesthesia Not In Conjunction With Operative Or Surgical Procedures',
    'Local Anesthesia Not In Conjunction With Operative Or Surgical Procedures'
  ],
  ['D9211', 'Adjunctive General Services', 'Regional Block Anesthesia', 'Regional Block Anesthesia'],
  [
    'D9212',
    'Adjunctive General Services',
    'Trigeminal Division Block Anesthesia',
    'Trigeminal Division Block Anesthesia'
  ],
  [
    'D9215',
    'Adjunctive General Services',
    'Local Anesthesia In Conjunction With Operative Or Surgical Procedures',
    'Local Anesthesia In Conjunction With Operative Or Surgical Procedures'
  ],
  [
    'D9219',
    'Adjunctive General Services',
    'Evaluation For Moderate Sedation, Deep Sedation Or General Anesthesia',
    'Evaluation For Moderate Sedation, Deep Sedation Or General Anesthesia'
  ],
  [
    'D9222',
    'Adjunctive General Services',
    'Deep Sedation/General Anesthesia – First 15 Minutes',
    'Deep Sedation/General Anesthesia – First 15 Minutes'
  ],
  [
    'D9223',
    'Adjunctive General Services',
    'Deep Sedation/General Anesthesia – Each Subsequent 15 Minute Increment',
    'Deep Sedation/General Anesthesia – Each Subsequent 15 Minute Increment'
  ],
  [
    'D9230',
    'Adjunctive General Services',
    'Inhalation Of Nitrous Oxide/Analgesia, Anxiolysis',
    'Inhalation Of Nitrous Oxide/Analgesia, Anxiolysis'
  ],
  [
    'D9239',
    'Adjunctive General Services',
    'Intravenous Moderate (Conscious) Sedation/Analgesia- First 15 Minutes',
    'Intravenous Moderate (Conscious) Sedation/Analgesia, 15 Minutes'
  ],
  [
    'D9243',
    'Adjunctive General Services',
    'Intravenous Moderate (Conscious) Sedation/Analgesia – Each Subsequent 15 Minute Increment',
    'Intravenous Moderate (Conscious) Sedation/Analgesia (Additional 15 Minutes)'
  ],
  ['D9248', 'Adjunctive General Services', 'Non-Intravenous Conscious Sedation', 'Non-Intravenous Conscious Sedation'],
  [
    'D9310',
    'Adjunctive General Services',
    'Consultation - Diagnostic Service Provided By Dentist Or Physician Other Than Requesting Dentist Or Physician',
    'Consultation'
  ],
  [
    'D9311',
    'Adjunctive General Services',
    'Consultation With A Medical Health Care Professional',
    'Consultation With A Medical Health Care Professional'
  ],
  ['D9410', 'Adjunctive General Services', 'House/Extended Care Facility Call', 'House/Extended Care Facility Call'],
  [
    'D9420',
    'Adjunctive General Services',
    'Hospital Or Ambulatory Surgical Center Call',
    'Hospital Or Ambulatory Surgical Center Call'
  ],
  [
    'D9430',
    'Adjunctive General Services',
    'Office Visit For Observation (During Regularly Scheduled Hours) - No Other Services Performed',
    'Office Visit For Observation (During Regularly Scheduled Hours)'
  ],
  [
    'D9440',
    'Adjunctive General Services',
    'Office Visit - After Regularly Scheduled Hours',
    'Office Visit - After Regularly Scheduled Hours'
  ],
  [
    'D9450',
    'Adjunctive General Services',
    'Case Presentation, Detailed And Extensive Treatment Planning',
    'Case Presentation, Detailed And Extensive Treatment Planning'
  ],
  [
    'D9610',
    'Adjunctive General Services',
    'Therapeutic Parenteral Drug, Single Administration',
    'Therapeutic Parenteral Drug, Single Administration'
  ],
  [
    'D9612',
    'Adjunctive General Services',
    'Therapeutic Parenteral Drugs, Two Or More Administrations, Different Medications',
    'Therapeutic Parenteral Drugs, Two Or More Administrations, Different Medications'
  ],
  [
    'D9613',
    'Adjunctive General Services',
    'Infiltration Of Sustained Release Therapeutic Drug – Single Or Multiple Sites',
    'Infiltration Of Sustained Release Therapeutic Drug – Single Or Multiple Sites'
  ],
  [
    'D9630',
    'Adjunctive General Services',
    'Drugs Or Medicaments Dispensed In The Office For Home Use',
    'Drugs Or Medicaments Dispensed In The Office For Home Use'
  ],
  [
    'D9910',
    'Adjunctive General Services',
    'Application Of Desensitizing Medicament',
    'Application Of Desensitizing Medicament'
  ],
  [
    'D9911',
    'Adjunctive General Services',
    'Application Of Desensitizing Resin For Cervical And/Or Root Surface, Per Tooth',
    'Application Of Desensitizing Resin For Cervical And/Or Root Surface, Per Tooth'
  ],
  ['D9920', 'Adjunctive General Services', 'Behavior Management, By Report', 'Behavior Management'],
  [
    'D9930',
    'Adjunctive General Services',
    'Treatment Of Complications (Post-Surgical) - Unusual Circumstances, By Report',
    'Treatment Of Complications (Post-Surgical) - Unusual Circumstances'
  ],
  [
    'D9932',
    'Adjunctive General Services',
    'Cleaning And Inspection Of Removable Complete Denture, Maxillary',
    'Cleaning And Inspection Of Removable Complete Denture, Maxillary'
  ],
  [
    'D9933',
    'Adjunctive General Services',
    'Cleaning And Inspection Of Removable Complete Denture, Mandibular',
    'Cleaning And Inspection Of Removable Complete Denture, Mandibular'
  ],
  [
    'D9934',
    'Adjunctive General Services',
    'Cleaning And Inspection Of Removable Partial Denture, Maxillary',
    'Cleaning And Inspection Of Removable Partial Denture, Maxillary'
  ],
  [
    'D9935',
    'Adjunctive General Services',
    'Cleaning And Inspection Of Removable Partial Denture, Mandibular',
    'Cleaning And Inspection Of Removable Partial Denture, Mandibular'
  ],
  ['D9941', 'Adjunctive General Services', 'Fabrication Of Athletic Mouthguard', 'Fabrication Of Athletic Mouthguard'],
  [
    'D9942',
    'Adjunctive General Services',
    'Repair And/Or Reline Of Occlusal Guard',
    'Repair And/Or Reline Of Occlusal Guard'
  ],
  ['D9943', 'Adjunctive General Services', 'Occlusal Guard Adjustment', 'Occlusal Guard Adjustment'],
  [
    'D9944',
    'Adjunctive General Services',
    'Occlusal Guard – Hard Appliance, Full Arch',
    'Occlusal Guard – Hard Appliance, Full Arch'
  ],
  [
    'D9945',
    'Adjunctive General Services',
    'Occlusal Guard – Soft Appliance, Full Arch',
    'Occlusal Guard – Soft Appliance, Full Arch'
  ],
  [
    'D9946',
    'Adjunctive General Services',
    'Occlusal Guard – Hard Appliance, Partial Arch',
    'Occlusal Guard – Hard Appliance, Partial Arch'
  ],
  ['D9950', 'Adjunctive General Services', 'Occlusion Analysis - Mounted Case', 'Occlusion Analysis - Mounted Case'],
  ['D9951', 'Adjunctive General Services', 'Occlusal Adjustment - Limited', 'Occlusal Adjustment - Limited'],
  ['D9952', 'Adjunctive General Services', 'Occlusal Adjustment - Complete', 'Occlusal Adjustment - Complete'],
  ['D9961', 'Adjunctive General Services', 'Duplicate/Copy Patient Records', 'Duplicate/Copy Patient Records'],
  ['D9970', 'Adjunctive General Services', 'Enamel Microabrasion', 'Enamel Microabrasion'],
  [
    'D9971',
    'Adjunctive General Services',
    'Odontoplasty 1 - 2 Teeth; Includes Removal Of Enamel Projections',
    'Odontoplasty 1 - 2 Teeth; Includes Removal Of Enamel Projections'
  ],
  [
    'D9972',
    'Adjunctive General Services',
    'External Bleaching - Per Arch - Performed In Office',
    'External Bleaching - Per Arch - Performed In Office'
  ],
  ['D9973', 'Adjunctive General Services', 'External Bleaching - Per Tooth', 'External Bleaching - Per Tooth'],
  ['D9974', 'Adjunctive General Services', 'Internal Bleaching - Per Tooth', 'Internal Bleaching - Per Tooth'],
  [
    'D9975',
    'Adjunctive General Services',
    'External Bleaching For Home Application, Per Arch; Includes Materials And Fabrication Of Custom Trays',
    'External Bleaching For Home Application'
  ],
  ['D9985', 'Adjunctive General Services', 'Sales Tax', 'Sales Tax'],
  ['D9986', 'Adjunctive General Services', 'Missed Appointment', 'Missed Appointment'],
  ['D9987', 'Adjunctive General Services', 'Cancelled Appointment', 'Cancelled Appointment'],
  [
    'D9991',
    'Adjunctive General Services',
    'Dental Case Management - Addressing Appointment Compliance Barriers',
    'Dental Case Management - Addressing Appointment Compliance Barriers'
  ],
  [
    'D9992',
    'Adjunctive General Services',
    'Dental Case Management - Care Coordination',
    'Dental Case Management - Care Coordination'
  ],
  [
    'D9993',
    'Adjunctive General Services',
    'Dental Case Management - Motivational Interviewing',
    'Dental Case Management - Motivational Interviewing'
  ],
  [
    'D9994',
    'Adjunctive General Services',
    'Dental Case Management - Patient Education To Improve Oral Health Literacy',
    'Dental Case Management - Patient Education To Improve Oral Health Literacy'
  ],
  [
    'D9995',
    'Adjunctive General Services',
    'Teledentistry – Synchronous; Real-Time Encounter',
    'Teledentistry – Synchronous; Real-Time Encounter'
  ],
  [
    'D9996',
    'Adjunctive General Services',
    'Teledentistry – Asynchronous; Information Stored And Forwarded To Dentist For Subsequent Review',
    'Teledentistry – Asynchronous'
  ],
  [
    'D9997',
    'Adjunctive General Services',
    'Dental Case Management - Patients With Special Health Care Needs',
    'Dental Case Management - Patients With Special Health Care Needs'
  ],
  [
    'D9990',
    'Adjunctive General Services',
    'Certified Translation Or Sign-Language Services – Per Visit',
    'Certified Translation Or Sign-Language Services – Per Visit'
  ],
  [
    'D9999',
    'Adjunctive General Services',
    'Unspecified Adjunctive Procedure, By Report',
    'Unspecified Adjunctive Procedure'
  ]
]
