import { useFormikContext } from 'formik'

import { Text, Pane, PaneProps } from 'evergreen-ui'

import { Icon } from 'lib'

export type Props = PaneProps & {
  max?: number
  collapseSpace?: boolean
}

const FormError = ({ max, collapseSpace, ...props }: Props) => {
  const { touched, errors } = useFormikContext()

  const errorMessages = Object.keys({ ...touched, customValidation: true })
    .map((key: string) => (key in errors ? (errors as { [key: string]: string })[key] : null))
    .filter((el) => el !== null) // removes null values
    .slice(0, max ?? undefined) as string[]

  return (
    <Pane minHeight={collapseSpace ? undefined : 21} marginTop={collapseSpace ? 4 : 8} marginBottom={-4} {...props}>
      {errorMessages.map((message: string, i: number) => (
        <div key={i}>
          <Icon color="red" icon={['fad', 'times']} marginRight={4} />
          <Text color="red">{message}</Text>
        </div>
      ))}
    </Pane>
  )
}

export default FormError
