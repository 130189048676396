import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { useLazyQuery } from '@apollo/client'
import { GET_PATIENT_SELECTOR_SEARCH } from 'lib/graphql/_patient-selector'
import { Paragraph, Autocomplete } from 'evergreen-ui'

import { Types, TextInput } from 'lib'

export type Props = {
  setPatient: (patient: Types.PatientSelectorSearch_patientsConnection_edges_node) => void
}

const PatientSelector = ({ setPatient }: Props) => {
  const [isValid, setIsValid] = useState(false)

  const [loadPatients, { loading, data, called }] = useLazyQuery<
    Types.PatientSelectorSearch,
    Types.PatientSelectorSearchVariables
  >(GET_PATIENT_SELECTOR_SEARCH)

  const patients = data?.patientsConnection.edges?.filter((edge) => !!edge).map((edge) => edge!.node)

  const [debouncedSearch] = useDebouncedCallback((value: string) => {
    loadPatients({ variables: { search: value, first: 10 } })
  }, 300)

  return (
    <>
      <Autocomplete
        onChange={(patient: Types.PatientSelectorSearch_patientsConnection_edges_node) => setPatient(patient)}
        onStateChange={(changes) => {
          if (changes.selectedItem) setIsValid(true)
          else if (changes.inputValue) {
            debouncedSearch(changes.inputValue)
            if (isValid) setIsValid(false)
          }
        }}
        items={patients ?? []}
        itemToString={(patient?: Types.PatientSelectorSearch_patientsConnection_edges_node) =>
          patient ? `${patient.name.first} ${patient.name.last} (${patient.isGuarantor ? 'G' : 'D'})` : ''
        }
        itemsFilter={(items) => items}
      >
        {({ getInputProps, getRef }) => {
          return (
            <TextInput
              icon={loading ? undefined : !called || patients?.length ? ['fas', 'search'] : ['fas', 'search-minus']}
              loading={loading}
              isInvalid={false}
              placeholder="Patient name..."
              marginBottom={4}
              width="100%"
              ref={getRef}
              {...getInputProps()}
            />
          )
        }}
      </Autocomplete>
      <Paragraph color="muted" size={300} textAlign="center">
        Look up an existing patient by first or last name
      </Paragraph>
    </>
  )
}

export default PatientSelector
