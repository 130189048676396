import { CTA } from 'lib'
import FailedPayment from './failed-payment'
import FollowUps from './follow-ups'
import Outstanding from './outstanding'
import Scheduled from './scheduled'
import Assistant from './assistant'
import Processed from './processed'
import Prompted from './prompted'

export default {
  CTA,
  FailedPayment,
  FollowUps,
  Outstanding,
  Scheduled,
  Assistant,
  Processed,
  Prompted
}
