import { useEffect, useState } from 'react'
import { Types } from 'lib'

import { SideSheet } from 'evergreen-ui'

import Dependent from 'lib/dependent'
import { colors } from 'lib'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  dependentId?: string
  guarantorId?: string
  initialPath?: Types.DependentPath
  showGuarantorSheet?: () => void
  isPortal?: boolean
}

const DependentSheet = ({
  isShown,
  setIsShown,
  dependentId,
  guarantorId,
  initialPath = Types.DependentPath.PROFILE,
  showGuarantorSheet,
  isPortal = false
}: Props) => {
  if (isShown && !dependentId && !guarantorId)
    throw Error('Dependent sheet requires either dependentId (existing) or guarantorId (create)')

  const [dependentIdState, setDependentIdState] = useState(dependentId)
  // Dependent ID can cahnge from DependentMemberCardList
  useEffect(() => setDependentIdState(dependentId), [dependentId])

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={400}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: colors.blue.lightest
      }}
    >
      {({ close }: { close: () => void }) => (
        <>
          {typeof dependentIdState === 'string' ? (
            <Dependent.Existing
              dependentId={dependentIdState}
              initialPath={initialPath}
              showGuarantorSheet={showGuarantorSheet}
              isPortal={isPortal}
              closeModal={close}
            />
          ) : (
            <Dependent.Create
              setDependentIdState={setDependentIdState}
              guarantorId={guarantorId!}
              initialPath={initialPath}
            />
          )}
        </>
      )}
    </SideSheet>
  )
}

export default DependentSheet
