import EnrollInstallmentsCard from 'components/enroll-installments-card'

export type Props = {
  guarantorId: string
}

const GuarantorEnrollInstallments = ({ guarantorId }: Props) => {
  return <EnrollInstallmentsCard contactId={guarantorId} margin={16} elevation={0} />
}

export default GuarantorEnrollInstallments
