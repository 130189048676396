import { forwardRef } from 'react'
import { Button as EvergreenButton, ButtonProps } from 'evergreen-ui'

import { Icon } from 'lib'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends Omit<ButtonProps, 'iconBefore' | 'iconAfter'> {
  icon?: IconProp
  iconBefore?: IconProp
  iconAfter?: IconProp
  leftChevron?: boolean
  rightChevron?: boolean
  children?: React.ReactNode
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ icon, iconBefore, iconAfter, children, leftChevron, rightChevron, ...props }, ref) => {
    let iconColor
    if (props.appearance === 'primary') iconColor = 'white'
    else if (props.appearance === 'minimal') iconColor = props.intent ?? 'primary'
    else iconColor = props.intent ?? 'base'

    return (
      <EvergreenButton ref={ref} type="button" {...props}>
        {leftChevron && (
          <Icon
            icon={['far', 'chevron-left']}
            color={iconColor}
            opacity="0.7"
            data-undraggable
            position="absolute"
            top="calc(50% - 10px)"
            left={24}
          />
        )}
        {iconBefore && <Icon icon={iconBefore} marginRight={8} color={iconColor} opacity="0.7" data-undraggable />}
        {children}
        {iconAfter && <Icon icon={iconAfter} marginLeft={8} color={iconColor} opacity="0.7" data-undraggable />}
        {rightChevron && (
          <Icon
            icon={['far', 'chevron-right']}
            color={iconColor}
            opacity="0.7"
            data-undraggable
            position="absolute"
            top="calc(50% - 10px)"
            right={24}
          />
        )}
      </EvergreenButton>
    )
  }
)

export default Button
