import 'graphql'
import gql from 'graphql-tag'

export const GET_DEPENDENT_MEMBER_CARD_LIST = gql`
  query DependentMemberCardList($contactId: ID!) {
    contact(id: $contactId) {
      id
      familyMemberCount
      source
      dependents(isMember: true) {
        id
        isGuarantor
        email
        phone

        code
        name {
          first
          last
        }
        perio
        dob {
          day
          month
          year
        }

        planTerm
        plan {
          id
          name
        }
        planRenewalDate
        stripe {
          membership {
            id
            effectiveDate
            status
            autoRenew
            price
            interval
          }
        }
      }
    }
  }
`
