import gql from 'graphql-tag'

export const GET_ACCOUNT_USERS = gql`
  query AccountUsers {
    account {
      id

      users {
        firstName
        id
        firstName
        lastName
        google {
          email
          role
          verified
        }
        notify_enrolled
        notify_invoiceFailed
        notify_paymentCompleted
        notify_eodPracticeReport
        isGroupUserForCurrentAccount
      }
    }
  }
`
