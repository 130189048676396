import gql from 'graphql-tag'

export const PAUSE_INSTALLMENT_PLAN = gql`
  mutation PauseInstallmentPlan($installmentPlanId: String!) {
    pauseInstallmentPlan(installmentPlanId: $installmentPlanId) {
      id
      status
    }
  }
`

export const TERMINATE_INSTALLMENT_PLAN = gql`
  mutation TerminateInstallmentPlan($installmentPlanId: ID!) {
    terminateInstallmentPlan(installmentPlanId: $installmentPlanId) {
      id
      status
    }
  }
`
