import { Tab as EvergreenTab, TabProps } from 'evergreen-ui'

import { Icon } from 'lib'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = Omit<TabProps, 'iconBefore' | 'iconAfter'> & {
  iconBefore?: IconProp
  iconAfter?: IconProp
  children: React.ReactNode
}

const Tab = ({ iconBefore, iconAfter, children, ...props }: Props) => {
  return (
    <EvergreenTab {...props}>
      {iconBefore && <Icon icon={iconBefore} color={props.color} marginRight="8px" opacity="0.6" />}
      {children}
      {iconAfter && <Icon icon={iconAfter} color={props.color} marginLeft="8px" opacity="0.6" />}
    </EvergreenTab>
  )
}

export default Tab
