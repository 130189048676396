import gql from 'graphql-tag'

export const TOGGLE_FINANCING_PROVIDER = gql`
  mutation ToggleFinancingProvider($id: ID!, $enabled: Boolean!) {
    toggleFinancingProvider(id: $id, enabled: $enabled) {
      id
      enabled
    }
  }
`
