import { Heading, Pane, Text, toaster } from 'evergreen-ui'
import { ApolloCache, useMutation } from '@apollo/client'
import { Types, Button, Card, CardHeader, colors, mergeVariables } from 'lib'

import { DELIVER_EMAIL_NOTIFICATION, DELIVER_SMS_NOTIFICATION, SEND_LETTER } from 'graphql/_send-notification-card'
import { mapTemplateData } from 'utility/notification-template-variables'

import { useModal } from 'components/modal-provider'
import { useState, useMemo } from 'react'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import LetterPreviewButton from 'components/letter-preview-button'

type Props = {
  state: Types.SendNotificationState
  template?: Types.GetSendNotificationCard_contact_account_notificationTemplates
  guarantor: Types.GetSendNotificationCard['contact']
  onBack: () => void
  onNext: (params?: { letterEstimatedAt?: string }) => void
}

type Template = Omit<Types.GetSendNotificationCard_contact_account_notificationTemplates, '__typename' | 'status'>

const SendNotificationReview = ({ state: { channel }, template, onBack, onNext, guarantor }: Props) => {
  if (!(channel && template)) throw Error('Incomplete client state')
  const showTemplateSheet = useModal('template')
  const [templateState, setTemplateState] = useState<Template>(template)

  const isEmail = channel === Types.NotificationChannel.EMAIL
  const isLetter = channel === Types.NotificationChannel.LETTER

  const borderStyle = `solid 1px ${colors.border.default}`

  const mergedTemplate = useMemo(() => {
    const templateData = mapTemplateData(guarantor)
    return {
      ...template,
      body: mergeVariables(templateState.body, templateData),
      ...(isEmail
        ? {
            subject: mergeVariables(templateState.subject || '', templateData),
            signature: mergeVariables(templateState.signature || '', templateData),
            buttonLabel: mergeVariables(templateState.buttonLabel || '', templateData),
            buttonUrl: mergeVariables(templateState.buttonUrl || '', templateData)
          }
        : isLetter
        ? { title: mergeVariables(templateState.title || '', templateData) }
        : {})
    }
  }, [templateState, isEmail])

  const clearOutreach = (cache: ApolloCache<any>) => {
    cache.evict({ id: cache.identify({ id: guarantor.id, __typename: 'Contact' }), fieldName: 'outreach' })
    cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        notificationsConnection: (_existing, { DELETE }) => DELETE
      }
    })
    cache.gc()
  }

  const [deliverEmailNotification, { loading: emailLoading }] = useMutation<
    Types.DeliverEmailNotification,
    Types.DeliverEmailNotificationVariables
  >(DELIVER_EMAIL_NOTIFICATION, {
    update: clearOutreach
  })

  const [deliverSmsNotification, { loading: smsLoading }] = useMutation<
    Types.DeliverSmsNotification,
    Types.DeliverSmsNotificationVariables
  >(DELIVER_SMS_NOTIFICATION, {
    update: clearOutreach,
    onCompleted: () => onNext(),
    onError: (err) => toaster.danger(err.message)
  })

  const [sendLetter, { loading: letterLoading }] = useMutation<Types.SendLetter, Types.SendLetterVariables>(
    SEND_LETTER,
    {
      update: clearOutreach
    }
  )

  const loading = emailLoading || smsLoading || letterLoading

  const handleTemplateChange = (template: Template) => setTemplateState(template)

  const fieldMap: Record<
    Types.NotificationChannel,
    { icon: IconName; cta: string; deliver: () => Promise<void>; templatePreview: React.ReactNode }
  > = {
    [Types.NotificationChannel.EMAIL]: {
      icon: 'paper-plane',
      cta: 'Send Email',
      deliver: async () => {
        if (!guarantor.email) throw Error('Guarantor does not have an email address')

        const { subject, body, buttonLabel, buttonUrl, signature } = mergedTemplate
        if (!subject || !signature) throw Error('Incorrect template')

        await deliverEmailNotification({
          variables: {
            contactId: guarantor.id,
            templateId: template.id,
            subject,
            body,
            buttonLabel,
            buttonUrl,
            signature
          }
        })
        onNext()
      },
      templatePreview: (
        <Pane width="100%">
          <Pane padding={16} borderBottom={borderStyle}>
            <Text fontWeight={500} fontSize={16}>
              {mergedTemplate.subject}
            </Text>
          </Pane>
          <Pane padding={16} borderBottom={borderStyle}>
            <Text whiteSpace="pre-wrap">{mergedTemplate.body}</Text>
          </Pane>
          {mergedTemplate.buttonLabel && mergedTemplate.buttonUrl && (
            <Pane padding={16} borderBottom={borderStyle} display="flex" alignItems="center">
              <Button
                flexShrink={0}
                marginRight={16}
                onClick={() => window.open(mergedTemplate.buttonUrl || '', '_blank')}
              >
                {mergedTemplate.buttonLabel}
              </Button>
              <Text whiteSpace="nowrap" display="block" overflow="hidden" textOverflow="ellipsis">
                {mergedTemplate.buttonUrl}
              </Text>
            </Pane>
          )}
          <Pane padding={16}>
            <Text whiteSpace="pre-wrap">{mergedTemplate.signature}</Text>
          </Pane>
        </Pane>
      )
    },
    [Types.NotificationChannel.SMS]: {
      icon: 'message-dots',
      cta: 'Send Text',
      deliver: async () => {
        if (!guarantor.phone) throw Error('Guarantor does not have a phone number')
        await deliverSmsNotification({
          variables: {
            contactId: guarantor.id,
            templateId: template.id,
            body: mergedTemplate.body
          }
        })
      },
      templatePreview: (
        <Pane padding={16} width="100%">
          <Text whiteSpace="pre-wrap">{mergedTemplate.body}</Text>
        </Pane>
      )
    },
    [Types.NotificationChannel.LETTER]: {
      icon: 'mailbox-flag-up',
      cta: 'Send Letter',
      deliver: async () => {
        const { body, title } = mergedTemplate

        if (!guarantor.verifiedAddress) throw Error('Guarantor does not have a verified address')
        const { data } = await sendLetter({
          variables: {
            contactId: guarantor.id,
            templateId: template.id,
            body,
            title
          }
        })
        onNext({ letterEstimatedAt: data?.sendLetter.estimatedAt })
      },
      templatePreview: (
        <Pane width="100%">
          {mergedTemplate.title && (
            <Pane padding={16} borderBottom={borderStyle}>
              <Text fontWeight={500} fontSize={16}>
                {mergedTemplate.title}
              </Text>
            </Pane>
          )}
          <Pane padding={16}>
            <Text whiteSpace="pre-wrap">{mergedTemplate.body}</Text>
          </Pane>
          <Pane padding={8}>
            <LetterPreviewButton
              appearance="minimal"
              justifyContent="center"
              width="100%"
              contactId={guarantor.id}
              title={mergedTemplate.title!}
              body={mergedTemplate.body}
              layout={mergedTemplate.layout}
            />
          </Pane>
        </Pane>
      )
    }
  }

  const { icon, cta, deliver, templatePreview } = fieldMap[channel]

  return (
    <>
      <Card display="flex" flexDirection="column" alignItems="center" padding={0} elevation={0} margin={16}>
        <CardHeader withButton>
          <Heading display="flex" justifyContent="space-between" alignItems="center" width="100%">
            Review & Send
            <Button
              type="button"
              justifyContent="center"
              onClick={() =>
                showTemplateSheet({
                  template: mergedTemplate,
                  channel: template.channel,
                  isDeliverMode: true,
                  onTemplateChange: handleTemplateChange
                })
              }
            >
              Edit
            </Button>
          </Heading>
        </CardHeader>

        {/* Email */}
        {templatePreview}
      </Card>
      <Pane elevation={0} padding={16} width="100%" display="flex" flexDirection="column">
        <Pane display="flex" justifyContent="space-between" width="100%">
          <Button
            type="button"
            justifyContent="center"
            height={48}
            onClick={onBack}
            appearance="minimal"
            disabled={loading}
          >
            Back
          </Button>
          <Button
            type="submit"
            justifyContent="center"
            appearance="primary"
            iconAfter={['fas', icon]}
            height={48}
            onClick={deliver}
            isLoading={loading}
          >
            {cta}
          </Button>
        </Pane>
        {isLetter && (
          <Text size={300} color="muted" marginTop={12} display="inline-block" textAlign="center">
            Note that we charge for each mailed letter.
            <br />
            You have 6 hours to cancel after clicking Send Letter.
          </Text>
        )}
      </Pane>
    </>
  )
}

export default SendNotificationReview
