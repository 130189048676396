import 'graphql'
import gql from 'graphql-tag'

export const GET_PLAN_CARD_LIST = gql`
  query PlanCardList($accountId: ID, $contactId: ID, $age: Int, $perio: Boolean) {
    livePlans(accountId: $accountId, contactId: $contactId, age: $age, perio: $perio) {
      id
      name
      perio
      minAge
      maxAge
      annualPriceActive
      annualPrice
      annualFamilyPrice
      monthlyPriceActive
      monthlyPrice
      monthlyFamilyPrice
      familyDiscount
      globalDiscountActive
      globalDiscount
      treatments {
        id
        name
        quantity
      }
      discounts {
        id
      }
    }
  }
`
