import useMedia from 'use-media'

const mediaQueries = {
  mobile: '(max-width: 768px)',
  tablet: '(max-width: 992px)'
}

export function useMediaQueryContext() {
  const isMobile = useMedia(mediaQueries.mobile)
  const isTablet = useMedia(mediaQueries.tablet)

  return { isMobile, isTablet }
}
