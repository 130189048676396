import { useMutation } from '@apollo/client'
import { UPDATE_ACCOUNT_DEFAULT_BALANCE_TYPE } from 'graphql/_account-balance-type-dialog'

import { Types, Button, Spinner } from 'lib'
import { Pane, Text, Dialog, toaster } from 'evergreen-ui'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  balanceType: Types.BalanceType
}

const AccountBalanceTypeDialog = ({ isShown, setIsShown, balanceType }: Props) => {
  const [update, updateStatus] = useMutation<
    Types.UpdateAccountDefaultBalanceType,
    Types.UpdateAccountDefaultBalanceTypeVariables
  >(UPDATE_ACCOUNT_DEFAULT_BALANCE_TYPE, {})

  return (
    <Dialog
      isShown={isShown}
      hasClose={false}
      title="Modify Default Balance Type"
      hasFooter={false}
      onCloseComplete={() => {
        setIsShown(false)
      }}
    >
      {({ close }) => (
        <>
          <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Text size={500} margin={24} textAlign="center">
              This setting specifies whether balances are determined by the PMS AR Summary Report or the Transaction
              Ledger. Please select which balances to apply this change to:
            </Text>
          </Pane>
          <Pane
            elevation={0}
            padding={16}
            margin={-16}
            marginTop={16}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {updateStatus.loading ? (
              <Spinner delay={0} height={32} />
            ) : (
              <>
                <Button
                  onClick={async () => {
                    close()
                  }}
                >
                  Cancel
                </Button>

                <Pane>
                  <Button
                    intent="danger"
                    onClick={async () => {
                      await update({ variables: { balanceType, updateCurrent: true } })
                      toaster.success('Default balance type updated')
                      close()
                    }}
                  >
                    Current + Future
                  </Button>
                  <Button
                    marginX={8}
                    intent="warning"
                    onClick={async () => {
                      await update({ variables: { balanceType, updateOutstanding: true } })
                      toaster.success('Default balance type updated')
                      close()
                    }}
                  >
                    Outstanding + Future
                  </Button>

                  <Button
                    intent="success"
                    onClick={async () => {
                      await update({ variables: { balanceType } })
                      toaster.success('Default balance type updated')
                      close()
                    }}
                  >
                    Future Only
                  </Button>
                </Pane>
              </>
            )}
          </Pane>
        </>
      )}
    </Dialog>
  )
}

export default AccountBalanceTypeDialog
