import { useState } from 'react'
import urlUtility from 'url'

import { useLazyQuery } from '@apollo/client'
import { GET_FILE_LINK } from 'lib/graphql/_file-link'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { Types, Icon } from 'lib'

import { colors } from 'lib'
import { mc } from 'lib'

import { Text, TextProps, toaster, Pane } from 'evergreen-ui'
import moment from 'moment'

export type Props = Omit<TextProps, 'onError'> & {
  label: string
  path: string
  icon?: IconProp
  onError?: (err: Error) => void
}

const FileLink = ({ label, path, icon = ['far', 'receipt'], onError, ...props }: Props) => {
  const [windowReference, setWindowReference] = useState<Window>()
  const [getFileLink, { data, loading, refetch }] = useLazyQuery<Types.FileLink, Types.FileLinkVariables>(
    GET_FILE_LINK,
    {
      variables: {
        path
      },
      onCompleted: (data) => windowReference!.location.replace(data.generateFileSignedUrl),
      onError: (err) => {
        onError && onError(err)
        toaster.danger(err.message)
      },
      notifyOnNetworkStatusChange: true
    }
  )

  const signedUrl = data?.generateFileSignedUrl

  return (
    <Text
      display="flex"
      cursor="pointer"
      onClick={() => {
        const openWindow = () => setWindowReference(window.open(`${mc.urls.member}/loading`)!)

        if (!signedUrl) {
          openWindow()
          return getFileLink()
        } else {
          if (!isSignedUrlExpired(signedUrl)) {
            return window.open(signedUrl)
          } else {
            if (!refetch) throw Error('Refetch not defined')
            openWindow()
            return refetch()
          }
        }
      }}
      size={500}
      color={colors.blue.base}
      {...props}
    >
      <Pane width={16} flex="none" display="inline-block">
        {loading ? (
          <Icon icon={['fas', 'circle-notch']} spin color={colors.blue.base} />
        ) : (
          <Icon icon={icon} color={colors.blue.base} />
        )}
      </Pane>
      <Pane display="inline-block" wordBreak="break-all">
        {label}
      </Pane>
    </Text>
  )
}

export default FileLink

const isSignedUrlExpired = (signedUrl: string) => {
  const parsedUrl = urlUtility.parse(signedUrl)
  const query = new URLSearchParams(parsedUrl.query ?? '')
  const signatureDate = query.get('X-Goog-Date') as string
  const signatureExpires = parseInt(query.get('X-Goog-Expires') as string)
  const expirationMoment = moment(signatureDate).add(signatureExpires, 'seconds')
  return expirationMoment.isBefore(moment().subtract(1, 'seconds'))
}
