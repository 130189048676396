import gql from 'graphql-tag'
import { fragments as plans_fragments } from 'graphql/_plans'

export const SET_PLAN_STATUS = gql`
  mutation SetPlanStatus($id: ID!, $status: PlanStatus!) {
    setPlanStatus(id: $id, status: $status) {
      ...planGridFields
    }
  }
  ${plans_fragments.planGridFields}
`

export const DELETE_PLAN = gql`
  mutation DeletePlan($id: ID!) {
    deletePlan(id: $id) {
      id
    }
  }
`

export const COPY_PLAN = gql`
  mutation CopyPlan($id: ID!) {
    copyPlan(id: $id) {
      ...planGridFields
    }
  }
  ${plans_fragments.planGridFields}
`
