import { useMutation } from '@apollo/client'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { ENROLL_INSTALLMENTS } from 'graphql/_enroll-installments-card'

import { Pane, Text, toaster } from 'evergreen-ui'
import { Types, Button, colors, Checkbox, CheckoutTableCard, TemplateDescription } from 'lib'

import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'

import { EnrollInstallmentsState } from '.'

export type Props = {
  guarantor: Types.EnrollInstallmentsCard_contact
  enrollState: EnrollInstallmentsState
  updateEnrollState: (update: Partial<EnrollInstallmentsState>) => void
}

const EnrollInstallmentsConfirm = ({
  guarantor,
  enrollState: { amount, installmentTemplate: template, step },
  updateEnrollState
}: Props) => {
  const { account } = useGlobal()
  if (!amount || !template) throw Error('EnrollState incomplete')

  const showInstallmentTermsSheet = useModal('installmentTerms')

  const [enroll, { loading }] = useMutation<Types.EnrollInstallments, Types.EnrollInstallmentsVariables>(
    ENROLL_INSTALLMENTS,
    {
      variables: {
        contactId: guarantor.id,
        amount: amount,
        installmentTemplateId: template.id
      },
      update: (cache) => {
        cache.evict({ id: cache.identify({ id: guarantor.id, __typename: 'Contact' }), fieldName: 'installmentPlans' })
        cache.gc()
      },
      onCompleted: (data) => updateEnrollState({ step: step + 1, charge: data.enrollInstallments }),
      onError: (err) => toaster.danger(err.message.replace('GraphQL error: ', ''))
    }
  )

  const hasPaymentDetails = guarantor.stripe?.defaultSource !== null

  return (
    <Formik
      initialValues={{
        confirm: true,
        sendReceipt: true
      }}
      validationSchema={Yup.object({
        confirm: Yup.boolean().oneOf([true], 'Confirmation is required')
      })}
      onSubmit={() => enroll()}
    >
      {({ values }) => (
        <Form>
          <Pane display="grid" padding={16} gridGap={16}>
            <Pane display="flex" flexDirection="column">
              <TemplateDescription
                color="muted"
                marginY={5}
                amount={amount}
                installmentTemplate={template}
                prepend="Patient pays"
              />
              <Button
                appearance="minimal"
                alignSelf="center"
                onClick={() =>
                  showInstallmentTermsSheet({
                    installmentTemplate: template,
                    amount,
                    account,
                    guarantor
                  })
                }
                marginBottom={-4}
              >
                View Schedule & Terms
              </Button>
            </Pane>

            <CheckoutTableCard amount={amount} installmentTemplate={template} elevation={0} />
            <Pane display="grid" gridGap={4}>
              <Checkbox
                name="confirm"
                label={
                  <TemplateDescription
                    amount={amount}
                    installmentTemplate={template}
                    prepend={
                      <Text size={400}>
                        Patient agrees to the plan{' '}
                        <Text
                          textDecoration="underline"
                          onClick={() =>
                            showInstallmentTermsSheet({
                              installmentTemplate: template,
                              amount,
                              account,
                              guarantor
                            })
                          }
                        >
                          Schedule & Terms
                        </Text>{' '}
                        and authorizes a charge of
                      </Text>
                    }
                  />
                }
                marginBottom={0}
              />
            </Pane>
          </Pane>
          <Pane display="flex" flexDirection="row" padding={16} borderTop={`solid 1px ${colors.border.muted}`}>
            <Button
              disabled={loading}
              appearance="minimal"
              height={48}
              marginRight={16}
              onClick={() => updateEnrollState({ step: step - 1 })}
            >
              Back
            </Button>
            <Button
              type="submit"
              height={48}
              appearance="primary"
              flexGrow={1}
              rightChevron
              disabled={!values.confirm || !hasPaymentDetails}
              isLoading={loading}
            >
              <Pane textAlign="center" width="100%">
                {hasPaymentDetails ? 'Charge Now' : 'Update Payment Details'}
              </Pane>
            </Button>
          </Pane>
        </Form>
      )}
    </Formik>
  )
}

export default EnrollInstallmentsConfirm
