import { useMutation } from '@apollo/client'

import { PAUSE_INSTALLMENT_PLAN, TERMINATE_INSTALLMENT_PLAN } from 'graphql/_modify-installment-plan-dropdown'
import { Popover, Position, Menu, toaster } from 'evergreen-ui'

import { Types, Button, ButtonProps, Icon } from 'lib'
import { useModal } from 'components/modal-provider'

export interface Props extends ButtonProps {
  installmentPlan: Types.GuarantorPlans_contact_installmentPlans
}

const ModifyInstallmentPlanDropdown = ({ installmentPlan, ...props }: Props) => {
  const showConfirmDialog = useModal('confirm')
  const showResumeInstallmentPlanDialog = useModal('resumeInstallmentPlan')

  const [pauseInstallmentPlan, pauseInstallmentPlanStatus] = useMutation<
    Types.PauseInstallmentPlan,
    Types.PauseInstallmentPlanVariables
  >(PAUSE_INSTALLMENT_PLAN, {
    variables: {
      installmentPlanId: installmentPlan.id
    },
    onCompleted: () => toaster.success(`Payment Plan successfully paused`),
    onError: () => toaster.danger('Unable to pause payment plan')
  })

  const [terminateInstallmentPlan, terminateInstallmentPlanStatus] = useMutation<
    Types.TerminateInstallmentPlan,
    Types.TerminateInstallmentPlanVariables
  >(TERMINATE_INSTALLMENT_PLAN, {
    variables: {
      installmentPlanId: installmentPlan.id
    },
    update: (cache) => {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          analytics: (existing) => ({ ...existing, installments: undefined })
        }
      })
      cache.gc()
    },
    onCompleted: () => {
      toaster.warning(`Payment plan successfully cancelled`)
    },
    onError: () => {
      toaster.danger('Unable to cancelled payment plan')
    }
  })

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={({ close }) => (
        <Menu>
          <Menu.Group>
            {[Types.InstallmentPlanStatus.ACTIVE, Types.InstallmentPlanStatus.FAILED_RETRYING].includes(
              installmentPlan.status
            ) ? (
              <Menu.Item
                onSelect={() => {
                  close()
                  showConfirmDialog({
                    onConfirm: () => pauseInstallmentPlan(),
                    title: 'Confirm Plan Pause',
                    body: 'Are you sure you want to pause this plan? Billing will be paused until you resume the plan.',
                    intent: 'danger'
                  })
                }}
              >
                Pause Billing
              </Menu.Item>
            ) : (
              <Menu.Item
                onSelect={() => {
                  close()
                  showResumeInstallmentPlanDialog({ installmentPlan })
                }}
              >
                Resume Billing
              </Menu.Item>
            )}
            <Menu.Item
              onSelect={() => {
                close()
                showConfirmDialog({
                  onConfirm: () => terminateInstallmentPlan(),
                  title: 'Confirm Plan Termination',
                  body: 'Are you sure you want to immediately terminate this plan?',
                  intent: 'danger'
                })
              }}
              intent="danger"
            >
              Cancel Plan
            </Menu.Item>
          </Menu.Group>
        </Menu>
      )}
    >
      <Button
        marginRight={16}
        {...props}
        justifyContent="space-between"
        isLoading={pauseInstallmentPlanStatus.loading || terminateInstallmentPlanStatus.loading}
      >
        Modify
        <Icon icon={['far', 'chevron-down']} marginLeft={8} />
      </Button>
    </Popover>
  )
}

export default ModifyInstallmentPlanDropdown
