import { Fragment } from 'react'
import { PieChart } from 'react-minimal-pie-chart'

import { PaneProps, Pane, Label, Pill } from 'evergreen-ui'
import { colors, toDollarsFromCents, calculateInstallmentAmounts, Types, prettyIntervalStart, getShortInterval } from 'lib'


export type Props = PaneProps & {
  installmentTemplate: {
    interval: number
    periods: number
    adminFee: number
    downPayment: number
    intervalType: Types.InstallmentIntervalType
  }
  isTruncated?: boolean
  startDate?: Date
  amount?: number
}

const InstallmentTemplate = ({ installmentTemplate, isTruncated, startDate, amount, ...props }: Props) => {
  const { interval, periods, intervalType } = installmentTemplate

  const installmentAmounts = amount && calculateInstallmentAmounts({ amount, installmentTemplate })

  return (
    <Pane display="flex" justifyContent="center" alignItems="center" {...props}>
      {[...Array(periods).fill('')].map((_el, i) => {
        let value: string
        if (installmentAmounts) {
          const { enrollmentAmount, installmentAmount } = installmentAmounts

          value =
            i === 0
              ? toDollarsFromCents(enrollmentAmount, enrollmentAmount < 100 && enrollmentAmount > -100 ? 2 : 0)
              : toDollarsFromCents(installmentAmount, installmentAmount < 100 && installmentAmount > -100 ? 2 : 0)
        } else {
          value = `${(100 / periods).toFixed(0)}%`
        }

        // truncate graphic for payment flow
        if (isTruncated) {
          if (periods > 4 && i === 2)
            return (
              <Fragment key={i}>
                <Pill color="green" textTransform="none" style={{ color: colors.blue.base }}>
                  +{periods - 3}
                </Pill>
                <SegmentedCircleDivider />
              </Fragment>
            )
          if (periods > 4 && i > 2 && i < periods - 1) return null
        }
        return (
          <Fragment key={i}>
            <SegmentedCircle
              totalSegments={periods}
              filledSegments={i + 1}
              size={48}
              label={`${
                startDate
                  ? prettyIntervalStart({ startDate: startDate || new Date(), interval, intervalType, intervalNumber: i, format: 'M/D' })
                  : i === 0
                    ? 'Today'
                    : `${i * interval} ${getShortInterval(intervalType)}${interval === 1 && i === 1 ? '' : 's'}`
              }`}
              value={value}
            />
            {i < periods - 1 && <SegmentedCircleDivider />}
          </Fragment>
        )
      })}
    </Pane>
  )
}

export default InstallmentTemplate

interface SegmentedCircleProps extends PaneProps {
  totalSegments: number
  filledSegments: number
  size?: number
  label: string
  value: string
}

const SegmentedCircle = ({
  totalSegments,
  filledSegments,
  size = 48,
  label,
  value,
  ...props
}: SegmentedCircleProps) => {
  const segmentLength = 100 / totalSegments

  const data = []

  for (let i = 0; i < totalSegments; i++) {
    data.push({ value: segmentLength, color: i < filledSegments ? colors.blue.base : 'white' })
  }

  return (
    <Pane {...props} display="flex" flexDirection="column" alignItems="center">
      <Label color="muted" marginBottom={4}>
        {label}
      </Label>
      <Pane
        width={size}
        height={size}
        borderWidth="2px"
        borderStyle="solid"
        borderColor={colors.green.light}
        borderRadius="100%"
        padding={2}
        marginBottom={4}
      >
        <PieChart data={data} lengthAngle={-360} startAngle={-90} segmentsShift={2} radius={48} />
      </Pane>
      <Label>{value}</Label>
    </Pane>
  )
}

const SegmentedCircleDivider = () => <Pane flex={1} maxWidth="80px" height="2px" backgroundColor={colors.green.light} />
