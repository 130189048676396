import { CardProps, Pane, Heading, Table, TableRowProps, Text } from 'evergreen-ui'

import { CardHeader, Card, toDollarsFromCents, colors, InfoTooltip, calculateInstallmentAmounts, Types, prettyIntervalStart } from './'

export type Props = CardProps & {
  title?: string
  amount: number
  convenienceFeeAmount?: number
  installmentTemplate: {
    interval: number
    intervalType: Types.InstallmentIntervalType
    periods: number
    adminFee: number
    downPayment: number
  } | null
  isPayment?: boolean
}

const CheckoutTableCard = ({
  title,
  amount,
  installmentTemplate,
  convenienceFeeAmount,
  isPayment = false,
  ...props
}: Props) => {
  let rows: React.ReactNode

  if (installmentTemplate) {
    if (typeof convenienceFeeAmount === 'number')
      throw Error('Cannot pass both installmentTemplate and convenience fee')

    const {
      planTotal,
      downPaymentAmount,
      installmentAmount,
      adminFeeAmount,
      enrollmentAmount
    } = calculateInstallmentAmounts({ amount, installmentTemplate })

    rows = (
      <>
        {!isPayment && (
          <CheckoutTableRow
            label="Plan Total"
            isBold
            value={toDollarsFromCents(planTotal, 2)}
            borderBottom={`solid 1px ${colors.border.default}`}
          />
        )}

        {adminFeeAmount > 0 && (
          <CheckoutTableRow
            label="Enrollment Fee"
            value={toDollarsFromCents(adminFeeAmount, 2)}
            backgroundColor={isPayment ? undefined : colors.blue.lightest}
          />
        )}

        {downPaymentAmount > 0 && (
          <CheckoutTableRow
            label="Down Payment"
            value={toDollarsFromCents(downPaymentAmount, 2)}
            backgroundColor={isPayment ? undefined : colors.blue.lightest}
          />
        )}

        <CheckoutTableRow
          label="First Installment"
          value={toDollarsFromCents(installmentAmount, 2)}
          backgroundColor={isPayment ? undefined : colors.blue.lightest}
        />

        <CheckoutTableTotalRow
          label="Due Today:"
          value={enrollmentAmount}
          largeTotal={isPayment}
          backgroundColor={isPayment ? undefined : colors.blue.lightest}
        />

        {!isPayment && (
          <>
            <CheckoutTableRow
              label="Remaining"
              value={toDollarsFromCents(planTotal - enrollmentAmount, 2)}
              isMuted
              borderTop={`solid 1px ${colors.border.default}`}
            />
            <CheckoutTableRow
              label={`Next Installment (${prettyIntervalStart({ interval: installmentTemplate.interval, intervalNumber: 1, intervalType: installmentTemplate.intervalType, startDate: new Date(), format: 'M/D' })})`}
              value={toDollarsFromCents(installmentAmount, 2)}
              isMuted
            />
            <CheckoutTableRow
              label="Remaining Installments"
              value={(installmentTemplate.periods - 1).toString()}
              isMuted
            />
          </>
        )}
      </>
    )
  } else {
    if (typeof convenienceFeeAmount !== 'number') throw Error('Convenience fee must be included for one-time payments')

    const total = amount + convenienceFeeAmount

    rows = (
      <>
        <CheckoutTableRow label="Balance Paid" value={toDollarsFromCents(amount, 2)} />
        {convenienceFeeAmount > 0 && (
          <Table.Row height={24} borderBottom="none">
            <Table.TextCell flex="3" textProps={{ size: 400 }}>
              Convenience Fee
              <InfoTooltip
                content="Processing fee charged by billing provider. No part of this fee goes to our practice."
                fontSize={11}
                marginLeft={4}
              />
            </Table.TextCell>
            <Table.TextCell
              textAlign="right"
              flexBasis={100}
              flexShrink={0}
              flexGrow={0}
              style={{ fontVariantNumeric: 'tabular-nums' }}
              textProps={{ size: 400 }}
            >
              {toDollarsFromCents(convenienceFeeAmount, 2)}
            </Table.TextCell>
          </Table.Row>
        )}
        <CheckoutTableTotalRow value={total} largeTotal />
      </>
    )
  }

  return (
    <Card padding={0} {...props}>
      {title && (
        <CardHeader>
          <Heading>{title}</Heading>
        </CardHeader>
      )}
      <Pane paddingY={title ? 4 : 8}>
        <Table width="100%" style={{ border: `none` }}>
          {isPayment && (
            <Table.Row height="24px">
              <Table.TextCell textProps={{ textTransform: 'uppercase', textAlign: 'right' }}>Amount</Table.TextCell>
            </Table.Row>
          )}
          {rows}
        </Table>
      </Pane>
    </Card>
  )
}

const CheckoutTableRow = ({
  label,
  value,
  isBold = false,
  isMuted = false,
  ...props
}: { label: string; value: string; isBold?: boolean; isMuted?: boolean } & TableRowProps) => (
  <Table.Row height={24} borderBottom="none" {...props}>
    <Table.TextCell
      flex="3"
      textProps={{
        size: 400,
        fontWeight: isBold ? 600 : undefined,
        color: isBold ? colors.blue.base : isMuted ? 'muted' : undefined
      }}
    >
      {label}
    </Table.TextCell>
    <Table.TextCell
      textAlign="right"
      flexBasis={100}
      flexShrink={0}
      flexGrow={0}
      style={{ fontVariantNumeric: 'tabular-nums' }}
      textProps={{ size: 400, color: isBold ? colors.blue.base : isMuted ? 'muted' : undefined }}
    >
      {value}
    </Table.TextCell>
  </Table.Row>
)

const CheckoutTableTotalRow = ({
  value,
  label = 'Total',
  largeTotal,
  ...props
}: {
  value: number
  label?: string
  largeTotal?: boolean
} & TableRowProps) =>
  largeTotal ? (
    <Table.Row
      height={32}
      borderBottom="none"
      borderTop={`solid 1px ${colors.border.default}`}
      marginBottom={-4}
      {...props}
    >
      <Table.TextCell textProps={{ fontWeight: 700, size: 500 }} textAlign="right">
        <Text fontWeight={700} size={500} textAlign="right" marginRight={6}>
          {label}
        </Text>
        {toDollarsFromCents(value, 2)}
      </Table.TextCell>
    </Table.Row>
  ) : (
    <CheckoutTableRow label={label} isBold value={toDollarsFromCents(value, 2)} {...props} />
  )

export default CheckoutTableCard
