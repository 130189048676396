import { Heading, Pane } from 'evergreen-ui'
import { Icon } from 'lib'

const NotificationTestCompleted = () => {
  return (
    <Pane display="flex" flexDirection="column" alignItems="center" marginY={24}>
      <Icon icon={['fad', 'check']} color="success" size="5x" marginBottom={16} />
      <Heading marginBottom={16}>Test Notification Sent!</Heading>
    </Pane>
  )
}

export default NotificationTestCompleted
