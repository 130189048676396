import { forwardRef } from 'react'

import { Button, ButtonProps } from 'evergreen-ui'

import { Icon } from 'lib'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = ButtonProps & {
  icon: IconProp
}

const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ icon, type = 'button', isLoading, width = 32, intent, color, opacity, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        {...props}
        isLoading={isLoading}
        display="flex"
        justifyContent="center"
        paddingLeft={12}
        paddingRight={isLoading ? 0 : 12}
        type={type}
        width={width}
        data-testId={`${icon}-button`}
      >
        {!isLoading && <Icon icon={icon} intent={intent} color={color} opacity={opacity} />}
      </Button>
    )
  }
)

export default IconButton
