import { useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { GET_GUARANTOR_BILLING_OUTREACH, SET_GUARANTOR_OUTREACH_OPTIONS } from 'graphql/_guarantor-sheet'

import { Text, Pane, Switch, toaster, Badge } from 'evergreen-ui'
import { Types, Card, CardHeader, Spinner } from 'lib'

import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'
import { getPrettyBlockedReason } from 'utility/label-maps'

import receiptIllustration from 'assets/img/receipt.svg'
import OutreachTimeline from './OutreachTimeline'
import ContactIndicator from 'components/contact-indicator'

export type Props = { guarantor: Types.GuarantorBilling_contact }

const GuarantorBillingOutreach = ({ guarantor }: Props) => {
  const showConfirm = useModal('confirm')
  const global = useGlobal()

  const { data } = useQuery<Types.GuarantorBillingOutreach, Types.GuarantorBillingOutreachVariables>(
    GET_GUARANTOR_BILLING_OUTREACH,
    {
      variables: {
        id: guarantor.id
      }
    }
  )

  const { suppressed, allowed } = guarantor
  const [suppressedState, setSuppressedState] = useState(suppressed)
  const [allowedState, setAllowedState] = useState(allowed)

  const [setGuarantorOutreachOptions] = useMutation<
    Types.SetGuarantorOutreachOptions,
    Types.SetGuarantorOutreachOptionsVariables
  >(SET_GUARANTOR_OUTREACH_OPTIONS, {
    onCompleted: () => toaster.success('Outreach options successfully updated'),
    onError: () => {
      toaster.danger(`Unable to update options`)
      setSuppressedState(suppressed)
      setAllowedState(allowed)
    },
    update: (cache, { data: responseData }) => {
      // Relcalculate balance counts if status changing
      if (
        responseData?.setGuarantorOutreachOptions.balance.engagementDetails?.status !==
        data?.contact.balance.engagementDetails?.status
      ) {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            balanceMetrics: (_existing, { DELETE }) => DELETE,
            balanceAssistant: (_existing, { DELETE }) => DELETE,
            balancesConnection: (_existing, { DELETE }) => DELETE
          }
        })

        cache.gc()
      }
    }
  })

  if (!data) return <Spinner delay={0} marginTop={36} />

  const { contact } = data
  const { outreach, balance } = contact

  const blockedReasons = balance.engagementDetails?.blockedReasons

  return (
    <Pane display="grid" gap={8}>
      <Card elevation={0} padding={0}>
        {blockedReasons && blockedReasons.length > 0 && (
          <Pane display="flex" alignItems="stretch" elevation={0} padding={16}>
            <Text size={500} marginRight={4}>
              Currently Blocked:
            </Text>
            <Pane display="flex" alignItems="center" flexWrap="wrap" marginBottom={-4}>
              {blockedReasons.map((reason, i) => {
                const label = getPrettyBlockedReason(reason)
                return (
                  <Badge key={i} marginX={4} marginBottom={4} color="red">
                    {label}
                  </Badge>
                )
              })}
            </Pane>
          </Pane>
        )}
        <Pane padding={16}>
          <Pane display="flex" justifyContent="space-between">
            <Text size={500}>Manual Block</Text>
            <Switch
              height={20}
              checked={suppressedState}
              onChange={() => {
                const updateSuppressed = () => {
                  setGuarantorOutreachOptions({
                    variables: { contactId: guarantor.id, suppressed: !suppressedState, allowed: allowedState }
                  })
                  setSuppressedState(!suppressedState)
                }

                if (suppressedState === true) updateSuppressed()
                // Confirm if setting to true
                else
                  showConfirm({
                    title: 'Confirm Manual Block',
                    body:
                      'Are you sure you want to add this patient to your outreach block list?  You can unblock at any time.',
                    onConfirm: updateSuppressed
                  })
              }}
            />
          </Pane>
          {global.account.hasAutoBlockRules && !suppressedState && (
            <Pane display="flex" justifyContent="space-between" marginTop={12}>
              <Text size={500}>Ignore Auto-Block Rules</Text>
              <Switch
                height={20}
                checked={allowedState}
                onChange={() => {
                  const updateAllowed = () => {
                    setGuarantorOutreachOptions({
                      variables: { contactId: guarantor.id, allowed: !allowedState, suppressed: suppressedState }
                    })
                    setAllowedState(!allowedState)
                  }

                  if (allowedState === true) updateAllowed()
                  // Confirm if setting to true
                  else
                    showConfirm({
                      title: 'Confirm Exclusion of Auto-Block Rules',
                      body:
                        'Please confirm that you would like this patient to be excluded from your practices auto-block settings.  For example, if you have set a rule to block patients with a specific insurance type, this will no longer apply to this patient and they will be eligible for outreach if they have an outstanding balance.  You can manually block this patient at any time.',
                      onConfirm: updateAllowed
                    })
                }}
              />
            </Pane>
          )}
        </Pane>
      </Card>
      <ContactIndicator.Card contact={contact} />
      {outreach?.timeline.length ? (
        <Card elevation={0} padding={0}>
          <CardHeader>
            <Text size={500}>Outreach History</Text>
          </CardHeader>

          <Pane padding={8}>
            <OutreachTimeline timeline={outreach.timeline} />
          </Pane>
        </Card>
      ) : (
        <Pane display="flex" flexDirection="column" alignItems="center" marginY={48}>
          <img src={receiptIllustration} height="100px" alt="Receipt Illustration" />
          <Text marginTop={24} size={500} textAlign="center" width="70%">
            This patient has no outreach history
          </Text>
        </Pane>
      )}
    </Pane>
  )
}

export default GuarantorBillingOutreach
