import React from 'react'
import 'ui-box/dist/src/types/box-types'

import { Text, Strong, TextProps } from 'evergreen-ui'
import { calculateInstallmentAmounts, prettyInterval, toDollarsFromCents, Types } from 'lib'

const TemplateDescription = ({
  amount,
  installmentTemplate,
  boldNumbers = false,
  prepend,
  ...props
}: {
  amount: number
  installmentTemplate: {
    interval: number
    periods: number
    adminFee: number
    downPayment: number
    intervalType: Types.InstallmentIntervalType
  }
  boldNumbers?: boolean
  prepend?: string | React.ReactNode
} & TextProps) => {
  const { enrollmentAmount, installmentAmount } = calculateInstallmentAmounts({ amount, installmentTemplate })

  const defaultProps: TextProps = {
    size: 400,
    textAlign: 'center'
  }

  props = { ...defaultProps, ...props }

  if (boldNumbers) {
    return (
      <Text {...props}>
        {prepend} <Strong>{toDollarsFromCents(enrollmentAmount, 2)}</Strong> today, then{' '}
        <Strong>{toDollarsFromCents(installmentAmount, 2)}</Strong> auto-charged every{' '}
        <Strong>{prettyInterval(installmentTemplate.interval, installmentTemplate.intervalType)}</Strong> for the next{' '}
        <Strong>{prettyInterval((installmentTemplate.periods - 1) * installmentTemplate.interval, installmentTemplate.intervalType)}</Strong>.
      </Text>
    )
  } else
    return (
      <Text {...props}>
        {prepend} {toDollarsFromCents(enrollmentAmount, 2)} today, then {toDollarsFromCents(installmentAmount, 2)}{' '}
        auto-charged every {prettyInterval(installmentTemplate.interval, installmentTemplate.intervalType)} for the next{' '}
        {prettyInterval((installmentTemplate.periods - 1) * installmentTemplate.interval, installmentTemplate.intervalType)}.
      </Text>
    )
}

export default TemplateDescription
