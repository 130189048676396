import { Pane, PaneProps, Text } from 'evergreen-ui'
import { Icon } from './'
import { InfoTooltip } from './'
import { colors } from 'lib'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = PaneProps & {
  label: string
  onClick?: () => void
  icon?: IconProp
  tooltip?: string
}

const Divider = ({ label, icon, tooltip, onClick, ...props }: Props) => {
  return (
    <Pane marginY={16} display="flex" justifyContent="center" alignItems="center" {...props}>
      <Pane height={1} flexGrow={1} backgroundColor={colors.border.default}></Pane>
      <Pane
        onClick={onClick}
        display="flex"
        justifyContent="center"
        alignItems="center"
        cursor={onClick ? 'pointer' : 'auto'}
        marginX={16}
      >
        <Text size={300}>{label.toUpperCase()}</Text>

        {icon && <Icon color={colors.neutral.muted} icon={icon} marginLeft={8} />}
        {tooltip && <InfoTooltip content={tooltip} marginLeft={8} />}
      </Pane>

      <Pane height={1} flexGrow={1} backgroundColor={colors.border.default}></Pane>
    </Pane>
  )
}

export default Divider
