import moment from 'moment'

import { CardProps, Pane, Text, Heading, Badge } from 'evergreen-ui'
import { Types, Card, Icon, toDollarsFromCents } from 'lib'

import { useModal } from 'components/modal-provider'

export interface Props extends CardProps {
  invoice: {
    id: string
    stripeId_invoice?: string

    stripeId_subscription?: string
    date: number
    amount: number
    isMembership: boolean
    status: Types.InvoiceStatus
  }
}

const Invoice = ({ invoice, ...props }: Props) => {
  const { isMembership, date, amount, status, stripeId_subscription, stripeId_invoice } = invoice

  const showInvoiceSheet = useModal('invoice')

  const isUpcoming = status === Types.InvoiceStatus.DRAFT
  if (isUpcoming && !stripeId_subscription) throw Error('Stripe subscriptionId required')
  if (!isUpcoming && !stripeId_invoice) throw Error('Stripe invoiceId required')
  const intent = isUpcoming ? ('default' as const) : ('danger' as const)

  return (
    <Card
      {...props}
      onClick={() => showInvoiceSheet({ invoiceId: stripeId_invoice, subscriptionId: stripeId_subscription })}
      paddingY={12}
      paddingX={16}
      hoverElevation={1}
      cursor="pointer"
      flexDirection="row"
      alignItems="center"
    >
      <Pane width="36px">
        <Icon icon={['fal', isUpcoming ? 'calendar-alt' : 'ban']} color={intent} size="lg" />
      </Pane>

      <Pane flexGrow={1}>
        <Text size={300} marginBottom={4}>
          {moment.unix(date).format('M/D/YYYY')}
        </Text>
        <Heading size={500}>{toDollarsFromCents(amount, 2)}</Heading>
      </Pane>
      <Badge color={isUpcoming ? 'blue' : 'red'}>
        {isUpcoming ? 'Scheduled' : 'Failed'} {isMembership ? 'Member Fee' : 'Payment'}
      </Badge>
      <Icon icon={['far', 'chevron-right']} color={intent} marginLeft={14} />
    </Card>
  )
}

export default Invoice
