export const calculateInstallmentAmounts = ({
  amount,
  installmentTemplate
}: {
  amount: number
  installmentTemplate: {
    periods: number
    downPayment: number
    adminFee: number
  }
}) => {
  const { periods, downPayment: downPaymentPercent, adminFee: adminFeePercent } = installmentTemplate

  const downPaymentAmount = Math.round(amount * (downPaymentPercent / 10000))
  const adminFeeAmount = Math.round(amount * (adminFeePercent / 10000))

  // Ceil to avoid underpaying balance (and installmentsPending persisting)
  const installmentAmount = Math.ceil((amount - downPaymentAmount) / periods)

  const firstPaymentAmount = downPaymentAmount + installmentAmount
  
  const faceAmount = downPaymentAmount + installmentAmount * periods

  return {
    firstPaymentAmount,
    enrollmentAmount: firstPaymentAmount + adminFeeAmount,
    downPaymentAmount,
    installmentAmount,
    adminFeeAmount,
    faceAmount,
    planTotal: adminFeeAmount + faceAmount
  }
}
