import gql from 'graphql-tag'

export const GET_BALANCE_FILTER = gql`
  query BalanceFilter($filterId: ID!) {
    balanceFilter(filterId: $filterId) {
      id
      minBalance
      maxBalance
      allowL30
      allowL60
      allowL90
      allowG90
    }
  }
`

export const UPDATE_BALANCE_FILTER = gql`
  mutation UpdateBalanceFilter($filterId: ID!, $balanceFilter: PartialBalanceFilterInput!) {
    updateBalanceFilter(filterId: $filterId, balanceFilter: $balanceFilter) {
      id
      minBalance
      maxBalance
      allowL30
      allowL60
      allowL90
      allowG90
    }
  }
`
