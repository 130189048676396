import styled from 'styled-components/macro'

import { useMutation } from '@apollo/client'
import { CREATE_USER, UPDATE_USER, DELETE_USER, USER_SHEET_RESEND_INVITE } from 'graphql/_user-sheet'
import { GET_ACCOUNT_USERS } from 'graphql/_account-users'
import { GET_GROUP_USERS } from 'graphql/_group-users'
import { RESET_USER_PASSWORD } from 'graphql/_reset-user-password'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Avatar, Text, Label, Heading, toaster, Position } from 'evergreen-ui'

import { Types, Card, Button, FormError, Switch, CardHeader, DoubleTextInput, TextInputField, RadioGroup } from 'lib'

import { useModal } from 'components/modal-provider'
import RoleTooltip from 'components/role-tooltip'

import { useCheckMinRole } from 'utility/roles'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  user?: Types.AccountUsers_account_users | Types.GroupUsers_group_users
  groupId?: string
}

const UserSheet = ({ isShown, setIsShown, user, groupId }: Props) => {
  const isCreatingUser = !user
  const checkMinRole = useCheckMinRole()

  const showConfirmDialog = useModal('confirm')

  const [resendInvite, resendInviteStatus] = useMutation<Types.UsersTableResendInvite>(USER_SHEET_RESEND_INVITE, {
    onCompleted: () => toaster.success(`Invitation successfully resent`),
    onError: () => toaster.danger(`Unable to resend invitation`)
  })

  const [resetPassword, resetPasswordStatus] = useMutation<Types.ResetUserPassword, Types.ResetUserPasswordVariables>(
    RESET_USER_PASSWORD,
    {
      onCompleted: () => toaster.success('Password reset email delivered if email on file'),

      onError: () => toaster.danger('Unable to deliver reset password email')
    }
  )

  const [createUser, createStatus] = useMutation<Types.CreateUser, Types.CreateUserVariables>(CREATE_USER, {
    update: (cache, { data }) => {
      const accountUsersData = cache.readQuery<Types.AccountUsers>({ query: GET_ACCOUNT_USERS })
      const groupUsersData =
        typeof groupId === 'string' &&
        cache.readQuery<Types.GroupUsers>({
          query: GET_GROUP_USERS,
          variables: { groupId }
        })

      if (data) {
        if (accountUsersData) {
          cache.writeQuery({
            query: GET_ACCOUNT_USERS,
            data: {
              ...accountUsersData,
              account: {
                ...accountUsersData.account,
                users: accountUsersData.account.users.concat([
                  { ...data.createUser, isGroupUserForCurrentAccount: !!groupId }
                ])
              }
            }
          })
        }

        if (groupUsersData) {
          cache.writeQuery({
            query: GET_GROUP_USERS,
            data: {
              ...groupUsersData,
              group: {
                ...groupUsersData.group,
                users: groupUsersData.group.users.concat([data.createUser])
              }
            }
          })
        }
      }
    },
    onCompleted: () => {
      setIsShown(false)
      toaster.success(`User successfully created!`)
    },
    onError: (err) => {
      if (err.message === 'The email address is already in use by another account.')
        toaster.danger('Unable to create user', { description: 'Email address is already in use' })
      else toaster.danger('Unable to create user')
    }
  })

  const [updateUser, updateStatus] = useMutation<Types.UpdateUser, Types.UpdateUserVariables>(UPDATE_USER, {
    onCompleted: () => toaster.success(`User successfully updated!`),
    onError: () => toaster.danger('Unable to update user')
  })

  const [deleteUser, deleteStatus] = useMutation<Types.DeleteUser, Types.DeleteUserVariables>(DELETE_USER, {
    variables: { id: user?.id ?? '' },
    update: (cache, { data }) => {
      const accountUsersData = cache.readQuery<Types.AccountUsers>({ query: GET_ACCOUNT_USERS })
      const groupUsersData =
        typeof groupId === 'string' &&
        cache.readQuery<Types.GroupUsers>({
          query: GET_GROUP_USERS,
          variables: { groupId }
        })

      if (data) {
        if (accountUsersData) {
          cache.writeQuery({
            query: GET_ACCOUNT_USERS,
            data: {
              ...accountUsersData,
              account: {
                ...accountUsersData.account,
                users: accountUsersData.account.users.filter((user) => user.id !== data.deleteUser.id)
              }
            }
          })
        }

        if (groupUsersData) {
          cache.writeQuery({
            query: GET_GROUP_USERS,
            data: {
              ...groupUsersData,
              group: {
                ...groupUsersData.group,
                users: groupUsersData.group.users.filter((user) => user.id !== data.deleteUser.id),
                accountUsers: groupUsersData.group.accountUsers.filter((user) => user.id !== data.deleteUser.id)
              }
            }
          })
        }
      }
    },
    onCompleted: () => {
      setIsShown(false)
      toaster.success(`User successfully deleted!`)
    },
    onError: () => toaster.danger('Unable to delete user')
  })

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      <Formik
        initialValues={
          user
            ? { ...user, email: user.google.email, role: user.google.role }
            : {
                firstName: '',
                lastName: '',
                email: '',
                role: Types.UserRole.BASIC as Types.UserRole,
                notify_enrolled: true,
                notify_paymentCompleted: true,
                notify_invoiceFailed: true,
                notify_eodPracticeReport: false
              }
        }
        onSubmit={({ ...userFields }) => {
          showConfirmDialog({
            body: `Are you sure you want to ${user ? 'update' : 'create'} this user?`,
            onConfirm: () => {
              if (user) {
                updateUser({
                  variables: { id: user.id, ...userFields }
                })
              } else {
                createUser({
                  variables: { groupId, ...userFields }
                })
              }
            }
          })
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: Yup.string().required('Email is required').email('Please enter a valid email'),
          role: Yup.mixed().oneOf(['BASIC', 'ADMIN', 'STANDARD', 'SUPERADMIN']),
          notify_enrolled: Yup.boolean().required(),
          notify_paymentCompleted: Yup.boolean().required(),
          notify_invoiceFailed: Yup.boolean().required(),
          notify_eodPracticeReport: Yup.boolean().required()
        })}
      >
        <Form style={{ height: '100%' }}>
          <SheetLayout>
            {!isCreatingUser ? (
              <CardHeader gridArea="header">
                {/* Match color to table */}
                <Avatar name={`${user.firstName} ${user.lastName}`} size={40} />
                <Pane marginLeft={16}>
                  <Heading size={600}>
                    {user.firstName} {user.lastName}
                  </Heading>
                  <Text size={400}>User Details</Text>
                </Pane>
              </CardHeader>
            ) : (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Add {groupId ? 'Group ' : ''}User</Heading>
                <Paragraph size={400}>This user will be granted access to the dashboard</Paragraph>
              </CardHeader>
            )}

            <Pane gridArea="body" overflow="auto" background="blueTint">
              <Card backgroundColor="white" elevation={0} padding={0} margin={16}>
                <CardHeader gridArea="header">
                  <Heading size={500} flex={1} textAlign="center">
                    User Profile
                  </Heading>
                </CardHeader>
                <Pane padding={24}>
                  <Label marginBottom={4} alignSelf="flex-start">
                    Name
                  </Label>
                  <DoubleTextInput name="firstName" half="top" width="100%" type="text" placeholder="First Name" />
                  <DoubleTextInput name="lastName" half="bottom" width="100%" type="text" placeholder="Last Name" />

                  <TextInputField name="email" label="Email" type="email" placeholder="john@mydental.co" />

                  {user?.google.role !== Types.UserRole.SUPERADMIN && (
                    <>
                      <Pane display="flex">
                        <Label>Role</Label>
                        <RoleTooltip marginLeft={8} isGroup={!!groupId} position={Position.TOP_LEFT} />
                      </Pane>
                      <RadioGroup
                        name="role"
                        size={16}
                        marginBottom={0}
                        options={[
                          {
                            label: 'Admin',
                            value: Types.UserRole.ADMIN,
                            isDisabled: !checkMinRole(Types.UserRole.ADMIN)
                          },
                          {
                            label: 'Standard',
                            value: Types.UserRole.STANDARD,
                            isDisabled: !checkMinRole(Types.UserRole.STANDARD)
                          },
                          ...(groupId ? [] : [{ label: 'Basic', value: Types.UserRole.BASIC }])
                        ]}
                      />
                    </>
                  )}

                  {user && user.google.verified && (
                    <Pane marginTop={16}>
                      <Label display="inline-block" marginBottom={4}>
                        Password
                      </Label>
                      <Button
                        isLoading={resetPasswordStatus.loading}
                        height={48}
                        width="100%"
                        justifyContent="center"
                        iconBefore={['fas', 'paper-plane']}
                        onClick={() => {
                          showConfirmDialog({
                            body: `Are you sure you want to send a password reset?`,
                            onConfirm: () => resetPassword({ variables: { email: user.google.email } })
                          })
                        }}
                      >
                        Send Reset Password Email
                      </Button>
                    </Pane>
                  )}
                  {user && !user.google.verified && (
                    <Pane marginTop={16}>
                      <Label marginBottom={4}>Invitation</Label>
                      <Button
                        isLoading={resendInviteStatus.loading && !resendInviteStatus.data}
                        height={48}
                        width="100%"
                        justifyContent="center"
                        iconBefore={['fas', 'paper-plane']}
                        onClick={() => resendInvite({ variables: { id: user.id } })}
                      >
                        Resend Invitation Email
                      </Button>
                    </Pane>
                  )}
                  <FormError />
                </Pane>
              </Card>
              <Card backgroundColor="white" elevation={0} padding={0} margin={16}>
                <CardHeader gridArea="header">
                  <Heading size={500} flex={1} textAlign="center">
                    Notifications
                  </Heading>
                </CardHeader>
                <Pane padding={24} display="grid" gap={8} justifyContent="center">
                  <Pane display="flex" alignItems="center">
                    <Switch name="notify_enrolled" height={16} marginBottom={0} marginRight={8} />
                    <Text>New Member Enrollment</Text>
                  </Pane>
                  <Pane display="flex" alignItems="center">
                    <Switch name="notify_paymentCompleted" height={16} marginBottom={0} marginRight={8} />
                    <Text>Completed Balance Payment</Text>
                  </Pane>
                  <Pane display="flex" alignItems="center">
                    <Switch name="notify_invoiceFailed" height={16} marginBottom={0} marginRight={8} />
                    <Text>Failed Payment Method</Text>
                  </Pane>
                  <Pane display="flex" alignItems="center">
                    <Switch name="notify_eodPracticeReport" height={16} marginBottom={0} marginRight={8} />
                    <Text>EOD Practice Report</Text>
                  </Pane>
                </Pane>
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {user ? (
                <Pane display="flex" justifyContent="space-between">
                  <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    visibility={updateStatus.loading ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this user?',
                        onConfirm: () => deleteUser(),
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>
                  <Button
                    autoFocus
                    isLoading={updateStatus.loading}
                    visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                <Button
                  isLoading={createStatus.loading || !!createStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                  iconBefore={['fas', 'user-plus']}
                >
                  Add User
                </Button>
              )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default UserSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
