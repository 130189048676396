import { IconName } from '@fortawesome/fontawesome-svg-core'

import { Types, colors } from 'lib'

// Keep in Sync with API
export const getPrettyBlockedReason = (blockedReason: Types.BalanceBlockedReason) => {
  return {
    [Types.BalanceBlockedReason.SUPPRESSED]: 'Manual Block',
    [Types.BalanceBlockedReason.SUPPRESSED_NO_BALANCE]: 'Manual Block',
    [Types.BalanceBlockedReason.CONTACT_MISSING]: 'Missing Contact Details',
    [Types.BalanceBlockedReason.CONTACT_FAILED]: 'Contact Failed',
    [Types.BalanceBlockedReason.DOB_MISSING]: 'Missing DOB',
    [Types.BalanceBlockedReason.RESTRICTED_INSURANCE]: 'Insurance Rule',
    [Types.BalanceBlockedReason.RESTRICTED_BILLING_TYPE]: 'Billing Type Rule',
    [Types.BalanceBlockedReason.RESTRICTED_CODE]: 'Code Rule',
    [Types.BalanceBlockedReason.RESTRICTED_AGE]: 'Age Rule',
    [Types.BalanceBlockedReason.PAYMENT_PLAN]: 'Payment Plan',
    [Types.BalanceBlockedReason.SECONDARY_LOCATION]: 'Secondary Location',
    [Types.BalanceBlockedReason.RESTRICTED_CLAIM_STATUS]: 'Restricted Claim Rule'
  }[blockedReason]
}

export const getPrettyBalanceStatus = (status: Types.BalanceEngagementStatus) => {
  return {
    [Types.BalanceEngagementStatus.ELIGIBLE]: 'Outstanding',
    [Types.BalanceEngagementStatus.SCHEDULED]: 'Scheduled',
    [Types.BalanceEngagementStatus.ENGAGED]: 'Engaged',
    [Types.BalanceEngagementStatus.FOLLOW_UP]: 'Follow Up',
    [Types.BalanceEngagementStatus.BLOCKED]: 'Blocked'
  }[status]
}

export const getBillingStatusColor = (status: Types.BalanceEngagementStatus) => {
  return {
    [Types.BalanceEngagementStatus.ELIGIBLE]: colors.orange.base,
    [Types.BalanceEngagementStatus.SCHEDULED]: colors.yellow.base,
    [Types.BalanceEngagementStatus.ENGAGED]: colors.blue.base,
    [Types.BalanceEngagementStatus.FOLLOW_UP]: colors.orange.base,
    [Types.BalanceEngagementStatus.BLOCKED]: colors.red.base
  }[status]
}

export const getBalanceBadgeColor = (status: Types.BalanceEngagementStatus) => {
  return {
    [Types.BalanceEngagementStatus.ELIGIBLE]: 'orange' as const,
    [Types.BalanceEngagementStatus.SCHEDULED]: 'yellow' as const,
    [Types.BalanceEngagementStatus.ENGAGED]: 'blue' as const,
    [Types.BalanceEngagementStatus.FOLLOW_UP]: 'orange' as const,
    [Types.BalanceEngagementStatus.BLOCKED]: 'red' as const
  }[status]
}

export const outreachEvents = {
  START: {
    label: 'Outreach Started',
    color: colors.blue.base,
    icon: 'plane-departure' as IconName
  },
  END: {
    label: 'Outreach Ended',
    color: colors.blue.base,
    icon: 'plane-arrival' as IconName
  },
  [Types.OutreachTimelineActionType.EMAIL_DELIVERED]: {
    label: 'Email Delivered',
    color: colors.green.base,
    icon: 'envelope' as IconName
  },
  [Types.OutreachTimelineActionType.EMAIL_FAILED]: {
    label: 'Email Failed',
    color: colors.red.base,
    icon: 'ban' as IconName
  },
  [Types.OutreachTimelineActionType.SMS_DELIVERED]: {
    label: 'SMS Delivered',
    color: colors.green.base,
    icon: 'comment-alt-lines' as IconName
  },
  [Types.OutreachTimelineActionType.SMS_FAILED]: {
    label: 'SMS Failed',
    color: colors.red.base,
    icon: 'ban' as IconName
  },
  [Types.OutreachTimelineActionType.SMS_ATTEMPTED]: {
    label: 'SMS Attempted',
    color: colors.yellow.base,
    icon: 'comment-alt-lines' as IconName
  },
  [Types.OutreachTimelineActionType.EMAIL_ATTEMPTED]: {
    label: 'Email Attempted',
    color: colors.yellow.base,
    icon: 'comment-alt-lines' as IconName
  },

  [Types.OutreachTimelineActionType.LETTER_PENDING]: {
    label: 'Letter Pending',
    color: colors.green.base,
    icon: 'mailbox-flag-up' as IconName
  },

  [Types.OutreachTimelineActionType.LETTER_IN_PRODUCTION]: {
    label: 'Letter In Production',
    color: colors.green.base,
    icon: 'mailbox-flag-up' as IconName
  },

  [Types.OutreachTimelineActionType.LETTER_MAILED]: {
    label: 'Mail Sent',
    color: colors.green.base,
    icon: 'mailbox-flag-up' as IconName
  },

  [Types.OutreachTimelineActionType.LETTER_DELIVERED]: {
    label: 'Mail Delivered',
    color: colors.green.base,
    icon: 'mailbox-flag-up' as IconName
  },

  [Types.OutreachTimelineActionType.LETTER_RETURNED]: {
    label: 'Mail Returned',
    color: colors.red.base,
    icon: 'mailbox-flag-up' as IconName
  },

  [Types.OutreachTimelineActionType.PAYMENT]: {
    label: 'Payment Processed',
    color: colors.green.base,
    icon: 'dollar-sign' as IconName
  }
}
