import { createContext, useContext } from 'react'
import { useDebouncedCallback } from 'use-debounce'

const Context = createContext({} as (cb: () => void) => void)

export const GlobalDebouncedCallbackProvider = ({ children }: { children: React.ReactNode }) => {
  const [debouncedCallback] = useDebouncedCallback((cb: () => void) => {
    cb()
  }, 10000)

  return <Context.Provider value={debouncedCallback}>{children}</Context.Provider>
}

export const useGlobalDebouncedCallback = () => useContext(Context)
