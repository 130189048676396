import { Spinner as EvergreenSpinner, SpinnerProps } from 'evergreen-ui'

export type Props = SpinnerProps & {
  providerView?: boolean
  memberView?: boolean
}

const Spinner = ({ providerView, memberView, delay = 600, size = 36, marginX = 'auto', ...props }: Props) => {
  if (providerView) {
    props = { marginTop: 'calc(50vh - 25px - 18px)', ...props }
  }

  if (memberView) {
    props = { marginTop: 'calc(50vh - 40px - 18px)', ...props }
  }

  return <EvergreenSpinner delay={delay} size={size} marginX={marginX} {...props} />
}

export default Spinner
