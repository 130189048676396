import { useMutation } from '@apollo/client'
import { GENERATE_LETTER_PREVIEW } from 'graphql/_letter-preview-button'

import { Button, ButtonProps, mergeVariables, Types, IconButton } from 'lib'

import { useGlobal } from 'components/global-provider'
import { getTestVariables } from 'utility/notification-template-variables'

export interface Props extends Omit<ButtonProps, 'title'> {
  contactId: string | null
  layout: Types.NotificationLayout
  title?: string | null
  iconButton?: boolean
  body: string
}

const LetterPreviewButton = ({
  contactId,
  title,
  body,
  layout,
  children = 'Preview Letter',
  iconButton,
  iconBefore,
  iconAfter,
  ...props
}: Props) => {
  const { account } = useGlobal()
  const variables = getTestVariables(account)

  const mergedTemplate = {
    title: mergeVariables(title || '', variables),
    body: mergeVariables(body, variables)
  }

  const [generatePdf, { loading }] = useMutation<Types.GenerateLetterPreview, Types.GenerateLetterPreviewVariables>(
    GENERATE_LETTER_PREVIEW,
    {
      variables: {
        contactId,
        layout,
        title: mergedTemplate.title,
        body: mergedTemplate.body
      },
      onCompleted: ({ generateLetterPreviewPdf }) => window.open(generateLetterPreviewPdf)
    }
  )

  if (iconButton)
    return (
      <IconButton
        {...props}
        onClick={() => generatePdf()}
        isLoading={loading}
        icon={['fas', 'arrow-up-right-from-square']}
      />
    )
  else
    return (
      <Button
        {...props}
        onClick={() => generatePdf()}
        isLoading={loading}
        iconBefore={iconBefore}
        iconAfter={iconAfter}
      >
        {children}
      </Button>
    )
}

export default LetterPreviewButton
