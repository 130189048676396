import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, Paragraph, Text } from 'evergreen-ui'
import { Types, Button, TextInputField, toDollarsFromCents, colors, FormError } from 'lib'

import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'
import { EnrollInstallmentsState } from '.'

export type Props = {
  guarantor: Types.EnrollInstallmentsCard_contact
  enrollState: EnrollInstallmentsState
  updateEnrollState: (update: Partial<EnrollInstallmentsState>) => void
}

const EnrollInstallmentsAmount = ({ guarantor, enrollState, updateEnrollState }: Props) => {
  const { updateGuarantorSheetState } = useGuarantorSheetState()
  const { step } = enrollState

  return (
    <Formik
      initialValues={{
        amount: ((typeof enrollState.amount === 'number'
          ? (enrollState.amount / 100).toFixed(2)
          : guarantor.balance.metrics.patientPortion / 100) as unknown) as number
      }}
      validationSchema={Yup.object({
        amount: Yup.number().required('Amount is required').min(5, 'Amount must be at least $5.00')
      })}
      onSubmit={({ amount }) => updateEnrollState({ step: step + 1, amount: Math.round(amount * 100) })}
    >
      <Form>
        <Pane display="flex" flexDirection="column" alignItems="center" padding={16}>
          <Heading marginTop={16} marginBottom={8}>
            Enter Amount
          </Heading>
          <Paragraph textAlign="center" marginBottom={16}>
            Please enter the payment plan amount. You will select the plan schedule on the next step.
          </Paragraph>

          <Pane display="grid" gridTemplateColumns="1fr 1fr" alignItems="center" padding={8}>
            <Heading>Balance Amount:</Heading>
            <TextInputField name="amount" marginBottom={0} textAlign="right" autoFocus placeholder="$0.00" />
            <div />
            <Text
              fontSize="14px"
              color="muted"
              fontStyle="italic"
              cursor="pointer"
              marginTop={4}
              textAlign="right"
              onClick={() =>
                updateGuarantorSheetState({
                  path: Types.GuarantorPath.BILLING,
                  billingPath: Types.GuarantorBillingPath.ACTIONS
                })
              }
            >
              Recent Balance: {toDollarsFromCents(guarantor.balance.metrics.patientPortion, 2)}
            </Text>
          </Pane>
          <FormError marginTop={0} />
        </Pane>
        <Pane display="flex" flexDirection="row" padding={16} borderTop={`solid 1px ${colors.border.muted}`}>
          <Button type="submit" height={48} appearance="primary" flexGrow={1} rightChevron>
            <Pane textAlign="center" width="100%">
              Next
            </Pane>
          </Button>
        </Pane>
      </Form>
    </Formik>
  )
}

export default EnrollInstallmentsAmount
