import 'graphql'
import gql from 'graphql-tag'

// Keep in sync with _pay.ts
export const GET_PAY_OVER_TIME_SELECTOR = gql`
  query PayOverTimeSelector($id: ID!, $selfEnroll: Boolean!, $amount: Int!) {
    contact(id: $id) {
      id
      accountId
      eligible_installmentTemplates(amount: $amount) {
        id
        name
        periods
        interval
        intervalType
        adminFee
        downPayment
        allowCC
        allowACH
      }
      eligible_financingProviders @include(if: $selfEnroll) {
        id
        name
        url
        description
      }
      balance @include(if: $selfEnroll) {
        sunbitEstimate {
          id
          monthlyAmount
          amountFinanced
          apr
          downPayment
        }
      }
    }
  }
`
