import 'graphql'
import gql from 'graphql-tag'

export const GET_PATIENT_SELECTOR_SEARCH = gql`
  query PatientSelectorSearch($search: String!, $first: Int, $after: String) {
    patientsConnection(search: $search, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          source
          email
          phone
          isGuarantor
          isMember

          name {
            first
            last
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
