import gql from 'graphql-tag'

const fragments = {
  addressFields: gql`
    fragment addressFields on Address {
      id
      name
      street1
      street2
      city
      state
      zip
    }
  `,
  verifiedAddressFields: gql`
    fragment verifiedAddressFields on LobAddress {
      id
      street1
      street2
      city
      zip
      state
    }
  `
}

export const UPSERT_ADDRESS = gql`
  mutation UpsertAddress(
    $id: ID
    $name: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: State!
    $zip: String!
  ) {
    upsertAddress(id: $id, name: $name, street1: $street1, street2: $street2, city: $city, state: $state, zip: $zip) {
      ...addressFields
      verifiedAddress {
        ...verifiedAddressFields
      }
    }
  }
  ${fragments.addressFields}
  ${fragments.verifiedAddressFields}
`

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: ID!) {
    deleteAddress(id: $id) {
      id
    }
  }
`

export const VERIFY_ACCOUNT_RETURN_ADDRESS = gql`
  mutation VerifyAccountReturnAddress {
    verifyAndSaveAccountReturnAddress {
      ...verifiedAddressFields
    }
  }
  ${fragments.verifiedAddressFields}
`

export const GET_OFFICES = gql`
  query GetOffices {
    account {
      id
      offices {
        ...addressFields
      }
    }
  }
  ${fragments.addressFields}
`
