import { useQuery } from '@apollo/client'
import { GET_GUARANTOR_BILLING } from 'graphql/_guarantor-sheet'
import { GET_BALANCE_CARD } from 'graphql/_balance-card'

import { Pane, Tablist, Tab } from 'evergreen-ui'
import { Types, Card, Spinner } from 'lib'

import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'
import BalanceCard from 'components/balance-card'
import { useGlobal } from 'components/global-provider'

import Actions from './guarantor-billing-actions'
import Visits from './guarantor-billing-visits'
import Outreach from './guarantor-billing-outreach'
import Processed from './guarantor-billing-processed'

let tabs = [
  { path: Types.GuarantorBillingPath.ACTIONS, component: Actions },
  { path: Types.GuarantorBillingPath.VISITS, component: Visits },
  { path: Types.GuarantorBillingPath.OUTREACH, component: Outreach },
  { path: Types.GuarantorBillingPath.PROCESSED, component: Processed }
]

export type Props = { guarantorId: string }

const GuarantorBilling = ({ guarantorId }: Props) => {
  const global = useGlobal()
  const capabilities = global.account.capabilities

  if (!capabilities.includes(Types.AccountCapability.BILLING))
    tabs = tabs.filter(
      (t) => ![Types.GuarantorBillingPath.VISITS, Types.GuarantorBillingPath.OUTREACH].includes(t.path)
    )

  const { billingPath: currentPath, updateGuarantorSheetState } = useGuarantorSheetState()

  const CurrentCard = tabs.find((tab) => tab.path === currentPath)!.component

  const { data } = useQuery<Types.GuarantorBilling, Types.GuarantorBillingVariables>(GET_GUARANTOR_BILLING, {
    variables: {
      id: guarantorId
    }
  })

  // Pre-fetch balance card data to avoid multiple UI spinners
  const { data: balanceCardData } = useQuery<Types.BalanceCard, Types.BalanceCardVariables>(GET_BALANCE_CARD, {
    variables: {
      id: guarantorId
    }
  })

  if (!(data && balanceCardData))
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner />
      </Pane>
    )

  const guarantor = data.contact

  return (
    <Pane padding={16}>
      {capabilities.includes(Types.AccountCapability.BILLING) && (
        <BalanceCard guarantorId={guarantorId} elevation={0} marginBottom={16} />
      )}
      <Card marginBottom={8} elevation={0} padding={0}>
        <Pane
          display="flex"
          paddingY={8}
          paddingX={4}
          justifyContent="space-between"
          alignItems="center"
          background="white"
        >
          <Tablist>
            {tabs.map((step) => {
              const { path } = step
              return (
                <Tab
                  key={path}
                  textTransform="uppercase"
                  isSelected={currentPath === path}
                  onSelect={() => updateGuarantorSheetState({ billingPath: path })}
                >
                  {path}
                </Tab>
              )
            })}
          </Tablist>
        </Pane>
      </Card>

      <CurrentCard guarantor={guarantor} />
    </Pane>
  )
}

export default GuarantorBilling
