import gql from 'graphql-tag'

export const GET_PAY_OVER_TIME_OPTIONS_CARD = gql`
  query PayOverTimeOptionsCard($guarantorId: ID!) {
    contact(id: $guarantorId) {
      id
      manual_payOverTime
      manual_installmentTemplates {
        id
      }
      default_installmentTemplates {
        id
      }
      manual_financingProviders {
        id
      }
      default_financingProviders {
        id
      }

      manual_sunbitEnabled
      default_sunbitEnabled
    }
    account {
      id

      sunbitConfigurationId

      installmentTemplates(enabled: true, orderBy: periods, orderDirection: asc) {
        id
        name
        periods
        interval
        intervalType
      }
      financingProviders(enabled: true) {
        id
        name
        url
      }
    }
  }
`

export const SET_PAY_OVER_TIME_OPTIONS = gql`
  mutation SetPayOverTimeOptions(
    $guarantorId: ID!
    $enabled: Boolean
    $enabledSunbit: Boolean
    $enabledInstallmentTemplateIds: [ID!]
    $enabledFinancingProviderIds: [ID!]
  ) {
    setPayOverTimeOptions(
      guarantorId: $guarantorId
      enabled: $enabled
      enabledSunbit: $enabledSunbit
      enabledInstallmentTemplateIds: $enabledInstallmentTemplateIds
      enabledFinancingProviderIds: $enabledFinancingProviderIds
    ) {
      id
      manual_payOverTime
      manual_sunbitEnabled
      manual_installmentTemplates {
        id
      }
      manual_financingProviders {
        id
      }
    }
  }
`
