import 'graphql'
import gql from 'graphql-tag'

export const GET_DEPENDENT_EXISTING = gql`
  query DependentExisting($id: ID!) {
    contact(id: $id) {
      id
      name {
        first
        last
      }
      code
      account {
        capabilities
      }
    }
  }
`

export const GET_DEPENDENT_PLAN = gql`
  query DependentPlan($id: ID!) {
    contact(id: $id) {
      id
      email
      phone
      code
      perio
      name {
        first
        last
      }
      dob {
        day
        month
        year
      }
      isGuarantor
      familyMemberCount
      planTerm
      planRenewalDate
      plan {
        id
        name
        status
      }
      stripe {
        latestOpenInvoice
        membership {
          id
          effectiveDate
          status
          autoRenew
          price
          interval
        }
      }
    }
  }
`
