import { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import { sortBy } from 'lodash'
import { useQuery } from '@apollo/client'

import { GET_PAY_OVER_TIME_SELECTOR } from 'lib/graphql/_pay-over-time-selector'

import { Pane, CardProps, Heading, Text } from 'evergreen-ui'

import {
  Types,
  Card,
  CardHeader,
  Spinner,
  Icon,
  InstallmentTemplate,
  Button,
  TemplateDescription,
  SunbitCheckoutButton,
  SunbitDisclaimer
} from './'

import { toDollarsFromCents, toDollars, prettyInterval, calculateInstallmentAmounts, colors } from 'lib'

import SunbitLogo from 'lib/assets/img/sunbit-logo'

export type Props = CardProps & {
  guarantorId: string
  guarantor?: Types.PayOverTimeSelector['contact']
  amount: number
  initialSelectedId?: string
  setSelectedTemplate?: (option: Types.PayOverTimeSelector_contact_eligible_installmentTemplates | null) => void
  showCtas?: boolean
  onInstallmentCtaClick?: (template: Types.PayOverTimeSelector_contact_eligible_installmentTemplates) => void
  onFinancingComplete?: () => void
  selfEnroll?: boolean
  account?: { name: string; phone: string }
}

const PayOverTimeSelector = ({ ...props }: Props) => {
  const { guarantorId, guarantor: guarantorProp, amount, selfEnroll = false } = props

  if (!guarantorProp && selfEnroll) throw Error('Guarantor data must be pre-loaded for Self Enroll')

  const { data } = useQuery<Types.PayOverTimeSelector, Types.PayOverTimeSelectorVariables>(GET_PAY_OVER_TIME_SELECTOR, {
    variables: { id: guarantorId, selfEnroll, amount },
    skip: !!guarantorProp
  })

  const guarantor = guarantorProp ?? data?.contact

  if (!guarantor) return <Spinner delay={0} marginY={48} />
  // This pattern is necessary to ensure data is available before animations begin
  else return <PayOverTimeSelectorInterface guarantor={guarantor} {...props} />
}

const PayOverTimeSelectorInterface = ({
  guarantor,
  amount,
  initialSelectedId,
  setSelectedTemplate,
  showCtas,
  onInstallmentCtaClick,
  onFinancingComplete,
  selfEnroll,
  account,
  ...props
}: Omit<Props, 'guarantorId'> & { guarantor: Types.PayOverTimeSelector['contact'] }) => {
  const { eligible_installmentTemplates: templates, eligible_financingProviders: providers, balance } = guarantor

  const sunbitEstimate = balance?.sunbitEstimate
  const sortedTemplates = sortBy(templates, (template) => template.periods)
  const sortedProviders = providers && sortBy(providers, 'name')
  const combinedOptions = [
    ...sortedTemplates,
    ...(sortedProviders ? sortedProviders : []),
    ...(sunbitEstimate ? [sunbitEstimate] : [])
  ]

  const defaultOption =
    combinedOptions.find((option) => option.id === initialSelectedId) ||
    (combinedOptions.length === 1 ? combinedOptions[0] : null)

  // Need useEffect (rather than onCompleted in parent) because guarantorProp can be passed in instead of data
  useEffect(() => {
    if (setSelectedTemplate && defaultOption && 'interval' in defaultOption) setSelectedTemplate(defaultOption)
  }, [])

  const [selectedIdState, setSelectedIdState] = useState<string | null>(defaultOption ? defaultOption.id : null)

  const templateTransitions = useTransition(selectedIdState, {
    unique: true,
    initial: { opacity: 1, height: showCtas ? 256 : 192 },
    from: { opacity: 0, height: 0 },
    enter: {
      opacity: 1,
      height: showCtas ? 256 : 192
    },
    leave: { opacity: 0, height: 0 }
  })

  const providerTransitions = useTransition(selectedIdState, {
    unique: true,
    initial: { opacity: 1, height: showCtas ? 160 : 96 },
    from: { opacity: 0, height: 0 },
    enter: {
      opacity: 1,
      height: showCtas ? 160 : 96
    },
    leave: { opacity: 0, height: 0 }
  })

  const sunbitTransition = useTransition(selectedIdState, {
    unique: true,
    initial: { opacity: 1, height: showCtas ? 138 : 74 },
    from: { opacity: 0, height: 0 },
    enter: {
      opacity: 1,
      height: showCtas ? 138 : 74
    },
    leave: { opacity: 0, height: 0 }
  })

  const nowDate = new Date()
  const sunbitSelected = sunbitEstimate && selectedIdState === sunbitEstimate.id
  const showSectionLabels = !!(sortedTemplates.length && (sortedProviders?.length || sunbitEstimate))

  if (!sortedTemplates.length && !sortedProviders?.length && !sunbitEstimate)
    return (
      // Match spinner height
      <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="132px">
        <Icon size="4x" icon={['fad', 'window-close']} color={colors.orange.base} marginBottom={8} />
        <Text size={500} textAlign="center">
          No eligible pay-over-time-options
        </Text>
      </Pane>
    )

  return (
    <Pane display="grid" gridGap={8} {...props}>
      {showSectionLabels && (
        <Heading size={400} textAlign="center" marginBottom={4} color={colors.blue.base} textTransform="uppercase">
          Payment Plans
        </Heading>
      )}
      {sortedTemplates.map((template, i) => {
        const selected = selectedIdState === template.id
        const { enrollmentAmount, installmentAmount } = calculateInstallmentAmounts({
          amount,
          installmentTemplate: template
        })

        return (
          <Card
            padding={0}
            key={i}
            elevation={selected ? 1 : 0}
            hoverElevation={selected ? undefined : 1}
            cursor="pointer"
            borderWidth={2}
            box-sizing="border-box"
            borderColor={selected ? colors.blue.base : 'transparent'}
            borderStyle="solid"
          >
            <CardHeader
              padding={12}
              background={selected ? 'blueTint' : 'white'}
              onClick={() => {
                setSelectedIdState(template.id)
                if (setSelectedTemplate) setSelectedTemplate(template)
              }}
              alignItems="flex-start"
            >
              <Pane flexGrow={1}>
                <Heading size={500} marginBottom={2}>
                  {template.name}
                </Heading>
                <Text color="muted">
                  {toDollarsFromCents(enrollmentAmount)} today, then {toDollarsFromCents(installmentAmount)} every{' '}
                  {prettyInterval(template.interval, template.intervalType)}
                </Text>
              </Pane>

              <Icon
                justifySelf="flex-end"
                color="default"
                icon={selected ? ['fas', 'check-circle'] : ['far', 'circle']}
                marginLeft={8}
              />
            </CardHeader>
            {templateTransitions(
              (props, item) =>
                item === template.id && (
                  <animated.div style={props}>
                    <Pane padding={16} display="flex" flexDirection="column" justifyContent="center">
                      <InstallmentTemplate
                        installmentTemplate={template}
                        isTruncated
                        startDate={nowDate}
                        marginY={12}
                        marginX={8}
                        amount={amount}
                      />
                      <TemplateDescription amount={amount} installmentTemplate={template} color="muted" />
                      {showCtas && (
                        <Button
                          marginTop={16}
                          appearance="primary"
                          width="100%"
                          height={48}
                          justifyContent="center"
                          iconAfter={['far', 'chevron-right']}
                          onClick={() => onInstallmentCtaClick && onInstallmentCtaClick(template)}
                        >
                          Select Schedule
                        </Button>
                      )}
                    </Pane>
                  </animated.div>
                )
            )}
          </Card>
        )
      })}

      {showSectionLabels && (
        <Heading
          size={400}
          textAlign="center"
          marginTop={12}
          marginBottom={4}
          color={colors.blue.base}
          textTransform="uppercase"
        >
          Financing
        </Heading>
      )}

      {sunbitEstimate && (
        <Card
          padding={0}
          elevation={sunbitSelected ? 1 : 0}
          hoverElevation={sunbitSelected ? undefined : 1}
          cursor="pointer"
          borderWidth={2}
          box-sizing="border-box"
          borderColor={sunbitSelected ? colors.blue.base : 'transparent'}
          borderStyle="solid"
        >
          <CardHeader
            padding={12}
            justifyContent="space-between"
            onClick={() => setSelectedIdState(sunbitEstimate.id)}
            background={sunbitSelected ? 'blueTint' : 'white'}
            alignItems="flex-start"
          >
            <Pane flexGrow={1}>
              <Heading size={500} marginBottom={2}>
                Patient Financing
              </Heading>
              <Pane display="flex">
                <Text color="muted" marginRight={6} size={400}>
                  As low as {toDollars(sunbitEstimate.monthlyAmount, 2)}/mo with
                </Text>
                <Pane height="20px">
                  <SunbitLogo />
                </Pane>
                <SunbitDisclaimer sunbitEstimate={sunbitEstimate} marginLeft={4} />
              </Pane>
            </Pane>

            <Icon
              justifySelf="flex-end"
              color="default"
              icon={sunbitSelected ? ['fas', 'check-circle'] : ['far', 'circle']}
              marginLeft={8}
            />
          </CardHeader>

          {sunbitTransition(
            (props, item) =>
              item === sunbitEstimate.id && (
                <animated.div style={props}>
                  <Pane padding={16}>
                    <Pane height={42}>
                      <Text>See what flexible monthly financing options you qualify for with Sunbit</Text>
                    </Pane>

                    <SunbitCheckoutButton
                      amount={amount}
                      contactId={guarantor.id}
                      marginTop={16}
                      appearance="primary"
                      width="100%"
                      height={48}
                      justifyContent="center"
                      iconAfter={['far', 'external-link']}
                      onCompleted={onFinancingComplete}
                    />
                  </Pane>
                </animated.div>
              )
          )}
        </Card>
      )}

      {sortedProviders?.map((provider, i) => {
        const selected = selectedIdState === provider.id

        return (
          <Card
            padding={0}
            key={i}
            elevation={selected ? 1 : 0}
            hoverElevation={selected ? undefined : 1}
            cursor="pointer"
            borderWidth={2}
            box-sizing="border-box"
            borderColor={selected ? colors.blue.base : 'transparent'}
            borderStyle="solid"
          >
            <CardHeader
              padding={12}
              justifyContent="space-between"
              onClick={() => setSelectedIdState(provider.id)}
              background={selected ? 'blueTint' : 'white'}
            >
              <Pane display="flex">
                <Heading size={500}>{provider.name}</Heading>
              </Pane>

              <Icon
                justifySelf="flex-end"
                color="default"
                icon={selected ? ['fas', 'check-circle'] : ['far', 'circle']}
                marginLeft={8}
              />
            </CardHeader>
            {providerTransitions(
              (props, item) =>
                item === provider.id && (
                  <animated.div style={props}>
                    <Pane padding={16}>
                      <Pane height={64} overflowY="scroll">
                        <Pane overflow="hidden">
                          <Text>{provider.description}</Text>
                        </Pane>
                      </Pane>
                      {showCtas && (
                        <Button
                          marginTop={16}
                          appearance="primary"
                          width="100%"
                          height={48}
                          justifyContent="center"
                          iconAfter={['far', 'external-link']}
                          onClick={() => window.open(provider.url, '_blank')}
                        >
                          Apply
                        </Button>
                      )}
                    </Pane>
                  </animated.div>
                )
            )}
          </Card>
        )
      })}
    </Pane>
  )
}

export default PayOverTimeSelector
