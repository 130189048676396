import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useMutation } from '@apollo/client'
import { RESET_USER_PASSWORD } from 'graphql/_reset-user-password'

import { Types, TextInput, FormError, Icon } from 'lib'
import { Pane, Text, Dialog, toaster } from 'evergreen-ui'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
}

const ResetUserPasswordDialog = ({ isShown, setIsShown }: Props) => {
  const [resetPassword, { loading }] = useMutation<Types.ResetUserPassword, Types.ResetUserPasswordVariables>(
    RESET_USER_PASSWORD,
    {
      onCompleted: () => {
        toaster.success('Password reset email delivered if email on file')
        setIsShown(false)
      },
      onError: () => toaster.danger('Unable to deliver reset password email')
    }
  )

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={({ email }) => resetPassword({ variables: { email } })}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required')
      })}
    >
      {(formik) => (
        <Dialog
          isShown={isShown}
          title="Reset Password"
          onCloseComplete={() => setIsShown(false)}
          isConfirmLoading={loading}
          onConfirm={() => formik.handleSubmit()}
          confirmLabel={loading ? 'Resetting...' : 'Reset Password'}
        >
          <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Icon icon={['fad', 'unlock']} color="default" size="3x" marginY={16} />
            <Text size={500} marginBottom={16}>
              Please enter your email address to receive a reset link
            </Text>

            <Form>
              <TextInput
                name="email"
                type="email"
                marginBottom={0}
                placeholder="john@harris.com"
                autoFocus
                data-cy="reset-password-email"
              />
              <FormError />
            </Form>
          </Pane>
        </Dialog>
      )}
    </Formik>
  )
}

export default ResetUserPasswordDialog
