import { createContext, useContext } from 'react'

export const LibConfigContext = createContext({
  sunbit: {
    mode: '',
    apiKey: ''
  }
})

type Config = {
  sunbit: {
    mode: string
    apiKey: string
  }
}

export const useLibConfig = () => useContext(LibConfigContext)

export const LibConfigProvider = ({ children, config }: { children: React.ReactNode; config: Config }) => (
  <LibConfigContext.Provider value={config}>{children}</LibConfigContext.Provider>
)
