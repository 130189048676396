import { useState } from 'react'

import { useQuery, useMutation } from '@apollo/client'

import { GET_PAY_OVER_TIME_OPTIONS_CARD, SET_PAY_OVER_TIME_OPTIONS } from 'graphql/_pay-over-time-options-card'

import { Pane, CardProps, Heading, Text, Switch, Badge, toaster, Tooltip } from 'evergreen-ui'
import { Types, Card, CardHeader, colors, Spinner, Button, InfoTooltip, Icon, ExternalLink, prettyInterval } from 'lib'

import { useModal } from 'components/modal-provider'

export interface Props extends CardProps {
  guarantorId: string
  lockSunbit?: boolean
}

const PayOverTimeOptionsCard = ({ guarantorId, lockSunbit, ...props }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [manualPayOverTimeState, setManualPayOverTimeState] = useState<boolean>()
  const [manualSunbitState, setManualSunbitState] = useState<boolean>()
  const [manualTemplateIdsState, setManualTemplateIdsState] = useState<string[]>()
  const [manualProviderIdsState, setManualProviderIdsState] = useState<string[]>()

  const { data } = useQuery<Types.PayOverTimeOptionsCard>(GET_PAY_OVER_TIME_OPTIONS_CARD, {
    variables: { guarantorId }
  })

  const [setPayOverTimeOptions, { loading }] = useMutation<
    Types.SetPayOverTimeOptions,
    Types.SetPayOverTimeOptionsVariables
  >(SET_PAY_OVER_TIME_OPTIONS, {
    update: (cache) => {
      const cached_contactId = cache.identify({ id: guarantorId, __typename: 'Contact' })
      cache.evict({
        id: cached_contactId,
        fieldName: 'eligible_sunbit'
      })
      cache.evict({
        id: cached_contactId,
        fieldName: 'eligible_installmentTemplates'
      })
      cache.evict({
        id: cached_contactId,
        fieldName: 'eligible_financingProviders'
      })
      cache.gc()
    },
    onCompleted: () => toaster.success('Options successfully updated'),
    onError: () => {
      toaster.danger('Unable to update options')
      setManualPayOverTimeState(undefined)
      setManualSunbitState(undefined)
      setManualTemplateIdsState(undefined)
      setManualProviderIdsState(undefined)
    }
  })

  if (!data) return <Spinner delay={0} marginY={48} />

  const { contact, account } = data
  const { installmentTemplates, financingProviders, sunbitConfigurationId } = account
  const {
    manual_payOverTime,
    manual_sunbitEnabled,
    default_sunbitEnabled,
    manual_installmentTemplates,
    default_installmentTemplates,
    manual_financingProviders,
    default_financingProviders
  } = contact

  const accountHasTemplates = installmentTemplates.length > 0
  const accountHasFinancing = financingProviders.length || sunbitConfigurationId

  // Use for optimistic UI updates (still check manual_payOverTime for API calls)
  const isOverridenUI = typeof manualPayOverTimeState === 'boolean' ? manualPayOverTimeState : manual_payOverTime

  const enabledSunbit = isOverridenUI ? manualSunbitState || manual_sunbitEnabled : default_sunbitEnabled

  const enabledTemplateIds = isOverridenUI
    ? manualTemplateIdsState || manual_installmentTemplates.map(({ id }) => id)
    : default_installmentTemplates.map(({ id }) => id)

  const enabledProviderIds = isOverridenUI
    ? manualProviderIdsState || manual_financingProviders.map(({ id }) => id)
    : default_financingProviders.map(({ id }) => id)

  const confirm = (cb: () => void) =>
    showConfirmDialog({
      body: `You are changing the default Pay-Over-Time options offered to this patient
    from your global settings. Please confirm that you would like to update the
    Pay-Over-Time options offered to this patient (this will not impact your global
    settings). You can edit the Pay-Over-Time options offered at any time.`,
      title: 'Confirm Change',
      onConfirm: cb
    })

  return accountHasTemplates || accountHasFinancing ? (
    <Card {...props} padding={0}>
      <CardHeader justifyContent="space-between" withButton={isOverridenUI}>
        <Heading>Pay-Over-Time Options</Heading>
        {isOverridenUI ? (
          <Button
            appearance="minimal"
            isLoading={loading}
            onClick={() => {
              setManualPayOverTimeState(false)
              setPayOverTimeOptions({
                variables: {
                  guarantorId,
                  enabled: false,
                  enabledSunbit: default_sunbitEnabled,
                  enabledInstallmentTemplateIds: default_installmentTemplates.map(({ id }) => id),
                  enabledFinancingProviderIds: default_financingProviders.map(({ id }) => id)
                }
              })
            }}
          >
            Reset Defaults
          </Button>
        ) : (
          <Tooltip content="Default options based on balance and days past due criteria">
            <Badge cursor="help">Default</Badge>
          </Tooltip>
        )}
      </CardHeader>
      <Pane padding={16} display="grid" gridGap={8}>
        {accountHasTemplates && (
          <>
            <Heading size={300} color={colors.blue.base} textTransform="uppercase">
              Payment Plans
            </Heading>
            {installmentTemplates.map(({ id, name, interval, intervalType, periods }, i) => (
              <Pane display="flex" justifyContent="space-between" alignItems="center" key={i}>
                <Text size={500}>{name}</Text>
                <Pane display="flex" alignItems="center">
                  <InfoTooltip
                    marginX={8}
                    content={`Every ${prettyInterval(interval, intervalType)} in ${periods} installments`}
                  />
                  <Switch
                    disabled={loading}
                    height={24}
                    checked={enabledTemplateIds.includes(id)}
                    onChange={({ target: { checked } }) => {
                      const newEnabledTemplateIds = checked
                        ? [...enabledTemplateIds, id]
                        : enabledTemplateIds.filter((enabledId) => enabledId !== id)

                      const handler = () => {
                        setManualSunbitState(enabledSunbit)
                        setManualTemplateIdsState(newEnabledTemplateIds)
                        setManualProviderIdsState(enabledProviderIds)
                        setManualPayOverTimeState(true)

                        setPayOverTimeOptions({
                          variables: {
                            guarantorId,
                            enabled: true,
                            enabledSunbit,
                            enabledInstallmentTemplateIds: newEnabledTemplateIds,
                            enabledFinancingProviderIds: enabledProviderIds
                          }
                        })
                      }

                      if (!manual_payOverTime) confirm(() => handler())
                      else handler()
                    }}
                  />
                </Pane>
              </Pane>
            ))}
          </>
        )}

        {accountHasFinancing && (
          <>
            <Heading
              size={300}
              color={colors.blue.base}
              textTransform="uppercase"
              marginTop={accountHasTemplates ? 8 : 0}
            >
              Financing
            </Heading>
            <Pane display="flex" justifyContent="space-between" alignItems="center">
              <Text size={500}>Sunbit</Text>
              <Pane display="flex" alignItems="center">
                <Icon
                  marginX={8}
                  icon={['far', 'link']}
                  opacity={0.8}
                  cursor="pointer"
                  onClick={() => window.open('https://sunbit.com/merchant-benefits/dental/', '_blank')}
                />
                <Tooltip
                  content="Sunbit financing option cannot be disabled if offered to patient engaged via billing automation rules"
                  isShown={!(lockSunbit && enabledSunbit) ? false : undefined}
                >
                  <Switch
                    disabled={loading || (lockSunbit && enabledSunbit)}
                    height={24}
                    checked={enabledSunbit}
                    onChange={({ target: { checked } }) => {
                      const newEnabledSunbit = checked

                      const handler = () => {
                        setManualSunbitState(newEnabledSunbit)
                        setManualTemplateIdsState(enabledTemplateIds)
                        setManualProviderIdsState(enabledProviderIds)
                        setManualPayOverTimeState(true)

                        setPayOverTimeOptions({
                          variables: {
                            guarantorId,
                            enabled: true,
                            enabledSunbit: newEnabledSunbit,
                            enabledInstallmentTemplateIds: enabledTemplateIds,
                            enabledFinancingProviderIds: enabledProviderIds
                          }
                        })
                      }

                      if (!manual_payOverTime) confirm(() => handler())
                      else handler()
                    }}
                  />
                </Tooltip>
              </Pane>
            </Pane>
            {financingProviders.map(({ id, name, url }, i) => (
              <Pane display="flex" justifyContent="space-between" alignItems="center" key={i}>
                <Text size={500}>{name}</Text>
                <Pane display="flex">
                  <ExternalLink web={{ title: 'Opening External Page', url }}>
                    <Icon marginX={8} icon={['far', 'link']} opacity={0.8} cursor="pointer" />
                  </ExternalLink>
                  <Switch
                    disabled={loading}
                    height={24}
                    checked={enabledProviderIds.includes(id)}
                    onChange={({ target: { checked } }) => {
                      const newEnabledProviderIds = checked
                        ? [...enabledProviderIds, id]
                        : enabledProviderIds.filter((enabledId) => enabledId !== id)

                      const handler = () => {
                        setManualSunbitState(enabledSunbit)
                        setManualTemplateIdsState(enabledTemplateIds)
                        setManualProviderIdsState(newEnabledProviderIds)
                        setManualPayOverTimeState(true)

                        setPayOverTimeOptions({
                          variables: {
                            guarantorId,
                            enabled: true,
                            enabledSunbit,
                            enabledInstallmentTemplateIds: enabledTemplateIds,
                            enabledFinancingProviderIds: newEnabledProviderIds
                          }
                        })
                      }

                      if (!manual_payOverTime) confirm(() => handler())
                      else handler()
                    }}
                  />
                </Pane>
              </Pane>
            ))}
          </>
        )}
      </Pane>
    </Card>
  ) : null
}

export default PayOverTimeOptionsCard
