import { useHistory } from 'react-router-dom'

import { Pane, Paragraph, Heading, Pill } from 'evergreen-ui'

import { Card, CardHeader, Icon, Button, mc } from 'lib'

import StripeConnectButton from 'components/stripe-connect-button'

export type Props = {
  prev: string
  next: string
  setIsForward: React.Dispatch<React.SetStateAction<boolean>>
}

const OnboardingBank = ({ prev, next, setIsForward }: Props) => {
  const history = useHistory()

  return (
    <Card padding={0}>
      <CardHeader>
        <Heading size={500} flexGrow={1} textAlign="center">
          Set up {mc.name} for your Practice
        </Heading>
        <Pill color="blue">5/5</Pill>
      </CardHeader>

      <Pane padding={24} display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <Icon icon={['fad', 'building-columns']} color="default" size="3x" marginBottom={16} />
        <Paragraph size={500} marginBottom={16}>
          Enter your practice and bank details to process payments and receive deposits.
        </Paragraph>
        <Paragraph size={500} marginBottom={16}>
          {mc.name} uses Stripe for secure payment processing. Stripe processes billions of dollars in payments and is
          used by Amazon and AirbnB.
        </Paragraph>
        <Paragraph size={500} marginBottom={24}>
          Please click the button below to create your merchant account securely.
        </Paragraph>
        <StripeConnectButton callbackUrl="/onboarding/bank" />
      </Pane>

      <Pane zIndex={1} elevation={0} padding={16} flexShrink={0} display="flex" justifyContent="space-between">
        <Button
          appearance="minimal"
          height={48}
          justifyContent="center"
          iconBefore={['far', 'chevron-left']}
          onClick={() => {
            setIsForward(false)
            history.push(prev)
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setIsForward(true)
            history.push(next)
          }}
          appearance="primary"
          height={48}
          justifyContent="center"
          iconAfter={['far', 'chevron-right']}
        >
          Skip
        </Button>
      </Pane>
    </Card>
  )
}

export default OnboardingBank
