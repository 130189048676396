import { useEffect } from 'react'

import { FirebaseAppProvider, AuthProvider, useAuth } from 'reactfire'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: window._env_.REACT_APP_FIREBASE_KEY,
  authDomain: window._env_.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: 'pearly-app'
}

const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseAuthProvider>
        <SetTenant>{children}</SetTenant>
      </FirebaseAuthProvider>
    </FirebaseAppProvider>
  )
}

const FirebaseAuthProvider = ({ children }: { children: React.ReactNode }) => (
  <AuthProvider sdk={getAuth()}>{children}</AuthProvider>
)

const SetTenant = ({ children }: { children: React.ReactNode }) => {
  if (!window._env_.REACT_APP_IDP_TENANTID_PRACTICE) throw Error('Practice TenantId Missing')

  const firebaseAuth = useAuth()

  useEffect(() => {
    firebaseAuth.tenantId = window._env_.REACT_APP_IDP_TENANTID_PRACTICE!
  }, [firebaseAuth])

  return <>{children}</>
}

export default FirebaseProvider
