import { useEffect, useState } from 'react'

import { Pane, Dialog, Heading } from 'evergreen-ui'
import { Spinner, Icon, colors } from './'

export type Props = {
  web: {
    title?: string
    url: string
    timeout?: number
  }

  children: React.ReactNode
}

const ExternalLink = ({ web, children }: Props) => {
  const [isDialogShown, setIsDialogShown] = useState(false)

  return (
    <>
      <Dialog
        isShown={isDialogShown}
        onCloseComplete={() => setIsDialogShown(false)}
        hasFooter={false}
        hasHeader={false}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        containerProps={{ width: '320px' }}
      >
        {({ close }) => (
          <>
            {web && (
              <DialogBody
                cb={() => {
                  setTimeout(() => {
                    close()
                    window.open(web.url, '_blank')
                  }, web.timeout ?? 2000)
                }}
              >
                <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="140px">
                  <Pane display="flex">
                    <Icon icon={['fad', 'browser']} marginRight={8} color={colors.blue.base} fontSize={20} />
                    <Heading marginBottom={32}>{web.title ?? 'Opening External Page'}</Heading>
                  </Pane>
                  <Spinner delay={0} />
                </Pane>
              </DialogBody>
            )}
          </>
        )}
      </Dialog>
      <Pane onClick={() => setIsDialogShown(true)}>{children}</Pane>
    </>
  )
}

const DialogBody = ({ children, cb }: { children: React.ReactNode; cb: () => Promise<void> | void }) => {
  useEffect(() => {
    cb()
  }, [])

  return <>{children}</>
}

export default ExternalLink
