import gql from 'graphql-tag'

export const fragments = {
  planGridFields: gql`
    fragment planGridFields on Plan {
      id
      name
      annualPriceActive
      annualPrice
      monthlyPriceActive
      monthlyPrice
      status
      # activeMemberCount
      allTimeMemberCount
      stripe {
        createdAt
      }
    }
  `
}

export const GET_PLAN_TEMPLATES = gql`
  query PlanTemplates {
    planTemplates {
      ...planGridFields
    }
  }
  ${fragments.planGridFields}
`

export const GET_DRAFT_PLANS = gql`
  query DraftPlans {
    plans(status: DRAFT) {
      ...planGridFields
    }
  }
  ${fragments.planGridFields}
`

export const GET_LIVE_PLANS = gql`
  query LivePlans {
    plans(status: LIVE) {
      ...planGridFields
    }
    account {
      id
      programName
    }
  }
  ${fragments.planGridFields}
`

export const GET_LEGACY_PLANS = gql`
  query LegacyPlans {
    plans(status: LEGACY) {
      ...planGridFields
    }
  }
  ${fragments.planGridFields}
`

export const CREATE_DRAFT_FROM_TEMPLATE = gql`
  mutation CreateDraftFromTemplate($templateId: ID!) {
    createDraftFromTemplate(templateId: $templateId) {
      ...planGridFields
    }
  }
  ${fragments.planGridFields}
`
