import { useState } from 'react'

import { Pane, Dialog, Text, IntentTypes } from 'evergreen-ui'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm?: () => void
  onCancel?: () => void
  title?: string
  body?: string
  confirmLabel?: string
  intent?: IntentTypes
}

const ConfirmDialog = ({ isShown, setIsShown, title, body, onConfirm, onCancel, intent, confirmLabel }: Props) => {
  const [confirmed, setConfirmed] = useState(false)

  return (
    <Dialog
      isShown={isShown}
      title={title ?? 'Confirmation'}
      intent={intent}
      onCloseComplete={() => {
        setIsShown(false)
        if (confirmed) onConfirm && onConfirm()
      }}
      confirmLabel={confirmLabel}
      onConfirm={(close) => {
        setConfirmed(true)
        close()
      }}
      onCancel={(close) => {
        onCancel && onCancel()
        close()
      }}
      containerProps={{
        'data-cy': 'confirm-dialog'
      }}
    >
      <Pane padding={16}>
        <Text>{body ?? 'Please confirm you would like to proceed'}</Text>
      </Pane>
    </Dialog>
  )
}

export default ConfirmDialog
