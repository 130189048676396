import { useQuery } from '@apollo/client'
import { GET_GUARANTOR_DEPENDENTS } from 'graphql/_guarantor-sheet'

import { Pane, Heading } from 'evergreen-ui'
import { Types, Spinner, Card, CardHeader, Icon, Button } from 'lib'

import { useModal } from 'components/modal-provider'

export type Props = {
  guarantorId: string
}

const GuarantorDependents = ({ guarantorId }: Props) => {
  const showDependentSheet = useModal('dependent')

  const { data } = useQuery<Types.GuarantorDependents, Types.GuarantorDependentsVariables>(GET_GUARANTOR_DEPENDENTS, {
    variables: { id: guarantorId }
  })

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%" background="blueTint">
        <Spinner delay={0} />
      </Pane>
    )

  const {
    contact: { dependents }
  } = data

  return (
    <>
      {dependents.map(({ id, name }, i) => (
        <Card margin={16} elevation={0} padding={0} key={i}>
          <CardHeader withButton>
            <Heading size={500}>
              <Icon icon={['fad', 'house-user']} marginRight={8} />
              {name.first} {name.last}
            </Heading>
            <Button onClick={() => showDependentSheet({ dependentId: id })}>View</Button>
          </CardHeader>
        </Card>
      ))}
    </>
  )
}

export default GuarantorDependents
