import { useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { GET_GUARANTOR_PLANS } from 'graphql/_guarantor-sheet'

import { Pane, Alert, Text, Link, SegmentedControl, toaster } from 'evergreen-ui'
import { Types, Spinner, MemberCard, Button, DependentMemberCardList, Divider } from 'lib'

import InstallmentPlanCard from 'components/installment-plan-card'
import PayOverTimeOptionsCard from 'components/pay-over-time-options-card'
import { useModal } from 'components/modal-provider'
import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'
import { useGlobal } from 'components/global-provider'

import memberIllustration from 'assets/img/member.svg'

export type Props = { guarantorId: string }

const GuarantorPlans = ({ guarantorId }: Props) => {
  const global = useGlobal()
  const capabilities = global.account.capabilities

  const showContractSheet = useModal('contract')
  const showDependentSheet = useModal('dependent')

  const { planType, updateGuarantorSheetState } = useGuarantorSheetState()

  useEffect(() => {
    if (!planType)
      if (capabilities.includes(Types.AccountCapability.MEMBERSHIP))
        updateGuarantorSheetState({ planType: Types.PlanType.MEMBERSHIP })
      else if (capabilities.includes(Types.AccountCapability.INSTALLMENTS))
        updateGuarantorSheetState({ planType: Types.PlanType.INSTALLMENTS })
      else throw Error('All plan types disabled for this account')
  }, [])

  const { data } = useQuery<Types.GuarantorPlans, Types.GuarantorPlansVariables>(GET_GUARANTOR_PLANS, {
    variables: {
      id: guarantorId
    }
  })

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  const { contact: guarantor } = data

  const {
    plan,
    stripe: stripeData,
    planTerm,
    installmentPlans,
    dob,
    balance: {
      metrics: { patientPortion },
      engagementDetails
    }
  } = guarantor

  const hasPastDuePlans = installmentPlans.find(({ status }) => ['FAILED_PAUSED', 'FAILED_RETRYING'].includes(status))

  const orderedInstallmentPlans = [
    ...installmentPlans.filter(({ status }) => status === 'ACTIVE'),
    ...installmentPlans.filter(({ status }) => status === 'FAILED_RETRYING'),
    ...installmentPlans.filter(({ status }) => status === 'FAILED_PAUSED'),
    ...installmentPlans.filter(({ status }) => status === 'PAUSED'),
    ...installmentPlans.filter(({ status }) => status === 'COMPLETED'),
    ...installmentPlans.filter(({ status }) => status === 'CANCELED')
  ]

  return (
    <Pane margin={16}>
      {(stripeData?.latestOpenInvoice || hasPastDuePlans) && (
        <Alert intent="danger" title="Saved Payment Method Invalid" marginBottom={16}>
          <Text>
            To bring the guarantor's account current, please{' '}
            <Link
              cursor="pointer"
              onClick={() => updateGuarantorSheetState({ path: Types.GuarantorPath.PAYMENT_INFO })}
            >
              update payment method
            </Link>
          </Text>
        </Alert>
      )}

      {capabilities.includes(Types.AccountCapability.MEMBERSHIP) &&
        capabilities.includes(Types.AccountCapability.BILLING) && (
          <SegmentedControl
            marginBottom={16}
            options={[
              { label: 'Membership', value: Types.PlanType.MEMBERSHIP },
              { label: 'Payment Plans', value: Types.PlanType.INSTALLMENTS }
            ]}
            value={planType}
            onChange={(value) => updateGuarantorSheetState({ planType: value as Types.PlanType })}
          />
        )}
      {planType === 'MEMBERSHIP' && (
        <>
          {plan && stripeData && stripeData.membership && planTerm ? (
            <MemberCard
              contact={guarantor}
              showContractSheet={showContractSheet}
              showProcessedTab={() =>
                updateGuarantorSheetState({
                  path: Types.GuarantorPath.BILLING,
                  billingPath: Types.GuarantorBillingPath.PROCESSED
                })
              }
            />
          ) : (
            <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin={20}>
              <Pane height="112px">
                <img src={memberIllustration} height="100%" alt="Add Plans Illustration" />
              </Pane>
              <Text marginTop={24} marginBottom={6} size={500} textAlign="center">
                This patient is not yet enrolled in your membership program...
              </Text>
              <Button
                appearance="minimal"
                onClick={() => {
                  if (dob) updateGuarantorSheetState({ path: Types.GuarantorPath.ENROLL_MEMBER })
                  else toaster.warning('Unable to enroll member with missing DOB')
                }}
              >
                Enroll Now
              </Button>
            </Pane>
          )}

          <Divider label="Dependents" marginTop={24} marginBottom={16} />
          <DependentMemberCardList
            contactId={guarantor.id}
            showProcessedTab={() =>
              updateGuarantorSheetState({
                path: Types.GuarantorPath.BILLING,
                billingPath: Types.GuarantorBillingPath.PROCESSED
              })
            }
          />
          <Pane display="flex" flexDirection="column" alignItems="center" marginY={16}>
            <Button
              appearance="minimal"
              onClick={() => showDependentSheet({ guarantorId: guarantor.id, initialPath: Types.DependentPath.ENROLL })}
            >
              Enroll New Dependent
            </Button>
            {guarantor.dependents.length > 0 && (
              <Button
                appearance="minimal"
                onClick={() => updateGuarantorSheetState({ path: Types.GuarantorPath.DEPENDENTS })}
              >
                View Existing Dependents
              </Button>
            )}
          </Pane>
        </>
      )}

      {planType === 'INSTALLMENTS' && (
        <>
          {installmentPlans.length ? (
            <Pane display="flex" flexDirection="column" alignItems="center">
              {orderedInstallmentPlans.map((plan) => (
                <InstallmentPlanCard marginBottom={16} width="100%" plan={plan} guarantor={guarantor} key={plan.id} />
              ))}
            </Pane>
          ) : (
            <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin={20}>
              <Pane height="112px">
                <img src={memberIllustration} height="100%" alt="Add Plans Illustration" />
              </Pane>
              <Text marginTop={24} marginBottom={6} size={500} textAlign="center">
                This patient has no current/completed installment plans...
              </Text>
              <Button
                appearance="minimal"
                onClick={() =>
                  updateGuarantorSheetState({
                    path: Types.GuarantorPath.ENROLL_INSTALLMENTS
                  })
                }
              >
                Create Payment Plan
              </Button>
            </Pane>
          )}
          {patientPortion > 0 && (
            <PayOverTimeOptionsCard
              guarantorId={guarantorId}
              elevation={0}
              lockSunbit={
                !!engagementDetails &&
                !engagementDetails.isLastStartManual &&
                (engagementDetails.status === Types.BalanceEngagementStatus.ENGAGED ||
                  engagementDetails?.status === Types.BalanceEngagementStatus.FOLLOW_UP)
              }
            />
          )}
        </>
      )}
    </Pane>
  )
}

export default GuarantorPlans
