import { useState } from 'react'

import { useMutation } from '@apollo/client'
import { UPDATE_MEMBERSHIP, PAUSE_MEMBERSHIP, TERMINATE_MEMBERSHIP } from 'lib/graphql/_modify-membership-dropdown'

import { Popover, Position, Menu, toaster } from 'evergreen-ui'

import { Types, Button, ButtonProps, Icon } from 'lib'

import { ConfirmDialog } from 'lib'
import { SetDateDialog } from 'lib'
import { SelectPlanDialog } from 'lib'
import { ResumeMembershipDialog } from 'lib'

import moment from 'moment'

export type Props = ButtonProps & {
  contact: {
    id: string
    guarantorId?: string | null
    familyMemberCount: number

    email: string | null
    phone: string | null
    perio: boolean
    dob: Types.PatientDetails_contact_dob | null

    plan: {
      id: string
    }
    planTerm: Types.PlanTerm
    stripe: {
      membership: {
        id: string
        autoRenew: boolean
        status: Types.SubscriptionStatus
      }
    }
  }
  isPortal?: boolean
}

const ModifyMembershipDropdown = ({ contact, isPortal, ...props }: Props) => {
  if (!contact.dob) throw Error('Member missing DOB')

  const [isSelectPlanDialogShown, setIsSelectPlanDialogShown] = useState(false)
  const [isSetDateDialogShown, setIsSetDateDialogShown] = useState(false)
  const [isDisableAutoRenewConfirmDialogShown, setIsDisableAutoRenewConfirmDialogShown] = useState(false)
  const [isPauseConfirmDialogShown, setIsPauseConfirmDialogShown] = useState(false)
  const [isResumeMembershipDialogShown, setIsResumeMembershipDialogShown] = useState(false)

  const [isTerminateConfirmDialogShown, setIsTerminateConfirmDialogShown] = useState(false)

  const [updateMembership, updateMembershipStatus] = useMutation<
    Types.UpdateMembership,
    Types.UpdateMembershipVariables
  >(UPDATE_MEMBERSHIP, {
    onCompleted: () => {
      toaster.success(`Membership successfully updated`)
    },
    onError: () => {
      toaster.danger('Unable to update membership')
    }
  })

  const [pauseMembership, pauseMembershipStatus] = useMutation<Types.PauseMembership, Types.PauseMembershipVariables>(
    PAUSE_MEMBERSHIP,
    {
      variables: {
        subscriptionId: contact.stripe.membership.id
      },
      onCompleted: () => {
        toaster.success(`Membership successfully paused`)
      },
      onError: () => {
        toaster.danger('Unable to pause membership')
      }
    }
  )

  const [terminateMembership, terminateMembershipStatus] = useMutation<
    Types.TerminateMembership,
    Types.TerminateMembershipVariables
  >(TERMINATE_MEMBERSHIP, {
    variables: {
      contactId: contact.id
    },
    update: (cache, { data }) => {
      cache.evict({ fieldName: 'patientsConnection' })

      const contact = data?.terminateMembership

      if (contact) {
        // Hide member plan card
        cache.evict({ id: cache.identify({ id: contact.id, __typename: 'Contact' }), fieldName: 'plan' })

        // Refresh dependent card list on dependent plan termination
        if (contact?.guarantorId) {
          cache.modify({
            id: cache.identify({ id: contact.guarantorId, __typename: 'Contact' }),
            fields: {
              dependents: (existingChildrenRefs: { id: string }[], { readField }) =>
                existingChildrenRefs.filter((childRef) => contact.id !== readField('id', childRef))
            }
          })
        }
      }

      cache.gc()
    },
    onCompleted: () => toaster.warning(`Membership successfully terminated`),
    onError: () => toaster.danger('Unable to terminate membership')
  })

  return (
    <>
      <ConfirmDialog
        isShown={isDisableAutoRenewConfirmDialogShown}
        setIsShown={setIsDisableAutoRenewConfirmDialogShown}
        onConfirm={() => updateMembership({ variables: { contactId: contact.id, autoRenew: false } })}
        title="Confirm Auto-Renew Cancellation"
        body="Please confirm that you want to disable automatic renewal of this plan."
        intent="danger"
      />
      <ConfirmDialog
        isShown={isTerminateConfirmDialogShown}
        setIsShown={setIsTerminateConfirmDialogShown}
        onConfirm={() => terminateMembership()}
        title="Confirm Plan Termination"
        body="Are you sure you want to immediately terminate this plan?  You can click on any recent payment to issue a refund."
        intent="danger"
      />
      <ConfirmDialog
        isShown={isPauseConfirmDialogShown}
        setIsShown={setIsPauseConfirmDialogShown}
        onConfirm={() => pauseMembership()}
        title="Confirm Plan Pause"
        body="Are you sure you want to pause this plan? Billing will be paused until you resume the plan."
        intent="danger"
      />
      <ResumeMembershipDialog
        isShown={isResumeMembershipDialogShown}
        setIsShown={setIsResumeMembershipDialogShown}
        subscriptionId={contact.stripe.membership.id}
      />

      <SetDateDialog
        isShown={isSetDateDialogShown}
        setIsShown={setIsSetDateDialogShown}
        onSubmit={({ day, month, year }) => {
          const renewalMoment = moment({ day, month: month - 1, year })
          updateMembership({ variables: { contactId: contact.id, renewalAnchor: renewalMoment.unix() } })
        }}
        title="Change Renewal Date"
        body="Please enter the revised plan renewal date:"
      />
      <SelectPlanDialog
        isShown={isSelectPlanDialogShown}
        setIsShown={setIsSelectPlanDialogShown}
        contactId={contact.id}
        familyMemberCount={contact.familyMemberCount}
        selectPlan={({ plan, term, couponCode }) => {
          updateMembership({
            variables: {
              contactId: contact.id,
              switchPlanId: plan.id,
              switchPlanTerm: term,
              coupon: couponCode
            }
          })
        }}
        currentPlan={{ id: contact.plan.id, term: contact.planTerm }}
      />
      <Popover
        position={Position.BOTTOM_LEFT}
        content={({ close }) => (
          <Menu>
            <Menu.Group>
              {contact.stripe.membership.autoRenew ? (
                <Menu.Item onSelect={() => setIsDisableAutoRenewConfirmDialogShown(true)}>
                  Turn Auto-Renew Off
                </Menu.Item>
              ) : (
                <Menu.Item
                  onSelect={() => {
                    close()
                    updateMembership({ variables: { contactId: contact.id, autoRenew: true } })
                  }}
                >
                  Turn Auto-Renew On
                </Menu.Item>
              )}
              {!isPortal && <Menu.Item onSelect={() => setIsSetDateDialogShown(true)}>Change Renewal Date</Menu.Item>}
            </Menu.Group>

            {!isPortal && (
              <>
                <Menu.Divider />
                <Menu.Group>
                  {contact.stripe.membership.status === Types.SubscriptionStatus.ACTIVE && (
                    <Menu.Item onSelect={() => setIsPauseConfirmDialogShown(true)}>Pause Billing</Menu.Item>
                  )}

                  {contact.stripe.membership.status === Types.SubscriptionStatus.PAUSED && (
                    <Menu.Item onSelect={() => setIsResumeMembershipDialogShown(true)}>Resume Billing</Menu.Item>
                  )}

                  <Menu.Item onSelect={() => setIsSelectPlanDialogShown(true)}>Switch Plan</Menu.Item>
                  <Menu.Item
                    onSelect={() => {
                      close()
                      setIsTerminateConfirmDialogShown(true)
                    }}
                    intent="danger"
                  >
                    Terminate Now
                  </Menu.Item>
                </Menu.Group>
              </>
            )}
          </Menu>
        )}
      >
        <Button
          {...props}
          justifyContent="space-between"
          isLoading={
            updateMembershipStatus.loading || terminateMembershipStatus.loading || pauseMembershipStatus.loading
          }
        >
          Modify
          <Icon icon={['far', 'chevron-down']} marginLeft={8} />
        </Button>
      </Popover>
    </>
  )
}

export default ModifyMembershipDropdown
