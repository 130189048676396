import gql from 'graphql-tag'

export const UPDATE_ACCOUNT_DEFAULT_BALANCE_TYPE = gql`
  mutation UpdateAccountDefaultBalanceType(
    $balanceType: BalanceType!
    $updateCurrent: Boolean
    $updateOutstanding: Boolean
  ) {
    updateAccountDefaultBalanceType(
      balanceType: $balanceType
      updateCurrent: $updateCurrent
      updateOutstanding: $updateOutstanding
    ) {
      id
      defaultBalanceType
    }
  }
`
