import { useState } from 'react'
import styled from 'styled-components/macro'
import WritebackButton from 'components/_sheets/charge-sheet/writeback-button'
import WritebackStatusCard from 'components/_sheets/charge-sheet/writeback-status-card'

import { useQuery, useMutation } from '@apollo/client'
import { GET_CHARGE_SHEET, UPDATE_CHARGE, RESEND_RECEIPT, GENERATE_CHARGE_RECEIPT_PDF } from 'graphql/_charge-sheet'

import { SideSheet, Pane, Text, Heading, Switch, Table, toaster, Strong, Elevation } from 'evergreen-ui'

import {
  Types,
  Card,
  CardHeader,
  Icon,
  Button,
  colors,
  Spinner,
  toDollarsFromCents,
  StatusBadge,
  getPrettyTransactionType
} from 'lib'

import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'

import moment from 'moment'
import { capital } from 'case'

import { useCheckMinRole } from 'utility/roles'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  chargeId: string
}

const ChargeSheet = ({ isShown, setIsShown, chargeId }: Props) => {
  const global = useGlobal()
  const checkMinRole = useCheckMinRole()
  const anonymize = global?.meUser.google.anonymize

  const [recordedState, setRecordedState] = useState(undefined as boolean | undefined)
  const showGuarantorSheet = useModal('guarantor')
  const showRefundDialog = useModal('refund')

  const { loading, error, data } = useQuery<Types.ChargeSheet, Types.ChargeSheetVariables>(GET_CHARGE_SHEET, {
    variables: { chargeId }
  })

  const charge = data?.charge
  const contact = charge?.contact

  const isChargeWritebackEligible =
    charge?.contact.account.capabilities.includes(Types.AccountCapability.WRITEBACK) && !charge.recorded

  const transactionWritebackMetrics = charge?.transaction.writebackMetrics
  const transactionWritebackStatus = transactionWritebackMetrics?.status
  const hasWritebackFailed = transactionWritebackStatus === Types.TransactionWritebackStatus.FAILED
  const isWritebackPending = transactionWritebackStatus === Types.TransactionWritebackStatus.PENDING

  const cardProps = {
    backgroundColor: 'white',
    elevation: 0 as Elevation,
    padding: 0,
    flexShrink: 0
  }

  const [update] = useMutation<Types.UpdateCharge, Types.UpdateChargeVariables>(UPDATE_CHARGE, {
    onCompleted: () => toaster.success(`Charge successfully updated!`),
    onError: () => {
      toaster.danger('Unable to update charge')
      setRecordedState(data?.charge.recorded)
    }
  })

  const [resendReceipt, resendReceiptStatus] = useMutation<Types.ResendReceipt>(RESEND_RECEIPT, {
    variables: { stripeChargeId: chargeId },
    onCompleted: () => toaster.success(`Receipt successfully resent!`),
    onError: () => toaster.danger('Unable to resend receipt, please confirm patient email address')
  })

  const [generateChargePdf, generateChargePdfStatus] = useMutation<
    Types.GeneratePracticeChargeReceiptPdf,
    Types.GeneratePracticeChargeReceiptPdfVariables
  >(GENERATE_CHARGE_RECEIPT_PDF, {
    variables: { chargeId },
    onCompleted: ({ generateChargeReceiptPdf }) => window.open(generateChargeReceiptPdf)
  })

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      {loading || error || !charge || !contact ? (
        <Pane display="flex" alignItems="center" height="100%" background="blueTint">
          <Spinner delay={0} />
        </Pane>
      ) : (
        <SheetLayout>
          <CardHeader gridArea="header" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Pane display="flex" alignItems="center">
              <Icon icon={['fad', 'file-invoice-dollar']} size="2x" color="selected" paddingLeft={4} />
              <Pane marginLeft={16}>
                <Heading size={600}>Charge</Heading>
                {charge.transaction && (
                  <Text size={400}>
                    <Strong size={400}>Type: </Strong>
                    {getPrettyTransactionType(charge.transaction)}
                  </Text>
                )}
              </Pane>
            </Pane>
            <Pane display="flex" flexDirection="column" alignItems="flex-end">
              <StatusBadge.Charge marginBottom={8} status={charge.status} />
              <Pane display="flex" alignItems="center">
                <Text size={400} marginRight={8}>
                  {transactionWritebackStatus && !hasWritebackFailed
                    ? `Writeback${isWritebackPending ? ' Pending' : ''}`
                    : 'Recorded'}
                </Text>
                <Switch
                  checked={
                    transactionWritebackStatus
                      ? isWritebackPending
                        ? false
                        : hasWritebackFailed
                        ? false
                        : true
                      : recordedState === undefined
                      ? charge.recorded
                      : recordedState
                  }
                  color="muted"
                  onChange={(e) => {
                    setRecordedState(e.target.checked)
                    update({ variables: { chargeId, recorded: e.target.checked } })
                  }}
                  disabled={!!transactionWritebackStatus || isWritebackPending}
                />
              </Pane>
            </Pane>
          </CardHeader>

          <Pane
            gridArea="body"
            overflow="auto"
            background="blueTint"
            padding={16}
            display="flex"
            flexDirection="column"
            gap={16}
          >
            <Card {...cardProps}>
              <CardHeader withButton>
                <Heading size={500}>Patient</Heading>
                <Button
                  iconBefore={['fas', 'user']}
                  onClick={() =>
                    showGuarantorSheet({
                      guarantorId: charge.contact?.id as string,
                      initialPath: Types.GuarantorPath.BILLING,
                      initialBillingPath: Types.GuarantorBillingPath.PROCESSED
                    })
                  }
                >
                  View Patient
                </Button>
              </CardHeader>
              <Pane padding={16} display="flex" flexDirection="column">
                <Text size={500} marginBottom={8}>
                  <Strong size={500}>Name: </Strong>
                  {contact.name.first} {contact.name.last}
                </Text>
                <Text size={500}>
                  <Strong size={500}>Patient ID: </Strong>
                  {anonymize ? 'ABC123' : contact.code.toUpperCase()}
                </Text>
              </Pane>
            </Card>
            <Card {...cardProps}>
              <CardHeader>
                <Heading size={500}>Paid - {toDollarsFromCents(charge.balanceAmount, 2)}</Heading>
              </CardHeader>
              <Pane padding={16}>
                <Pane padding={8} display="flex" flexDirection="column">
                  <Text size={500} marginBottom={8}>
                    <Strong size={500}>Paid On: </Strong>
                    {moment.unix(charge.date).format('M/D/YYYY')}
                  </Text>

                  {charge.invoice && (
                    <>
                      <Text size={500} marginBottom={8}>
                        <Strong size={500}>Due On: </Strong>
                        {moment.unix(charge.invoice?.date).format('M/D/YYYY')}
                      </Text>
                      <Text size={500}>
                        <Strong size={500}>Invoice Code: </Strong>
                        {charge.invoice?.code}
                      </Text>
                    </>
                  )}

                  {!!charge.payoutDate && (
                    <Text size={500} marginTop={8}>
                      <Strong size={500}>Deposited On: </Strong>
                      {moment.unix(charge.payoutDate).format('M/D/YYYY')}
                    </Text>
                  )}

                  <Pane
                    height={1}
                    marginY={16}
                    width={48}
                    alignSelf="center"
                    backgroundColor={colors.border.default}
                  ></Pane>
                  <Text size={500} marginBottom={16}>
                    <Strong size={500}>Payment Method: </Strong>
                    {capital(charge.institution)} **{charge.last4}
                  </Text>

                  {!!(typeof charge.practiceFee === 'number' && typeof charge.netAmount === 'number') && (
                    <Table borderRadius={4} style={{ border: `solid 1px ${colors.border.default}` }}>
                      <Table.Head height={32} background="white" paddingRight={0}>
                        <Table.TextHeaderCell flexShrink={0}>Amount</Table.TextHeaderCell>
                        <Table.TextHeaderCell textAlign="center">Fees</Table.TextHeaderCell>
                        <Table.TextHeaderCell textAlign="right">Net Deposit</Table.TextHeaderCell>
                      </Table.Head>
                      <Table.Row height={32} borderBottom="none">
                        <Table.TextCell>{toDollarsFromCents(charge.balanceAmount, 2)}</Table.TextCell>
                        <Table.TextCell textAlign="center">{toDollarsFromCents(charge.practiceFee, 2)}</Table.TextCell>
                        <Table.TextCell textAlign="right">{toDollarsFromCents(charge.netAmount, 2)}</Table.TextCell>
                      </Table.Row>
                    </Table>
                  )}
                  {!!charge.patientFee && (
                    <Text
                      color="muted"
                      size={300}
                      marginTop={4}
                      textAlign="right"
                      marginRight={12}
                      fontStyle="italic"
                      // fontWeight={300}
                    >
                      Patient Fee: {toDollarsFromCents(charge.patientFee, 2)}
                    </Text>
                  )}
                </Pane>
                {charge.status === Types.ChargeStatus.SUCCEEDED && (
                  <Pane display="grid" gridAutoFlow="column" gridAutoColumns="1fr" gridGap={8} marginTop={16}>
                    <Button
                      iconAfter={['fas', 'receipt']}
                      onClick={() => generateChargePdf()}
                      isLoading={generateChargePdfStatus.loading}
                    >
                      Receipt
                    </Button>

                    <Button
                      onClick={() => resendReceipt()}
                      iconAfter={['fas', 'paper-plane']}
                      isLoading={resendReceiptStatus.loading}
                    >
                      Resend
                    </Button>
                    <Button
                      iconAfter={['fas', 'undo']}
                      onClick={() => showRefundDialog({ charge })}
                      disabled={!checkMinRole(Types.UserRole.STANDARD) || charge.refundedAmount > 0}
                    >
                      Refund
                    </Button>
                  </Pane>
                )}
              </Pane>
            </Card>
            {charge.refundedAmount > 0 && (
              <Card {...cardProps}>
                <CardHeader>
                  <Heading size={500}>Refund Issued - {toDollarsFromCents(charge.refundedAmount, 2)}</Heading>
                </CardHeader>
              </Card>
            )}
            {charge.disputedAmount > 0 && (
              <Card {...cardProps}>
                <CardHeader>
                  <Heading size={500}>Dispute Refunded - {toDollarsFromCents(charge.disputedAmount, 2)}</Heading>
                </CardHeader>
              </Card>
            )}

            {isChargeWritebackEligible && (
              <>
                <WritebackButton charge={charge} />
                <WritebackStatusCard charge={charge} {...cardProps} />
              </>
            )}
          </Pane>
        </SheetLayout>
      )}
    </SideSheet>
  )
}

export default ChargeSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
