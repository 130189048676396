import gql from 'graphql-tag'

const fragments = {
  InstallmentsTemplateFields: gql`
    fragment InstallmentsTemplateFields on InstallmentTemplate {
      id
      name
      interval
      periods
      intervalType
      enabled

      balanceFilter {
        id
        minBalance
        maxBalance
        allowL30
        allowL60
        allowL90
        allowG90
      }

      allowCC
      allowACH

      adminFee
      downPayment
    }
  `
}

export const UPSERT_INSTALLMENT_TEMPLATE = gql`
  mutation UpsertInstallmentTemplate(
    $id: ID
    $name: String!
    $interval: Int!
    $periods: Int!
    $balanceFilter: PartialBalanceFilterInput!
    $intervalType: InstallmentIntervalType!
    $allowCC: Boolean!
    $allowACH: Boolean!
    $adminFee: Int!
    $downPayment: Int!
  ) {
    upsertInstallmentTemplate(
      id: $id
      name: $name
      interval: $interval
      periods: $periods
      balanceFilter: $balanceFilter
      intervalType: $intervalType
      allowCC: $allowCC
      allowACH: $allowACH

      adminFee: $adminFee
      downPayment: $downPayment
    ) {
      ...InstallmentsTemplateFields
    }
  }
  ${fragments.InstallmentsTemplateFields}
`

export const DELETE_INSTALLMENT_TEMPLATE = gql`
  mutation DeleteInstallmentTemplate($id: ID!) {
    deleteInstallmentTemplate(id: $id) {
      id
    }
  }
`
