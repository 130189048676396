import { Card, CardHeader, Types, Icon, colors, Button } from 'lib'

import { Badge, CardProps, Heading, Pane, Paragraph, Spinner, toaster } from 'evergreen-ui'
import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'
import { useMutation, useQuery } from '@apollo/client'
import { START_GUARANTOR_FLOW_ACTION, GET_START_GUARANTOR_OUTREACH_CARD } from 'graphql/_start-guarantor-outreach-card'
import { useState } from 'react'

export interface Props extends CardProps {
  guarantorId: string
}

const StartGuarantorOutreachCard = ({ guarantorId, ...props }: Props) => {
  const { updateGuarantorSheetState } = useGuarantorSheetState()

  const [isComplete, setIsComplete] = useState<Types.StartGurantorFlowAction_startFlow | null>(null)

  const { data } = useQuery<Types.StartGuarantorOutreachCard, Types.StartGuarantorOutreachCardVariables>(
    GET_START_GUARANTOR_OUTREACH_CARD,
    {
      variables: {
        id: guarantorId
      }
    }
  )

  const [startFlow, startFlowStatus] = useMutation<
    Types.StartGurantorFlowAction,
    Types.StartGurantorFlowActionVariables
  >(START_GUARANTOR_FLOW_ACTION, {
    variables: {
      guarantorId
    },
    update: (cache) => {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          balanceMetrics: (_existing, { DELETE }) => DELETE,
          balanceAssistant: (_existing, { DELETE }) => DELETE,
          balancesConnection: (_existing, { DELETE }) => DELETE
        }
      })
      const cacheId = cache.identify({ id: guarantorId, __typename: 'Contact' })
      cache.evict({ id: cacheId, fieldName: 'outreach' })
      cache.evict({ id: cacheId, fieldName: 'balance' })

      cache.gc()
    },
    onCompleted: (data) => setIsComplete(data.startFlow),
    onError: () => toaster.danger('Unable to start outreach')
  })

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  const { first, last } = data.contact.name

  return !isComplete ? (
    <Card {...props} padding={0}>
      <CardHeader>
        <Heading>Confirm Outreach Start</Heading>
      </CardHeader>
      <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding={36}>
        <Icon icon={['fad', 'paper-plane']} color={colors.blue.base} size="5x" marginBottom={20} />

        <Paragraph size={400} textAlign="center">
          The first notification will be sent immediately, and then automatic reminders will be sent based on your
          automation sendings.
        </Paragraph>

        <Button
          height={48}
          marginY={16}
          isLoading={startFlowStatus.loading}
          disabled={startFlowStatus.loading}
          onClick={() => startFlow()}
          appearance="primary"
        >
          Start Outreach
        </Button>
      </Pane>
    </Card>
  ) : (
    <Card {...props} padding={0}>
      <CardHeader justifyContent="space-between">
        <Heading>Outreach Started</Heading>
        <Badge color="blue">Completed</Badge>
      </CardHeader>
      <Pane padding={36} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Icon icon={['fad', 'check']} color="success" size="5x" marginBottom={16} />
        <Heading marginBottom={16}>Outreach Started</Heading>
        <Paragraph size={500} textAlign="center">
          {first} {last} has successfully been engaged for billing outreach.
        </Paragraph>
        <Button
          iconBefore={['fal', 'envelopes-bulk']}
          height={48}
          marginY={16}
          onClick={() =>
            updateGuarantorSheetState({
              path: Types.GuarantorPath.BILLING,
              billingPath: Types.GuarantorBillingPath.OUTREACH
            })
          }
        >
          View Outreach Details
        </Button>
        <Paragraph textAlign="center">
          To view outreach history, please click the <br />
          View Outreach Detials button above.
        </Paragraph>
      </Pane>
    </Card>
  )
}

export default StartGuarantorOutreachCard
