import moment from 'moment'

import { Pane, Text, Strong, Table, Heading, Alert, CardProps } from 'evergreen-ui'
import { Types, Icon, Card, toDollarsFromCents, CardHeader, colors, Spinner } from 'lib'
import { ReactNode } from 'react'

const paymentAmountSuffix: Record<Types.PostingAmount, string> = {
  [Types.PostingAmount.GROSS]: 'Gross',
  [Types.PostingAmount.NET]: 'Net',
  [Types.PostingAmount.NET_PLUS_ADJUSTMENT]: 'Net'
}

export type WritebackStatusCardProps = CardProps & {
  charge: Types.ChargeSheet_charge
}

export const WritebackStatusCard = ({ charge, ...props }: WritebackStatusCardProps) => {
  const transactionWritebackMetrics = charge.transaction.writebackMetrics
  if (!transactionWritebackMetrics) return null
  const {
    postDate,
    status: transactionWritebackStatus,
    totalPosted,
    paymentsTotal,
    payments: writebackPayments,
    adjustmentsTotal,
    unallocatedTotal,
    allocatedTotal
  } = transactionWritebackMetrics

  const isWritebackPartiallyFailed = transactionWritebackStatus === Types.TransactionWritebackStatus.PARTIALLY_FAILED
  const isWritebackPendingOrInitialized =
    transactionWritebackStatus === Types.TransactionWritebackStatus.PENDING ||
    transactionWritebackStatus === Types.TransactionWritebackStatus.INITIALIZED
  const isWritebackSucceeded = transactionWritebackStatus === Types.TransactionWritebackStatus.SUCCEEDED
  const isWritebackSucceededNeedsReview =
    transactionWritebackStatus === Types.TransactionWritebackStatus.SUCCEEDED_SOME_UNALLOCATED
  const postingSettings = charge.contact.account.postingSettings

  if (isWritebackPendingOrInitialized) {
    return (
      <Alert intent="none" title="Writeback To PMS Pending">
        Note that it can take up to 24 hours to post. View the charge table for the latest status.
      </Alert>
    )
  }

  if (postDate && (isWritebackSucceeded || isWritebackSucceededNeedsReview)) {
    return (
      <Card {...props}>
        <CardHeader display="flex" gap={4}>
          <Heading>{`PMS Payment Post ${
            isWritebackSucceededNeedsReview ? '(Review Unallocated)' : 'Successful'
          }`}</Heading>
          <Icon
            icon={['fad', 'square-check']}
            size="1x"
            color={isWritebackSucceededNeedsReview ? 'danger' : 'success'}
            paddingLeft={4}
          />
        </CardHeader>
        <Pane display="flex" flexDirection="column" padding={24} gap={16}>
          <Pane display="grid" gridTemplateColumns="5fr 5fr" rowGap={16}>
            <Strong size={500} gridColumn="1 / 2">
              Payment Amount:
            </Strong>
            <Text size={500} gridColumn="2 / 3">
              {toDollarsFromCents(paymentsTotal ?? 0, 2) + ` (${paymentAmountSuffix[postingSettings.postingAmount]})`}
            </Text>
            {adjustmentsTotal !== null && (
              <>
                <Strong size={500} gridColumn="1 / 2">
                  Credit Amount:
                </Strong>
                <Text size={500} gridColumn="2 / 3">
                  {toDollarsFromCents(adjustmentsTotal, 2)}
                </Text>
              </>
            )}
            <Strong size={500} gridColumn="1 / 2">
              Transaction Date:
            </Strong>
            <Text size={500} gridColumn="2 / 3">
              {moment.unix(postDate).format('M/DD/YYYY')}
            </Text>
          </Pane>
          <Strong size={500} marginTop={16}>
            Allocation Details:
          </Strong>
          {!writebackPayments ? (
            <Pane display="flex" alignItems="center" height={100}>
              <Spinner delay={0} />
            </Pane>
          ) : (
            <Table borderRadius={4} style={{ border: `solid 1px ${colors.border.default}` }}>
              <Table.Head height={32} background="white" paddingRight={0}>
                <Table.TextHeaderCell flexBasis={100}>Procedure ID</Table.TextHeaderCell>
                <Table.TextHeaderCell textAlign="center">Date</Table.TextHeaderCell>
                <Table.TextHeaderCell textAlign="right">Amount</Table.TextHeaderCell>
              </Table.Head>
              {writebackPayments
                .slice()
                .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
                .map((wb) => {
                  const italicize = isWritebackSucceededNeedsReview && wb.pms_procedureId === null
                  return (
                    <Table.Row key={wb.pms_procedureId} height={32} borderBottom="none">
                      <Table.TextCell flexBasis={100}>
                        {styleText({ italicize, text: wb.pms_procedureId ?? 'Unallocated' })}
                      </Table.TextCell>
                      <Table.TextCell textAlign="center">
                        {styleText({ italicize, text: moment.unix(postDate).format('M/DD/YY') })}
                      </Table.TextCell>
                      <Table.TextCell textAlign="right">
                        {styleText({ italicize, text: toDollarsFromCents(Math.abs(wb.amount), 2) })}
                      </Table.TextCell>
                    </Table.Row>
                  )
                })}
              <Table.Head height={0} marginY={4} />
              <Table.Row height={32} borderBottom="none">
                <Table.Cell flexBasis={40} />
                <Table.TextCell flexBasis={80} textAlign="right">
                  Allocated Total:
                </Table.TextCell>
                <Table.TextCell textAlign="right">{toDollarsFromCents(allocatedTotal ?? 0, 2)}</Table.TextCell>
              </Table.Row>
              <Table.Row height={32} borderBottom="none">
                <Table.Cell flexBasis={40} />
                <Table.TextCell flexBasis={80} textAlign="right">
                  Unallocated Total:
                </Table.TextCell>
                <Table.TextCell textAlign="right">{toDollarsFromCents(unallocatedTotal ?? 0, 2)}</Table.TextCell>
              </Table.Row>
              {adjustmentsTotal !== null && adjustmentsTotal !== 0 && (
                <Table.Row height={32} borderBottom="none">
                  <Table.Cell flexBasis={40} />
                  <Table.TextCell flexBasis={80} textAlign="right">
                    Credit Total:
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">{toDollarsFromCents(adjustmentsTotal, 2)}</Table.TextCell>
                </Table.Row>
              )}
              <Table.Row height={32} borderBottom="none">
                <Table.Cell flexBasis={40} />
                <Table.TextCell flexBasis={80} textAlign="right">
                  <Strong size={300}>Total:</Strong>
                </Table.TextCell>
                <Table.TextCell textAlign="right">
                  <Strong size={300}>{toDollarsFromCents(Math.abs(totalPosted!), 2)}</Strong>
                </Table.TextCell>
              </Table.Row>
            </Table>
          )}
        </Pane>
      </Card>
    )
  }

  if (isWritebackPartiallyFailed) {
    return (
      <Card backgroundColor="white" elevation={0} padding={0}>
        <Pane display="flex" flexDirection="column" padding={16} gap={8}>
          <Alert intent="warning" title={`Writeback To PMS Partially Failed`}>
            Please review this charge in your PMS.
          </Alert>
          <Pane padding={8} display="flex" flexDirection="column" gap={8}>
            {totalPosted ? (
              <>
                <Text size={500}>
                  <Strong size={500}>Unreflected: </Strong>
                  <Text size={500}>
                    {toDollarsFromCents(
                      postingSettings?.postingAmount === Types.PostingAmount.NET && charge.netAmount
                        ? charge.netAmount + totalPosted
                        : charge.balanceAmount + totalPosted,
                      2
                    )}
                  </Text>
                </Text>
                <Text size={500}>
                  <Strong size={500}>Posted: </Strong>
                  <Text size={500}>{toDollarsFromCents(Math.abs(totalPosted), 2)}</Text>
                </Text>
              </>
            ) : (
              <Text size={500}>A portion of this charge failed to post to the PMS</Text>
            )}
          </Pane>
        </Pane>
      </Card>
    )
  }

  return null
}

const styleText = ({ text, italicize }: { text: ReactNode; italicize: boolean }) => (italicize ? <i>{text}</i> : text)

export default WritebackStatusCard
