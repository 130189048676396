import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import 'normalize.css'
import 'typeface-open-sans'
import './index.css'
import './env'
import { initializeIconLibrary } from 'utility/icon-library'

import App from './App'

initializeIconLibrary()

if (window._env_.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: window._env_.REACT_APP_SENTRY_URL,
    environment: window._env_.REACT_APP_ENV
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
