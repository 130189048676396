import { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { TOGGLE_SUNBIT } from 'graphql/_sunbit-toggle'

import { Types } from 'lib'

import { Pane, Label, toaster, Switch as EvergreenSwitch, PaneProps } from 'evergreen-ui'

import { useCheckMinRole } from 'utility/roles'

export interface Props extends PaneProps {
  sunbitConfiguration: {
    id: string
    enabled: boolean
  }
}

const SunbitToggle = ({ sunbitConfiguration: sunbit, ...props }: Props) => {
  const checkMinRole = useCheckMinRole()

  const [enabledState, setEnabledState] = useState(sunbit.enabled)

  useEffect(() => setEnabledState(sunbit.enabled), [sunbit.enabled])

  const [toggleSunbit, toggleStatus] = useMutation<Types.ToggleSunbit, Types.ToggleSunbitVariables>(TOGGLE_SUNBIT, {
    onCompleted: (data) => {
      if (data.toggleSunbit.enabled) {
        toaster.success(`Sunbit enabled!`)
      } else {
        toaster.warning(`Sunbit disabled!`)
      }
    },
    onError: () => toaster.danger('Unable to toggle Sunbit')
  })

  return (
    <Pane display="flex" alignItems="center" {...props}>
      <Label marginRight={8}>Enabled</Label>
      <EvergreenSwitch
        marginBottom={0}
        height={24}
        onChange={({ currentTarget: { checked } }) => {
          setEnabledState(checked)
          toggleSunbit({ variables: { enabled: checked } })
        }}
        checked={enabledState}
        disabled={!checkMinRole(Types.UserRole.STANDARD) || toggleStatus.loading}
      />
    </Pane>
  )
}

export default SunbitToggle
