import 'ui-box/dist/src/types/box-types'

import { Badge, BadgeProps } from 'evergreen-ui'

import { Types } from 'lib'

enum InvoiceStatus {
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  OTHER = 'OTHER',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID',
  PAUSED = 'PAUSED' // Custom status for draft invoices on paused plans
}

enum ChargeStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED'
}

enum TransactionType {
  ADJUSTMENT = 'ADJUSTMENT',
  COLLECTION_ADJUSTMENT = 'COLLECTION_ADJUSTMENT',
  OTHER_ADJUSTMENT = 'OTHER_ADJUSTMENT',
  PAYMENT = 'PAYMENT',
  INSURANCE_PAYMENT = 'INSURANCE_PAYMENT',
  PEARLY_ADJUSTMENT = 'PEARLY_ADJUSTMENT',
  PEARLY_MEMBERSHIP = 'PEARLY_MEMBERSHIP',
  PEARLY_PAYMENT = 'PEARLY_PAYMENT',
  PEARLY_PLAN = 'PEARLY_PLAN',
  PEARLY_REFUND = 'PEARLY_REFUND',
  PROCEDURE = 'PROCEDURE',
  CLAIM_DENIED = 'CLAIM_DENIED'
}

type Color = 'automatic' | 'neutral' | 'blue' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'purple'
type ColorMap<T extends string> = { [x in T]: { color: Color; label: string } }

// export type Props = BadgeProps & {
//   status: ChargeStatus | InvoiceStatus | Types.SubscriptionStatus | TransactionType
// }

const generateBadgeComponent = <T extends ChargeStatus | InvoiceStatus | Types.SubscriptionStatus | Types.InstallmentPlanStatus | TransactionType | Types.ScheduledPaymentStatus>(
  colorMap: ColorMap<T>
) => ({ status, ...props }: { status: T } & BadgeProps) => {
  const { color, label } = colorMap[status]

  return (
    <Badge color={color} {...props}>
      {label}
    </Badge>
  )
}

const Invoice = generateBadgeComponent({
  [InvoiceStatus.OPEN]: {
    color: 'red',
    label: 'failed'
  },
  [InvoiceStatus.PAUSED]: {
    color: 'red',
    label: 'failed'
  },
  [InvoiceStatus.VOID]: {
    color: 'orange',
    label: 'void'
  },
  [InvoiceStatus.DRAFT]: {
    color: 'blue',
    label: 'scheduled'
  },
  [InvoiceStatus.UNCOLLECTIBLE]: {
    color: 'neutral',
    label: 'uncollectible'
  },
  [InvoiceStatus.DELETED]: {
    color: 'red',
    label: 'deleted'
  },
  [InvoiceStatus.OTHER]: {
    color: 'neutral',
    label: 'other'
  },

  [InvoiceStatus.PAID]: {
    color: 'green',
    label: 'paid'
  }
})

const Charge = generateBadgeComponent({
  [ChargeStatus.SUCCEEDED]: {
    color: 'green',
    label: 'succeeded'
  },
  [ChargeStatus.PENDING]: {
    color: 'yellow',
    label: 'pending'
  },
  [ChargeStatus.FAILED]: {
    color: 'red',
    label: 'failed'
  }
})

const Subscription = generateBadgeComponent({
  [Types.SubscriptionStatus.ACTIVE]: {
    color: 'blue',
    label: 'active'
  },
  [Types.SubscriptionStatus.CANCELED]: {
    color: 'red',
    label: 'canceled'
  },
  [Types.SubscriptionStatus.INCOMPLETE]: {
    color: 'orange',
    label: 'incomplete'
  },
  [Types.SubscriptionStatus.COMPLETED]: {
    color: 'green',
    label: 'completed'
  },
  [Types.SubscriptionStatus.PAUSED]: {
    color: 'yellow',
    label: 'paused'
  },
  [Types.SubscriptionStatus.PAST_DUE]: {
    color: 'orange',
    label: 'past due'
  }
})

const InstallmentPlanStatus = generateBadgeComponent({
  [Types.InstallmentPlanStatus.ACTIVE]: {
    color: 'blue',
    label: 'active'
  },
  [Types.InstallmentPlanStatus.COMPLETED]: {
    color: 'green',
    label: 'completed'
  },
  [Types.InstallmentPlanStatus.CANCELED]: {
    color: 'red',
    label: 'canceled'
  },
  [Types.InstallmentPlanStatus.PAUSED]: {
    color: 'yellow',
    label: 'paused'
  },
  [Types.InstallmentPlanStatus.FAILED_RETRYING]: {
    color: 'orange',
    label: 'past due'
  },
  [Types.InstallmentPlanStatus.FAILED_PAUSED]: {
    color: 'orange',
    label: 'past due'
  }
})

const ScheduledPayment = generateBadgeComponent({
  [Types.ScheduledPaymentStatus.FAILED_RETRYING]: {
    color: 'red',
    label: 'failed'
  },
  [Types.ScheduledPaymentStatus.FAILED_PAUSED]: {
    color: 'red',
    label: 'failed'
  },
  [Types.ScheduledPaymentStatus.PAUSED]: {
    color: 'red',
    label: 'failed'
  },
  [Types.ScheduledPaymentStatus.UPCOMING]: {
    color: 'blue',
    label: 'scheduled'
  },
  [Types.ScheduledPaymentStatus.CANCELED]: {
    color: 'red',
    label: 'deleted'
  },
  [Types.ScheduledPaymentStatus.SUCCESS]: {
    color: 'green',
    label: 'paid'
  }
})

export default {
  Invoice,
  Charge,
  Subscription,
  InstallmentPlanStatus,
  ScheduledPayment
}
