import 'graphql'
import gql from 'graphql-tag'

export const GET_ENROLL_MEMBER_CARD = gql`
  query EnrollMemberCard($id: ID!) {
    contact(id: $id) {
      id

      name {
        first
        last
      }
      isGuarantor
      guarantorId
      isFirstFamilyMember
    }
  }
`

export const ENROLL = gql`
  mutation Enroll(
    $contactId: ID!
    $planId: ID!
    $planTerm: PlanTerm!
    $billingCycleAnchor: Int
    $coupon: String
    $stripeToken: String
    $plaidToken: String
    $plaidAccountId: String
  ) {
    enrollMembership(
      contactId: $contactId
      planId: $planId
      planTerm: $planTerm
      billingCycleAnchor: $billingCycleAnchor
      coupon: $coupon
      stripeToken: $stripeToken
      plaidToken: $plaidToken
      plaidAccountId: $plaidAccountId
    ) {
      id
      stripe {
        defaultSource {
          id
          isCredit
          institution
          last4
        }
      }
    }
  }
`
