import { useState, useContext, createContext } from 'react'

import { SideSheet } from 'evergreen-ui'
import { Types, colors } from 'lib'

import Guarantor from 'components/guarantor'
import { merge } from 'lodash'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  guarantorId?: string
  initialPath?: Types.GuarantorPath
  initialBillingPath?: Types.GuarantorBillingPath
  initialPlanType?: Types.PlanType
  initialNotificationChannel?: Types.NotificationChannel
}

const GuarantorSheet = ({
  isShown,
  setIsShown,
  guarantorId,
  initialPath = Types.GuarantorPath.OVERVIEW,
  initialBillingPath = Types.GuarantorBillingPath.ACTIONS,
  initialPlanType,
  initialNotificationChannel
}: Props) => {
  const [guarantorIdState, setGuarantorIdState] = useState(guarantorId)

  return (
    <GuarantorSheetStateProvider
      initialPath={initialPath}
      initialBillingPath={initialBillingPath}
      initialPlanType={initialPlanType}
      initialNotificationChannel={initialNotificationChannel}
    >
      <SideSheet
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        width={400}
        containerProps={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: colors.blue.lightest
        }}
        shouldCloseOnEscapePress={false}
      >
        {({ close }: { close: () => void }) => (
          <>
            {!!guarantorIdState ? (
              <Guarantor.Existing guarantorId={guarantorIdState} closeModal={close} />
            ) : (
              <Guarantor.CreateFind setGuarantorIdState={setGuarantorIdState} initialPath={initialPath} />
            )}
          </>
        )}
      </SideSheet>
    </GuarantorSheetStateProvider>
  )
}

export default GuarantorSheet

const GuarantorSheetContext = createContext<
  | (GuarantorSheetState & { updateGuarantorSheetState: (partialContextState: Partial<GuarantorSheetState>) => void })
  | undefined
>(undefined)

type GuarantorSheetState = {
  path: Types.GuarantorPath
  billingPath: Types.GuarantorBillingPath
  planType?: Types.PlanType
  initialNotificationChannel?: Types.NotificationChannel
}

type GuarantorSheetStateProviderProps = {
  children: React.ReactNode
  initialPath: Types.GuarantorPath
  initialBillingPath: Types.GuarantorBillingPath
  initialPlanType?: Types.PlanType
  initialNotificationChannel?: Types.NotificationChannel
}

const GuarantorSheetStateProvider = ({
  children,
  initialPath,
  initialBillingPath,
  initialPlanType,
  initialNotificationChannel
}: GuarantorSheetStateProviderProps) => {
  const [contextState, setContextState] = useState({
    path: initialPath,
    billingPath: initialBillingPath,
    planType: initialPlanType,
    initialNotificationChannel
  })

  const updateGuarantorSheetState = (update: Partial<GuarantorSheetState>) =>
    setContextState({ ...merge(contextState, update) })

  return (
    <GuarantorSheetContext.Provider value={{ ...contextState, updateGuarantorSheetState }}>
      {children}
    </GuarantorSheetContext.Provider>
  )
}

export const useGuarantorSheetState = () => {
  const context = useContext(GuarantorSheetContext)
  if (context === undefined) {
    throw new Error('useGuarantorSheetState must be used within a GuarantorSheetStateProvider')
  }
  return context
}
