import { useState } from 'react'
import { Types } from 'lib'

import { Pane, Dialog, Paragraph } from 'evergreen-ui'

import { Link } from 'lib'
import { PlanCardList } from 'lib'
import { ConfirmDialog } from 'lib'
import { CouponCard } from 'lib'
import { mc } from 'lib'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  contactId: string
  familyMemberCount: number
  selectPlan: (selectedPlan: { plan: Types.PlanCardList_livePlans; term: Types.PlanTerm; couponCode?: string }) => void
  currentPlan?: { id: string; term: Types.PlanTerm }
}

const SelectPlanDialog = ({ isShown, setIsShown, contactId, familyMemberCount, selectPlan, currentPlan }: Props) => {
  const [isConfirmDialogShown, setIsConfirmDialogShown] = useState(false)

  const [selectedPlan, setSelectedPlan] = useState((undefined as unknown) as Types.PlanCardList_livePlans)
  const [selectedTerm, setSelectedTerm] = useState((undefined as unknown) as Types.PlanTerm)

  const [couponState, setCouponState] = useState<Types.Coupon>()

  return (
    <>
      <Dialog
        isShown={isShown}
        title={currentPlan ? 'Switch Plan' : 'Enroll Existing Patient'}
        onCloseComplete={() => {
          setIsShown(false)
        }}
        confirmLabel={currentPlan ? 'Switch Plan' : 'Enroll'}
        isConfirmDisabled={!selectedPlan}
        onConfirm={() => setIsConfirmDialogShown(true)}
      >
        {({ close }) => {
          return (
            <>
              <ConfirmDialog
                isShown={isConfirmDialogShown}
                setIsShown={setIsConfirmDialogShown}
                onConfirm={() => {
                  selectPlan({
                    plan: selectedPlan,
                    term: selectedTerm,
                    couponCode: couponState?.id
                  })
                  close()
                }}
                body={`Please confirm that you want to ${
                  currentPlan ? `switch this patient's plan.` : `enroll the patient in this plan.`
                }`}
                intent="success"
              />
              <Pane maxHeight={600} overflow="visible" padding={8} paddingBottom={0}>
                <PlanCardList
                  contactId={contactId}
                  selectedPlanId={selectedPlan?.id}
                  selectPlan={(plan, term) => {
                    setSelectedPlan(plan)
                    setSelectedTerm(term)
                  }}
                  hideList={currentPlan ? [currentPlan] : undefined}
                  padding={8}
                  isFirstFamilyMember={familyMemberCount === 1}
                  coupon={couponState}
                />
                <CouponCard onRetrieve={(coupon) => setCouponState(coupon)} marginY={16} />
                {currentPlan && (
                  <Paragraph textAlign="left" marginBottom={16} display="inline-block">
                    If upgrading, the patient will be billed the prorated balance immediately. If downgrading, the
                    patient will receive a credit that will be applied to their next invoice. Please reach out to{' '}
                    <Link inline href={`mailto:${mc.email}`}>
                      {mc.email}
                    </Link>{' '}
                    if you would like to issue a refund.
                  </Paragraph>
                )}
              </Pane>
            </>
          )
        }}
      </Dialog>
    </>
  )
}

export default SelectPlanDialog
