import { useState } from 'react'

import { useApolloClient } from '@apollo/client'

import { Pane, Text, PaneProps } from 'evergreen-ui'

import { Spinner } from 'lib'
import { MemberCard } from 'lib'

import { useQuery } from '@apollo/client'
import { GET_DEPENDENT_MEMBER_CARD_LIST } from 'lib/graphql/_dependent-member-card-list'
import { Types } from 'lib'

import { ContractSheet } from 'lib'
import { DependentSheet } from 'lib'

import DependentsIllustration from 'lib/assets/img/dependents'

type ContractProps = { planId: string; planTerm: Types.PlanTerm; autoRenew: boolean }

export type Props = PaneProps & {
  contactId: string
  showProcessedTab?: () => void
  isPortal?: boolean
}

const DependentMemberCardList = ({ contactId, showProcessedTab, isPortal = false, ...props }: Props) => {
  const apolloClient = useApolloClient()

  const [isContractSheetShown, setIsContractSheetShown] = useState(false)
  const [contractProps, setContractProps] = useState<ContractProps>({} as ContractProps)

  const showContractSheet = (contractProps: ContractProps) => {
    setContractProps(contractProps)
    setIsContractSheetShown(true)
  }

  const [isDependentSheetShown, setIsDependentSheetShown] = useState(false)
  const [selectedDependentId, setSelectedDependentId] = useState('')

  const showDependentSheet = (dependentId: string) => {
    setSelectedDependentId(dependentId)
    setIsDependentSheetShown(true)
  }

  const { loading, error, data } = useQuery<Types.DependentMemberCardList, Types.DependentMemberCardListVariables>(
    GET_DEPENDENT_MEMBER_CARD_LIST,
    {
      variables: {
        contactId
      },

      client: apolloClient
    }
  )

  return (
    <>
      <DependentSheet
        isShown={isDependentSheetShown}
        setIsShown={setIsDependentSheetShown}
        dependentId={selectedDependentId}
        initialPath={Types.DependentPath.PROFILE}
        isPortal={isPortal}
      />

      <ContractSheet
        isShown={isContractSheetShown}
        setIsShown={setIsContractSheetShown}
        planId={contractProps.planId}
        planTerm={contractProps.planTerm}
        autoRenew={contractProps.autoRenew}
      />

      <Pane {...props} display="flex" flexDirection="column" alignItems="center">
        {loading || error || !data ? (
          <Pane display="flex" alignItems="center" height="100%" margin={16}>
            <Spinner delay={0} />
          </Pane>
        ) : data.contact?.dependents.filter((dependent) => dependent.plan).length ? (
          data.contact.dependents.map((dependent, i) => (
            <MemberCard
              key={dependent.id}
              contact={{ ...dependent, familyMemberCount: data.contact.familyMemberCount }}
              showContractSheet={showContractSheet}
              showDependentSheet={showDependentSheet}
              showProcessedTab={showProcessedTab}
              width="100%"
              marginBottom={i === data.contact!.dependents.length - 1 ? 0 : 16}
              isPortal={isPortal}
            />
          ))
        ) : (
          <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={16}>
            <Pane height="112px">
              <DependentsIllustration />
            </Pane>

            <Text marginTop={24} size={500}>
              No dependents have been enrolled...
            </Text>
          </Pane>
        )}
      </Pane>
    </>
  )
}

export default DependentMemberCardList
