import { useState } from 'react'

import { useQuery } from '@apollo/client'
import { GET_ENROLL_INSTALLMENTS_CARD } from 'graphql/_enroll-installments-card'

import { CardProps, Heading, Badge, Pane } from 'evergreen-ui'
import { Types, calculateInstallmentAmounts, Card, CardHeader, Spinner, toDollarsFromCents } from 'lib'

import Amount from './enroll-installments-amount'
import Template from './enroll-installments-template'
import Payment from './enroll-installments-payment'
import Confirm from './enroll-installments-confirm'
import Complete from './enroll-installments-complete'

export interface Props extends CardProps {
  contactId: string
}

const steps = [Amount, Template, Payment, Confirm, Complete]

export type EnrollInstallmentsState = {
  step: number
  amount?: number
  installmentTemplate?: {
    id: string
    interval: number
    intervalType: Types.InstallmentIntervalType
    periods: number
    adminFee: number
    downPayment: number
  } | null
  charge?: {
    id: string
    grossAmount: number
  }
}

const EnrollInstallmentsCard = ({ contactId, ...props }: Props) => {
  const { data } = useQuery<Types.EnrollInstallmentsCard, Types.EnrollInstallmentsCardVariables>(
    GET_ENROLL_INSTALLMENTS_CARD,
    {
      variables: {
        id: contactId
      }
    }
  )

  const [enrollState, setEnrollState] = useState<EnrollInstallmentsState>({
    step: 0
  })

  const updateEnrollState = (update: Partial<EnrollInstallmentsState>) => setEnrollState({ ...enrollState, ...update })

  const { step, amount, installmentTemplate } = enrollState

  const headingText =
    step === 0
      ? 'Plan Amount'
      : amount && installmentTemplate
      ? `Plan Total - ${toDollarsFromCents(calculateInstallmentAmounts({ amount, installmentTemplate }).planTotal, 2)}`
      : amount
      ? `Plan Amount - ${toDollarsFromCents(amount, 2)}`
      : 'Plan'

  const Current = steps[step]
  const stepsCount = steps.length - 1

  if (!data)
    return (
      <Pane display="flex" alignItems="center" height="100%">
        <Spinner delay={0} />
      </Pane>
    )

  return (
    <Card {...props} padding={0}>
      <CardHeader justifyContent="space-between">
        <Heading>{headingText}</Heading>
        {step < stepsCount && (
          <Badge color="blue">
            Step {step + 1}/{stepsCount}
          </Badge>
        )}
      </CardHeader>

      <Current enrollState={enrollState} updateEnrollState={updateEnrollState} guarantor={data.contact} />
    </Card>
  )
}

export default EnrollInstallmentsCard
