import 'graphql'
import gql from 'graphql-tag'

export const GENERATE_INSTALLMENT_TERMS_PDF = gql`
  mutation GenerateInstallmentTermsPdf(
    $contactId: ID!
    $amount: Int!
    $startDate: String!
    $installmentTemplateId: ID
    $installmentPlanId: ID
  ) {
    generateInstallmentTermsPdf(
      contactId: $contactId
      amount: $amount
      startDate: $startDate
      installmentTemplateId: $installmentTemplateId
      installmentPlanId: $installmentPlanId
    )
  }
`
