import * as Yup from 'yup'
import { Dialog, Label, Pane, Text, toaster } from 'evergreen-ui'
import { Form, Formik } from 'formik'
import { colors, FormError, mergeVariables, TextInputField, Types } from 'lib'
import { useMutation } from '@apollo/client'

import { SEND_TEST_SMS_NOTIFICATION } from 'graphql/_notification-template-test-dialog'

import NotificationTestCompleted from './notification-test-completed'

type Props = {
  template: {
    id: string
    channel: Types.NotificationChannel
    name: string
    body: string
  }
  variables: Types.NotificationTemplateVariables
  isShown: boolean
  setIsShown: (isShown: boolean) => void
}

const SmsNotificationTest = ({ template, variables, isShown, setIsShown }: Props) => {
  const mergedTemplate = { body: mergeVariables(template.body || '', variables) }

  const [sendTestNotification, { loading, data }] = useMutation<
    Types.SendTestSmsNotification,
    Types.SendTestSmsNotificationVariables
  >(SEND_TEST_SMS_NOTIFICATION, {
    onError: (e) => toaster.danger(e.message)
  })

  const isCompleted = !!data

  const handleSubmit = ({ phone }: { phone: string }) => {
    sendTestNotification({
      variables: {
        ...mergedTemplate,
        phone
      }
    })
  }

  return (
    <Formik
      initialValues={{ phone: '' }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        phone: Yup.string()
          .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number')
          .required('Phone is required')
      })}
    >
      {(formik) => (
        <Dialog
          isShown={isShown}
          title="Send Test SMS"
          onConfirm={() => formik.handleSubmit()}
          isConfirmLoading={loading}
          hasFooter={!isCompleted}
          onCloseComplete={() => setIsShown(false)}
        >
          {!isCompleted ? (
            <Pane padding={16}>
              <Form>
                <TextInputField name="phone" type="text" label="Phone" placeholder="8051234567" disabled={loading} />
                <FormError />
              </Form>
              <Label display="inline-block" marginBottom={8}>
                Sample Message
              </Label>
              <Pane padding={16} backgroundColor={colors.blue.lightest}>
                <Text whiteSpace="pre-wrap">{mergedTemplate.body}</Text>
              </Pane>
            </Pane>
          ) : (
            <NotificationTestCompleted />
          )}
        </Dialog>
      )}
    </Formik>
  )
}

export default SmsNotificationTest
