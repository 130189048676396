import { Avatar, PaneProps, Heading } from 'evergreen-ui'
import { Card } from 'lib'

export type Props = PaneProps & {
  dentist: {
    salutation: string
    name: string
    degree: string
  }
}

const DentistCard = ({ dentist: { salutation, name, degree }, ...props }: Props) => (
  <Card
    className="dentist-card"
    elevation={1}
    hoverElevation={2}
    {...props}
    display="flex"
    flexDirection="row"
    alignItems="center"
  >
    <Avatar name={name} />

    <Heading marginLeft={8}>{`${salutation}. ${name}${degree !== 'NONE' ? `, ${degree}` : ''}`}</Heading>
  </Card>
)

export default DentistCard
