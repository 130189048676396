import styled from 'styled-components/macro'

import { Pane, Heading } from 'evergreen-ui'
import { Types, Icon, PatientDetails } from 'lib'

export type Props = {
  initialPath: Types.GuarantorPath
  setGuarantorIdState: React.Dispatch<React.SetStateAction<string | undefined>>
}

const GuarantorCreateFind = ({ initialPath, setGuarantorIdState }: Props) => {
  const optionalFields = [Types.PatientField.EMAIL, Types.PatientField.PHONE, Types.PatientField.PERIO]

  const requiredFields: Types.PatientField[] = [Types.PatientField.DOB]

  return (
    <SheetLayout>
      <Pane
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        padding={16}
        borderBottom="muted"
        background="white"
      >
        <Pane height={40} width={40} display="flex" justifyContent="center" alignItems="center">
          <Icon icon={['fad', 'tooth']} size="lg" color="selected" marginRight={16} />
        </Pane>
        <Heading size={600}>Create or Find a Patient</Heading>
      </Pane>

      <Pane gridArea="body" overflow="hidden">
        <PatientDetails
          height={40}
          canSearch
          optionalFields={optionalFields}
          requiredFields={requiredFields}
          button={{ label: 'Create Patient', existingLabel: 'Select Patient', chevron: true }}
          onCreated={(data) => setGuarantorIdState(data.createPatient.id)}
          onSkip={(data) => setGuarantorIdState(data.createPatient.id)}
          onUpdated={(data) => setGuarantorIdState(data.updatePatient.id)}
          disableExistingDependent
          disableExistingMember={initialPath === Types.GuarantorPath.ENROLL_MEMBER}
        />
      </Pane>
    </SheetLayout>
  )
}

export default GuarantorCreateFind

const SheetLayout = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
`
