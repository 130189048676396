import gql from 'graphql-tag'

const fragments = {
  notificationTemplateFields: gql`
    fragment notificationTemplateFields on NotificationTemplate {
      id
      channel
      layout
      name
      body
      title
      subject
      buttonLabel
      buttonUrl
      signature
      createdAt
    }
  `
}

export const GET_TEMPLATE_SHEET = gql`
  query GetTemplateSheet($channel: NotificationChannel!) {
    account {
      id
      name
      publicReplyPhone
      returnAddress {
        id
        name
        street1
        street2
        city
        state
        zip
      }
      checkAddress {
        id
        name
        street1
        street2
        city
        state
        zip
      }
      exampleNotificationTemplate(channel: $channel) {
        ...notificationTemplateFields
      }
    }
  }
  ${fragments.notificationTemplateFields}
`

export const CREATE_NOTIFICATION_TEMPLATE = gql`
  mutation CreateNotificationTemplate(
    $channel: NotificationChannel!
    $layout: NotificationLayout!
    $name: String!
    $body: String!
    $subject: String
    $title: String
    $buttonLabel: String
    $buttonUrl: String
    $signature: String
  ) {
    createNotificationTemplate(
      channel: $channel
      layout: $layout
      name: $name
      body: $body
      subject: $subject
      title: $title
      buttonLabel: $buttonLabel
      buttonUrl: $buttonUrl
      signature: $signature
    ) {
      ...notificationTemplateFields
    }
  }
  ${fragments.notificationTemplateFields}
`

export const UPDATE_NOTIFICATION_TEMPLATE = gql`
  mutation UpdateNotificationTemplate(
    $id: ID!
    $name: String!
    $body: String!
    $subject: String
    $title: String
    $buttonLabel: String
    $buttonUrl: String
    $signature: String
    $layout: NotificationLayout!
  ) {
    updateNotificationTemplate(
      id: $id
      name: $name
      body: $body
      subject: $subject
      title: $title
      buttonLabel: $buttonLabel
      buttonUrl: $buttonUrl
      signature: $signature
      layout: $layout
    ) {
      ...notificationTemplateFields
    }
  }
  ${fragments.notificationTemplateFields}
`
