import { Link, useRouteMatch } from 'react-router-dom'

import { SidebarTab, SidebarTabProps, Pane, Pill } from 'evergreen-ui'
import { Icon } from 'lib'
import { colors } from 'lib'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = SidebarTabProps & {
  to: string
  match?: string | string[]
  icon?: IconProp
  count?: number
  children: string
}

export default ({ to, match, icon, children, count, ...props }: Props) => {
  const isMatched = useRouteMatch({
    path: match,
    exact: true
  })

  return (
    <Link to={to} style={{ pointerEvents: props.disabled ? 'none' : 'auto' }}>
      <SidebarTab size={500} isSelected={!!isMatched} {...props}>
        <Pane display="flex" alignItems="center">
          {icon && (
            <Pane display="inline-block" width="24px" marginTop={-1}>
              <Icon icon={icon} color={isMatched ? colors.blue.base : 'auto'} />
            </Pane>
          )}
          <Pane flexGrow={1}>{children}</Pane>
          {count && (
            <Pill color="green" marginRight={4} fontSize={11} paddingRight={4} paddingLeft={4}>
              {count}
            </Pill>
          )}
        </Pane>
      </SidebarTab>
    </Link>
  )
}
