import { Types } from 'lib'

import { useGlobal } from 'components/global-provider'
import { getTestVariables } from 'utility/notification-template-variables'

import EmailNotificationTest from './email-notification-test'
import SMSNotificationTest from './sms-notification-test'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  template: {
    id: string
    channel: Types.NotificationChannel
    name: string
    body: string
    subject?: string | null
    buttonLabel?: string | null
    buttonUrl?: string | null
    signature?: string | null
  }
}

const NotificationTemplateTestDialog = ({ isShown, setIsShown, template }: Props) => {
  const { account } = useGlobal()

  const isEmail = template.channel === Types.NotificationChannel.EMAIL

  const variables = getTestVariables(account)

  const props = {
    isShown,
    setIsShown,
    variables
  }

  if (isEmail) {
    if (!(template.subject && template.signature)) throw Error('Email template missing Subject')

    return (
      <EmailNotificationTest
        {...props}
        template={{ ...template, subject: template.subject, signature: template.signature }}
      />
    )
  } else return <SMSNotificationTest {...props} template={template} />
}

export default NotificationTemplateTestDialog
