/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DenyClaims
// ====================================================

export interface DenyClaims {
  denyClaims: number;
}

export interface DenyClaimsVariables {
  guarantorId: string;
  claimIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContractSheet
// ====================================================

export interface ContractSheet_plan_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  code: string;
  quantity: number;
  value: number;
}

export interface ContractSheet_plan_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface ContractSheet_plan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  familyDiscount: number | null;
  treatments: ContractSheet_plan_treatments[];
  showValue: boolean;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  discounts: ContractSheet_plan_discounts[];
  additionalTerms: string[];
}

export interface ContractSheet {
  plan: ContractSheet_plan;
}

export interface ContractSheetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoupon
// ====================================================

export interface GetCoupon_coupon {
  __typename: "StripeCoupon";
  id: string;
  valid: boolean;
  duration: string;
  duration_in_months: number | null;
  amount_off: number | null;
  percent_off: number | null;
}

export interface GetCoupon {
  coupon: GetCoupon_coupon;
}

export interface GetCouponVariables {
  code: string;
  accountId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DependentMemberCardList
// ====================================================

export interface DependentMemberCardList_contact_dependents_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface DependentMemberCardList_contact_dependents_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface DependentMemberCardList_contact_dependents_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface DependentMemberCardList_contact_dependents_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  effectiveDate: number;
  status: SubscriptionStatus;
  /**
   * Is subscription renewal off?
   */
  autoRenew: boolean;
  price: number;
  interval: string;
}

export interface DependentMemberCardList_contact_dependents_stripe {
  __typename: "ContactStripeFields";
  membership: DependentMemberCardList_contact_dependents_stripe_membership | null;
}

export interface DependentMemberCardList_contact_dependents {
  __typename: "Contact";
  id: string;
  isGuarantor: boolean;
  email: string | null;
  phone: string | null;
  code: string;
  name: DependentMemberCardList_contact_dependents_name;
  perio: boolean;
  dob: DependentMemberCardList_contact_dependents_dob | null;
  planTerm: PlanTerm | null;
  plan: DependentMemberCardList_contact_dependents_plan | null;
  planRenewalDate: number | null;
  stripe: DependentMemberCardList_contact_dependents_stripe;
}

export interface DependentMemberCardList_contact {
  __typename: "Contact";
  id: string;
  familyMemberCount: number;
  source: DataSource;
  dependents: DependentMemberCardList_contact_dependents[];
}

export interface DependentMemberCardList {
  contact: DependentMemberCardList_contact;
}

export interface DependentMemberCardListVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DependentExisting
// ====================================================

export interface DependentExisting_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface DependentExisting_contact_account {
  __typename: "Account";
  capabilities: AccountCapability[];
}

export interface DependentExisting_contact {
  __typename: "Contact";
  id: string;
  name: DependentExisting_contact_name;
  code: string;
  account: DependentExisting_contact_account;
}

export interface DependentExisting {
  contact: DependentExisting_contact;
}

export interface DependentExistingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DependentPlan
// ====================================================

export interface DependentPlan_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface DependentPlan_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface DependentPlan_contact_plan {
  __typename: "Plan";
  id: string;
  name: string;
  status: PlanStatus;
}

export interface DependentPlan_contact_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  effectiveDate: number;
  status: SubscriptionStatus;
  /**
   * Is subscription renewal off?
   */
  autoRenew: boolean;
  price: number;
  interval: string;
}

export interface DependentPlan_contact_stripe {
  __typename: "ContactStripeFields";
  latestOpenInvoice: number | null;
  membership: DependentPlan_contact_stripe_membership | null;
}

export interface DependentPlan_contact {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  perio: boolean;
  name: DependentPlan_contact_name;
  dob: DependentPlan_contact_dob | null;
  isGuarantor: boolean;
  familyMemberCount: number;
  planTerm: PlanTerm | null;
  planRenewalDate: number | null;
  plan: DependentPlan_contact_plan | null;
  stripe: DependentPlan_contact_stripe;
}

export interface DependentPlan {
  contact: DependentPlan_contact;
}

export interface DependentPlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EnrollMemberCard
// ====================================================

export interface EnrollMemberCard_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface EnrollMemberCard_contact {
  __typename: "Contact";
  id: string;
  name: EnrollMemberCard_contact_name;
  isGuarantor: boolean;
  guarantorId: string | null;
  isFirstFamilyMember: boolean;
}

export interface EnrollMemberCard {
  contact: EnrollMemberCard_contact;
}

export interface EnrollMemberCardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Enroll
// ====================================================

export interface Enroll_enrollMembership_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
  isCredit: boolean;
  institution: string;
  last4: string;
}

export interface Enroll_enrollMembership_stripe {
  __typename: "ContactStripeFields";
  defaultSource: Enroll_enrollMembership_stripe_defaultSource | null;
}

export interface Enroll_enrollMembership {
  __typename: "Contact";
  id: string;
  stripe: Enroll_enrollMembership_stripe;
}

export interface Enroll {
  enrollMembership: Enroll_enrollMembership;
}

export interface EnrollVariables {
  contactId: string;
  planId: string;
  planTerm: PlanTerm;
  billingCycleAnchor?: number | null;
  coupon?: string | null;
  stripeToken?: string | null;
  plaidToken?: string | null;
  plaidAccountId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FileLink
// ====================================================

export interface FileLink {
  generateFileSignedUrl: string;
}

export interface FileLinkVariables {
  path: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateInstallmentTermsPdf
// ====================================================

export interface GenerateInstallmentTermsPdf {
  generateInstallmentTermsPdf: string;
}

export interface GenerateInstallmentTermsPdfVariables {
  contactId: string;
  amount: number;
  startDate: string;
  installmentTemplateId?: string | null;
  installmentPlanId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteMember
// ====================================================

export interface InviteMember {
  inviteMember: boolean;
}

export interface InviteMemberVariables {
  emailOrPhone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMembership
// ====================================================

export interface UpdateMembership_updateMembership_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  effectiveDate: number;
  status: SubscriptionStatus;
  /**
   * Is subscription renewal off?
   */
  autoRenew: boolean;
  price: number;
  interval: string;
}

export interface UpdateMembership_updateMembership_stripe {
  __typename: "ContactStripeFields";
  membership: UpdateMembership_updateMembership_stripe_membership | null;
}

export interface UpdateMembership_updateMembership {
  __typename: "Contact";
  id: string;
  planRenewalDate: number | null;
  stripe: UpdateMembership_updateMembership_stripe;
}

export interface UpdateMembership {
  updateMembership: UpdateMembership_updateMembership;
}

export interface UpdateMembershipVariables {
  contactId: string;
  autoRenew?: boolean | null;
  renewalAnchor?: number | null;
  switchPlanId?: string | null;
  switchPlanTerm?: PlanTerm | null;
  coupon?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PauseMembership
// ====================================================

export interface PauseMembership_pauseContactSubscription_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  effectiveDate: number;
  status: SubscriptionStatus;
  /**
   * Is subscription renewal off?
   */
  autoRenew: boolean;
  price: number;
  interval: string;
}

export interface PauseMembership_pauseContactSubscription_stripe {
  __typename: "ContactStripeFields";
  membership: PauseMembership_pauseContactSubscription_stripe_membership | null;
  hasSubscriptions: boolean;
}

export interface PauseMembership_pauseContactSubscription {
  __typename: "Contact";
  id: string;
  stripe: PauseMembership_pauseContactSubscription_stripe;
}

export interface PauseMembership {
  pauseContactSubscription: PauseMembership_pauseContactSubscription;
}

export interface PauseMembershipVariables {
  subscriptionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TerminateMembership
// ====================================================

export interface TerminateMembership_terminateMembership_stripe {
  __typename: "ContactStripeFields";
  hasSubscriptions: boolean;
}

export interface TerminateMembership_terminateMembership {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  stripe: TerminateMembership_terminateMembership_stripe;
}

export interface TerminateMembership {
  terminateMembership: TerminateMembership_terminateMembership;
}

export interface TerminateMembershipVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePatient
// ====================================================

export interface CreatePatient_createPatient_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface CreatePatient_createPatient_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface CreatePatient_createPatient_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface CreatePatient_createPatient {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  isGuarantor: boolean;
  email: string | null;
  phone: string | null;
  source: DataSource;
  isFirstFamilyMember: boolean;
  code: string;
  name: CreatePatient_createPatient_name;
  perio: boolean;
  dob: CreatePatient_createPatient_dob | null;
  address: CreatePatient_createPatient_address | null;
}

export interface CreatePatient {
  createPatient: CreatePatient_createPatient;
}

export interface CreatePatientVariables {
  guarantorId?: string | null;
  email?: string | null;
  phone?: string | null;
  profile: CreatePatientProfileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePatient
// ====================================================

export interface UpdatePatient_updatePatient_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface UpdatePatient_updatePatient_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface UpdatePatient_updatePatient_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface UpdatePatient_updatePatient_balance_engagementDetails {
  __typename: "BalanceEngagementDetails";
  blockedReasons: BalanceBlockedReason[];
}

export interface UpdatePatient_updatePatient_balance {
  __typename: "ContactBalance";
  engagementDetails: UpdatePatient_updatePatient_balance_engagementDetails | null;
}

export interface UpdatePatient_updatePatient {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  isGuarantor: boolean;
  email: string | null;
  phone: string | null;
  source: DataSource;
  isFirstFamilyMember: boolean;
  code: string;
  name: UpdatePatient_updatePatient_name;
  perio: boolean;
  dob: UpdatePatient_updatePatient_dob | null;
  address: UpdatePatient_updatePatient_address | null;
  balance: UpdatePatient_updatePatient_balance;
}

export interface UpdatePatient {
  updatePatient: UpdatePatient_updatePatient;
}

export interface UpdatePatientVariables {
  contactId: string;
  email?: string | null;
  phone?: string | null;
  profile: UpdatePatientProfileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PatientDetails
// ====================================================

export interface PatientDetails_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface PatientDetails_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface PatientDetails_contact_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface PatientDetails_contact {
  __typename: "Contact";
  source: DataSource;
  pms_override: boolean;
  pms_primaryInsuranceName: string | null;
  pms_secondaryInsuranceName: string | null;
  pms_billingType: string | null;
  stripeId_customer: string | null;
  stripeId_subscription: string | null;
  id: string;
  guarantorId: string | null;
  isGuarantor: boolean;
  email: string | null;
  phone: string | null;
  isFirstFamilyMember: boolean;
  code: string;
  name: PatientDetails_contact_name;
  perio: boolean;
  dob: PatientDetails_contact_dob | null;
  address: PatientDetails_contact_address | null;
}

export interface PatientDetails {
  contact: PatientDetails_contact;
  activeOutreachFlowsByContactId: number;
  transactionsCountByContactId: number;
}

export interface PatientDetailsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExistingPatients
// ====================================================

export interface ExistingPatients_existingContacts_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface ExistingPatients_existingContacts_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface ExistingPatients_existingContacts_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface ExistingPatients_existingContacts {
  __typename: "Contact";
  source: DataSource;
  isMember: boolean;
  id: string;
  guarantorId: string | null;
  isGuarantor: boolean;
  email: string | null;
  phone: string | null;
  isFirstFamilyMember: boolean;
  code: string;
  name: ExistingPatients_existingContacts_name;
  perio: boolean;
  dob: ExistingPatients_existingContacts_dob | null;
  address: ExistingPatients_existingContacts_address | null;
}

export interface ExistingPatients {
  existingContacts: ExistingPatients_existingContacts[];
}

export interface ExistingPatientsVariables {
  email?: string | null;
  phone?: string | null;
  guarantorId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePatient
// ====================================================

export interface DeletePatient {
  deletePatient: string;
}

export interface DeletePatientVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnsyncPatient
// ====================================================

export interface UnsyncPatient_unsyncPatient {
  __typename: "Contact";
  id: string;
  pms_override: boolean;
}

export interface UnsyncPatient {
  unsyncPatient: UnsyncPatient_unsyncPatient;
}

export interface UnsyncPatientVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncPatient
// ====================================================

export interface SyncPatient_syncPatient_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface SyncPatient_syncPatient_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface SyncPatient_syncPatient_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface SyncPatient_syncPatient {
  __typename: "Contact";
  pms_override: boolean;
  id: string;
  guarantorId: string | null;
  isGuarantor: boolean;
  email: string | null;
  phone: string | null;
  source: DataSource;
  isFirstFamilyMember: boolean;
  code: string;
  name: SyncPatient_syncPatient_name;
  perio: boolean;
  dob: SyncPatient_syncPatient_dob | null;
  address: SyncPatient_syncPatient_address | null;
}

export interface SyncPatient {
  syncPatient: SyncPatient_syncPatient;
}

export interface SyncPatientVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PatientSelectorSearch
// ====================================================

export interface PatientSelectorSearch_patientsConnection_edges_node_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface PatientSelectorSearch_patientsConnection_edges_node {
  __typename: "Contact";
  id: string;
  source: DataSource;
  email: string | null;
  phone: string | null;
  isGuarantor: boolean;
  isMember: boolean;
  name: PatientSelectorSearch_patientsConnection_edges_node_name;
}

export interface PatientSelectorSearch_patientsConnection_edges {
  __typename: "ContactEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: PatientSelectorSearch_patientsConnection_edges_node;
}

export interface PatientSelectorSearch_patientsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface PatientSelectorSearch_patientsConnection {
  __typename: "ContactConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: PatientSelectorSearch_patientsConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: PatientSelectorSearch_patientsConnection_pageInfo;
}

export interface PatientSelectorSearch {
  patientsConnection: PatientSelectorSearch_patientsConnection;
}

export interface PatientSelectorSearchVariables {
  search: string;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayOverTimeSelector
// ====================================================

export interface PayOverTimeSelector_contact_eligible_installmentTemplates {
  __typename: "InstallmentTemplate";
  id: string;
  name: string;
  periods: number;
  interval: number;
  intervalType: InstallmentIntervalType;
  adminFee: number;
  downPayment: number;
  allowCC: boolean;
  allowACH: boolean;
}

export interface PayOverTimeSelector_contact_eligible_financingProviders {
  __typename: "FinancingProvider";
  id: string;
  name: string;
  url: string;
  description: string;
}

export interface PayOverTimeSelector_contact_balance_sunbitEstimate {
  __typename: "SunbitEstimate";
  id: string;
  monthlyAmount: number;
  amountFinanced: number;
  apr: number;
  downPayment: number;
}

export interface PayOverTimeSelector_contact_balance {
  __typename: "ContactBalance";
  sunbitEstimate: PayOverTimeSelector_contact_balance_sunbitEstimate | null;
}

export interface PayOverTimeSelector_contact {
  __typename: "Contact";
  id: string;
  accountId: string;
  eligible_installmentTemplates: PayOverTimeSelector_contact_eligible_installmentTemplates[];
  eligible_financingProviders: PayOverTimeSelector_contact_eligible_financingProviders[];
  balance: PayOverTimeSelector_contact_balance;
}

export interface PayOverTimeSelector {
  contact: PayOverTimeSelector_contact;
}

export interface PayOverTimeSelectorVariables {
  id: string;
  selfEnroll: boolean;
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentMethodCard
// ====================================================

export interface PaymentMethodCard_contact_restrictedPaymentMethods {
  __typename: "ContactRestrictedPaymentMethods";
  ach: boolean;
  cc: boolean;
}

export interface PaymentMethodCard_contact_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
  isCredit: boolean;
  institution: string;
  last4: string;
  createdAt: number;
}

export interface PaymentMethodCard_contact_stripe {
  __typename: "ContactStripeFields";
  latestOpenInvoice: number | null;
  defaultSource: PaymentMethodCard_contact_stripe_defaultSource | null;
  hasSubscriptions: boolean;
}

export interface PaymentMethodCard_contact {
  __typename: "Contact";
  id: string;
  hasFailedScheduledPayment: boolean;
  hasOngoingInstallmentPlan: boolean;
  restrictedPaymentMethods: PaymentMethodCard_contact_restrictedPaymentMethods;
  stripe: PaymentMethodCard_contact_stripe;
}

export interface PaymentMethodCard {
  contact: PaymentMethodCard_contact;
}

export interface PaymentMethodCardVariables {
  guarantorId: string;
  allowDelete: boolean;
  attemptScheduledPayments: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuarantorPaymentMethod
// ====================================================

export interface UpdateGuarantorPaymentMethod_updateGuarantorPaymentMethod_contact_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
  isCredit: boolean;
  institution: string;
  last4: string;
  createdAt: number;
}

export interface UpdateGuarantorPaymentMethod_updateGuarantorPaymentMethod_contact_stripe {
  __typename: "ContactStripeFields";
  latestOpenInvoice: number | null;
  defaultSource: UpdateGuarantorPaymentMethod_updateGuarantorPaymentMethod_contact_stripe_defaultSource | null;
}

export interface UpdateGuarantorPaymentMethod_updateGuarantorPaymentMethod_contact {
  __typename: "Contact";
  id: string;
  stripe: UpdateGuarantorPaymentMethod_updateGuarantorPaymentMethod_contact_stripe;
}

export interface UpdateGuarantorPaymentMethod_updateGuarantorPaymentMethod {
  __typename: "UpdateGuarantorPaymentMethod";
  contact: UpdateGuarantorPaymentMethod_updateGuarantorPaymentMethod_contact;
  error: string | null;
}

export interface UpdateGuarantorPaymentMethod {
  updateGuarantorPaymentMethod: UpdateGuarantorPaymentMethod_updateGuarantorPaymentMethod;
}

export interface UpdateGuarantorPaymentMethodVariables {
  guarantorId: string;
  stripeToken?: string | null;
  plaidToken?: string | null;
  plaidAccountId?: string | null;
  attemptScheduledPayments: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGuarantorPaymentMethod
// ====================================================

export interface DeleteGuarantorPaymentMethod_deleteGuarantorPaymentMethod_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
}

export interface DeleteGuarantorPaymentMethod_deleteGuarantorPaymentMethod_stripe {
  __typename: "ContactStripeFields";
  defaultSource: DeleteGuarantorPaymentMethod_deleteGuarantorPaymentMethod_stripe_defaultSource | null;
}

export interface DeleteGuarantorPaymentMethod_deleteGuarantorPaymentMethod {
  __typename: "Contact";
  id: string;
  stripe: DeleteGuarantorPaymentMethod_deleteGuarantorPaymentMethod_stripe;
}

export interface DeleteGuarantorPaymentMethod {
  deleteGuarantorPaymentMethod: DeleteGuarantorPaymentMethod_deleteGuarantorPaymentMethod;
}

export interface DeleteGuarantorPaymentMethodVariables {
  guarantorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlaidLinkButton
// ====================================================

export interface PlaidLinkButton {
  plaidLinkToken: string;
}

export interface PlaidLinkButtonVariables {
  userId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlanCardList
// ====================================================

export interface PlanCardList_livePlans_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  quantity: number;
}

export interface PlanCardList_livePlans_discounts {
  __typename: "Discount";
  id: string;
}

export interface PlanCardList_livePlans {
  __typename: "Plan";
  id: string;
  name: string;
  perio: boolean | null;
  minAge: number | null;
  maxAge: number | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  annualFamilyPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  monthlyFamilyPrice: number | null;
  familyDiscount: number | null;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  treatments: PlanCardList_livePlans_treatments[];
  discounts: PlanCardList_livePlans_discounts[];
}

export interface PlanCardList {
  livePlans: PlanCardList_livePlans[];
}

export interface PlanCardListVariables {
  accountId?: string | null;
  contactId?: string | null;
  age?: number | null;
  perio?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResumeInstallment
// ====================================================

export interface ResumeInstallment_resumeInstallmentPlan {
  __typename: "InstallmentPlan";
  id: string;
  status: InstallmentPlanStatus;
}

export interface ResumeInstallment {
  resumeInstallmentPlan: ResumeInstallment_resumeInstallmentPlan;
}

export interface ResumeInstallmentVariables {
  installmentPlanId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResumeSubscription
// ====================================================

export interface ResumeSubscription_pauseContactSubscription_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  effectiveDate: number;
  status: SubscriptionStatus;
  /**
   * Is subscription renewal off?
   */
  autoRenew: boolean;
}

export interface ResumeSubscription_pauseContactSubscription_stripe {
  __typename: "ContactStripeFields";
  membership: ResumeSubscription_pauseContactSubscription_stripe_membership | null;
}

export interface ResumeSubscription_pauseContactSubscription {
  __typename: "Contact";
  id: string;
  stripe: ResumeSubscription_pauseContactSubscription_stripe;
}

export interface ResumeSubscription {
  pauseContactSubscription: ResumeSubscription_pauseContactSubscription;
}

export interface ResumeSubscriptionVariables {
  subscriptionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateSunbitCheckoutToken
// ====================================================

export interface GenerateSunbitCheckoutToken {
  generateSunbitCheckoutToken: string;
}

export interface GenerateSunbitCheckoutTokenVariables {
  amount: number;
  contactId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountSelector
// ====================================================

export interface AccountSelector_meContacts_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface AccountSelector_meContacts_dependents_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface AccountSelector_meContacts_dependents {
  __typename: "Contact";
  name: AccountSelector_meContacts_dependents_name;
}

export interface AccountSelector_meContacts_balance_metrics {
  __typename: "Balance";
  patientPortion: number;
}

export interface AccountSelector_meContacts_balance {
  __typename: "ContactBalance";
  metrics: AccountSelector_meContacts_balance_metrics;
}

export interface AccountSelector_meContacts_account_offices {
  __typename: "Address";
  city: string;
  state: State;
}

export interface AccountSelector_meContacts_account {
  __typename: "Account";
  id: string;
  name: string;
  logoUrl: string | null;
  offices: AccountSelector_meContacts_account_offices[];
}

export interface AccountSelector_meContacts {
  __typename: "Contact";
  id: string;
  isMember: boolean;
  hasTransactions: boolean;
  name: AccountSelector_meContacts_name;
  dependents: AccountSelector_meContacts_dependents[];
  balance: AccountSelector_meContacts_balance;
  account: AccountSelector_meContacts_account;
}

export interface AccountSelector {
  meContacts: AccountSelector_meContacts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCurrentContactId
// ====================================================

export interface SetCurrentContactId_setCurrentContactId {
  __typename: "Contact";
  id: string;
  code: string;
}

export interface SetCurrentContactId {
  setCurrentContactId: SetCurrentContactId_setCurrentContactId;
}

export interface SetCurrentContactIdVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppGlobal
// ====================================================

export interface AppGlobal_meContact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface AppGlobal_meContact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface AppGlobal_meContact_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface AppGlobal_meContact_account_offices {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface AppGlobal_meContact_account_dentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface AppGlobal_meContact_account {
  __typename: "Account";
  id: string;
  code: string;
  logoUrl: string | null;
  name: string;
  programName: string;
  phone: string;
  siteUrl: string | null;
  offices: AppGlobal_meContact_account_offices[];
  dentists: AppGlobal_meContact_account_dentists[];
}

export interface AppGlobal_meContact_plan_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface AppGlobal_meContact_plan_treatments {
  __typename: "Treatment";
  id: string;
  code: string;
  name: string;
  quantity: number;
  value: number;
}

export interface AppGlobal_meContact_plan {
  __typename: "Plan";
  id: string;
  name: string;
  additionalTerms: string[];
  discounts: AppGlobal_meContact_plan_discounts[];
  globalDiscount: number | null;
  globalDiscountActive: boolean;
  showValue: boolean;
  treatments: AppGlobal_meContact_plan_treatments[];
}

export interface AppGlobal_meContact_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  price: number;
  interval: string;
  effectiveDate: number;
  status: SubscriptionStatus;
  /**
   * Is subscription renewal off?
   */
  autoRenew: boolean;
}

export interface AppGlobal_meContact_stripe {
  __typename: "ContactStripeFields";
  membership: AppGlobal_meContact_stripe_membership | null;
}

export interface AppGlobal_meContact {
  __typename: "Contact";
  id: string;
  isGuarantor: boolean;
  source: DataSource;
  familyMemberCount: number;
  planTerm: PlanTerm | null;
  email: string | null;
  phone: string | null;
  code: string;
  name: AppGlobal_meContact_name;
  perio: boolean;
  dob: AppGlobal_meContact_dob | null;
  address: AppGlobal_meContact_address | null;
  account: AppGlobal_meContact_account;
  plan: AppGlobal_meContact_plan | null;
  planRenewalDate: number | null;
  stripe: AppGlobal_meContact_stripe;
}

export interface AppGlobal {
  meContact: AppGlobal_meContact;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Billing
// ====================================================

export interface Billing_contact_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
  isCredit: boolean;
  institution: string;
  last4: string;
}

export interface Billing_contact_stripe {
  __typename: "ContactStripeFields";
  defaultSource: Billing_contact_stripe_defaultSource | null;
}

export interface Billing_contact_charges_transaction {
  __typename: "Transaction";
  id: string;
  type: TransactionType;
}

export interface Billing_contact_charges {
  __typename: "Charge";
  id: string;
  date: number;
  grossAmount: number;
  transaction: Billing_contact_charges_transaction;
}

export interface Billing_contact {
  __typename: "Contact";
  id: string;
  stripe: Billing_contact_stripe;
  charges: Billing_contact_charges[];
}

export interface Billing {
  contact: Billing_contact;
}

export interface BillingVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GeneratePatientChargeReceiptPdf
// ====================================================

export interface GeneratePatientChargeReceiptPdf {
  generateChargeReceiptPdf: string;
}

export interface GeneratePatientChargeReceiptPdfVariables {
  chargeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GeneratePatientInstallmentTermsPdf
// ====================================================

export interface GeneratePatientInstallmentTermsPdf {
  generateInstallmentTermsPdf: string;
}

export interface GeneratePatientInstallmentTermsPdfVariables {
  contactId: string;
  amount: number;
  startDate: string;
  installmentPlanId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendInvitation
// ====================================================

export interface ResendInvitation {
  sendPatientSignInLink: boolean;
}

export interface ResendInvitationVariables {
  email: string;
  accountCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayPractice
// ====================================================

export interface PayPractice_account_public_feeSchedule {
  __typename: "FeeSchedule";
  payments_patient_ach: number;
  payments_patient_cc: number;
  payments_patient_30: boolean;
}

export interface PayPractice_account_public {
  __typename: "Account";
  id: string;
  name: string;
  phone: string;
  publicReplyPhone: string;
  siteUrl: string | null;
  logoUrl: string | null;
  stripeId: string | null;
  enablePayments: boolean;
  enablePatientSelfCreation: boolean;
  capabilities: AccountCapability[];
  feeSchedule: PayPractice_account_public_feeSchedule;
}

export interface PayPractice {
  account_public: PayPractice_account_public;
}

export interface PayPracticeVariables {
  code?: string | null;
  practicePortalSlug?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayPatient
// ====================================================

export interface PayPatient_authenticateGuarantor_guarantor_eligible_installmentTemplates {
  __typename: "InstallmentTemplate";
  id: string;
  name: string;
  periods: number;
  interval: number;
  intervalType: InstallmentIntervalType;
  allowACH: boolean;
  allowCC: boolean;
  adminFee: number;
  downPayment: number;
}

export interface PayPatient_authenticateGuarantor_guarantor_eligible_financingProviders {
  __typename: "FinancingProvider";
  id: string;
  name: string;
  url: string;
  description: string;
}

export interface PayPatient_authenticateGuarantor_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface PayPatient_authenticateGuarantor_guarantor_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface PayPatient_authenticateGuarantor_guarantor_account_installmentTemplates {
  __typename: "InstallmentTemplate";
  id: string;
  interval: number;
  periods: number;
  intervalType: InstallmentIntervalType;
}

export interface PayPatient_authenticateGuarantor_guarantor_account_feeSchedule {
  __typename: "FeeSchedule";
  payments_patient_ach: number;
  payments_patient_cc: number;
  payments_patient_30: boolean;
}

export interface PayPatient_authenticateGuarantor_guarantor_account {
  __typename: "Account";
  id: string;
  enablePayments: boolean;
  siteUrl: string | null;
  logoUrl: string | null;
  name: string;
  phone: string;
  publicReplyPhone: string;
  capabilities: AccountCapability[];
  installmentTemplates: PayPatient_authenticateGuarantor_guarantor_account_installmentTemplates[];
  stripeId: string | null;
  feeSchedule: PayPatient_authenticateGuarantor_guarantor_account_feeSchedule;
}

export interface PayPatient_authenticateGuarantor_guarantor_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
  isCredit: boolean;
  institution: string;
  last4: string;
  createdAt: number;
}

export interface PayPatient_authenticateGuarantor_guarantor_stripe {
  __typename: "ContactStripeFields";
  customerId: string | null;
  defaultSource: PayPatient_authenticateGuarantor_guarantor_stripe_defaultSource | null;
}

export interface PayPatient_authenticateGuarantor_guarantor_balance_metrics {
  __typename: "Balance";
  patientPortion: number;
  insurancePortion: number;
  writeOffEstimate: number;
}

export interface PayPatient_authenticateGuarantor_guarantor_balance_activity_patient_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface PayPatient_authenticateGuarantor_guarantor_balance_activity_patient_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface PayPatient_authenticateGuarantor_guarantor_balance_activity_patient {
  __typename: "Contact";
  name: PayPatient_authenticateGuarantor_guarantor_balance_activity_patient_name;
  dob: PayPatient_authenticateGuarantor_guarantor_balance_activity_patient_dob | null;
}

export interface PayPatient_authenticateGuarantor_guarantor_balance_activity_procedures {
  __typename: "ContactBalanceActivityTransaction";
  id: string;
  amount: number;
  procedureCode: string | null;
  description: string;
  pms_claimId: string | null;
}

export interface PayPatient_authenticateGuarantor_guarantor_balance_activity {
  __typename: "ContactBalanceActivity";
  date: number;
  patient: PayPatient_authenticateGuarantor_guarantor_balance_activity_patient;
  netAmount: number;
  patientPaymentsSum: number;
  insurancePaymentsSum: number;
  adjustmentsSum: number;
  proceduresSum: number;
  isPendingPrimary: boolean;
  isPendingSecondary: boolean;
  isDenied: boolean;
  uploadPaths: string[];
  procedures: PayPatient_authenticateGuarantor_guarantor_balance_activity_procedures[];
}

export interface PayPatient_authenticateGuarantor_guarantor_balance_sunbitEstimate {
  __typename: "SunbitEstimate";
  id: string;
  downPayment: number;
  amountFinanced: number;
  monthlyAmount: number;
  apr: number;
}

export interface PayPatient_authenticateGuarantor_guarantor_balance {
  __typename: "ContactBalance";
  metrics: PayPatient_authenticateGuarantor_guarantor_balance_metrics;
  activity: PayPatient_authenticateGuarantor_guarantor_balance_activity[];
  sunbitEstimate: PayPatient_authenticateGuarantor_guarantor_balance_sunbitEstimate | null;
}

export interface PayPatient_authenticateGuarantor_guarantor_charges {
  __typename: "Charge";
  id: string;
  date: number;
  balanceAmount: number;
  grossAmount: number;
  patientFee: number;
  refundedAmount: number;
  institution: string;
  last4: string;
}

export interface PayPatient_authenticateGuarantor_guarantor_installmentPlans_scheduledPayments {
  __typename: "ScheduledPayment";
  id: string;
  amount: number;
  date: string;
}

export interface PayPatient_authenticateGuarantor_guarantor_installmentPlans {
  __typename: "InstallmentPlan";
  id: string;
  guarantorId: string;
  status: InstallmentPlanStatus;
  startDate: string;
  faceAmount: number;
  adminFee: number;
  downPayment: number;
  periods: number;
  interval: number;
  intervalType: InstallmentIntervalType;
  scheduledPayments: PayPatient_authenticateGuarantor_guarantor_installmentPlans_scheduledPayments[];
}

export interface PayPatient_authenticateGuarantor_guarantor {
  __typename: "Contact";
  id: string;
  accountId: string;
  suppressed: boolean;
  balanceNote: string | null;
  eligible_installmentTemplates: PayPatient_authenticateGuarantor_guarantor_eligible_installmentTemplates[];
  eligible_financingProviders: PayPatient_authenticateGuarantor_guarantor_eligible_financingProviders[];
  code: string;
  name: PayPatient_authenticateGuarantor_guarantor_name;
  dob: PayPatient_authenticateGuarantor_guarantor_dob | null;
  account: PayPatient_authenticateGuarantor_guarantor_account;
  stripe: PayPatient_authenticateGuarantor_guarantor_stripe;
  balance: PayPatient_authenticateGuarantor_guarantor_balance;
  charges: PayPatient_authenticateGuarantor_guarantor_charges[];
  installmentPlans: PayPatient_authenticateGuarantor_guarantor_installmentPlans[];
}

export interface PayPatient_authenticateGuarantor {
  __typename: "GuarantorWithToken";
  token: string;
  guarantor: PayPatient_authenticateGuarantor_guarantor;
}

export interface PayPatient {
  authenticateGuarantor: PayPatient_authenticateGuarantor;
}

export interface PayPatientVariables {
  code: string;
  dob: number;
  anonymize?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PayOneTime
// ====================================================

export interface PayOneTime_payOneTime_contact_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
  isCredit: boolean;
  institution: string;
  last4: string;
  createdAt: number;
}

export interface PayOneTime_payOneTime_contact_stripe {
  __typename: "ContactStripeFields";
  customerId: string | null;
  defaultSource: PayOneTime_payOneTime_contact_stripe_defaultSource | null;
}

export interface PayOneTime_payOneTime_contact_charges {
  __typename: "Charge";
  id: string;
  date: number;
  balanceAmount: number;
  grossAmount: number;
  patientFee: number;
  refundedAmount: number;
  institution: string;
  last4: string;
}

export interface PayOneTime_payOneTime_contact_balance_metrics {
  __typename: "Balance";
  patientPortion: number;
  insurancePortion: number;
  writeOffEstimate: number;
}

export interface PayOneTime_payOneTime_contact_balance {
  __typename: "ContactBalance";
  metrics: PayOneTime_payOneTime_contact_balance_metrics;
}

export interface PayOneTime_payOneTime_contact {
  __typename: "Contact";
  stripe: PayOneTime_payOneTime_contact_stripe;
  charges: PayOneTime_payOneTime_contact_charges[];
  balance: PayOneTime_payOneTime_contact_balance;
}

export interface PayOneTime_payOneTime {
  __typename: "Charge";
  id: string;
  grossAmount: number;
  contact: PayOneTime_payOneTime_contact;
}

export interface PayOneTime {
  payOneTime: PayOneTime_payOneTime;
}

export interface PayOneTimeVariables {
  contactId: string;
  amount: number;
  checkBalance?: number | null;
  stripeToken?: string | null;
  plaidToken?: string | null;
  plaidAccountId?: string | null;
  deleteContactOnFailure?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SelfEnrollInstallments
// ====================================================

export interface SelfEnrollInstallments_enrollInstallments_contact_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
  isCredit: boolean;
  institution: string;
  last4: string;
  createdAt: number;
}

export interface SelfEnrollInstallments_enrollInstallments_contact_stripe {
  __typename: "ContactStripeFields";
  customerId: string | null;
  defaultSource: SelfEnrollInstallments_enrollInstallments_contact_stripe_defaultSource | null;
}

export interface SelfEnrollInstallments_enrollInstallments_contact_installmentPlans_scheduledPayments {
  __typename: "ScheduledPayment";
  id: string;
  amount: number;
  date: string;
}

export interface SelfEnrollInstallments_enrollInstallments_contact_installmentPlans {
  __typename: "InstallmentPlan";
  id: string;
  guarantorId: string;
  status: InstallmentPlanStatus;
  startDate: string;
  faceAmount: number;
  adminFee: number;
  downPayment: number;
  periods: number;
  interval: number;
  intervalType: InstallmentIntervalType;
  scheduledPayments: SelfEnrollInstallments_enrollInstallments_contact_installmentPlans_scheduledPayments[];
}

export interface SelfEnrollInstallments_enrollInstallments_contact_charges {
  __typename: "Charge";
  id: string;
  date: number;
  balanceAmount: number;
  grossAmount: number;
  patientFee: number;
  refundedAmount: number;
  institution: string;
  last4: string;
}

export interface SelfEnrollInstallments_enrollInstallments_contact_balance_metrics {
  __typename: "Balance";
  patientPortion: number;
  insurancePortion: number;
  writeOffEstimate: number;
}

export interface SelfEnrollInstallments_enrollInstallments_contact_balance {
  __typename: "ContactBalance";
  metrics: SelfEnrollInstallments_enrollInstallments_contact_balance_metrics;
}

export interface SelfEnrollInstallments_enrollInstallments_contact {
  __typename: "Contact";
  stripe: SelfEnrollInstallments_enrollInstallments_contact_stripe;
  installmentPlans: SelfEnrollInstallments_enrollInstallments_contact_installmentPlans[];
  charges: SelfEnrollInstallments_enrollInstallments_contact_charges[];
  balance: SelfEnrollInstallments_enrollInstallments_contact_balance;
}

export interface SelfEnrollInstallments_enrollInstallments {
  __typename: "Charge";
  id: string;
  grossAmount: number;
  receiptUrl: string;
  contact: SelfEnrollInstallments_enrollInstallments_contact;
}

export interface SelfEnrollInstallments {
  enrollInstallments: SelfEnrollInstallments_enrollInstallments;
}

export interface SelfEnrollInstallmentsVariables {
  contactId: string;
  amount: number;
  installmentTemplateId: string;
  checkBalance: number;
  stripeToken?: string | null;
  plaidToken?: string | null;
  plaidAccountId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InitiatePatientLookup
// ====================================================

export interface InitiatePatientLookup {
  initiatePatientLookup: boolean;
}

export interface InitiatePatientLookupVariables {
  accountId: string;
  firstName: string;
  email?: string | null;
  phone?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSelf
// ====================================================

export interface CreateSelf {
  createSelf: string;
}

export interface CreateSelfVariables {
  accountId: string;
  token: string;
  profile: CreatePatientProfileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetPhoneConsent
// ====================================================

export interface SetPhoneConsent_setPhoneConsent {
  __typename: "PhoneConsent";
  phone: string;
}

export interface SetPhoneConsent {
  setPhoneConsent: SetPhoneConsent_setPhoneConsent | null;
}

export interface SetPhoneConsentVariables {
  phone: string;
  optIn: boolean;
  dobDay: number;
  dobMonth: number;
  dobYear: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePaymentIntent
// ====================================================

export interface CreatePaymentIntent {
  createPaymentIntent: string;
}

export interface CreatePaymentIntentVariables {
  contactId: string;
  amount: number;
  checkBalance?: number | null;
  deleteContactOnFailure?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FulfillPaymentIntent
// ====================================================

export interface FulfillPaymentIntent_fulfillPaymentIntent {
  __typename: "Charge";
  id: string;
  status: ChargeStatus;
  grossAmount: number;
}

export interface FulfillPaymentIntent {
  fulfillPaymentIntent: FulfillPaymentIntent_fulfillPaymentIntent;
}

export interface FulfillPaymentIntentVariables {
  paymentIntentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePatientProfile
// ====================================================

export interface UpdatePatientProfile_updatePatient {
  __typename: "Contact";
  id: string;
}

export interface UpdatePatientProfile {
  updatePatient: UpdatePatientProfile_updatePatient;
}

export interface UpdatePatientProfileVariables {
  contactId: string;
  email?: string | null;
  phone?: string | null;
  profile: UpdatePatientProfileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Provider
// ====================================================

export interface Provider_livePlans_treatments {
  __typename: "Treatment";
  id: string;
  quantity: number;
  name: string;
}

export interface Provider_livePlans {
  __typename: "Plan";
  id: string;
  name: string;
  minAge: number | null;
  maxAge: number | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  treatments: Provider_livePlans_treatments[];
}

export interface Provider {
  livePlans: Provider_livePlans[];
}

export interface ProviderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublicGlobal
// ====================================================

export interface PublicGlobal_account_public_dentists {
  __typename: "Dentist";
  id: string;
  degree: Degree;
  name: string;
  salutation: Salutation;
}

export interface PublicGlobal_account_public_offices {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface PublicGlobal_account_public {
  __typename: "Account";
  id: string;
  name: string;
  code: string;
  programName: string;
  logoUrl: string | null;
  siteColor: SiteColor;
  siteImage: SiteImage;
  siteUrl: string | null;
  phone: string;
  email: string | null;
  dentists: PublicGlobal_account_public_dentists[];
  offices: PublicGlobal_account_public_offices[];
}

export interface PublicGlobal {
  account_public: PublicGlobal_account_public;
}

export interface PublicGlobalVariables {
  code?: string | null;
  membershipSlug?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPatientPassword
// ====================================================

export interface ResetPatientPassword {
  sendPatientResetPasswordEmail: boolean;
}

export interface ResetPatientPasswordVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SelfEnrollMembership
// ====================================================

export interface SelfEnrollMembership_enrollMembership {
  __typename: "Contact";
  id: string;
}

export interface SelfEnrollMembership {
  enrollMembership: SelfEnrollMembership_enrollMembership;
}

export interface SelfEnrollMembershipVariables {
  contactId: string;
  planId: string;
  planTerm: PlanTerm;
  coupon?: string | null;
  stripeToken?: string | null;
  plaidToken?: string | null;
  plaidAccountId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMembershipSelf
// ====================================================

export interface CreateMembershipSelf {
  createMembershipSelf: string;
}

export interface CreateMembershipSelfVariables {
  accountId: string;
  email: string;
  phone?: string | null;
  profile: CreatePatientProfileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountAdmin
// ====================================================

export interface AccountAdmin_account_returnAddress_verifiedAddress {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
}

export interface AccountAdmin_account_returnAddress {
  __typename: "Address";
  id: string;
  verifiedAddress: AccountAdmin_account_returnAddress_verifiedAddress | null;
}

export interface AccountAdmin_account_feeSchedule {
  __typename: "FeeSchedule";
  id: string;
  membership_practice_ach: number;
  membership_practice_cc: number;
  payments_practice_ach: number;
  payments_practice_cc: number;
  payments_practice_30: boolean;
  payments_patient_ach: number;
  payments_patient_cc: number;
  payments_patient_30: boolean;
  installments_practice_ach: number;
  installments_practice_cc: number;
}

export interface AccountAdmin_account_locations {
  __typename: "Location";
  id: string;
  name: string;
  pms_id: string;
  source: DataSource;
  enableSync: boolean;
}

export interface AccountAdmin_account {
  __typename: "Account";
  id: string;
  capabilities: AccountCapability[];
  status: AccountStatus;
  stripeId: string | null;
  stripe_customerId: string | null;
  sikka_id: string | null;
  sikka_key: string | null;
  sikka_areGuarantorsDistinct: boolean;
  sikka_isInsuranceArEstimated: boolean;
  sikka_incrementalTransactions: boolean;
  sikka_isIsolatedLocations: boolean;
  hubspot_customerId: string | null;
  syncSource: SyncSource;
  returnAddress: AccountAdmin_account_returnAddress;
  feeSchedule: AccountAdmin_account_feeSchedule;
  enableSync: boolean;
  enableOutreach: boolean;
  enablePayments: boolean;
  defaultBalanceType: BalanceType;
  claimDeniedDays: number;
  secondaryClaimDays: number;
  billing_minDaysSettled: number;
  locations: AccountAdmin_account_locations[];
}

export interface AccountAdmin {
  account: AccountAdmin_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAccountInternal
// ====================================================

export interface UpdateAccountInternal_updateAccountInternal_returnAddress_verifiedAddress {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
}

export interface UpdateAccountInternal_updateAccountInternal_returnAddress {
  __typename: "Address";
  id: string;
  verifiedAddress: UpdateAccountInternal_updateAccountInternal_returnAddress_verifiedAddress | null;
}

export interface UpdateAccountInternal_updateAccountInternal_feeSchedule {
  __typename: "FeeSchedule";
  id: string;
  membership_practice_ach: number;
  membership_practice_cc: number;
  payments_practice_ach: number;
  payments_practice_cc: number;
  payments_practice_30: boolean;
  payments_patient_ach: number;
  payments_patient_cc: number;
  payments_patient_30: boolean;
  installments_practice_ach: number;
  installments_practice_cc: number;
}

export interface UpdateAccountInternal_updateAccountInternal {
  __typename: "Account";
  id: string;
  capabilities: AccountCapability[];
  status: AccountStatus;
  stripeId: string | null;
  stripe_customerId: string | null;
  sikka_id: string | null;
  sikka_key: string | null;
  sikka_areGuarantorsDistinct: boolean;
  sikka_isInsuranceArEstimated: boolean;
  sikka_incrementalTransactions: boolean;
  sikka_isIsolatedLocations: boolean;
  hubspot_customerId: string | null;
  syncSource: SyncSource;
  returnAddress: UpdateAccountInternal_updateAccountInternal_returnAddress;
  feeSchedule: UpdateAccountInternal_updateAccountInternal_feeSchedule;
  enableSync: boolean;
  enableOutreach: boolean;
  enablePayments: boolean;
  defaultBalanceType: BalanceType;
  claimDeniedDays: number;
  secondaryClaimDays: number;
  billing_minDaysSettled: number;
}

export interface UpdateAccountInternal {
  updateAccountInternal: UpdateAccountInternal_updateAccountInternal;
}

export interface UpdateAccountInternalVariables {
  sikka_id?: string | null;
  sikka_key?: string | null;
  feeSchedule?: FeeScheduleInput | null;
  enableSync?: boolean | null;
  enableOutreach?: boolean | null;
  enablePayments?: boolean | null;
  sikka_areGuarantorsDistinct?: boolean | null;
  sikka_isInsuranceArEstimated?: boolean | null;
  sikka_incrementalTransactions?: boolean | null;
  sikka_isIsolatedLocations?: boolean | null;
  status?: AccountStatus | null;
  stripe_customerId?: string | null;
  hubspot_customerId?: string | null;
  capabilities?: AccountCapability[] | null;
  syncSource?: SyncSource | null;
  claimDeniedDays?: number | null;
  secondaryClaimDays?: number | null;
  billing_minDaysSettled?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RefreshAccountSikkaLocations
// ====================================================

export interface RefreshAccountSikkaLocations_refreshAccountSikkaLocations_locations {
  __typename: "Location";
  id: string;
  name: string;
  pms_id: string;
  source: DataSource;
  enableSync: boolean;
}

export interface RefreshAccountSikkaLocations_refreshAccountSikkaLocations {
  __typename: "Account";
  id: string;
  locations: RefreshAccountSikkaLocations_refreshAccountSikkaLocations_locations[];
}

export interface RefreshAccountSikkaLocations {
  refreshAccountSikkaLocations: RefreshAccountSikkaLocations_refreshAccountSikkaLocations;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLocations
// ====================================================

export interface UpdateLocations_updateLocations {
  __typename: "Location";
  id: string;
  name: string;
  pms_id: string;
  source: DataSource;
  enableSync: boolean;
}

export interface UpdateLocations {
  updateLocations: UpdateLocations_updateLocations[];
}

export interface UpdateLocationsVariables {
  locations: LocationInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAccountDefaultBalanceType
// ====================================================

export interface UpdateAccountDefaultBalanceType_updateAccountDefaultBalanceType {
  __typename: "Account";
  id: string;
  defaultBalanceType: BalanceType;
}

export interface UpdateAccountDefaultBalanceType {
  updateAccountDefaultBalanceType: UpdateAccountDefaultBalanceType_updateAccountDefaultBalanceType;
}

export interface UpdateAccountDefaultBalanceTypeVariables {
  balanceType: BalanceType;
  updateCurrent?: boolean | null;
  updateOutstanding?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountPortal
// ====================================================

export interface AccountPortal_account {
  __typename: "Account";
  id: string;
  code: string;
  privateReplyPhone: string;
  publicReplyPhone: string;
  practicePortalSlug: string | null;
  enablePatientSelfCreation: boolean;
  hidePortalBalanceHistory: boolean;
}

export interface AccountPortal {
  account: AccountPortal_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAccountPortal
// ====================================================

export interface UpdateAccountPortal_updateAccount {
  __typename: "Account";
  id: string;
  code: string;
  privateReplyPhone: string;
  publicReplyPhone: string;
  practicePortalSlug: string | null;
  enablePatientSelfCreation: boolean;
  hidePortalBalanceHistory: boolean;
}

export interface UpdateAccountPortal {
  updateAccount: UpdateAccountPortal_updateAccount;
}

export interface UpdateAccountPortalVariables {
  practicePortalSlug?: string | null;
  enablePatientSelfCreation?: boolean | null;
  hidePortalBalanceHistory?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountPractice
// ====================================================

export interface AccountPractice_account_returnAddress_verifiedAddress {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
  street1: string;
  street2: string | null;
  city: string;
  zip: string;
  state: string;
}

export interface AccountPractice_account_returnAddress {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  zip: string;
  state: State;
  verifiedAddress: AccountPractice_account_returnAddress_verifiedAddress | null;
}

export interface AccountPractice_account_checkAddress {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  zip: string;
  state: State;
}

export interface AccountPractice_account_offices {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  zip: string;
  state: State;
}

export interface AccountPractice_account {
  __typename: "Account";
  id: string;
  name: string;
  siteUrl: string | null;
  phone: string;
  email: string | null;
  replyEmail: string;
  timezone: Timezone;
  privateReplyPhone: string;
  inboundCall_behavior: InboundCallBehavior;
  capabilities: AccountCapability[];
  logoUrl: string | null;
  statementDescriptor: string;
  generalSignature: string;
  paymentSignature: string;
  returnAddress: AccountPractice_account_returnAddress;
  checkAddress: AccountPractice_account_checkAddress;
  offices: AccountPractice_account_offices[];
}

export interface AccountPractice_dentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface AccountPractice {
  account: AccountPractice_account;
  dentists: AccountPractice_dentists[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAccountPractice
// ====================================================

export interface UpdateAccountPractice_updateAccount {
  __typename: "Account";
  id: string;
  siteUrl: string | null;
  phone: string;
  privateReplyPhone: string;
  email: string | null;
  replyEmail: string;
  logoUrl: string | null;
  generalSignature: string;
  paymentSignature: string;
  statementDescriptor: string;
}

export interface UpdateAccountPractice {
  updateAccount: UpdateAccountPractice_updateAccount;
}

export interface UpdateAccountPracticeVariables {
  siteUrl?: string | null;
  phone?: string | null;
  privateReplyPhone?: string | null;
  email?: string | null;
  replyEmail?: string | null;
  timezone?: Timezone | null;
  logoUrl?: string | null;
  generalSignature?: string | null;
  paymentSignature?: string | null;
  statementDescriptor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountProcessing
// ====================================================

export interface AccountProcessing_account_stripe {
  __typename: "StripeAccountFields";
  id: string;
  loginLink: string;
  acceptPayments: boolean;
  bankTransfers: boolean;
  required: string | null;
  verifying: string | null;
  payoutInterval: string | null;
  payoutAnchor: string | null;
}

export interface AccountProcessing_account {
  __typename: "Account";
  id: string;
  stripe: AccountProcessing_account_stripe | null;
  capabilities: AccountCapability[];
  sikka_areGuarantorsDistinct: boolean;
}

export interface AccountProcessing {
  account: AccountProcessing_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountPostingSettings
// ====================================================

export interface AccountPostingSettings_account_postingSettings {
  __typename: "PostingSettings";
  id: string;
  credit_payments: string | null;
  credit_installments: string | null;
  credit_membership: string | null;
  debit_payments: string | null;
  debit_installments: string | null;
  debit_membership: string | null;
  ach_payments: string | null;
  ach_installments: string | null;
  ach_membership: string | null;
  adjustment_code: string | null;
  enabled: boolean;
  smartAllocate: boolean;
  postingDate: PostingDate;
  postingAmount: PostingAmount;
}

export interface AccountPostingSettings_account_paymentTypes {
  __typename: "PaymentType";
  code: string;
  description: string;
}

export interface AccountPostingSettings_account_adjustmentTypes {
  __typename: "AdjustmentType";
  code: string;
  description: string;
}

export interface AccountPostingSettings_account {
  __typename: "Account";
  id: string;
  capabilities: AccountCapability[];
  sikka_areGuarantorsDistinct: boolean;
  postingSettings: AccountPostingSettings_account_postingSettings;
  paymentTypes: AccountPostingSettings_account_paymentTypes[];
  adjustmentTypes: AccountPostingSettings_account_adjustmentTypes[];
}

export interface AccountPostingSettings {
  account: AccountPostingSettings_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAccountPostingSettings
// ====================================================

export interface UpdateAccountPostingSettings_updatePostingSettings {
  __typename: "PostingSettings";
  id: string;
  credit_payments: string | null;
  credit_installments: string | null;
  credit_membership: string | null;
  debit_payments: string | null;
  debit_installments: string | null;
  debit_membership: string | null;
  ach_payments: string | null;
  ach_installments: string | null;
  ach_membership: string | null;
  adjustment_code: string | null;
  enabled: boolean;
  smartAllocate: boolean;
  postingDate: PostingDate;
  postingAmount: PostingAmount;
}

export interface UpdateAccountPostingSettings {
  updatePostingSettings: UpdateAccountPostingSettings_updatePostingSettings;
}

export interface UpdateAccountPostingSettingsVariables {
  postingSettings: PostingSettingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleAutomatedPosting
// ====================================================

export interface ToggleAutomatedPosting_toggleAutomatedPosting {
  __typename: "PostingSettings";
  id: string;
  enabled: boolean;
}

export interface ToggleAutomatedPosting {
  toggleAutomatedPosting: ToggleAutomatedPosting_toggleAutomatedPosting;
}

export interface ToggleAutomatedPostingVariables {
  postingSettingsId: string;
  enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountUsers
// ====================================================

export interface AccountUsers_account_users_google {
  __typename: "GoogleUserFields";
  email: string;
  role: UserRole;
  verified: boolean;
}

export interface AccountUsers_account_users {
  __typename: "User";
  firstName: string;
  id: string;
  lastName: string;
  google: AccountUsers_account_users_google;
  notify_enrolled: boolean;
  notify_invoiceFailed: boolean;
  notify_paymentCompleted: boolean;
  notify_eodPracticeReport: boolean;
  isGroupUserForCurrentAccount: boolean;
}

export interface AccountUsers_account {
  __typename: "Account";
  id: string;
  users: AccountUsers_account_users[];
}

export interface AccountUsers {
  account: AccountUsers_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertAddress
// ====================================================

export interface UpsertAddress_upsertAddress_verifiedAddress {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
  street1: string;
  street2: string | null;
  city: string;
  zip: string;
  state: string;
}

export interface UpsertAddress_upsertAddress {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
  verifiedAddress: UpsertAddress_upsertAddress_verifiedAddress | null;
}

export interface UpsertAddress {
  upsertAddress: UpsertAddress_upsertAddress;
}

export interface UpsertAddressVariables {
  id?: string | null;
  name: string;
  street1: string;
  street2?: string | null;
  city: string;
  state: State;
  zip: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAddress
// ====================================================

export interface DeleteAddress_deleteAddress {
  __typename: "Address";
  id: string;
}

export interface DeleteAddress {
  deleteAddress: DeleteAddress_deleteAddress;
}

export interface DeleteAddressVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyAccountReturnAddress
// ====================================================

export interface VerifyAccountReturnAddress_verifyAndSaveAccountReturnAddress {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
  street1: string;
  street2: string | null;
  city: string;
  zip: string;
  state: string;
}

export interface VerifyAccountReturnAddress {
  verifyAndSaveAccountReturnAddress: VerifyAccountReturnAddress_verifyAndSaveAccountReturnAddress | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOffices
// ====================================================

export interface GetOffices_account_offices {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface GetOffices_account {
  __typename: "Account";
  id: string;
  offices: GetOffices_account_offices[];
}

export interface GetOffices {
  account: GetOffices_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BalanceCard
// ====================================================

export interface BalanceCard_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface BalanceCard_contact_balance_metrics {
  __typename: "Balance";
  id: string;
  type: BalanceType;
  patientPortion: number;
  insurancePortion: number;
  writeOffEstimate: number;
  ledger_patientPortion: number;
  ledger_insurancePortion: number;
  summary_patientPortion: number;
  summary_insurancePortion: number;
  manual_patientPortion: number | null;
  manual_insurancePortion: number | null;
  installmentsPending: number;
  shadowBalance: number;
  daysPastDue: number | null;
}

export interface BalanceCard_contact_balance {
  __typename: "ContactBalance";
  metrics: BalanceCard_contact_balance_metrics;
}

export interface BalanceCard_contact {
  __typename: "Contact";
  id: string;
  dob: BalanceCard_contact_dob | null;
  balance: BalanceCard_contact_balance;
}

export interface BalanceCard {
  contact: BalanceCard_contact;
}

export interface BalanceCardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OverrideBalance
// ====================================================

export interface OverrideBalance_overrideBalance {
  __typename: "Balance";
  id: string;
  type: BalanceType;
  patientPortion: number;
  insurancePortion: number;
  writeOffEstimate: number;
  ledger_patientPortion: number;
  ledger_insurancePortion: number;
  summary_patientPortion: number;
  summary_insurancePortion: number;
  manual_patientPortion: number | null;
  manual_insurancePortion: number | null;
  installmentsPending: number;
  shadowBalance: number;
  daysPastDue: number | null;
}

export interface OverrideBalance {
  overrideBalance: OverrideBalance_overrideBalance;
}

export interface OverrideBalanceVariables {
  guarantorId: string;
  type: BalanceType;
  manual_patientPortion?: number | null;
  manual_insurancePortion?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveProcessedPendingAmount
// ====================================================

export interface RemoveProcessedPendingAmount {
  removeProcessedPendingAmount: string;
}

export interface RemoveProcessedPendingAmountVariables {
  guarantorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartFlow
// ====================================================

export interface StartFlow_startFlow_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface StartFlow_startFlow {
  __typename: "Contact";
  id: string;
  latestEmailNotification: StartFlow_startFlow_latestEmailNotification | null;
}

export interface StartFlow {
  startFlow: StartFlow_startFlow;
}

export interface StartFlowVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EndFlow
// ====================================================

export interface EndFlow_endFlow {
  __typename: "Contact";
  id: string;
}

export interface EndFlow {
  endFlow: EndFlow_endFlow;
}

export interface EndFlowVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetBlocking
// ====================================================

export interface SetBlocking_setGuarantorOutreachOptions_balance_engagementDetails {
  __typename: "BalanceEngagementDetails";
  status: BalanceEngagementStatus;
  blockedReasons: BalanceBlockedReason[];
}

export interface SetBlocking_setGuarantorOutreachOptions_balance {
  __typename: "ContactBalance";
  engagementDetails: SetBlocking_setGuarantorOutreachOptions_balance_engagementDetails | null;
}

export interface SetBlocking_setGuarantorOutreachOptions {
  __typename: "Contact";
  id: string;
  suppressed: boolean;
  allowed: boolean;
  balance: SetBlocking_setGuarantorOutreachOptions_balance;
}

export interface SetBlocking {
  setGuarantorOutreachOptions: SetBlocking_setGuarantorOutreachOptions;
}

export interface SetBlockingVariables {
  contactId: string;
  suppressed: boolean;
  allowed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BalanceFilter
// ====================================================

export interface BalanceFilter_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface BalanceFilter {
  balanceFilter: BalanceFilter_balanceFilter;
}

export interface BalanceFilterVariables {
  filterId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBalanceFilter
// ====================================================

export interface UpdateBalanceFilter_updateBalanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface UpdateBalanceFilter {
  updateBalanceFilter: UpdateBalanceFilter_updateBalanceFilter;
}

export interface UpdateBalanceFilterVariables {
  filterId: string;
  balanceFilter: PartialBalanceFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BillingAutomation
// ====================================================

export interface BillingAutomation_account_billing_workflow_start_emailTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface BillingAutomation_account_billing_workflow_start_smsTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface BillingAutomation_account_billing_workflow_start_letterTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface BillingAutomation_account_billing_workflow_reminder_emailTemplates {
  __typename: "NotificationTemplate";
  id: string;
}

export interface BillingAutomation_account_billing_workflow_reminder_smsTemplates {
  __typename: "NotificationTemplate";
  id: string;
}

export interface BillingAutomation_account_billing_workflow_reminder_letterTemplates_template {
  __typename: "NotificationTemplate";
  id: string;
}

export interface BillingAutomation_account_billing_workflow_reminder_letterTemplates {
  __typename: "LetterTemplateWithDelay";
  template: BillingAutomation_account_billing_workflow_reminder_letterTemplates_template;
  delay: number;
}

export interface BillingAutomation_account_billing_workflow_final_emailTemplate {
  __typename: "NotificationTemplate";
  id: string;
  name: string;
}

export interface BillingAutomation_account_billing_workflow_final_smsTemplate {
  __typename: "NotificationTemplate";
  id: string;
  name: string;
}

export interface BillingAutomation_account_billing_workflow_final_letterTemplate {
  __typename: "NotificationTemplate";
  id: string;
  name: string;
}

export interface BillingAutomation_account_billing_workflow {
  __typename: "Workflow";
  id: string;
  enabled: boolean;
  start_dailyLimit: number;
  start_emailTemplate: BillingAutomation_account_billing_workflow_start_emailTemplate | null;
  start_smsTemplate: BillingAutomation_account_billing_workflow_start_smsTemplate | null;
  start_letterTemplate: BillingAutomation_account_billing_workflow_start_letterTemplate | null;
  start_reminder_buffer: number;
  reminder_duration: number;
  reminder_cadence: number;
  reminder_emailTemplates: BillingAutomation_account_billing_workflow_reminder_emailTemplates[];
  reminder_smsTemplates: BillingAutomation_account_billing_workflow_reminder_smsTemplates[];
  reminder_letterTemplates: BillingAutomation_account_billing_workflow_reminder_letterTemplates[];
  reminder_final_buffer: number;
  final_emailTemplate: BillingAutomation_account_billing_workflow_final_emailTemplate | null;
  final_smsTemplate: BillingAutomation_account_billing_workflow_final_smsTemplate | null;
  final_letterTemplate: BillingAutomation_account_billing_workflow_final_letterTemplate | null;
}

export interface BillingAutomation_account_billing_startFilter {
  __typename: "BalanceFilter";
  id: string;
  restrictPending: boolean;
  minBalance: number;
  maxBalance: number;
  minDaysSinceLastVisit: number | null;
  maxDaysSinceLastVisit: number | null;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface BillingAutomation_account_notificationTemplates {
  __typename: "NotificationTemplate";
  id: string;
  channel: NotificationChannel;
  name: string;
  layout: NotificationLayout;
  title: string | null;
  body: string;
  subject: string | null;
  buttonLabel: string | null;
  buttonUrl: string | null;
  signature: string | null;
}

export interface BillingAutomation_account {
  __typename: "Account";
  id: string;
  capabilities: AccountCapability[];
  billing_workflow: BillingAutomation_account_billing_workflow;
  billing_startFilter: BillingAutomation_account_billing_startFilter;
  billing_restrictedInsuranceCompanies: string[];
  billing_restrictedTransactionCodes: string[];
  billing_restrictedTransactionCodeWindow: number;
  billing_restrictedPaymentPlanWindow: number | null;
  billing_restrictedBillingTypes: string[];
  billing_restrictedAgeMin: number | null;
  billing_restrictedAgeMax: number | null;
  billing_restrictedClaimStatuses: string[];
  notificationTemplates: BillingAutomation_account_notificationTemplates[];
}

export interface BillingAutomation {
  account: BillingAutomation_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleBillingWorkflow
// ====================================================

export interface ToggleBillingWorkflow_toggleWorkflow {
  __typename: "Workflow";
  id: string;
  enabled: boolean;
}

export interface ToggleBillingWorkflow {
  toggleWorkflow: ToggleBillingWorkflow_toggleWorkflow;
}

export interface ToggleBillingWorkflowVariables {
  workflowId: string;
  enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBillingBlocking
// ====================================================

export interface UpdateBillingBlocking_updateAccount {
  __typename: "Account";
  id: string;
  billing_restrictedInsuranceCompanies: string[];
  billing_restrictedTransactionCodes: string[];
  billing_restrictedTransactionCodeWindow: number;
  billing_restrictedPaymentPlanWindow: number | null;
  billing_restrictedBillingTypes: string[];
  billing_restrictedAgeMin: number | null;
  billing_restrictedAgeMax: number | null;
  billing_restrictedClaimStatuses: string[];
}

export interface UpdateBillingBlocking {
  updateAccount: UpdateBillingBlocking_updateAccount;
}

export interface UpdateBillingBlockingVariables {
  restrictedInsuranceCompanies?: string[] | null;
  restrictedTransactionCodes?: string[] | null;
  billing_restrictedTransactionCodeWindow?: number | null;
  restrictedClaimStatuses?: string[] | null;
  restrictedBillingTypes?: string[] | null;
  restrictedPaymentPlanWindow?: number | null;
  restrictedAgeMin?: number | null;
  restrictedAgeMax?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBillingFlowStart
// ====================================================

export interface UpdateBillingFlowStart_updateBillingWorkflowStart_billing_workflow_start_emailTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface UpdateBillingFlowStart_updateBillingWorkflowStart_billing_workflow_start_smsTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface UpdateBillingFlowStart_updateBillingWorkflowStart_billing_workflow_start_letterTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface UpdateBillingFlowStart_updateBillingWorkflowStart_billing_workflow {
  __typename: "Workflow";
  id: string;
  start_dailyLimit: number;
  start_emailTemplate: UpdateBillingFlowStart_updateBillingWorkflowStart_billing_workflow_start_emailTemplate | null;
  start_smsTemplate: UpdateBillingFlowStart_updateBillingWorkflowStart_billing_workflow_start_smsTemplate | null;
  start_letterTemplate: UpdateBillingFlowStart_updateBillingWorkflowStart_billing_workflow_start_letterTemplate | null;
  start_reminder_buffer: number;
}

export interface UpdateBillingFlowStart_updateBillingWorkflowStart_billing_startFilter {
  __typename: "BalanceFilter";
  id: string;
  restrictPending: boolean;
  minBalance: number;
  maxBalance: number;
  minDaysSinceLastVisit: number | null;
  maxDaysSinceLastVisit: number | null;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface UpdateBillingFlowStart_updateBillingWorkflowStart {
  __typename: "Account";
  id: string;
  billing_workflow: UpdateBillingFlowStart_updateBillingWorkflowStart_billing_workflow;
  billing_startFilter: UpdateBillingFlowStart_updateBillingWorkflowStart_billing_startFilter;
}

export interface UpdateBillingFlowStart {
  updateBillingWorkflowStart: UpdateBillingFlowStart_updateBillingWorkflowStart;
}

export interface UpdateBillingFlowStartVariables {
  filter: FullBalanceFilterInput;
  dailyLimit: number;
  emailTemplateId?: string | null;
  smsTemplateId?: string | null;
  letterTemplateId?: string | null;
  start_reminder_buffer: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBillingFlowReminders
// ====================================================

export interface UpdateBillingFlowReminders_updateBillingWorkflowReminders_reminder_emailTemplates {
  __typename: "NotificationTemplate";
  id: string;
}

export interface UpdateBillingFlowReminders_updateBillingWorkflowReminders_reminder_smsTemplates {
  __typename: "NotificationTemplate";
  id: string;
}

export interface UpdateBillingFlowReminders_updateBillingWorkflowReminders_reminder_letterTemplates_template {
  __typename: "NotificationTemplate";
  id: string;
}

export interface UpdateBillingFlowReminders_updateBillingWorkflowReminders_reminder_letterTemplates {
  __typename: "LetterTemplateWithDelay";
  template: UpdateBillingFlowReminders_updateBillingWorkflowReminders_reminder_letterTemplates_template;
  delay: number;
}

export interface UpdateBillingFlowReminders_updateBillingWorkflowReminders {
  __typename: "Workflow";
  id: string;
  reminder_duration: number;
  reminder_cadence: number;
  reminder_emailTemplates: UpdateBillingFlowReminders_updateBillingWorkflowReminders_reminder_emailTemplates[];
  reminder_smsTemplates: UpdateBillingFlowReminders_updateBillingWorkflowReminders_reminder_smsTemplates[];
  reminder_letterTemplates: UpdateBillingFlowReminders_updateBillingWorkflowReminders_reminder_letterTemplates[];
  reminder_final_buffer: number;
}

export interface UpdateBillingFlowReminders {
  updateBillingWorkflowReminders: UpdateBillingFlowReminders_updateBillingWorkflowReminders;
}

export interface UpdateBillingFlowRemindersVariables {
  duration: number;
  cadence: number;
  reminder_final_buffer: number;
  smsTemplateIds: string[];
  emailTemplateIds: string[];
  letterTemplates: LetterReminderTemplateInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBillingFlowEnd
// ====================================================

export interface UpdateBillingFlowEnd_updateBillingWorkflowEnd_final_emailTemplate {
  __typename: "NotificationTemplate";
  id: string;
  name: string;
}

export interface UpdateBillingFlowEnd_updateBillingWorkflowEnd_final_smsTemplate {
  __typename: "NotificationTemplate";
  id: string;
  name: string;
}

export interface UpdateBillingFlowEnd_updateBillingWorkflowEnd_final_letterTemplate {
  __typename: "NotificationTemplate";
  id: string;
  name: string;
}

export interface UpdateBillingFlowEnd_updateBillingWorkflowEnd {
  __typename: "Workflow";
  id: string;
  final_emailTemplate: UpdateBillingFlowEnd_updateBillingWorkflowEnd_final_emailTemplate | null;
  final_smsTemplate: UpdateBillingFlowEnd_updateBillingWorkflowEnd_final_smsTemplate | null;
  final_letterTemplate: UpdateBillingFlowEnd_updateBillingWorkflowEnd_final_letterTemplate | null;
}

export interface UpdateBillingFlowEnd {
  updateBillingWorkflowEnd: UpdateBillingFlowEnd_updateBillingWorkflowEnd;
}

export interface UpdateBillingFlowEndVariables {
  emailTemplateId?: string | null;
  smsTemplateId?: string | null;
  letterTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BillingDashboard
// ====================================================

export interface BillingDashboard_account_latestSync {
  __typename: "SyncEvent";
  id: string;
  date: number;
}

export interface BillingDashboard_account {
  __typename: "Account";
  id: string;
  syncSource: SyncSource;
  latestSync: BillingDashboard_account_latestSync | null;
}

export interface BillingDashboard_balanceMetrics_engagement_outstanding_all_total {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
}

export interface BillingDashboard_balanceMetrics_engagement_outstanding_all {
  __typename: "BalanceStratification";
  total: BillingDashboard_balanceMetrics_engagement_outstanding_all_total;
}

export interface BillingDashboard_balanceMetrics_engagement_outstanding {
  __typename: "BalanceEngagementOutstanding";
  all: BillingDashboard_balanceMetrics_engagement_outstanding_all;
}

export interface BillingDashboard_balanceMetrics_engagement_scheduled_total {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
}

export interface BillingDashboard_balanceMetrics_engagement_scheduled {
  __typename: "BalanceStratification";
  total: BillingDashboard_balanceMetrics_engagement_scheduled_total;
}

export interface BillingDashboard_balanceMetrics_engagement_engaged_total {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
}

export interface BillingDashboard_balanceMetrics_engagement_engaged {
  __typename: "BalanceStratification";
  total: BillingDashboard_balanceMetrics_engagement_engaged_total;
}

export interface BillingDashboard_balanceMetrics_engagement_followUp_total {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
}

export interface BillingDashboard_balanceMetrics_engagement_followUp {
  __typename: "BalanceStratification";
  total: BillingDashboard_balanceMetrics_engagement_followUp_total;
}

export interface BillingDashboard_balanceMetrics_engagement {
  __typename: "BalanceEngagement";
  outstanding: BillingDashboard_balanceMetrics_engagement_outstanding;
  scheduled: BillingDashboard_balanceMetrics_engagement_scheduled;
  engaged: BillingDashboard_balanceMetrics_engagement_engaged;
  followUp: BillingDashboard_balanceMetrics_engagement_followUp;
}

export interface BillingDashboard_balanceMetrics {
  __typename: "Balances";
  engagement: BillingDashboard_balanceMetrics_engagement;
  countPositive: number;
  patientPortionPositive: number;
}

export interface BillingDashboard_balancesConnection_edges_node_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface BillingDashboard_balancesConnection_edges_node_guarantor {
  __typename: "Contact";
  id: string;
  name: BillingDashboard_balancesConnection_edges_node_guarantor_name;
}

export interface BillingDashboard_balancesConnection_edges_node {
  __typename: "Balance";
  guarantor: BillingDashboard_balancesConnection_edges_node_guarantor;
}

export interface BillingDashboard_balancesConnection_edges {
  __typename: "BalanceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: BillingDashboard_balancesConnection_edges_node;
}

export interface BillingDashboard_balancesConnection {
  __typename: "BalanceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: BillingDashboard_balancesConnection_edges[];
}

export interface BillingDashboard_analytics_paymentTransactions_ar_collected {
  __typename: "TimeSeries";
  last30: number;
}

export interface BillingDashboard_analytics_paymentTransactions_ar_processed {
  __typename: "TimeSeries";
  last30: number;
  last30Count: number;
}

export interface BillingDashboard_analytics_paymentTransactions {
  __typename: "AnalyticsPaymentTransactions";
  ar_collected: BillingDashboard_analytics_paymentTransactions_ar_collected;
  ar_processed: BillingDashboard_analytics_paymentTransactions_ar_processed;
}

export interface BillingDashboard_analytics_ar {
  __typename: "AnalyticsAR";
  last30PatientsPromptedCount: number;
}

export interface BillingDashboard_analytics {
  __typename: "Analytics";
  paymentTransactions: BillingDashboard_analytics_paymentTransactions;
  ar: BillingDashboard_analytics_ar;
}

export interface BillingDashboard_chargesConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface BillingDashboard_chargesConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  name: BillingDashboard_chargesConnection_edges_node_contact_name;
}

export interface BillingDashboard_chargesConnection_edges_node {
  __typename: "Charge";
  id: string;
  contact: BillingDashboard_chargesConnection_edges_node_contact;
}

export interface BillingDashboard_chargesConnection_edges {
  __typename: "ChargeEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: BillingDashboard_chargesConnection_edges_node;
}

export interface BillingDashboard_chargesConnection {
  __typename: "ChargeConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: BillingDashboard_chargesConnection_edges[];
}

export interface BillingDashboard_promptsConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface BillingDashboard_promptsConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  name: BillingDashboard_promptsConnection_edges_node_contact_name;
}

export interface BillingDashboard_promptsConnection_edges_node {
  __typename: "OutreachFlow";
  contact: BillingDashboard_promptsConnection_edges_node_contact;
}

export interface BillingDashboard_promptsConnection_edges {
  __typename: "OutreachFlowEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: BillingDashboard_promptsConnection_edges_node;
}

export interface BillingDashboard_promptsConnection {
  __typename: "OutreachFlowConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: BillingDashboard_promptsConnection_edges[];
}

export interface BillingDashboard {
  account: BillingDashboard_account;
  balanceMetrics: BillingDashboard_balanceMetrics;
  balancesConnection: BillingDashboard_balancesConnection;
  analytics: BillingDashboard_analytics;
  chargesConnection: BillingDashboard_chargesConnection;
  promptsConnection: BillingDashboard_promptsConnection;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResyncSikka
// ====================================================

export interface ResyncSikka {
  createSikkaSynctask: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecalculateBalances
// ====================================================

export interface RecalculateBalances {
  recalculateBalances: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlockedTable
// ====================================================

export interface BlockedTable_balancesConnection_edges_node_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface BlockedTable_balancesConnection_edges_node_guarantor_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface BlockedTable_balancesConnection_edges_node_guarantor_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface BlockedTable_balancesConnection_edges_node_guarantor_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface BlockedTable_balancesConnection_edges_node_guarantor_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface BlockedTable_balancesConnection_edges_node_guarantor_balance_engagementDetails {
  __typename: "BalanceEngagementDetails";
  id: string;
  status: BalanceEngagementStatus;
  blockedReasons: BalanceBlockedReason[];
}

export interface BlockedTable_balancesConnection_edges_node_guarantor_balance {
  __typename: "ContactBalance";
  engagementDetails: BlockedTable_balancesConnection_edges_node_guarantor_balance_engagementDetails | null;
}

export interface BlockedTable_balancesConnection_edges_node_guarantor {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  name: BlockedTable_balancesConnection_edges_node_guarantor_name;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: BlockedTable_balancesConnection_edges_node_guarantor_dob | null;
  latestEmailNotification: BlockedTable_balancesConnection_edges_node_guarantor_latestEmailNotification | null;
  latestSMSNotification: BlockedTable_balancesConnection_edges_node_guarantor_latestSMSNotification | null;
  latestLetterNotification: BlockedTable_balancesConnection_edges_node_guarantor_latestLetterNotification | null;
  balance: BlockedTable_balancesConnection_edges_node_guarantor_balance;
}

export interface BlockedTable_balancesConnection_edges_node {
  __typename: "Balance";
  id: string;
  patientPortion: number;
  insurancePortion: number;
  daysPastDue: number | null;
  dpdBucket: DPDBucket;
  guarantor: BlockedTable_balancesConnection_edges_node_guarantor;
}

export interface BlockedTable_balancesConnection_edges {
  __typename: "BalanceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: BlockedTable_balancesConnection_edges_node;
}

export interface BlockedTable_balancesConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface BlockedTable_balancesConnection {
  __typename: "BalanceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: BlockedTable_balancesConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: BlockedTable_balancesConnection_pageInfo;
}

export interface BlockedTable {
  balancesConnection: BlockedTable_balancesConnection;
}

export interface BlockedTableVariables {
  search?: string | null;
  contactIds?: string[] | null;
  blockedReasons?: BalanceBlockedReason[] | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChargeGuarantorCard
// ====================================================

export interface ChargeGuarantorCard_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface ChargeGuarantorCard_contact_balance_metrics {
  __typename: "Balance";
  id: string;
  patientPortion: number;
}

export interface ChargeGuarantorCard_contact_balance {
  __typename: "ContactBalance";
  metrics: ChargeGuarantorCard_contact_balance_metrics;
}

export interface ChargeGuarantorCard_contact_account_feeSchedule {
  __typename: "FeeSchedule";
  id: string;
  payments_patient_ach: number;
  payments_patient_cc: number;
  payments_patient_30: boolean;
}

export interface ChargeGuarantorCard_contact_account {
  __typename: "Account";
  id: string;
  feeSchedule: ChargeGuarantorCard_contact_account_feeSchedule;
}

export interface ChargeGuarantorCard_contact_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
  isCredit: boolean;
  institution: string;
  last4: string;
}

export interface ChargeGuarantorCard_contact_stripe {
  __typename: "ContactStripeFields";
  defaultSource: ChargeGuarantorCard_contact_stripe_defaultSource | null;
}

export interface ChargeGuarantorCard_contact {
  __typename: "Contact";
  id: string;
  name: ChargeGuarantorCard_contact_name;
  email: string | null;
  balance: ChargeGuarantorCard_contact_balance;
  account: ChargeGuarantorCard_contact_account;
  stripe: ChargeGuarantorCard_contact_stripe;
}

export interface ChargeGuarantorCard {
  contact: ChargeGuarantorCard_contact;
}

export interface ChargeGuarantorCardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompleteGuarantorCharge
// ====================================================

export interface CompleteGuarantorCharge_chargeGuarantor {
  __typename: "Charge";
  id: string;
  balanceAmount: number;
}

export interface CompleteGuarantorCharge {
  chargeGuarantor: CompleteGuarantorCharge_chargeGuarantor;
}

export interface CompleteGuarantorChargeVariables {
  guarantorId: string;
  amount: number;
  sendReceipt: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChargeSheet
// ====================================================

export interface ChargeSheet_charge_transaction_writebackMetrics_payments {
  __typename: "Writeback";
  id: string;
  status: WritebackStatus;
  amount: number;
  type: WritebackType;
  pms_procedureId: string | null;
  createdAt: string;
}

export interface ChargeSheet_charge_transaction_writebackMetrics {
  __typename: "TransactionWritebackMetrics";
  id: string;
  postDate: number | null;
  status: TransactionWritebackStatus | null;
  totalPosted: number;
  paymentsTotal: number;
  adjustmentsTotal: number;
  allocatedTotal: number;
  unallocatedTotal: number;
  payments: ChargeSheet_charge_transaction_writebackMetrics_payments[];
}

export interface ChargeSheet_charge_transaction {
  __typename: "Transaction";
  id: string;
  type: TransactionType;
  stripeId_subscription: string | null;
  writebackMetrics: ChargeSheet_charge_transaction_writebackMetrics | null;
  installmentPlanId: string | null;
}

export interface ChargeSheet_charge_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface ChargeSheet_charge_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface ChargeSheet_charge_contact_account_postingSettings {
  __typename: "PostingSettings";
  enabled: boolean;
  postingDate: PostingDate;
  postingAmount: PostingAmount;
}

export interface ChargeSheet_charge_contact_account {
  __typename: "Account";
  name: string;
  phone: string;
  siteUrl: string | null;
  logoUrl: string | null;
  postingSettings: ChargeSheet_charge_contact_account_postingSettings;
  capabilities: AccountCapability[];
}

export interface ChargeSheet_charge_contact {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  source: DataSource;
  code: string;
  name: ChargeSheet_charge_contact_name;
  dob: ChargeSheet_charge_contact_dob | null;
  account: ChargeSheet_charge_contact_account;
}

export interface ChargeSheet_charge_invoice {
  __typename: "Invoice";
  id: string;
  code: string | null;
  date: number;
}

export interface ChargeSheet_charge {
  __typename: "Charge";
  id: string;
  status: ChargeStatus;
  date: number;
  payoutDate: number | null;
  patientFee: number;
  grossAmount: number;
  balanceAmount: number;
  refundedAmount: number;
  disputedAmount: number;
  practiceFee: number | null;
  netAmount: number | null;
  last4: string;
  institution: string;
  recorded: boolean;
  transaction: ChargeSheet_charge_transaction;
  contact: ChargeSheet_charge_contact;
  invoice: ChargeSheet_charge_invoice | null;
}

export interface ChargeSheet {
  charge: ChargeSheet_charge;
}

export interface ChargeSheetVariables {
  chargeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCharge
// ====================================================

export interface UpdateCharge_updateCharge {
  __typename: "Charge";
  id: string;
  recorded: boolean;
}

export interface UpdateCharge {
  updateCharge: UpdateCharge_updateCharge;
}

export interface UpdateChargeVariables {
  chargeId: string;
  recorded?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: WritebackCharge
// ====================================================

export interface WritebackCharge_writebackCharge_writebackMetrics {
  __typename: "TransactionWritebackMetrics";
  id: string;
  status: TransactionWritebackStatus | null;
  totalPosted: number;
}

export interface WritebackCharge_writebackCharge {
  __typename: "Transaction";
  id: string;
  writebackMetrics: WritebackCharge_writebackCharge_writebackMetrics | null;
}

export interface WritebackCharge {
  writebackCharge: WritebackCharge_writebackCharge;
}

export interface WritebackChargeVariables {
  chargeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendReceipt
// ====================================================

export interface ResendReceipt {
  resendReceipt: boolean;
}

export interface ResendReceiptVariables {
  stripeChargeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GeneratePracticeChargeReceiptPdf
// ====================================================

export interface GeneratePracticeChargeReceiptPdf {
  generateChargeReceiptPdf: string;
}

export interface GeneratePracticeChargeReceiptPdfVariables {
  chargeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChargesTable
// ====================================================

export interface ChargesTable_chargesConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface ChargesTable_chargesConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  name: ChargesTable_chargesConnection_edges_node_contact_name;
}

export interface ChargesTable_chargesConnection_edges_node_transaction_writebackMetrics {
  __typename: "TransactionWritebackMetrics";
  id: string;
  status: TransactionWritebackStatus | null;
}

export interface ChargesTable_chargesConnection_edges_node_transaction {
  __typename: "Transaction";
  id: string;
  type: TransactionType;
  stripeId_subscription: string | null;
  writebackMetrics: ChargesTable_chargesConnection_edges_node_transaction_writebackMetrics | null;
  installmentPlanId: string | null;
}

export interface ChargesTable_chargesConnection_edges_node {
  __typename: "Charge";
  id: string;
  date: number;
  payoutDate: number | null;
  status: ChargeStatus;
  balanceAmount: number;
  grossAmount: number;
  netAmount: number | null;
  practiceFee: number | null;
  recorded: boolean;
  disputed: boolean;
  refundedAmount: number;
  contact: ChargesTable_chargesConnection_edges_node_contact;
  transaction: ChargesTable_chargesConnection_edges_node_transaction;
}

export interface ChargesTable_chargesConnection_edges {
  __typename: "ChargeEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: ChargesTable_chargesConnection_edges_node;
}

export interface ChargesTable_chargesConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface ChargesTable_chargesConnection {
  __typename: "ChargeConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: ChargesTable_chargesConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: ChargesTable_chargesConnection_pageInfo;
}

export interface ChargesTable {
  chargesConnection: ChargesTable_chargesConnection;
}

export interface ChargesTableVariables {
  first?: number | null;
  after?: string | null;
  filter?: InvoiceFilter | null;
  statuses?: ChargeStatus[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertDentist
// ====================================================

export interface UpsertDentist_upsertDentist {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface UpsertDentist {
  upsertDentist: UpsertDentist_upsertDentist;
}

export interface UpsertDentistVariables {
  id?: string | null;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDentist
// ====================================================

export interface DeleteDentist_deleteDentist {
  __typename: "Dentist";
  id: string;
}

export interface DeleteDentist {
  deleteDentist: DeleteDentist_deleteDentist;
}

export interface DeleteDentistVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDentists
// ====================================================

export interface GetDentists_dentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface GetDentists {
  dentists: GetDentists_dentists[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmailsTable
// ====================================================

export interface EmailsTable_notificationsConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface EmailsTable_notificationsConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  name: EmailsTable_notificationsConnection_edges_node_contact_name;
}

export interface EmailsTable_notificationsConnection_edges_node {
  __typename: "Notification";
  id: string;
  createdAt: string;
  status: NotificationStatus;
  openedAt: string | null;
  failureReason: NotificationFailureReason | null;
  contact: EmailsTable_notificationsConnection_edges_node_contact;
  templateName: string | null;
}

export interface EmailsTable_notificationsConnection_edges {
  __typename: "NotificationEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: EmailsTable_notificationsConnection_edges_node;
}

export interface EmailsTable_notificationsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface EmailsTable_notificationsConnection {
  __typename: "NotificationConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: EmailsTable_notificationsConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: EmailsTable_notificationsConnection_pageInfo;
}

export interface EmailsTable {
  notificationsConnection: EmailsTable_notificationsConnection;
}

export interface EmailsTableVariables {
  search?: string | null;
  status?: NotificationStatus | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EngagedTable
// ====================================================

export interface EngagedTable_balancesConnection_edges_node_guarantor_touchpoints {
  __typename: "Touchpoints";
  email: number;
  sms: number;
  letter: number;
}

export interface EngagedTable_balancesConnection_edges_node_guarantor_latestActiveFlow {
  __typename: "OutreachFlow";
  id: string;
  daysOnFlow: number | null;
}

export interface EngagedTable_balancesConnection_edges_node_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface EngagedTable_balancesConnection_edges_node_guarantor_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface EngagedTable_balancesConnection_edges_node_guarantor_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface EngagedTable_balancesConnection_edges_node_guarantor_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface EngagedTable_balancesConnection_edges_node_guarantor_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface EngagedTable_balancesConnection_edges_node_guarantor {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  touchpoints: EngagedTable_balancesConnection_edges_node_guarantor_touchpoints | null;
  latestActiveFlow: EngagedTable_balancesConnection_edges_node_guarantor_latestActiveFlow | null;
  email: string | null;
  phone: string | null;
  code: string;
  name: EngagedTable_balancesConnection_edges_node_guarantor_name;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: EngagedTable_balancesConnection_edges_node_guarantor_dob | null;
  latestEmailNotification: EngagedTable_balancesConnection_edges_node_guarantor_latestEmailNotification | null;
  latestSMSNotification: EngagedTable_balancesConnection_edges_node_guarantor_latestSMSNotification | null;
  latestLetterNotification: EngagedTable_balancesConnection_edges_node_guarantor_latestLetterNotification | null;
}

export interface EngagedTable_balancesConnection_edges_node {
  __typename: "Balance";
  id: string;
  patientPortion: number;
  insurancePortion: number;
  nextNotification: number | null;
  daysPastDue: number | null;
  dpdBucket: DPDBucket;
  guarantor: EngagedTable_balancesConnection_edges_node_guarantor;
}

export interface EngagedTable_balancesConnection_edges {
  __typename: "BalanceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: EngagedTable_balancesConnection_edges_node;
}

export interface EngagedTable_balancesConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface EngagedTable_balancesConnection {
  __typename: "BalanceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: EngagedTable_balancesConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: EngagedTable_balancesConnection_pageInfo;
}

export interface EngagedTable {
  balancesConnection: EngagedTable_balancesConnection;
}

export interface EngagedTableVariables {
  search?: string | null;
  contactIds?: string[] | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EnrollInstallmentsCard
// ====================================================

export interface EnrollInstallmentsCard_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface EnrollInstallmentsCard_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface EnrollInstallmentsCard_contact_balance_metrics {
  __typename: "Balance";
  patientPortion: number;
}

export interface EnrollInstallmentsCard_contact_balance {
  __typename: "ContactBalance";
  metrics: EnrollInstallmentsCard_contact_balance_metrics;
}

export interface EnrollInstallmentsCard_contact_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
}

export interface EnrollInstallmentsCard_contact_stripe {
  __typename: "ContactStripeFields";
  defaultSource: EnrollInstallmentsCard_contact_stripe_defaultSource | null;
}

export interface EnrollInstallmentsCard_contact {
  __typename: "Contact";
  id: string;
  name: EnrollInstallmentsCard_contact_name;
  dob: EnrollInstallmentsCard_contact_dob | null;
  balance: EnrollInstallmentsCard_contact_balance;
  stripe: EnrollInstallmentsCard_contact_stripe;
}

export interface EnrollInstallmentsCard {
  contact: EnrollInstallmentsCard_contact;
}

export interface EnrollInstallmentsCardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EnrollInstallments
// ====================================================

export interface EnrollInstallments_enrollInstallments {
  __typename: "Charge";
  id: string;
  grossAmount: number;
}

export interface EnrollInstallments {
  enrollInstallments: EnrollInstallments_enrollInstallments;
}

export interface EnrollInstallmentsVariables {
  contactId: string;
  amount: number;
  installmentTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFinancingProvider
// ====================================================

export interface UpsertFinancingProvider_upsertFinancingProvider_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface UpsertFinancingProvider_upsertFinancingProvider {
  __typename: "FinancingProvider";
  id: string;
  name: string;
  url: string;
  description: string;
  enabled: boolean;
  balanceFilter: UpsertFinancingProvider_upsertFinancingProvider_balanceFilter;
}

export interface UpsertFinancingProvider {
  upsertFinancingProvider: UpsertFinancingProvider_upsertFinancingProvider;
}

export interface UpsertFinancingProviderVariables {
  id?: string | null;
  name: string;
  url: string;
  description: string;
  balanceFilter: PartialBalanceFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFinancingProvider
// ====================================================

export interface DeleteFinancingProvider_deleteFinancingProvider {
  __typename: "FinancingProvider";
  id: string;
}

export interface DeleteFinancingProvider {
  deleteFinancingProvider: DeleteFinancingProvider_deleteFinancingProvider;
}

export interface DeleteFinancingProviderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleFinancingProvider
// ====================================================

export interface ToggleFinancingProvider_toggleFinancingProvider {
  __typename: "FinancingProvider";
  id: string;
  enabled: boolean;
}

export interface ToggleFinancingProvider {
  toggleFinancingProvider: ToggleFinancingProvider_toggleFinancingProvider;
}

export interface ToggleFinancingProviderVariables {
  id: string;
  enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FollowUpTable
// ====================================================

export interface FollowUpTable_balancesConnection_edges_node_guarantor_touchpoints {
  __typename: "Touchpoints";
  email: number;
  sms: number;
  letter: number;
}

export interface FollowUpTable_balancesConnection_edges_node_guarantor_lastCompletedFlow {
  __typename: "OutreachFlow";
  id: string;
  endDate: number | null;
  endReason: OutreachFlowEndReason | null;
  daysOnFlow: number | null;
}

export interface FollowUpTable_balancesConnection_edges_node_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface FollowUpTable_balancesConnection_edges_node_guarantor_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface FollowUpTable_balancesConnection_edges_node_guarantor_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface FollowUpTable_balancesConnection_edges_node_guarantor_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface FollowUpTable_balancesConnection_edges_node_guarantor_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface FollowUpTable_balancesConnection_edges_node_guarantor {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  touchpoints: FollowUpTable_balancesConnection_edges_node_guarantor_touchpoints | null;
  lastCompletedFlow: FollowUpTable_balancesConnection_edges_node_guarantor_lastCompletedFlow | null;
  email: string | null;
  phone: string | null;
  name: FollowUpTable_balancesConnection_edges_node_guarantor_name;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: FollowUpTable_balancesConnection_edges_node_guarantor_dob | null;
  latestEmailNotification: FollowUpTable_balancesConnection_edges_node_guarantor_latestEmailNotification | null;
  latestSMSNotification: FollowUpTable_balancesConnection_edges_node_guarantor_latestSMSNotification | null;
  latestLetterNotification: FollowUpTable_balancesConnection_edges_node_guarantor_latestLetterNotification | null;
}

export interface FollowUpTable_balancesConnection_edges_node {
  __typename: "Balance";
  id: string;
  patientPortion: number;
  insurancePortion: number;
  daysPastDue: number | null;
  dpdBucket: DPDBucket;
  guarantor: FollowUpTable_balancesConnection_edges_node_guarantor;
}

export interface FollowUpTable_balancesConnection_edges {
  __typename: "BalanceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: FollowUpTable_balancesConnection_edges_node;
}

export interface FollowUpTable_balancesConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface FollowUpTable_balancesConnection {
  __typename: "BalanceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: FollowUpTable_balancesConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: FollowUpTable_balancesConnection_pageInfo;
}

export interface FollowUpTable {
  balancesConnection: FollowUpTable_balancesConnection;
}

export interface FollowUpTableVariables {
  search?: string | null;
  contactIds?: string[] | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Global
// ====================================================

export interface Global_meUser_google {
  __typename: "GoogleUserFields";
  currentGroupId: string | null;
  email: string;
  anonymize: boolean;
}

export interface Global_meUser_groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface Global_meUser_accounts {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
}

export interface Global_meUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: Global_meUser_google;
  groups: Global_meUser_groups[];
  accounts: Global_meUser_accounts[];
}

export interface Global_account_stripe {
  __typename: "StripeAccountFields";
  id: string;
  acceptPayments: boolean;
}

export interface Global_account_latestGeneralReport {
  __typename: "Report";
  id: string;
  type: ReportType;
  path: string;
}

export interface Global_account_installmentTemplates {
  __typename: "InstallmentTemplate";
  interval: number;
  intervalType: InstallmentIntervalType;
  periods: number;
  adminFee: number;
  downPayment: number;
}

export interface Global_account_returnAddress_verifiedAddress {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
}

export interface Global_account_returnAddress {
  __typename: "Address";
  id: string;
  verifiedAddress: Global_account_returnAddress_verifiedAddress | null;
}

export interface Global_account {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
  phone: string;
  publicReplyPhone: string;
  programName: string;
  capabilities: AccountCapability[];
  hasAutoBlockRules: boolean;
  letterFilterId: string;
  stripe: Global_account_stripe | null;
  latestGeneralReport: Global_account_latestGeneralReport | null;
  logoUrl: string | null;
  siteUrl: string | null;
  installmentTemplates: Global_account_installmentTemplates[];
  returnAddress: Global_account_returnAddress;
}

export interface Global {
  meUser: Global_meUser;
  account: Global_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupAR
// ====================================================

export interface GroupAR_group_accounts_analytics_outreach_balances_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupAR_group_accounts_analytics_outreach_balances_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupAR_group_accounts_analytics_outreach_balances {
  __typename: "BucketSummary";
  sum: GroupAR_group_accounts_analytics_outreach_balances_sum;
  count: GroupAR_group_accounts_analytics_outreach_balances_count;
}

export interface GroupAR_group_accounts_analytics_outreach_engaged_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupAR_group_accounts_analytics_outreach_engaged_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupAR_group_accounts_analytics_outreach_engaged {
  __typename: "BucketSummary";
  sum: GroupAR_group_accounts_analytics_outreach_engaged_sum;
  count: GroupAR_group_accounts_analytics_outreach_engaged_count;
}

export interface GroupAR_group_accounts_analytics_outreach {
  __typename: "OutreachSummary";
  balances: GroupAR_group_accounts_analytics_outreach_balances;
  engaged: GroupAR_group_accounts_analytics_outreach_engaged;
}

export interface GroupAR_group_accounts_analytics {
  __typename: "AccountAnalytics";
  id: string;
  activeFlowCount: number;
  outreach: GroupAR_group_accounts_analytics_outreach;
}

export interface GroupAR_group_accounts {
  __typename: "Account";
  id: string;
  name: string;
  analytics: GroupAR_group_accounts_analytics;
}

export interface GroupAR_group {
  __typename: "Group";
  id: string;
  accounts: GroupAR_group_accounts[];
}

export interface GroupAR {
  group: GroupAR_group;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupArNoClaims
// ====================================================

export interface GroupArNoClaims_group_accounts_analytics_outreach_noClaims_balances_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupArNoClaims_group_accounts_analytics_outreach_noClaims_balances_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupArNoClaims_group_accounts_analytics_outreach_noClaims_balances {
  __typename: "BucketSummary";
  sum: GroupArNoClaims_group_accounts_analytics_outreach_noClaims_balances_sum;
  count: GroupArNoClaims_group_accounts_analytics_outreach_noClaims_balances_count;
}

export interface GroupArNoClaims_group_accounts_analytics_outreach_noClaims {
  __typename: "OutreachSummary";
  balances: GroupArNoClaims_group_accounts_analytics_outreach_noClaims_balances;
}

export interface GroupArNoClaims_group_accounts_analytics {
  __typename: "AccountAnalytics";
  id: string;
  outreach_noClaims: GroupArNoClaims_group_accounts_analytics_outreach_noClaims;
}

export interface GroupArNoClaims_group_accounts {
  __typename: "Account";
  id: string;
  analytics: GroupArNoClaims_group_accounts_analytics;
}

export interface GroupArNoClaims_group {
  __typename: "Group";
  id: string;
  accounts: GroupArNoClaims_group_accounts[];
}

export interface GroupArNoClaims {
  group: GroupArNoClaims_group;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupArStatuses
// ====================================================

export interface GroupArStatuses_group_accounts_analytics_balanceStatus_scheduled_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupArStatuses_group_accounts_analytics_balanceStatus_scheduled_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupArStatuses_group_accounts_analytics_balanceStatus_scheduled {
  __typename: "BucketSummary";
  sum: GroupArStatuses_group_accounts_analytics_balanceStatus_scheduled_sum;
  count: GroupArStatuses_group_accounts_analytics_balanceStatus_scheduled_count;
}

export interface GroupArStatuses_group_accounts_analytics_balanceStatus_blocked_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupArStatuses_group_accounts_analytics_balanceStatus_blocked_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupArStatuses_group_accounts_analytics_balanceStatus_blocked {
  __typename: "BucketSummary";
  sum: GroupArStatuses_group_accounts_analytics_balanceStatus_blocked_sum;
  count: GroupArStatuses_group_accounts_analytics_balanceStatus_blocked_count;
}

export interface GroupArStatuses_group_accounts_analytics_balanceStatus {
  __typename: "BalanceStatusSummary";
  scheduled: GroupArStatuses_group_accounts_analytics_balanceStatus_scheduled;
  blocked: GroupArStatuses_group_accounts_analytics_balanceStatus_blocked;
}

export interface GroupArStatuses_group_accounts_analytics {
  __typename: "AccountAnalytics";
  id: string;
  balanceStatus: GroupArStatuses_group_accounts_analytics_balanceStatus;
}

export interface GroupArStatuses_group_accounts {
  __typename: "Account";
  id: string;
  analytics: GroupArStatuses_group_accounts_analytics;
}

export interface GroupArStatuses_group {
  __typename: "Group";
  id: string;
  accounts: GroupArStatuses_group_accounts[];
}

export interface GroupArStatuses {
  group: GroupArStatuses_group;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupMembership
// ====================================================

export interface GroupMembership_group_accounts {
  __typename: "Account";
  id: string;
  name: string;
}

export interface GroupMembership_group_analytics_membershipPerformance_fees {
  __typename: "TimeSeries";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  last30: number;
}

export interface GroupMembership_group_analytics_membershipPerformance {
  __typename: "MembershipPerformance";
  accountId: string;
  memberCount: number;
  memberCountLast30: number;
  fees: GroupMembership_group_analytics_membershipPerformance_fees;
}

export interface GroupMembership_group_analytics {
  __typename: "GroupAnalytics";
  id: string;
  membershipPerformance: GroupMembership_group_analytics_membershipPerformance[];
}

export interface GroupMembership_group {
  __typename: "Group";
  id: string;
  accounts: GroupMembership_group_accounts[];
  analytics: GroupMembership_group_analytics;
}

export interface GroupMembership {
  group: GroupMembership_group;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupPayments
// ====================================================

export interface GroupPayments_group_accounts {
  __typename: "Account";
  id: string;
  name: string;
}

export interface GroupPayments_group_analytics_paymentsPerformance_collected {
  __typename: "TimeSeries";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  last30: number;
}

export interface GroupPayments_group_analytics_paymentsPerformance_processed {
  __typename: "TimeSeries";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  last30: number;
}

export interface GroupPayments_group_analytics_paymentsPerformance {
  __typename: "PaymentsPerformance";
  accountId: string;
  collected: GroupPayments_group_analytics_paymentsPerformance_collected;
  processed: GroupPayments_group_analytics_paymentsPerformance_processed;
}

export interface GroupPayments_group_analytics {
  __typename: "GroupAnalytics";
  id: string;
  paymentsPerformance: GroupPayments_group_analytics_paymentsPerformance[];
}

export interface GroupPayments_group {
  __typename: "Group";
  id: string;
  accounts: GroupPayments_group_accounts[];
  analytics: GroupPayments_group_analytics;
}

export interface GroupPayments {
  group: GroupPayments_group;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupReporting
// ====================================================

export interface GroupReporting_group_accounts {
  __typename: "Account";
  id: string;
  name: string;
}

export interface GroupReporting_group_latestReport {
  __typename: "Report";
  id: string;
  path: string;
}

export interface GroupReporting_group {
  __typename: "Group";
  id: string;
  accounts: GroupReporting_group_accounts[];
  latestReport: GroupReporting_group_latestReport | null;
  dailyEmailRecipients: string[];
}

export interface GroupReporting {
  group: GroupReporting_group;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGroupReporting
// ====================================================

export interface UpdateGroupReporting_updateCurrentGroup {
  __typename: "Group";
  id: string;
  dailyEmailRecipients: string[];
}

export interface UpdateGroupReporting {
  updateCurrentGroup: UpdateGroupReporting_updateCurrentGroup;
}

export interface UpdateGroupReportingVariables {
  dailyEmailRecipients: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupUsers
// ====================================================

export interface GroupUsers_group_accounts {
  __typename: "Account";
  id: string;
  name: string;
}

export interface GroupUsers_group_users_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface GroupUsers_group_users {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: GroupUsers_group_users_google;
  notify_enrolled: boolean;
  notify_paymentCompleted: boolean;
  notify_invoiceFailed: boolean;
  notify_eodPracticeReport: boolean;
}

export interface GroupUsers_group_accountUsers_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface GroupUsers_group_accountUsers_accounts {
  __typename: "Account";
  id: string;
  name: string;
}

export interface GroupUsers_group_accountUsers {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: GroupUsers_group_accountUsers_google;
  notify_enrolled: boolean;
  notify_paymentCompleted: boolean;
  notify_invoiceFailed: boolean;
  notify_eodPracticeReport: boolean;
  accounts: GroupUsers_group_accountUsers_accounts[];
}

export interface GroupUsers_group {
  __typename: "Group";
  id: string;
  accounts: GroupUsers_group_accounts[];
  users: GroupUsers_group_users[];
  accountUsers: GroupUsers_group_accountUsers[];
}

export interface GroupUsers {
  group: GroupUsers_group;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupHome
// ====================================================

export interface GroupHome_group_analytics_aggregate_collected {
  __typename: "TimeSeries";
  last30: number;
  month6: number;
}

export interface GroupHome_group_analytics_aggregate_balanceEquation {
  __typename: "BalanceEquation";
  insurance: any;
  patient: any;
}

export interface GroupHome_group_analytics_aggregate_outreach_engaged_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupHome_group_analytics_aggregate_outreach_engaged_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupHome_group_analytics_aggregate_outreach_engaged {
  __typename: "BucketSummary";
  sum: GroupHome_group_analytics_aggregate_outreach_engaged_sum;
  count: GroupHome_group_analytics_aggregate_outreach_engaged_count;
}

export interface GroupHome_group_analytics_aggregate_outreach_balances_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupHome_group_analytics_aggregate_outreach_balances_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupHome_group_analytics_aggregate_outreach_balances {
  __typename: "BucketSummary";
  sum: GroupHome_group_analytics_aggregate_outreach_balances_sum;
  count: GroupHome_group_analytics_aggregate_outreach_balances_count;
}

export interface GroupHome_group_analytics_aggregate_outreach {
  __typename: "OutreachSummary";
  engaged: GroupHome_group_analytics_aggregate_outreach_engaged;
  balances: GroupHome_group_analytics_aggregate_outreach_balances;
}

export interface GroupHome_group_analytics_aggregate {
  __typename: "GroupAnalyticsAggregate";
  id: string;
  collected: GroupHome_group_analytics_aggregate_collected;
  notifications_last30: number;
  balanceEquation: GroupHome_group_analytics_aggregate_balanceEquation;
  outreach: GroupHome_group_analytics_aggregate_outreach;
}

export interface GroupHome_group_analytics {
  __typename: "GroupAnalytics";
  aggregate: GroupHome_group_analytics_aggregate;
}

export interface GroupHome_group_accounts_latestSync {
  __typename: "SyncEvent";
  date: number;
}

export interface GroupHome_group_accounts_coordinates {
  __typename: "Coordinates";
  latitude: number;
  longitude: number;
}

export interface GroupHome_group_accounts_analytics {
  __typename: "AccountAnalytics";
  activeFlowCount: number;
}

export interface GroupHome_group_accounts {
  __typename: "Account";
  id: string;
  name: string;
  phone: string;
  latestSync: GroupHome_group_accounts_latestSync | null;
  coordinates: GroupHome_group_accounts_coordinates;
  analytics: GroupHome_group_accounts_analytics;
}

export interface GroupHome_group {
  __typename: "Group";
  id: string;
  name: string;
  analytics: GroupHome_group_analytics;
  accounts: GroupHome_group_accounts[];
}

export interface GroupHome {
  group: GroupHome_group;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupHomeNoClaimsBalances
// ====================================================

export interface GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_engaged_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_engaged_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_engaged {
  __typename: "BucketSummary";
  sum: GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_engaged_sum;
  count: GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_engaged_count;
}

export interface GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_balances_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_balances_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_balances {
  __typename: "BucketSummary";
  sum: GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_balances_sum;
  count: GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_balances_count;
}

export interface GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach {
  __typename: "OutreachSummary";
  engaged: GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_engaged;
  balances: GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach_balances;
}

export interface GroupHomeNoClaimsBalances_group_analytics_aggregate {
  __typename: "GroupAnalyticsAggregate";
  id: string;
  outreach: GroupHomeNoClaimsBalances_group_analytics_aggregate_outreach;
}

export interface GroupHomeNoClaimsBalances_group_analytics {
  __typename: "GroupAnalytics";
  aggregate: GroupHomeNoClaimsBalances_group_analytics_aggregate;
}

export interface GroupHomeNoClaimsBalances_group {
  __typename: "Group";
  id: string;
  name: string;
  analytics: GroupHomeNoClaimsBalances_group_analytics;
}

export interface GroupHomeNoClaimsBalances {
  group: GroupHomeNoClaimsBalances_group;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupHomeAccount
// ====================================================

export interface GroupHomeAccount_accountById_returnAddress {
  __typename: "Address";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface GroupHomeAccount_accountById_analytics_balanceEquation {
  __typename: "BalanceEquation";
  insurance: any;
  patient: any;
}

export interface GroupHomeAccount_accountById_analytics_outreach_engaged_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupHomeAccount_accountById_analytics_outreach_engaged_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupHomeAccount_accountById_analytics_outreach_engaged {
  __typename: "BucketSummary";
  sum: GroupHomeAccount_accountById_analytics_outreach_engaged_sum;
  count: GroupHomeAccount_accountById_analytics_outreach_engaged_count;
}

export interface GroupHomeAccount_accountById_analytics_outreach_balances_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupHomeAccount_accountById_analytics_outreach_balances_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupHomeAccount_accountById_analytics_outreach_balances {
  __typename: "BucketSummary";
  sum: GroupHomeAccount_accountById_analytics_outreach_balances_sum;
  count: GroupHomeAccount_accountById_analytics_outreach_balances_count;
}

export interface GroupHomeAccount_accountById_analytics_outreach {
  __typename: "OutreachSummary";
  engaged: GroupHomeAccount_accountById_analytics_outreach_engaged;
  balances: GroupHomeAccount_accountById_analytics_outreach_balances;
}

export interface GroupHomeAccount_accountById_analytics_outreach_noClaims_balances_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface GroupHomeAccount_accountById_analytics_outreach_noClaims_balances_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface GroupHomeAccount_accountById_analytics_outreach_noClaims_balances {
  __typename: "BucketSummary";
  sum: GroupHomeAccount_accountById_analytics_outreach_noClaims_balances_sum;
  count: GroupHomeAccount_accountById_analytics_outreach_noClaims_balances_count;
}

export interface GroupHomeAccount_accountById_analytics_outreach_noClaims {
  __typename: "OutreachSummary";
  balances: GroupHomeAccount_accountById_analytics_outreach_noClaims_balances;
}

export interface GroupHomeAccount_accountById_analytics {
  __typename: "AccountAnalytics";
  activeFlowCount: number;
  balanceEquation: GroupHomeAccount_accountById_analytics_balanceEquation;
  outreach: GroupHomeAccount_accountById_analytics_outreach;
  outreach_noClaims: GroupHomeAccount_accountById_analytics_outreach_noClaims;
}

export interface GroupHomeAccount_accountById {
  __typename: "Account";
  id: string;
  name: string;
  logoUrl: string | null;
  returnAddress: GroupHomeAccount_accountById_returnAddress;
  phone: string;
  analytics: GroupHomeAccount_accountById_analytics;
}

export interface GroupHomeAccount {
  accountById: GroupHomeAccount_accountById;
}

export interface GroupHomeAccountVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuarantorActionItems
// ====================================================

export interface GuarantorActionItems_contact_account {
  __typename: "Account";
  sunbitConfigurationId: string | null;
}

export interface GuarantorActionItems_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface GuarantorActionItems_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface GuarantorActionItems_contact_balance_metrics {
  __typename: "Balance";
  patientPortion: number;
}

export interface GuarantorActionItems_contact_balance_engagementDetails {
  __typename: "BalanceEngagementDetails";
  status: BalanceEngagementStatus;
  blockedReasons: BalanceBlockedReason[];
}

export interface GuarantorActionItems_contact_balance {
  __typename: "ContactBalance";
  metrics: GuarantorActionItems_contact_balance_metrics;
  engagementDetails: GuarantorActionItems_contact_balance_engagementDetails | null;
}

export interface GuarantorActionItems_contact_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
}

export interface GuarantorActionItems_contact_stripe {
  __typename: "ContactStripeFields";
  customerId: string | null;
  subscriptionId: string | null;
  defaultSource: GuarantorActionItems_contact_stripe_defaultSource | null;
}

export interface GuarantorActionItems_contact {
  __typename: "Contact";
  id: string;
  account: GuarantorActionItems_contact_account;
  name: GuarantorActionItems_contact_name;
  code: string;
  dob: GuarantorActionItems_contact_dob | null;
  email: string | null;
  phone: string | null;
  balance: GuarantorActionItems_contact_balance;
  stripe: GuarantorActionItems_contact_stripe;
}

export interface GuarantorActionItems {
  contact: GuarantorActionItems_contact;
}

export interface GuarantorActionItemsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EndGuarantorFlow
// ====================================================

export interface EndGuarantorFlow_endFlow {
  __typename: "Contact";
  id: string;
}

export interface EndGuarantorFlow {
  endFlow: EndGuarantorFlow_endFlow;
}

export interface EndGuarantorFlowVariables {
  guarantorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuarantorExisting
// ====================================================

export interface GuarantorExisting_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface GuarantorExisting_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface GuarantorExisting_contact_stripe_defaultSource {
  __typename: "StripeDefaultSource";
  isCredit: boolean;
  last4: string;
}

export interface GuarantorExisting_contact_stripe {
  __typename: "ContactStripeFields";
  defaultSource: GuarantorExisting_contact_stripe_defaultSource | null;
}

export interface GuarantorExisting_contact {
  __typename: "Contact";
  id: string;
  name: GuarantorExisting_contact_name;
  dob: GuarantorExisting_contact_dob | null;
  code: string;
  email: string | null;
  phone: string | null;
  stripe: GuarantorExisting_contact_stripe;
}

export interface GuarantorExisting {
  contact: GuarantorExisting_contact;
}

export interface GuarantorExistingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuarantorOverview
// ====================================================

export interface GuarantorOverview_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface GuarantorOverview_contact_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface GuarantorOverview_contact_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface GuarantorOverview_contact_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface GuarantorOverview_contact_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface GuarantorOverview_contact_balance_engagementDetails {
  __typename: "BalanceEngagementDetails";
  status: BalanceEngagementStatus;
}

export interface GuarantorOverview_contact_balance_metrics {
  __typename: "Balance";
  patientPortion: number;
}

export interface GuarantorOverview_contact_balance {
  __typename: "ContactBalance";
  engagementDetails: GuarantorOverview_contact_balance_engagementDetails | null;
  metrics: GuarantorOverview_contact_balance_metrics;
}

export interface GuarantorOverview_contact_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  status: SubscriptionStatus;
}

export interface GuarantorOverview_contact_stripe {
  __typename: "ContactStripeFields";
  customerId: string | null;
  membership: GuarantorOverview_contact_stripe_membership | null;
}

export interface GuarantorOverview_contact_dependents {
  __typename: "Contact";
  id: string;
}

export interface GuarantorOverview_contact {
  __typename: "Contact";
  id: string;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  email: string | null;
  phone: string | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: GuarantorOverview_contact_dob | null;
  latestEmailNotification: GuarantorOverview_contact_latestEmailNotification | null;
  latestSMSNotification: GuarantorOverview_contact_latestSMSNotification | null;
  latestLetterNotification: GuarantorOverview_contact_latestLetterNotification | null;
  address: GuarantorOverview_contact_address | null;
  balance: GuarantorOverview_contact_balance;
  stripe: GuarantorOverview_contact_stripe;
  dependents: GuarantorOverview_contact_dependents[];
}

export interface GuarantorOverview {
  contact: GuarantorOverview_contact;
}

export interface GuarantorOverviewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuarantorBilling
// ====================================================

export interface GuarantorBilling_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface GuarantorBilling_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface GuarantorBilling_contact {
  __typename: "Contact";
  id: string;
  code: string;
  name: GuarantorBilling_contact_name;
  dob: GuarantorBilling_contact_dob | null;
  suppressed: boolean;
  allowed: boolean;
  balanceNote: string | null;
}

export interface GuarantorBilling {
  contact: GuarantorBilling_contact;
}

export interface GuarantorBillingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetGuarantorBalanceNote
// ====================================================

export interface SetGuarantorBalanceNote_setContactbalanceNote {
  __typename: "Contact";
  id: string;
  balanceNote: string | null;
}

export interface SetGuarantorBalanceNote {
  setContactbalanceNote: SetGuarantorBalanceNote_setContactbalanceNote;
}

export interface SetGuarantorBalanceNoteVariables {
  contactId: string;
  balanceNote?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuarantorBillingVisits
// ====================================================

export interface GuarantorBillingVisits_contact_balance_activity_patient_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface GuarantorBillingVisits_contact_balance_activity_patient_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface GuarantorBillingVisits_contact_balance_activity_patient {
  __typename: "Contact";
  name: GuarantorBillingVisits_contact_balance_activity_patient_name;
  dob: GuarantorBillingVisits_contact_balance_activity_patient_dob | null;
}

export interface GuarantorBillingVisits_contact_balance_activity_procedures {
  __typename: "ContactBalanceActivityTransaction";
  id: string;
  amount: number;
  procedureCode: string | null;
  description: string;
  pms_claimId: string | null;
}

export interface GuarantorBillingVisits_contact_balance_activity {
  __typename: "ContactBalanceActivity";
  date: number;
  patient: GuarantorBillingVisits_contact_balance_activity_patient;
  netAmount: number;
  patientPaymentsSum: number;
  insurancePaymentsSum: number;
  adjustmentsSum: number;
  proceduresSum: number;
  isPendingPrimary: boolean;
  isPendingSecondary: boolean;
  isDenied: boolean;
  uploadPaths: string[];
  procedures: GuarantorBillingVisits_contact_balance_activity_procedures[];
}

export interface GuarantorBillingVisits_contact_balance {
  __typename: "ContactBalance";
  activity: GuarantorBillingVisits_contact_balance_activity[];
}

export interface GuarantorBillingVisits_contact {
  __typename: "Contact";
  id: string;
  balance: GuarantorBillingVisits_contact_balance;
}

export interface GuarantorBillingVisits {
  contact: GuarantorBillingVisits_contact;
}

export interface GuarantorBillingVisitsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuarantorBillingOutreach
// ====================================================

export interface GuarantorBillingOutreach_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface GuarantorBillingOutreach_contact_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface GuarantorBillingOutreach_contact_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface GuarantorBillingOutreach_contact_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface GuarantorBillingOutreach_contact_balance_engagementDetails {
  __typename: "BalanceEngagementDetails";
  status: BalanceEngagementStatus;
  blockedReasons: BalanceBlockedReason[];
}

export interface GuarantorBillingOutreach_contact_balance {
  __typename: "ContactBalance";
  engagementDetails: GuarantorBillingOutreach_contact_balance_engagementDetails | null;
}

export interface GuarantorBillingOutreach_contact_outreach_timeline_flow_items {
  __typename: "OutreachTimelineAction";
  id: string;
  date: number;
  initiatedDate: number | null;
  type: OutreachTimelineActionType;
  templateName: string | null;
  url: string | null;
}

export interface GuarantorBillingOutreach_contact_outreach_timeline_flow {
  __typename: "OutreachTimelineFlow";
  id: string;
  type: OutreachFlowType;
  startDate: number;
  endDate: number | null;
  isManualEnd: boolean | null;
  endReason: OutreachFlowEndReason | null;
  isPrompted: boolean;
  items: GuarantorBillingOutreach_contact_outreach_timeline_flow_items[];
}

export interface GuarantorBillingOutreach_contact_outreach_timeline_singleAction {
  __typename: "OutreachTimelineAction";
  id: string;
  date: number;
  initiatedDate: number | null;
  type: OutreachTimelineActionType;
  templateName: string | null;
  url: string | null;
}

export interface GuarantorBillingOutreach_contact_outreach_timeline {
  __typename: "OutreachTimelineItem";
  id: string;
  type: OutreachTimelineItemType;
  flow: GuarantorBillingOutreach_contact_outreach_timeline_flow | null;
  singleAction: GuarantorBillingOutreach_contact_outreach_timeline_singleAction | null;
}

export interface GuarantorBillingOutreach_contact_outreach {
  __typename: "ContactOutreach";
  timeline: GuarantorBillingOutreach_contact_outreach_timeline[];
}

export interface GuarantorBillingOutreach_contact {
  __typename: "Contact";
  id: string;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  email: string | null;
  phone: string | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: GuarantorBillingOutreach_contact_dob | null;
  latestEmailNotification: GuarantorBillingOutreach_contact_latestEmailNotification | null;
  latestSMSNotification: GuarantorBillingOutreach_contact_latestSMSNotification | null;
  latestLetterNotification: GuarantorBillingOutreach_contact_latestLetterNotification | null;
  balance: GuarantorBillingOutreach_contact_balance;
  outreach: GuarantorBillingOutreach_contact_outreach;
}

export interface GuarantorBillingOutreach {
  contact: GuarantorBillingOutreach_contact;
}

export interface GuarantorBillingOutreachVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetGuarantorOutreachOptions
// ====================================================

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions_balance_engagementDetails {
  __typename: "BalanceEngagementDetails";
  status: BalanceEngagementStatus;
  blockedReasons: BalanceBlockedReason[];
}

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions_balance {
  __typename: "ContactBalance";
  engagementDetails: SetGuarantorOutreachOptions_setGuarantorOutreachOptions_balance_engagementDetails | null;
}

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_timeline_flow_items {
  __typename: "OutreachTimelineAction";
  id: string;
  date: number;
  initiatedDate: number | null;
  type: OutreachTimelineActionType;
  templateName: string | null;
  url: string | null;
}

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_timeline_flow {
  __typename: "OutreachTimelineFlow";
  id: string;
  type: OutreachFlowType;
  startDate: number;
  endDate: number | null;
  isManualEnd: boolean | null;
  endReason: OutreachFlowEndReason | null;
  isPrompted: boolean;
  items: SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_timeline_flow_items[];
}

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_timeline_singleAction {
  __typename: "OutreachTimelineAction";
  id: string;
  date: number;
  initiatedDate: number | null;
  type: OutreachTimelineActionType;
  templateName: string | null;
  url: string | null;
}

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_timeline {
  __typename: "OutreachTimelineItem";
  id: string;
  type: OutreachTimelineItemType;
  flow: SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_timeline_flow | null;
  singleAction: SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_timeline_singleAction | null;
}

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_latestSMSNotification {
  __typename: "Notification";
  channel: NotificationChannel;
  failureReason: NotificationFailureReason | null;
}

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_latestEmailNotification {
  __typename: "Notification";
  channel: NotificationChannel;
  failureReason: NotificationFailureReason | null;
}

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach {
  __typename: "ContactOutreach";
  timeline: SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_timeline[];
  latestSMSNotification: SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_latestSMSNotification | null;
  latestEmailNotification: SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach_latestEmailNotification | null;
}

export interface SetGuarantorOutreachOptions_setGuarantorOutreachOptions {
  __typename: "Contact";
  id: string;
  suppressed: boolean;
  allowed: boolean;
  balance: SetGuarantorOutreachOptions_setGuarantorOutreachOptions_balance;
  outreach: SetGuarantorOutreachOptions_setGuarantorOutreachOptions_outreach;
}

export interface SetGuarantorOutreachOptions {
  setGuarantorOutreachOptions: SetGuarantorOutreachOptions_setGuarantorOutreachOptions;
}

export interface SetGuarantorOutreachOptionsVariables {
  contactId: string;
  suppressed: boolean;
  allowed: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuarantorBillingProcessed
// ====================================================

export interface GuarantorBillingProcessed_contact_upcomingInvoices {
  __typename: "Invoice";
  id: string;
  stripeId_subscription: string;
  amount: number;
  isMembership: boolean;
  date: number;
  status: InvoiceStatus;
}

export interface GuarantorBillingProcessed_contact_invoices {
  __typename: "Invoice";
  id: string;
  stripeId_invoice: string;
  status: InvoiceStatus;
  amount: number;
  isMembership: boolean;
  date: number;
}

export interface GuarantorBillingProcessed_contact_charges_transaction {
  __typename: "Transaction";
  id: string;
  type: TransactionType;
  stripeId_subscription: string | null;
  installmentPlanId: string | null;
}

export interface GuarantorBillingProcessed_contact_charges {
  __typename: "Charge";
  id: string;
  grossAmount: number;
  balanceAmount: number;
  date: number;
  transaction: GuarantorBillingProcessed_contact_charges_transaction;
  refundedAmount: number;
  disputedAmount: number;
}

export interface GuarantorBillingProcessed_contact_nextScheduledPayments_installmentPlan_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface GuarantorBillingProcessed_contact_nextScheduledPayments_installmentPlan_guarantor {
  __typename: "Contact";
  id: string;
  code: string;
  name: GuarantorBillingProcessed_contact_nextScheduledPayments_installmentPlan_guarantor_name;
}

export interface GuarantorBillingProcessed_contact_nextScheduledPayments_installmentPlan {
  __typename: "InstallmentPlan";
  id: string;
  guarantor: GuarantorBillingProcessed_contact_nextScheduledPayments_installmentPlan_guarantor;
}

export interface GuarantorBillingProcessed_contact_nextScheduledPayments {
  __typename: "ScheduledPayment";
  id: string;
  amount: number;
  date: string;
  status: ScheduledPaymentStatus;
  installmentPlan: GuarantorBillingProcessed_contact_nextScheduledPayments_installmentPlan | null;
}

export interface GuarantorBillingProcessed_contact {
  __typename: "Contact";
  id: string;
  upcomingInvoices: GuarantorBillingProcessed_contact_upcomingInvoices[];
  invoices: GuarantorBillingProcessed_contact_invoices[];
  charges: GuarantorBillingProcessed_contact_charges[];
  nextScheduledPayments: GuarantorBillingProcessed_contact_nextScheduledPayments[];
}

export interface GuarantorBillingProcessed {
  contact: GuarantorBillingProcessed_contact;
}

export interface GuarantorBillingProcessedVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuarantorPlans
// ====================================================

export interface GuarantorPlans_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface GuarantorPlans_contact_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface GuarantorPlans_contact_balance_metrics {
  __typename: "Balance";
  patientPortion: number;
}

export interface GuarantorPlans_contact_balance_engagementDetails {
  __typename: "BalanceEngagementDetails";
  id: string;
  status: BalanceEngagementStatus;
  isLastStartManual: boolean | null;
}

export interface GuarantorPlans_contact_balance {
  __typename: "ContactBalance";
  metrics: GuarantorPlans_contact_balance_metrics;
  engagementDetails: GuarantorPlans_contact_balance_engagementDetails | null;
}

export interface GuarantorPlans_contact_plan {
  __typename: "Plan";
  id: string;
  name: string;
  status: PlanStatus;
}

export interface GuarantorPlans_contact_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  effectiveDate: number;
  status: SubscriptionStatus;
  /**
   * Is subscription renewal off?
   */
  autoRenew: boolean;
  price: number;
  interval: string;
}

export interface GuarantorPlans_contact_stripe {
  __typename: "ContactStripeFields";
  latestOpenInvoice: number | null;
  membership: GuarantorPlans_contact_stripe_membership | null;
}

export interface GuarantorPlans_contact_dependents {
  __typename: "Contact";
  id: string;
}

export interface GuarantorPlans_contact_installmentPlans_scheduledPayments {
  __typename: "ScheduledPayment";
  id: string;
  amount: number;
  date: string;
  status: ScheduledPaymentStatus;
}

export interface GuarantorPlans_contact_installmentPlans {
  __typename: "InstallmentPlan";
  id: string;
  status: InstallmentPlanStatus;
  startDate: string;
  faceAmount: number;
  adminFee: number;
  downPayment: number;
  periods: number;
  interval: number;
  intervalType: InstallmentIntervalType;
  guarantorId: string;
  adminFeeAmount: number;
  scheduledPayments: GuarantorPlans_contact_installmentPlans_scheduledPayments[];
}

export interface GuarantorPlans_contact {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  perio: boolean;
  name: GuarantorPlans_contact_name;
  dob: GuarantorPlans_contact_dob | null;
  balance: GuarantorPlans_contact_balance;
  isGuarantor: boolean;
  familyMemberCount: number;
  planTerm: PlanTerm | null;
  planRenewalDate: number | null;
  plan: GuarantorPlans_contact_plan | null;
  stripe: GuarantorPlans_contact_stripe;
  dependents: GuarantorPlans_contact_dependents[];
  installmentPlans: GuarantorPlans_contact_installmentPlans[];
}

export interface GuarantorPlans {
  contact: GuarantorPlans_contact;
}

export interface GuarantorPlansVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuarantorDependents
// ====================================================

export interface GuarantorDependents_contact_dependents_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface GuarantorDependents_contact_dependents {
  __typename: "Contact";
  id: string;
  name: GuarantorDependents_contact_dependents_name;
}

export interface GuarantorDependents_contact {
  __typename: "Contact";
  id: string;
  dependents: GuarantorDependents_contact_dependents[];
}

export interface GuarantorDependents {
  contact: GuarantorDependents_contact;
}

export interface GuarantorDependentsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartGuarantorFlow
// ====================================================

export interface StartGuarantorFlow_startFlow_latestEmailNotification {
  __typename: "Notification";
  channel: NotificationChannel;
}

export interface StartGuarantorFlow_startFlow {
  __typename: "Contact";
  id: string;
  suppressed: boolean;
  latestEmailNotification: StartGuarantorFlow_startFlow_latestEmailNotification | null;
}

export interface StartGuarantorFlow {
  startFlow: StartGuarantorFlow_startFlow;
}

export interface StartGuarantorFlowVariables {
  guarantorId: string;
  balanceNote?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCurrentAccountId
// ====================================================

export interface SetCurrentAccountId {
  setCurrentAccountId: string;
}

export interface SetCurrentAccountIdVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCurrentGroupId
// ====================================================

export interface SetCurrentGroupId {
  setCurrentGroupId: string;
}

export interface SetCurrentGroupIdVariables {
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetAnonymize
// ====================================================

export interface SetAnonymize {
  setAnonymize: boolean;
}

export interface SetAnonymizeVariables {
  anonymize: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HomeDashboard
// ====================================================

export interface HomeDashboard_analytics_paymentTransactions_ar_collected {
  __typename: "TimeSeries";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  last30: number;
}

export interface HomeDashboard_analytics_paymentTransactions_ar_processed {
  __typename: "TimeSeries";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  last30: number;
  last30Count: number;
}

export interface HomeDashboard_analytics_paymentTransactions_membership {
  __typename: "TimeSeries";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  last30: number;
  last30Count: number;
}

export interface HomeDashboard_analytics_paymentTransactions {
  __typename: "AnalyticsPaymentTransactions";
  ar_collected: HomeDashboard_analytics_paymentTransactions_ar_collected;
  ar_processed: HomeDashboard_analytics_paymentTransactions_ar_processed;
  membership: HomeDashboard_analytics_paymentTransactions_membership;
}

export interface HomeDashboard_analytics_ar {
  __typename: "AnalyticsAR";
  last30PatientsPromptedCount: number;
}

export interface HomeDashboard_analytics {
  __typename: "Analytics";
  paymentTransactions: HomeDashboard_analytics_paymentTransactions;
  ar: HomeDashboard_analytics_ar;
}

export interface HomeDashboard_balanceMetrics_engagement_outstanding_all_total {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
}

export interface HomeDashboard_balanceMetrics_engagement_outstanding_all {
  __typename: "BalanceStratification";
  total: HomeDashboard_balanceMetrics_engagement_outstanding_all_total;
}

export interface HomeDashboard_balanceMetrics_engagement_outstanding {
  __typename: "BalanceEngagementOutstanding";
  all: HomeDashboard_balanceMetrics_engagement_outstanding_all;
}

export interface HomeDashboard_balanceMetrics_engagement {
  __typename: "BalanceEngagement";
  outstanding: HomeDashboard_balanceMetrics_engagement_outstanding;
}

export interface HomeDashboard_balanceMetrics {
  __typename: "Balances";
  engagement: HomeDashboard_balanceMetrics_engagement;
}

export interface HomeDashboard_balancesConnection_edges_node_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface HomeDashboard_balancesConnection_edges_node_guarantor {
  __typename: "Contact";
  id: string;
  name: HomeDashboard_balancesConnection_edges_node_guarantor_name;
}

export interface HomeDashboard_balancesConnection_edges_node {
  __typename: "Balance";
  guarantor: HomeDashboard_balancesConnection_edges_node_guarantor;
}

export interface HomeDashboard_balancesConnection_edges {
  __typename: "BalanceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: HomeDashboard_balancesConnection_edges_node;
}

export interface HomeDashboard_balancesConnection {
  __typename: "BalanceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: HomeDashboard_balancesConnection_edges[];
}

export interface HomeDashboard_chargesConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface HomeDashboard_chargesConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  name: HomeDashboard_chargesConnection_edges_node_contact_name;
}

export interface HomeDashboard_chargesConnection_edges_node {
  __typename: "Charge";
  id: string;
  contact: HomeDashboard_chargesConnection_edges_node_contact;
}

export interface HomeDashboard_chargesConnection_edges {
  __typename: "ChargeEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: HomeDashboard_chargesConnection_edges_node;
}

export interface HomeDashboard_chargesConnection {
  __typename: "ChargeConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: HomeDashboard_chargesConnection_edges[];
}

export interface HomeDashboard_promptsConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface HomeDashboard_promptsConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  name: HomeDashboard_promptsConnection_edges_node_contact_name;
}

export interface HomeDashboard_promptsConnection_edges_node {
  __typename: "OutreachFlow";
  contact: HomeDashboard_promptsConnection_edges_node_contact;
}

export interface HomeDashboard_promptsConnection_edges {
  __typename: "OutreachFlowEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: HomeDashboard_promptsConnection_edges_node;
}

export interface HomeDashboard_promptsConnection {
  __typename: "OutreachFlowConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: HomeDashboard_promptsConnection_edges[];
}

export interface HomeDashboard_failedMembership_contacts_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface HomeDashboard_failedMembership_contacts {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  name: HomeDashboard_failedMembership_contacts_name;
}

export interface HomeDashboard_failedMembership {
  __typename: "FailedPatients";
  count: number;
  contacts: HomeDashboard_failedMembership_contacts[];
}

export interface HomeDashboard_failedInstallments_contacts_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface HomeDashboard_failedInstallments_contacts {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  name: HomeDashboard_failedInstallments_contacts_name;
}

export interface HomeDashboard_failedInstallments {
  __typename: "FailedPatients";
  count: number;
  contacts: HomeDashboard_failedInstallments_contacts[];
}

export interface HomeDashboard {
  analytics: HomeDashboard_analytics;
  balanceMetrics: HomeDashboard_balanceMetrics;
  balancesConnection: HomeDashboard_balancesConnection;
  chargesConnection: HomeDashboard_chargesConnection;
  promptsConnection: HomeDashboard_promptsConnection;
  failedMembership: HomeDashboard_failedMembership;
  failedInstallments: HomeDashboard_failedInstallments;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsightsAccountsReceivable
// ====================================================

export interface InsightsAccountsReceivable_balanceMetrics_engagement_engaged_l30 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_engaged_l60 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_engaged_l90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_engaged_g90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_engaged {
  __typename: "BalanceStratification";
  l30: InsightsAccountsReceivable_balanceMetrics_engagement_engaged_l30;
  l60: InsightsAccountsReceivable_balanceMetrics_engagement_engaged_l60;
  l90: InsightsAccountsReceivable_balanceMetrics_engagement_engaged_l90;
  g90: InsightsAccountsReceivable_balanceMetrics_engagement_engaged_g90;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_scheduled_l30 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_scheduled_l60 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_scheduled_l90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_scheduled_g90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_scheduled {
  __typename: "BalanceStratification";
  l30: InsightsAccountsReceivable_balanceMetrics_engagement_scheduled_l30;
  l60: InsightsAccountsReceivable_balanceMetrics_engagement_scheduled_l60;
  l90: InsightsAccountsReceivable_balanceMetrics_engagement_scheduled_l90;
  g90: InsightsAccountsReceivable_balanceMetrics_engagement_scheduled_g90;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_followUp_l30 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_followUp_l60 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_followUp_l90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_followUp_g90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_followUp {
  __typename: "BalanceStratification";
  l30: InsightsAccountsReceivable_balanceMetrics_engagement_followUp_l30;
  l60: InsightsAccountsReceivable_balanceMetrics_engagement_followUp_l60;
  l90: InsightsAccountsReceivable_balanceMetrics_engagement_followUp_l90;
  g90: InsightsAccountsReceivable_balanceMetrics_engagement_followUp_g90;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all_l30 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all_l60 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all_l90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all_g90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all {
  __typename: "BalanceStratification";
  l30: InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all_l30;
  l60: InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all_l60;
  l90: InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all_l90;
  g90: InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all_g90;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement_outstanding {
  __typename: "BalanceEngagementOutstanding";
  all: InsightsAccountsReceivable_balanceMetrics_engagement_outstanding_all;
}

export interface InsightsAccountsReceivable_balanceMetrics_engagement {
  __typename: "BalanceEngagement";
  engaged: InsightsAccountsReceivable_balanceMetrics_engagement_engaged;
  scheduled: InsightsAccountsReceivable_balanceMetrics_engagement_scheduled;
  followUp: InsightsAccountsReceivable_balanceMetrics_engagement_followUp;
  outstanding: InsightsAccountsReceivable_balanceMetrics_engagement_outstanding;
}

export interface InsightsAccountsReceivable_balanceMetrics {
  __typename: "Balances";
  patientPortion: number;
  patientPortionPositive: number;
  insurancePortion: number;
  engagement: InsightsAccountsReceivable_balanceMetrics_engagement;
}

export interface InsightsAccountsReceivable_analytics_paymentTransactions_ar_collected {
  __typename: "TimeSeries";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  last30: number;
}

export interface InsightsAccountsReceivable_analytics_paymentTransactions_ar_processed {
  __typename: "TimeSeries";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  last30: number;
}

export interface InsightsAccountsReceivable_analytics_paymentTransactions_notifications {
  __typename: "TimeSeries";
  month6: number;
  last30: number;
}

export interface InsightsAccountsReceivable_analytics_paymentTransactions {
  __typename: "AnalyticsPaymentTransactions";
  ar_collected: InsightsAccountsReceivable_analytics_paymentTransactions_ar_collected;
  ar_processed: InsightsAccountsReceivable_analytics_paymentTransactions_ar_processed;
  notifications: InsightsAccountsReceivable_analytics_paymentTransactions_notifications;
}

export interface InsightsAccountsReceivable_analytics {
  __typename: "Analytics";
  paymentTransactions: InsightsAccountsReceivable_analytics_paymentTransactions;
}

export interface InsightsAccountsReceivable {
  balanceMetrics: InsightsAccountsReceivable_balanceMetrics;
  analytics: InsightsAccountsReceivable_analytics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsightsMembership
// ====================================================

export interface InsightsMembership_analytics_membership_subscriptions_planMemberCounts {
  __typename: "PlanMemberCount";
  plan: string;
  memberCount: number;
}

export interface InsightsMembership_analytics_membership_subscriptions {
  __typename: "AnalyticsMembershipSubscriptions";
  enrolledLast30: number;
  autoRenewalRate: number;
  monthlyCount: number;
  annualCount: number;
  planMemberCounts: InsightsMembership_analytics_membership_subscriptions_planMemberCounts[];
}

export interface InsightsMembership_analytics_membership {
  __typename: "AnalyticsMembership";
  subscriptions: InsightsMembership_analytics_membership_subscriptions;
}

export interface InsightsMembership_analytics_paymentTransactions_membership {
  __typename: "TimeSeries";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
}

export interface InsightsMembership_analytics_paymentTransactions {
  __typename: "AnalyticsPaymentTransactions";
  membership: InsightsMembership_analytics_paymentTransactions_membership;
}

export interface InsightsMembership_analytics {
  __typename: "Analytics";
  membership: InsightsMembership_analytics_membership;
  paymentTransactions: InsightsMembership_analytics_paymentTransactions;
}

export interface InsightsMembership {
  analytics: InsightsMembership_analytics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InstallmentPaymentsTable
// ====================================================

export interface InstallmentPaymentsTable_scheduledPaymentsConnection_edges_node_installmentPlan_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface InstallmentPaymentsTable_scheduledPaymentsConnection_edges_node_installmentPlan_guarantor {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  name: InstallmentPaymentsTable_scheduledPaymentsConnection_edges_node_installmentPlan_guarantor_name;
}

export interface InstallmentPaymentsTable_scheduledPaymentsConnection_edges_node_installmentPlan {
  __typename: "InstallmentPlan";
  id: string;
  guarantor: InstallmentPaymentsTable_scheduledPaymentsConnection_edges_node_installmentPlan_guarantor;
}

export interface InstallmentPaymentsTable_scheduledPaymentsConnection_edges_node {
  __typename: "ScheduledPayment";
  id: string;
  status: ScheduledPaymentStatus;
  amount: number;
  date: string;
  installmentPlan: InstallmentPaymentsTable_scheduledPaymentsConnection_edges_node_installmentPlan | null;
}

export interface InstallmentPaymentsTable_scheduledPaymentsConnection_edges {
  __typename: "ScheduledPaymentEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: InstallmentPaymentsTable_scheduledPaymentsConnection_edges_node;
}

export interface InstallmentPaymentsTable_scheduledPaymentsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface InstallmentPaymentsTable_scheduledPaymentsConnection {
  __typename: "ScheduledPaymentConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: InstallmentPaymentsTable_scheduledPaymentsConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: InstallmentPaymentsTable_scheduledPaymentsConnection_pageInfo;
}

export interface InstallmentPaymentsTable {
  scheduledPaymentsConnection: InstallmentPaymentsTable_scheduledPaymentsConnection;
}

export interface InstallmentPaymentsTableVariables {
  first?: number | null;
  after?: string | null;
  statuses: ScheduledPaymentStatus[];
  earliest?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertInstallmentTemplate
// ====================================================

export interface UpsertInstallmentTemplate_upsertInstallmentTemplate_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface UpsertInstallmentTemplate_upsertInstallmentTemplate {
  __typename: "InstallmentTemplate";
  id: string;
  name: string;
  interval: number;
  periods: number;
  intervalType: InstallmentIntervalType;
  enabled: boolean;
  balanceFilter: UpsertInstallmentTemplate_upsertInstallmentTemplate_balanceFilter;
  allowCC: boolean;
  allowACH: boolean;
  adminFee: number;
  downPayment: number;
}

export interface UpsertInstallmentTemplate {
  upsertInstallmentTemplate: UpsertInstallmentTemplate_upsertInstallmentTemplate;
}

export interface UpsertInstallmentTemplateVariables {
  id?: string | null;
  name: string;
  interval: number;
  periods: number;
  balanceFilter: PartialBalanceFilterInput;
  intervalType: InstallmentIntervalType;
  allowCC: boolean;
  allowACH: boolean;
  adminFee: number;
  downPayment: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInstallmentTemplate
// ====================================================

export interface DeleteInstallmentTemplate_deleteInstallmentTemplate {
  __typename: "InstallmentTemplate";
  id: string;
}

export interface DeleteInstallmentTemplate {
  deleteInstallmentTemplate: DeleteInstallmentTemplate_deleteInstallmentTemplate;
}

export interface DeleteInstallmentTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleInstallmentTemplate
// ====================================================

export interface ToggleInstallmentTemplate_toggleInstallmentTemplate {
  __typename: "InstallmentTemplate";
  id: string;
  enabled: boolean;
}

export interface ToggleInstallmentTemplate {
  toggleInstallmentTemplate: ToggleInstallmentTemplate_toggleInstallmentTemplate;
}

export interface ToggleInstallmentTemplateVariables {
  id: string;
  enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InstallmentsDashboard
// ====================================================

export interface InstallmentsDashboard_analytics_installments {
  __typename: "AnalyticsInstallments";
  count: number;
  activeCount: number;
  pastDueCount: number;
  completedCount: number;
}

export interface InstallmentsDashboard_analytics {
  __typename: "Analytics";
  installments: InstallmentsDashboard_analytics_installments;
}

export interface InstallmentsDashboard_failedPatients_contacts_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface InstallmentsDashboard_failedPatients_contacts {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  name: InstallmentsDashboard_failedPatients_contacts_name;
}

export interface InstallmentsDashboard_failedPatients {
  __typename: "FailedPatients";
  count: number;
  contacts: InstallmentsDashboard_failedPatients_contacts[];
}

export interface InstallmentsDashboard {
  analytics: InstallmentsDashboard_analytics;
  failedPatients: InstallmentsDashboard_failedPatients;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InvoiceSheet
// ====================================================

export interface InvoiceSheet_invoice_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface InvoiceSheet_invoice_contact {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  name: InvoiceSheet_invoice_contact_name;
}

export interface InvoiceSheet_invoice {
  __typename: "Invoice";
  id: string;
  code: string | null;
  date: number;
  status: InvoiceStatus;
  voidedAt: number | null;
  amount: number;
  isMembership: boolean;
  contact: InvoiceSheet_invoice_contact | null;
}

export interface InvoiceSheet {
  invoice: InvoiceSheet_invoice;
}

export interface InvoiceSheetVariables {
  invoiceId?: string | null;
  subscriptionId?: string | null;
  expandSubscription?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InvoicesTable
// ====================================================

export interface InvoicesTable_invoicesConnection_edges_node_charge {
  __typename: "Charge";
  id: string;
  date: number;
  status: ChargeStatus;
}

export interface InvoicesTable_invoicesConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface InvoicesTable_invoicesConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  name: InvoicesTable_invoicesConnection_edges_node_contact_name;
}

export interface InvoicesTable_invoicesConnection_edges_node {
  __typename: "Invoice";
  id: string;
  stripeId_subscription: string;
  status: InvoiceStatus;
  amount: number;
  date: number;
  isMembership: boolean;
  charge: InvoicesTable_invoicesConnection_edges_node_charge | null;
  contact: InvoicesTable_invoicesConnection_edges_node_contact | null;
}

export interface InvoicesTable_invoicesConnection_edges {
  __typename: "InvoiceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: InvoicesTable_invoicesConnection_edges_node;
}

export interface InvoicesTable_invoicesConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface InvoicesTable_invoicesConnection {
  __typename: "InvoiceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: InvoicesTable_invoicesConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: InvoicesTable_invoicesConnection_pageInfo;
}

export interface InvoicesTable {
  invoicesConnection: InvoicesTable_invoicesConnection;
}

export interface InvoicesTableVariables {
  first?: number | null;
  after?: string | null;
  filter?: InvoiceFilter | null;
  status?: InvoiceStatus | null;
  expandSubscription?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateLetterPreview
// ====================================================

export interface GenerateLetterPreview {
  generateLetterPreviewPdf: string;
}

export interface GenerateLetterPreviewVariables {
  contactId?: string | null;
  title?: string | null;
  body: string;
  layout: NotificationLayout;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LettersTable
// ====================================================

export interface LettersTable_notificationsConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface LettersTable_notificationsConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  name: LettersTable_notificationsConnection_edges_node_contact_name;
}

export interface LettersTable_notificationsConnection_edges_node {
  __typename: "Notification";
  id: string;
  createdAt: string;
  estimatedAt: string;
  updatedAt: string | null;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
  contact: LettersTable_notificationsConnection_edges_node_contact;
  templateName: string | null;
  lob_url: string | null;
}

export interface LettersTable_notificationsConnection_edges {
  __typename: "NotificationEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: LettersTable_notificationsConnection_edges_node;
}

export interface LettersTable_notificationsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface LettersTable_notificationsConnection {
  __typename: "NotificationConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: LettersTable_notificationsConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: LettersTable_notificationsConnection_pageInfo;
}

export interface LettersTable {
  notificationsConnection: LettersTable_notificationsConnection;
}

export interface LettersTableVariables {
  search?: string | null;
  status?: NotificationStatus | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelLetter
// ====================================================

export interface CancelLetter_cancelLetter {
  __typename: "Notification";
  id: string;
  status: NotificationStatus;
  updatedAt: string | null;
}

export interface CancelLetter {
  cancelLetter: CancelLetter_cancelLetter;
}

export interface CancelLetterVariables {
  notificationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MarketingSite
// ====================================================

export interface MarketingSite_account {
  __typename: "Account";
  id: string;
  siteImage: SiteImage;
  siteColor: SiteColor;
  membershipSlug: string | null;
  siteVideoUrl: string | null;
}

export interface MarketingSite {
  account: MarketingSite_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMarketingSite
// ====================================================

export interface UpdateMarketingSite_updateAccount {
  __typename: "Account";
  id: string;
  siteImage: SiteImage;
  siteColor: SiteColor;
  siteVideoUrl: string | null;
  membershipSlug: string | null;
}

export interface UpdateMarketingSite {
  updateAccount: UpdateMarketingSite_updateAccount;
}

export interface UpdateMarketingSiteVariables {
  siteImage?: SiteImage | null;
  siteColor?: SiteColor | null;
  membershipSlug?: string | null;
  siteVideoUrl?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MarketingWidget
// ====================================================

export interface MarketingWidget_widget {
  __typename: "Widget";
  id: string;
  themeColor: string;
  isLeft: boolean;
  show: boolean;
  showMobile: boolean;
  title: string;
  icon: WidgetIcon;
  description: string;
  buttonLabel: string;
  solidButton: boolean;
}

export interface MarketingWidget {
  widget: MarketingWidget_widget;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWidget
// ====================================================

export interface UpdateWidget_updateWidget {
  __typename: "Widget";
  id: string;
  themeColor: string;
  isLeft: boolean;
  show: boolean;
  showMobile: boolean;
  title: string;
  icon: WidgetIcon;
  description: string;
  buttonLabel: string;
  solidButton: boolean;
}

export interface UpdateWidget {
  updateWidget: UpdateWidget_updateWidget;
}

export interface UpdateWidgetVariables {
  id?: string | null;
  themeColor?: string | null;
  isLeft?: boolean | null;
  show?: boolean | null;
  showMobile?: boolean | null;
  title?: string | null;
  icon?: WidgetIcon | null;
  description?: string | null;
  buttonLabel?: string | null;
  solidButton?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembersTable
// ====================================================

export interface MembersTable_patientsConnection_edges_node_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface MembersTable_patientsConnection_edges_node_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  status: SubscriptionStatus;
  /**
   * Is subscription renewal off?
   */
  autoRenew: boolean;
}

export interface MembersTable_patientsConnection_edges_node_stripe {
  __typename: "ContactStripeFields";
  membership: MembersTable_patientsConnection_edges_node_stripe_membership | null;
}

export interface MembersTable_patientsConnection_edges_node_plan {
  __typename: "Plan";
  name: string;
}

export interface MembersTable_patientsConnection_edges_node {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  email: string | null;
  phone: string | null;
  hasAddress: boolean;
  isGuarantor: boolean;
  name: MembersTable_patientsConnection_edges_node_name;
  stripe: MembersTable_patientsConnection_edges_node_stripe;
  plan: MembersTable_patientsConnection_edges_node_plan | null;
  planTerm: PlanTerm | null;
  planRenewalDate: number | null;
}

export interface MembersTable_patientsConnection_edges {
  __typename: "ContactEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: MembersTable_patientsConnection_edges_node;
}

export interface MembersTable_patientsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface MembersTable_patientsConnection {
  __typename: "ContactConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: MembersTable_patientsConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: MembersTable_patientsConnection_pageInfo;
}

export interface MembersTable {
  patientsConnection: MembersTable_patientsConnection;
}

export interface MembersTableVariables {
  search?: string | null;
  filter?: PatientFilter | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipDashboard
// ====================================================

export interface MembershipDashboard_analytics_membership_subscriptions_planMemberCounts {
  __typename: "PlanMemberCount";
  plan: string;
  memberCount: number;
}

export interface MembershipDashboard_analytics_membership_subscriptions {
  __typename: "AnalyticsMembershipSubscriptions";
  totalCount: number;
  planMemberCounts: MembershipDashboard_analytics_membership_subscriptions_planMemberCounts[];
}

export interface MembershipDashboard_analytics_membership {
  __typename: "AnalyticsMembership";
  subscriptions: MembershipDashboard_analytics_membership_subscriptions;
}

export interface MembershipDashboard_analytics {
  __typename: "Analytics";
  membership: MembershipDashboard_analytics_membership;
}

export interface MembershipDashboard_failedPatients_contacts_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface MembershipDashboard_failedPatients_contacts {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  name: MembershipDashboard_failedPatients_contacts_name;
}

export interface MembershipDashboard_failedPatients {
  __typename: "FailedPatients";
  count: number;
  contacts: MembershipDashboard_failedPatients_contacts[];
}

export interface MembershipDashboard {
  analytics: MembershipDashboard_analytics;
  failedPatients: MembershipDashboard_failedPatients;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MergeGuarantorsDialog
// ====================================================

export interface MergeGuarantorsDialog_legacy_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface MergeGuarantorsDialog_legacy_dependents_stripe {
  __typename: "ContactStripeFields";
  subscriptionId: string | null;
}

export interface MergeGuarantorsDialog_legacy_dependents {
  __typename: "Contact";
  id: string;
  manual_guarantorId: string | null;
  stripe: MergeGuarantorsDialog_legacy_dependents_stripe;
}

export interface MergeGuarantorsDialog_legacy {
  __typename: "Contact";
  id: string;
  accountId: string;
  isGuarantor: boolean;
  code: string;
  name: MergeGuarantorsDialog_legacy_name;
  dependents: MergeGuarantorsDialog_legacy_dependents[];
}

export interface MergeGuarantorsDialog_current_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface MergeGuarantorsDialog_current_stripe {
  __typename: "ContactStripeFields";
  customerId: string | null;
  subscriptionId: string | null;
}

export interface MergeGuarantorsDialog_current_dependents_stripe {
  __typename: "ContactStripeFields";
  subscriptionId: string | null;
}

export interface MergeGuarantorsDialog_current_dependents {
  __typename: "Contact";
  id: string;
  stripe: MergeGuarantorsDialog_current_dependents_stripe;
}

export interface MergeGuarantorsDialog_current {
  __typename: "Contact";
  id: string;
  accountId: string;
  isGuarantor: boolean;
  code: string;
  name: MergeGuarantorsDialog_current_name;
  stripe: MergeGuarantorsDialog_current_stripe;
  dependents: MergeGuarantorsDialog_current_dependents[];
}

export interface MergeGuarantorsDialog {
  legacy: MergeGuarantorsDialog_legacy;
  current: MergeGuarantorsDialog_current;
}

export interface MergeGuarantorsDialogVariables {
  legacyCode: string;
  currentCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MergeGuarantors
// ====================================================

export interface MergeGuarantors_mergeGuarantors {
  __typename: "Contact";
  id: string;
}

export interface MergeGuarantors {
  mergeGuarantors: MergeGuarantors_mergeGuarantors;
}

export interface MergeGuarantorsVariables {
  legacyId: string;
  currentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PauseInstallmentPlan
// ====================================================

export interface PauseInstallmentPlan_pauseInstallmentPlan {
  __typename: "InstallmentPlan";
  id: string;
  status: InstallmentPlanStatus;
}

export interface PauseInstallmentPlan {
  pauseInstallmentPlan: PauseInstallmentPlan_pauseInstallmentPlan;
}

export interface PauseInstallmentPlanVariables {
  installmentPlanId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TerminateInstallmentPlan
// ====================================================

export interface TerminateInstallmentPlan_terminateInstallmentPlan {
  __typename: "InstallmentPlan";
  id: string;
  status: InstallmentPlanStatus;
}

export interface TerminateInstallmentPlan {
  terminateInstallmentPlan: TerminateInstallmentPlan_terminateInstallmentPlan;
}

export interface TerminateInstallmentPlanVariables {
  installmentPlanId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTestEmailNotification
// ====================================================

export interface SendTestEmailNotification {
  deliverTestEmailNotification: boolean;
}

export interface SendTestEmailNotificationVariables {
  email: string;
  subject: string;
  body: string;
  signature: string;
  buttonUrl?: string | null;
  buttonLabel?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTestSmsNotification
// ====================================================

export interface SendTestSmsNotification {
  deliverTestSmsNotification: boolean;
}

export interface SendTestSmsNotificationVariables {
  phone: string;
  body: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingStart
// ====================================================

export interface OnboardingStart_meUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
}

export interface OnboardingStart {
  meUser: OnboardingStart_meUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitOnboardingStart
// ====================================================

export interface SubmitOnboardingStart_onboardingStart {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
}

export interface SubmitOnboardingStart {
  onboardingStart: SubmitOnboardingStart_onboardingStart;
}

export interface SubmitOnboardingStartVariables {
  idToken: string;
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOnboardingStart
// ====================================================

export interface UpdateOnboardingStart_updateUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
}

export interface UpdateOnboardingStart {
  updateUser: UpdateOnboardingStart_updateUser;
}

export interface UpdateOnboardingStartVariables {
  id: string;
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingPractice
// ====================================================

export interface OnboardingPractice_account_offices {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface OnboardingPractice_account {
  __typename: "Account";
  id: string;
  name: string;
  timezone: Timezone;
  phone: string;
  offices: OnboardingPractice_account_offices[];
}

export interface OnboardingPractice {
  account: OnboardingPractice_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitOnboardingPractice
// ====================================================

export interface SubmitOnboardingPractice_onboardingPractice_offices {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface SubmitOnboardingPractice_onboardingPractice {
  __typename: "Account";
  id: string;
  name: string;
  timezone: Timezone;
  phone: string;
  offices: SubmitOnboardingPractice_onboardingPractice_offices[];
}

export interface SubmitOnboardingPractice {
  onboardingPractice: SubmitOnboardingPractice_onboardingPractice;
}

export interface SubmitOnboardingPracticeVariables {
  name: string;
  mirror: Mirror;
  timezone: Timezone;
  street1: string;
  street2?: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOnboardingPractice
// ====================================================

export interface UpdateOnboardingPractice_updateAccount {
  __typename: "Account";
  id: string;
  name: string;
  timezone: Timezone;
}

export interface UpdateOnboardingPractice_updateAddress {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface UpdateOnboardingPractice {
  updateAccount: UpdateOnboardingPractice_updateAccount;
  updateAddress: UpdateOnboardingPractice_updateAddress;
}

export interface UpdateOnboardingPracticeVariables {
  name: string;
  timezone: Timezone;
  officeId: string;
  street1: string;
  street2?: string | null;
  city: string;
  state: State;
  zip: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingOffices
// ====================================================

export interface OnboardingOffices_account_offices {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface OnboardingOffices_account {
  __typename: "Account";
  id: string;
  offices: OnboardingOffices_account_offices[];
}

export interface OnboardingOffices {
  account: OnboardingOffices_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingDentists
// ====================================================

export interface OnboardingDentists_dentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface OnboardingDentists {
  dentists: OnboardingDentists_dentists[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitOnboardingDentists
// ====================================================

export interface SubmitOnboardingDentists_onboardingDentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface SubmitOnboardingDentists {
  onboardingDentists: SubmitOnboardingDentists_onboardingDentists;
}

export interface SubmitOnboardingDentistsVariables {
  salutation: Salutation;
  name: string;
  degree: Degree;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingComplete
// ====================================================

export interface OnboardingComplete_firstAddress {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface OnboardingComplete_meUser {
  __typename: "User";
  firstName: string;
}

export interface OnboardingComplete {
  firstAddress: OnboardingComplete_firstAddress;
  meUser: OnboardingComplete_meUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OutreachBatch
// ====================================================

export interface OutreachBatch_account_notificationTemplates {
  __typename: "NotificationTemplate";
  id: string;
  channel: NotificationChannel;
  name: string;
  layout: NotificationLayout;
  title: string | null;
  body: string;
  subject: string | null;
  buttonLabel: string | null;
  buttonUrl: string | null;
  signature: string | null;
}

export interface OutreachBatch_account {
  __typename: "Account";
  notificationTemplates: OutreachBatch_account_notificationTemplates[];
}

export interface OutreachBatch {
  account: OutreachBatch_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OutreachBatchBalances
// ====================================================

export interface OutreachBatchBalances_balances_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface OutreachBatchBalances_balances_guarantor_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface OutreachBatchBalances_balances_guarantor_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface OutreachBatchBalances_balances_guarantor_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface OutreachBatchBalances_balances_guarantor_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface OutreachBatchBalances_balances_guarantor {
  __typename: "Contact";
  id: string;
  name: OutreachBatchBalances_balances_guarantor_name;
  email: string | null;
  phone: string | null;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: OutreachBatchBalances_balances_guarantor_dob | null;
  latestEmailNotification: OutreachBatchBalances_balances_guarantor_latestEmailNotification | null;
  latestSMSNotification: OutreachBatchBalances_balances_guarantor_latestSMSNotification | null;
  latestLetterNotification: OutreachBatchBalances_balances_guarantor_latestLetterNotification | null;
}

export interface OutreachBatchBalances_balances {
  __typename: "Balance";
  id: string;
  guarantor: OutreachBatchBalances_balances_guarantor;
  patientPortion: number;
  insurancePortion: number;
  dpdBucket: DPDBucket;
}

export interface OutreachBatchBalances {
  balances: OutreachBatchBalances_balances[];
}

export interface OutreachBatchBalancesVariables {
  engagementStatus: BalanceEngagementStatus;
  blockedReasons?: BalanceBlockedReason[] | null;
  singleBlockedReason?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendOutreachBatch
// ====================================================

export interface SendOutreachBatch {
  batchOutreach: number;
}

export interface SendOutreachBatchVariables {
  contactIds: string[];
  templateId: string;
  channel: NotificationChannel;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OutreachEmails
// ====================================================

export interface OutreachEmails {
  last30: number;
  allTime: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OutreachInbound
// ====================================================

export interface OutreachInbound_account_returnAddress {
  __typename: "Address";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface OutreachInbound_account {
  __typename: "Account";
  id: string;
  name: string;
  replyEmail: string;
  privateReplyPhone: string;
  publicReplyPhone: string;
  inboundCall_behavior: InboundCallBehavior;
  inboundCall_greetingText: string | null;
  inboundCall_greetingUrl: string | null;
  capabilities: AccountCapability[];
  returnAddress: OutreachInbound_account_returnAddress;
}

export interface OutreachInbound_last30 {
  __typename: "InboundEventMetrics";
  totalEvents: number;
  totalResolved: number;
  totalForwarded: number;
}

export interface OutreachInbound_allTime {
  __typename: "InboundEventMetrics";
  totalEvents: number;
  totalResolved: number;
  totalForwarded: number;
}

export interface OutreachInbound {
  account: OutreachInbound_account;
  last30: OutreachInbound_last30;
  allTime: OutreachInbound_allTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOutreachInboundCallBehavior
// ====================================================

export interface UpdateOutreachInboundCallBehavior_updateInboundCallBehavior {
  __typename: "Account";
  id: string;
  inboundCall_behavior: InboundCallBehavior;
}

export interface UpdateOutreachInboundCallBehavior {
  updateInboundCallBehavior: UpdateOutreachInboundCallBehavior_updateInboundCallBehavior;
}

export interface UpdateOutreachInboundCallBehaviorVariables {
  accountId: string;
  behavior: InboundCallBehavior;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertOutreachInboundCallGreeting
// ====================================================

export interface UpsertOutreachInboundCallGreeting_upsertInboundCallGreeting {
  __typename: "Account";
  id: string;
  inboundCall_greetingText: string | null;
  inboundCall_greetingUrl: string | null;
}

export interface UpsertOutreachInboundCallGreeting {
  upsertInboundCallGreeting: UpsertOutreachInboundCallGreeting_upsertInboundCallGreeting;
}

export interface UpsertOutreachInboundCallGreetingVariables {
  accountId: string;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OutreachLetters
// ====================================================

export interface OutreachLetters {
  last30: number;
  allTime: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OutreachSms
// ====================================================

export interface OutreachSms {
  last30: number;
  allTime: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OutreachTemplates
// ====================================================

export interface OutreachTemplates_account_notificationTemplates {
  __typename: "NotificationTemplate";
  id: string;
  channel: NotificationChannel;
  layout: NotificationLayout;
  isBilling: boolean;
  name: string;
  body: string;
  title: string | null;
  subject: string | null;
  buttonLabel: string | null;
  buttonUrl: string | null;
  signature: string | null;
  createdAt: string;
}

export interface OutreachTemplates_account {
  __typename: "Account";
  id: string;
  notificationTemplates: OutreachTemplates_account_notificationTemplates[];
}

export interface OutreachTemplates {
  account: OutreachTemplates_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteNotificationTemplate
// ====================================================

export interface DeleteNotificationTemplate_deleteNotificationTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface DeleteNotificationTemplate {
  deleteNotificationTemplate: DeleteNotificationTemplate_deleteNotificationTemplate;
}

export interface DeleteNotificationTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BillingOutstandingAging
// ====================================================

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_total {
  __typename: "BalanceCohort";
  sum: number;
  count: number;
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt0 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt100 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt500 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt1000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt2000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt5000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_l30 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_l60 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_l90 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_g90 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_all {
  __typename: "BalanceStratification";
  total: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_total;
  gt0: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt0;
  gt100: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt100;
  gt500: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt500;
  gt1000: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt1000;
  gt2000: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt2000;
  gt5000: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_gt5000;
  l30: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_l30;
  l60: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_l60;
  l90: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_l90;
  g90: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all_g90;
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_total {
  __typename: "BalanceCohort";
  sum: number;
  count: number;
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt0 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt100 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt500 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt1000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt2000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt5000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_l30 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_l60 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_l90 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_g90 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance {
  __typename: "BalanceStratification";
  total: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_total;
  gt0: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt0;
  gt100: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt100;
  gt500: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt500;
  gt1000: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt1000;
  gt2000: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt2000;
  gt5000: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_gt5000;
  l30: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_l30;
  l60: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_l60;
  l90: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_l90;
  g90: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance_g90;
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_total {
  __typename: "BalanceCohort";
  sum: number;
  count: number;
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt0 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt100 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt500 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt1000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt2000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt5000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_l30 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_l60 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_l90 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_g90 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance {
  __typename: "BalanceStratification";
  total: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_total;
  gt0: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt0;
  gt100: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt100;
  gt500: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt500;
  gt1000: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt1000;
  gt2000: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt2000;
  gt5000: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_gt5000;
  l30: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_l30;
  l60: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_l60;
  l90: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_l90;
  g90: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance_g90;
}

export interface BillingOutstandingAging_balanceMetrics_engagement_outstanding {
  __typename: "BalanceEngagementOutstanding";
  all: BillingOutstandingAging_balanceMetrics_engagement_outstanding_all;
  pendingInsurance: BillingOutstandingAging_balanceMetrics_engagement_outstanding_pendingInsurance;
  notPendingInsurance: BillingOutstandingAging_balanceMetrics_engagement_outstanding_notPendingInsurance;
}

export interface BillingOutstandingAging_balanceMetrics_engagement {
  __typename: "BalanceEngagement";
  outstanding: BillingOutstandingAging_balanceMetrics_engagement_outstanding;
}

export interface BillingOutstandingAging_balanceMetrics {
  __typename: "Balances";
  engagement: BillingOutstandingAging_balanceMetrics_engagement;
}

export interface BillingOutstandingAging {
  balanceMetrics: BillingOutstandingAging_balanceMetrics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BillingOutstandingAssistant
// ====================================================

export interface BillingOutstandingAssistant_balanceAssistant_digital {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAssistant_balanceAssistant_prime {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAssistant_balanceAssistant_big {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAssistant_balanceAssistant_writeoff {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface BillingOutstandingAssistant_balanceAssistant {
  __typename: "BalanceAssistant";
  digital: BillingOutstandingAssistant_balanceAssistant_digital;
  prime: BillingOutstandingAssistant_balanceAssistant_prime;
  big: BillingOutstandingAssistant_balanceAssistant_big;
  writeoff: BillingOutstandingAssistant_balanceAssistant_writeoff;
}

export interface BillingOutstandingAssistant {
  balanceAssistant: BillingOutstandingAssistant_balanceAssistant;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OutstandingTable
// ====================================================

export interface OutstandingTable_balancesConnection_edges_node_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface OutstandingTable_balancesConnection_edges_node_guarantor_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface OutstandingTable_balancesConnection_edges_node_guarantor_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface OutstandingTable_balancesConnection_edges_node_guarantor_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface OutstandingTable_balancesConnection_edges_node_guarantor_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface OutstandingTable_balancesConnection_edges_node_guarantor {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  email: string | null;
  phone: string | null;
  name: OutstandingTable_balancesConnection_edges_node_guarantor_name;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: OutstandingTable_balancesConnection_edges_node_guarantor_dob | null;
  latestEmailNotification: OutstandingTable_balancesConnection_edges_node_guarantor_latestEmailNotification | null;
  latestSMSNotification: OutstandingTable_balancesConnection_edges_node_guarantor_latestSMSNotification | null;
  latestLetterNotification: OutstandingTable_balancesConnection_edges_node_guarantor_latestLetterNotification | null;
}

export interface OutstandingTable_balancesConnection_edges_node {
  __typename: "Balance";
  id: string;
  patientPortion: number;
  insurancePortion: number;
  daysPastDue: number | null;
  dpdBucket: DPDBucket;
  guarantor: OutstandingTable_balancesConnection_edges_node_guarantor;
}

export interface OutstandingTable_balancesConnection_edges {
  __typename: "BalanceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: OutstandingTable_balancesConnection_edges_node;
}

export interface OutstandingTable_balancesConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface OutstandingTable_balancesConnection {
  __typename: "BalanceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: OutstandingTable_balancesConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: OutstandingTable_balancesConnection_pageInfo;
}

export interface OutstandingTable {
  balancesConnection: OutstandingTable_balancesConnection;
}

export interface OutstandingTableVariables {
  search?: string | null;
  contactIds?: string[] | null;
  first?: number | null;
  after?: string | null;
  insurancePending?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PatientsTable
// ====================================================

export interface PatientsTable_patientsConnection_edges_node_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface PatientsTable_patientsConnection_edges_node_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface PatientsTable_patientsConnection_edges_node_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface PatientsTable_patientsConnection_edges_node_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface PatientsTable_patientsConnection_edges_node_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface PatientsTable_patientsConnection_edges_node_stripe_membership {
  __typename: "StripeMembershipFields";
  id: string;
  status: SubscriptionStatus;
}

export interface PatientsTable_patientsConnection_edges_node_stripe {
  __typename: "ContactStripeFields";
  customerId: string | null;
  membership: PatientsTable_patientsConnection_edges_node_stripe_membership | null;
}

export interface PatientsTable_patientsConnection_edges_node {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  source: DataSource;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  email: string | null;
  phone: string | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: PatientsTable_patientsConnection_edges_node_dob | null;
  latestEmailNotification: PatientsTable_patientsConnection_edges_node_latestEmailNotification | null;
  latestSMSNotification: PatientsTable_patientsConnection_edges_node_latestSMSNotification | null;
  latestLetterNotification: PatientsTable_patientsConnection_edges_node_latestLetterNotification | null;
  isGuarantor: boolean;
  hasInstallmentPlan: boolean;
  name: PatientsTable_patientsConnection_edges_node_name;
  stripe: PatientsTable_patientsConnection_edges_node_stripe;
}

export interface PatientsTable_patientsConnection_edges {
  __typename: "ContactEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: PatientsTable_patientsConnection_edges_node;
}

export interface PatientsTable_patientsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface PatientsTable_patientsConnection {
  __typename: "ContactConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: PatientsTable_patientsConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: PatientsTable_patientsConnection_pageInfo;
}

export interface PatientsTable {
  patientsConnection: PatientsTable_patientsConnection;
}

export interface PatientsTableVariables {
  search?: string | null;
  filter?: PatientFilter | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayOverTimeDashboard
// ====================================================

export interface PayOverTimeDashboard_analytics_installments {
  __typename: "AnalyticsInstallments";
  count: number;
  activeCount: number;
  pastDueCount: number;
  completedCount: number;
}

export interface PayOverTimeDashboard_analytics {
  __typename: "Analytics";
  installments: PayOverTimeDashboard_analytics_installments;
}

export interface PayOverTimeDashboard_failedPatients_contacts_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface PayOverTimeDashboard_failedPatients_contacts {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  code: string;
  name: PayOverTimeDashboard_failedPatients_contacts_name;
}

export interface PayOverTimeDashboard_failedPatients {
  __typename: "FailedPatients";
  count: number;
  contacts: PayOverTimeDashboard_failedPatients_contacts[];
}

export interface PayOverTimeDashboard_balanceMetrics_engagement_outstanding_all_total {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
}

export interface PayOverTimeDashboard_balanceMetrics_engagement_outstanding_all {
  __typename: "BalanceStratification";
  total: PayOverTimeDashboard_balanceMetrics_engagement_outstanding_all_total;
}

export interface PayOverTimeDashboard_balanceMetrics_engagement_outstanding {
  __typename: "BalanceEngagementOutstanding";
  all: PayOverTimeDashboard_balanceMetrics_engagement_outstanding_all;
}

export interface PayOverTimeDashboard_balanceMetrics_engagement {
  __typename: "BalanceEngagement";
  outstanding: PayOverTimeDashboard_balanceMetrics_engagement_outstanding;
}

export interface PayOverTimeDashboard_balanceMetrics {
  __typename: "Balances";
  engagement: PayOverTimeDashboard_balanceMetrics_engagement;
}

export interface PayOverTimeDashboard_balancesConnection_edges_node_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface PayOverTimeDashboard_balancesConnection_edges_node_guarantor {
  __typename: "Contact";
  id: string;
  name: PayOverTimeDashboard_balancesConnection_edges_node_guarantor_name;
}

export interface PayOverTimeDashboard_balancesConnection_edges_node {
  __typename: "Balance";
  guarantor: PayOverTimeDashboard_balancesConnection_edges_node_guarantor;
}

export interface PayOverTimeDashboard_balancesConnection_edges {
  __typename: "BalanceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: PayOverTimeDashboard_balancesConnection_edges_node;
}

export interface PayOverTimeDashboard_balancesConnection {
  __typename: "BalanceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: PayOverTimeDashboard_balancesConnection_edges[];
}

export interface PayOverTimeDashboard {
  analytics: PayOverTimeDashboard_analytics;
  failedPatients: PayOverTimeDashboard_failedPatients;
  balanceMetrics: PayOverTimeDashboard_balanceMetrics;
  balancesConnection: PayOverTimeDashboard_balancesConnection;
}

export interface PayOverTimeDashboardVariables {
  minPatientPortion: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayOverTimeFinancing
// ====================================================

export interface PayOverTimeFinancing_account_sunbitConfiguration_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface PayOverTimeFinancing_account_sunbitConfiguration {
  __typename: "SunbitConfiguration";
  id: string;
  enabled: boolean;
  locationId: string;
  departmentId: string | null;
  representativeEmail: string | null;
  representativeFirstName: string | null;
  representativeLastName: string | null;
  balanceFilter: PayOverTimeFinancing_account_sunbitConfiguration_balanceFilter;
}

export interface PayOverTimeFinancing_account_financingProviders_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface PayOverTimeFinancing_account_financingProviders {
  __typename: "FinancingProvider";
  id: string;
  name: string;
  url: string;
  description: string;
  enabled: boolean;
  balanceFilter: PayOverTimeFinancing_account_financingProviders_balanceFilter;
}

export interface PayOverTimeFinancing_account {
  __typename: "Account";
  id: string;
  sunbitConfiguration: PayOverTimeFinancing_account_sunbitConfiguration | null;
  financingProviders: PayOverTimeFinancing_account_financingProviders[];
}

export interface PayOverTimeFinancing {
  account: PayOverTimeFinancing_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayOverTimeInstallments
// ====================================================

export interface PayOverTimeInstallments_account_installmentTemplates_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  minDaysSinceLastVisit: number | null;
  maxDaysSinceLastVisit: number | null;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface PayOverTimeInstallments_account_installmentTemplates {
  __typename: "InstallmentTemplate";
  id: string;
  name: string;
  interval: number;
  periods: number;
  intervalType: InstallmentIntervalType;
  enabled: boolean;
  balanceFilter: PayOverTimeInstallments_account_installmentTemplates_balanceFilter;
  allowCC: boolean;
  allowACH: boolean;
  adminFee: number;
  downPayment: number;
}

export interface PayOverTimeInstallments_account {
  __typename: "Account";
  id: string;
  installmentTemplates: PayOverTimeInstallments_account_installmentTemplates[];
}

export interface PayOverTimeInstallments {
  account: PayOverTimeInstallments_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayOverTimeOptionsCard
// ====================================================

export interface PayOverTimeOptionsCard_contact_manual_installmentTemplates {
  __typename: "InstallmentTemplate";
  id: string;
}

export interface PayOverTimeOptionsCard_contact_default_installmentTemplates {
  __typename: "InstallmentTemplate";
  id: string;
}

export interface PayOverTimeOptionsCard_contact_manual_financingProviders {
  __typename: "FinancingProvider";
  id: string;
}

export interface PayOverTimeOptionsCard_contact_default_financingProviders {
  __typename: "FinancingProvider";
  id: string;
}

export interface PayOverTimeOptionsCard_contact {
  __typename: "Contact";
  id: string;
  manual_payOverTime: boolean;
  manual_installmentTemplates: PayOverTimeOptionsCard_contact_manual_installmentTemplates[];
  default_installmentTemplates: PayOverTimeOptionsCard_contact_default_installmentTemplates[];
  manual_financingProviders: PayOverTimeOptionsCard_contact_manual_financingProviders[];
  default_financingProviders: PayOverTimeOptionsCard_contact_default_financingProviders[];
  manual_sunbitEnabled: boolean;
  default_sunbitEnabled: boolean;
}

export interface PayOverTimeOptionsCard_account_installmentTemplates {
  __typename: "InstallmentTemplate";
  id: string;
  name: string;
  periods: number;
  interval: number;
  intervalType: InstallmentIntervalType;
}

export interface PayOverTimeOptionsCard_account_financingProviders {
  __typename: "FinancingProvider";
  id: string;
  name: string;
  url: string;
}

export interface PayOverTimeOptionsCard_account {
  __typename: "Account";
  id: string;
  sunbitConfigurationId: string | null;
  installmentTemplates: PayOverTimeOptionsCard_account_installmentTemplates[];
  financingProviders: PayOverTimeOptionsCard_account_financingProviders[];
}

export interface PayOverTimeOptionsCard {
  contact: PayOverTimeOptionsCard_contact;
  account: PayOverTimeOptionsCard_account;
}

export interface PayOverTimeOptionsCardVariables {
  guarantorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetPayOverTimeOptions
// ====================================================

export interface SetPayOverTimeOptions_setPayOverTimeOptions_manual_installmentTemplates {
  __typename: "InstallmentTemplate";
  id: string;
}

export interface SetPayOverTimeOptions_setPayOverTimeOptions_manual_financingProviders {
  __typename: "FinancingProvider";
  id: string;
}

export interface SetPayOverTimeOptions_setPayOverTimeOptions {
  __typename: "Contact";
  id: string;
  manual_payOverTime: boolean;
  manual_sunbitEnabled: boolean;
  manual_installmentTemplates: SetPayOverTimeOptions_setPayOverTimeOptions_manual_installmentTemplates[];
  manual_financingProviders: SetPayOverTimeOptions_setPayOverTimeOptions_manual_financingProviders[];
}

export interface SetPayOverTimeOptions {
  setPayOverTimeOptions: SetPayOverTimeOptions_setPayOverTimeOptions;
}

export interface SetPayOverTimeOptionsVariables {
  guarantorId: string;
  enabled?: boolean | null;
  enabledSunbit?: boolean | null;
  enabledInstallmentTemplateIds?: string[] | null;
  enabledFinancingProviderIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentPlansTable
// ====================================================

export interface PaymentPlansTable_paymentPlansConnection_edges_node_scheduledPayments {
  __typename: "ScheduledPayment";
  id: string;
  amount: number;
  date: string;
  status: ScheduledPaymentStatus;
}

export interface PaymentPlansTable_paymentPlansConnection_edges_node_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface PaymentPlansTable_paymentPlansConnection_edges_node_guarantor {
  __typename: "Contact";
  id: string;
  name: PaymentPlansTable_paymentPlansConnection_edges_node_guarantor_name;
  email: string | null;
  phone: string | null;
}

export interface PaymentPlansTable_paymentPlansConnection_edges_node {
  __typename: "InstallmentPlan";
  id: string;
  interval: number;
  intervalType: InstallmentIntervalType;
  periods: number;
  faceAmount: number;
  adminFee: number;
  downPayment: number;
  startDate: string;
  status: InstallmentPlanStatus;
  scheduledPayments: PaymentPlansTable_paymentPlansConnection_edges_node_scheduledPayments[];
  guarantor: PaymentPlansTable_paymentPlansConnection_edges_node_guarantor;
}

export interface PaymentPlansTable_paymentPlansConnection_edges {
  __typename: "InstallmentPlanEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: PaymentPlansTable_paymentPlansConnection_edges_node;
}

export interface PaymentPlansTable_paymentPlansConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface PaymentPlansTable_paymentPlansConnection {
  __typename: "InstallmentPlanConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: PaymentPlansTable_paymentPlansConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: PaymentPlansTable_paymentPlansConnection_pageInfo;
}

export interface PaymentPlansTable {
  paymentPlansConnection: PaymentPlansTable_paymentPlansConnection;
}

export interface PaymentPlansTableVariables {
  first?: number | null;
  after?: string | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentPlansAnalytics
// ====================================================

export interface PaymentPlansAnalytics_analytics_installments {
  __typename: "AnalyticsInstallments";
  activeCount: number;
  pastDueCount: number;
  completedCount: number;
}

export interface PaymentPlansAnalytics_analytics {
  __typename: "Analytics";
  installments: PaymentPlansAnalytics_analytics_installments;
}

export interface PaymentPlansAnalytics {
  analytics: PaymentPlansAnalytics_analytics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlanSheet
// ====================================================

export interface PlanSheet_plan_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  code: string;
  quantity: number;
  value: number;
}

export interface PlanSheet_plan_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface PlanSheet_plan_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface PlanSheet_plan {
  __typename: "Plan";
  id: string;
  status: PlanStatus;
  name: string;
  minAge: number | null;
  maxAge: number | null;
  perio: boolean | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  familyDiscount: number | null;
  allTimeMemberCount: number;
  treatments: PlanSheet_plan_treatments[];
  showValue: boolean;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  discounts: PlanSheet_plan_discounts[];
  additionalTerms: string[];
  stripe: PlanSheet_plan_stripe | null;
}

export interface PlanSheet {
  plan: PlanSheet_plan;
}

export interface PlanSheetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePlan
// ====================================================

export interface UpdatePlan_updatePlan_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  code: string;
  quantity: number;
  value: number;
}

export interface UpdatePlan_updatePlan_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface UpdatePlan_updatePlan_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface UpdatePlan_updatePlan {
  __typename: "Plan";
  id: string;
  status: PlanStatus;
  name: string;
  minAge: number | null;
  maxAge: number | null;
  perio: boolean | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  familyDiscount: number | null;
  allTimeMemberCount: number;
  treatments: UpdatePlan_updatePlan_treatments[];
  showValue: boolean;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  discounts: UpdatePlan_updatePlan_discounts[];
  additionalTerms: string[];
  stripe: UpdatePlan_updatePlan_stripe | null;
}

export interface UpdatePlan {
  updatePlan: UpdatePlan_updatePlan;
}

export interface UpdatePlanVariables {
  id: string;
  name?: string | null;
  minAgeToggle?: boolean | null;
  minAge?: number | null;
  maxAgeToggle?: boolean | null;
  maxAge?: number | null;
  familyDiscountToggle?: boolean | null;
  familyDiscount?: number | null;
  perio?: boolean | null;
  annualPriceActive?: boolean | null;
  annualPrice?: number | null;
  monthlyPriceActive?: boolean | null;
  monthlyPrice?: number | null;
  treatments?: TreatmentInput[] | null;
  showValue?: boolean | null;
  globalDiscountActive?: boolean | null;
  globalDiscount?: number | null;
  discounts?: DiscountInput[] | null;
  additionalTerms?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetPlanStatus
// ====================================================

export interface SetPlanStatus_setPlanStatus_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface SetPlanStatus_setPlanStatus {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  stripe: SetPlanStatus_setPlanStatus_stripe | null;
}

export interface SetPlanStatus {
  setPlanStatus: SetPlanStatus_setPlanStatus;
}

export interface SetPlanStatusVariables {
  id: string;
  status: PlanStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePlan
// ====================================================

export interface DeletePlan_deletePlan {
  __typename: "Plan";
  id: string;
}

export interface DeletePlan {
  deletePlan: DeletePlan_deletePlan;
}

export interface DeletePlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyPlan
// ====================================================

export interface CopyPlan_copyPlan_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface CopyPlan_copyPlan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  stripe: CopyPlan_copyPlan_stripe | null;
}

export interface CopyPlan {
  copyPlan: CopyPlan_copyPlan;
}

export interface CopyPlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlanTemplates
// ====================================================

export interface PlanTemplates_planTemplates_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface PlanTemplates_planTemplates {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  stripe: PlanTemplates_planTemplates_stripe | null;
}

export interface PlanTemplates {
  planTemplates: PlanTemplates_planTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DraftPlans
// ====================================================

export interface DraftPlans_plans_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface DraftPlans_plans {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  stripe: DraftPlans_plans_stripe | null;
}

export interface DraftPlans {
  plans: DraftPlans_plans[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LivePlans
// ====================================================

export interface LivePlans_plans_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface LivePlans_plans {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  stripe: LivePlans_plans_stripe | null;
}

export interface LivePlans_account {
  __typename: "Account";
  id: string;
  programName: string;
}

export interface LivePlans {
  plans: LivePlans_plans[];
  account: LivePlans_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LegacyPlans
// ====================================================

export interface LegacyPlans_plans_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface LegacyPlans_plans {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  stripe: LegacyPlans_plans_stripe | null;
}

export interface LegacyPlans {
  plans: LegacyPlans_plans[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDraftFromTemplate
// ====================================================

export interface CreateDraftFromTemplate_createDraftFromTemplate_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface CreateDraftFromTemplate_createDraftFromTemplate {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  stripe: CreateDraftFromTemplate_createDraftFromTemplate_stripe | null;
}

export interface CreateDraftFromTemplate {
  createDraftFromTemplate: CreateDraftFromTemplate_createDraftFromTemplate;
}

export interface CreateDraftFromTemplateVariables {
  templateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePracticeName
// ====================================================

export interface UpdatePracticeName_updateAccount {
  __typename: "Account";
  id: string;
  name: string;
}

export interface UpdatePracticeName {
  updateAccount: UpdatePracticeName_updateAccount;
}

export interface UpdatePracticeNameVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProgramName
// ====================================================

export interface UpdateProgramName_updateAccount {
  __typename: "Account";
  id: string;
  programName: string;
}

export interface UpdateProgramName {
  updateAccount: UpdateProgramName_updateAccount;
}

export interface UpdateProgramNameVariables {
  programName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PromptsTable
// ====================================================

export interface PromptsTable_promptsConnection_edges_node_lastDeliveredNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
}

export interface PromptsTable_promptsConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface PromptsTable_promptsConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  name: PromptsTable_promptsConnection_edges_node_contact_name;
}

export interface PromptsTable_promptsConnection_edges_node_touchpoints {
  __typename: "Touchpoints";
  email: number;
  sms: number;
  letter: number;
}

export interface PromptsTable_promptsConnection_edges_node {
  __typename: "OutreachFlow";
  id: string;
  paidDate: number | null;
  value: number | null;
  lastDeliveredNotification: PromptsTable_promptsConnection_edges_node_lastDeliveredNotification | null;
  contact: PromptsTable_promptsConnection_edges_node_contact;
  touchpoints: PromptsTable_promptsConnection_edges_node_touchpoints | null;
}

export interface PromptsTable_promptsConnection_edges {
  __typename: "OutreachFlowEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: PromptsTable_promptsConnection_edges_node;
}

export interface PromptsTable_promptsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface PromptsTable_promptsConnection {
  __typename: "OutreachFlowConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: PromptsTable_promptsConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: PromptsTable_promptsConnection_pageInfo;
}

export interface PromptsTable {
  promptsConnection: PromptsTable_promptsConnection;
}

export interface PromptsTableVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RefundCharge
// ====================================================

export interface RefundCharge_refundCharge {
  __typename: "Charge";
  id: string;
  refundedAmount: number;
}

export interface RefundCharge {
  refundCharge: RefundCharge_refundCharge;
}

export interface RefundChargeVariables {
  chargeId: string;
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RenewalsAnalytics
// ====================================================

export interface RenewalsAnalytics_analytics_membership_subscriptions {
  __typename: "AnalyticsMembershipSubscriptions";
  autoRenewalRate: number;
  monthlyRenewalCount: number;
  monthlyCanceledCount: number;
}

export interface RenewalsAnalytics_analytics_membership {
  __typename: "AnalyticsMembership";
  subscriptions: RenewalsAnalytics_analytics_membership_subscriptions;
}

export interface RenewalsAnalytics_analytics {
  __typename: "Analytics";
  membership: RenewalsAnalytics_analytics_membership;
}

export interface RenewalsAnalytics {
  analytics: RenewalsAnalytics_analytics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupReportPath
// ====================================================

export interface GroupReportPath_group_latestReport {
  __typename: "Report";
  id: string;
  type: ReportType;
  path: string;
}

export interface GroupReportPath_group {
  __typename: "Group";
  id: string;
  latestReport: GroupReportPath_group_latestReport | null;
}

export interface GroupReportPath {
  group: GroupReportPath_group;
}

export interface GroupReportPathVariables {
  type: ReportType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountReportPath
// ====================================================

export interface AccountReportPath_account_latestReport {
  __typename: "Report";
  id: string;
  type: ReportType;
  path: string;
}

export interface AccountReportPath_account {
  __typename: "Account";
  id: string;
  latestReport: AccountReportPath_account_latestReport | null;
}

export interface AccountReportPath {
  account: AccountReportPath_account;
}

export interface AccountReportPathVariables {
  type: ReportType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RunReport
// ====================================================

export interface RunReport {
  runReport: boolean;
}

export interface RunReportVariables {
  groupId?: string | null;
  accountId?: string | null;
  lookback?: number | null;
  type: ReportType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReportSignedUrl
// ====================================================

export interface GetReportSignedUrl {
  generateFileSignedUrl: string;
}

export interface GetReportSignedUrlVariables {
  path: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetUserPassword
// ====================================================

export interface ResetUserPassword {
  sendUserResetPasswordEmail: boolean;
}

export interface ResetUserPasswordVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScheduledTable
// ====================================================

export interface ScheduledTable_account_billing_workflow {
  __typename: "Workflow";
  id: string;
  start_dailyLimit: number;
}

export interface ScheduledTable_account {
  __typename: "Account";
  id: string;
  billing_workflow: ScheduledTable_account_billing_workflow;
}

export interface ScheduledTable_balancesConnection_edges_node_guarantor_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface ScheduledTable_balancesConnection_edges_node_guarantor_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface ScheduledTable_balancesConnection_edges_node_guarantor_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface ScheduledTable_balancesConnection_edges_node_guarantor_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface ScheduledTable_balancesConnection_edges_node_guarantor_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface ScheduledTable_balancesConnection_edges_node_guarantor {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  email: string | null;
  phone: string | null;
  code: string;
  name: ScheduledTable_balancesConnection_edges_node_guarantor_name;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: ScheduledTable_balancesConnection_edges_node_guarantor_dob | null;
  latestEmailNotification: ScheduledTable_balancesConnection_edges_node_guarantor_latestEmailNotification | null;
  latestSMSNotification: ScheduledTable_balancesConnection_edges_node_guarantor_latestSMSNotification | null;
  latestLetterNotification: ScheduledTable_balancesConnection_edges_node_guarantor_latestLetterNotification | null;
}

export interface ScheduledTable_balancesConnection_edges_node {
  __typename: "Balance";
  id: string;
  patientPortion: number;
  insurancePortion: number;
  daysPastDue: number | null;
  dpdBucket: DPDBucket;
  guarantor: ScheduledTable_balancesConnection_edges_node_guarantor;
}

export interface ScheduledTable_balancesConnection_edges {
  __typename: "BalanceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: ScheduledTable_balancesConnection_edges_node;
}

export interface ScheduledTable_balancesConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface ScheduledTable_balancesConnection {
  __typename: "BalanceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: ScheduledTable_balancesConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: ScheduledTable_balancesConnection_pageInfo;
}

export interface ScheduledTable {
  account: ScheduledTable_account;
  balancesConnection: ScheduledTable_balancesConnection;
}

export interface ScheduledTableVariables {
  search?: string | null;
  contactIds?: string[] | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSendNotificationCard
// ====================================================

export interface GetSendNotificationCard_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface GetSendNotificationCard_contact_balance_metrics {
  __typename: "Balance";
  patientPortion: number;
}

export interface GetSendNotificationCard_contact_balance {
  __typename: "ContactBalance";
  metrics: GetSendNotificationCard_contact_balance_metrics;
}

export interface GetSendNotificationCard_contact_account_notificationTemplates {
  __typename: "NotificationTemplate";
  id: string;
  channel: NotificationChannel;
  layout: NotificationLayout;
  isBilling: boolean;
  name: string;
  body: string;
  subject: string | null;
  title: string | null;
  buttonLabel: string | null;
  buttonUrl: string | null;
  signature: string | null;
  createdAt: string;
}

export interface GetSendNotificationCard_contact_account_returnAddress_verifiedAddress {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
}

export interface GetSendNotificationCard_contact_account_returnAddress {
  __typename: "Address";
  id: string;
  verifiedAddress: GetSendNotificationCard_contact_account_returnAddress_verifiedAddress | null;
}

export interface GetSendNotificationCard_contact_account {
  __typename: "Account";
  id: string;
  name: string;
  code: string;
  programName: string;
  publicReplyPhone: string;
  notificationTemplates: GetSendNotificationCard_contact_account_notificationTemplates[];
  returnAddress: GetSendNotificationCard_contact_account_returnAddress;
}

export interface GetSendNotificationCard_contact_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface GetSendNotificationCard_contact_verifiedAddress {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
}

export interface GetSendNotificationCard_contact {
  __typename: "Contact";
  id: string;
  phone: string | null;
  email: string | null;
  code: string;
  name: GetSendNotificationCard_contact_name;
  balance: GetSendNotificationCard_contact_balance;
  account: GetSendNotificationCard_contact_account;
  address: GetSendNotificationCard_contact_address | null;
  verifiedAddress: GetSendNotificationCard_contact_verifiedAddress | null;
}

export interface GetSendNotificationCard_account {
  __typename: "Account";
  id: string;
}

export interface GetSendNotificationCard {
  contact: GetSendNotificationCard_contact;
  account: GetSendNotificationCard_account;
}

export interface GetSendNotificationCardVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliverEmailNotification
// ====================================================

export interface DeliverEmailNotification_deliverEmailNotification {
  __typename: "Notification";
  id: string;
}

export interface DeliverEmailNotification {
  deliverEmailNotification: DeliverEmailNotification_deliverEmailNotification;
}

export interface DeliverEmailNotificationVariables {
  contactId: string;
  templateId: string;
  subject: string;
  body: string;
  signature: string;
  buttonLabel?: string | null;
  buttonUrl?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliverSmsNotification
// ====================================================

export interface DeliverSmsNotification_deliverSmsNotification {
  __typename: "Notification";
  id: string;
}

export interface DeliverSmsNotification {
  deliverSmsNotification: DeliverSmsNotification_deliverSmsNotification;
}

export interface DeliverSmsNotificationVariables {
  contactId: string;
  templateId: string;
  body: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendLetter
// ====================================================

export interface SendLetter_sendLetter {
  __typename: "Notification";
  id: string;
  lob_url: string | null;
  estimatedAt: string;
}

export interface SendLetter {
  sendLetter: SendLetter_sendLetter;
}

export interface SendLetterVariables {
  contactId: string;
  templateId: string;
  title?: string | null;
  body: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Sidebar
// ====================================================

export interface Sidebar_balanceMetrics_engagement_outstanding_all_total {
  __typename: "BalanceCohort";
  count: number;
}

export interface Sidebar_balanceMetrics_engagement_outstanding_all {
  __typename: "BalanceStratification";
  total: Sidebar_balanceMetrics_engagement_outstanding_all_total;
}

export interface Sidebar_balanceMetrics_engagement_outstanding {
  __typename: "BalanceEngagementOutstanding";
  all: Sidebar_balanceMetrics_engagement_outstanding_all;
}

export interface Sidebar_balanceMetrics_engagement_scheduled_total {
  __typename: "BalanceCohort";
  count: number;
}

export interface Sidebar_balanceMetrics_engagement_scheduled {
  __typename: "BalanceStratification";
  total: Sidebar_balanceMetrics_engagement_scheduled_total;
}

export interface Sidebar_balanceMetrics_engagement_engaged_total {
  __typename: "BalanceCohort";
  count: number;
}

export interface Sidebar_balanceMetrics_engagement_engaged {
  __typename: "BalanceStratification";
  total: Sidebar_balanceMetrics_engagement_engaged_total;
}

export interface Sidebar_balanceMetrics_engagement_followUp_total {
  __typename: "BalanceCohort";
  count: number;
}

export interface Sidebar_balanceMetrics_engagement_followUp {
  __typename: "BalanceStratification";
  total: Sidebar_balanceMetrics_engagement_followUp_total;
}

export interface Sidebar_balanceMetrics_engagement_blocked_total {
  __typename: "BalanceCohort";
  count: number;
}

export interface Sidebar_balanceMetrics_engagement_blocked {
  __typename: "BalanceStratification";
  total: Sidebar_balanceMetrics_engagement_blocked_total;
}

export interface Sidebar_balanceMetrics_engagement {
  __typename: "BalanceEngagement";
  outstanding: Sidebar_balanceMetrics_engagement_outstanding;
  scheduled: Sidebar_balanceMetrics_engagement_scheduled;
  engaged: Sidebar_balanceMetrics_engagement_engaged;
  followUp: Sidebar_balanceMetrics_engagement_followUp;
  blocked: Sidebar_balanceMetrics_engagement_blocked;
}

export interface Sidebar_balanceMetrics {
  __typename: "Balances";
  engagement: Sidebar_balanceMetrics_engagement;
}

export interface Sidebar {
  balanceMetrics: Sidebar_balanceMetrics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmsTable
// ====================================================

export interface SmsTable_notificationsConnection_edges_node_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface SmsTable_notificationsConnection_edges_node_contact {
  __typename: "Contact";
  id: string;
  email: string | null;
  name: SmsTable_notificationsConnection_edges_node_contact_name;
}

export interface SmsTable_notificationsConnection_edges_node {
  __typename: "Notification";
  id: string;
  createdAt: string;
  updatedAt: string | null;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
  contact: SmsTable_notificationsConnection_edges_node_contact;
  templateName: string | null;
}

export interface SmsTable_notificationsConnection_edges {
  __typename: "NotificationEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: SmsTable_notificationsConnection_edges_node;
}

export interface SmsTable_notificationsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface SmsTable_notificationsConnection {
  __typename: "NotificationConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: SmsTable_notificationsConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: SmsTable_notificationsConnection_pageInfo;
}

export interface SmsTable {
  notificationsConnection: SmsTable_notificationsConnection;
}

export interface SmsTableVariables {
  search?: string | null;
  status?: NotificationStatus | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StartGuarantorOutreachCard
// ====================================================

export interface StartGuarantorOutreachCard_contact_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface StartGuarantorOutreachCard_contact {
  __typename: "Contact";
  id: string;
  name: StartGuarantorOutreachCard_contact_name;
}

export interface StartGuarantorOutreachCard {
  contact: StartGuarantorOutreachCard_contact;
}

export interface StartGuarantorOutreachCardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartGurantorFlowAction
// ====================================================

export interface StartGurantorFlowAction_startFlow {
  __typename: "Contact";
  id: string;
}

export interface StartGurantorFlowAction {
  startFlow: StartGurantorFlowAction_startFlow;
}

export interface StartGurantorFlowActionVariables {
  guarantorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectStripeAccount
// ====================================================

export interface ConnectStripeAccount_connectStripeAccount_stripe {
  __typename: "StripeAccountFields";
  id: string;
  loginLink: string;
  acceptPayments: boolean;
  bankTransfers: boolean;
  required: string | null;
  verifying: string | null;
}

export interface ConnectStripeAccount_connectStripeAccount {
  __typename: "Account";
  id: string;
  stripe: ConnectStripeAccount_connectStripeAccount_stripe | null;
}

export interface ConnectStripeAccount {
  connectStripeAccount: ConnectStripeAccount_connectStripeAccount;
}

export interface ConnectStripeAccountVariables {
  authorizationCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertSunbitConfiguration
// ====================================================

export interface UpsertSunbitConfiguration_upsertSunbitConfiguration_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface UpsertSunbitConfiguration_upsertSunbitConfiguration {
  __typename: "SunbitConfiguration";
  id: string;
  enabled: boolean;
  locationId: string;
  departmentId: string | null;
  representativeEmail: string | null;
  representativeFirstName: string | null;
  representativeLastName: string | null;
  balanceFilter: UpsertSunbitConfiguration_upsertSunbitConfiguration_balanceFilter;
}

export interface UpsertSunbitConfiguration {
  upsertSunbitConfiguration: UpsertSunbitConfiguration_upsertSunbitConfiguration;
}

export interface UpsertSunbitConfigurationVariables {
  id?: string | null;
  locationId: string;
  departmentId?: string | null;
  representativeEmail?: string | null;
  representativeFirstName?: string | null;
  representativeLastName?: string | null;
  balanceFilter: PartialBalanceFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendSunbitTextPrompt
// ====================================================

export interface SendSunbitTextPrompt {
  sendSunbitTextPrompt: boolean;
}

export interface SendSunbitTextPromptVariables {
  amount: number;
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleSunbit
// ====================================================

export interface ToggleSunbit_toggleSunbit {
  __typename: "SunbitConfiguration";
  id: string;
  enabled: boolean;
}

export interface ToggleSunbit {
  toggleSunbit: ToggleSunbit_toggleSunbit;
}

export interface ToggleSunbitVariables {
  enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SuppressedNoBalanceTable
// ====================================================

export interface SuppressedNoBalanceTable_patientsConnection_edges_node_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface SuppressedNoBalanceTable_patientsConnection_edges_node_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface SuppressedNoBalanceTable_patientsConnection_edges_node_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface SuppressedNoBalanceTable_patientsConnection_edges_node_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface SuppressedNoBalanceTable_patientsConnection_edges_node_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface SuppressedNoBalanceTable_patientsConnection_edges_node {
  __typename: "Contact";
  id: string;
  email: string | null;
  phone: string | null;
  name: SuppressedNoBalanceTable_patientsConnection_edges_node_name;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: SuppressedNoBalanceTable_patientsConnection_edges_node_dob | null;
  latestEmailNotification: SuppressedNoBalanceTable_patientsConnection_edges_node_latestEmailNotification | null;
  latestSMSNotification: SuppressedNoBalanceTable_patientsConnection_edges_node_latestSMSNotification | null;
  latestLetterNotification: SuppressedNoBalanceTable_patientsConnection_edges_node_latestLetterNotification | null;
}

export interface SuppressedNoBalanceTable_patientsConnection_edges {
  __typename: "ContactEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: SuppressedNoBalanceTable_patientsConnection_edges_node;
}

export interface SuppressedNoBalanceTable_patientsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface SuppressedNoBalanceTable_patientsConnection {
  __typename: "ContactConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: SuppressedNoBalanceTable_patientsConnection_edges[];
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: SuppressedNoBalanceTable_patientsConnection_pageInfo;
}

export interface SuppressedNoBalanceTable {
  patientsConnection: SuppressedNoBalanceTable_patientsConnection;
}

export interface SuppressedNoBalanceTableVariables {
  search?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTemplateSheet
// ====================================================

export interface GetTemplateSheet_account_returnAddress {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface GetTemplateSheet_account_checkAddress {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface GetTemplateSheet_account_exampleNotificationTemplate {
  __typename: "NotificationTemplate";
  id: string;
  channel: NotificationChannel;
  layout: NotificationLayout;
  name: string;
  body: string;
  title: string | null;
  subject: string | null;
  buttonLabel: string | null;
  buttonUrl: string | null;
  signature: string | null;
  createdAt: string;
}

export interface GetTemplateSheet_account {
  __typename: "Account";
  id: string;
  name: string;
  publicReplyPhone: string;
  returnAddress: GetTemplateSheet_account_returnAddress;
  checkAddress: GetTemplateSheet_account_checkAddress;
  exampleNotificationTemplate: GetTemplateSheet_account_exampleNotificationTemplate;
}

export interface GetTemplateSheet {
  account: GetTemplateSheet_account;
}

export interface GetTemplateSheetVariables {
  channel: NotificationChannel;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateNotificationTemplate
// ====================================================

export interface CreateNotificationTemplate_createNotificationTemplate {
  __typename: "NotificationTemplate";
  id: string;
  channel: NotificationChannel;
  layout: NotificationLayout;
  name: string;
  body: string;
  title: string | null;
  subject: string | null;
  buttonLabel: string | null;
  buttonUrl: string | null;
  signature: string | null;
  createdAt: string;
}

export interface CreateNotificationTemplate {
  createNotificationTemplate: CreateNotificationTemplate_createNotificationTemplate;
}

export interface CreateNotificationTemplateVariables {
  channel: NotificationChannel;
  layout: NotificationLayout;
  name: string;
  body: string;
  subject?: string | null;
  title?: string | null;
  buttonLabel?: string | null;
  buttonUrl?: string | null;
  signature?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateNotificationTemplate
// ====================================================

export interface UpdateNotificationTemplate_updateNotificationTemplate {
  __typename: "NotificationTemplate";
  id: string;
  channel: NotificationChannel;
  layout: NotificationLayout;
  name: string;
  body: string;
  title: string | null;
  subject: string | null;
  buttonLabel: string | null;
  buttonUrl: string | null;
  signature: string | null;
  createdAt: string;
}

export interface UpdateNotificationTemplate {
  updateNotificationTemplate: UpdateNotificationTemplate_updateNotificationTemplate;
}

export interface UpdateNotificationTemplateVariables {
  id: string;
  name: string;
  body: string;
  subject?: string | null;
  title?: string | null;
  buttonLabel?: string | null;
  buttonUrl?: string | null;
  signature?: string | null;
  layout: NotificationLayout;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface CreateUser_createUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: CreateUser_createUser_google;
  notify_enrolled: boolean;
  notify_paymentCompleted: boolean;
  notify_invoiceFailed: boolean;
  notify_eodPracticeReport: boolean;
}

export interface CreateUser {
  createUser: CreateUser_createUser;
}

export interface CreateUserVariables {
  email: string;
  groupId?: string | null;
  firstName: string;
  lastName: string;
  role: UserRole;
  notify_enrolled: boolean;
  notify_paymentCompleted: boolean;
  notify_invoiceFailed: boolean;
  notify_eodPracticeReport: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface UpdateUser_updateUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: UpdateUser_updateUser_google;
  notify_enrolled: boolean;
  notify_paymentCompleted: boolean;
  notify_invoiceFailed: boolean;
  notify_eodPracticeReport: boolean;
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser;
}

export interface UpdateUserVariables {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  notify_enrolled: boolean;
  notify_paymentCompleted: boolean;
  notify_invoiceFailed: boolean;
  notify_eodPracticeReport: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUser
// ====================================================

export interface DeleteUser_deleteUser {
  __typename: "User";
  id: string;
}

export interface DeleteUser {
  deleteUser: DeleteUser_deleteUser;
}

export interface DeleteUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserSheetResendInvite
// ====================================================

export interface UserSheetResendInvite_resendUserInvite {
  __typename: "User";
  id: string;
}

export interface UserSheetResendInvite {
  resendUserInvite: UserSheetResendInvite_resendUserInvite;
}

export interface UserSheetResendInviteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UsersTableResendInvite
// ====================================================

export interface UsersTableResendInvite_resendUserInvite {
  __typename: "User";
  id: string;
}

export interface UsersTableResendInvite {
  resendUserInvite: UsersTableResendInvite_resendUserInvite;
}

export interface UsersTableResendInviteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VerifyContactAddressCard
// ====================================================

export interface VerifyContactAddressCard_contact_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface VerifyContactAddressCard_contact_verifiedAddress {
  __typename: "LobAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface VerifyContactAddressCard_contact {
  __typename: "Contact";
  id: string;
  address: VerifyContactAddressCard_contact_address | null;
  verifiedAddress: VerifyContactAddressCard_contact_verifiedAddress | null;
}

export interface VerifyContactAddressCard {
  contact: VerifyContactAddressCard_contact;
}

export interface VerifyContactAddressCardVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyContactAddress
// ====================================================

export interface VerifyContactAddress_verifyContactAddress_verifiedAddress {
  __typename: "LobAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface VerifyContactAddress_verifyContactAddress {
  __typename: "Contact";
  id: string;
  isAddressVerified: boolean | null;
  verifiedAddress: VerifyContactAddress_verifyContactAddress_verifiedAddress | null;
}

export interface VerifyContactAddress {
  verifyContactAddress: VerifyContactAddress_verifyContactAddress;
}

export interface VerifyContactAddressVariables {
  contactId: string;
  street1: string;
  street2?: string | null;
  city: string;
  state: State;
  zip: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: contactFields
// ====================================================

export interface contactFields_name {
  __typename: "PatientName";
  first: string;
  last: string;
}

export interface contactFields_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface contactFields_address {
  __typename: "PatientAddress";
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface contactFields {
  __typename: "Contact";
  id: string;
  guarantorId: string | null;
  isGuarantor: boolean;
  email: string | null;
  phone: string | null;
  source: DataSource;
  isFirstFamilyMember: boolean;
  code: string;
  name: contactFields_name;
  perio: boolean;
  dob: contactFields_dob | null;
  address: contactFields_address | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: contactStripeFields
// ====================================================

export interface contactStripeFields_defaultSource {
  __typename: "StripeDefaultSource";
  id: string;
  isCredit: boolean;
  institution: string;
  last4: string;
  createdAt: number;
}

export interface contactStripeFields {
  __typename: "ContactStripeFields";
  customerId: string | null;
  defaultSource: contactStripeFields_defaultSource | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: installmentPlanFields
// ====================================================

export interface installmentPlanFields_scheduledPayments {
  __typename: "ScheduledPayment";
  id: string;
  amount: number;
  date: string;
}

export interface installmentPlanFields {
  __typename: "InstallmentPlan";
  id: string;
  guarantorId: string;
  status: InstallmentPlanStatus;
  startDate: string;
  faceAmount: number;
  adminFee: number;
  downPayment: number;
  periods: number;
  interval: number;
  intervalType: InstallmentIntervalType;
  scheduledPayments: installmentPlanFields_scheduledPayments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: successfulContactCharges
// ====================================================

export interface successfulContactCharges_charges {
  __typename: "Charge";
  id: string;
  date: number;
  balanceAmount: number;
  grossAmount: number;
  patientFee: number;
  refundedAmount: number;
  institution: string;
  last4: string;
}

export interface successfulContactCharges {
  __typename: "Contact";
  charges: successfulContactCharges_charges[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: contactBalanceMetrics
// ====================================================

export interface contactBalanceMetrics_metrics {
  __typename: "Balance";
  patientPortion: number;
  insurancePortion: number;
  writeOffEstimate: number;
}

export interface contactBalanceMetrics {
  __typename: "ContactBalance";
  metrics: contactBalanceMetrics_metrics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: accountAdminFields
// ====================================================

export interface accountAdminFields_returnAddress_verifiedAddress {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
}

export interface accountAdminFields_returnAddress {
  __typename: "Address";
  id: string;
  verifiedAddress: accountAdminFields_returnAddress_verifiedAddress | null;
}

export interface accountAdminFields_feeSchedule {
  __typename: "FeeSchedule";
  id: string;
  membership_practice_ach: number;
  membership_practice_cc: number;
  payments_practice_ach: number;
  payments_practice_cc: number;
  payments_practice_30: boolean;
  payments_patient_ach: number;
  payments_patient_cc: number;
  payments_patient_30: boolean;
  installments_practice_ach: number;
  installments_practice_cc: number;
}

export interface accountAdminFields {
  __typename: "Account";
  id: string;
  capabilities: AccountCapability[];
  status: AccountStatus;
  stripeId: string | null;
  stripe_customerId: string | null;
  sikka_id: string | null;
  sikka_key: string | null;
  sikka_areGuarantorsDistinct: boolean;
  sikka_isInsuranceArEstimated: boolean;
  sikka_incrementalTransactions: boolean;
  sikka_isIsolatedLocations: boolean;
  hubspot_customerId: string | null;
  syncSource: SyncSource;
  returnAddress: accountAdminFields_returnAddress;
  feeSchedule: accountAdminFields_feeSchedule;
  enableSync: boolean;
  enableOutreach: boolean;
  enablePayments: boolean;
  defaultBalanceType: BalanceType;
  claimDeniedDays: number;
  secondaryClaimDays: number;
  billing_minDaysSettled: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: locationFields
// ====================================================

export interface locationFields {
  __typename: "Location";
  id: string;
  name: string;
  pms_id: string;
  source: DataSource;
  enableSync: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: accountPortalFields
// ====================================================

export interface accountPortalFields {
  __typename: "Account";
  id: string;
  code: string;
  privateReplyPhone: string;
  publicReplyPhone: string;
  practicePortalSlug: string | null;
  enablePatientSelfCreation: boolean;
  hidePortalBalanceHistory: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: postingSettingsFields
// ====================================================

export interface postingSettingsFields {
  __typename: "PostingSettings";
  id: string;
  credit_payments: string | null;
  credit_installments: string | null;
  credit_membership: string | null;
  debit_payments: string | null;
  debit_installments: string | null;
  debit_membership: string | null;
  ach_payments: string | null;
  ach_installments: string | null;
  ach_membership: string | null;
  adjustment_code: string | null;
  enabled: boolean;
  smartAllocate: boolean;
  postingDate: PostingDate;
  postingAmount: PostingAmount;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: addressFields
// ====================================================

export interface addressFields {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: verifiedAddressFields
// ====================================================

export interface verifiedAddressFields {
  __typename: "LobAddress";
  /**
   * Lob Address ID
   */
  id: string;
  street1: string;
  street2: string | null;
  city: string;
  zip: string;
  state: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: balanceFields
// ====================================================

export interface balanceFields {
  __typename: "Balance";
  id: string;
  type: BalanceType;
  patientPortion: number;
  insurancePortion: number;
  writeOffEstimate: number;
  ledger_patientPortion: number;
  ledger_insurancePortion: number;
  summary_patientPortion: number;
  summary_insurancePortion: number;
  manual_patientPortion: number | null;
  manual_insurancePortion: number | null;
  installmentsPending: number;
  shadowBalance: number;
  daysPastDue: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: accountBlockingFields
// ====================================================

export interface accountBlockingFields {
  __typename: "Account";
  id: string;
  billing_restrictedInsuranceCompanies: string[];
  billing_restrictedTransactionCodes: string[];
  billing_restrictedTransactionCodeWindow: number;
  billing_restrictedPaymentPlanWindow: number | null;
  billing_restrictedBillingTypes: string[];
  billing_restrictedAgeMin: number | null;
  billing_restrictedAgeMax: number | null;
  billing_restrictedClaimStatuses: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: balanceFilterFields
// ====================================================

export interface balanceFilterFields {
  __typename: "BalanceFilter";
  id: string;
  restrictPending: boolean;
  minBalance: number;
  maxBalance: number;
  minDaysSinceLastVisit: number | null;
  maxDaysSinceLastVisit: number | null;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: workflowStartFields
// ====================================================

export interface workflowStartFields_start_emailTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface workflowStartFields_start_smsTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface workflowStartFields_start_letterTemplate {
  __typename: "NotificationTemplate";
  id: string;
}

export interface workflowStartFields {
  __typename: "Workflow";
  id: string;
  start_dailyLimit: number;
  start_emailTemplate: workflowStartFields_start_emailTemplate | null;
  start_smsTemplate: workflowStartFields_start_smsTemplate | null;
  start_letterTemplate: workflowStartFields_start_letterTemplate | null;
  start_reminder_buffer: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: workflowReminderFields
// ====================================================

export interface workflowReminderFields_reminder_emailTemplates {
  __typename: "NotificationTemplate";
  id: string;
}

export interface workflowReminderFields_reminder_smsTemplates {
  __typename: "NotificationTemplate";
  id: string;
}

export interface workflowReminderFields_reminder_letterTemplates_template {
  __typename: "NotificationTemplate";
  id: string;
}

export interface workflowReminderFields_reminder_letterTemplates {
  __typename: "LetterTemplateWithDelay";
  template: workflowReminderFields_reminder_letterTemplates_template;
  delay: number;
}

export interface workflowReminderFields {
  __typename: "Workflow";
  id: string;
  reminder_duration: number;
  reminder_cadence: number;
  reminder_emailTemplates: workflowReminderFields_reminder_emailTemplates[];
  reminder_smsTemplates: workflowReminderFields_reminder_smsTemplates[];
  reminder_letterTemplates: workflowReminderFields_reminder_letterTemplates[];
  reminder_final_buffer: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: workflowEndFields
// ====================================================

export interface workflowEndFields_final_emailTemplate {
  __typename: "NotificationTemplate";
  id: string;
  name: string;
}

export interface workflowEndFields_final_smsTemplate {
  __typename: "NotificationTemplate";
  id: string;
  name: string;
}

export interface workflowEndFields_final_letterTemplate {
  __typename: "NotificationTemplate";
  id: string;
  name: string;
}

export interface workflowEndFields {
  __typename: "Workflow";
  id: string;
  final_emailTemplate: workflowEndFields_final_emailTemplate | null;
  final_smsTemplate: workflowEndFields_final_smsTemplate | null;
  final_letterTemplate: workflowEndFields_final_letterTemplate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: contactIndicatorFields
// ====================================================

export interface contactIndicatorFields_dob {
  __typename: "PatientDob";
  day: number;
  month: number;
  year: number;
}

export interface contactIndicatorFields_latestEmailNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface contactIndicatorFields_latestSMSNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface contactIndicatorFields_latestLetterNotification {
  __typename: "Notification";
  id: string;
  createdAt: string;
  channel: NotificationChannel;
  status: NotificationStatus;
  failureReason: NotificationFailureReason | null;
}

export interface contactIndicatorFields {
  __typename: "Contact";
  id: string;
  hasAddress: boolean;
  isAddressVerified: boolean | null;
  email: string | null;
  phone: string | null;
  phone_smsEnabled: boolean | null;
  phone_consent: boolean | null;
  isPMS: boolean;
  pms_override: boolean;
  dob: contactIndicatorFields_dob | null;
  latestEmailNotification: contactIndicatorFields_latestEmailNotification | null;
  latestSMSNotification: contactIndicatorFields_latestSMSNotification | null;
  latestLetterNotification: contactIndicatorFields_latestLetterNotification | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: dentistFields
// ====================================================

export interface dentistFields {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FinancingProviderFields
// ====================================================

export interface FinancingProviderFields_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface FinancingProviderFields {
  __typename: "FinancingProvider";
  id: string;
  name: string;
  url: string;
  description: string;
  enabled: boolean;
  balanceFilter: FinancingProviderFields_balanceFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: bucketSummary
// ====================================================

export interface bucketSummary_sum {
  __typename: "BucketSummarySum";
  l30: any;
  l60: any;
  l90: any;
  g90: any;
  total: any;
}

export interface bucketSummary_count {
  __typename: "BucketSummaryCount";
  l30: number;
  l60: number;
  l90: number;
  g90: number;
  total: any;
}

export interface bucketSummary {
  __typename: "BucketSummary";
  sum: bucketSummary_sum;
  count: bucketSummary_count;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: timelineFields
// ====================================================

export interface timelineFields_flow_items {
  __typename: "OutreachTimelineAction";
  id: string;
  date: number;
  initiatedDate: number | null;
  type: OutreachTimelineActionType;
  templateName: string | null;
  url: string | null;
}

export interface timelineFields_flow {
  __typename: "OutreachTimelineFlow";
  id: string;
  type: OutreachFlowType;
  startDate: number;
  endDate: number | null;
  isManualEnd: boolean | null;
  endReason: OutreachFlowEndReason | null;
  isPrompted: boolean;
  items: timelineFields_flow_items[];
}

export interface timelineFields_singleAction {
  __typename: "OutreachTimelineAction";
  id: string;
  date: number;
  initiatedDate: number | null;
  type: OutreachTimelineActionType;
  templateName: string | null;
  url: string | null;
}

export interface timelineFields {
  __typename: "OutreachTimelineItem";
  id: string;
  type: OutreachTimelineItemType;
  flow: timelineFields_flow | null;
  singleAction: timelineFields_singleAction | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: balanceAgingFields
// ====================================================

export interface balanceAgingFields_l30 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface balanceAgingFields_l60 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface balanceAgingFields_l90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface balanceAgingFields_g90 {
  __typename: "BalanceCohort";
  sum: number;
}

export interface balanceAgingFields {
  __typename: "BalanceStratification";
  l30: balanceAgingFields_l30;
  l60: balanceAgingFields_l60;
  l90: balanceAgingFields_l90;
  g90: balanceAgingFields_g90;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InstallmentsTemplateFields
// ====================================================

export interface InstallmentsTemplateFields_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface InstallmentsTemplateFields {
  __typename: "InstallmentTemplate";
  id: string;
  name: string;
  interval: number;
  periods: number;
  intervalType: InstallmentIntervalType;
  enabled: boolean;
  balanceFilter: InstallmentsTemplateFields_balanceFilter;
  allowCC: boolean;
  allowACH: boolean;
  adminFee: number;
  downPayment: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: onboardingUserFields
// ====================================================

export interface onboardingUserFields {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: onboardingAccountFields
// ====================================================

export interface onboardingAccountFields_offices {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

export interface onboardingAccountFields {
  __typename: "Account";
  id: string;
  name: string;
  timezone: Timezone;
  phone: string;
  offices: onboardingAccountFields_offices[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: onboardingAddressFields
// ====================================================

export interface onboardingAddressFields {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: State;
  zip: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: onboardingDentistFields
// ====================================================

export interface onboardingDentistFields {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: inboundEventMetricsFields
// ====================================================

export interface inboundEventMetricsFields {
  __typename: "InboundEventMetrics";
  totalEvents: number;
  totalResolved: number;
  totalForwarded: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: balanceAgingCohortFields
// ====================================================

export interface balanceAgingCohortFields {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: balanceAgingStratificationFields
// ====================================================

export interface balanceAgingStratificationFields_total {
  __typename: "BalanceCohort";
  sum: number;
  count: number;
}

export interface balanceAgingStratificationFields_gt0 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields_gt100 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields_gt500 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields_gt1000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields_gt2000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields_gt5000 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields_l30 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields_l60 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields_l90 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields_g90 {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

export interface balanceAgingStratificationFields {
  __typename: "BalanceStratification";
  total: balanceAgingStratificationFields_total;
  gt0: balanceAgingStratificationFields_gt0;
  gt100: balanceAgingStratificationFields_gt100;
  gt500: balanceAgingStratificationFields_gt500;
  gt1000: balanceAgingStratificationFields_gt1000;
  gt2000: balanceAgingStratificationFields_gt2000;
  gt5000: balanceAgingStratificationFields_gt5000;
  l30: balanceAgingStratificationFields_l30;
  l60: balanceAgingStratificationFields_l60;
  l90: balanceAgingStratificationFields_l90;
  g90: balanceAgingStratificationFields_g90;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: balanceAssistantCohortFields
// ====================================================

export interface balanceAssistantCohortFields {
  __typename: "BalanceCohort";
  count: number;
  sum: number;
  contactIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: planSheetFields
// ====================================================

export interface planSheetFields_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  code: string;
  quantity: number;
  value: number;
}

export interface planSheetFields_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface planSheetFields_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface planSheetFields {
  __typename: "Plan";
  id: string;
  status: PlanStatus;
  name: string;
  minAge: number | null;
  maxAge: number | null;
  perio: boolean | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  familyDiscount: number | null;
  allTimeMemberCount: number;
  treatments: planSheetFields_treatments[];
  showValue: boolean;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  discounts: planSheetFields_discounts[];
  additionalTerms: string[];
  stripe: planSheetFields_stripe | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: planGridFields
// ====================================================

export interface planGridFields_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface planGridFields {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  stripe: planGridFields_stripe | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: sendNotificationTemplateFields
// ====================================================

export interface sendNotificationTemplateFields {
  __typename: "NotificationTemplate";
  id: string;
  channel: NotificationChannel;
  layout: NotificationLayout;
  isBilling: boolean;
  name: string;
  body: string;
  subject: string | null;
  title: string | null;
  buttonLabel: string | null;
  buttonUrl: string | null;
  signature: string | null;
  createdAt: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SunbitConfigurationFields
// ====================================================

export interface SunbitConfigurationFields_balanceFilter {
  __typename: "BalanceFilter";
  id: string;
  minBalance: number;
  maxBalance: number;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  allowG90: boolean;
}

export interface SunbitConfigurationFields {
  __typename: "SunbitConfiguration";
  id: string;
  enabled: boolean;
  locationId: string;
  departmentId: string | null;
  representativeEmail: string | null;
  representativeFirstName: string | null;
  representativeLastName: string | null;
  balanceFilter: SunbitConfigurationFields_balanceFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: notificationTemplateFields
// ====================================================

export interface notificationTemplateFields {
  __typename: "NotificationTemplate";
  id: string;
  channel: NotificationChannel;
  layout: NotificationLayout;
  name: string;
  body: string;
  title: string | null;
  subject: string | null;
  buttonLabel: string | null;
  buttonUrl: string | null;
  signature: string | null;
  createdAt: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: userFields
// ====================================================

export interface userFields_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface userFields {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: userFields_google;
  notify_enrolled: boolean;
  notify_paymentCompleted: boolean;
  notify_invoiceFailed: boolean;
  notify_eodPracticeReport: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountCapability {
  BILLING = "BILLING",
  CALL_FORWARDING = "CALL_FORWARDING",
  CHARGES = "CHARGES",
  INSTALLMENTS = "INSTALLMENTS",
  LETTERS = "LETTERS",
  MEMBERSHIP = "MEMBERSHIP",
  UPSELLS = "UPSELLS",
  WEB_PAY = "WEB_PAY",
  WRITEBACK = "WRITEBACK",
}

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  CHURNED = "CHURNED",
  INACTIVE = "INACTIVE",
  PILOT = "PILOT",
}

export enum BalanceBlockedReason {
  CONTACT_FAILED = "CONTACT_FAILED",
  CONTACT_MISSING = "CONTACT_MISSING",
  DOB_MISSING = "DOB_MISSING",
  PAYMENT_PLAN = "PAYMENT_PLAN",
  RESTRICTED_AGE = "RESTRICTED_AGE",
  RESTRICTED_BILLING_TYPE = "RESTRICTED_BILLING_TYPE",
  RESTRICTED_CLAIM_STATUS = "RESTRICTED_CLAIM_STATUS",
  RESTRICTED_CODE = "RESTRICTED_CODE",
  RESTRICTED_INSURANCE = "RESTRICTED_INSURANCE",
  SECONDARY_LOCATION = "SECONDARY_LOCATION",
  SUPPRESSED = "SUPPRESSED",
  SUPPRESSED_NO_BALANCE = "SUPPRESSED_NO_BALANCE",
}

export enum BalanceEngagementStatus {
  BLOCKED = "BLOCKED",
  ELIGIBLE = "ELIGIBLE",
  ENGAGED = "ENGAGED",
  FOLLOW_UP = "FOLLOW_UP",
  SCHEDULED = "SCHEDULED",
}

export enum BalanceType {
  LEDGER = "LEDGER",
  MANUAL = "MANUAL",
  SUMMARY = "SUMMARY",
  ZERO = "ZERO",
}

export enum ChargeStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
}

export enum DPDBucket {
  g90 = "g90",
  l30 = "l30",
  l60 = "l60",
  l90 = "l90",
}

export enum DataSource {
  LINK = "LINK",
  PEARLY = "PEARLY",
  RETAINED = "RETAINED",
  SIKKA = "SIKKA",
  UNKNOWN = "UNKNOWN",
}

export enum Degree {
  DDS = "DDS",
  DMD = "DMD",
  NONE = "NONE",
}

export enum DiscountCategory {
  ADJUNCTIVE_GENERAL_SERVICES = "ADJUNCTIVE_GENERAL_SERVICES",
  DIAGNOSTIC = "DIAGNOSTIC",
  ENDODONTICS = "ENDODONTICS",
  IMPLANT_SERVICES = "IMPLANT_SERVICES",
  MAXILLOFACIAL_PROSTHETICS = "MAXILLOFACIAL_PROSTHETICS",
  ORAL_AND_MAXILLOFACIAL_SURGERY = "ORAL_AND_MAXILLOFACIAL_SURGERY",
  ORTHODONTICS = "ORTHODONTICS",
  PERIODONTICS = "PERIODONTICS",
  PREVENTIVE = "PREVENTIVE",
  PROSTHODONTICS_FIXED = "PROSTHODONTICS_FIXED",
  PROSTHODONTICS_REMOVABLE = "PROSTHODONTICS_REMOVABLE",
  RESTORATIVE = "RESTORATIVE",
}

export enum InboundCallBehavior {
  DISABLED = "DISABLED",
  FORWARD = "FORWARD",
  GREETING = "GREETING",
}

export enum InstallmentIntervalType {
  HOUR = "HOUR",
  WEEK = "WEEK",
}

export enum InstallmentPlanStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  FAILED_PAUSED = "FAILED_PAUSED",
  FAILED_RETRYING = "FAILED_RETRYING",
  PAUSED = "PAUSED",
}

export enum InvoiceFilter {
  INSTALLMENTS = "INSTALLMENTS",
  MEMBERSHIP = "MEMBERSHIP",
  ONE_TIME_ACH = "ONE_TIME_ACH",
  PAYMENTS = "PAYMENTS",
  REFUNDS = "REFUNDS",
}

export enum InvoiceStatus {
  DELETED = "DELETED",
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  OTHER = "OTHER",
  PAID = "PAID",
  PAUSED = "PAUSED",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
  VOID = "VOID",
}

export enum Mirror {
  PEARLY = "PEARLY",
  PPO = "PPO",
}

export enum NotificationChannel {
  EMAIL = "EMAIL",
  LETTER = "LETTER",
  SMS = "SMS",
}

export enum NotificationFailureReason {
  CARRIER_FILTERED = "CARRIER_FILTERED",
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_PHONE = "INVALID_PHONE",
  LANDLINE = "LANDLINE",
  OPT_OUT = "OPT_OUT",
}

export enum NotificationLayout {
  CUSTOM = "CUSTOM",
  PAYMENT_QR = "PAYMENT_QR",
  PAYMENT_REQUEST = "PAYMENT_REQUEST",
}

export enum NotificationStatus {
  ATTEMPTED = "ATTEMPTED",
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED",
  FAILED = "FAILED",
  IN_PRODUCTION = "IN_PRODUCTION",
  MAILED = "MAILED",
  OPENED = "OPENED",
  PENDING = "PENDING",
  RETURNED = "RETURNED",
}

export enum OutreachFlowEndReason {
  BALANCE_CHANGED = "BALANCE_CHANGED",
  BLOCKED = "BLOCKED",
  DURATION_EXCEEDED = "DURATION_EXCEEDED",
  LEGACY = "LEGACY",
  PENDING_INSURANCE = "PENDING_INSURANCE",
  STOPPED = "STOPPED",
  SUPERSEDED = "SUPERSEDED",
}

export enum OutreachFlowType {
  BILLING = "BILLING",
  MANUAL = "MANUAL",
}

export enum OutreachTimelineActionType {
  EMAIL_ATTEMPTED = "EMAIL_ATTEMPTED",
  EMAIL_DELIVERED = "EMAIL_DELIVERED",
  EMAIL_FAILED = "EMAIL_FAILED",
  LETTER_DELIVERED = "LETTER_DELIVERED",
  LETTER_IN_PRODUCTION = "LETTER_IN_PRODUCTION",
  LETTER_MAILED = "LETTER_MAILED",
  LETTER_PENDING = "LETTER_PENDING",
  LETTER_RETURNED = "LETTER_RETURNED",
  PAYMENT = "PAYMENT",
  SMS_ATTEMPTED = "SMS_ATTEMPTED",
  SMS_DELIVERED = "SMS_DELIVERED",
  SMS_FAILED = "SMS_FAILED",
}

export enum OutreachTimelineItemType {
  FLOW = "FLOW",
  SINGLE_ACTION = "SINGLE_ACTION",
}

export enum PatientFilter {
  MEMBER = "MEMBER",
  RENEWAL = "RENEWAL",
  SUPPRESSED_NO_BALANCE = "SUPPRESSED_NO_BALANCE",
}

export enum PlanStatus {
  DRAFT = "DRAFT",
  LEGACY = "LEGACY",
  LIVE = "LIVE",
  TEMPLATE = "TEMPLATE",
}

export enum PlanTerm {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
}

export enum PostingAmount {
  GROSS = "GROSS",
  NET = "NET",
  NET_PLUS_ADJUSTMENT = "NET_PLUS_ADJUSTMENT",
}

export enum PostingDate {
  DEPOSIT_DATE = "DEPOSIT_DATE",
  DEPOSIT_DATE_PLUS_ONE = "DEPOSIT_DATE_PLUS_ONE",
  DEPOSIT_DATE_PLUS_TWO = "DEPOSIT_DATE_PLUS_TWO",
  PAID_DATE = "PAID_DATE",
}

export enum ReportType {
  ACTIVE_MEMBERS = "ACTIVE_MEMBERS",
  CARD_ON_FILE = "CARD_ON_FILE",
  COLLECTION_RECS = "COLLECTION_RECS",
  GENERAL = "GENERAL",
  OFFICE_LIST = "OFFICE_LIST",
  OPT_OUT = "OPT_OUT",
  PAYMENT_PLANS = "PAYMENT_PLANS",
}

export enum Salutation {
  Dr = "Dr",
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
}

export enum ScheduledPaymentStatus {
  CANCELED = "CANCELED",
  FAILED_PAUSED = "FAILED_PAUSED",
  FAILED_RETRYING = "FAILED_RETRYING",
  PAUSED = "PAUSED",
  SUCCESS = "SUCCESS",
  UPCOMING = "UPCOMING",
}

export enum SiteColor {
  BLUE = "BLUE",
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  RED = "RED",
}

export enum SiteImage {
  CHILDREN = "CHILDREN",
  COUPLE = "COUPLE",
  MAN = "MAN",
  WOMAN = "WOMAN",
}

export enum State {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AS = "AS",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  GU = "GU",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UM = "UM",
  UT = "UT",
  VA = "VA",
  VI = "VI",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
  XX = "XX",
}

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  INCOMPLETE = "INCOMPLETE",
  PAST_DUE = "PAST_DUE",
  PAUSED = "PAUSED",
}

export enum SyncSource {
  SIKKA = "SIKKA",
  SIKKA_LEGACY = "SIKKA_LEGACY",
}

export enum Timezone {
  ALASKA = "ALASKA",
  ARIZONA = "ARIZONA",
  CENTRAL = "CENTRAL",
  EASTERN = "EASTERN",
  HAWAII = "HAWAII",
  MOUNTAIN = "MOUNTAIN",
  PACIFIC = "PACIFIC",
}

export enum TransactionType {
  ADJUSTMENT = "ADJUSTMENT",
  CLAIM_DENIED = "CLAIM_DENIED",
  COLLECTION_ADJUSTMENT = "COLLECTION_ADJUSTMENT",
  INSURANCE_PAYMENT = "INSURANCE_PAYMENT",
  OTHER_ADJUSTMENT = "OTHER_ADJUSTMENT",
  PAYMENT = "PAYMENT",
  PEARLY_ADJUSTMENT = "PEARLY_ADJUSTMENT",
  PEARLY_ADMIN_FEE = "PEARLY_ADMIN_FEE",
  PEARLY_BILL = "PEARLY_BILL",
  PEARLY_MEMBERSHIP = "PEARLY_MEMBERSHIP",
  PEARLY_PAYMENT = "PEARLY_PAYMENT",
  PEARLY_PLAN = "PEARLY_PLAN",
  PEARLY_REFUND = "PEARLY_REFUND",
  PROCEDURE = "PROCEDURE",
}

export enum TransactionWritebackStatus {
  FAILED = "FAILED",
  INITIALIZED = "INITIALIZED",
  PARTIALLY_FAILED = "PARTIALLY_FAILED",
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  SUCCEEDED_SOME_UNALLOCATED = "SUCCEEDED_SOME_UNALLOCATED",
}

export enum UserRole {
  ADMIN = "ADMIN",
  BASIC = "BASIC",
  STANDARD = "STANDARD",
  SUPERADMIN = "SUPERADMIN",
}

export enum WidgetIcon {
  MEDAL = "MEDAL",
  NONE = "NONE",
}

export enum WritebackStatus {
  FAILED = "FAILED",
  INITIALIZED = "INITIALIZED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export enum WritebackType {
  ADJUSTMENT = "ADJUSTMENT",
  PAYMENT = "PAYMENT",
}

export interface CreatePatientProfileInput {
  city?: string | null;
  dobDay?: number | null;
  dobMonth?: number | null;
  dobYear?: number | null;
  firstName: string;
  lastName: string;
  perio?: boolean | null;
  state?: State | null;
  street1?: string | null;
  street2?: string | null;
  zip?: string | null;
}

export interface DiscountInput {
  category: DiscountCategory;
  value: number;
}

export interface FeeScheduleInput {
  installments_practice_ach: number;
  installments_practice_cc: number;
  membership_practice_ach: number;
  membership_practice_cc: number;
  payments_patient_30: boolean;
  payments_patient_ach: number;
  payments_patient_cc: number;
  payments_practice_30: boolean;
  payments_practice_ach: number;
  payments_practice_cc: number;
}

export interface FullBalanceFilterInput {
  allowG90: boolean;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  maxBalance: number;
  maxDaysSinceLastVisit: number;
  minBalance: number;
  minDaysSinceLastVisit: number;
  restrictPending: boolean;
}

export interface LetterReminderTemplateInput {
  delay: number;
  templateId: string;
}

export interface LocationInput {
  enableSync: boolean;
  id: string;
}

export interface PartialBalanceFilterInput {
  allowG90: boolean;
  allowL30: boolean;
  allowL60: boolean;
  allowL90: boolean;
  maxBalance: number;
  minBalance: number;
}

export interface PostingSettingsInput {
  ach_installments?: string | null;
  ach_membership?: string | null;
  ach_payments?: string | null;
  adjustment_code?: string | null;
  credit_installments?: string | null;
  credit_membership?: string | null;
  credit_payments?: string | null;
  debit_installments?: string | null;
  debit_membership?: string | null;
  debit_payments?: string | null;
  postingAmount: PostingAmount;
  postingDate: PostingDate;
  smartAllocate: boolean;
}

export interface TreatmentInput {
  code: string;
  name: string;
  quantity: number;
  value: number;
}

export interface UpdatePatientProfileInput {
  city?: string | null;
  dobDay?: number | null;
  dobMonth?: number | null;
  dobYear?: number | null;
  firstName: string;
  lastName: string;
  perio?: boolean | null;
  state?: State | null;
  street1?: string | null;
  street2?: string | null;
  zip?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
