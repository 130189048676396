import styled from 'styled-components/macro'

import { useMutation } from '@apollo/client'
import { UPSERT_FINANCING_PROVIDER, DELETE_FINANCING_PROVIDER } from 'graphql/_financing-provider-sheet'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Label, Text, Heading, toaster, Strong } from 'evergreen-ui'
import { Types, Card, CardHeader, Button, FormError, TextInputField, TextInput, Textarea, colors, urlRegex } from 'lib'

import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'
import FinancingProviderToggle from 'components/financing-provider-toggle'
import BucketSelector from 'components/bucket-selector'
import { useState } from 'react'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  financingProvider?: Types.PayOverTimeFinancing_account_financingProviders
}

const FinancingProviderSheet = ({ isShown, setIsShown, financingProvider: provider }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const global = useGlobal()

  const [upsertProvider, upsertStatus] = useMutation<
    Types.UpsertFinancingProvider,
    Types.UpsertFinancingProviderVariables
  >(UPSERT_FINANCING_PROVIDER, {
    update: (cache, { data }) => {
      // return if performing update (Apollo automatically updates cache)
      if (provider || !data) return

      cache.modify({
        id: cache.identify({ id: global.account.id, __typename: 'Account' }),
        fields: {
          financingProviders(cachedFinancingProviders) {
            return [
              ...cachedFinancingProviders,
              { __ref: cache.identify({ id: data.upsertFinancingProvider.id, __typename: 'FinancingProvider' }) }
            ]
          }
        }
      })
    },
    onCompleted: () => {
      toaster.success(`Financing provider successfully ${provider ? 'updated' : 'created'}!`)
      if (!provider) setIsShown(false)
    },
    onError: () => toaster.danger(`Unable to ${provider ? 'update' : 'create'} financing provider`)
  })

  const [deleteProvider, deleteStatus] = useMutation<
    Types.DeleteFinancingProvider,
    Types.DeleteFinancingProviderVariables
  >(DELETE_FINANCING_PROVIDER, {
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          id: cache.identify({ id: global.account.id, __typename: 'Account' }),
          fields: {
            financingProviders(cachedFinancingProviders) {
              return cachedFinancingProviders.filter(
                ({ __ref }: { __ref: string }) =>
                  __ref !== cache.identify({ id: data.deleteFinancingProvider.id, __typename: 'FinancingProvider' })
              )
            }
          }
        })

        cache.gc()
      }
    },
    onCompleted: () => {
      setIsShown(false)
      toaster.success(`Financing provider successfully deleted!`)
    },
    onError: () => toaster.danger('Unable to delete financing provider')
  })

  const [bucketState, setBucketState] = useState({
    allowL30: provider ? provider.balanceFilter.allowL30 : true,
    allowL60: provider ? provider.balanceFilter.allowL60 : true,
    allowL90: provider ? provider.balanceFilter.allowL90 : true,
    allowG90: provider ? provider.balanceFilter.allowG90 : true
  })

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      <Formik
        initialValues={
          provider
            ? {
                ...provider,
                maxBalance: provider.balanceFilter.maxBalance / 100,
                minBalance: provider.balanceFilter.minBalance / 100
              }
            : {
                name: '',
                url: '',
                description: '',
                minBalance: ('' as unknown) as number,
                maxBalance: ('' as unknown) as number
              }
        }
        onSubmit={({ ...fields }) => {
          showConfirmDialog({
            body: `Are you sure you want to ${provider ? 'update' : 'create'} this financing provider?`,
            onConfirm: () => {
              upsertProvider({
                variables: {
                  id: provider ? provider.id : null,
                  ...fields,
                  balanceFilter: {
                    maxBalance: fields.maxBalance * 100,
                    minBalance: fields.minBalance * 100,
                    ...bucketState
                  }
                }
              })
            }
          })
        }}
        validationSchema={Yup.object({
          name: Yup.string().min(4, 'Name must be at least 4 characters').required('Name is required'),
          url: Yup.string().matches(urlRegex, 'Please enter a valid URL'),
          description: Yup.string().required('Description is required'),

          minBalance: Yup.number().min(0, 'Field must be 0 or greater').required('Required field is missing'),
          maxBalance: Yup.number().min(0, 'Field must be 0 or greater').required('Required field is missing')
        })}
      >
        {({ values }) => (
          <Form style={{ height: '100%' }}>
            <SheetLayout>
              <CardHeader justifyContent="space-between" alignItems="center" overflow="hidden">
                <Pane maxWidth={provider ? '254px' : undefined}>
                  <Label>3rd Party Financing</Label>
                  <Heading size={600} height={24} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    {values.name}
                  </Heading>
                </Pane>
                {provider && <FinancingProviderToggle financingProvider={provider} />}
              </CardHeader>
              <Pane gridArea="body" overflow="auto" background="blueTint">
                <Pane display="grid" gap={16} padding={16}>
                  <Card backgroundColor="white" elevation={0} padding={0}>
                    <CardHeader>
                      <Heading>Provider Details</Heading>
                    </CardHeader>
                    <Pane padding={24}>
                      <TextInputField name="name" label="Display Name (shown to patients at checkout)" height={40} />
                      <TextInputField name="url" label="Prequalification URL (external page to apply)" height={40} />
                      <Label marginBottom={4}>Description (shown before leaving pay portal)</Label>
                      <Textarea name="description" marginBottom={0} />
                    </Pane>
                  </Card>
                  <Card backgroundColor="white" elevation={0} padding={0}>
                    <CardHeader>
                      <Heading>Pay Portal Defaults</Heading>
                    </CardHeader>
                    <Pane padding={16} borderBottom={`solid 1px ${colors.border.muted}`}>
                      <Text>
                        By default, show this link to patients with balances satisfying the below criteria. Can be
                        adjusted on a patient-by-patient basis.
                      </Text>
                    </Pane>
                    <Pane padding={16} display="grid" gap={6}>
                      <Heading size={300} color={colors.blue.base} textTransform="uppercase" marginBottom={4}>
                        Show this link if...
                      </Heading>
                      <Pane display="flex" alignItems="center" justifyContent="space-between">
                        <Pane display="flex">
                          <Strong size={500} marginRight={4} width={120}>
                            Patient Balance
                          </Strong>
                          <Text size={500}>between </Text>
                        </Pane>
                        <Pane display="flex" alignItems="center">
                          <TextInput name="minBalance" type="number" width={52} height={32} marginBottom={0} />
                          <Text size={500} display="inline-block" marginX={8}>
                            -
                          </Text>
                          <TextInput name="maxBalance" type="number" width={52} height={32} marginBottom={0} />
                        </Pane>
                      </Pane>
                      <Heading size={300} color={colors.blue.base} marginBottom={4}>
                        AND
                      </Heading>
                      <Pane display="flex" flexDirection="column" justifyContent="space-between" rowGap={16}>
                        <Pane display="flex" alignItems="center">
                          <Strong size={500} marginRight={4}>
                            Days Past Due
                          </Strong>
                          <Text size={500}>bucket includes:</Text>
                        </Pane>
                        <BucketSelector state={bucketState} onSelect={setBucketState} />
                      </Pane>

                      <FormError marginTop={0} />
                    </Pane>
                  </Card>
                </Pane>
              </Pane>
              <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
                {provider ? (
                  <Pane display="flex" justifyContent="space-between">
                    <Button
                      isLoading={deleteStatus.loading}
                      disabled={upsertStatus.loading}
                      onClick={() => {
                        showConfirmDialog({
                          body: 'Are you sure you want to delete this financing provider?',
                          intent: 'danger',
                          onConfirm: () => deleteProvider({ variables: { id: provider.id } })
                        })
                      }}
                      appearance="minimal"
                      intent="danger"
                      height={48}
                      justifyContent="center"
                    >
                      Delete
                    </Button>
                    <Button
                      isLoading={upsertStatus.loading}
                      disabled={deleteStatus.loading}
                      type="submit"
                      appearance="primary"
                      height={48}
                      justifyContent="center"
                    >
                      Save
                    </Button>
                  </Pane>
                ) : (
                  <Button
                    isLoading={upsertStatus.loading}
                    type="submit"
                    appearance="primary"
                    height={48}
                    width="100%"
                    justifyContent="center"
                    iconBefore={['fas', 'plus']}
                  >
                    Add Financing Provider
                  </Button>
                )}
              </Pane>
            </SheetLayout>
          </Form>
        )}
      </Formik>
    </SideSheet>
  )
}

export default FinancingProviderSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
