import styled from 'styled-components/macro'

import { useMutation } from '@apollo/client'
import { UPSERT_ADDRESS, DELETE_ADDRESS, GET_OFFICES, VERIFY_ACCOUNT_RETURN_ADDRESS } from 'graphql/_address-sheet'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Heading, toaster, Alert } from 'evergreen-ui'
import { Types, Card, CardHeader, Button, FormError, Icon, Divider } from 'lib'

import AddressFields from 'components/_fields/address-fields'
import { useModal } from 'components/modal-provider'
import VerifiedAddressCard from 'components/verified-address-card'

import { useState } from 'react'

type VerifiedAddress = { id: string; street1: string; street2: string | null; city: string; state: string; zip: string }

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  address?: {
    id: string
    name: string
    street1: string
    street2: string | null
    city: string
    state: Types.State
    zip: string
    verifiedAddress?: VerifiedAddress | null
  } | null
  isReturnAddress?: boolean
  disableDelete?: boolean
}

const AddressSheet = ({ isShown, setIsShown, address, disableDelete, isReturnAddress = false }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [verificationState, setVerificationState] = useState<VerifiedAddress | null>(address?.verifiedAddress ?? null)

  const [upsertAddress, upsertStatus] = useMutation<Types.UpsertAddress, Types.UpsertAddressVariables>(UPSERT_ADDRESS, {
    update: (cache, { data }) => {
      //  Insert
      if (!address) {
        if (!data) throw Error('Address upsert data missing')
        const cachedData = cache.readQuery<Types.GetOffices>({ query: GET_OFFICES })
        if (cachedData) {
          cache.writeQuery({
            query: GET_OFFICES,
            data: {
              account: {
                ...cachedData.account,
                offices: cachedData.account.offices.concat([data.upsertAddress])
              }
            }
          })
        }
      } else {
        cache.evict({ id: cache.identify({ id: address.id, __typename: 'Address' }), fieldName: 'verifiedAddress' })
      }
    },
    onCompleted: () => {
      toaster.success(`Address successfully ${address ? 'updated' : 'created'}!`)
      setVerificationState(null)
      if (!address) setIsShown(false)
    },
    onError: () => toaster.danger(`Unable to ${address ? 'update' : 'create'} address`)
  })

  const [deleteAddress, deleteStatus] = useMutation<Types.DeleteAddress, Types.DeleteAddressVariables>(DELETE_ADDRESS, {
    variables: { id: address?.id ?? '' },
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetOffices>({ query: GET_OFFICES })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_OFFICES,
          data: {
            account: {
              ...cachedData.account,
              offices: cachedData.account.offices.filter((address) => address.id !== data.deleteAddress.id)
            }
          }
        })
      }
    },
    onCompleted: () => {
      setIsShown(false)
      toaster.success(`Address successfully deleted!`)
    },
    onError: () => toaster.danger('Unable to delete address')
  })

  const [verify, verifyStatus] = useMutation<Types.VerifyAccountReturnAddress>(VERIFY_ACCOUNT_RETURN_ADDRESS, {
    onCompleted: (data) => {
      if (data.verifyAndSaveAccountReturnAddress) setVerificationState(data.verifyAndSaveAccountReturnAddress)
      else toaster.danger('Unable to verify address')
    },
    update: (cache, { data }) => {
      if (cache && data && address) {
        cache.modify({
          id: cache.identify({ id: address.id, __typename: 'Address' }),
          fields: {
            verifiedAddress() {
              return data.verifyAndSaveAccountReturnAddress
            }
          }
        })
      }
    }
  })

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      <Formik
        initialValues={
          address ? { ...address } : { name: '', street1: '', street2: '', city: '', state: '' as Types.State, zip: '' }
        }
        onSubmit={(values, { resetForm }) => {
          const { street2, ...addressFields } = values
          showConfirmDialog({
            body: `Are you sure you want to ${address ? 'update' : 'create'} this address?`,
            onConfirm: async () => {
              await upsertAddress({
                variables: {
                  id: address ? address.id : null,
                  street2: street2 ?? null,
                  ...addressFields
                }
              })
              // Clears dirty status
              resetForm({ values })
            }
          })
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Name is required'),
          street1: Yup.string().required('Address is required'),
          street2: Yup.string().nullable(),
          city: Yup.string().required('City is required'),
          state: Yup.mixed()
            .required('State is required')
            .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation)'),
          zip: Yup.string()
            .required('Zip is required')
            .matches(/^\d{5}(-\d{4})?$/, 'Please enter a valid zip code (5 digits or 9 digits)')
        })}
      >
        {({ dirty }) => (
          <Form style={{ height: '100%' }}>
            <SheetLayout>
              {address ? (
                <CardHeader gridArea="header">
                  <Icon icon={['fad', 'map-marked-alt']} size="2x" color="default" marginLeft={4} />
                  <Pane marginLeft={16}>
                    <Heading size={600}>{address.name}</Heading>
                    <Paragraph size={400}>Address Details</Paragraph>
                  </Pane>
                </CardHeader>
              ) : (
                <CardHeader gridArea="header">
                  <Icon icon={['fad', 'map-marked-alt']} size="2x" color="default" paddingLeft={4} />
                  <Pane marginLeft={16}>
                    <Heading size={600}>Add Address</Heading>
                    <Paragraph size={400}>This address will be shown on your landing page</Paragraph>
                  </Pane>
                </CardHeader>
              )}

              <Pane gridArea="body" overflow="auto" background="blueTint">
                <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                  <AddressFields />
                  <FormError collapseSpace />

                  {isReturnAddress && (
                    <>
                      <Divider label="Verification" marginTop={32} />

                      {dirty ? (
                        <Alert children="Please save address details prior to verification" />
                      ) : verificationState ? (
                        <VerifiedAddressCard address={verificationState} />
                      ) : (
                        <Button
                          onClick={() => verify()}
                          flexGrow={1}
                          appearance="default"
                          justifyContent="center"
                          intent="success"
                          height={48}
                          width="100%"
                          iconBefore={['fas', 'location-check']}
                          isLoading={verifyStatus.loading}
                        >
                          Verify with USPS CASS
                        </Button>
                      )}
                    </>
                  )}
                </Card>
              </Pane>
              <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
                {address ? (
                  <Pane display="flex" justifyContent={disableDelete ? 'flex-end' : 'space-between'}>
                    {!disableDelete && (
                      <Button
                        isLoading={deleteStatus.loading || !!deleteStatus.data}
                        visibility={upsertStatus.loading ? 'hidden' : 'visible'}
                        onClick={() => {
                          showConfirmDialog({
                            body: 'Are you sure you want to delete this address?',
                            onConfirm: () => deleteAddress(),
                            intent: 'danger'
                          })
                        }}
                        appearance="minimal"
                        intent="danger"
                        height={48}
                        justifyContent="center"
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      autoFocus
                      isLoading={upsertStatus.loading}
                      visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                      type="submit"
                      appearance="primary"
                      height={48}
                      justifyContent="center"
                      disabled={!dirty}
                    >
                      Save
                    </Button>
                  </Pane>
                ) : (
                  <Button
                    isLoading={upsertStatus.loading || !!upsertStatus.data}
                    type="submit"
                    appearance="primary"
                    height={48}
                    width="100%"
                    justifyContent="center"
                    iconBefore={['fas', 'plus']}
                  >
                    Add Address
                  </Button>
                )}
              </Pane>
            </SheetLayout>
          </Form>
        )}
      </Formik>
    </SideSheet>
  )
}

export default AddressSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
