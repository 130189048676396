import { useLazyQuery, useApolloClient } from '@apollo/client'
import { GET_COUPON } from 'lib/graphql/_coupon-card'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, toaster, CardProps, Badge, Elevation } from 'evergreen-ui'

import { Types, Card } from 'lib'

import { TextInputFieldWithButton } from 'lib'

export type Props = CardProps & {
  accountId?: string
  elevation?: Elevation
  onRetrieve: (coupon: Types.Coupon) => void
}

const CouponField = ({ accountId, onRetrieve, elevation = 0, ...props }: Props) => {
  const apolloClient = useApolloClient()
  const [getCoupon, { loading, data }] = useLazyQuery<Types.GetCoupon, Types.GetCouponVariables>(GET_COUPON, {
    fetchPolicy: 'network-only',
    client: apolloClient,
    onCompleted: (data) => {
      onRetrieve(data.coupon)
      toaster.success(`Coupon successfully applied`)
    },
    onError: () => toaster.danger('Coupon not found')
  })

  return (
    <Card elevation={elevation} padding={0} overflow="hidden" {...props}>
      <Pane padding={16} display="flex" flexDirection="column" alignItems="center">
        <Formik
          initialValues={{ coupon: '' }}
          onSubmit={({ coupon }) => getCoupon({ variables: { code: coupon, accountId } })}
          validationSchema={Yup.object({
            coupon: Yup.string().required()
          })}
        >
          <Form style={{ height: '100%', width: '100%' }}>
            <TextInputFieldWithButton
              isButtonLoading={loading}
              height={40}
              label="Apply"
              name="coupon"
              placeholder="Promotion code..."
              width="100%"
              marginBottom={0}
              boxShadow="inset 0 0 0 1px rgba(67, 90, 111, 0.15), inset 0 1px 2px rgba(67, 90, 111, 0.07) !important"
            />
          </Form>
        </Formik>
        {data?.coupon?.valid && (
          <Badge color="green" marginTop={16} paddingX={16}>
            Coupon Applied: {data.coupon.percent_off}% Off
          </Badge>
        )}
      </Pane>
    </Card>
  )
}

export default CouponField
