import { forwardRef } from 'react'

import { Tooltip, PositionTypes } from 'evergreen-ui'

import { Icon, IconProps, colors } from 'lib'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = Omit<IconProps, 'icon' | 'position'> & {
  content: React.ReactNode | string
  icon?: IconProp
  position?: PositionTypes
  width?: number | string
  iconPosition?: IconProps['position']
}

const InfoTooltip = forwardRef<HTMLDivElement, Props>(
  (
    {
      content,
      icon,
      color = colors.neutral.muted,
      size = undefined,
      width,
      position = 'bottom',
      iconPosition,
      ...props
    },
    ref
  ) => {
    if (!icon) icon = ['fas', 'info-circle']

    return (
      <Tooltip content={content} position={position} statelessProps={{ minWidth: width }}>
        <Icon
          icon={icon}
          color={color}
          position={iconPosition}
          marginLeft={8}
          size={size}
          cursor="help"
          {...props}
          ref={ref}
        />
      </Tooltip>
    )
  }
)

export default InfoTooltip
