import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import { Pane, Heading, Alert, Text, Avatar, Strong } from 'evergreen-ui'
import { Types, Icon, colors, toDollarsFromCents } from 'lib'
import { useModal } from 'components/modal-provider'

export type Props = {
  count: number
  total: number
  connection: Types.HomeDashboard_promptsConnection
}

const Prompted = ({ count, total, connection }: Props) => {
  const history = useHistory()
  const showGuarantorSheet = useModal('guarantor')

  return (
    <Alert
      hasIcon={false}
      hoverElevation={1}
      cursor="pointer"
      onClick={() => history.push('/charges/prompted')}
      intent="success"
    >
      <Pane display="flex" alignItems="center">
        <Pane width="52px" display="flex" justifyContent="center" alignItems="center">
          <Icon icon={['fad', 'user-tag']} size="2x" marginRight={20} marginLeft={8} color="success" />
        </Pane>
        <Pane flexGrow={1}>
          <Heading marginBottom={4}>Review Payments Prompted in Last 30 Days</Heading>
          <Text color="muted">
            {count > 1 ? (
              <>
                <Strong>{count} payments</Strong> prompted totaling
              </>
            ) : (
              <>
                <Strong>1 payment</Strong> prompted totaling
              </>
            )}
            <Strong> {toDollarsFromCents(total)}</Strong> in the past 30 days
          </Text>
        </Pane>
        <Pane display="grid" gridTemplateRows="auto" gridGap={6} gridAutoFlow="column" paddingX={24}>
          {connection.edges.slice(0, 5).map(({ node: { contact } }, i) => (
            <HoverAvatar
              key={i}
              name={`${contact.name.first} ${contact.name.last}`}
              color="green"
              size={28}
              onClick={(e: MouseEvent) => {
                e.stopPropagation()
                showGuarantorSheet({ guarantorId: contact.id })
              }}
            />
          ))}

          {count > 5 && <HoverAvatar name="＋" color="green" size={28} />}
        </Pane>

        <Icon icon={['far', 'chevron-right']} color="success" size="lg" marginRight={8} />
      </Pane>
    </Alert>
  )
}

export default Prompted

const HoverAvatar = styled(Avatar)`
  :hover {
    background-color: ${colors.green.base};
    span {
      color: white;
    }
  }
`
