import { useState } from 'react'
import moment from 'moment'

import { Pane, Heading, Text, Card, CardProps, Avatar, Badge, Tooltip } from 'evergreen-ui'

import { Icon, colors, toDollarsFromCents, BalanceActivitySheet, boxShadow } from 'lib'

export type Props = CardProps & {
  isPatientApp?: boolean
  guarantorId: string

  date: number
  patient: {
    name: {
      first: string
      last: string
    }
    dob?: {
      day: number
      month: number
      year: number
    } | null
  }
  isPendingPrimary: boolean
  isPendingSecondary: boolean
  isDenied: boolean
  uploadPaths: string[]

  netAmount: number
  patientPaymentsSum: number
  insurancePaymentsSum: number
  adjustmentsSum: number
  proceduresSum: number

  procedures: {
    id: string
    amount: number
    procedureCode: string | null
    description: string
    pms_claimId: string | null
  }[]
}

const BalanceActivityCard = ({ className, ...props }: Props) => {
  const {
    patient,
    date,
    netAmount,
    adjustmentsSum,
    patientPaymentsSum,
    proceduresSum,
    insurancePaymentsSum,
    isPendingPrimary,
    isPendingSecondary,
    procedures
  } = props

  const [isBalanceActivitySheetShown, setIsBalanceActivitySheetShown] = useState(false)
  const isVisit = procedures.length
  const isPendingAny = isPendingPrimary || isPendingSecondary

  const label = isVisit
    ? `Patient Visit`
    : adjustmentsSum && patientPaymentsSum
    ? 'Payment + Adj.'
    : adjustmentsSum && insurancePaymentsSum
    ? 'Insurance + Adj.'
    : patientPaymentsSum && insurancePaymentsSum
    ? 'Payment + Insurance'
    : patientPaymentsSum
    ? 'Payment'
    : insurancePaymentsSum
    ? 'Insurance'
    : adjustmentsSum
    ? 'Adjustment'
    : 'Other'

  const badgeLabel = label !== 'Payment' ? label.replace('Payment', 'PMT') : 'Payment'

  const color = isVisit || netAmount > 0 ? 'blue' : 'green'
  const intent = isVisit || netAmount > 0 ? 'default' : 'success'

  return (
    <>
      <BalanceActivitySheet
        isShown={isBalanceActivitySheetShown}
        setIsShown={setIsBalanceActivitySheetShown}
        label={label}
        {...props}
      />

      <Card
        hoverElevation={isVisit ? 1 : undefined}
        onClick={isVisit ? () => setIsBalanceActivitySheetShown(true) : undefined}
        background="white"
        cursor="pointer"
        paddingY={12}
        paddingX={14}
        boxShadow={boxShadow.minimal}
        borderRadius={4}
        {...props}
      >
        <Pane display="flex" alignItems="center">
          <Pane width="44px" display="flex" alignItems="center">
            <Avatar size={32} name={`${patient.name.first} ${patient.name.last}`} color={color} />
          </Pane>
          <Pane flexGrow={1}>
            <Text size={300} marginBottom={4}>
              {moment.unix(date).format('M/D/YYYY')}
            </Text>
            <Heading size={500} color={isPendingAny ? colors.neutral.muted : colors.neutral.dark}>
              {netAmount ? toDollarsFromCents(netAmount, 2) : '$－'}
            </Heading>
          </Pane>
          <Pane display="flex" flexDirection="column" alignItems="flex-end">
            <Badge color={color} marginBottom={4}>
              {badgeLabel}
              {isPendingAny ? ' (pending)' : ''}
            </Badge>
            <Pane display="flex" marginBottom={-2}>
              <Tooltip content={`Insurance Pending`}>
                <Icon
                  icon={['fas', 'hourglass']}
                  color={intent}
                  display={isPendingAny ? 'block' : 'none'}
                  marginLeft={6}
                />
              </Tooltip>
              <Tooltip content={`Procedures: ${toDollarsFromCents(proceduresSum)}`}>
                <Icon
                  icon={['fas', 'tooth']}
                  color={intent}
                  display={proceduresSum ? 'block' : 'none'}
                  marginLeft={6}
                />
              </Tooltip>
              <Tooltip content={`Payments: ${toDollarsFromCents(patientPaymentsSum)}`}>
                <Icon
                  icon={['fas', 'credit-card']}
                  color={intent}
                  display={patientPaymentsSum ? 'block' : 'none'}
                  marginLeft={6}
                />
              </Tooltip>
              <Tooltip content={`Insurance Payments: ${toDollarsFromCents(insurancePaymentsSum)}`}>
                <Icon
                  icon={['fas', 'building-columns']}
                  color={intent}
                  display={insurancePaymentsSum ? 'block' : 'none'}
                  marginLeft={6}
                />
              </Tooltip>
              <Tooltip content={`Adjustments: ${toDollarsFromCents(adjustmentsSum)}`}>
                <Icon
                  icon={['fas', 'percentage']}
                  color={intent}
                  display={adjustmentsSum ? 'block' : 'none'}
                  marginLeft={6}
                />
              </Tooltip>
            </Pane>
          </Pane>
          <Icon
            marginLeft={14}
            icon={['far', 'chevron-right']}
            color={intent}
            visibility={isVisit ? 'visible' : 'hidden'}
          />
        </Pane>
      </Card>
    </>
  )
}

export default BalanceActivityCard
