import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import styled from 'styled-components/macro'

import moment from 'moment'
import { mapValues, omitBy, isNil } from 'lodash'

import { Formik, Form } from 'formik'

import { useMutation, useQuery, useApolloClient, ApolloError } from '@apollo/client'
import {
  CREATE_PATIENT,
  UPDATE_PATIENT,
  GET_PATIENT_DETAILS,
  GET_EXISTING_PATIENTS,
  UNSYNC_PATIENT,
  DELETE_PATIENT,
  SYNC_PATIENT
} from 'lib/graphql/_patient-details'

import { Pane, Heading, Label, toaster, PaneProps, Text, Tooltip } from 'evergreen-ui'

import {
  Types,
  Card,
  CardHeader,
  TextInputField,
  DoubleTextInput,
  Divider,
  Switch,
  FormError,
  Button,
  IconButton,
  Spinner,
  ConfirmDialog,
  ExistingPatientDialog,
  PatientSelector,
  Icon
} from 'lib'

const PatientField = Types.PatientField

enum DOBField {
  DOB_MONTH = 'dobMonth',
  DOB_DAY = 'dobDay',
  DOB_YEAR = 'dobYear'
}

enum AddressField {
  STREET_1 = 'street1',
  STREET_2 = 'street2',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zip'
}

export type Props = PaneProps & {
  requiredFields?: Types.PatientField[]
  optionalFields?: Types.PatientField[]
  requireEmailOrPhone?: boolean
  contactId?: string
  guarantorId?: string
  height?: number
  button: {
    label: string
    existingLabel?: string
    chevron?: boolean
  }
  isProfile?: boolean
  onCreated?: (patient: Types.CreatePatient) => void
  onUpdated?: (patient: Types.UpdatePatient) => void
  onSkip?: (patient: Types.CreatePatient) => void
  lockUpdate?: boolean
  disableExistingDependent?: boolean
  disableExistingGuarantor?: boolean
  disableExistingMember?: boolean
  disablePMSPatient?: boolean
  canSearch?: boolean
  closeModal?: () => void
}

const PatientDetails = ({
  requiredFields = [],
  optionalFields = [],
  requireEmailOrPhone = false,
  contactId: contactIdProp,
  guarantorId,
  height = 48,
  button,
  isProfile,
  onCreated,
  onUpdated,
  onSkip,
  lockUpdate,
  disableExistingDependent,
  disableExistingGuarantor,
  disableExistingMember,
  disablePMSPatient,
  canSearch,
  closeModal,
  ...props
}: Props) => {
  const apolloClient = useApolloClient()

  const allFields = [PatientField.FIRST_NAME, PatientField.LAST_NAME].concat(requiredFields).concat(optionalFields)

  useEffect(() => {
    if (requiredFields.some((el) => optionalFields.includes(el)))
      throw Error('Required and optional fields must not overlap')
  }, [])

  const [contactId, setContactId] = useState(contactIdProp)
  const [locked, setLocked] = useState(lockUpdate && !!contactId)
  const isUpdating = !!contactId

  const { data, loading, error } = useQuery<Types.PatientDetails>(GET_PATIENT_DETAILS, {
    variables: { id: contactId },
    skip: !isUpdating
  })

  const [isLoadingExisting, setIsLoadingExisting] = useState(false)
  const [isExistingPatientDialogShown, setIsExistingPatientDialogShown] = useState(false)
  const [existingPatients, setExistingPatients] = useState<Types.ExistingPatients_existingContacts[]>()

  const contact = data?.contact

  const nameEmailPhone = {
    [PatientField.FIRST_NAME]: contact?.name.first,
    [PatientField.LAST_NAME]: contact?.name.last,
    [PatientField.EMAIL]: contact?.email,
    [PatientField.PHONE]: contact?.phone
  }

  const contactSource = data?.contact.source
  const synced = contactSource && contactSource === 'SIKKA' && !data?.contact.pms_override
  const unsynced = contactSource && contactSource === 'SIKKA' && data?.contact.pms_override

  const disabledFields: string[] = [] // Does not include address or DOB (separatve variables below)

  const isDeleteButtonShown =
    contactSource &&
    contactSource === 'PEARLY' &&
    !data?.contact.stripeId_customer &&
    !data?.contact.stripeId_subscription

  const hasOutreachFlows = data && data.activeOutreachFlowsByContactId > 0
  const hasTransactions = data && data.transactionsCountByContactId > 0

  const isDeleteButtonEnabled = !hasOutreachFlows && !hasTransactions

  let deleteButtonTooltip = 'Click to delete patient.'
  if (hasOutreachFlows) {
    deleteButtonTooltip = 'Unable to delete contact on Outreach Flow'
  } else if (hasTransactions) {
    deleteButtonTooltip = 'Unable to delete contact with payment history'
  }

  if (synced) {
    //@ts-ignore
    disabledFields.push(...Object.keys(nameEmailPhone), PatientField.DOB, PatientField.ADDRESS, PatientField.PERIO)
  } else if (locked) {
    //@ts-ignore
    disabledFields.push(...Object.keys(omitBy(nameEmailPhone, isNil)))
    if (contact) {
      if (contact.dob)
        // Disable DOB if all DOB exists
        disabledFields.push(PatientField.DOB)
      if (contact.address)
        // Disable address if address exists
        disabledFields.push(PatientField.ADDRESS)
    }
  }

  const isAddressRequired = requiredFields.includes(PatientField.ADDRESS)
  const isAddressDisabled = disabledFields.includes(PatientField.ADDRESS)

  const isDOBRequired = requiredFields.includes(PatientField.DOB)
  const isDOBDisabled = disabledFields.includes(PatientField.DOB)

  // Necessary because useLazyQuery result cannot take variables
  const [createPatient, createPatientStatus] = useMutation<Types.CreatePatient, Types.CreatePatientVariables>(
    CREATE_PATIENT,
    {
      refetchQueries: ['PatientsTable'],
      update: (cache, { data }) => {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            patientsConnection: (_existing, { DELETE }) => DELETE
          }
        })

        if (data?.createPatient.guarantorId)
          cache.evict({
            id: cache.identify({ id: data.createPatient.guarantorId, __typename: 'Contact' }),
            fieldName: 'dependents'
          })

        cache.gc()
      },
      onCompleted: (data) => onCreated && onCreated(data),
      onError: (err) => toaster.danger(err.message)
    }
  )

  const [updatePatient, updatePatientStatus] = useMutation<Types.UpdatePatient, Types.UpdatePatientVariables>(
    UPDATE_PATIENT,
    {
      update: (cache, { data }) => {
        // Recalculate balances if updating contact details (balance counts may be changing)
        if (data?.updatePatient.email || data?.updatePatient.phone) {
          cache.modify({
            id: 'ROOT_QUERY',
            fields: {
              balanceMetrics: (_existing, { DELETE }) => DELETE,
              balanceAssistant: (_existing, { DELETE }) => DELETE
            }
          })

          cache.gc()
        }
      },
      onCompleted: (data) => onUpdated && onUpdated(data),
      onError: (err) => toaster.danger(err.message)
    }
  )

  const [isUnsyncConfirmShown, setIsUnsyncConfirmShown] = useState(false)
  const [isSyncConfirmShown, setIsSyncConfirmShown] = useState(false)
  const [isDeleteConfirmShown, setIsDeleteConfirmShown] = useState(false)

  const [deletePatient, deletePatientStatus] = useMutation<Types.DeletePatient, Types.DeletePatientVariables>(
    DELETE_PATIENT,
    {
      onCompleted: () => {
        setLocked(false)
        toaster.success('Patient successfully deleted')

        if (closeModal) {
          closeModal()
        }
      },
      onError: (err) => toaster.danger(err.message),
      update: (cache) => {
        cache.evict({ fieldName: 'patientsConnection' })
      }
    }
  )

  const [unsyncPatient, unsyncPatientStatus] = useMutation<Types.UnsyncPatient, Types.UnsyncPatientVariables>(
    UNSYNC_PATIENT,
    {
      onCompleted: () => {
        setLocked(false)
        toaster.success('Patient successfully unsynced')
      },
      onError: (err) => toaster.danger(err.message)
    }
  )

  const [syncPatient, syncPatientStatus] = useMutation<Types.SyncPatient, Types.SyncPatientVariables>(SYNC_PATIENT, {
    onCompleted: () => toaster.success('Patient successfully synced'),
    onError: (err) => toaster.danger(err.message)
  })

  return (
    <>
      <ConfirmDialog
        isShown={isDeleteConfirmShown}
        setIsShown={setIsDeleteConfirmShown}
        onConfirm={() => deletePatient({ variables: { contactId: contactId! } })}
        title="Confirm Delete"
        body={`Are you sure you want to delete the patient record for ${data?.contact.name.first} ${data?.contact.name.last}? This action cannot be reversed.`}
      />

      <ConfirmDialog
        isShown={isUnsyncConfirmShown}
        setIsShown={setIsUnsyncConfirmShown}
        onConfirm={() => unsyncPatient({ variables: { contactId: contactId! } })}
        title="Confirm Unsync"
        body="Unsyncing this patient will prevent future profile and insurance carrier updates from your practice management system from being reflected. Would you like to proceed?"
      />

      <ConfirmDialog
        isShown={isSyncConfirmShown}
        setIsShown={setIsSyncConfirmShown}
        onConfirm={() => syncPatient({ variables: { contactId: contactId! } })}
        title="Confirm Re-Sync"
        body="Re-syncing this patient will override their current details with the information in your practice management system. Would you like to proceed?"
      />

      <Formik
        onSubmit={async (values) => {
          const patientVariables = getPatientVariables(values)
          const { profile } = patientVariables

          const returnNullIfFieldIsOnFormAndEmpty = <T extends string | number | boolean>(
            value: T | undefined,
            field: Types.PatientField
          ): T | null | undefined =>
            value === '' || value === undefined ? (allFields.includes(field) ? null : undefined) : value

          if (contactId) {
            updatePatient({
              variables: {
                contactId,
                ...patientVariables,
                email: returnNullIfFieldIsOnFormAndEmpty(patientVariables.email, Types.PatientField.EMAIL),
                phone: returnNullIfFieldIsOnFormAndEmpty(patientVariables.phone, Types.PatientField.PHONE),

                profile: {
                  ...profile,
                  street2: returnNullIfFieldIsOnFormAndEmpty(profile.street2, Types.PatientField.ADDRESS)
                }
              }
            })
          } else {
            if (values.email || values.phone) {
              try {
                setIsLoadingExisting(true)
                const { data } = await apolloClient.query<Types.ExistingPatients, Types.ExistingPatientsVariables>({
                  query: GET_EXISTING_PATIENTS,
                  variables: { email: values.email, phone: values.phone, guarantorId }
                })
                setIsLoadingExisting(false)

                if (data.existingContacts.length) {
                  setExistingPatients(data.existingContacts)
                  setIsExistingPatientDialogShown(true)
                  return
                }
              } catch (err) {
                setIsLoadingExisting(false)
                toaster.danger((err as ApolloError).message.replace('GraphQL error: ', ''))
                return
              }
            }

            createPatient({
              variables: {
                guarantorId,
                ...patientVariables
              }
            })
          }
        }}
        initialValues={{
          [PatientField.FIRST_NAME]: contact?.name.first ?? '',
          [PatientField.LAST_NAME]: contact?.name.last ?? '',
          [PatientField.EMAIL]: contact?.email ?? '',
          [PatientField.PHONE]: contact?.phone ?? '',

          [PatientField.PERIO]: !!contact?.perio,

          [DOBField.DOB_MONTH]: contact?.dob?.month ?? '',
          [DOBField.DOB_DAY]: contact?.dob?.day ?? '',
          [DOBField.DOB_YEAR]: contact?.dob?.year ?? '',

          [AddressField.STREET_1]: contact?.address?.street1 ?? '',
          [AddressField.STREET_2]: contact?.address?.street2 ?? '',
          [AddressField.CITY]: contact?.address?.city ?? '',
          [AddressField.STATE]: (contact?.address?.state ?? ('' as unknown)) as Types.State,
          [AddressField.ZIP]: contact?.address?.zip ?? ''
        }}
        enableReinitialize={true}
        validationSchema={getValidationSchema({ requiredFields, optionalFields, requireEmailOrPhone })}
      >
        {({ validateForm, handleSubmit, dirty, setTouched, values }) => (
          <Form
            style={{ height: '100%', width: '100%' }}
            onSubmit={async (e) => {
              e.preventDefault()

              if (dirty) {
                handleSubmit()
              } else if (onSkip) {
                // Manual validation
                const formErrors = await validateForm()
                if (Object.values(formErrors).some((error) => !!error)) setTouched(mapValues(values, () => true))
                else {
                  if (!(contactId && contact)) throw Error('Unable to skip step, incomplete data')
                  // Repurpose createPatient type
                  onSkip({
                    createPatient: contact
                  })
                }
              }
            }}
            data-cy="patient-form"
          >
            <ExistingPatientDialog
              isShown={isExistingPatientDialogShown}
              setIsShown={setIsExistingPatientDialogShown}
              patients={existingPatients}
              disablePMSPatient={disablePMSPatient}
              disableExistingDependent={disableExistingDependent}
              disableExistingGuarantor={disableExistingGuarantor}
              disableExistingMember={disableExistingMember}
              guarantorId={guarantorId}
              selectPatientId={(patientId) => {
                if (patientId === 'new') {
                  const patientVariables = getPatientVariables(values)

                  createPatient({
                    variables: {
                      guarantorId,
                      ...patientVariables
                    }
                  })
                } else {
                  setContactId(patientId)
                  if (lockUpdate) setLocked(true)
                }
              }}
            />
            <Layout>
              <Pane gridArea="body" overflow="auto" background="blueTint" {...props}>
                {canSearch && (
                  <>
                    <Card padding={0} margin={16} elevation={0}>
                      <CardHeader>
                        <Heading size={500} width="100%" textAlign="center">
                          Find Existing Patient
                        </Heading>
                      </CardHeader>
                      <Pane padding={24} display="flex" flexDirection="column">
                        <PatientSelector
                          setPatient={(patient) => {
                            if (disablePMSPatient && patient.source !== 'PEARLY') {
                              toaster.danger('Unable to proceed for patients from Practice Management System')
                            } else if (disableExistingDependent && !patient.isGuarantor) {
                              toaster.danger(
                                'Unable to proceed for dependent patients.  Please find the dependent in your Patient Directory.'
                              )
                            } else if (disableExistingGuarantor && patient.isGuarantor) {
                              toaster.danger('Unable to proceed for guarantor patoeints')
                            } else if (disableExistingMember && patient.isMember) {
                              toaster.danger('Unable to proceed - Patient is already enrolled in a memberhsip plan')
                            } else {
                              setContactId(patient.id)
                              if (lockUpdate) setLocked(true)
                            }
                          }}
                        />
                      </Pane>
                    </Card>

                    <Divider width="100%" label={isUpdating ? 'AND' : 'OR'} paddingY={8} paddingX={16} />
                  </>
                )}
                <Card padding={0} margin={16} elevation={0}>
                  {loading || error ? (
                    <Pane
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      height="160px"
                    >
                      <Label marginBottom={20} color="muted">
                        Loading Patient Details
                      </Label>
                      <Spinner delay={0} />
                    </Pane>
                  ) : (
                    <>
                      <CardHeader withButton={locked || synced || unsynced || isDeleteButtonShown}>
                        <Heading
                          size={500}
                          flexGrow={1}
                          textAlign={isProfile && !synced && !unsynced ? 'center' : 'left'}
                        >
                          Patient Details
                          {synced && (
                            <Text size={500} color="muted" marginLeft={4} fontStyle="italic">
                              (Synced)
                            </Text>
                          )}
                        </Heading>
                        {locked && !synced && (
                          <Button appearance="minimal" iconAfter={['fas', 'unlock']} onClick={() => setLocked(false)}>
                            Unlock
                          </Button>
                        )}
                        {isDeleteButtonShown && (
                          <Tooltip content={deleteButtonTooltip}>
                            {/* Tooltip doesn't work with disabled button, so div is added to make a work around */}
                            <div>
                              <IconButton
                                disabled={!isDeleteButtonEnabled}
                                appearance="minimal"
                                icon={['fas', 'trash-alt']}
                                isLoading={deletePatientStatus.loading}
                                onClick={() => setIsDeleteConfirmShown(true)}
                              />
                            </div>
                          </Tooltip>
                        )}
                        {synced && data?.contact.source !== Types.DataSource.UNKNOWN && (
                          <Tooltip content="Patient is currently synced with practice management system.  Click to unsync.">
                            <IconButton
                              appearance="minimal"
                              icon={['fas', 'link']}
                              isLoading={unsyncPatientStatus.loading}
                              onClick={() => setIsUnsyncConfirmShown(true)}
                            />
                          </Tooltip>
                        )}
                        {unsynced && (
                          <Tooltip content="Patient is currently unsynced with practice management system.  Click to re-sync.">
                            <IconButton
                              appearance="minimal"
                              icon={['fas', 'unlink']}
                              isLoading={syncPatientStatus.loading}
                              onClick={() => setIsSyncConfirmShown(true)}
                            />
                          </Tooltip>
                        )}
                      </CardHeader>

                      <Pane padding={24} display="flex" flexDirection="column">
                        <Pane display="flex">
                          <TextInputField
                            flex={1}
                            name={PatientField.FIRST_NAME}
                            disabled={disabledFields.includes(PatientField.FIRST_NAME)}
                            label="First Name*"
                            height={height}
                            marginRight={16}
                            placeholder="First Name"
                          />
                          <TextInputField
                            flex={1}
                            name={PatientField.LAST_NAME}
                            disabled={disabledFields.includes(PatientField.LAST_NAME)}
                            label="Last Name*"
                            height={height}
                            placeholder="Last Name"
                          />
                        </Pane>

                        {allFields.includes(PatientField.EMAIL) && (
                          <TextInputField
                            name={PatientField.EMAIL}
                            disabled={disabledFields.includes(PatientField.EMAIL)}
                            label={`Email${requiredFields.includes(PatientField.EMAIL) ? '*' : ''}`}
                            type="email"
                            placeholder="Email"
                            height={height}
                          />
                        )}

                        {allFields.includes(PatientField.PHONE) && (
                          <TextInputField
                            name={PatientField.PHONE}
                            disabled={disabledFields.includes(PatientField.PHONE)}
                            label={`Mobile Phone${requiredFields.includes(PatientField.PHONE) ? '*' : ''}`}
                            isPhone
                            placeholder="Phone"
                            height={height}
                          />
                        )}

                        {allFields.includes(PatientField.DOB) && (
                          <>
                            <Label alignSelf="flex-start">{`Date of Birth${isDOBRequired ? '*' : ''}`}</Label>
                            <Pane display="flex">
                              <TextInputField
                                name={DOBField.DOB_MONTH}
                                disabled={isDOBDisabled}
                                flex={1.3}
                                placeholder="MM"
                                height={height}
                                marginRight={16}
                                textAlign="center"
                                hint="Month"
                                minLength={1}
                                maxLength={2}
                              />
                              <TextInputField
                                name={DOBField.DOB_DAY}
                                disabled={isDOBDisabled}
                                flex={1.3}
                                placeholder="DD"
                                height={height}
                                marginRight={16}
                                textAlign="center"
                                hint="Day"
                                minLength={1}
                                maxLength={2}
                              />
                              <TextInputField
                                name={DOBField.DOB_YEAR}
                                disabled={isDOBDisabled}
                                flex={2}
                                placeholder="YYYY"
                                textAlign="center"
                                height={height}
                                hint="Year"
                                minLength={4}
                                maxLength={4}
                              />
                            </Pane>
                          </>
                        )}

                        {allFields.includes(PatientField.PERIO) && (
                          <Pane display="flex" alignItems="center">
                            <Label alignSelf="flex-start" marginRight={16} marginBottom={16}>
                              {`${
                                isUpdating
                                  ? 'Periodontal Disease'
                                  : 'Has the patient been diagnosed with periodontal disease, gingivitis, or gum disease?'
                              }${requiredFields.includes(PatientField.PERIO) ? '*' : ''}`}
                            </Label>
                            <Switch
                              name={PatientField.PERIO}
                              disabled={disabledFields.includes(PatientField.PERIO)}
                              height={24}
                            />
                          </Pane>
                        )}

                        {allFields.includes(PatientField.ADDRESS) && (
                          <>
                            <Divider label="More Info" />

                            <Label alignSelf="flex-start" marginBottom={4} display="inline-block">
                              {`Address${isAddressRequired ? '*' : ''}`}
                            </Label>
                            <DoubleTextInput
                              half={'top'}
                              name={AddressField.STREET_1}
                              disabled={isAddressDisabled}
                              height={height}
                              type="text"
                              placeholder="Street Address"
                            />
                            <DoubleTextInput
                              half="bottom"
                              name={AddressField.STREET_2}
                              disabled={isAddressDisabled}
                              type="text"
                              placeholder="Unit/Suite"
                              height={height}
                            />

                            <TextInputField
                              name={AddressField.CITY}
                              disabled={isAddressDisabled}
                              label={`City${isAddressRequired ? '*' : ''}`}
                              placeholder="City"
                              height={height}
                            />

                            <Pane display="flex">
                              <TextInputField
                                flex={1}
                                name={AddressField.STATE}
                                disabled={isAddressDisabled}
                                label={`State${isAddressRequired ? '*' : ''}`}
                                placeholder="State"
                                height={height}
                                marginRight={16}
                                marginBottom={0}
                              />
                              <TextInputField
                                flex={1}
                                name={AddressField.ZIP}
                                disabled={isAddressDisabled}
                                label={`Zip Code${isAddressRequired ? '*' : ''}`}
                                placeholder="Zip Code"
                                height={height}
                                marginBottom={0}
                              />
                            </Pane>
                          </>
                        )}

                        <Pane display="flex" justifyContent="center">
                          <FormError />
                        </Pane>
                      </Pane>
                    </>
                  )}
                </Card>
                {isProfile &&
                  (contact?.pms_primaryInsuranceName ||
                    contact?.pms_secondaryInsuranceName ||
                    contact?.pms_billingType) && (
                    <Card margin={16} padding={0} elevation={0}>
                      <CardHeader>
                        <Icon icon={['fad', 'shield-alt']} marginRight={8} />
                        <Heading>Insurance & Billing Type</Heading>
                      </CardHeader>
                      <Pane padding={16}>
                        {contact.pms_primaryInsuranceName && (
                          <Heading>
                            Primary: <Text size={500}>{contact.pms_primaryInsuranceName}</Text>
                          </Heading>
                        )}
                        {contact.pms_secondaryInsuranceName && (
                          <Heading marginTop={12}>
                            Secondary: <Text size={500}>{contact.pms_secondaryInsuranceName}</Text>
                          </Heading>
                        )}
                        {contact.pms_billingType && (
                          <Heading marginTop={12}>
                            Billing Type: <Text size={500}>{contact.pms_billingType}</Text>
                          </Heading>
                        )}
                      </Pane>
                    </Card>
                  )}
              </Pane>

              {!(loading || (isProfile && synced)) && (
                <Pane gridArea="footer" elevation={0} padding={16}>
                  <Button
                    autoFocus
                    type="submit"
                    isLoading={
                      createPatientStatus.loading ||
                      updatePatientStatus.loading ||
                      isLoadingExisting ||
                      deletePatientStatus.loading ||
                      unsyncPatientStatus.loading ||
                      syncPatientStatus.loading
                    }
                    appearance="primary"
                    height={48}
                    width="100%"
                    justifyContent="center"
                    iconAfter={button.chevron ? ['far', 'chevron-right'] : undefined}
                  >
                    {contactId && button.existingLabel ? button.existingLabel : button.label}
                  </Button>
                </Pane>
              )}
            </Layout>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PatientDetails

const getValidationSchema = ({
  requiredFields,
  optionalFields,
  requireEmailOrPhone
}: {
  requiredFields: Types.PatientField[]
  optionalFields: Types.PatientField[]
  requireEmailOrPhone: boolean
}) => {
  const allFields = requiredFields.concat(optionalFields)

  const schema: { [x: string]: Yup.StringSchema | Yup.NumberSchema | Yup.BooleanSchema } = {
    [PatientField.FIRST_NAME]: Yup.string().required('First name is required'),
    [PatientField.LAST_NAME]: Yup.string().required('Last name is required')
  }

  if (allFields.includes(PatientField.EMAIL)) {
    schema[PatientField.EMAIL] = Yup.string().email('Please enter a valid email')
    if (requiredFields.includes(PatientField.EMAIL)) schema[PatientField.EMAIL].required('Email is required')
  }

  if (allFields.includes(PatientField.PHONE)) {
    schema[PatientField.PHONE] = Yup.string().matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number')
    if (requiredFields.includes(PatientField.PHONE)) schema[PatientField.PHONE].required('Phone number is required')
  }

  if (allFields.includes(PatientField.PERIO)) {
    schema[PatientField.PERIO] = Yup.boolean()
    if (requiredFields.includes(PatientField.PHONE))
      schema[PatientField.PHONE].required('Periodontal status is required')
  }

  if (allFields.includes(PatientField.DOB)) {
    schema[DOBField.DOB_MONTH] = Yup.number()
      .integer('Please enter a valid birth month')
      .min(1, 'Please enter a valid birth month')
      .max(12, 'Please enter a valid birth month')
    schema[DOBField.DOB_DAY] = Yup.number()
      .integer('Please enter a valid birth day')
      .min(1, 'Please enter a valid birth day')
      .max(32, 'Please enter a valid birth day')
    schema[DOBField.DOB_YEAR] = Yup.number()
      .integer('Please enter a valid birth year')
      .min(1900, 'Please enter a valid birth year')
      .max(moment().year(), 'Please enter a valid birth year')

    if (requiredFields.includes(PatientField.DOB)) {
      schema[DOBField.DOB_MONTH] = schema[DOBField.DOB_MONTH].required('Birth month is required')
      schema[DOBField.DOB_DAY] = schema[DOBField.DOB_DAY].required('Birth day is required')
      schema[DOBField.DOB_YEAR] = schema[DOBField.DOB_YEAR].required('Birth year is required')
    }
  }

  if (allFields.includes(PatientField.ADDRESS)) {
    schema[AddressField.STREET_1] = Yup.string().when('street2', {
      is: (val) => val,
      then: Yup.string().required('Please submit a complete address')
    })
    schema[AddressField.STREET_2] = Yup.string()
    schema[AddressField.CITY] = Yup.string()
    schema[AddressField.STATE] = Yup.mixed().oneOf(
      Object.values(Types.State),
      'Please submit a valid state (abbreviation)'
    )
    schema[AddressField.ZIP] = Yup.string().matches(
      /^\d{5}(-\d{4})?$/,
      'Please enter a valid zip code (5 digits or 9 digits)'
    )

    if (requiredFields.includes(PatientField.ADDRESS)) {
      schema[AddressField.STREET_1] = schema[AddressField.STREET_1].required('Address is required')
      schema[AddressField.CITY] = schema[AddressField.CITY].required('City is required')
      schema[AddressField.STATE] = schema[AddressField.STATE].required('State is required')
      schema[AddressField.ZIP] = schema[AddressField.ZIP].required('Zip code is required')
    }
  }

  if (requireEmailOrPhone) {
    schema['customValidation'] = Yup.boolean().test('email-or-phone', 'Email or phone is required', function () {
      const { email, phone } = this.parent
      return !!(email || phone)
    })
  }

  return Yup.object(schema)
}

const Layout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'body'
    'footer';
  grid-template-rows: 1fr auto;
`

const getPatientVariables = ({
  firstName,
  lastName,
  email,
  phone,
  perio,
  dobMonth,
  dobDay,
  dobYear,
  street1,
  street2,
  city,
  state,
  zip
}: {
  firstName: string
  lastName: string
  email: string
  phone: string
  perio: boolean
  dobMonth: string | number
  dobDay: string | number
  dobYear: string | number
  street1: string
  street2: string
  city: string
  state: Types.State
  zip: string
}) => ({
  email: email || undefined,
  phone: phone || undefined,
  profile: {
    firstName,
    lastName,
    perio,

    dobMonth: typeof dobMonth === 'string' ? parseInt(dobMonth) : dobMonth || undefined,
    dobDay: typeof dobDay === 'string' ? parseInt(dobDay) : dobDay || undefined,
    dobYear: typeof dobYear === 'string' ? parseInt(dobYear) : dobYear || undefined,

    street1: street1 || undefined,
    street2: street2 || undefined,
    city: city || undefined,
    state: state || undefined,
    zip: zip || undefined
  }
})
