import 'graphql'
import gql from 'graphql-tag'

export const GET_PAYMENT_METHOD_CARD = gql`
  query PaymentMethodCard($guarantorId: ID!, $allowDelete: Boolean!, $attemptScheduledPayments: Boolean!) {
    contact(id: $guarantorId) {
      id
      hasFailedScheduledPayment @include(if: $attemptScheduledPayments)
      hasOngoingInstallmentPlan @include(if: $allowDelete)
      restrictedPaymentMethods {
        ach
        cc
      }
      stripe {
        latestOpenInvoice @include(if: $attemptScheduledPayments)
        defaultSource {
          id
          isCredit
          institution
          last4
          createdAt
        }
        hasSubscriptions @include(if: $allowDelete)
      }
    }
  }
`

export const UPDATE_GUARANTOR_PAYMENT_METHOD = gql`
  mutation UpdateGuarantorPaymentMethod(
    $guarantorId: ID!
    $stripeToken: String
    $plaidToken: String
    $plaidAccountId: String
    $attemptScheduledPayments: Boolean!
  ) {
    updateGuarantorPaymentMethod(
      contactId: $guarantorId
      stripeToken: $stripeToken
      plaidToken: $plaidToken
      plaidAccountId: $plaidAccountId
      attemptScheduledPayments: $attemptScheduledPayments
    ) {
      contact {
        id
        stripe {
          latestOpenInvoice
          defaultSource {
            id
            isCredit
            institution
            last4
            createdAt
          }
        }
      }
      error
    }
  }
`

export const DELETE_GUARANTOR_PAYMENT_METHOD = gql`
  mutation DeleteGuarantorPaymentMethod($guarantorId: ID!) {
    deleteGuarantorPaymentMethod(contactId: $guarantorId) {
      id
      stripe {
        defaultSource {
          id
        }
      }
    }
  }
`
