import styled from 'styled-components/macro'

import { useQuery } from '@apollo/client'
import { GET_INVOICE_SHEET } from 'graphql/_invoice-sheet'

import { SideSheet, Pane, Text, Heading, Strong } from 'evergreen-ui'

import { Types, Card, CardHeader, Icon, Button, Spinner, toDollarsFromCents, StatusBadge } from 'lib'

import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'

import moment from 'moment'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  invoiceId?: string
  subscriptionId?: string
  expandSubscription?: boolean
}

const InvoiceSheet = ({ isShown, setIsShown, invoiceId, subscriptionId, expandSubscription }: Props) => {
  const global = useGlobal()
  const anonymize = global?.meUser.google.anonymize

  const showGuarantorSheet = useModal('guarantor')

  const { loading, error, data } = useQuery<Types.InvoiceSheet>(GET_INVOICE_SHEET, {
    variables: { invoiceId, subscriptionId, expandSubscription }
  })

  const invoice = data?.invoice

  const contact = invoice?.contact

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400}>
      {loading || error || !invoice || !contact ? (
        <Pane display="flex" alignItems="center" height="100%" background="blueTint">
          <Spinner delay={0} />
        </Pane>
      ) : (
        <SheetLayout>
          <CardHeader gridArea="header" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Pane display="flex" alignItems="center">
              <Icon icon={['fad', 'file-invoice-dollar']} size="2x" color="selected" paddingLeft={4} />
              <Pane marginLeft={16}>
                <Heading size={600}>Invoice</Heading>
                <Text size={400}>
                  <Strong size={400}>Type: </Strong>
                  {invoice.isMembership ? 'Membership' : 'Pay-Over-Time'}
                </Text>
              </Pane>
            </Pane>

            <StatusBadge.Invoice status={invoice.status} />
          </CardHeader>

          <Pane gridArea="body" overflow="auto" background="blueTint">
            <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
              <CardHeader withButton>
                <Heading size={500}>Patient</Heading>
                <Button
                  iconBefore={['fas', 'user']}
                  onClick={() => showGuarantorSheet({ guarantorId: invoice.contact?.id as string })}
                >
                  View Patient
                </Button>
              </CardHeader>
              <Pane padding={16} display="flex" flexDirection="column">
                <Text size={500} marginBottom={8}>
                  <Strong size={500}>Name: </Strong>
                  {contact.name.first} {contact.name.last}
                </Text>
                <Text size={500}>
                  <Strong size={500}>Patient ID: </Strong>
                  {anonymize ? 'ABC123' : contact.code.toUpperCase()}
                </Text>
              </Pane>
            </Card>

            <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
              <CardHeader>
                <Heading size={500}>Total Due - {toDollarsFromCents(invoice.amount, 2)}</Heading>
              </CardHeader>
              <Pane padding={16}>
                <Pane padding={8} display="flex" flexDirection="column">
                  <Text size={500}>
                    <Strong size={500}>Invoice Date: </Strong>
                    {moment.unix(invoice.date).format('M/D/YYYY')}
                  </Text>

                  {invoice.code && (
                    <Text size={500} marginTop={8}>
                      <Strong size={500}>Invoice Code: </Strong>
                      {invoice.code}
                    </Text>
                  )}
                </Pane>
              </Pane>
            </Card>
          </Pane>
        </SheetLayout>
      )}
    </SideSheet>
  )
}

export default InvoiceSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
