import moment from 'moment'

import { Heading, Pane, Text, CardProps } from 'evergreen-ui'

import { Types, Card, CardHeader, Button, Icon, ModifyMembershipDropdown, StatusBadge, InfoTooltip } from 'lib'

import { getPrettySubscriptionStatus, toDollarsFromCents } from 'lib'

export type Props = CardProps & {
  contact: {
    id: string
    isGuarantor: boolean | null
    familyMemberCount: number

    email: string | null
    phone: string | null
    code: string
    perio: boolean
    dob: Types.PatientDetails_contact_dob | null

    name: { first: string; last: string }

    planTerm: Types.PlanTerm | null
    planRenewalDate: number | null
    plan: {
      id: string
      name: string
      annualPrice?: number | null
      monthlyPrice?: number | null
    } | null
    stripe: {
      membership: {
        id: string
        price: number
        interval: string
        effectiveDate: number
        status: Types.SubscriptionStatus
        autoRenew: boolean
      } | null
    } | null
  }
  isPortal?: boolean
  showContractSheet: (contractProps: {
    planId: string
    planTerm: Types.PlanTerm
    autoRenew: boolean
    effectiveDate: number
  }) => void
  showDependentSheet?: (dependentId: string) => void
  showProcessedTab?: () => void
  hasDependents?: boolean
}

const MemberCard = ({
  contact,
  showContractSheet,
  showDependentSheet,
  showProcessedTab,
  elevation = 0,
  isPortal,

  ...props
}: Props) => {
  if (!(contact && contact.plan && contact.planTerm && contact.planRenewalDate && contact.stripe?.membership))
    throw Error('Data incomplete')

  const {
    plan,
    stripe: { membership },
    isGuarantor
  } = contact

  const isDependent = !isGuarantor

  return (
    <Card elevation={elevation} padding={0} {...props}>
      <CardHeader display="flex" justifyContent="space-between" withButton={!!(isDependent && showDependentSheet)}>
        <Heading size={500}>
          <Icon icon={['fad', isDependent ? 'house-user' : 'user']} marginRight={8} />
          {contact.name.first} {contact.name.last}
        </Heading>

        <span>
          {isDependent && showDependentSheet && (
            <Button onClick={() => showDependentSheet(contact.id)} appearance="minimal" marginRight="8">
              Profile
            </Button>
          )}

          <StatusBadge.Subscription status={membership.status} />
          {membership.status === Types.SubscriptionStatus.PAUSED && (
            <InfoTooltip
              marginLeft={8}
              content="This plan was manually paused by your team.  You can resume billing in the Modify Plan dropdown."
            />
          )}
        </span>
      </CardHeader>
      <Pane padding={16} display="flex" flexDirection="column">
        <Pane display="grid" gridTemplateColumns="auto 1fr" gridRowGap={12} gridColumnGap={40} marginBottom={16}>
          <Text size={400} textTransform="uppercase" color="selected">
            Plan:
          </Text>
          <Text size={500}>{plan.name}</Text>
          <Text size={400} textTransform="uppercase" color="selected">
            Patient ID:
          </Text>
          <Text size={500}>{contact.code.toUpperCase()}</Text>

          <Text size={400} textTransform="uppercase" color="selected">
            Status:
          </Text>
          <Text size={500}>{getPrettySubscriptionStatus(membership.status)}</Text>

          <Text size={400} textTransform="uppercase" color="selected">
            Effective Date:
          </Text>
          <Text size={500}>{moment.unix(membership.effectiveDate).format('M/D/YYYY')}</Text>

          <Text size={400} textTransform="uppercase" color="selected">
            {membership.autoRenew ? 'Renewal' : 'Termination'} Date:
          </Text>
          <Text size={500}>{moment.unix(contact.planRenewalDate).format('M/D/YYYY')}</Text>

          <Text size={400} textTransform="uppercase" color="selected">
            Price:
          </Text>
          <Text size={500}>
            {`${toDollarsFromCents(contact.stripe.membership.price, 2)} / ${contact.stripe.membership.interval}`}
          </Text>
        </Pane>
        <Pane display="grid" gridGap={8} gridAutoFlow="column" alignSelf="center" marginTop={8}>
          <Button
            onClick={() =>
              showContractSheet({
                planId: plan.id,
                planTerm: contact.planTerm!,
                autoRenew: membership.autoRenew,
                effectiveDate: membership.effectiveDate
              })
            }
          >
            Contract
          </Button>
          {showProcessedTab && <Button onClick={() => showProcessedTab()}>Payments</Button>}
          <ModifyMembershipDropdown
            //  use spread operators to enforce property type guards
            contact={{
              ...contact,
              plan: contact.plan,
              planTerm: contact.planTerm,
              stripe: { ...contact.stripe, membership: contact.stripe.membership }
            }}
            isPortal={isPortal}
          />
        </Pane>
      </Pane>
    </Card>
  )
}

export default MemberCard
