import { CornerDialog, CornerDialogProps, Position } from 'evergreen-ui'

export interface Props extends CornerDialogProps {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  body: React.ReactNode
  position?: Position
}

const CornerDialogComponent = ({ isShown, setIsShown, body, position, ...props }: Props) => (
  <CornerDialog
    isShown={isShown}
    onCloseComplete={() => setIsShown(false)}
    //@ts-ignore
    position={position}
    {...props}
  >
    {body}
  </CornerDialog>
)

export default CornerDialogComponent
