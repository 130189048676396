import { Types } from 'lib'

import { Pane, Heading } from 'evergreen-ui'

import { EnrollMemberState } from '.'
import { PlanCardList } from 'lib'
import { getShownPrice } from 'lib'

export type Props = {
  contact: Types.EnrollMemberCard_contact
  enrollState: EnrollMemberState
  updateEnrollState: (update: Partial<EnrollMemberState>) => void
}

const EnrollMemberPlan = ({ contact, enrollState, updateEnrollState }: Props) => {
  const { plan: planState, payment } = enrollState
  return (
    <Pane display="flex" flexDirection="column" alignItems="center" padding={16}>
      <Heading marginTop={16}>Select a Plan</Heading>
      <PlanCardList
        contactId={contact.id}
        isFirstFamilyMember={contact.isFirstFamilyMember}
        selectPlan={(plan, term, goNext) => {
          updateEnrollState({
            plan: {
              ...plan,
              term,
              price: getShownPrice({ plan, term, isFirstFamilyMember: contact.isFirstFamilyMember })
            },
            step: enrollState.step + (goNext ? 1 : 0)
          })
        }}
        selectedPlanId={planState?.id}
        selectedPlanTerm={planState?.term}
        effectiveDate={payment?.billingCycleAnchor}
        width="100%"
      />
    </Pane>
  )
}

export default EnrollMemberPlan
