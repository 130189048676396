import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Heading, Pane, Text, Tooltip } from 'evergreen-ui'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, Card, CardHeader, colors, FormError, Icon, Spinner, Switch, Textarea, Types } from 'lib'

import { useGlobal } from 'components/global-provider'
import BalanceCard from 'components/balance-card'
import PayOverTimeOptionsCard from 'components/pay-over-time-options-card'
import { GET_BALANCE_CARD } from 'graphql/_balance-card'
import { SET_GUARANTOR_BALANCE_NOTE } from 'graphql/_guarantor-sheet'

export type Props = {
  guarantor: Types.GetSendNotificationCard['contact']
  onBack: () => void
  onNext: () => void
}

const SendNotificationPayment = ({ onBack, onNext, guarantor }: Props) => {
  const guarantorId = guarantor.id
  const global = useGlobal()
  const capabilities = global.account.capabilities

  const [isBalanceEditing, setIsBalanceEditing] = useState(false)

  // Prefetch balance card data
  const { data: balanceCardData } = useQuery<Types.BalanceCard, Types.BalanceCardVariables>(GET_BALANCE_CARD, {
    variables: {
      id: guarantorId
    }
  })

  const [setContactBalanceNote, setContactBalanceNoteStatus] = useMutation<
    Types.SetGuarantorBalanceNote,
    Types.SetGuarantorBalanceNoteVariables
  >(SET_GUARANTOR_BALANCE_NOTE)

  if (!balanceCardData) {
    return <Spinner delay={0} marginY={60} />
  }

  const patientPortion = balanceCardData.contact.balance.metrics.patientPortion

  const handleSubmit = async ({
    statementMemo,
    statementMemoEnabled
  }: {
    statementMemo: string
    statementMemoEnabled: boolean
  }) => {
    await setContactBalanceNote({
      variables: {
        contactId: guarantorId,
        balanceNote: statementMemoEnabled && statementMemo.length ? statementMemo : null
      }
    })
    onNext()
  }

  return (
    <Pane padding={16} display="grid" gap="16px">
      <BalanceCard guarantorId={guarantorId} elevation={0} setParentEditingState={setIsBalanceEditing} />

      <Formik
        initialValues={{ statementMemo: '', statementMemoEnabled: false }}
        validationSchema={Yup.object({
          statementMemo: Yup.string().when('statementMemoEnabled', {
            is: true,
            then: Yup.string().required('Statement Memo is required')
          })
        })}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <Card padding={0} elevation={0}>
              <CardHeader>
                <Heading>Pay Portal Options</Heading>
              </CardHeader>

              <Pane paddingY={8}>
                <Pane paddingY={8} paddingX={16}>
                  <Pane display="flex" justifyContent="space-between">
                    <Text size={500}>Show Statement Memo</Text>
                    <Switch height={20} name="statementMemoEnabled" marginBottom={0} />
                  </Pane>

                  {values.statementMemoEnabled && (
                    <>
                      <Pane marginTop={16}>
                        <Textarea name="statementMemo" width="100%" placeholder="Memo text..." marginBottom={0} />
                      </Pane>
                      <FormError collapseSpace margin={16} />
                    </>
                  )}
                </Pane>
                {values.statementMemoEnabled && (
                  <Pane width="100%" height="1px" marginY={8} backgroundColor={colors.border.default} />
                )}

                <Pane display="flex" justifyContent="space-between" padding={16}>
                  <Text size={500}>Payment Methods</Text>
                  <Pane>
                    <Tooltip content="eCheck / ACH">
                      <Icon icon={['fad', 'building-columns']} color={colors.blue.base} marginRight={8} />
                    </Tooltip>
                    <Icon icon={['fad', 'credit-card']} color={colors.blue.base} marginRight={8} />
                    <Icon icon={['fab', 'apple']} color={colors.blue.base} marginRight={8} />
                  </Pane>
                </Pane>
              </Pane>
            </Card>
            {capabilities.includes(Types.AccountCapability.INSTALLMENTS) && patientPortion >= 500 && (
              <PayOverTimeOptionsCard guarantorId={guarantorId} elevation={0} />
            )}
            <Pane display="flex" marginTop={24} justifyContent="space-between">
              <Button type="button" justifyContent="center" height={48} onClick={onBack} appearance="minimal">
                Back
              </Button>
              <Button
                type="submit"
                justifyContent="center"
                appearance="primary"
                height={48}
                disabled={patientPortion < 500 || isBalanceEditing}
                isLoading={setContactBalanceNoteStatus.loading}
              >
                Next
              </Button>
            </Pane>
          </Form>
        )}
      </Formik>
    </Pane>
  )
}

export default SendNotificationPayment
