import { forwardRef } from 'react'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Text, TextProps, ButtonProps } from 'evergreen-ui'
import { colors } from 'lib'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = Omit<TextProps, 'size'> & {
  icon: IconProp
  size?: FontAwesomeIconProps['size']
  spin?: boolean
  intent?: ButtonProps['intent']
}

const Icon = forwardRef<HTMLDivElement, Props>(({ icon, size, color, intent, spin, ...props }, ref) => {
  // TODO: Replace color={} references with intent throughout the App
  if (intent) color = intent

  switch (color) {
    case 'default':
    case 'none': // covers intent
      color = colors.blue.base
      break
    case 'success':
      color = colors.green.base
      break
    case 'warning':
      color = colors.orange.base
      break
    case 'danger':
      color = colors.red.base
      break
    case 'info':
      color = colors.yellow.base
      break
    case 'muted':
      color = colors.neutral.muted
      break
  }

  return (
    <Text {...props} color={color} ref={ref}>
      <FontAwesomeIcon icon={icon} size={size} spin={spin} data-undraggable />
    </Text>
  )
})

export default Icon
