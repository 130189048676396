import gql from 'graphql-tag'

export const GET_GLOBAL = gql`
  query Global {
    meUser {
      id

      firstName
      lastName
      google {
        currentGroupId
        email
        anonymize
      }
      groups {
        id
        name
      }
      accounts {
        id
        code
        name
      }
      groups {
        id
        name
      }
    }
    account {
      id
      code
      name
      phone
      publicReplyPhone
      programName
      capabilities
      hasAutoBlockRules
      letterFilterId

      stripe {
        id
        acceptPayments
      }
      latestGeneralReport: latestReport(type: GENERAL) {
        id
        type
        path
      }

      logoUrl
      siteUrl
      installmentTemplates {
        interval
        intervalType
        periods
        adminFee
        downPayment
      }

      returnAddress {
        id
        verifiedAddress {
          id
        }
      }
    }
  }
`

// REMOVE LAST TWO!!!!
