import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useMutation, useApolloClient } from '@apollo/client'
import { CONNECT_STRIPE_ACCOUNT } from 'graphql/_stripe_connect_button'

import { toaster } from 'evergreen-ui'

import cuid from 'cuid'

import { Types, Button, useToken, mc } from 'lib'

export type Props = {
  callbackUrl: string
}

const StripeConnectButton = ({ callbackUrl }: Props) => {
  const { tokenResult } = useToken()
  const history = useHistory()
  const apolloClient = useApolloClient()

  const redirectUrl = `${mc.urls.provider}${callbackUrl}`

  const [connectAccount, connectAccountStatus] = useMutation<
    Types.ConnectStripeAccount,
    Types.ConnectStripeAccountVariables
  >(CONNECT_STRIPE_ACCOUNT, {
    onCompleted: () => {
      if (callbackUrl === '/onboarding/bank') {
        history.push('/onboarding/complete')
      }
      toaster.success('Stripe account successfully connected!')
    },
    onError: () => toaster.danger('Unable to connect with Stripe')
  })

  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search)
    const stripeCode = queryString.get('code')

    if (stripeCode && !!tokenResult) {
      const localStorageToken = queryString.get('state')
      const urlToken = window.localStorage.getItem('pearlyStripeCSRFToken')

      if (localStorageToken === urlToken) {
        connectAccount({ variables: { authorizationCode: stripeCode } })
      } else {
        toaster.danger('Invalid Callback Token')
      }
    }
  }, [connectAccount, apolloClient, tokenResult])

  return (
    <Button
      isLoading={connectAccountStatus.loading}
      height={48}
      iconBefore={['fab', 'stripe-s']}
      onClick={() => {
        const token = cuid()
        window.localStorage.setItem('pearlyStripeCSRFToken', token)
        window.location.href = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${redirectUrl}&client_id=${window._env_.REACT_APP_STRIPE_CLIENTID}&state=${token}`
      }}
    >
      Create Account With Stripe
    </Button>
  )
}

export default StripeConnectButton
