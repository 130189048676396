import { createContext, useContext, useReducer } from 'react'

import { ApolloClient, ApolloProvider } from '@apollo/client'

import cuid from 'cuid'

type CustomParameters<T> = T extends (...args: infer P) => any ? P : never

type ModalType =
  | 'contract'
  | 'dentist'
  | 'address'
  | 'plan'
  | 'user'
  | 'dependent'
  | 'guarantor'
  | 'sendInvite'
  | 'confirm'
  | 'invoice'
  | 'scheduledPayment'
  | 'charge'
  | 'twoFactor'
  | 'selectPlan'
  | 'setDate'
  | 'resumeMembership'
  | 'resumeInstallmentPlan'
  | 'resetPatientPassword'
  | 'resetUserPassword'
  | 'video'
  | 'balanceActivity'
  | 'refund'
  | 'accountBalanceType'
  | 'installmentTemplate'
  | 'financingProvider'
  | 'sunbit'
  | 'installmentTerms'
  | 'sunbitText'
  | 'payFaqs'
  | 'template'
  | 'notificationTemplateTest'
  | 'mergeGuarantors'
  | 'balanceFilter'
  | 'corner'
  | 'billingIvr'

type ModalsState = { key: string; type: ModalType; isShown: boolean; props?: { [x: string]: unknown } }[]
type ModalComponent = (props: any) => JSX.Element
type ModalComponents = Partial<Record<ModalType, ModalComponent>>
type ModalsAction =
  | { type: 'push'; modal: ModalType; key: string; props?: { [x: string]: unknown } }
  | { type: 'remove'; key: string }
  | { type: 'reset' }

type CreateModalProvider = <V extends ModalComponents>(
  modalComponents: V
) => {
  ModalProvider: ModalProvider
  useModal: <T extends ModalType & keyof V>(
    modal: T | false
  ) => (sheetProps?: Omit<CustomParameters<V[T]>[0], 'isShown' | 'setIsShown'>) => void
}

type ModalProvider = (props: { children: React.ReactNode; apolloClient: ApolloClient<any> }) => JSX.Element

export const createModalProvider: CreateModalProvider = (modalComponents) => {
  const ModalContext = createContext({} as React.Dispatch<ModalsAction>)

  const initialSheetState: ModalsState = []

  type ModalsReducer = (state: ModalsState, action: ModalsAction) => ModalsState
  const reducer: ModalsReducer = (state, action) => {
    switch (action.type) {
      case 'push':
        return [...state, { key: action.key, type: action.modal, isShown: true, props: action.props }]
      case 'remove':
        return state.filter((el) => el.key !== action.key)
      case 'reset':
        return state.map((el) => ({ ...el, isShown: false }))
    }
  }

  const ModalProvider: ModalProvider = ({ children, apolloClient }) => {
    const [state, dispatch] = useReducer(reducer, initialSheetState)
    return (
      <ModalContext.Provider value={dispatch}>
        <ApolloProvider client={apolloClient}>
          {state.map((modal) => {
            const Component = modalComponents[modal.type] as ModalComponent
            return (
              <Component
                key={modal.key}
                isShown={modal.isShown}
                setIsShown={() =>
                  dispatch({
                    type: 'remove',
                    key: modal.key
                  })
                }
                {...modal.props}
              />
            )
          })}
        </ApolloProvider>
        {children}
      </ModalContext.Provider>
    )
  }

  return {
    ModalProvider,
    useModal: (modal) => {
      // Takes either modalType (shows Modal) or false (hides all modals)
      const modalDispatch = useContext(ModalContext)

      return modal === false
        ? () => modalDispatch({ type: 'reset' })
        : (modalProps) => {
            const key = cuid()
            modalDispatch({
              type: 'push',
              modal,
              key,
              props: modalProps
            })
          }
    }
  }
}
