import { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'

import styled from 'styled-components/macro'

import { Switch, Route, useLocation, useHistory, Redirect } from 'react-router-dom'

import { Pane } from 'evergreen-ui'

import Onboarding from 'components/onboarding'

import {
  Types,
  FullScreen,
  Link,
  Button,
  useMediaQueryContext,
  ProgressBar,
  useToken,
  mc,
  prettyPhoneNumber
} from 'lib'

import logo from 'assets/img/logo_color.png'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = {
  className?: string
}

const steps: {
  name: Types.OnboardingStep
  path: string
  component: React.FC<{
    prev: string
    next: string
    setIsForward: React.Dispatch<React.SetStateAction<boolean>>
  }>
  icon: IconProp
}[] = [
  { name: Types.OnboardingStep.START, path: '/onboarding', component: Onboarding.Start, icon: ['far', 'id-card-alt'] },
  {
    name: Types.OnboardingStep.PRACTICE,
    path: '/onboarding/practice',
    component: Onboarding.Practice,
    icon: ['far', 'store-alt']
  },
  {
    name: Types.OnboardingStep.OFFICES,
    path: '/onboarding/offices',
    component: Onboarding.Offices,
    icon: ['far', 'map-location']
  },
  {
    name: Types.OnboardingStep.DENTISTS,
    path: '/onboarding/dentists',
    component: Onboarding.Dentists,
    icon: ['far', 'users']
  },
  {
    name: Types.OnboardingStep.BANK,
    path: '/onboarding/bank',
    component: Onboarding.Bank,
    icon: ['far', 'building-columns']
  },
  {
    name: Types.OnboardingStep.COMPLETE,
    path: '/onboarding/complete',
    component: Onboarding.Complete,
    icon: ['far', 'building-columns']
  }
]

const OnboardingView = ({ className }: Props) => {
  const { tokenResult } = useToken()
  const history = useHistory()

  const location = useLocation()
  const { pathname } = location
  const { isMobile } = useMediaQueryContext()

  const [isForward, setIsForward] = useState(true)
  const exitAnimationDuration = 0.15

  const currentStepIndex = steps.findIndex((step) => step.path === pathname)

  // Redirect onboarding complete and restrict onboarding step to furthest step reached
  useEffect(() => {
    if (tokenResult) {
      const { claims } = tokenResult
      if (claims.onboardingStep === Types.OnboardingStep.COMPLETE) history.replace('/')
      const latestIndex = steps.findIndex((step) => step.name === claims.onboardingStep)
      if (latestIndex > -1 && currentStepIndex > latestIndex) {
        setIsForward(false)
        history.replace(steps[latestIndex].path)
      }
    }
  }, [tokenResult, currentStepIndex, history])

  useEffect(() => {
    // Was unable to implement using refs because onboarding body is being unmounted / mounted
    const timeout = setTimeout(
      () => document.querySelectorAll('#onboarding-body').forEach((el) => el.scrollTo({ top: 0, behavior: 'auto' })),
      exitAnimationDuration * 1000
    )
    return () => clearTimeout(timeout)
  }, [pathname])

  const transitions = useTransition(location, {
    key: location.pathname,
    initial: null,
    from: {
      opacity: 0,
      left: isForward ? '400px' : '-400px'
    },
    enter: {
      opacity: 1,
      left: '0px'
    },
    leave: {
      opacity: 0,
      left: isForward ? '-400px' : '400px'
    }
  })

  // Redirect new onboardings to first step
  if (tokenResult === null && currentStepIndex > 0) return <Redirect to="/onboarding" />
  // Redirect to dashboard if onboarding complete

  return (
    <FullScreen className={className}>
      <Pane gridArea="header" display="flex" justifyContent="space-between" alignItems="center">
        <Pane width={isMobile ? '120px' : '180px'} textAlign="center" paddingY={16}>
          <img src={mc.imagePaths?.logo ?? logo} alt="Logo" height={isMobile ? '28px' : '37px'} />
        </Pane>
        <Pane height={isMobile ? '100%' : '50px'} marginRight={8} display="flex" alignItems="center">
          <Link href={`tel:${mc.phone}`}>
            <Button appearance="minimal" iconBefore={['fas', 'phone']}>
              {prettyPhoneNumber(mc.phone)}
            </Button>
          </Link>
          <Link href={`mailto:${mc.email}`} hidden={isMobile} marginLeft={8}>
            <Button appearance="minimal" iconBefore={['fas', 'envelope']}>
              {mc.email}
            </Button>
          </Link>
        </Pane>
      </Pane>
      <Pane
        gridArea="progress-bar"
        justifySelf="center"
        padding={8}
        paddingBottom={32}
        display={isMobile ? 'none' : 'block'}
      >
        <ProgressBar
          index={currentStepIndex}
          steps={steps.slice(0, -1).map((step) => ({ icon: step.icon as IconProp }))}
        />
      </Pane>
      <Pane gridArea="body" overflow="hidden" position="relative">
        {transitions((props, location, { key }) => (
          <animated.div style={{ ...props, height: '100%', width: '100%', position: 'absolute' }} key={key}>
            <Pane
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              overflow="auto"
            >
              <Pane
                width="400px"
                maxWidth="100vw"
                paddingX={16}
                paddingTop={isMobile ? 8 : 0}
                paddingBottom={isMobile ? 128 : 16}
              >
                <>
                  <Switch location={location}>
                    {steps.map((step, i) => (
                      <Route key={i} exact path={step.path} strict>
                        <step.component
                          prev={steps[i - 1]?.path}
                          next={steps[i + 1]?.path}
                          setIsForward={setIsForward}
                        />
                      </Route>
                    ))}
                    <Redirect from="/onboarding*" to="/onboarding" />
                  </Switch>
                </>
              </Pane>
            </Pane>
          </animated.div>
        ))}
      </Pane>
    </FullScreen>
  )
}

export default styled(OnboardingView)`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 100%;
  height: 100vh;
  grid-template-areas:
    'header'
    'progress-bar'
    'body';
`
