import gql from 'graphql-tag'

const fragments = {
  SunbitConfigurationFields: gql`
    fragment SunbitConfigurationFields on SunbitConfiguration {
      id
      enabled
      locationId
      departmentId
      representativeEmail
      representativeFirstName
      representativeLastName

      balanceFilter {
        id
        minBalance
        maxBalance
        allowL30
        allowL60
        allowL90
        allowG90
      }
    }
  `
}

export const UPSERT_SUNBIT_CONFIGURATION = gql`
  mutation UpsertSunbitConfiguration(
    $id: ID
    $locationId: String!
    $departmentId: String
    $representativeEmail: String
    $representativeFirstName: String
    $representativeLastName: String
    $balanceFilter: PartialBalanceFilterInput!
  ) {
    upsertSunbitConfiguration(
      id: $id
      locationId: $locationId
      departmentId: $departmentId
      representativeEmail: $representativeEmail
      representativeFirstName: $representativeFirstName
      representativeLastName: $representativeLastName
      balanceFilter: $balanceFilter
    ) {
      ...SunbitConfigurationFields
    }
  }
  ${fragments.SunbitConfigurationFields}
`
