import { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'

import { useQuery, useApolloClient } from '@apollo/client'
import { GET_PLAN_CARD_LIST } from 'lib/graphql/_plan-card-list'

import { Pane, Badge, Heading, UnorderedList, ListItem, PaneProps, Switch, Text, Strong } from 'evergreen-ui'

import {
  Types,
  Button,
  Icon,
  Card,
  Spinner,
  Link,
  colors,
  ageRangeToString,
  toDollars,
  getShownPrice,
  useMediaQueryContext,
  ContractSheet
} from 'lib'

export type Props = PaneProps & {
  accountId?: string
  contactId?: string

  isFirstFamilyMember?: boolean
  age?: number
  perio?: boolean
  selectPlan?: (plan: Types.PlanCardList_livePlans, term: Types.PlanTerm, goNext?: boolean) => void
  selectedPlanId?: string
  selectedPlanTerm?: Types.PlanTerm
  isSite?: boolean
  siteColor?: Types.SiteColor

  coupon?: Types.Coupon
  hideList?: { id: string; term: Types.PlanTerm }[]
  effectiveDate?: number
}

const PlanCardList = ({
  accountId,
  contactId,

  isFirstFamilyMember = true,
  age,
  perio,
  selectPlan,
  selectedPlanId,
  selectedPlanTerm,
  isSite,
  siteColor,
  coupon,
  hideList,
  effectiveDate,
  ...props
}: Props) => {
  const apolloClient = useApolloClient()
  const [isContractSheetShown, setIsContractSheetShown] = useState(false)
  const [planId, setPlanId] = useState('')

  // refetch every time
  const { loading, error, data } = useQuery<Types.PlanCardList, Types.PlanCardListVariables>(GET_PLAN_CARD_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      contactId,
      age,
      perio
    },
    client: apolloClient,
    onCompleted: () => {
      if (!term) setTerm(annualPlans?.length ? Types.PlanTerm.ANNUAL : Types.PlanTerm.MONTHLY)
    }
  })

  const filteredPlans = data?.livePlans

  const annualPlans = filteredPlans
    ?.filter((plan) => plan.annualPriceActive && !hideList?.includes({ id: plan.id, term: Types.PlanTerm.ANNUAL }))
    .sort((a, b) => a.annualPrice! - b.annualPrice!)
  const monthlyPlans = filteredPlans
    ?.filter((plan) => plan.monthlyPriceActive && !hideList?.includes({ id: plan.id, term: Types.PlanTerm.MONTHLY }))
    .sort((a, b) => a.monthlyPrice! - b.monthlyPrice!)

  const hasAnnualAndMonthly = !!(annualPlans?.length && monthlyPlans?.length)
  const [term, setTerm] = useState<Types.PlanTerm | undefined>(selectedPlanTerm)
  const shownPlans = hasAnnualAndMonthly ? (term === Types.PlanTerm.ANNUAL ? annualPlans : monthlyPlans) : filteredPlans

  // Auto-select existing plan with new term, or existing plan
  useEffect(() => {
    if (term === Types.PlanTerm.ANNUAL) {
      if (selectedPlanId) {
        const annualSelectedPlan = annualPlans?.find((plan) => plan.id === selectedPlanId)
        if (annualSelectedPlan) selectPlan && selectPlan(annualSelectedPlan, Types.PlanTerm.ANNUAL)
      } else if (annualPlans?.length === 1) selectPlan && selectPlan(annualPlans[0], Types.PlanTerm.ANNUAL)
    } else if (term === Types.PlanTerm.MONTHLY) {
      if (selectedPlanId) {
        const monthlySelectedPlan = monthlyPlans?.find((plan) => plan.id === selectedPlanId)
        if (monthlySelectedPlan) selectPlan && selectPlan(monthlySelectedPlan, Types.PlanTerm.MONTHLY)
        else if (monthlyPlans?.length === 1) selectPlan && selectPlan(monthlyPlans[0], Types.PlanTerm.MONTHLY)
      }
    }
  }, [term])

  return loading || error || !data || !term || isFirstFamilyMember === undefined ? (
    <Pane height={225} display="flex" alignItems="center">
      <Spinner delay={0} />
    </Pane>
  ) : (
    <>
      <ContractSheet
        isShown={isContractSheetShown}
        setIsShown={setIsContractSheetShown}
        planId={planId}
        effectiveDate={effectiveDate}
      />

      <Pane padding={16} {...props}>
        {hasAnnualAndMonthly && (
          <Pane display="flex" justifyContent="center" alignItems="center" marginBottom={24}>
            <Heading size={600}>Annual</Heading>
            <Switch
              checked={term === Types.PlanTerm.ANNUAL}
              onChange={() => setTerm(term === Types.PlanTerm.ANNUAL ? Types.PlanTerm.MONTHLY : Types.PlanTerm.ANNUAL)}
              hasCheckIcon={false}
              marginX={16}
              height={24}
              transform="rotate(180deg)"
            />
            <Heading size={600}>Monthly</Heading>
          </Pane>
        )}
        {!shownPlans?.length ? (
          <Heading size={500} flexShrink={0} textAlign="center" paddingY={16} paddingX={40}>
            There are no qualifying live plans in this program.
          </Heading>
        ) : (
          <Pane
            display="flex"
            flexDirection={isSite ? 'row' : 'column'}
            justifyContent="center"
            flexWrap={isSite ? 'wrap' : 'nowrap'}
          >
            {shownPlans.map((plan, i) => {
              return (
                // <Pane key={i}>
                <Fragment key={i}>
                  <PlanCard
                    plan={plan}
                    term={term}
                    margin={isSite ? 12 : 0}
                    marginBottom={i === shownPlans.length - 1 ? 0 : isSite ? 12 : 8}
                    onClick={() => selectPlan && selectPlan(plan, term, true)}
                    showContractSheet={() => {
                      setPlanId(plan.id)
                      setIsContractSheetShown(true)
                    }}
                    selected={plan.id === selectedPlanId}
                    isSite={isSite}
                    siteColor={siteColor}
                    isFirstFamilyMember={isFirstFamilyMember}
                    coupon={coupon}
                  />
                  {isSite && shownPlans.length % 3 === 1 && i === shownPlans.length - 3 && <Pane width="100%" />}
                </Fragment>
              )
            })}
          </Pane>
        )}
      </Pane>
    </>
  )
}

export default PlanCardList

interface PlanCardProps extends PaneProps {
  plan: Types.PlanCardList_livePlans
  term: Types.PlanTerm
  showContractSheet: () => void
  isSite?: boolean
  siteColor?: Types.SiteColor
  isFirstFamilyMember: boolean
  coupon?: Types.Coupon
  selected?: boolean
}

const colorMap = {
  [Types.SiteColor.BLUE]: { buttonIntent: 'none' as const, badgeColor: 'blue' as const },
  [Types.SiteColor.GREEN]: { buttonIntent: 'success' as const, badgeColor: 'green' as const },
  [Types.SiteColor.ORANGE]: { buttonIntent: 'warning' as const, badgeColor: 'orange' as const },
  [Types.SiteColor.RED]: { buttonIntent: 'danger' as const, badgeColor: 'red' as const }
}

const PlanCard = ({
  plan,
  term,
  showContractSheet,
  isSite,
  siteColor,
  isFirstFamilyMember,
  coupon,
  selected,
  ...props
}: PlanCardProps) => {
  const { providerParam } = useParams<{ providerParam: string }>()
  const { isMobile } = useMediaQueryContext()

  const { name, treatments, globalDiscountActive, globalDiscount, discounts, minAge, maxAge } = plan

  const annualPrice = plan.annualPrice ?? 0
  const monthlyPrice = plan.monthlyPrice ?? 0

  return !isSite ? (
    // Enrollment Card
    <Card
      alignItems="center"
      paddingTop={24}
      elevation={1}
      hoverElevation={2}
      borderStyle="solid"
      borderWidth="2px"
      borderColor={selected ? colors.blue.base : 'transparent'}
      {...props}
      cursor="pointer"
    >
      <Strong size={500} marginBottom={8} color={colors.green.dark} textAlign="center" textTransform="uppercase">
        {name}
      </Strong>

      <ShownPrice term={term} isFirstFamilyMember={isFirstFamilyMember} plan={plan} coupon={coupon} />

      <UnorderedList
        icon={<Icon icon={['far', 'check']} color="default" marginTop={-3} marginRight={16} size={undefined} />}
        width={180}
      >
        {treatments.slice(0, 2).map((treatment, i) => (
          <ListItem key={i} textAlign="left">
            {treatment.quantity > 0 && `${treatment.quantity}x `}
            {treatment.name}
          </ListItem>
        ))}
        {(globalDiscountActive || !!discounts.length) && (
          <ListItem textAlign="left">
            {globalDiscountActive ? `${globalDiscount}% Off Other Procedures` : 'Additional Discounts'}
          </ListItem>
        )}
      </UnorderedList>
      <Button
        appearance="minimal"
        type="button"
        height={24}
        marginLeft={8}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation()
          showContractSheet()
        }}
      >
        More Details
      </Button>
    </Card>
  ) : (
    // Marketing Site Card
    <Card padding={24} alignItems="center" cursor="pointer" elevation={1} hoverElevation={3} {...props}>
      <Badge
        size={500}
        height="auto"
        maxWidth={198}
        marginBottom={8}
        color={siteColor && colorMap[siteColor].badgeColor}
        overflow="hidden"
      >
        {name}
      </Badge>
      <Text marginBottom={isMobile ? 8 : 16}>{ageRangeToString({ min: minAge, max: maxAge })}</Text>
      <Heading size={800}>{toDollars(term === Types.PlanTerm.ANNUAL ? annualPrice : monthlyPrice)}</Heading>

      <Text marginBottom={isMobile ? 8 : 16}>Per {term === Types.PlanTerm.ANNUAL ? 'Year' : 'Month'}</Text>

      <UnorderedList
        icon={<Icon icon={['far', 'check']} color="default" marginTop={-3} marginRight={16} size={undefined} />}
        width={180}
        textAlign="left"
        flexGrow={1}
        marginBottom={isMobile ? 8 : 0}
      >
        {treatments.slice(0, 5).map((treatment) => (
          <ListItem key={treatment.id}>
            {treatment.quantity > 0 && `${treatment.quantity}x `}
            {treatment.name}
          </ListItem>
        ))}
        {(globalDiscountActive || !!discounts.length) && (
          <ListItem>
            {globalDiscountActive ? `${globalDiscount}% Off Other Procedures` : 'Additional Discounts'}
          </ListItem>
        )}
      </UnorderedList>
      <Button appearance="minimal" marginBottom={16} onClick={() => showContractSheet()}>
        View More Details
      </Button>
      <Link to={`/${providerParam}/enroll`}>
        <Button
          appearance="primary"
          intent={siteColor && colorMap[siteColor].buttonIntent}
          height={48}
          paddingX={48}
          textAlign="center"
        >
          Sign Up
        </Button>
      </Link>
    </Card>
  )
}

const ShownPrice = ({
  term,
  isFirstFamilyMember,
  plan,
  coupon
}: {
  term: Types.PlanTerm
  isFirstFamilyMember: boolean
  plan: {
    annualPrice: number | null
    annualFamilyPrice: number | null
    monthlyPrice: number | null
    monthlyFamilyPrice: number | null
    familyDiscount: number | null
  }
  coupon?: Types.Coupon
}) => {
  const price = getShownPrice({ term, isFirstFamilyMember, plan, coupon })
  const { familyDiscount } = plan

  return (
    <>
      <Pane display="flex" alignItems="flex-end">
        <Heading size={600}>{toDollars(price)}</Heading>
        <Heading size={500} marginBottom={2} marginLeft={2}>
          {` / ${term === Types.PlanTerm.ANNUAL ? 'yr' : 'mo'}`}
        </Heading>
      </Pane>
      {!isFirstFamilyMember && !!familyDiscount && <Heading size={500}> ({familyDiscount}% Discount)</Heading>}
      {coupon && !!coupon.percent_off && <Heading size={500}> ({coupon.percent_off}% Off Coupon)</Heading>}
    </>
  )
}
