import moment from 'moment'
import { Heading, Paragraph } from 'evergreen-ui'
import { Button, Card, Icon, Types } from 'lib'
import { useHistory } from 'react-router-dom'

import { useGuarantorSheetState } from 'components/_sheets/guarantor-sheet'
import { useModal } from 'components/modal-provider'

type Props = {
  state: Types.SendNotificationState

  guarantor: Types.GetSendNotificationCard['contact']
}

const SendNotificationComplete = ({ state: { channel, letterEstimatedAt }, guarantor: { name } }: Props) => {
  const { updateGuarantorSheetState } = useGuarantorSheetState()

  const closeModal = useModal(false)
  const history = useHistory()
  const isLetter = channel === Types.NotificationChannel.LETTER

  if (isLetter && !letterEstimatedAt) throw Error('Letter estimate missing')

  return isLetter ? (
    <Card alignItems="center" padding={36} elevation={0} textAlign="center">
      <Icon icon={['fad', 'check']} color="success" size="5x" marginBottom={16} />
      <Heading marginBottom={16}>Letter Being Processed</Heading>

      <Paragraph size={500}>
        We expect this letter to be delivered to {name.first} {name.last} on{' '}
        {moment(letterEstimatedAt).format('M/D/YYYY')}
      </Paragraph>

      <Button
        iconBefore={['far', 'mail-bulk']}
        height={48}
        marginY={16}
        onClick={() => {
          closeModal()
          history.push('/outreach/letters')
        }}
      >
        View Letters
      </Button>

      <Paragraph>You have 6 hours to cancel the production of this letter if you made a mistake.</Paragraph>
    </Card>
  ) : (
    <Card alignItems="center" padding={36} elevation={0} textAlign="center">
      <Icon icon={['fad', 'check']} color="success" size="5x" marginBottom={16} />
      <Heading marginBottom={16}>Notification Sent!</Heading>
      <Paragraph size={500}>
        Your custom notification has been successfully sent to {name.first} {name.last}
      </Paragraph>
      <Button
        iconBefore={['far', 'mail-bulk']}
        height={48}
        marginY={16}
        onClick={() =>
          updateGuarantorSheetState({
            path: Types.GuarantorPath.BILLING,
            billingPath: Types.GuarantorBillingPath.OUTREACH
          })
        }
      >
        View Outreach Details
      </Button>
      <Paragraph>To view outreach history, please click the View Outreach Details button above.</Paragraph>
    </Card>
  )
}

export default SendNotificationComplete
