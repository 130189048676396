import { Card as EvergreenCard, CardProps } from 'evergreen-ui'

export type Props = CardProps & {
  className?: string
  children?: React.ReactNode
}

const Card = (props: Props) => (
  <EvergreenCard
    elevation={1}
    background="white"
    padding={16}
    display="flex"
    flexDirection="column"
    overflow="hidden"
    {...props}
  />
)

export default Card
