import moment from 'moment'
import { Pane, Table, Text, Tooltip } from 'evergreen-ui'
import { colors, Icon, Types } from 'lib'

import { outreachEvents } from 'utility/label-maps'

type Props = {
  action: {
    id: string
    date: number
    initiatedDate?: number | null
    type: Types.OutreachTimelineActionType
    templateName: string | null
    url?: string | null
  }
  isOnFlow: boolean
  isFirstItem?: boolean
}

const OutreachTimelineAction = ({ action, isOnFlow, isFirstItem = false }: Props) => {
  const { date, initiatedDate, type, templateName, url } = action

  const isPayment = type === Types.OutreachTimelineActionType.PAYMENT

  let tooltip: string | null = null
  if (templateName) {
    if (initiatedDate) tooltip = `${templateName}\nInitiated ${moment.unix(initiatedDate).format('M/D/YYYY')}`
    else tooltip = templateName
  }

  return (
    <Tooltip
      isShown={tooltip ? undefined : false}
      content={tooltip}
      position="left"
      statelessProps={{ whiteSpace: 'break-spaces' }}
    >
      <Table.Row height={32} borderColor="transparent" position="relative">
        <Table.Cell display="flex" paddingRight={0}>
          {isOnFlow && !isFirstItem && (
            <Pane
              height={16}
              width={2}
              backgroundColor={colors.border.default}
              position="absolute"
              left={17}
              bottom={24}
              zIndex={1}
            />
          )}
          <Pane width="18px" marginRight={4} backgroundColor="white" zIndex={2}>
            <Icon
              icon={['far', outreachEvents[type].icon]}
              fontSize={12}
              lineHeight="12px"
              width={16}
              marginLeft={isPayment ? 3 : 0}
              flexShrink={0}
              color={outreachEvents[type].color}
              cursor={url ? 'pointer' : undefined}
              onClick={url ? () => window.open(url, '_blank') : undefined}
            />
          </Pane>
          <Text size={300} flex="1" fontWeight={500}>
            {outreachEvents[type].label}{' '}
            {url && (
              <Icon
                icon={['far', 'arrow-down-to-line']}
                fontSize={12}
                lineHeight="12px"
                width={16}
                marginLeft={4}
                flexShrink={0}
                cursor={url ? 'pointer' : undefined}
                onClick={() => window.open(url, '_blank')}
              />
            )}
          </Text>
        </Table.Cell>
        <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} paddingRight={0}>
          {moment.unix(date).format('MMM D, YYYY')}
        </Table.TextCell>
        <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
          {moment.unix(date).format('h:mm A')}
        </Table.TextCell>
      </Table.Row>
    </Tooltip>
  )
}

export default OutreachTimelineAction
