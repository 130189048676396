import { Table } from 'evergreen-ui'
import { Types } from 'lib'

import OutreachTimelineFlow from './OutreachTimelineFlow'
import OutreachTimelineAction from './OutreachTimelineAction'

type OutreachTimelineProps = {
  timeline: Types.GuarantorBillingOutreach_contact_outreach_timeline[]
}

const OutreachTimeline = ({ timeline }: OutreachTimelineProps) => {
  return (
    <Table textAlign="left">
      <Table.Body>
        {timeline.map((timelineItem, i) => {
          if (timelineItem.type === Types.OutreachTimelineItemType.FLOW) {
            if (!timelineItem.flow) throw new Error('Inconsistent data')

            return <OutreachTimelineFlow flow={timelineItem.flow} isFirstItem={i === 0} />
          } else if (timelineItem.type === Types.OutreachTimelineItemType.SINGLE_ACTION) {
            if (!timelineItem.singleAction) throw new Error('Inconsistent data')

            return <OutreachTimelineAction action={timelineItem.singleAction} isOnFlow={false} />
          }

          throw new Error('Inconsistent data')
        })}
      </Table.Body>
    </Table>
  )
}

export default OutreachTimeline
